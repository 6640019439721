export type GenericQuotaType = {
  current: null | number;
  max: null | number;
};

export type ActionsQuotaType = {
  current_triggered_monthly: null | number;
  max_triggered_monthly: null | number;
} & GenericQuotaType;

export type AnomalyAlertingQuotaType = {
  max_triggered_monthly: null | number;
  periods: number[];
} & GenericQuotaType;

export type FindingsQuotaType = {
  current_monthly: null | number;
  max_monthly: null | number;
};

export type IncidentPoliciesQuotaType = {
  current_triggered_monthly: null | number;
  frequency: number[];
  max_triggered_monthly: null | number;
  periods: number[];
} & GenericQuotaType;

export type IntegrationsQuotaType = {
  scan_frequency_minimum: null | number;
  supported_integrations: string[];
} & GenericQuotaType;

export type LoggingQuotaType = {
  current_size_monthly: null | number;
  current_num_monthly: null | number;
  max_num_monthly: null | number;
  retention_days: null | number;
  max_log_size_monthly: null | number;
};

export type OrganizationQuotaType = {
  current_users: null | number;
  max_users: null | number;
};

export type StaticAlertingQuotaType = {
  current_triggered_monthly: null | number;
  max_triggered_monthly: null | number;
  periods: number[];
} & GenericQuotaType;

export interface QuotaInterface {
  plan_uuid: string;
  actions: ActionsQuotaType;
  anomaly_alerting: AnomalyAlertingQuotaType;
  api: GenericQuotaType;
  api_token: GenericQuotaType;
  app: GenericQuotaType;
  app_token: GenericQuotaType;
  specification: GenericQuotaType;
  saved_filters: GenericQuotaType;
  findings: FindingsQuotaType;
  incident_policies: IncidentPoliciesQuotaType;
  integrations: IntegrationsQuotaType;
  logging: LoggingQuotaType;
  organization: OrganizationQuotaType;
  specification_version: GenericQuotaType;
  static_alerting: StaticAlertingQuotaType;
}

export class QuotaClass implements QuotaInterface {
  plan_uuid: string;
  actions: ActionsQuotaType;
  anomaly_alerting: AnomalyAlertingQuotaType;
  api: GenericQuotaType;
  api_token: GenericQuotaType;
  app: GenericQuotaType;
  app_token: GenericQuotaType;
  specification: GenericQuotaType;
  findings: FindingsQuotaType;
  incident_policies: IncidentPoliciesQuotaType;
  integrations: IntegrationsQuotaType;
  logging: LoggingQuotaType;
  organization: OrganizationQuotaType;
  saved_filters: GenericQuotaType;
  specification_version: GenericQuotaType;
  static_alerting: StaticAlertingQuotaType;

  constructor(data: QuotaInterface) {
    this.plan_uuid = data.plan_uuid;
    this.actions = data.actions;
    this.anomaly_alerting = data.anomaly_alerting;
    this.api = data.api;
    this.api_token = data.api_token;
    this.app = data.app;
    this.app_token = data.app_token;
    this.specification = data.specification;
    this.findings = data.findings;
    this.incident_policies = data.incident_policies;
    this.integrations = data.integrations;
    this.logging = data.logging;
    this.organization = data.organization;
    this.saved_filters = data.saved_filters;
    this.specification_version = data.specification_version;
    this.static_alerting = data.static_alerting;
  }
}
