import { getRegionFlagIcon } from '@/utils/regionFlags';
import ItemSpan from './ItemSpan';

const ItemRegionFlag = ({
  regionName,
  isAwsResource = true,
}: {
  regionName?: string;
  isAwsResource?: boolean;
}) => {
  if (!regionName || !isAwsResource) return <></>;

  return (
    <div className='flex items-center gap-2'>
      {getRegionFlagIcon(regionName, undefined, 4)}
      <ItemSpan text={regionName} />
    </div>
  );
};

export default ItemRegionFlag;
