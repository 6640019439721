import { Button, message } from 'antd';
import { MdContentCopy } from 'react-icons/md';
import { useCopyToClipboard } from 'usehooks-ts';

const ItemUUID = ({ uuid }: { uuid: string }) => {
  const [, copy] = useCopyToClipboard();
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        copy(uuid);
        message.success('UUID Copied');
      }}
      className='flex items-center gap-2'
    >
      <span>Copy UUID</span>
      <MdContentCopy />
    </Button>
  );
};

export default ItemUUID;
