import { Children, cloneElement } from 'react';

interface DashboardFiltersSelectorsWrapperProps {
  children: React.ReactElement[] | React.ReactElement;
  dark?: boolean;
}

const DashboardFiltersSelectorsWrapper = ({
  children,
  dark = false,
}: DashboardFiltersSelectorsWrapperProps) => {
  const renderChildren = () => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        dark,
      });
    });
  };

  return (
    <div className='flex items-center mt-2 gap-x-4'>{renderChildren()}</div>
  );
};

export default DashboardFiltersSelectorsWrapper;
