import { Drawer as DrawerAntd, Spin } from 'antd';
import { MdClose, MdWarning } from 'react-icons/md';
import { useLazyGetIncidentQuery } from '@/utils/services/incident/incident-endpoints';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import IncidentsSelectStatus from './IncidentsSelectStatus';
import EventsListing from '../Events/EventsListing';
import Item from '../List/Item';
import Dashboard from '@/components/Dashboard';
import List from '../List';
import { useLazyGetIncidentPolicyQuery } from '@/utils/services/incident-policy/incident-policy-endpoints';
import moment from 'moment';
import useRole from '@/utils/hooks/useRole';
import FindingsListingSortedBySeverities from '../Findings/FindingsListingSortedBySeverities';
import { FieldsData } from '@/utils/models/FieldType';
import { FiltersGroupInterface } from '@/utils/models/Filter';

const IncidentsDrawer = () => {
  const { hasPermissionToListEvent, hasPermissionToListFinding } = useRole();
  const { orgID, incidentID } = useParams<{
    orgID: string;
    incidentID?: string;
  }>();
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<string>('events');

  const [
    getIncident,
    {
      currentData: incident,
      isLoading: isIncidentLoading,
      isFetching: isIncidentFetching,
    },
  ] = useLazyGetIncidentQuery();
  const [
    getIncidentPolicy,
    {
      currentData: incidentPolicy,
      isLoading: isIncidentPolicyLoading,
      isFetching: isIncidentPolicyFetching,
      isError: isIncidentPolicyEisError,
    },
  ] = useLazyGetIncidentPolicyQuery();

  const apis = incident?.resources?.apis;
  const apiIDs = incident?.resources?.apiIDs || [];

  const loading =
    isIncidentLoading ||
    isIncidentPolicyLoading ||
    isIncidentPolicyFetching ||
    isIncidentFetching;

  let findingsFilters: FieldsData[] = [];
  let requestsFilters: FiltersGroupInterface[] = [];
  if (apiIDs.length > 0) {
    findingsFilters = [{ field: 'apis', values: apiIDs }];
    requestsFilters = [
      {
        operator: 'include',
        groups: [
          {
            field: 'apiUUID',
            operator: 'is-one-of',
            values: apiIDs,
          },
        ],
      },
    ];
  }

  useEffect(() => {
    if (incidentID) {
      getIncident({ orgID, incidentID }, true);
    }
  }, [orgID, incidentID, getIncident]);

  useEffect(() => {
    if (incident?.incidentPolicy) {
      getIncidentPolicy({
        orgID,
        incidentPolicyID: incident.incidentPolicy,
        throwError: false,
      });
    }
  }, [incident, orgID, getIncidentPolicy]);

  const handleClose = () => {
    const pathname = location.pathname.split('/');
    pathname.pop(); // remove id
    history.replace({ ...location, pathname: pathname.join('/') });
  };

  const open = incidentID !== undefined;
  const events = incident?.events;

  const tabs = [];
  if (hasPermissionToListEvent) {
    tabs.push({ label: 'Events', key: 'events' });
  }
  if (hasPermissionToListFinding) {
    tabs.push({
      label: 'Top 10 Findings by Severity',
      key: 'findings-by-severity',
    });
  }
  tabs.push({ label: 'Requests', key: 'requests' });

  return (
    <DrawerAntd
      width={'100%'}
      placement='right'
      onClose={handleClose}
      size='large'
      open={open}
      closable={false}
    >
      <Spin spinning={loading}>
        <div className='flex items-center mb-4 border-b pb-4 justify-between'>
          <div className='flex items-center gap-x-2'>
            <button className='focus:outline-none' onClick={handleClose}>
              <MdClose size={22} />
            </button>
            <h3 className='font-semibold text-lg'>{incident?.name}</h3>
          </div>
          {incident && (
            <div className='flex items-center gap-x-2'>
              <IncidentsSelectStatus
                status={incident.status}
                incidentID={incident.UUID}
              />
            </div>
          )}
        </div>

        <div className='flex flex-col gap-10 mb-16'>
          <div className='flex flex-col gap-2'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='flex flex-col p-4 gap-6 border shadow-sm'>
                <span className={`text-xl`}>Incident Policy Triggered</span>
                {isIncidentPolicyEisError && (
                  <div className='flex items-center gap-1'>
                    <MdWarning color='red' />
                    <span className='text-red-500'>
                      Incident policy deleted
                    </span>
                  </div>
                )}

                {incidentPolicy && (
                  <>
                    <div className='flex flex-row'>
                      <span className='mr-1 font-semibold'>Name:</span>
                      <div
                        onClick={() => {
                          history.push(
                            `/organisations/${orgID}/posture-management/incidents-policy/${incidentPolicy.UUID}`
                          );
                        }}
                        className='underline hover:underline hover:text-second font-normal cursor-pointer'
                      >
                        {incidentPolicy?.name}
                      </div>
                    </div>
                    <div>
                      <span className='mr-1 font-semibold'>
                        Minimum Severities:
                      </span>
                      <div className='max-h-1/2 mt-2'>
                        <Item.SeveritiesOverview
                          showAllSeverities={false}
                          severities={incidentPolicy?.minimumSeverities || {}}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className='flex flex-col p-4 gap-2 justify-between border shadow-sm'>
                <span className={`text-xl mr-2 mb-4`}>APIs Affected</span>
                {apis?.map((api) => (
                  <div
                    key={api.UUID}
                    className='flex flex-col gap-2 mb-2 h-full'
                  >
                    <div className='flex flex-row'>
                      <Item.LogoAndName logo={api.avatar} name={api.name} />
                      <div
                        className='underline hover:underline hover:text-second ml-2 cursor-pointer'
                        onClick={() => {
                          const uri = encodeURI(
                            `/organisations/${orgID}/applications/${
                              api.api_appUUID
                            }/apis/${
                              api.UUID
                            }/requests?dateTime={"mode":"fixed","start":"${incident?.createdUtcFormat}","end":"${moment()
                              .utc()
                              .format()}"}`
                          );
                          history.push(uri);
                        }}
                      >
                        (View requests since incident)
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='flex flex-col p-4 gap-2 justify-between border shadow-sm'>
                <Dashboard.FindingsOverview
                  light
                  hideViewFindings
                  manualFilters={findingsFilters}
                />
              </div>
            </div>
          </div>

          <div>
            <List.Tabs
              selectedTab={currentTab}
              onChange={(key) => setCurrentTab(key)}
              tabs={tabs}
            />

            {currentTab === 'events' && (
              <EventsListing
                table={{ show: true, ignoreMode: true }}
                grid={{ show: false, ignoreMode: false }}
                events={events}
                incident={incident}
              />
            )}

            {currentTab === 'findings-by-severity' && (
              <FindingsListingSortedBySeverities filters={findingsFilters} />
            )}

            {currentTab === 'requests' && (
              <Dashboard.RequestsTable
                showHeaderOptions={false}
                defaultFilters={requestsFilters}
                defaultOptions={{}}
              />
            )}
          </div>
        </div>
      </Spin>
    </DrawerAntd>
  );
};

export default IncidentsDrawer;
