import { UploadProps } from 'antd';
import { FieldByResource } from './services/resource/resource-endpoints';

export type GenericObject = { [key: string]: string };

export const OPERATORS: GenericObject = {
  'is-one-of': 'is one of',
  'is-not-one-of': 'is not one of',
  exist: 'exists',
  'not-exist': 'does not exist',
  'greater-than': 'is greater than',
  'less-than': 'is less than',
  bool: 'bool',
};

export const PERIOD_OPTIONS = [
  { label: '15 minutes', value: 900, id: '0' },
  { label: '30 minutes', value: 1800, id: '1' },
  { label: '1 hour', value: 3600, id: '2' },
  { label: '6 hours', value: 21600, id: '3' },
  { label: '1 day', value: 86400, id: '4' },
];

export const POLICY_PERIOD_OPTIONS = [
  { label: '15 minutes', value: 15, id: '0' },
  { label: '30 minutes', value: 30, id: '1' },
  { label: '1 hour', value: 60, id: '2' },
  { label: '6 hours', value: 360, id: '3' },
  { label: '1 day', value: 1440, id: '4' },
  { label: '1 week', value: 10080, id: '5' },
];

export const POLICY_FREQUENCY_OPTIONS = [
  { label: '5 minutes', value: 5, id: '0' },
  { label: '15 minutes', value: 15, id: '1' },
  { label: '30 minutes', value: 30, id: '2' },
  { label: '1 hour', value: 60, id: '3' },
  { label: '6 hours', value: 360, id: '4' },
  { label: '1 day', value: 1440, id: '5' },
];

export const RUNS_EVERY_OPTIONS = [
  { label: '5 minutes', value: 900, id: '0' },
  { label: '10 minutes', value: 1800, id: '1' },
  { label: '20 minutes', value: 3600, id: '2' },
  { label: '2 hours', value: 21600, id: '3' },
  { label: '8 hours', value: 86400, id: '4' },
];

export const TIME_UNIT = [
  { value: 'MINS', label: 'Mins' },
  { value: 'HOURS', label: 'Hours' },
  { value: 'DAYS', label: 'Days' },
  { value: 'WEEKS', label: 'Weeks' },
];

export const MISSING_DATA_TREATMENT = [
  { value: 'missing', label: 'Treat missing data as missing' },
  {
    value: 'good',
    label: 'Treat missing data as good (not breaching threshold)',
  },
  {
    value: 'ignore',
    label: 'Treat missing data as ignore (maintain the alarm state)',
  },
  { value: 'bad', label: 'Treat missing data as bad (breaching threshold)' },
];

export const LOGGING_INTEGRATION_ID = '70b7faa9-6ec6-4105-7e58-e6f528908554';

export const APIGATEWAYLOGGING = 'APIGATEWAYLOGGING';

export const UPLOAD_SPEC_PROPS: UploadProps = {
  customRequest: () => {},
  accept: '.json, .yaml',
  maxCount: 1,
  showUploadList: false,
};

export const multiOperators = {
  'is-one-of': 'is one of',
  'is-not-one-of': 'is not one of',
};

export const MILISECONDS_TO_INVALIDATE_TAGS_FIVE_SECONDS = 5000;

export const MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS = 3000;

export const MILISECONDS_TO_INVALIDATE_TAGS_TWO_SECONDS = 2000;

export const STATE_VARIABLES_KEY = 'stateVariables';

export const FINDINGS_FIELD_BY_RESOURCE: FieldByResource[] = [
  { key: 'API', value: 'apis' },
  { key: 'Application', value: 'apps' },
  { key: 'Severity', value: 'severity' },
  { key: 'Status', value: 'status' },
  { key: 'Name', value: 'code' },
  { key: 'Event', value: 'events' },
  {
    key: 'Security Frameworks',
    value: 'security-frameworks',
  },
];

export const INCIDENT_POLICY_FIELD_BY_RESOURCE: FieldByResource[] = [
  { key: 'API', value: 'apis' },
  { key: 'Application', value: 'apps' },
];

export const INTEGRATION_SLACK_SETUP_COMPLETE =
  'INTEGRATION_SLACK_SETUP_COMPLETE';

export const INTEGRATION_SLACK_SETUP_ERROR = 'INTEGRATION_SLACK_SETUP_ERROR';

export const METRIC_NAME_OPTIONS = [
  {
    label: 'Log Count',
    description: 'Number of Logs',
    value: 'log_count',
    supportMetricStat: ['sum'],
  },
  {
    label: 'Request Payload Size',
    description: 'Request Payload Size in Bytes',
    value: 'request_payload_size',
  },
  {
    label: 'Response Payload Size',
    description: 'Response Payload Size in Bytes',
    value: 'response_payload_size',
  },
  {
    label: 'Request Header Size',
    description: 'Request Header Size in Bytes',
    value: 'request_header_size',
  },
  {
    label: 'Response Header Size',
    description: 'Response Header Size in Bytes',
    value: 'response_header_size',
  },
  {
    label: 'Combined Header Size',
    description: 'Combined Header Size in Bytes',
    value: 'combined_header_size',
  },
  {
    label: 'Combined Payload Size',
    description: 'Combined Payload Size in Bytes',
    value: 'combined_payload_size',
  },
  {
    label: 'Combined Size',
    description: 'Combined Size in Bytes',
    value: 'combined_size',
  },
  {
    label: 'Execution Time',
    description: 'Execution Time in Milliseconds',
    value: 'execution_time',
  },
];

export const METRIC_STAT_OPTIONS = [
  { label: 'Sum', value: 'sum' },
  { label: 'Average', value: 'avg' },
  { label: 'Minimum', value: 'min' },
  { label: 'Maximum', value: 'max' },
  { label: 'Count', value: 'count' },
  { label: '99.9th Percentile', value: 'p_99.9' },
  { label: '99th Percentile', value: 'p_99' },
  { label: '95th Percentile', value: 'p_95' },
  { label: '90th Percentile', value: 'p_90' },
  { label: '50th Percentile', value: 'p_50' },
  { label: '25th Percentile', value: 'p_25' },
];

export const STATIC_THRESHOLD_OPTIONS = [
  {
    label: 'Greater',
    description: '> threshold',
    value: '>',
  },
  {
    label: 'Greater / Equal',
    description: '>= threshold',
    value: '>=',
  },
  {
    label: 'Equal',
    description: '== threshold',
    value: '==',
  },
  {
    label: 'Lower / Equal',
    description: '<= threshold',
    value: '<=',
  },
  {
    label: 'Lower',
    description: '< threshold',
    value: '<',
  },
];

export const ANOMALY_THRESHOLD_OPTIONS = [
  {
    label: 'Outside of the band',
    description: '> or < threshold',
    value: 'LessThanLowerOrGreaterThanUpperThreshold',
  },
  {
    label: 'Greater than the band',
    description: '> threshold',
    value: 'GreaterThanUpperThreshold',
  },
  {
    label: 'Lower than the band',
    description: '< threshold',
    value: 'LessThanLowerThreshold',
  },
];

export const STORAGE_KEY_PREFIX = 'firetail_';
