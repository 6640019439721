import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FieldsData } from '@/utils/models/FieldType';
import {
  JsonParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const filtersDefault = withDefault(JsonParam, undefined);

interface UseFiltersQueryParamProps {
  updateType?: UrlUpdateType;
}

function useFiltersQueryParam(props?: UseFiltersQueryParamProps) {
  const updateType: UrlUpdateType = props?.updateType || 'push';

  const { appID, apiID } = useParams<{ appID?: string; apiID?: string }>();
  const [{ filters: filtersQuery }, setQuery] = useQueryParams({
    filters: filtersDefault,
  });

  const location = useLocation();

  const setFiltersQuery = useCallback(
    (newFilters: FieldsData[]) => {
      setQuery(
        { filters: newFilters.length ? newFilters : undefined },
        updateType
      );
    },
    [setQuery, updateType]
  );

  const setParams = useCallback(
    (obj?: FieldsData[]) => {
      if (
        (Array.isArray(obj) && obj.length > 0) ||
        (typeof obj === 'object' && Object.keys(obj).length > 0)
      ) {
        setFiltersQuery(obj);
      } else {
        setFiltersQuery([]);
      }
    },
    [setFiltersQuery]
  );

  const setFilters = useCallback(
    (object?: any) => {
      let filters: FieldsData[] | undefined;

      if (object) {
        filters = [];
        for (const key in object) {
          if (Array.isArray(object[key]) && object[key].length > 0) {
            filters.push({ field: key, values: object[key] });
          }
        }
      }

      setParams(filters);
    },
    [setParams]
  );

  const isFindingsFilters = useMemo(
    () => location.pathname.includes('findings'),
    [location.pathname]
  );

  const isLinkFilters = useMemo(
    () => location.pathname.endsWith('linked-resources'),
    [location.pathname]
  );

  const filters = useMemo(() => {
    const defaultFilters: FieldsData[] = [];

    if (appID && !isLinkFilters) {
      defaultFilters.push({
        field: isFindingsFilters ? 'apps' : 'appUUID',
        values: [appID],
      });
    }

    if (apiID && !isLinkFilters) {
      defaultFilters.push({
        field: isFindingsFilters ? 'apis' : 'apiUUID',
        values: [apiID],
      });
    }

    const filters: FieldsData[] = filtersQuery || defaultFilters;

    return filters;
  }, [filtersQuery, apiID, appID, isFindingsFilters, isLinkFilters]);

  const getFilterValue = useCallback(
    (field: string) => {
      const filter = filters.find((filter) => filter.field === field);

      return filter?.values;
    },
    [filters]
  );

  const setFilterValue = useCallback(
    (filter: FieldsData) => {
      const copy = [...filters];
      const oldFilterIndex = copy.findIndex((f) => f.field === filter.field);

      if (oldFilterIndex > -1) {
        copy.splice(oldFilterIndex, 1);
      }

      if (filter?.values?.length && filter.values.length > 0) {
        copy.push(filter);
      }

      setParams(copy);
    },
    [filters, setParams]
  );

  return {
    filters,
    setFilters,
    getFilterValue,
    setFilterValue,
  };
}

export default useFiltersQueryParam;
