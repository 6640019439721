import { Dropdown, DropdownProps } from 'antd';
import { HTMLProps, ReactNode, useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';

interface DataDisplayDropdownProps extends DropdownProps {
  children: ReactNode;
  buttonClassName?: HTMLProps<HTMLElement>['className'];
}

const DataDisplayDropdown = ({
  children,
  buttonClassName,
  ...rest
}: DataDisplayDropdownProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const { menu, onOpenChange } = rest;

  return (
    <Dropdown
      {...rest}
      menu={{
        ...menu,
        onClick: (info) => {
          setDropdownIsOpen(false);
          if (menu?.onClick) menu.onClick(info);
        },
      }}
      onOpenChange={(isOpen) => {
        setDropdownIsOpen(isOpen);
        if (onOpenChange) onOpenChange(isOpen);
      }}
    >
      <button
        className={`${buttonClassName} ${
          rest.disabled
            ? 'bg-gray-50 opacity-80 cursor-not-allowed'
            : 'bg-white'
        }`}
        onClick={(event) => event.stopPropagation()}
      >
        <div className='flex items-center gap-x-2 justify-between'>
          {children}
          <MdOutlineExpandMore
            className={`transition-all focus:outline-none ${
              dropdownIsOpen ? '-rotate-180' : ''
            }`}
            size={20}
          />
        </div>
      </button>
    </Dropdown>
  );
};

export default DataDisplayDropdown;
