import './integration-modal.css';
import { PageHeader } from 'antd';
import Item from '../List/Item';
import List from '../List';
import { IntegrationModalTabType } from './IntegrationModal';
import ModalDocsLink from '../Modal/ModalDocsLink';
import './IntegrationModalHeader.css';

const IntegrationModalHeader = ({
  tab,
  setTab,
  logoUrl,
  name,
  description,
  hasNotificationHistory = false,
  helpUrl,
}: {
  tab: IntegrationModalTabType;
  setTab: (state: IntegrationModalTabType) => void;
  logoUrl?: string;
  name?: string;
  description?: string;
  hasNotificationHistory?: boolean;
  helpUrl?: string;
}) => {
  return (
    <PageHeader
      className='-mt-8 -mx-6 pb-6 custom-page-header'
      title={
        <div className='flex flex-row items-center w-full justify-between'>
          <div className='flex relative items-center'>
            <div className='flex justify-center absolute' style={{ width: 80 }}>
              <Item.Logo height={18} logoUrl={logoUrl} name={name} />
            </div>

            <div
              style={{
                paddingLeft: '5.4rem',
              }}
            >
              <p className='text-xl'>{name}</p>
              <p className='text-gray-600 font-normal text-sm text-wrap'>
                {description}
              </p>
            </div>
          </div>
          {helpUrl && <ModalDocsLink link={helpUrl} />}
        </div>
      }
    >
      <div
        className='-mx-6 z-40'
        style={{
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        {hasNotificationHistory && (
          <div
            style={{
              paddingLeft: '7rem',
              paddingRight: '7rem',
            }}
            className='integration-custom-tabs z-50 -mb-6 pb-2'
          >
            <List.Tabs
              onChange={(state) => setTab(state as IntegrationModalTabType)}
              selectedTab={tab}
              tabs={[
                { label: 'Edit', key: 'edit' },
                { label: 'History', key: 'notificationHistory', isNew: true },
              ]}
            />
          </div>
        )}
      </div>
    </PageHeader>
  );
};

export default IntegrationModalHeader;
