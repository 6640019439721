import { useHistory } from 'react-router-dom';
import useQuery from '@/utils/hooks/useQuery';
import FormsModal from '@/components/Modal/FormsModal';
import { useAddOrganisationMutation } from '@/utils/services/organisation/organisation-endpoints';
import { useEffect, useState } from 'react';
import Fields from '@/components/Form/FieldComponents';
import { useTokenRegisterMutation } from '@/utils/services/user/user-endpoints';
import { Button } from 'antd';
import withAuthorizedRequired from '@/utils/lib/firetail-auth/wrappers/withAuthorizedRequired';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';

const AWSMarketplacePage = () => {
  const query = useQuery();
  const token = query.get('x-amzn-marketplace-token');

  const [tokenRegister, { isSuccess: tokenRegisterIsSuccess }] =
    useTokenRegisterMutation();

  useEffect(() => {
    if (token) tokenRegister({ token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AmazonMarketplaceWithAuthorizedRequired
      tokenRegisterWithSuccess={tokenRegisterIsSuccess}
    />
  );
};

const AmazonMarketplace = ({
  tokenRegisterWithSuccess,
}: {
  tokenRegisterWithSuccess?: boolean;
}) => {
  const history = useHistory();
  const [showCreateOrganisationModal, setShowCreateOrganisationModal] =
    useState(false);
  const [addOrganisation, { isLoading: addOrganisationIsLoading }] =
    useAddOrganisationMutation();

  const handleCreateOrganisation = async ({ name }: { name: string }) => {
    const org = await addOrganisation({
      data: {
        name: name.trim(),
      },
    }).unwrap();
    history.push(`/organisations/${org.UUID}/integrations/available`);
  };

  return (
    <>
      <FormsModal
        title='Create Organization'
        confirmLoading={addOrganisationIsLoading}
        handleCloseModal={() => setShowCreateOrganisationModal(false)}
        handleSubmit={handleCreateOrganisation}
        showModal={showCreateOrganisationModal}
        fields={[
          <Fields.InputText
            key='name'
            field={{
              attribute: 'name',
              name: 'Name',
              placeholder: 'my-org',
              validation: { required: true },
              inputType: 'text',
              fieldSize: 'extralarge',
              colSize: 'extralarge',
            }}
          />,
        ]}
      />

      <section className='px-8 py-32'>
        <div className='flex flex-col gap-6 text-center justify-center'>
          <h1 className='text-4xl'>Amazon Marketplace</h1>
          <div>
            <Button
              onClick={() => setShowCreateOrganisationModal(true)}
              type='primary'
              disabled={!tokenRegisterWithSuccess}
            >
              Create Organization
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

const AmazonMarketplaceWithAuthorizedRequired =
  withAuthorizedRequired(AmazonMarketplace);

export default withAuthenticationRequired(AWSMarketplacePage);
