import { useLazyGetEventQuery } from '@/utils/services/events/event-endpoints';
import { Drawer, Result, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Item from '@/components/List/Item';
import { EventClass } from '@/utils/models/Event';
import ApiEventContext from './Context/ApiEventContext';
import SpecificationEventContext from './Context/SpecificationEventContext';
import AlertEventContext from './Context/AlertEventContext';
import IncidentEventContext from './Context/IncidentEventContext';
import SpecificationVersionEventContext from './Context/SpecificationVersionEventContext';
import IncidentPolicyEventContext from './Context/IncidentPolicyEventContext';
import IntegrationEventContext from './Context/IntegrationEventContext';
import AppEventContext from './Context/AppEventContext';
import DailyMetricsEventContext from './Context/DailyMetricsEventContext';
import ActionEventContext from './Context/ActionEventContext';

const EventsDrawer = ({
  redirectToFindings,
}: {
  redirectToFindings?: (event: EventClass) => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const { orgID, eventID } = useParams<{
    orgID: string;
    eventID?: string;
  }>();
  const [
    getEvent,
    {
      currentData: eventData,
      isLoading: isEventLoading,
      isFetching: isEventFetching,
      isError: isGetEventError,
    },
  ] = useLazyGetEventQuery();
  const [event, setEvent] = useState<EventClass>();

  const loading = isEventLoading || isEventFetching;

  useEffect(() => {
    const fetchEventData = async (eventID: string) => {
      const event = await getEvent({ orgID, eventID }, true);
      if (event.data) setEvent(event.data);
    };

    if (eventID) fetchEventData(eventID);
    else setEvent(undefined);
  }, [orgID, eventID, getEvent]);

  const handleClose = () => {
    const pathname = location.pathname.split('/');
    pathname.pop(); // remove id
    history.replace({ ...location, pathname: pathname.join('/') });
  };

  const open = eventID !== undefined;

  const title = event?.mapping?.title || event?.code;

  return (
    <Drawer
      width={'100%'}
      placement='right'
      onClose={handleClose}
      size='large'
      open={open}
      closable={false}
    >
      <Spin spinning={loading}>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center border-b pb-4 justify-between'>
            <div className='flex items-center gap-x-2'>
              <button className='focus:outline-none' onClick={handleClose}>
                <MdClose size={22} />
              </button>
              <span className='text-base'>Event Details</span>
            </div>
          </div>

          {event && (
            <>
              <div className='flex items-center justify-between'>
                <span className='font-semibold text-2xl'>{title}</span>
              </div>

              <div className='flex gap-2 text-xs text-gray-600'>
                <span>{event.created}</span>
                <span className='text-gray-200'>|</span>
                <span>{event.code}</span>
              </div>

              {redirectToFindings && !event.isSeveritiesEmpty && (
                <Item.SeveritiesOverview
                  showAllSeverities={false}
                  severities={event.initialFindingSeverities}
                  onClick={() => redirectToFindings(event)}
                />
              )}

              {event.mapping && <p>{event.mapping.description}</p>}

              {event.context.isAPI && (
                <ApiEventContext context={event.context} />
              )}

              {event.context.isApp && (
                <AppEventContext context={event.context} />
              )}

              {event.context.isSpecification && (
                <SpecificationEventContext context={event.context} />
              )}

              {event.context.isSpecificationVersion && (
                <SpecificationVersionEventContext context={event.context} />
              )}

              {event.context.isAlert && (
                <AlertEventContext context={event.context} />
              )}

              {event.context.isIncident && (
                <IncidentEventContext context={event.context} />
              )}

              {event.context.isIntegration && (
                <IntegrationEventContext context={event.context} />
              )}

              {event.context.isIncidentPolicy && (
                <IncidentPolicyEventContext context={event.context} />
              )}

              {event.context.isDailyMetrics && (
                <DailyMetricsEventContext context={event.context} />
              )}

              {event.context.isAction && (
                <ActionEventContext event={eventData} context={event.context} />
              )}
            </>
          )}

          {isGetEventError && (
            <div className='pt-16' data-testid='show-unauthorized'>
              <Result status='error' title='Bad request' />
            </div>
          )}
        </div>
      </Spin>
    </Drawer>
  );
};

export default EventsDrawer;
