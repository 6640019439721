import useInfiniteScrollQueryParam from '@/utils/hooks/QueryParam/useInfiniteScrollQueryParam';
import { Button } from 'antd';
import React, { ReactElement } from 'react';
import { MdOutlineRefresh } from 'react-icons/md';
import { UrlUpdateType } from 'use-query-params';

const ListInfiniteScroll = ({
  children,
  dataSource,
  currentPageMarker,
  loading,
  updateType,
}: {
  children: ReactElement[] | ReactElement;
  dataSource?: any[];
  currentPageMarker?: string | null;
  loading?: boolean;
  updateType?: UrlUpdateType;
}) => {
  const { setMarker } = useInfiniteScrollQueryParam({ updateType });

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        dataSource,
      });
    });
  };

  const loadMore = () => setMarker(currentPageMarker ?? undefined);

  return (
    <div>
      {renderChildren()}

      {currentPageMarker && (
        <div className='w-full py-4 flex justify-center'>
          <Button
            size='large'
            style={{ backgroundColor: 'white' }}
            loading={loading}
            className='flex gap-1 items-center'
            onClick={loadMore}
          >
            Load More <MdOutlineRefresh size={18} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ListInfiniteScroll;
