import { IoMdStar } from 'react-icons/io';

const ItemButton = ({
  label,
  isSlackButton = false,
  isVisible = true,
  isUpgradeRequired = false,
  onClick,
}: {
  label: string;
  isSlackButton?: boolean;
  isVisible?: boolean;
  isUpgradeRequired?: boolean;
  onClick?: () => void;
}) => {
  const customLabel = isUpgradeRequired ? (
    <div className='flex gap-2 items-center opacity-80 '>
      <IoMdStar color='#F28606' size={18} />
      <span>Upgrade Plan</span>
    </div>
  ) : (
    label
  );

  if (isSlackButton && !isUpgradeRequired) {
    return (
      <img
        alt='Add to Slack'
        height='40'
        width='139'
        src='https://platform.slack-edge.com/img/add_to_slack.png'
        srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
      />
    );
  }

  return (
    <div>
      {isVisible && (
        <div
          style={{
            height: 40,
            color: '#393A3F',
            fontSize: 14,
          }}
          onClick={onClick}
          className={`flex items-center font-semibold border ${
            isUpgradeRequired ? 'border-second' : 'border-[#C7CACD]'
          } rounded-md py-1 px-4 ${
            onClick ? 'cursor-pointer hover:bg-white hover:border-black' : ''
          }`}
        >
          {customLabel}
        </div>
      )}
    </div>
  );
};

export default ItemButton;
