import { Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Container from '@/components/Section/Container';
import FormsModal from '@/components/Modal/FormsModal';
import List from '@/components/List';
import Item from '@/components/List/Item';
import {
  useAddOrganisationMutation,
  useListOrganisationsQuery,
} from '@/utils/services/organisation/organisation-endpoints';
import { OrganisationClass } from '@/utils/models/Organisation';
import { ColumnsType } from 'antd/lib/table';
import Fields from '@/components/Form/FieldComponents';
import Heading from '@/components/Section/Heading';
import { useState } from 'react';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const OrganisationsList = () => {
  const history = useHistory();

  const { query } = useSearchQueryParam();

  const {
    data: listOrganisationsData,
    isLoading: isListOrganisationsDataLoading,
    isFetching: isListOrganisationsDataFetching,
  } = useListOrganisationsQuery({ query });

  const [addOrganisation, { isLoading: addOrganisationIsLoading }] =
    useAddOrganisationMutation();

  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleCreateOrganisation = async ({ name }: { name: string }) => {
    const org = await addOrganisation({
      data: {
        name: name.trim(),
      },
    }).unwrap();
    history.push(
      `/organisations/${org.UUID}/integrations/available?show=billing`
    );
  };

  const handleOrganisationSelected = (organisation: OrganisationClass) =>
    history.push(`/organisations/${organisation.g_orgUUID}`);

  const columns: ColumnsType<OrganisationClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, organisation) => (
        <button
          onClick={() => handleOrganisationSelected(organisation)}
          className='text-base text-blue-600 font-semibold text-left'
        >
          {organisation.name}
        </button>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, organisation) => (
        <Item.Date
          created={organisation.created}
          createdFromNow={organisation.createdFromNow}
        />
      ),
    },
    {
      title: 'Resources',
      key: 'resources',
      render: (_, organisation) => {
        const baseURL = `/organisations/${organisation.g_orgUUID}`;
        return (
          <Space size='middle'>
            <Link
              to={`${baseURL}/dashboard`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Dashboard
            </Link>
            <Link
              to={`${baseURL}/applications`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Applications
            </Link>
            <Link
              to={`${baseURL}/apis`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              APIs
            </Link>
            <Link
              to={`${baseURL}/integrations`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Integrations
            </Link>
            <Link
              to={`${baseURL}/monitor-alerting`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Alerting
            </Link>
            <Link
              to={`${baseURL}/settings`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Settings
            </Link>
          </Space>
        );
      },
    },
  ];

  const handleOpenModal = () => setShowCreateModal(true);

  const isListLoading =
    isListOrganisationsDataFetching || isListOrganisationsDataLoading;

  return (
    <>
      <FormsModal
        title='Create Organization'
        confirmLoading={addOrganisationIsLoading}
        handleCloseModal={() => setShowCreateModal(false)}
        handleSubmit={handleCreateOrganisation}
        showModal={showCreateModal}
        fields={[
          <Fields.InputText
            key='name'
            field={{
              attribute: 'name',
              name: 'Organization Name',
              placeholder: 'my-org',
              validation: {
                required: true,
                regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s]+[a-zA-Z0-9]$`,
                regex_description:
                  'The name must be alphanumeric and between 3 and 128 characters.',
              },
              inputType: 'text',
              fieldSize: 'extralarge',
              colSize: 'extralarge',
            }}
          />,
        ]}
      />

      <Heading text='Organizations' />

      <Container>
        <List.Root
          renderEmpty={{
            isEmpty: listOrganisationsData?.length === 0 && !query,
            loading: isListLoading,
            element: (
              <List.Empty
                title='Create an Organization'
                subtitle='Create an organization to start using the FireTail SaaS platform. You can sign up using a credit card (free trial plan available), or via Amazon Marketplace.'
                buttonTxt='Create Organization'
                handleClick={handleOpenModal}
              />
            ),
          }}
        >
          <List.Header>
            <List.Search placeholder='Search organizations' />
            <List.Settings>
              <Button onClick={handleOpenModal} type='primary'>
                Create Organization
              </Button>
            </List.Settings>
            <List.Segmented />
          </List.Header>

          <List.Pagination dataSource={listOrganisationsData}>
            <List.Grid
              item={{
                render: (organisation: OrganisationClass) => (
                  <Item.Root
                    key={organisation.key}
                    onClick={() => handleOrganisationSelected(organisation)}
                    id={organisation.key}
                  >
                    <Item.Header>
                      <Item.Title>{organisation.name}</Item.Title>
                    </Item.Header>
                    <Item.Footer>
                      <Item.Date
                        created={organisation.created}
                        createdFromNow={organisation.createdFromNow}
                      />
                    </Item.Footer>
                  </Item.Root>
                ),
              }}
              loading={isListLoading}
            />

            <List.Table columns={columns} loading={isListLoading} />
          </List.Pagination>
        </List.Root>
      </Container>
    </>
  );
};

export default OrganisationsList;
