const LIST_OF_SECURITY_FRAMEWORKS = [
  {
    label: 'OWASP API TOP 10',
    value: 'owasp',
  },
  {
    label: 'CIS ASG - API Security Guide',
    value: 'cis-asg',
  },
  {
    label: 'MITRE CWE TOP 25',
    value: 'mitre-cwe-top-25',
  },
  {
    label: 'MITRE ATT&CK - Tactics',
    value: 'mitre-tactics',
  },
  {
    label: 'MITRE ATT&CK - Techniques',
    value: 'mitre-techniques',
  },
];

export default LIST_OF_SECURITY_FRAMEWORKS;
