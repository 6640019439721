import FieldType from '@/utils/models/FieldType';
import { Form, Input, Space } from 'antd';
import { Rule } from 'antd/lib/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const MapField = ({ field: _field }: { field: FieldType }) => {
  const field = structuredClone(_field);
  const rules: Rule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  return (
    <Form.Item
      key={field.attribute}
      label={field.name}
      rules={rules}
      tooltip={field.description}
      className={`w-full ${getFieldSize(field.fieldSize || 'middle')}`}
      style={{
        marginBottom: 0,
      }}
    >
      <Form.List name={field.attribute}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: 'flex',
                  marginBottom: 0,
                }}
                align='baseline'
              >
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  rules={[
                    { required: true, message: 'Missing key' },
                    {
                      pattern: new RegExp(/^\S*$/, 'g'),
                      message: 'Key does not match pattern ${pattern}',
                    },
                  ]}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Input placeholder='Key' disabled={field.disabled} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[
                    { required: true, message: 'Missing value' },
                    {
                      pattern: new RegExp(/^\S*$/, 'g'),
                      message: 'Value does not match pattern ${pattern}',
                    },
                  ]}
                  style={{
                    marginBottom: 4,
                  }}
                >
                  <Input
                    placeholder='Value'
                    style={{
                      marginBottom: 4,
                    }}
                    disabled={field.disabled}
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              {fields.length !== field.validation?.max && (
                <button
                  className='w-24 h-8 px-3 py-2 rounded-sm border border-gray-800 justify-center items-center gap-2 inline-flex cursor-pointer'
                  disabled={field.disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    add();
                  }}
                >
                  <div className='justify-end items-start gap-1 flex'>
                    <PlusOutlined />
                    <div className='text-right text-gray-800 text-sm font-normal leading-none tracking-tight'>
                      Add key
                    </div>
                  </div>
                </button>
              )}
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};

export default MapField;
