import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import {
  IntegrationClass,
  IntegrationInterface,
} from '@/utils/models/Integration';
import FieldType, { FieldsData } from '@/utils/models/FieldType';
import {
  getIntegrationAvailable,
  getMyIntegrations,
  listIntegrationsAvailable,
} from './integration-export-data';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import {
  IntegrationAvailableClass,
  IntegrationAvailableInterface,
} from '@/utils/models/IntegrationAvailableInterface';
import {
  NotificationHistoryClass,
  NotificationHistoryInterface,
} from '@/utils/models/NotificationHistory';
import {
  addIntegrationQueryFulfilled,
  formatFiltersToSupportCategories,
  isIntegrationData,
  mockGetIntegrationAfterEdition,
  mockListIntegrationsAfterDeletion,
  mockListIntegrationsAfterEdition,
  transformApiResponseToInternalData,
} from './integration-methods';
import { getIntegrationsQuotas } from '@/utils/services/quota/quota-export-data';
import { integrationsQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota } from '../quota/quota-methods';

interface ListIntegrationAvailableParams extends OrgGenericParams {
  filters?: { categories?: string[]; supportsActions?: boolean };
}

interface GetIntegrationAvailableParams extends OrgGenericParams {
  integrationAvailableID: string;
}

interface MyIntegrationParams extends OrgGenericParams {
  myIntegrationID: string;
}

interface GithubIntegrationParams extends OrgGenericParams {
  installID: string;
}

interface UpdateGithubIntegrationParams extends GithubIntegrationParams {
  code: string;
}

interface AddGithubIntegrationParams extends UpdateGithubIntegrationParams {
  name: string;
  appID: string;
  scanFrequency: Number;
  ignoreArchivedRepos?: boolean;
  enableAiEnrichment?: boolean;
  deleteArchivedRepoApi?: boolean;
}

interface ListNotificationsHistoryParams extends MyIntegrationParams {
  marker?: string | null;
}

interface NotificationHistoryParams extends MyIntegrationParams {
  notificationHistoryID: string;
}

export interface ListMyIntegrationParams extends OrgGenericParams {
  query?: string;
  filters?: FieldsData[];
  UUIDs?: string[];
  pageSize?: number;
  marker?: number;
}

interface AddIntegrationParams extends ListMyIntegrationParams {
  data: {
    name: string;
    input_values: any[];
    enabled: boolean;
    integration_uuid: string;
  };
}

interface EditMyIntegrationParams
  extends MyIntegrationParams,
    ListMyIntegrationParams {
  data: {
    name: string;
    input_values: any[];
    enabled: boolean;
  };
}

interface DeleteMyIntegrationParams extends ListMyIntegrationParams {
  myIntegrationID: string;
}

export interface IntegrationApiResponse {
  integration: IntegrationClass[];
  marker?: number;
  total: number;
}

export interface IntegrationData {
  integrations: IntegrationClass[];
  marker?: number;
  total: number;
}

export const integrationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listIntegrationsAvailable: build.query<
      IntegrationAvailableClass[],
      ListIntegrationAvailableParams
    >({
      async queryFn({ orgID, filters }, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(
          `/organisations/${orgID}/integrations/available`
        );
        const { integrations } = result.data as {
          integrations: IntegrationAvailableInterface[];
        };

        const integrationsQuota = await getIntegrationsQuotas(orgID);

        let integrationsAvailable = integrations
          .filter((integration) => integration.enabled)
          .map(
            (integration) =>
              new IntegrationAvailableClass(integration, {
                quotas: integrationsQuota,
              })
          )
          .sort((a, b) =>
            a.plan_upgrade_required === b.plan_upgrade_required
              ? 0
              : a.plan_upgrade_required
                ? 1
                : -1
          )
          .sort((a, b) =>
            a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1
          );

        if (filters) {
          const { categories, supportsActions } = filters;
          integrationsAvailable = integrationsAvailable.filter(
            (integration) => {
              const { integration_category, supports_actions } = integration;

              let condition = true;
              if (Array.isArray(categories) && categories.length > 0) {
                condition = categories.includes(integration_category);
              }

              if (supportsActions) {
                condition = condition && supports_actions === supportsActions;
              }

              return condition;
            }
          );
        }

        return {
          data: integrationsAvailable,
        };
      },
    }),
    getIntegrationAvailable: build.query<
      IntegrationAvailableClass,
      GetIntegrationAvailableParams
    >({
      async queryFn(
        { orgID, integrationAvailableID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const result = await fetchWithBQ(
          `/organisations/${orgID}/integrations/available/${integrationAvailableID}`
        );
        const { integration } = result.data as {
          integration: IntegrationAvailableInterface;
        };
        const integrationsQuota = await getIntegrationsQuotas(orgID);
        return {
          data: new IntegrationAvailableClass(integration, {
            quotas: integrationsQuota,
          }),
        };
      },
    }),
    listMyIntegrations: build.query<IntegrationData, ListMyIntegrationParams>({
      async queryFn(paramsQuery, _queryApi, _extraOptions, fetchWithBQ) {
        const { orgID, query, filters, UUIDs, marker, pageSize } = paramsQuery;
        sessionStorage.setItem(
          'MY_INTEGRATIONS_PARAMS',
          JSON.stringify(paramsQuery)
        );
        if (UUIDs) {
          const myIntegrations = await getMyIntegrations({
            orgID,
            myIntegrationIDs: UUIDs,
          });

          return {
            data: {
              integrations: myIntegrations,
              marker: 0,
              total: myIntegrations.length,
            },
          };
        }

        const integrationsAvailable = await listIntegrationsAvailable(orgID);

        const customFilters = formatFiltersToSupportCategories({
          filters,
          integrationsAvailable,
        });

        const params: string[] = ['resource=integrations'];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);
        const url = `/organisations/${orgID}/search?${params.join('&')}`;

        const searchResult = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            search_value: query,
            filters: customFilters,
            sort: { order: 'desc' },
            resource: 'integration',
          },
        });

        let internalData: IntegrationData = {
          integrations: [],
          marker: 0,
          total: 0,
        };

        if (searchResult.data && isIntegrationData(searchResult.data)) {
          internalData = {
            integrations: searchResult.data.integration,
            marker: searchResult.data.marker,
            total: searchResult.data.total,
          };
        } else {
          console.error(
            'Data received does not match expected format',
            searchResult.data
          );
        }

        return {
          data: {
            integrations: internalData.integrations.map((integration) => {
              const available = integrationsAvailable.find(
                (i) => integration.integrationUUID === i.UUID
              );
              return new IntegrationClass(integration, { available });
            }),
            marker: internalData.marker,
            total: internalData.total,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.integrations.map(({ UUID }) => ({
                type: 'Integrations' as const,
                id: UUID,
              })),
              { type: 'Integrations', id: 'LIST' },
            ]
          : [{ type: 'Integrations', id: 'LIST' }],
    }),
    getMyIntegration: build.query<IntegrationClass, MyIntegrationParams>({
      async queryFn(
        { orgID, myIntegrationID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        try {
          const listResult = await fetchWithBQ(
            `/organisations/${orgID}/integrations/${myIntegrationID}`
          );
          const { integration } = listResult.data as {
            integration: IntegrationInterface;
          };

          const available = await getIntegrationAvailable({
            orgID,
            integrationAvailableID: integration.integrationUUID,
          });

          return {
            data: new IntegrationClass(integration, {
              available,
            }),
          };
        } catch (e) {
          return {
            error: 'Integration was not found',
          };
        }
      },
      providesTags: (result, error, { myIntegrationID }) => [
        { type: 'Integrations', id: myIntegrationID },
      ],
    }),
    editMyIntegration: build.mutation<
      IntegrationClass,
      EditMyIntegrationParams
    >({
      async queryFn(
        { orgID, myIntegrationID, data },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const listResult = await fetchWithBQ({
          url: `/organisations/${orgID}/integrations/${myIntegrationID}`,
          method: 'PUT',
          data,
        });

        if (listResult.error) {
          const error = listResult.error as any;
          return {
            error: error?.data?.message ?? 'Error editing integration',
          };
        }

        const { integration } = listResult.data as {
          integration: IntegrationInterface;
        };

        const available = await getIntegrationAvailable({
          orgID,
          integrationAvailableID: integration.integrationUUID,
        });

        return {
          data: new IntegrationClass(integration, { available }),
        };
      },
      async onQueryStarted(
        { orgID, myIntegrationID },
        { dispatch, queryFulfilled }
      ) {
        const storageParams = JSON.parse(
          sessionStorage.getItem('MY_INTEGRATIONS_PARAMS') || ''
        );
        queryFulfilled.then((response) => {
          dispatch(
            integrationEndpoints.util.updateQueryData(
              'listMyIntegrations',
              storageParams, // must contain the same args as listMyIntegrations
              (draft) => {
                const transformedData: IntegrationApiResponse = {
                  integration: draft.integrations,
                  ...draft,
                };

                mockListIntegrationsAfterEdition({
                  draft: transformedData,
                  integrations: response.data,
                });
              }
            )
          );

          dispatch(
            integrationEndpoints.util.updateQueryData(
              'getMyIntegration',
              { orgID, myIntegrationID }, // must contain the same args as getMyIntegration
              () =>
                mockGetIntegrationAfterEdition({
                  integration: response.data,
                })
            )
          );

          setTimeout(() => {
            dispatch(
              integrationEndpoints.util.invalidateTags([
                { type: 'Integrations', id: myIntegrationID },
                'Quotas',
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteMyIntegration: build.mutation<null, DeleteMyIntegrationParams>({
      query: ({ orgID, myIntegrationID }) => ({
        url: `/organisations/${orgID}/integrations/${myIntegrationID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ myIntegrationID }, { dispatch, queryFulfilled }) {
        const storageParams = JSON.parse(
          sessionStorage.getItem('MY_INTEGRATIONS_PARAMS') || ''
        );

        dispatch(
          integrationsQuotaEndpoint.util.updateQueryData(
            'integrations',
            { orgID: storageParams?.orgID },
            (draft) => mockDecrementQuota(draft)
          )
        );

        queryFulfilled.then(() => {
          dispatch(
            integrationEndpoints.util.updateQueryData(
              'listMyIntegrations',
              storageParams, // must contain the same args as listMyIntegrations
              (draft) => {
                const transformedData =
                  transformApiResponseToInternalData(draft);
                mockListIntegrationsAfterDeletion({
                  draft: transformedData,
                  integrationUUID: myIntegrationID,
                });
              }
            )
          );
        });

        setTimeout(() => {
          dispatch(
            integrationEndpoints.util.invalidateTags([
              { type: 'Integrations', id: 'LIST' },
              'Quotas',
            ])
          );
        }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
      },
    }),
    addIntegration: build.mutation<IntegrationClass, AddIntegrationParams>({
      async queryFn({ orgID, data }, _queryApi, _extraOptions, fetchWithBQ) {
        const listResult = await fetchWithBQ({
          url: `/organisations/${orgID}/integrations`,
          method: 'POST',
          data,
        });

        if (listResult.error) {
          const error = listResult.error as any;
          return {
            error: error?.data?.message ?? 'Error adding integration',
          };
        }

        const { integration } = listResult.data as {
          integration: IntegrationInterface;
        };

        const available = await getIntegrationAvailable({
          orgID,
          integrationAvailableID: integration.integrationUUID,
        });

        return {
          data: new IntegrationClass(integration, { available }),
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
          addIntegrationQueryFulfilled(response, dispatch)
        );
      },
    }),
    listNotificationsHistory: build.query<
      { notifications: NotificationHistoryClass[]; marker: string | null },
      ListNotificationsHistoryParams
    >({
      async queryFn(
        { orgID, myIntegrationID, marker },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const LIMIT = 20;

        let url = `/organisations/${orgID}/integrations/${myIntegrationID}/history?limit=${LIMIT}`;
        if (marker) url = url.concat(`&marker=${marker}`);

        const notificationHistoryResult = await fetchWithBQ(url);

        const data = notificationHistoryResult.data as {
          history: NotificationHistoryInterface[];
          marker: string | null;
        };

        return {
          data: {
            notifications: data.history.map(
              (notification) => new NotificationHistoryClass(notification)
            ),
            marker: data.marker,
          },
        };
      },
      providesTags: (result, error, { myIntegrationID }) => [
        { type: 'Integrations', id: myIntegrationID },
      ],
    }),
    getNotificationHistory: build.query<
      NotificationHistoryClass,
      NotificationHistoryParams
    >({
      query: ({ orgID, myIntegrationID, notificationHistoryID }) =>
        `/organisations/${orgID}/integrations/${myIntegrationID}/history/${notificationHistoryID}`,
      transformResponse: (rawResult: {
        history: NotificationHistoryInterface;
      }) => new NotificationHistoryClass(rawResult.history),
    }),
    getGithubIntegrationFields: build.query<FieldType[], OrgGenericParams>({
      query: ({ orgID }) =>
        `/organisations/${orgID}/integrations/github/fields`,
      transformResponse: (rawResult: { fields: FieldType[] }) =>
        rawResult.fields,
    }),
    addGithubIntegration: build.mutation<
      IntegrationClass,
      AddGithubIntegrationParams
    >({
      query: ({
        orgID,
        installID,
        name,
        code,
        appID,
        scanFrequency,
        deleteArchivedRepoApi,
        enableAiEnrichment,
        ignoreArchivedRepos,
      }) => ({
        url: `/organisations/${orgID}/integrations/github/install/${installID}`,
        method: 'POST',
        data: {
          name,
          code,
          app_uuid: appID,
          scan_frequency: scanFrequency,
          ignore_archived_repos: ignoreArchivedRepos,
          delete_archived_repo_api: deleteArchivedRepoApi,
          enable_ai_enrichment: enableAiEnrichment,
        },
      }),
      transformResponse: (rawResult: { integration: IntegrationClass }) =>
        rawResult.integration,
      async onQueryStarted({ installID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          setTimeout(() => {
            dispatch(
              integrationEndpoints.util.invalidateTags([
                { type: 'Integrations', id: installID },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    updateGithubIntegration: build.mutation<
      IntegrationClass,
      UpdateGithubIntegrationParams
    >({
      query: ({ orgID, installID, code }) => ({
        url: `/organisations/${orgID}/integrations/github/install/${installID}`,
        method: 'PUT',
        data: {
          code,
        },
      }),
      transformResponse: (rawResult: { integration: IntegrationClass }) =>
        rawResult.integration,
      async onQueryStarted({ installID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          setTimeout(() => {
            dispatch(
              integrationEndpoints.util.invalidateTags([
                { type: 'Integrations', id: installID },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    getGithubIntegration: build.query<
      IntegrationClass,
      GithubIntegrationParams
    >({
      query: ({ orgID, installID }) => ({
        url: `/organisations/${orgID}/integrations/github/install/${installID}`,
      }),
      transformResponse: (rawResult: { integration: IntegrationClass }) =>
        rawResult.integration,
      providesTags: (result, error, { installID }) => [
        { type: 'Integrations', code: installID },
      ],
    }),
  }),
});

export const {
  useListIntegrationsAvailableQuery,
  useLazyListIntegrationsAvailableQuery,
  useLazyGetIntegrationAvailableQuery,
  useLazyGetMyIntegrationQuery,
  useLazyListMyIntegrationsQuery,
  useListMyIntegrationsQuery,
  useEditMyIntegrationMutation,
  useDeleteMyIntegrationMutation,
  useAddIntegrationMutation,
  useLazyGetNotificationHistoryQuery,
  useGetNotificationHistoryQuery,
  useListNotificationsHistoryQuery,
  useLazyGetGithubIntegrationFieldsQuery,
  useAddGithubIntegrationMutation,
  useLazyGetGithubIntegrationQuery,
  useUpdateGithubIntegrationMutation,
  useGetMyIntegrationQuery,
} = integrationEndpoints;
