import IncidentPolicyDetails from '@/components/IncidentPolicy/IncidentPolicyDetails';
import { EventContextClass } from '@/utils/models/EventContext';
import { IncidentPolicyClass } from '@/utils/models/IncidentPolicy';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const IncidentPolicyEventContext = ({
  context,
}: {
  context: EventContextClass;
}) => {
  const history = useHistory();

  const extraButtons = (incidentPolicy: IncidentPolicyClass) => {
    return [
      <Button
        key='incidentPolicy'
        onClick={() => {
          history.push(
            `/organisations/${incidentPolicy.g_orgUUID}/posture-management/incidents-policy/${incidentPolicy.UUID}`
          );
        }}
      >
        {incidentPolicy.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const incidentPolicy = new IncidentPolicyClass(context.created);
    content = (
      <IncidentPolicyDetails
        incidentPolicy={incidentPolicy}
        extra={extraButtons(incidentPolicy)}
      />
    );
  }

  if (context.isDeleted) {
    content = (
      <IncidentPolicyDetails
        incidentPolicy={new IncidentPolicyClass(context.before)}
      />
    );
  }

  if (context.isChanged) {
    const incidentPolicy = new IncidentPolicyClass(context.after);
    content = (
      <IncidentPolicyDetails
        incidentPolicy={incidentPolicy}
        extra={extraButtons(incidentPolicy)}
      />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default IncidentPolicyEventContext;
