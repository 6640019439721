import { useParams } from 'react-router-dom';
import {
  useGetQuotaUsageQuery,
  QuotaUsageItem,
} from '@/utils/services/quota/quota-endpoints';
import QuotasResourceModal, {
  resourceName,
} from '@/components/Modal/QuotasResourceModal';
import { useState } from 'react';
import { MdSettings } from 'react-icons/md';

const Quotas = () => {
  const { orgID } = useParams<{ orgID: string }>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [resource, setResource] = useState<resourceName>();
  const { data: usageData, isLoading: isUsageLoading } = useGetQuotaUsageQuery({
    orgID,
  });

  const formatNumber = (number: number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  const renderUsageItem = (item: QuotaUsageItem) => {
    let suffix = item.suffix ? ` ${item.suffix}` : '';
    return `${formatNumber(item.current)}/${formatNumber(item.max)}${suffix}`;
  };

  const renderQuotaItem = (category: string, items: QuotaUsageItem[]) => {
    return (
      <div key={category} className='py-2 bg-white px-4'>
        <h5 className='font-bold text-lg pb-1 border-b border-[#dde8ff] grid grid-cols-2 gap-4 items-center py-1'>
          <div
            onClick={
              category === 'Actions'
                ? () => {
                    setResource('Active Actions');
                    setModalTitle('Actions');
                    setShowModal(true);
                  }
                : undefined
            }
            className='flex flex-row'
          >
            {category}
            {category === 'Actions' && (
              <MdSettings className='ml-1 self-center hover:text-second hover:cursor-pointer' />
            )}
          </div>
          <span className='text-sm font-medium'>Used/Max</span>
        </h5>
        {items.map((item, idx) => (
          <div
            key={`${item.name}-${idx}`}
            className='grid grid-cols-2 gap-4 items-center py-1'
            style={{
              borderBottom:
                idx < items.length - 1 ? '1px solid #dde8ff' : 'none',
            }}
          >
            <span>{item.name}:</span>
            <span className='text-sm'>{renderUsageItem(item)}</span>
          </div>
        ))}
      </div>
    );
  };

  const loading = isUsageLoading;

  const sortedData = () => {
    const sortedEntries = Object.entries(usageData?.quotas || {}).sort(
      (a, b) => {
        if (a[0] === 'APIs') return -1;
        if (b[0] === 'APIs') return 1;
        if (a[0] === 'Logging') return -1;
        if (b[0] === 'Logging') return 1;
        return 0;
      }
    );
    return sortedEntries;
  };

  return (
    <>
      <QuotasResourceModal
        handleCloseModal={() => setShowModal(false)}
        showModal={showModal}
        title={modalTitle}
        resource={resource || 'Active Actions'}
      />
      <div className='py-3 text-left flex flex-col'>
        {!loading && usageData && (
          <div className='flex flex-col w-full'>
            {sortedData().map(([category, items]) =>
              renderQuotaItem(category, items)
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Quotas;
