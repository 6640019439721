import { Form } from 'antd';
import { useState } from 'react';
import Modal from '../Modal';
import { DecisionItemType } from '../Modal/ModalDecision';
import { AlertType } from '@/utils/models/Alert';
import AlertsDrawer from './AlertsDrawer';
import { useAddAlertMutation } from '@/utils/services/alerting/alerting-endpoints';
import { useParams } from 'react-router-dom';
import Billing from '@/components/Billing';
import useAlertDecisionItems from '@/utils/hooks/useAlertDecisionItems';
import {
  AnomalyAlertingQuotaType,
  StaticAlertingQuotaType,
} from '@/utils/models/Quota';

interface AlertsModalProps {
  onCloseModal: () => void;
  open: boolean;
  useCurrentFilters?: boolean;
  staticQuota?: StaticAlertingQuotaType;
  anomalyQuota?: AnomalyAlertingQuotaType;
}

const AlertCreation = ({
  onCloseModal,
  open,
  useCurrentFilters,
  staticQuota,
  anomalyQuota,
}: AlertsModalProps) => {
  const { orgID } = useParams<{ orgID: string }>();
  const [formInstance] = Form.useForm();
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);

  const [alertTypeState, setAlertTypeState] = useState<DecisionItemType>();
  const [titleText, setTitleText] = useState<string>();
  const alertDecisionItems = useAlertDecisionItems({
    anomalyQuota,
    staticQuota,
  });

  const [addAlert, { isLoading: isAdding }] = useAddAlertMutation();

  const isOpen = !!alertTypeState;

  const handleOnCloseAlertCreation = () => {
    onCloseModal();
    setAlertTypeState(undefined);
  };

  const handleDecisionMade = (decision: DecisionItemType) => {
    if (decision.value === 'anomaly' && anomalyQuota) {
      // TODO: If using a flag from backend like plan_upgrade_required or something, replace this logic.
      if (
        anomalyQuota.max === 0 ||
        (anomalyQuota?.current as number) >= (anomalyQuota?.max as number)
      ) {
        setTitleText(
          anomalyQuota.max === 0
            ? 'Your current plan does not support anomaly detection alerts.'
            : 'You have reached your limit of created anomaly detection alerts.'
        );
        setIsPlanModalOpen(true);
        return;
      }
    }
    if (decision.value === 'static' && staticQuota) {
      // TODO: If using a flag from backend like plan_upgrade_required or something, replace this logic.
      if (
        staticQuota.max === 0 ||
        (staticQuota?.current as number) >= (staticQuota?.max as number)
      ) {
        setTitleText('You have reached your limit of created static alerts.');
        setIsPlanModalOpen(true);
        return;
      }
    }
    setAlertTypeState(decision);
  };

  const handleSubmitAlert = async (data: any) =>
    addAlert({ orgID, data }).unwrap();

  return (
    <>
      <Modal.Decision
        open={open}
        onClose={onCloseModal}
        handleDecisionMade={handleDecisionMade}
        title={'Choose an Alert Type'}
        options={alertDecisionItems}
      />

      <Billing.Modal
        titleText={titleText}
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <AlertsDrawer
        isOpen={isOpen}
        forceType={alertTypeState?.value as AlertType}
        formInstance={formInstance}
        onClose={handleOnCloseAlertCreation}
        onSubmit={handleSubmitAlert}
        isSubmittingAlert={isAdding}
        useCurrentFilters={useCurrentFilters}
      />
    </>
  );
};

export default AlertCreation;
