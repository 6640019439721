import FormsModal from '@/components/Modal/FormsModal';
import InputTextField from './InputTextField';
import { Button, Form, FormInstance, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAddApplicationMutation } from '@/utils/services/application/application-endpoints';
import FieldType from '@/utils/models/FieldType';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetApplicationQuotasQuery } from '@/utils/services/quota/quota-endpoints';

const CreateApplicationButton = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  const { hasPermissionToCreateApplication } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [openCreateAppModal, setOpenCreateAppModal] = useState<boolean>(false);
  const [isBillingOpen, setIsBillingOpen] = useState<boolean>(false);
  const [addApplication, { isLoading: addApplicationIsLoading }] =
    useAddApplicationMutation();
  const { data: appQuota } = useGetApplicationQuotasQuery(
    { orgID },
    { skip: field.sourceFrom?.resource !== 'app' }
  );

  const handleCloseCreateAppModal = () => setOpenCreateAppModal(false);

  const upgradeRequired =
    (appQuota?.current as number) >= (appQuota?.max as number);

  const handleOpenCreateAppModal = () => {
    if (upgradeRequired) {
      setIsBillingOpen(true);
      return;
    }

    setOpenCreateAppModal(true);
  };

  const onSubmitCreateApp = async (values: { appName: string }) => {
    const app = await addApplication({
      orgID,
      data: {
        name: values.appName.trim(),
      },
    }).unwrap();

    if (formInstance) formInstance.setFieldValue('app_uuid', app.UUID);

    handleCloseCreateAppModal();
  };

  const showCreateApplicationButton =
    hasPermissionToCreateApplication &&
    ((field.sourceFrom?.resource === 'app' && !field.disabled) ||
      field.allowCreate);

  if (showCreateApplicationButton) {
    return (
      <>
        <Billing.Modal
          open={isBillingOpen}
          setIsOpen={() => setIsBillingOpen(false)}
          titleText='You have reached your maximum amount of created applications.'
        />

        <FormsModal
          title='Create Application'
          confirmLoading={addApplicationIsLoading}
          handleCloseModal={handleCloseCreateAppModal}
          handleSubmit={onSubmitCreateApp}
          showModal={openCreateAppModal}
          fields={[
            <InputTextField
              key='app-name'
              field={{
                attribute: 'appName',
                name: 'Application Name',
                placeholder: 'my-app',
                inputType: 'text',
                validation: { required: true },
                fieldSize: 'extralarge',
                colSize: 'extralarge',
                disabled: field.disabled,
              }}
            />,
          ]}
        />

        <Form.Item
          label={field.name === undefined ? field.name : ' '}
          className='ml-1'
        >
          <Button
            onClick={handleOpenCreateAppModal}
            disabled={field.disabled}
            className='flex gap-2 items-center'
          >
            Create{' '}
            {upgradeRequired && (
              <Tooltip title='Upgrade Required'>
                <MdWarning style={{ color: '#FFE25E' }} size={16} />
              </Tooltip>
            )}
          </Button>
        </Form.Item>
      </>
    );
  }

  return <></>;
};

export default CreateApplicationButton;
