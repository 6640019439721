import { Modal, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useListIntegrationsAvailableQuery } from '@/utils/services/integration/integration-endpoints';
import IntegrationItemCard from '../Integration/IntegrationItemCard';

const ChooseIntegrationModal = ({
  open,
  handleClose,
  categories = [],
  onSelected,
  supportsActions,
}: {
  open: boolean;
  handleClose: () => void;
  categories?: Array<string>;
  onSelected: ({
    integrationAvailableID,
    myIntegrationID,
  }: {
    integrationAvailableID: string;
    myIntegrationID?: string;
  }) => void;
  supportsActions?: boolean;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const {
    data: integrationsAvailable,
    isLoading: integrationsAvailableIsLoading,
  } = useListIntegrationsAvailableQuery(
    {
      orgID,
      filters: { categories, supportsActions },
    },
    { skip: open !== true }
  );

  const handleClick = ({
    integrationAvailableID,
    myIntegrationID,
  }: {
    integrationAvailableID: string;
    myIntegrationID?: string;
  }) => {
    onSelected({
      integrationAvailableID,
      myIntegrationID,
    });
    handleClose();
  };

  return (
    <Modal
      style={{ top: 15 }}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      open={open}
      onCancel={handleClose}
      footer={null}
      title='Select an Integration to Add'
      bodyStyle={{ backgroundColor: 'rgb(243 244 246)' }}
      width={'80%'}
    >
      <Spin spinning={integrationsAvailableIsLoading}>
        <div
          className='grid grid-cols-3 gap-4 overflow-auto -m-6 p-6'
          style={{
            maxHeight: '90vh',
          }}
        >
          {integrationsAvailable?.map((integration) => (
            <IntegrationItemCard
              key={integration.UUID}
              integrationAvailable={integration}
              onClick={handleClick}
              isUpgradeRequired={false}
            />
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default ChooseIntegrationModal;
