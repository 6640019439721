import { useGetRequestTagsQuery } from '@/utils/services/org/org-endpoints';
import { Tag, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { getStyleColors } from '@/components/Tag/utils';

const RequestTag = ({
  tagValue,
  dark = false,
  onMouseDown,
  closable,
  onClose,
  showTooltip = true,
}: {
  tagValue: string;
  dark?: boolean;
  onMouseDown?: any;
  closable?: boolean;
  onClose?: any;
  showTooltip?: boolean;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: tags } = useGetRequestTagsQuery({ orgID });
  const tag = tags?.find(
    (t) => t.key === tagValue || t.display_name === tagValue
  );
  const color = tag?.colour;
  const style = getStyleColors(color, dark);

  const TagComponent = (
    <Tag
      key={tag?.display_name || tagValue}
      data-testid={tag?.display_name || tagValue}
      style={style}
      onMouseDown={onMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {tag?.display_name || tagValue}
    </Tag>
  );

  if (showTooltip) {
    return <Tooltip title={tag?.description}>{TagComponent}</Tooltip>;
  }

  return TagComponent;
};

export default RequestTag;
