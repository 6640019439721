import { Dropdown as DropdownAntd, MenuProps } from 'antd';
import { useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';

const Dropdown = ({
  menu,
  icon,
  label,
  dropdownRender,
  labelStyle,
  labelClasses,
  dark = false,
  disabled = false,
}: {
  menu: MenuProps | undefined;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  labelStyle?: React.CSSProperties | undefined;
  labelClasses?: string;
  dark?: boolean;
  disabled?: boolean;
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const defaultStyle = {
    padding: '8px 10px 8px 10px',
    borderRadius: '2px',
    border: '1px solid #7D7D7D',
    letterSpacing: '0.01em',
    lineHeight: '112%',
    color: '#F2F4F6',
  };

  return (
    <DropdownAntd
      menu={{
        ...menu,
        onClick: (info) => {
          setDropdownIsOpen(false);
          if (menu?.onClick) menu.onClick(info);
        },
      }}
      trigger={['click']}
      dropdownRender={dropdownRender}
      onOpenChange={(isOpen) => setDropdownIsOpen(isOpen)}
      disabled={disabled}
    >
      <button
        onClick={(e) => e.preventDefault()}
        className={`focus:outline-none ${labelClasses || ''} ${
          disabled ? 'opacity-50' : ''
        }`}
        style={labelStyle || defaultStyle}
        disabled={disabled}
      >
        <div
          className={`${
            dark ? 'text-anti-flash' : 'text-first'
          } flex gap-x-2 justify-between items-center`}
        >
          {icon}
          {label}
          <MdOutlineExpandMore
            className={`${
              dark ? 'text-anti-flash' : 'text-first'
            } transition-all focus:outline-none ${
              dropdownIsOpen ? '-rotate-180' : ''
            }`}
            size={20}
          />
        </div>
      </button>
    </DropdownAntd>
  );
};

export default Dropdown;
