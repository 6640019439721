import ApisListing from '@/components/Apis/ApisListing';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import EntityLinkingModal from '@/components/Modal/EntityLinkingModal';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';
import { Button } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const Code = () => {
  const { orgID, apiID } = useParams<{
    orgID: string;
    appID?: string;
    apiID?: string;
  }>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data: apiData, isLoading: isApiDataLoading } = useGetApiQuery(
    { orgID, apiID: apiID || '' },
    { skip: !apiID }
  );
  const isApi = apiData?.ft_integration?.platform?.toLowerCase() !== 'github';
  const entityName = isApi ? 'Code Repositories' : 'APIs';

  return (
    <>
      <FullScreenLoader loading={isApiDataLoading} />

      <EntityLinkingModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        onOk={() => setIsModalOpen(false)}
        isApi={isApi}
      />

      <div className='flex flex-row justify-between border-b pb-2 mb-5'>
        <div className='text-xl font-bold self-end'>{entityName}</div>
        <Button
          onClick={() => {
            setIsModalOpen(true);
          }}
          type='primary'
        >
          Create Link
        </Button>
      </div>
      <div>
        <ApisListing />
      </div>
    </>
  );
};

export default Code;
