import { useAppSelector } from '@/app/hooks';
import { ReactNode } from 'react';
import { ListMode, selectListMode } from './listSlice';
import { Empty, Spin } from 'antd';

const ListGrid = ({
  item,
  dataSource,
  loading,
  ignoreMode = false,
}: {
  item: { render: (data: any, index: number) => ReactNode };
  loading?: boolean;
  dataSource?: any[];
  ignoreMode?: boolean;
}) => {
  const defaultMode: ListMode = useAppSelector(selectListMode);
  const dataSourceLength = dataSource?.length;

  if (!ignoreMode && defaultMode !== 'grid') {
    return <></>;
  }

  return (
    <Spin spinning={loading}>
      {dataSourceLength ? (
        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 
          2xl:grid-cols-4 gap-x-4 gap-y-5`}
        >
          {dataSource.map((data, index) => item.render(data, index))}
        </div>
      ) : (
        <section data-testid='show-empty' className='my-12'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </section>
      )}
    </Spin>
  );
};

export default ListGrid;
