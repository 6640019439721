import SpecificationTree from './SpecificationTree';
import SpecificationOverview from './SpecificationOverview';
import SpecificationEndpointDetails from './SpecificationEndpointDetails';
import SpecificationDetails from './SpecificationDetails';
import SpecificationListing from './SpecificationListing';
import SpecificationVersionDetails from './SpecificationVersionDetails';
import SpecificationSwaggerUIPreview from './SpecificationSwaggerUIPreview';
import SpecificationDrawer from './SpecificationDrawer';

const Specification = {
  Listing: SpecificationListing,
  Details: SpecificationDetails,
  EndpointDetails: SpecificationEndpointDetails,
  Overview: SpecificationOverview,
  Tree: SpecificationTree,
  VersionDetails: SpecificationVersionDetails,
  SwaggerUIPreview: SpecificationSwaggerUIPreview,
  Drawer: SpecificationDrawer,
};

export default Specification;
