import FieldType from '@/utils/models/FieldType';
import { Modal } from 'antd';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import { byteSize } from './const/functions';
import { useState } from 'react';
import useFormsVariables from '@/utils/hooks/useFormsVariables';

const CodeBlock = ({ field }: { field: FieldType }) => {
  const { replaceValues } = useFormsVariables();
  let content = replaceValues(field.value || field.code);

  if (content && field.language === 'json') {
    content = JSON.stringify(JSON.parse(content), null, 4);
  }

  const isContentTooLarge = byteSize(content) > 2000;
  const [showAllCode, setShowAllCode] = useState<boolean>(!isContentTooLarge);

  try {
    return (
      <div className='my-2'>
        <div
          style={{ maxHeight: showAllCode ? undefined : 502 }}
          className={`${!showAllCode && 'overflow-hidden'}`}
        >
          <CopyBlock
            language={field.language || 'javascript'}
            text={content}
            codeBlock
            theme={atomOneLight}
            showLineNumbers={field?.showLineNumbers !== false}
          />
        </div>

        {!showAllCode && (
          <button
            onClick={() => setShowAllCode(true)}
            className='w-full bg-white py-2 border text-center cursor-pointer
            shadow-sm hover:border-first-dark'
          >
            Show All
          </button>
        )}
      </div>
    );
  } catch (err: any) {
    if (err.message) {
      Modal.error({ title: err.message });
    }
    return <></>;
  }
};

export default CodeBlock;
