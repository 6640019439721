import LIST_OF_HTTP_METHODS, {
  LIST_OF_EXTRAS_HTTP_METHODS,
} from '@/utils/constants/listOfHttpMethods';
import { Tag, Tooltip } from 'antd';

const MethodTag = ({
  name,
  mode = 'tag',
  showTooltip = true,
  onMouseDown,
  closable,
  onClose,
}: {
  name: string;
  mode?: 'tag' | 'span';
  showTooltip?: boolean;
  onMouseDown?: any;
  closable?: boolean;
  onClose?: any;
}) => {
  let method = LIST_OF_HTTP_METHODS.find(
    (method) => method.name.toUpperCase() === name.toUpperCase()
  );

  if (!method) {
    method = LIST_OF_EXTRAS_HTTP_METHODS.find(
      (method) => method.key.toUpperCase() === name.toUpperCase()
    );
  }

  const content =
    mode === 'tag' ? (
      <Tag
        data-testid={method?.name}
        key={method?.name}
        color={method?.colour}
        onMouseDown={onMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {method?.name.toUpperCase()}
      </Tag>
    ) : (
      <span
        data-testid={method?.name}
        key={method?.name}
        className='w-16 mr-2 px-1 text-center rounded-md opacity-90'
        style={{
          backgroundColor: method?.colour,
          color: 'white',
        }}
      >
        {method?.name.toUpperCase()}
      </span>
    );

  if (showTooltip)
    return <Tooltip title={method?.description}>{content}</Tooltip>;

  return content;
};

export default MethodTag;
