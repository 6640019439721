import EventsListing from '@/components/Events/EventsListing';
import List from '@/components/List';
import useInfiniteScrollQueryParam from '@/utils/hooks/QueryParam/useInfiniteScrollQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import { EventClass } from '@/utils/models/Event';
import { useListEventsQuery } from '@/utils/services/events/event-endpoints';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EventsPage = () => {
  const { orgID } = useParams<{ orgID: string }>();
  const [eventsData, setEventsData] = useState<EventClass[]>([]);

  const { query } = useSearchQueryParam();

  const { marker } = useInfiniteScrollQueryParam();

  const {
    data: listEventsData,
    isLoading: listEventsLoading,
    isFetching: listEventsFetching,
  } = useListEventsQuery({
    orgID,
    marker,
  });

  useEffect(() => {
    if (!listEventsData) return;
    setEventsData((prev) => {
      if (prev.length === 0) {
        return listEventsData.events;
      }

      const newEvents = [...prev];
      listEventsData.events.forEach((event) => {
        if (!newEvents.find((e) => e.UUID === event.UUID)) {
          newEvents.push(event);
        }
      });
      return newEvents;
    });
  }, [listEventsData]);

  const events = filterEventsBySearchQuery(eventsData, query);

  const fetchDataToDownload: () => Promise<any[]> = async () => {
    return Promise.resolve(
      events?.map((event) => event.mapDataToDownload) || []
    );
  };

  return (
    <div>
      <List.Root>
        <List.Header>
          <List.Search updateType='replaceIn' placeholder='Search events' />

          <List.Settings>
            <List.Download
              disabled={events?.length === 0}
              fetchData={fetchDataToDownload}
              loading={listEventsLoading}
            />
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <EventsListing
          events={events}
          currentPageMarker={listEventsData?.marker}
          loading={listEventsLoading || listEventsFetching}
        />
      </List.Root>
    </div>
  );
};

const filterEventsBySearchQuery = (
  events: EventClass[],
  searchQuery: string
): EventClass[] => {
  const sq = searchQuery.toLowerCase();
  return events.filter((event) => {
    const code = event.code.toLowerCase();
    const uuid = event.UUID.toLowerCase();
    const title = event.mapping?.title.toLowerCase();
    const description = event.mapping?.description.toLowerCase();
    return (
      code?.includes(sq) ||
      uuid?.includes(sq) ||
      title?.includes(sq) ||
      description?.includes(sq)
    );
  });
};

export default EventsPage;
