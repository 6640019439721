import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import LIST_OF_HTTP_STATUS_CODE from '@/utils/constants/listOfHttpStatusCode';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

export type StatusCodeGroupType = '1XX' | '2XX' | '3XX' | '4XX' | '5XX';

const StatusCodeGroupButton = ({
  group,
  getFormValues,
  setFormValues,
  check,
  setCheckboxState,
}: {
  group: StatusCodeGroupType;
  check: boolean;
  getFormValues: () => string[];
  setFormValues: (values: string[]) => void;
  setCheckboxState: (checked: boolean) => void;
}) => {
  const firstStatusCodeCharacter = group.charAt(0);

  const getCheckCurrentState = (): boolean => {
    const values = getFormValues();

    if (values.length === 0) return check;

    const statusCodeTotal = LIST_OF_HTTP_STATUS_CODE.filter((status) =>
      status.code.startsWith(firstStatusCodeCharacter)
    ).length;

    const statusCodeSelectedTotal = values.filter((value) =>
      value.startsWith(firstStatusCodeCharacter)
    ).length;

    return statusCodeTotal === statusCodeSelectedTotal || check;
  };

  const isChecked = getCheckCurrentState();

  const handleOnClick = () => {
    const currentValues: string[] = getFormValues();
    if (!isChecked) {
      const newValues = new Set<string>();
      const codes = LIST_OF_HTTP_STATUS_CODE.filter((status) =>
        status.code.startsWith(firstStatusCodeCharacter)
      ).map((status) => status.code);
      [...currentValues, ...codes].forEach((code) => newValues.add(code));
      setFormValues(Array.from(newValues));
    } else {
      setFormValues(
        currentValues.filter(
          (code) => !code.startsWith(firstStatusCodeCharacter)
        )
      );
    }
    setCheckboxState(!isChecked);
  };

  return (
    <button
      className={`w-full flex justify-between hover:bg-[#f2f4f6] ${
        isChecked ? 'bg-[#f2f4f6]' : ''
      }`}
      style={{ minHeight: 32, padding: '5px 12px 5px 24px' }}
      onClick={handleOnClick}
    >
      <StatusCodeTag showTooltip={false} code={group} />
      <div className='flex items-center'>
        <span className='mx-2 text-sm'>Select all</span>
        {isChecked ? (
          <MdCheckBox className='text-first' size={24} />
        ) : (
          <MdCheckBoxOutlineBlank className='text-first' size={24} />
        )}
      </div>
    </button>
  );
};

export default StatusCodeGroupButton;
