import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { usePDF } from '@react-pdf/renderer';
import { MdFileDownload } from 'react-icons/md';

import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import Heading from '@/components/Section/Heading';
import Container from '@/components/Section/Container';
import List from '@/components/List';
import Item from '@/components/List/Item';

import {
  useListAvailableReportsQuery,
  ReportInterface,
  useGetReportMutation,
} from '@/utils/services/reporting/reporting-endpoints';
import { listAPIsByUUID } from '@/utils/services/api/api-export-data';
import { useLazyGetEventsByUUIDsQuery } from '@/utils/services/events/event-endpoints';
import { ApiClass } from '@/utils/models/Api';
import { EventClass } from '@/utils/models/Event';
import {
  getFindingMappings,
  getOwaspMappings,
} from '@/utils/services/local-api-export-data';
import ReportPdf from '@/components/Reporting/ReportPdf';
import useRole from '@/utils/hooks/useRole';
import { message } from 'antd';
import { ReportClass } from '@/utils/models/Report';
import {
  UsageData,
  useLazyGetQuotaUsageQuery,
} from '@/utils/services/quota/quota-endpoints';
import QuotaPdf from '@/components/Reporting/QuotaPdf';
import { useListPlansQuery } from '@/utils/services/billing/billing-endpoints';

interface ReportDetail {
  UUID: string;
  api_uuids: string[];
  event_uuids: string[];
  apiDetails?: ApiClass[];
  eventDetails?: EventClass[];
}

interface GeneratedReport extends ReportInterface {
  documentData: ReportDetail[];
}

interface QuotasReport extends ReportInterface {
  quotaData: UsageData;
}

const Reporting = () => {
  const { hasPermissionToGetReports, hasPermissionToListReports } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [haveManualReportsBeenSet, setHaveManualReportsBeenSet] =
    useState<boolean>(false);
  const [reports, setReports] = useState<ReportClass[]>([]);
  const [isDetailsFetching, setIsDetailsFetching] = useState<boolean>(false);
  const [preparedDocument, setPreparedDocument] = useState<
    GeneratedReport | QuotasReport
  >();
  const [getEventsByUUIDs, { isLoading: isEventsLoading }] =
    useLazyGetEventsByUUIDsQuery();
  const [instance, updateInstance] = usePDF({ document: <></> });
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { data: availableReports, isLoading } = useListAvailableReportsQuery({
    orgID,
  });
  const [getReport, { isLoading: isReportLoading }] = useGetReportMutation();
  const [getQuotasUsage] = useLazyGetQuotaUsageQuery();
  const initiateDownload = useRef(false);
  const { data: plans } = useListPlansQuery({ orgID });

  useEffect(() => {
    if (availableReports && !haveManualReportsBeenSet) {
      const firetailPlatformReport = {
        description: 'This report provides metrics of FireTail platform usage.',
        icon_url:
          'https://cdn.services.firetail.app/assets/images/Logo_Text_Right-black-orange.png',
        report_name: 'quotas_report',
        supports_finding_filters: false,
        title: 'Platform Usage Report',
        UUID: 'firetail-quotas',
      };

      const updatedReports = [
        ...(availableReports || []),
        firetailPlatformReport,
      ];

      setReports(updatedReports);
      setHaveManualReportsBeenSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableReports]);

  const fetchDetailsForReport = async (reportID: string) => {
    if (!reports || !hasPermissionToGetReports) {
      setIsDetailsFetching(false);
      return;
    }

    if (reportID === 'firetail-quotas') {
      const quotaData = await getQuotasUsage({ orgID }).unwrap();
      const detailedReport = {
        UUID: reportID,
        quotaData: quotaData,
        description: 'This report provides metric of FireTail platform usage.',
        icon_url: '',
        report_name: 'firetail_platform_usage_report',
        supports_finding_filters: false,
        title: 'Platform Usage Report',
      };
      if (quotaData) {
        setPreparedDocument(detailedReport);
        const pdfComponent = (
          <QuotaPdf orgID={orgID} quotaData={quotaData} plans={plans} />
        );
        if (pdfComponent) {
          updateInstance(pdfComponent);
          setIsDownloadReady(true);
          initiateDownload.current = true;
          return;
        }
      }
    }

    const reportToDownload = reports.find((report) => report.UUID === reportID);
    if (!reportToDownload) {
      message.info('This report is currently unavailable.');
      setIsDetailsFetching(false);
      return;
    }

    setIsDetailsFetching(true);
    try {
      const reportDetailsResponse = await getReport({
        orgID,
        reportID,
      }).unwrap();

      if (!reportDetailsResponse || reportDetailsResponse.data.length === 0) {
        message.info(
          'Not enough data is currently available to generate a report.'
        );
        setIsDetailsFetching(false);
        return;
      }

      const apiUuids = Array.from(
        new Set(
          reportDetailsResponse.data.map((item) => item.api_uuids).flat() || []
        )
      );
      const eventUuids = Array.from(
        new Set(
          reportDetailsResponse.data.map((item) => item.event_uuids).flat() ||
            []
        )
      );
      const [apiData, events] = await Promise.all([
        listAPIsByUUID({ orgID, UUIDs: apiUuids }),
        fetchEventsInChunks(orgID, eventUuids),
      ]);

      const detailedReport = {
        UUID: reportID,
        data: reportDetailsResponse.data,
        description: reportToDownload.description,
        icon_url: reportToDownload.icon_url,
        report_name: reportToDownload.report_name,
        supports_finding_filters: reportToDownload.supports_finding_filters,
        title: reportToDownload.title,
        documentData: reportDetailsResponse.data.map((detail) => ({
          ...detail,
          apiDetails: apiData.filter((api) =>
            detail.api_uuids.includes(api.UUID)
          ),
          eventDetails: events.filter((event) =>
            detail.event_uuids.includes(event.UUID)
          ),
        })),
      };

      setPreparedDocument(detailedReport);
      const pdfComponent = await renderPdfDocument(detailedReport);
      updateInstance(pdfComponent);
      setIsDownloadReady(true);
      initiateDownload.current = true;
    } catch (error) {
      console.error('Failed to fetch report details:', error);
    }
    setIsDetailsFetching(false);
  };

  const chunkArray = (array: any[], size: number) => {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i += size) {
      chunked_arr.push(array.slice(i, i + size));
    }
    return chunked_arr;
  };

  const fetchEventsInChunks = async (orgID: string, eventUuids: string[]) => {
    const eventChunks = chunkArray(eventUuids, 100);
    let events: EventClass[] = [];
    for (const chunk of eventChunks) {
      const chunkData = await getEventsByUUIDs({ orgID, eventIDs: chunk });
      if (chunkData.data) events = events.concat(chunkData.data);
    }
    return events;
  };

  const handleDownload = (fileUrl: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    initiateDownload.current = false;
  };

  useEffect(() => {
    if (
      instance.url &&
      !instance.loading &&
      preparedDocument &&
      linkRef.current &&
      initiateDownload.current
    ) {
      handleDownload(
        instance.url,
        generateFileName(preparedDocument.report_name)
      );
      setIsDownloadReady(false);
    }
  }, [instance, preparedDocument, isDownloadReady]);

  const MemoizedPdf = React.memo(ReportPdf);

  const renderPdfDocument = async (preparedDocument: {
    documentData: any[];
    UUID: string;
    description: string;
    icon_url: string;
    report_name: string;
    supports_finding_filters: boolean;
    title: string;
  }) => {
    if (preparedDocument) {
      const mappings =
        preparedDocument?.report_name === 'findings_summary'
          ? await getFindingMappings()
          : await getOwaspMappings();

      if (mappings)
        return (
          <MemoizedPdf
            reportData={preparedDocument?.documentData}
            mappings={mappings}
            orgID={orgID}
            title={preparedDocument.title}
          />
        );
    }
    return <></>;
  };

  const generateFileName = (reportName: string): string => {
    const dateSuffix = new Date().toISOString().slice(0, 10);
    return `${reportName
      .replace(/\s+/g, '-')
      .toLowerCase()}-report-${dateSuffix}.pdf`;
  };

  return (
    <>
      <FullScreenLoader
        loading={
          isDetailsFetching ||
          isLoading ||
          isEventsLoading ||
          isReportLoading ||
          isDownloadReady
        }
      />
      <Heading text='Reporting' />
      <Container>
        {hasPermissionToListReports && (
          <List.Root>
            <List.Pagination dataSource={reports}>
              <List.Grid
                loading={false}
                item={{
                  render: (item: ReportInterface) => (
                    <Item.Root
                      key={item.UUID}
                      id={item.UUID}
                      backgroundColor='bg-white'
                      onClick={
                        hasPermissionToGetReports
                          ? () => fetchDetailsForReport(item.UUID)
                          : undefined
                      }
                    >
                      <Item.Header>
                        <div className='flex flex-row justify-between items-center w-full'>
                          <Item.Title>
                            <div className='flex flex-col'>
                              <div>
                                <span>{item.title}</span>
                              </div>
                            </div>
                          </Item.Title>
                          <Item.Logo logoUrl={item.icon_url} />
                        </div>
                      </Item.Header>
                      <div className='overflow-auto'>{item.description}</div>
                      {hasPermissionToGetReports && (
                        <>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            ref={linkRef}
                            download={generateFileName(item.report_name)}
                            style={{ display: 'none' }}
                          >
                            Download
                          </a>
                          <Item.Footer justifyContent='end'>
                            <div className='justify-end'>
                              <div className='flex border border-outline-gray h-8 w-14 items-center justify-center align-middle self-center'>
                                <MdFileDownload size={14} />
                              </div>
                            </div>
                          </Item.Footer>
                        </>
                      )}
                    </Item.Root>
                  ),
                }}
              />
            </List.Pagination>
          </List.Root>
        )}
      </Container>
    </>
  );
};

export default Reporting;
