import BaseEntityClass from './BaseEntity';
import { FilterClass, FilterGroupType } from './Filter';

export type AlertType = 'static' | 'anomaly';
export interface AlertConfigInterface {
  grace_period: number;
  period: number;
  severity: number;
  throttle_interval_unit?: string;
  throttle_period: number;
  threshold_condition: string;
  threshold_value: number;
  throttle_enabled: boolean;
  type: AlertType;
  metric_name?: string;
  metric_stat?: string;
  sensitivity?: number;
}

export interface AlertInterface {
  UUID: string;
  alert_org_uuid: string;
  config: AlertConfigInterface;
  dateAddedInMicroSeconds: number;
  enabled: boolean;
  filters: FilterGroupType[];
  g_orgUUID: string;
  itemType: string;
  last_alerted?: number;
  name: string;
  notification_integration_uuid: string;
  status: string;
  managed: boolean;
  managed_alert_uuid?: string;
}

export class AlertClass extends BaseEntityClass implements AlertInterface {
  UUID: string;
  alert_org_uuid: string;
  config: AlertConfigInterface;
  dateAddedInMicroSeconds: number;
  enabled: boolean;
  filters: FilterGroupType[];
  g_orgUUID: string;
  itemType: string;
  last_alerted?: number;
  name: string;
  notification_integration_uuid: string;
  status: string;
  managed: boolean;
  managed_alert_uuid?: string;

  constructor(data: AlertInterface) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data?.dateAddedInMicroSeconds,
    });
    this.UUID = data.UUID;
    this.alert_org_uuid = data.alert_org_uuid;
    this.config = data.config;
    this.dateAddedInMicroSeconds = data?.dateAddedInMicroSeconds;
    this.enabled = data.enabled;
    this.g_orgUUID = data.g_orgUUID;
    this.itemType = data.itemType;
    this.last_alerted = data.last_alerted;
    this.name = data.name;
    this.notification_integration_uuid = data.notification_integration_uuid;
    this.status = data.status;
    this.filters = data.filters;
    this.managed = data.managed;
    this.managed_alert_uuid = data.managed_alert_uuid;
  }

  get filtersGroups(): FilterGroupType[] {
    return FilterClass.mapFiltersGroups(this.filters);
  }

  get category(): string {
    return this.config.type === 'anomaly' ? 'Anomaly Detection' : 'Static';
  }

  get type(): AlertType {
    return this.config.type || 'static';
  }
}
