import moment from 'moment';
import { DynamicDateTime } from '../hooks/QueryParam/useDynamicDateTimeQueryParam';
import { StaticDateTime } from '../hooks/QueryParam/useStaticDateTimeQueryParam';

function isDynamicDateTime(obj: any): obj is DynamicDateTime {
  return typeof obj.value === 'number';
}

function isStaticDateTime(obj: any): obj is StaticDateTime {
  return typeof obj.mode === 'string';
}

export const calcDateTime = (dateTime: DynamicDateTime | StaticDateTime) => {
  if (isDynamicDateTime(dateTime)) {
    return _calcDynamicDateTime(dateTime);
  }

  if (isStaticDateTime(dateTime)) {
    return _calcStaticDateTime(dateTime);
  }

  throw new Error('Invalid DateTime');
};

const _calcDynamicDateTime = (dateTime: DynamicDateTime) => {
  if (!dateTime.value) {
    throw new Error('Value is required');
  }

  if (dateTime.value > 0) {
    const end = moment();
    const start = moment();
    return {
      start: start.subtract(dateTime.value, 'seconds').utc().format(),
      end: end.utc().format(),
    };
  }

  if (!dateTime.start || !dateTime.end) {
    throw new Error('Start and End are required');
  }

  return {
    start: dateTime.start,
    end: dateTime.end,
  };
};

const _calcStaticDateTime = (
  dateTime: StaticDateTime = { mode: 'last-three-months' }
) => {
  if (dateTime.mode === 'fixed') {
    if (!dateTime.start || !dateTime.end) {
      throw new Error('Start and End are required');
    }

    return {
      start: dateTime.start,
      end: dateTime.end,
    };
  }

  const end = moment();
  const start = moment();
  switch (dateTime.mode) {
    case 'last-hour':
      start.subtract(1, 'hours');
      break;
    case 'last-day':
      start.subtract(1, 'days');
      break;
    case 'last-month':
      start.subtract(1, 'months');
      break;
    case 'last-three-months':
      start.subtract(90, 'days');
      break;
    default:
      throw new Error('Invalid mode');
  }

  return { start: start.utc().format(), end: end.utc().format() };
};

type OrganizedType = { [key: string]: string[] };

export function organizeResourcesFromList(items: string[]) {
  return items.reduce((all: OrganizedType, item) => {
    const [name, uuid] = item.split(':');
    all[name] = all[name] || [];
    all[name].push(uuid);
    return all;
  }, {});
}

export const convertDaysToSeconds = (value: number) => {
  return value * 60 * 60 * 24;
};
