import ListDownload from './ListDownload';
import ListEmpty from './ListEmpty';
import ListGrid from './ListGrid';
import ListHeader from './ListHeader';
import ListPagination from './ListPagination';
import ListRoot from './ListRoot';
import ListSearch from './ListSearch';
import ListSegmented from './ListSegmented';
import ListSettings from './ListSettings';
import ListTable from './ListTable';
import ListTabs from './ListTabs';
import ListUpdate from './ListUpdate';
import ListFilter from './ListFilter/ListFilter';
import ListCheckableTag from './ListCheckableTag';
import ListQuotaIndicator from './ListQuotaIndicator';
import ListCategoryFilter from './ListCategoryFilter';
import ListInfiniteScroll from './ListInfiniteScroll';

const List = {
  Root: ListRoot,
  Segmented: ListSegmented,
  Search: ListSearch,
  Header: ListHeader,
  Grid: ListGrid,
  Table: ListTable,
  Settings: ListSettings,
  Pagination: ListPagination,
  Tabs: ListTabs,
  Download: ListDownload,
  Update: ListUpdate,
  Empty: ListEmpty,
  Filter: ListFilter,
  CheckableTag: ListCheckableTag,
  QuotaIndicator: ListQuotaIndicator,
  CategoryFilter: ListCategoryFilter,
  InfiniteScroll: ListInfiniteScroll,
};

export default List;
