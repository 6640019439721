import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

const ItemCheckBox = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <span
      className='absolute right-1 top-1 bg-white'
      onClick={(event) => {
        onChange(!checked);
        event.stopPropagation();
      }}
    >
      {checked ? (
        <MdCheckBox size={24} />
      ) : (
        <MdCheckBoxOutlineBlank size={24} />
      )}
    </span>
  );
};

export default ItemCheckBox;
