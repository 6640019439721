import { AlertClass, AlertInterface } from '@/utils/models/Alert';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import {
  DataPointsHistographType,
  LineChartType,
  AnomalyPreviewType,
} from '@/utils/models/HistographType';
import {
  ConditionOperatorType,
  FilterGroupOperatorType,
  FiltersGroupInterface,
} from '@/utils/models/Filter';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import {
  mockGetAlertAfterEdition,
  mockListAlertAfterAdition,
  mockListAlertAfterDeletion,
  mockListAlertAfterEdition,
} from './alerting-methods';
import {
  anomalyAlertingQuotaEndpoint,
  staticAlertingQuotaEndpoint,
} from '../quota/quota-endpoints';
import { mockIncrementQuota } from '../quota/quota-methods';
import { setLoadingState } from '@/components/Alerts/Sections/AlertSlice';
import { FieldsData } from '@/utils/models/FieldType';

export interface AlertingParams extends OrgGenericParams {
  alertID: string;
}

export interface ListAlertingParams extends OrgGenericParams {
  query?: string;
  pageSize?: number;
  marker?: number;
  alertType?: string;
}

export interface ListAlertingResponse {
  alerts: AlertClass[];
  total: number;
}

export interface AlertPreviewParams extends OrgGenericParams {
  filters: FiltersGroupInterface[];
  period: number;
  metricName: string;
  metricStat: string;
}

export interface AnomalyAlertPreviewParams extends AlertPreviewParams {
  alertID: string;
  sensitivity?: number;
}

interface AddAlertParams extends OrgGenericParams {
  data: {
    name: string;
    config: any;
    enabled: boolean;
    notification_integration_uuid: string;
    managedFilter?: string;
    managedFilterApiIDs?: string;
    managed?: boolean;
    filterType?: string;
    managed_alert_uuid?: string;
    filters?: FiltersGroupInterface[];
  };
}

interface EditAlertParams extends AlertingParams {
  data: {
    name: string;
    input_values: any[];
    enabled: boolean;
    integration_uuid: string;
    managedFilter?: string;
    managedFilterApiIDs?: string;
    managed?: boolean;
    filterType?: string;
    managed_alert_uuid?: string;
    filters?: FiltersGroupInterface[];
  };
}

interface PreviewResponse {
  count: number;
  key: string;
}

interface ManagedFilter {
  UUID: string;
  description: string;
  filters: FiltersGroupInterface[];
  name: string;
}

export const alertingEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listAlerts: build.query<ListAlertingResponse, ListAlertingParams>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        sessionStorage.setItem('listAlertsArgs', JSON.stringify(args));
        const { orgID, query, pageSize, marker, alertType } = args;

        const resource = 'alert';
        const params: string[] = [`resource=${resource}`];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);
        const url = `/organisations/${orgID}/search?${params.join('&')}`;

        const filters: FieldsData[] = [];
        if (alertType && alertType !== 'all') {
          filters.push({ field: 'alert_type', values: [alertType] });
        }

        const alertsResult = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            filters,
            search_value: query,
            resource,
            sort: { order: 'desc' },
          },
        });

        const { alert, total } = alertsResult.data as {
          alert: AlertInterface[];
          total: number;
        };

        return {
          data: {
            alerts: alert.map((alert) => new AlertClass(alert)),
            total,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.alerts.map(({ UUID }) => ({
                type: 'Alerts' as const,
                id: UUID,
              })),
              { type: 'Alerts', id: 'LIST' },
            ]
          : [{ type: 'Alerts', id: 'LIST' }],
    }),
    listManagedAlerts: build.query<ManagedFilter[], ListAlertingParams>({
      query: ({ orgID }) => ({
        url: `/organisations/${orgID}/alerts/managed`,
      }),
      transformResponse: (rawResult: { 'managed-alerts': ManagedFilter[] }) => {
        return rawResult['managed-alerts'];
      },
    }),
    getAlert: build.query<AlertClass, AlertingParams>({
      async queryFn({ orgID, alertID }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const alertResult = await fetchWithBQ(
            `/organisations/${orgID}/alerts/${alertID}`
          );
          const { alert } = alertResult.data as {
            alert: AlertInterface;
          };

          return {
            data: new AlertClass(alert),
          };
        } catch (e) {
          return {
            error: 'An error occurred while fetching the alert.',
          };
        }
      },
      providesTags: (result, error, { alertID }) => [
        { type: 'Alerts', id: alertID },
      ],
    }),
    addAlert: build.mutation<AlertClass, AddAlertParams>({
      async queryFn({ orgID, data }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          if (data.managedFilter) {
            const filtersToAdd = [];

            if (
              data.managedFilterApiIDs &&
              data.managedFilterApiIDs.length > 0 &&
              data.filterType === 'managed'
            ) {
              filtersToAdd.push({
                groups: [
                  {
                    field: 'apiUUID',
                    operator: 'is-one-of' as ConditionOperatorType,
                    values: [...data.managedFilterApiIDs],
                  },
                ],
                operator: 'include' as FilterGroupOperatorType,
              });
            }

            data.managed_alert_uuid = data.managedFilter;
            data.managed = true;
            data.filters = filtersToAdd;
          }
          if (!data.managedFilter) {
            data.managed = false;
            data.managed_alert_uuid = undefined;
          }

          delete data.managedFilter;
          delete data.managedFilterApiIDs;
          delete data.filterType;

          const postResponse = await fetchWithBQ({
            url: `/organisations/${orgID}/alerts`,
            method: 'POST',
            data,
          });

          const response = postResponse.data as { alert: AlertInterface };
          return { data: new AlertClass(response.alert) };
        } catch (error) {
          return { error: { status: 'FETCH_ERROR', error: error } };
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const listAlertsArgs = JSON.parse(
            sessionStorage.getItem('listAlertsArgs') || '{}'
          );

          dispatch(
            alertingEndpoints.util.updateQueryData(
              'listAlerts',
              listAlertsArgs,
              (draft) =>
                mockListAlertAfterAdition({
                  draft,
                  alert: response.data,
                })
            )
          );

          if (response.data.enabled) {
            if (response.data.config.type === 'static') {
              dispatch(
                staticAlertingQuotaEndpoint.util.updateQueryData(
                  'static_alerting',
                  { orgID: arg.orgID },
                  (draft) => mockIncrementQuota(draft)
                )
              );
            }

            if (response.data.config.type === 'anomaly') {
              dispatch(
                anomalyAlertingQuotaEndpoint.util.updateQueryData(
                  'anomaly_alerting',
                  { orgID: arg.orgID },
                  (draft) => mockIncrementQuota(draft)
                )
              );
            }
          }

          setTimeout(() => {
            dispatch(
              alertingEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'Alerts', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    editAlert: build.mutation<AlertClass, EditAlertParams>({
      async queryFn(
        { orgID, alertID, data },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        try {
          if (data.managedFilter) {
            const filtersToAdd = [];

            if (
              data.managedFilterApiIDs &&
              data.managedFilterApiIDs.length > 0 &&
              data.filterType === 'managed'
            ) {
              filtersToAdd.push({
                groups: [
                  {
                    field: 'apiUUID',
                    operator: 'is-one-of' as ConditionOperatorType,
                    values: [...data.managedFilterApiIDs],
                  },
                ],
                operator: 'include' as FilterGroupOperatorType,
              });
            }

            data.managed_alert_uuid = data.managedFilter;
            data.managed = true;
            data.filters = filtersToAdd;
          }
          if (!data.managedFilter) {
            data.managed = false;
            data.managed_alert_uuid = undefined;
          }

          delete data.managedFilter;
          delete data.managedFilterApiIDs;
          delete data.filterType;

          const editResponse = await fetchWithBQ({
            url: `/organisations/${orgID}/alerts/${alertID}`,
            method: 'PUT',
            data,
          });

          const response = editResponse.data as { alert: AlertInterface };
          return { data: new AlertClass(response.alert) };
        } catch (error) {
          return { error: { status: 'FETCH_ERROR', error: error } };
        }
      },
      async onQueryStarted({ orgID, alertID }, { dispatch, queryFulfilled }) {
        dispatch(setLoadingState({ endpointId: 'getAlert', isLoading: true }));
        try {
          const response = await queryFulfilled;
          const listAlertsArgs = JSON.parse(
            sessionStorage.getItem('listAlertsArgs') || '{}'
          );

          dispatch(
            alertingEndpoints.util.updateQueryData(
              'listAlerts',
              listAlertsArgs,
              (draft) =>
                mockListAlertAfterEdition({
                  alert: response.data,
                  draft,
                })
            )
          );

          dispatch(
            alertingEndpoints.util.updateQueryData(
              'getAlert',
              { orgID, alertID },
              () =>
                mockGetAlertAfterEdition({
                  alert: response.data,
                })
            )
          );
        } catch (error) {
          console.error('Failed to process alert:', error);
        } finally {
          setTimeout(() => {
            dispatch(
              alertingEndpoints.util.invalidateTags([
                { type: 'Alerts', id: alertID },
                'Quotas',
              ])
            );
            dispatch(
              setLoadingState({ endpointId: 'getAlert', isLoading: false })
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        }
      },
    }),
    deleteAlert: build.mutation<void, AlertingParams>({
      query: ({ orgID, alertID }) => ({
        url: `/organisations/${orgID}/alerts/${alertID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ alertID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          const listAlertsArgs = JSON.parse(
            sessionStorage.getItem('listAlertsArgs') || '{}'
          );

          dispatch(
            alertingEndpoints.util.updateQueryData(
              'listAlerts',
              listAlertsArgs,
              (draft) =>
                mockListAlertAfterDeletion({
                  draft,
                  alertID,
                })
            )
          );

          setTimeout(() => {
            dispatch(
              alertingEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'Alerts', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    getAlertPreview: build.query<LineChartType, AlertPreviewParams>({
      query(data) {
        const { orgID, filters, period, metricName, metricStat } = data;
        return {
          url: `/organisations/${orgID}/alerts/preview`,
          method: 'POST',
          data: {
            filters,
            config: {
              period,
              metric_name: metricName,
              metric_stat: metricStat,
            },
          },
        };
      },
      transformResponse: (rawResult: { data: Array<PreviewResponse> }) => {
        const dataPoints: DataPointsHistographType[] = rawResult.data.map(
          (point) => ({
            date: point.key,
            value: point.count,
          })
        );
        return { 'y-axis': '# of requests', data: dataPoints };
      },
    }),
    getAnomalyAlertPreview: build.query<
      AnomalyPreviewType,
      AnomalyAlertPreviewParams
    >({
      query(data) {
        const { orgID, filters, period, alertID, sensitivity } = data;
        return {
          url: `/organisations/${orgID}/alerts/${alertID}/preview`,
          method: 'POST',
          data: {
            filters,
            config: {
              period,
              sensitivity,
            },
          },
        };
      },
    }),
  }),
});

export const {
  useListAlertsQuery,
  useLazyListAlertsQuery,
  useGetAlertQuery,
  useLazyGetAlertQuery,
  useEditAlertMutation,
  useAddAlertMutation,
  useDeleteAlertMutation,
  useGetAlertPreviewQuery,
  useGetAnomalyAlertPreviewQuery,
  useLazyGetAnomalyAlertPreviewQuery,
  useListManagedAlertsQuery,
} = alertingEndpoints;
