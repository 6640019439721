const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <section className='bg-gray-100' style={{ minHeight: '93vh' }}>
      <div className='py-4 px-8'>{children}</div>
      <div className='py-12' />
    </section>
  );
};

export default Container;
