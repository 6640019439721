import FindingInspectorAlertMessage from './FindingInspectorAlertMessage';
import FindingInspectorCodeBlock from './FindingInspectorCodeBlock';
import FindingInspectorFooter from './FindingInspectorFooter';
import FindingInspectorHeader from './FindingInspectorHeader';
import FindingInspectorRoot from './FindingInspectorRoot';

const FindingInspector = {
  Root: FindingInspectorRoot,
  Header: FindingInspectorHeader,
  CodeBlock: FindingInspectorCodeBlock,
  AlertMessage: FindingInspectorAlertMessage,
  Footer: FindingInspectorFooter,
};

export default FindingInspector;
