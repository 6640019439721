import Fields from '@/components/Form/FieldComponents';
import { RequestClass } from '@/utils/models/Request';

const DashboardRequestDetailsRequest = ({ log }: { log: RequestClass }) => {
  if (!log?.request) return null;

  return (
    <div className='gap-3 flex flex-col'>
      <h1 className='font-semibold' style={{ fontSize: 20 }}>
        Request
      </h1>

      <Fields.CodeBlock
        field={{
          value: JSON.stringify(log?.request),
          language: 'json',
          inputType: 'code-block',
          name: 'request',
          attribute: 'request',
        }}
      />
    </div>
  );
};

export default DashboardRequestDetailsRequest;
