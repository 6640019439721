import { useCallback } from 'react';
import {
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const categoryDefault = withDefault(StringParam, 'all');

interface UseCategoryFilterQueryParamProps {
  updateType?: UrlUpdateType;
}

function useCategoryFilterQueryParam(props?: UseCategoryFilterQueryParamProps) {
  const updateType: UrlUpdateType = props?.updateType || 'push';

  const [{ category }, setQuery] = useQueryParams({
    category: categoryDefault,
  });

  const setCategory = useCallback(
    (newCategory: string) => {
      setQuery({ category: newCategory }, updateType);
    },
    [setQuery, updateType]
  );

  return { category: category ?? 'all', setCategory };
}

export default useCategoryFilterQueryParam;
