import moment from 'moment';
import { ApiClass } from './Api';
import { SpecificationClass } from './Specification';

export type SeverityFindingType =
  | 'information'
  | 'low'
  | 'medium'
  | 'high'
  | 'critical';

export type SeveritiesFindingType = {
  CRITICAL?: number;
  HIGH?: number;
  MEDIUM?: number;
  LOW?: number;
  INFORMATIONAL?: number;
};

export type StatusFindingType =
  | 'Open'
  | 'Remediated'
  | 'Ignored'
  | 'Risk Accepted'
  | 'False Positive';

export type FieldFindingType =
  | 'UUID'
  | 'tags'
  | 'events'
  | 'code'
  | 'severity'
  | 'status'
  | 'apis'
  | 'collection_version_uuid';

export type SortType = { sort_field: string; sort_order: 'asc' | 'desc' };

export interface FindingReportInterface {
  code?: string;
  message?: string;
  path?: string[];
  security?: number;
  source?: string;
  range?: {
    start?: { line?: number; character?: number };
    end?: { line?: number; character?: number };
  };
  current_mean_average?: number;
  description?: string;
  previous_mean_average?: number;
  previous_variance?: number;
}

export type GenericObjectOptional = {
  [key: string]: string | string[] | undefined;
};

export interface FindingContextInterface extends GenericObjectOptional {
  collection_uuid?: string;
  collection_version_uuid: string;
  paths: string[];
  report_file: string;
}

type FindingItemType = 'finding' | 'observation' | 'detection';

export interface FindingInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  status: StatusFindingType;
  tags: string[];
  resources: string[];
  code: string;
  severity: SeverityFindingType;
  events: string[];
  context: FindingContextInterface;
  finding_orgUUID?: string;
  g_orgUUID: string;
  itemType: FindingItemType;
}

export interface FindingMappingInterface {
  code?: string;
  title: string;
  description: string;
  remediation: string;
  frameworks: any[];
}

type FindingExtraDataType = {
  mapping?: FindingMappingInterface;
  report?: FindingReportInterface;
  api?: ApiClass;
  specification?: SpecificationClass;
};

export class FindingClass implements FindingInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  status: StatusFindingType;
  tags: string[];
  resources: string[];
  code: string;
  severity: SeverityFindingType;
  events: string[];
  context: FindingContextInterface;
  finding_orgUUID?: string;
  g_orgUUID: string;

  originalData: FindingInterface;
  key: string;
  mapping?: FindingMappingInterface;
  report?: FindingReportInterface;
  api?: ApiClass;
  specification?: SpecificationClass;
  itemType: FindingItemType;

  constructor(data: FindingInterface, extraData?: FindingExtraDataType) {
    this.originalData = data;
    this.key = data.UUID;
    this.UUID = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.status = data.status;
    this.tags = data.tags;
    this.resources = data.resources;
    this.code = data.code;
    this.severity = data.severity;
    this.events = data.events;
    this.context = data.context;
    this.finding_orgUUID = data.finding_orgUUID;
    this.g_orgUUID = data.g_orgUUID;
    this.mapping = extraData?.mapping;
    this.report = extraData?.report;
    this.api = extraData?.api;
    this.specification = extraData?.specification;
    this.itemType = data.itemType;
  }

  get mapDataToDownload() {
    return {
      ID: this.UUID,
      Name: this.mapping?.title,
      Description: this.mapping?.description,
      Remediation: this.mapping?.remediation,
      Code: this.code,
      Context: this.context,
      'Date Added in Microseconds': this.dateAddedInMicroSeconds,
      'Creation Time': this.created,
      Tags: this.tags,
      Events: this.events,
      Severity: this.severity,
      Resources: this.resources,
      Status: this.status,
    };
  }

  get created(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }

  get severityColor() {
    return FindingClass.getSeverityColor(this.severity);
  }

  static getSeverityColor = (severity: SeverityFindingType) => {
    let color;
    switch (severity) {
      case 'information':
        color = '#027FFF';
        break;
      case 'low':
        color = '#2c7752';
        break;
      case 'medium':
        color = '#c4aa00';
        break;
      case 'high':
        color = '#FF9900';
        break;
      case 'critical':
        color = '#FF0000';
        break;
    }
    return color;
  };

  static getSeveritySecundaryColor = (severity: SeverityFindingType) => {
    let color;
    switch (severity) {
      case 'information':
        color = '#ddeeff';
        break;
      case 'low':
        color = '#dcffee';
        break;
      case 'medium':
        color = '#fff8cc';
        break;
      case 'high':
        color = '#ffeed5';
        break;
      case 'critical':
        color = '#ffecec';
        break;
    }
    return color;
  };
}
