import { Descriptions, PageHeader } from 'antd';
import Item from '../List/Item';
import { SpecificationVersionClass } from '@/utils/models/SpecificationVersion';

const SpecificationVersionDetails = ({
  specificationVersion,
  extra,
}: {
  specificationVersion?: SpecificationVersionClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {specificationVersion && (
        <PageHeader subTitle='Specification Version' extra={extra}>
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={specificationVersion.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {specificationVersion.created}
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default SpecificationVersionDetails;
