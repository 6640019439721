import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import { MdOutlineLaunch } from 'react-icons/md';
import {
  Alert,
  Button,
  Descriptions,
  Input,
  Modal,
  PageHeader,
  Spin,
  Tag,
} from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import members from './settings/members';
import { useState } from 'react';
import subscription from './settings/subscription';
import { getRegionFlagIcon } from '@/utils/regionFlags';
import {
  useDeleteOrganisationMutation,
  useGetOrganisationQuery,
} from '@/utils/services/organisation/organisation-endpoints';
import { deletedOrganisation } from '@/components/Navbar/navSlice';
import Container from '@/components/Section/Container';
import List from '@/components/List';
import { ListTabType } from '@/components/List/ListTabs';
import useRole from '@/utils/hooks/useRole';
import { useLazyCreatePortalSessionQuery } from '@/utils/services/billing/billing-endpoints';
import Quotas from './settings/quotas';

const Settings = () => {
  const {
    hasPermissionToListMember,
    hasPermissionToUpdateBillingPlan,
    hasPermissionToUpdateBillingDetails,
  } = useRole();
  const { orgID } = useParams<{
    orgID: string;
  }>();
  const dispatch = useDispatch();

  const { data: organisation, isLoading: getOrganisationIsLoading } =
    useGetOrganisationQuery({ orgID });

  const [deleteOrganisation, { isLoading: deleteOrganisationIsLoading }] =
    useDeleteOrganisationMutation();

  const [
    createPortalSession,
    {
      isLoading: isCreatePortalSessionLoading,
      isFetching: isCreatePortalSessionFetching,
    },
  ] = useLazyCreatePortalSessionQuery();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [orgNameInput, setOrgNameInput] = useState<string>('');
  const history = useHistory();

  const handleCreatePortalSession = () => {
    createPortalSession({ orgID })
      .unwrap()
      .then((url) => window.open(url, '_blank'));
  };

  const handleDeleteOrganisation = async () => {
    const response = await deleteOrganisation({ orgID }).unwrap();
    if (response.success) {
      dispatch(deletedOrganisation(orgID));
      history.push(`/organisations`);
    }
  };

  const handleOnCancel = () => {
    setOrgNameInput('');
    setDeleteModalIsOpen(false);
  };

  const tabs: ListTabType[] = [];
  if (hasPermissionToListMember) {
    tabs.push({ label: 'Members', key: 'members' });
  }
  if (hasPermissionToUpdateBillingPlan) {
    tabs.push({ label: 'Subscription', key: 'subscription' });
  }
  if (hasPermissionToUpdateBillingDetails) {
    tabs.push({
      label: (
        <div className='flex items-center gap-1'>
          <h3>Billing</h3>
          <MdOutlineLaunch size={14} />
        </div>
      ),
      key: 'billing',
    });
  }
  tabs.push({ label: 'Quotas', key: 'quotas' });

  return (
    <Container>
      <Modal
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        footer={null}
        title='Are you absolutely sure?'
        open={deleteModalIsOpen}
        onCancel={handleOnCancel}
      >
        <Alert
          message='Unexpected bad things will happen if you don’t read this!'
          type='warning'
          className='mb-4'
        />
        <p>
          This action cannot be undone. This will permanently delete the{` `}
          <b>{organisation?.name}</b>
          {` `}organization, applications, apis, integrations, alerts, tokens
          and remove all collaborator associations.
        </p>
        <p>
          Please type{` `}
          <b>{organisation?.name}</b>
          {` `}to confirm.
        </p>
        <Input
          value={orgNameInput}
          onChange={(evt) => setOrgNameInput(evt.target.value)}
        />
        <Button
          danger
          className='w-full mt-3'
          disabled={orgNameInput !== organisation?.name}
          onClick={handleDeleteOrganisation}
          loading={deleteOrganisationIsLoading}
        >
          I understand the consequences, delete this organisation
        </Button>
      </Modal>

      <Spin spinning={getOrganisationIsLoading}>
        <div className='p-1 bg-white mb-4 border'>
          <PageHeader
            ghost={false}
            title={organisation?.name}
            subTitle='Organization Settings'
            onBack={() => window.history.back()}
            tags={
              <Tag
                color={organisation?.status === 'active' ? 'green' : 'yellow'}
                className='uppercase'
              >
                {organisation?.status}
              </Tag>
            }
          >
            <Descriptions size='small' column={{ xs: 1, sm: 2, md: 3 }}>
              <Descriptions.Item label='UUID'>
                {organisation?.UUID}
              </Descriptions.Item>
              {organisation?.dateAddedInMicroSeconds && (
                <Descriptions.Item label='Creation Time'>
                  {moment(organisation.dateAddedInMicroSeconds / 1000).format(
                    'lll'
                  )}
                </Descriptions.Item>
              )}
              <Descriptions.Item label='Owner'>
                {organisation?.orgAdmin}
              </Descriptions.Item>
              {organisation?.region && (
                <Descriptions.Item label='Region'>
                  <div className='flex items-center'>
                    <span className='pr-1'>{organisation?.region}</span>
                    {getRegionFlagIcon(organisation?.region, '', 4)}
                  </div>
                </Descriptions.Item>
              )}
            </Descriptions>
          </PageHeader>
        </div>
      </Spin>

      <Spin
        spinning={isCreatePortalSessionFetching || isCreatePortalSessionLoading}
      >
        <List.Tabs
          onChange={(key) => {
            if (key === 'billing') {
              handleCreatePortalSession();
            } else {
              history.replace(`/organisations/${orgID}/settings/${key}`);
            }
          }}
          tabs={tabs}
        />
        <Switch>
          {hasPermissionToListMember && (
            <Route
              path='/organisations/:orgID/settings/members'
              component={members}
            />
          )}
          {hasPermissionToUpdateBillingPlan && (
            <Route
              path='/organisations/:orgID/settings/subscription'
              component={subscription}
            />
          )}
          <Route
            path='/organisations/:orgID/settings/quotas'
            component={Quotas}
          />
          {hasPermissionToListMember && (
            <Redirect
              from='/organisations/:orgID/settings'
              to='/organisations/:orgID/settings/members'
            />
          )}
        </Switch>
      </Spin>
    </Container>
  );
};

export default Settings;
