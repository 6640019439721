import Alerts from '@/components/Alerts';
import { AlertClass } from '@/utils/models/Alert';
import { EventContextClass } from '@/utils/models/EventContext';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const AlertEventContext = ({ context }: { context: EventContextClass }) => {
  const history = useHistory();

  const extraButtons = (alert: AlertClass) => {
    return [
      <Button
        key='alert'
        onClick={() => {
          history.push(
            `/organisations/${alert.alert_org_uuid}/monitor-alerting/${alert.UUID}`
          );
        }}
      >
        {alert.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const alert = new AlertClass(context.created);
    content = <Alerts.Details alert={alert} extra={extraButtons(alert)} />;
  }

  if (context.isDeleted) {
    content = <Alerts.Details alert={new AlertClass(context.before)} />;
  }

  if (context.isChanged) {
    const alert = new AlertClass(context.after);
    content = <Alerts.Details alert={alert} extra={extraButtons(alert)} />;
  }

  return <div className='-mx-6'>{content}</div>;
};

export default AlertEventContext;
