import { store } from '@/app/store';
import { RequestsLogsPagination, requestEndpoints } from './request-endpoints';
import { getQuotasForLoggingDuration } from '../quota/quota-export-data';
import { FiltersGroupInterface } from '@/utils/models/Filter';
import { OrgDashboardParams } from '../org/org-endpoints';
import { DynamicDateTime } from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';

export const listRequestsLogs = async (params: {
  orgID: string;
  filters: FiltersGroupInterface[];
  dateTime?: DynamicDateTime;
}) => {
  if (params?.dateTime === undefined || params?.dateTime?.value === undefined) {
    const loggingDuration = await getQuotasForLoggingDuration(params.orgID);
    const retentionValue = loggingDuration.retention_days * 24 * 60 * 60;
    params.dateTime = { value: retentionValue };
  }

  const data: OrgDashboardParams = {
    orgID: params.orgID,
    filters: params.filters,
    dateTime: params.dateTime,
  };

  const result = await store.dispatch<any>(
    requestEndpoints.endpoints.listRequestsLogs.initiate(data)
  );
  return result.data as RequestsLogsPagination;
};
