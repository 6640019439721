import FieldType from '@/utils/models/FieldType';
import Editor from '@monaco-editor/react';
import { Form, FormInstance, Modal } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState } from 'react';
import { byteSize, getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const CodeEditorField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance?: FormInstance<any>;
}) => {
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  let content = field.value || field.code;
  const required = {
    required: field?.validation?.required === true,
    message: '${label} is required',
  };
  const [rules, setRules] = useState<Rule[]>([required]);

  const handleFormValidations = (errors: string[]) => {
    const isValid = errors.length === 0;
    formInstance?.setFields([
      {
        name: field.attribute,
        errors,
      },
    ]);
    setRules(
      isValid
        ? [required]
        : [
            {
              validator: () => {
                return Promise.reject(errors.map((error) => new Error(error)));
              },
            },
            required,
          ]
    );
    setIsCodeValid(isValid);
  };

  const validateMaxSize = (value?: string) => {
    if (!value) return;
    let errors: string[] = [];
    const currentSize = byteSize(value);
    if (field.validation?.maxSize && currentSize > field.validation.maxSize) {
      errors = [
        `Maximum supported size is ${
          field.validation.maxSize / 1000
        } KB. Current size is ${Math.round(currentSize / 1000)} KB.`,
      ];
    }
    handleFormValidations(errors);
  };

  try {
    if (field.language === 'json' && field.value) {
      content = JSON.stringify(JSON.parse(field.value), null, 4);
    }

    if (field.readOnly) {
      const options = { readOnly: true, minimap: { enabled: false } };
      return (
        <Editor
          options={options}
          height='30vh'
          defaultLanguage={field.language}
          defaultValue={content}
          theme='Active4D'
          className='my-2 py-2'
        />
      );
    }

    return (
      <Form.Item
        required={field?.validation?.required}
        key={field.attribute}
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'extralarge')}
      >
        <div
          className={`${
            !isCodeValid ? 'border-[1px] border-red-500' : 'border'
          } ${field.disabled ? 'opacity-70' : ''}`}
        >
          <Editor
            options={{ readOnly: field.disabled }}
            onChange={(value) => {
              validateMaxSize(value);

              if (formInstance) {
                formInstance.setFieldValue(field.attribute, value);
              }
            }}
            onValidate={(markers) => {
              let errors: string[] = [];
              if (markers.length > 0) {
                errors = Array.from(
                  new Set(
                    markers.map(
                      (m) =>
                        `Line: ${
                          m.startLineNumber === m.endLineNumber
                            ? m.startLineNumber
                            : `${m.startLineNumber} - ${m.endLineNumber}`
                        }, Column: ${
                          m.startColumn === m.endColumn
                            ? m.startColumn
                            : `${m.startColumn} - ${m.endColumn}`
                        }, Error: ${m.message}`
                    )
                  )
                );
              }
              handleFormValidations(errors);
            }}
            height='45vh'
            defaultLanguage={field.language}
            defaultValue={content}
            theme='Active4D'
          />
        </div>
      </Form.Item>
    );
  } catch (err: any) {
    if (err.message) {
      Modal.error({ title: err.message });
    }
    return <></>;
  }
};
export default CodeEditorField;
