import './navbar.css';
import { BsGithub } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
  clearCurrentOrganisation,
  NavbarTabItem,
  selectActiveTabNavbar,
  selectCurrentOrgNavbar,
  selectSelectedOrgsNavbar,
  selectTabItemsNavbar,
  setTabNavbarItems,
} from '@/components/Navbar/navSlice';
import { SummaryOrg } from '@/storage/OrganisationStorage';
import Tabs from '../DataDisplay/Tabs';
import LinkNavbar from './LinkNavbar';
import { MenuProps } from 'antd';
import ButtonNavbar from './ButtonNavbar';
import Dropdown from '../DataDisplay/Dropdown';
import { MdHelpOutline } from 'react-icons/md';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';

const logoWithName = require('../../assets/images/logo-with-name-white.png');
const logo = require('../../assets/images/logo-white.png');

const TopNavbar = () => {
  const navigation = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loginWithRedirect,
    logoutWithRedirect,
    isAuthenticated,
    user: profile,
  } = useAuth();
  const selectedOrgs: SummaryOrg[] = useSelector(selectSelectedOrgsNavbar);
  const organisation = useSelector(selectCurrentOrgNavbar);
  const tabItems: NavbarTabItem[] = useSelector(selectTabItemsNavbar);
  const activeTabKey: string | undefined = useSelector(selectActiveTabNavbar);
  const [menuDropdown, setMenuDropdown] = React.useState<MenuProps>();

  useEffect(() => {
    initMenuDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgs]);

  const initMenuDropdown = () => {
    if (!organisation) return;

    const menuDropdownItems: MenuProps['items'] = [];
    if (selectedOrgs.length > 1) {
      const recent = structuredClone(selectedOrgs);
      menuDropdownItems?.push({
        key: 'recent-organisations',
        type: 'group',
        label: 'Recent Organizations',
        children: recent.splice(1).map((org) => ({
          label: org.name,
          key: `/organisations/${org.UUID}`,
        })),
      });
    }

    menuDropdownItems?.push({
      key: 'view-all-organisations',
      label: 'View all organizations',
    });

    menuDropdownItems?.push({
      key: `/organisations/${organisation.UUID}/settings`,
      label: 'Manage settings',
    });

    menuDropdownItems?.push({
      key: 'log-out',
      label: 'Log out',
    });

    setMenuDropdown({
      items: menuDropdownItems,
      onClick: menuDropdownItemOnClick,
    });
  };

  const menuDropdownItemOnClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'view-all-organisations':
        viewAllOrganisationsRedirect();
        break;
      case 'log-out':
        logoutWithRedirect();
        break;
      default:
        const router = key;
        navigation.push(router);
        break;
    }
  };

  const viewAllOrganisationsRedirect = () => {
    dispatch(setTabNavbarItems([]));
    dispatch(clearCurrentOrganisation());
    navigation.push(`/organisations`);
  };

  const loginButton =
    location.pathname === '/signup-from-aws-marketplace' ? (
      <></>
    ) : (
      <ButtonNavbar
        buttonID='login'
        onClick={loginWithRedirect}
        label='Log in'
      />
    );

  return (
    <header
      className='flex items-center gap-x-6 xl:gap-x-8 bg-first dark:text-anti-flash w-full 
      border-b-2 border-davys px-8 justify-between'
      style={{ height: '72px' }}
    >
      <div className='flex items-center gap-x-6 xl:gap-x-8 w-full overflow-x-auto xl:overflow-hidden'>
        <img
          src={logoWithName}
          onClick={() => navigation.push('/')}
          alt='logo-with-name-white'
          className='h-8 hover:cursor-pointer hidden xl:inline'
        />

        <img
          src={logo}
          onClick={() => navigation.push('/')}
          alt='logo-white'
          className='h-8 hover:cursor-pointer xl:hidden'
        />

        <Tabs
          style={{ height: '73px' }}
          activeKey={activeTabKey}
          onChange={(key) => {
            const selectedTab = tabItems.find(
              (m: NavbarTabItem) => m.key === key
            );
            if (selectedTab?.redirectTo)
              navigation.push(selectedTab.redirectTo);
          }}
          items={tabItems.map((item: NavbarTabItem) => ({
            key: item.key,
            label: item.label,
          }))}
        />
      </div>

      <div className='flex items-center gap-x-6 xl:gap-x-8 h-full'>
        <LinkNavbar href='https://www.firetail.io/docs'>
          <MdHelpOutline size={28} className='mr-2' /> Docs
        </LinkNavbar>

        <LinkNavbar href='https://github.com/FireTail-io'>
          <BsGithub size={24} />
        </LinkNavbar>

        {isAuthenticated ? (
          <>
            {organisation ? (
              <Dropdown
                dark
                menu={menuDropdown}
                dropdownRender={(menu) => (
                  <div className='select-navbar-dark'>{menu}</div>
                )}
                label={
                  <div
                    className='mr-2 flex flex-row'
                    style={{ minWidth: '200px', maxWidth: '200px' }}
                  >
                    <img
                      className='h-10 mr-2 rounded-full flex items-center justify-center'
                      src={profile?.picture}
                      alt='Profile'
                    />
                    <div className='flex flex-col min-w-0'>
                      <div className='whitespace-nowrap truncate text-start'>
                        {profile?.given_name
                          ? `${profile?.given_name} ${profile?.family_name}`
                          : profile?.nickname}
                      </div>
                      <div className='flex flex-row'>
                        <span className='mr-2 text-xs'>Organization:</span>
                        <span className='font-semibold text-xs truncate'>
                          {organisation.name}
                        </span>
                      </div>
                    </div>
                  </div>
                }
                labelClasses='text-first dark:text-anti-flash
                pl-4 h-full border-l border-davys text-base'
                labelStyle={{}}
              />
            ) : (
              <ButtonNavbar
                buttonID='logout'
                onClick={logoutWithRedirect}
                label='Log out'
              />
            )}
          </>
        ) : (
          loginButton
        )}
      </div>
    </header>
  );
};

export default TopNavbar;
