import { Drawer as DrawerAntd, Result, Skeleton, Table } from 'antd';
import { MdClose } from 'react-icons/md';
import { useLazyGetFindingQuery } from '@/utils/services/finding/finding-endpoints';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FindingOWASP from './FindingOWASP';
import FindingInspector from './FindingInspector';
import useHubspot from '@/utils/hooks/useHubspot';

const FindingDrawer = () => {
  const { addHubspotWidget, removeHubspotWidget } = useHubspot();
  const history = useHistory();
  const location = useLocation();
  const { orgID, findingID } = useParams<{
    orgID: string;
    findingID?: string;
  }>();
  const [
    getFinding,
    {
      currentData: finding,
      isLoading: isFindingLoading,
      isFetching: isFindingFetching,
      isError,
      isSuccess,
    },
  ] = useLazyGetFindingQuery();

  const loading = isFindingLoading || isFindingFetching;

  useEffect(() => {
    if (findingID) {
      getFinding({ orgID, findingID }, true);
    }
  }, [orgID, findingID, getFinding]);

  const handleClose = () => {
    const pathname = location.pathname.split('/');
    pathname.pop(); // remove id
    history.replace({ ...location, pathname: pathname.join('/') });
  };

  const renderKey = (key: string, isSubValue?: boolean) => {
    if (key.toLowerCase() === 'id') return 'ID';
    return isSubValue
      ? key
      : key
          .split('_')
          .map(
            (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
          )
          .join(' ');
  };

  const renderRecursiveValue = (value: any) => {
    if (value === null) {
      return null;
    } else if (Array.isArray(value)) {
      return (
        <ul style={{ listStyleType: 'none' }}>
          {value.map((item, index) => (
            <li className='ml-6 custom-bullets' key={index}>
              {renderRecursiveValue(item)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object') {
      return (
        <ul style={{ listStyleType: 'none' }}>
          {Object.entries(value).map(([key, subValue]) => {
            const renderedValue = renderRecursiveValue(subValue);
            return (
              renderedValue !== null && (
                <li key={key}>
                  <strong>{renderKey(key, true)}:</strong> {renderedValue}
                </li>
              )
            );
          })}
        </ul>
      );
    }
    return value;
  };

  const open = findingID !== undefined;
  const hasOWASP = !!finding?.mapping?.frameworks?.length;

  useEffect(() => {
    if (open) removeHubspotWidget();
    else {
      addHubspotWidget();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DrawerAntd
      width={'100%'}
      placement='right'
      onClose={handleClose}
      size='large'
      open={open}
      closable={false}
    >
      <div className='flex items-center mb-4 border-b pb-4 justify-between'>
        <div className='flex items-center gap-x-2'>
          <button className='focus:outline-none' onClick={handleClose}>
            <MdClose size={22} />
          </button>
          <span className='text-base'>Finding Details</span>
        </div>
      </div>

      {loading && (
        <div className='my-12'>
          <Skeleton active />
        </div>
      )}

      {!loading && isError && (
        <div className='pt-16' data-testid='show-unauthorized'>
          <Result status='error' title='Bad request' />
        </div>
      )}

      {!loading && isSuccess && (
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-4 lg:-mr-6 lg:-mb-6'>
          <div
            className={`${hasOWASP ? 'lg:col-span-9' : 'lg:col-span-12'} pb-4`}
          >
            {finding?.itemType === 'finding' && (
              <FindingInspector.Root
                key={finding?.UUID}
                finding={finding}
                loading={loading}
              >
                <FindingInspector.Header />
                <FindingInspector.CodeBlock />
                <FindingInspector.AlertMessage />
                <FindingInspector.Footer />
              </FindingInspector.Root>
            )}

            {finding?.itemType === 'observation' && (
              <>
                <FindingInspector.Root
                  key={finding?.UUID}
                  finding={finding}
                  loading={loading}
                >
                  <FindingInspector.Header />
                  <FindingInspector.Footer />
                </FindingInspector.Root>
                <div className='flex flex-col gap-4'>
                  <div className='border rounded-md bg-white pl-8 pr-3 py-6 my-3'>
                    <div
                      className='font-bold text-xl mb-4 border-b-2'
                      style={{ borderColor: '#F28606' }}
                    >
                      Context
                    </div>
                    {finding.context && (
                      <Table
                        dataSource={Object.entries(finding.context).reduce(
                          (
                            acc: {
                              key: string;
                              property: string;
                              value: any;
                            }[],
                            [key, value]
                          ) => {
                            if (value != null) {
                              acc.push({
                                key,
                                property: renderKey(key),
                                value: renderRecursiveValue(value),
                              });
                            }
                            return acc;
                          },
                          []
                        )}
                        columns={[
                          {
                            title: '',
                            dataIndex: 'property',
                            key: 'property',
                            render: (text) => <strong>{text}</strong>,
                            width: '25%',
                          },
                          {
                            title: '',
                            dataIndex: 'value',
                            key: 'value',
                            width: '50%',
                          },
                        ]}
                        showHeader={false}
                        pagination={false}
                        bordered
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {finding?.itemType === 'detection' && (
              <FindingInspector.Root
                key={finding?.UUID}
                finding={finding}
                loading={loading}
              >
                <FindingInspector.Header />
                <FindingInspector.AlertMessage noWrap />
                <FindingInspector.Footer />
              </FindingInspector.Root>
            )}
          </div>

          {hasOWASP && (
            <div className='lg:col-span-3'>
              <FindingOWASP key={finding?.UUID} finding={finding} />
            </div>
          )}
        </div>
      )}
    </DrawerAntd>
  );
};

export default FindingDrawer;
