import Incidents from '@/components/Incidents';
import { EventContextClass } from '@/utils/models/EventContext';
import { IncidentClass } from '@/utils/models/Incident';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const IncidentEventContext = ({ context }: { context: EventContextClass }) => {
  const history = useHistory();

  const extraButtons = (incident: IncidentClass) => {
    return [
      <Button
        key='incident'
        onClick={() => {
          history.push(
            `/organisations/${incident.incident_orgUUID}/posture-management/incidents/${incident.UUID}`
          );
        }}
      >
        {incident.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const incident = new IncidentClass(context.created);
    content = (
      <Incidents.Details incident={incident} extra={extraButtons(incident)} />
    );
  }

  if (context.isDeleted) {
    content = (
      <Incidents.Details incident={new IncidentClass(context.before)} />
    );
  }

  if (context.isChanged) {
    const incident = new IncidentClass(context.after);
    content = (
      <Incidents.Details incident={incident} extra={extraButtons(incident)} />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default IncidentEventContext;
