import { ApplicationClass } from '@/utils/models/Application';
import { Descriptions, PageHeader } from 'antd';
import Item from '../List/Item';

const ApplicationDetails = ({
  application,
  extra,
}: {
  application?: ApplicationClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {application && (
        <PageHeader
          subTitle='Application'
          title={application.name}
          extra={extra}
        >
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={application.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Created by'>
              {application.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {application.created}
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{application.status}</span>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default ApplicationDetails;
