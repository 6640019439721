import { useCallback, useMemo } from 'react';
import useQuery from './useQuery';
import { STATE_VARIABLES_KEY } from '@/utils/constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export type StateVariableType = {
  attribute: string;
  key: string;
  value?: string;
};

function useFormsVariables() {
  const { orgID } = useParams<{ orgID: string }>();

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const stateVariablesQuery = query.get(STATE_VARIABLES_KEY);

  const stateVariables: StateVariableType[] = useMemo(() => {
    try {
      if (stateVariablesQuery) return JSON.parse(stateVariablesQuery);
    } catch (err) {
      console.error(err);
    }

    return [];
  }, [stateVariablesQuery]);

  const requiredAttributes = useMemo(() => {
    const attributes = new Set(stateVariables.map((v) => v.attribute));

    return Array.from(attributes);
  }, [stateVariables]);

  const setStateVariables = useCallback(
    (variables: StateVariableType[]) => {
      const copyStateVariables = [...stateVariables];
      variables.forEach((variable) => {
        const variableIndex = copyStateVariables.findIndex(
          (v) => v.key === variable.key
        );

        if (variableIndex > -1) copyStateVariables[variableIndex] = variable;
        else copyStateVariables.push(variable);
      });

      query.set(STATE_VARIABLES_KEY, JSON.stringify(copyStateVariables));

      history.replace({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
    [history, location.pathname, query, stateVariables]
  );

  const replaceValues = useCallback(
    (
      originalValue: string,
      formVariables: StateVariableType[] = stateVariables
    ) => {
      let value = originalValue;

      for (const variable of formVariables) {
        if (!variable.value) continue;

        value = value.replace(
          new RegExp('\\${' + variable.key + '}', 'g'),
          variable.value
        );

        value = value.replace(
          new RegExp('\\{' + variable.key + '}', 'g'),
          variable.value
        );
      }

      value = value.replace(new RegExp('{org_uuid}', 'g'), orgID);

      return value;
    },
    [orgID, stateVariables]
  );

  return {
    stateVariables,
    setStateVariables,
    replaceValues,
    requiredAttributes,
  };
}

export default useFormsVariables;
