import { useCallback, useMemo, useState } from 'react';
import { Button, Divider, Form, Modal, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import {
  FieldByResource,
  ResourceType,
  useLazyAutocompleteFilterOptionsQuery,
  useListFieldsByResourceQuery,
} from '@/utils/services/resource/resource-endpoints';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import DebounceSelectField, {
  ValueType,
} from '@/components/Form/DebounceSelectField';
import ListFilterButton from './ListFilterButton';
import ListFilterSelectField from './ListFilterSelectField';
import { MdClose } from 'react-icons/md';
import ListFilterDateTimeSelector from './ListFilterDateTimeSelector';
import { FieldsData } from '@/utils/models/FieldType';
import useStaticDateTimeQueryParam, {
  StaticDateTime,
} from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';

interface ListFilterProps {
  resource: ResourceType;
  showDateTimeSelector?: boolean;
  onAdd?: (filters: { [key: string]: string[] }) => void;
  prevDef?: boolean;
  manuallyAddedFilters?: FieldsData[];
  useResourceTags?: boolean;
  renderOptionLabel?: (options: ValueType[]) => JSX.Element[];
  renderTags?: boolean;
  isLinkedRepos?: boolean;
}

const ListFilter = ({
  resource,
  showDateTimeSelector = false,
  onAdd,
  prevDef,
  manuallyAddedFilters,
  renderOptionLabel,
  renderTags,
  isLinkedRepos = false,
}: ListFilterProps) => {
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const { data: fieldsData, isLoading: isListFieldsLoading } =
    useListFieldsByResourceQuery({
      orgID,
      resource,
    });

  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedFields, setSelectedFields] = useState<FieldByResource[]>([]);
  const [type, setType] = useState<string | null>(null);
  const [autocompleteFilterOptions] = useLazyAutocompleteFilterOptionsQuery();
  const [dateTimeTemporary, setDateTimeTemporary] = useState<StaticDateTime>();

  const { dateTime, setDateTime } = useStaticDateTimeQueryParam();
  const { filters, setFilters } = useFiltersQueryParam();

  const resetFiltersResource = useCallback(() => {
    setFilters();
    setDateTime();
  }, [setFilters, setDateTime]);

  const counter = useMemo(() => {
    const filtersCounter = filters.reduce(
      (total, filter) =>
        total + (filter?.values?.length ? filter.values.length : 0),
      0
    );

    return dateTime && Object.keys(dateTime).length > 0
      ? filtersCounter + 1
      : filtersCounter;
  }, [filters, dateTime]);

  const disableConfirmButton =
    dateTimeTemporary?.mode === 'fixed' &&
    (!dateTimeTemporary.start || !dateTimeTemporary.end);

  const openModal = () => {
    handleFillFormValue();
    setOpen(true);
  };

  const handleFillFormValue = () => {
    const tempFields: FieldByResource[] = [];
    const formData: any = {};

    const filtersToLoop =
      manuallyAddedFilters && manuallyAddedFilters?.length > 0
        ? manuallyAddedFilters
        : filters;

    filtersToLoop.forEach((filter) => {
      const field = fieldsData?.find((f) => f.value === filter.field);
      if (field) {
        tempFields.push(field);
        formData[field.value] = filter.values;
      }
    });

    setSelectedFields(tempFields);
    form.setFieldsValue(formData);

    setDateTimeTemporary(dateTime);
  };

  const closeModal = () => {
    setOpen(false);
    clearFields();
  };

  const clearFields = () => {
    form.resetFields();
    setSelectedFields([]);
  };

  const clearFilter = () => {
    resetFiltersResource();
  };

  const confirmFilters = (values: any) => {
    setFilters(values);

    setDateTime(dateTimeTemporary);
    closeModal();
  };

  const onAddAndClose = (values: any) => {
    if (onAdd) {
      onAdd(values);
      closeModal();
    }
  };

  const handleFetchOptions = async (field: string, query: string) => {
    const options = await autocompleteFilterOptions(
      {
        orgID,
        resource,
        field,
        query,
        apiID,
        appID,
      },
      true
    ).unwrap();

    return options;
  };

  const addField = (newFieldValue: string) => {
    const field = fieldsData?.find((f) => f.value === newFieldValue);
    if (field) setSelectedFields((prev) => [...prev, field]);
  };

  const editField = (selectedField: FieldByResource, newFieldValue: string) => {
    form.setFieldValue(selectedField.value, undefined);
    const copy = [...selectedFields];
    const oldFieldIndexSelected = copy.findIndex(
      (f) => f.value === selectedField.value
    );
    const field = fieldsData?.find((f) => f.value === newFieldValue);
    if (field) copy[oldFieldIndexSelected] = field;
    setSelectedFields(copy);
  };

  const deleteField = (selectedField: FieldByResource) => {
    form.setFieldValue(selectedField.value, undefined);
    const copy = [...selectedFields];
    const oldFieldIndexSelected = copy.findIndex(
      (f) => f.value === selectedField.value
    );
    if (oldFieldIndexSelected > -1) {
      copy.splice(oldFieldIndexSelected, 1);
    }
    setSelectedFields(copy);
  };

  const availableFieldOptions =
    fieldsData?.filter(
      (f) => selectedFields.find((fs) => fs.value === f.value) === undefined
    ) || [];

  return (
    <>
      <Modal
        style={{ top: 15 }}
        maskClosable={false}
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        forceRender
        width={1000}
        okText='Confirm'
        open={open}
        title={
          <div className='flex items-center gap-x-4'>
            <span className='material-symbols-outlined text-lg'>tune</span>
            <span>Filters</span>
          </div>
        }
        onCancel={closeModal}
        footer={
          <div className='flex justify-between'>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={disableConfirmButton}
              type='primary'
              onClick={() => form.submit()}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <Spin spinning={isListFieldsLoading}>
          <div
            className='flex flex-col overflow-auto -m-6 p-6'
            style={{
              maxHeight: '78vh',
              paddingLeft: '7rem',
              paddingRight: '7rem',
            }}
          >
            <Form
              form={form}
              layout='horizontal'
              onFinish={onAdd ? onAddAndClose : confirmFilters}
              className={`flex flex-col gap-8 ${selectedFields.length > 0 ? 'mb-4' : ''}`}
            >
              {showDateTimeSelector && (
                <ListFilterDateTimeSelector
                  dateTime={dateTimeTemporary}
                  setDateTime={setDateTimeTemporary}
                />
              )}

              {selectedFields.map((field) => {
                const isAPI =
                  field.value === 'apiUUID' || field.value === 'apis';

                const isAPP =
                  field.value === 'appUUID' || field.value === 'apps';

                const allowClear = !((appID && isAPP) || (apiID && isAPI));

                return (
                  <div key={field.key} className='flex gap-x-4'>
                    <div className='flex gap-x-4 items-center'>
                      <div className='flex items-center gap-1 w-full'>
                        {allowClear && (
                          <button
                            onClick={() => deleteField(field)}
                            className='hover:bg-gray-400 hover:text-white 
                        transition-all rounded-full'
                          >
                            <MdClose size={18} />
                          </button>
                        )}

                        <ListFilterSelectField
                          disabled={isLinkedRepos ? false : !allowClear}
                          value={field.value}
                          onChange={(value) => editField(field, value)}
                          options={[field, ...availableFieldOptions].map(
                            (opt) => ({
                              label: opt.key,
                              value: opt.value,
                            })
                          )}
                        />
                      </div>

                      <span className='font-semibold whitespace-nowrap '>
                        is one of
                      </span>
                    </div>

                    <Form.Item
                      className='w-full col-span-2'
                      style={{ marginBottom: 0 }}
                      key={field.value}
                      name={field.value}
                    >
                      <DebounceSelectField
                        key={field.value}
                        showSearch
                        mode='multiple'
                        placeholder={`Select ${field.key}`}
                        fetchOptions={(value) =>
                          handleFetchOptions(field.value, value)
                        }
                        className='bg-green-900'
                        style={{ width: '100%' }}
                        allowClear={isLinkedRepos ? true : allowClear}
                        renderOptionLabel={renderOptionLabel}
                        renderTags={renderTags}
                      />
                    </Form.Item>
                  </div>
                );
              })}
            </Form>

            {availableFieldOptions.length > 0 && (
              <div>
                {(selectedFields.length > 0 || showDateTimeSelector) && (
                  <Divider />
                )}

                <div className='grid grid-cols-3 gap-x-4'>
                  <ListFilterSelectField
                    label='Select Field'
                    placeholder='Select field'
                    value={type}
                    onChange={(value) => {
                      addField(value);
                      setType(null);
                    }}
                    options={availableFieldOptions.map((opt) => ({
                      label: opt.key,
                      value: opt.value,
                    }))}
                  />
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      <ListFilterButton
        openModal={openModal}
        counter={counter}
        clearFilter={clearFilter}
        prevDef={prevDef}
      />
    </>
  );
};

export default ListFilter;
