import { useAppDispatch } from '@/app/hooks';
import {
  ConditionFormType,
  ConditionOperatorType,
  ConditionType,
  FilterClass,
  FilterGroupType,
} from '@/utils/models/Filter';
import { nanoid } from '@reduxjs/toolkit';
import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import {
  filterConditionUpdatedOrRemovedOne,
  filterSetted,
  filterUpdatedOne,
} from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import OperatorField from './OperatorField';
import SelectField from './SelectField';
import TextField from './TextField';
import TypeField from './TypeField';
import {
  AutocompleteFilterOptionsType,
  useLazyAutocompleteFilterOptionsQuery,
  useListLogFilterFieldsByResourceQuery,
} from '@/utils/services/resource/resource-endpoints';
import { useParams } from 'react-router-dom';
import BoolField from './BoolField';
import AddonAfterField from './AddonAfterField';
import NumberField from './NumberField';
import { Rule } from 'antd/lib/form';

const ConditionForm = ({
  selectedFilter,
  selectedCondition,
  formSubmited,
  excludeTypes = [],
  initForm,
}: {
  selectedFilter?: FilterGroupType;
  selectedCondition?: ConditionType;
  formSubmited: () => void;
  excludeTypes?: Array<string>;
  initForm?: boolean;
}) => {
  const { orgID } = useParams<{
    orgID: string;
  }>();
  const [form] = Form.useForm();
  const [filterSelected, setFilterSelected] = useState<FilterClass>();
  const [operatorSelected, setOperatorSelected] =
    useState<ConditionOperatorType>();
  const valueRules: Rule[] = getValueRulesByType();
  const dispatch = useAppDispatch();
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteFilterOptionsType[]
  >([]);
  const [addonAfterSelection, setAddonAfterSelection] = useState<string[]>([]);
  const [dynamicOption, setDynamicOption] = useState<string[]>([]);
  const [dynamicAutocompleteOptions, setDynamicAutocompleteOptions] = useState<
    AutocompleteFilterOptionsType[]
  >([]);
  const { data } = useListLogFilterFieldsByResourceQuery({ orgID });
  const [fetchAutocompleteFilterOptions, { isLoading }] =
    useLazyAutocompleteFilterOptionsQuery();

  const nonValueOperators: Array<ConditionOperatorType | undefined> = [
    'exist',
    'not-exist',
    'bool',
  ];

  useEffect(() => {
    if (filterSelected) {
      fetchAutocompleteFilterOptions({
        orgID,
        field: filterSelected.code,
        resource: 'log',
        query: '',
      })
        .unwrap()
        .then((opts) => setAutocompleteOptions(opts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, orgID]);

  useEffect(() => {
    if (
      filterSelected &&
      dynamicOption.length > 0 &&
      filterSelected.dynamic &&
      !nonValueOperators.includes(operatorSelected)
    ) {
      fetchAutocompleteFilterOptions({
        orgID,
        field: `${filterSelected.code}${dynamicOption[0]}`,
        resource: 'log',
        query: '',
      })
        .unwrap()
        .then((opts) => setDynamicAutocompleteOptions(opts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicOption, orgID]);

  useEffect(() => {
    initializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  useEffect(() => {
    if (filterSelected?.operators.length === 1) {
      setOperatorSelected(filterSelected?.operators[0]);
      form.setFieldsValue({ operator: filterSelected?.operators[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected]);

  useEffect(() => {
    form.setFieldsValue({ values: [], value: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addonAfterSelection]);

  const initializeForm = () => {
    if (!initForm) return;

    resetForms();

    if (selectedCondition) edit(selectedCondition);
  };

  const resetForms = () => {
    form.resetFields();
    setFilterSelected(undefined);
    setOperatorSelected(undefined);
  };

  const extractFieldAndAddonAfter = (fullField: string) => {
    const moreThanOnePeriod = (fullField.match(/\./g) || []).length > 1;

    if (moreThanOnePeriod) {
      const regex = /((?:.*\..+)\.)?([^.]+)$/;
      const match = fullField.match(regex);

      if (match) {
        const fieldBase = match[1];
        const addonAfter = match[2];
        return [fieldBase, addonAfter];
      }
    }

    return [fullField, ''];
  };

  const edit = (cond: ConditionType) => {
    const condition = formatConditionToEdit(cond);

    let fieldBase: string;
    let addonAfter: string;

    if (!condition.addonAfter || condition.addonAfter === '') {
      [fieldBase, addonAfter] = extractFieldAndAddonAfter(condition.field);
    } else {
      fieldBase = condition.field;
      addonAfter = condition.addonAfter;
    }

    const logFilterObject = data?.find(
      (filter) =>
        filter.code === fieldBase || filter.code === `${fieldBase}${addonAfter}`
    );

    if (logFilterObject) {
      const resolvedAddonAfter = addonAfter || condition.addonAfter;
      setFilterSelected(logFilterObject);
      form.setFieldsValue({
        ...condition,
        field: logFilterObject.code,
        addonAfter: addonAfter || condition.addonAfter,
      });
      setDynamicOption([resolvedAddonAfter || '']);
    } else {
      console.error(
        'No matching FilterClass found for the field base:',
        fieldBase
      );
    }

    setOperatorSelected(condition.operator);
  };

  const handleSetForm = () => {
    const typeValue: string | undefined = form.getFieldValue('field');
    if (!typeValue) return;

    const type: FilterClass | undefined = data?.find(
      (t) => t.code === typeValue
    );
    if (!type) return;

    if (operatorSelected && !type.operators.includes(operatorSelected)) {
      setOperatorSelected(undefined);
      form.resetFields(['operator']);
    }

    setFilterSelected(type);

    const operatorValue: ConditionOperatorType | undefined =
      form.getFieldValue('operator');
    if (!operatorValue) return;
    setOperatorSelected(operatorValue);
  };

  const resetFieldValue = () => form.resetFields(['value', 'values']);

  const resetAddonAfter = () => form.resetFields(['addonAfter']);

  const editExistentFilter = (
    selectedFilter: FilterGroupType,
    condition: ConditionType
  ) => {
    const editExistentCondition = (selectedCondition: ConditionType) =>
      dispatch(
        filterConditionUpdatedOrRemovedOne({
          selectedFilter,
          selectedCondition,
          conditionUpdated: condition,
        })
      );

    const createCondition = () =>
      dispatch(
        filterUpdatedOne({
          id: selectedFilter.id,
          changes: {
            groups: [...selectedFilter.groups, condition],
          },
        })
      );

    if (selectedCondition) editExistentCondition(selectedCondition);
    else createCondition();
  };

  const createFilterAndCondition = (condition: ConditionType) =>
    dispatch(
      filterSetted({
        id: nanoid(),
        groups: [condition],
        operator: 'include',
      })
    );

  const handleSaveCondition = (cond: ConditionFormType) => {
    if (cond.value !== undefined && typeof cond.value === 'number') {
      cond.value = String(cond.value);
    }

    const condition = formatConditionToSave(cond);

    if (selectedFilter) editExistentFilter(selectedFilter, condition);
    else createFilterAndCondition(condition);

    formSubmited();
  };

  const formatConditionToSave = (cond: ConditionFormType): ConditionType => {
    const condition: ConditionType = { ...cond };

    if (cond.addonAfter && !condition.field.includes(`.${cond.addonAfter}`)) {
      condition.field += `${cond.addonAfter}`;
    }

    if (!selectedCondition) {
      condition.id = nanoid();
    }

    return condition;
  };

  const formatConditionToEdit = (cond: ConditionType): ConditionFormType => {
    const condition: ConditionFormType = { ...cond };

    if (!selectedCondition) condition.id = nanoid();

    const [fieldBase, addonAfter] = extractFieldAndAddonAfter(condition.field);
    condition.field = fieldBase;
    if (addonAfter) {
      condition.addonAfter = addonAfter;
    }

    return condition;
  };

  const handleDeleteCondition = (
    selectedFilter: FilterGroupType,
    selectedCondition: ConditionType
  ) => {
    dispatch(
      filterConditionUpdatedOrRemovedOne({
        selectedFilter,
        selectedCondition,
      })
    );
    formSubmited();
  };

  return (
    <div className='-m-6 flex flex-col z-10'>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSaveCondition}
        requiredMark={false}
        className='overflow-auto p-6 grid grid-cols-6 gap-8 flex-grow w-full'
        style={{
          maxHeight: '70vh',
          paddingLeft: '7rem',
          paddingRight: '7rem',
        }}
      >
        <span className='col-span-6 text-[#262D35] text-2xl'>
          {selectedCondition ? 'Edit condition' : 'Add condition'}
        </span>
        <div className='col-span-3'>
          <TypeField
            excludeTypes={excludeTypes}
            onChange={(v) => {
              resetFieldValue();
              resetAddonAfter();
              handleSetForm();
              setDynamicOption([]);
              setDynamicAutocompleteOptions([]);
            }}
          />

          <OperatorField
            onChange={() => {
              handleSetForm();
            }}
            typeSelected={filterSelected}
          />
        </div>
        <div className='col-span-3'>
          <AddonAfterField
            type={filterSelected}
            options={autocompleteOptions}
            isOptionsLoading={isLoading}
            onSelectChange={(v) => setDynamicOption(v)}
            onSelectChangeCallback={(v) => setAddonAfterSelection(v)}
          />

          <TextField
            rules={valueRules}
            options={autocompleteOptions}
            type={filterSelected}
          />

          <BoolField rules={valueRules} type={filterSelected} />

          <NumberField
            rules={valueRules}
            operator={operatorSelected}
            type={filterSelected}
          />

          <SelectField
            rules={valueRules}
            type={filterSelected}
            operator={operatorSelected}
            getFormValues={() => form.getFieldValue('values') || []}
            setFormValues={(values) => form.setFieldsValue({ values })}
            autocompleteOptions={autocompleteOptions}
            isOptionsLoading={isLoading}
            dynamicAutocompleteOptions={dynamicAutocompleteOptions}
            addOnAfterValue={addonAfterSelection}
          />
        </div>
      </Form>

      <div
        className='border-t w-full'
        style={{
          borderColor: '#d9d9d9',
        }}
      />

      <div
        className='flex justify-between items-center py-5'
        style={{
          paddingLeft: '7rem',
          paddingRight: '7rem',
        }}
      >
        {selectedFilter && selectedCondition && (
          <Button
            key='delete'
            data-testid='delete-condition'
            danger
            onClick={() =>
              handleDeleteCondition(selectedFilter, selectedCondition)
            }
          >
            Delete
          </Button>
        )}
        <div className='w-full text-right'>
          <Button
            data-testid='save-filter'
            key='save'
            onClick={() => form.submit()}
            type='primary'
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const getValueRulesByType = (): Rule[] => {
  const defaultRules: Rule[] = [{ required: true, message: 'Required' }];
  return defaultRules;
};

export default ConditionForm;
