import { Table } from 'antd';
import { ListMode, selectListMode } from './listSlice';
import { useAppSelector } from '@/app/hooks';
import { ColumnsType } from 'antd/lib/table';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  ExpandableConfig,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

interface ListTableProps {
  columns: ColumnsType<any> | undefined;
  size?: SizeType;
  dataSource?: any[];
  loading?: boolean;
  ignoreMode?: boolean;
  onTableChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => void;
  expandable?: ExpandableConfig<any> | undefined;
}

const ListTable = ({
  columns,
  size,
  dataSource,
  loading,
  ignoreMode = false,
  onTableChange,
  expandable,
}: ListTableProps) => {
  const defaultMode: ListMode = useAppSelector(selectListMode);

  if (!ignoreMode && defaultMode !== 'table') {
    return <></>;
  }

  return (
    <Table
      scroll={{ x: 1000 }}
      columns={columns}
      pagination={false}
      size={size || 'large'}
      dataSource={dataSource}
      loading={loading}
      onChange={onTableChange}
      expandable={expandable}
    />
  );
};

export default ListTable;
