import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

interface BaseState {
  httpError: { title: string; content: string } | undefined;
}

const initialState: BaseState = {
  httpError: undefined,
};

const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    interceptAxiosError: (state, action: PayloadAction<AxiosError>) => {
      const axiosError: any = action.payload;
      let title = axiosError.title || 'Bad Request';
      let content = axiosError.message;

      if (typeof axiosError?.response?.data?.error?.status === 'string') {
        title = axiosError.response.data.error.status;
      }

      if (typeof axiosError?.response?.data?.message === 'string') {
        content = axiosError.response.data.message;
      } else if (
        typeof axiosError?.response?.data?.error?.message === 'string'
      ) {
        content = axiosError.response.data.error.message;
      }

      state.httpError = { title, content };
    },
    clearInterceptAxiosError: (state) => {
      state.httpError = undefined;
    },
  },
  extraReducers: (builder) => {},
});

export const { interceptAxiosError, clearInterceptAxiosError } =
  baseSlice.actions;

export const selectHttpError = (state: RootState) => state.base.httpError;

export default baseSlice.reducer;
