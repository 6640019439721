import { FilterClass } from '@/utils/models/Filter';
import { Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';

const BoolField = ({ type, rules }: { rules?: Rule[]; type?: FilterClass }) => {
  const isBoolMode = type && type.type === 'bool';
  if (isBoolMode)
    return (
      <Form.Item
        rules={rules}
        key='value'
        name='value'
        label={type.dynamic_key ?? 'Value'}
      >
        <Select>
          <Select.Option key='true' value='true' children='True' />
          <Select.Option key='false' value='false' children='False' />
        </Select>
      </Form.Item>
    );

  return <></>;
};

export default BoolField;
