import { Form } from 'antd';
import { FormInstance } from 'rc-field-form';
import { useDebounceValue } from 'usehooks-ts';

function useWatchWithDebounce<T>({
  formInstance,
  delay = 800,
  attribute,
}: {
  formInstance: FormInstance<any>;
  delay?: number;
  attribute: any;
}): T {
  const fieldValue = Form.useWatch(attribute, formInstance);

  let fieldValueAsString = '';
  if (fieldValue !== null && fieldValue !== undefined) {
    fieldValueAsString = fieldValue.toString();
  }

  const [debouncedValue] = useDebounceValue<string>(fieldValueAsString, delay);

  if (debouncedValue !== '' && typeof fieldValue === 'number') {
    const numberValue = parseFloat(debouncedValue);
    if (isNaN(numberValue)) {
      throw new Error('Conversion to number failed.');
    }
    return numberValue as T;
  }

  return fieldValue as T;
}

export default useWatchWithDebounce;
