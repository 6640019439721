import { counterFormat } from '@/utils/methods/displayFormat';
import { Tooltip } from 'antd';
import { MdOutlineInfo } from 'react-icons/md';

const Statitic = ({
  value,
  label,
  info,
}: {
  value: number | undefined;
  label: string;
  info?: string;
}) => {
  return (
    <div>
      <span
        className='font-semibold mb-0 text-anti-flash'
        style={{ fontSize: 32 }}
      >
        {counterFormat(value || 0)}
      </span>
      <div className='flex items-center'>
        <span className='font-normal text-anti-flash' style={{ fontSize: 14 }}>
          {label}
        </span>
        {info && (
          <Tooltip
            placement='bottom'
            className='cursor-pointer'
            title={<span className='text-first'>{info}</span>}
            color={'#F2F4F6'}
            trigger={['click']}
          >
            <MdOutlineInfo size={16} className='ml-1 text-anti-flash' />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Statitic;
