import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import { useEffect } from 'react';

const VerifiedByEmailPage = () => {
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullScreenLoader />;
};

export default VerifiedByEmailPage;
