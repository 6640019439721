import { PlanClass, PlanInterface } from '@/utils/models/Plan';
import baseApi from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';

export const billingEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listPlans: build.query<PlanClass[], OrgGenericParams>({
      query: ({ orgID }) => `/organisations/${orgID}/plans`,
      transformResponse: (rawResult: { plans: PlanInterface[] }) =>
        rawResult.plans.map((plan) => new PlanClass(plan)),
    }),
    createCheckoutSession: build.query<
      string,
      { orgID: string; planID: string; billingPeriod: string }
    >({
      query: ({ orgID, planID, billingPeriod }) =>
        `/organisations/${orgID}/billing/cart/${planID}?billing_period=${billingPeriod}`,
      transformResponse: (rawResult: { url: string }) => rawResult.url,
    }),
    createPortalSession: build.query<string, OrgGenericParams>({
      query: ({ orgID }) => `/organisations/${orgID}/billing/link`,
      transformResponse: (rawResult: { url: string }) => rawResult.url,
    }),
  }),
});

export const {
  useListPlansQuery,
  useLazyCreateCheckoutSessionQuery,
  useLazyCreatePortalSessionQuery,
} = billingEndpoints;
