const LIST_OF_HTTP_METHODS: {
  name: string;
  colour: string;
  description?: string;
}[] = [
  {
    colour: '#1E4FFF',
    description:
      'The GET method requests a representation of the specified resource. Requests using GET should only retrieve data.',
    name: 'GET',
  },
  {
    colour: '#D60EB6',
    description:
      'The HEAD method asks for a response identical to a GET request, but without the response body.',
    name: 'HEAD',
  },
  {
    colour: '#007822',
    description:
      'The POST method submits an entity to the specified resource, often causing a change in state or side effects on the server.',
    name: 'POST',
  },
  {
    colour: '#A7610E',
    description:
      'The PUT method replaces all current representations of the target resource with the request payload.',
    name: 'PUT',
  },
  {
    colour: '#D52600',
    description: 'The DELETE method deletes the specified resource.',
    name: 'DELETE',
  },
  {
    colour: '#6700CE',
    description:
      'The CONNECT method establishes a tunnel to the server identified by the target resource.',
    name: 'CONNECT',
  },
  {
    colour: '#064EA3',
    description:
      'The OPTIONS method describes the communication options for the target resource.',
    name: 'OPTIONS',
  },
  {
    colour: '#5e401c',
    description:
      'The TRACE method performs a message loop-back test along the path to the target resource.',
    name: 'TRACE',
  },
  {
    colour: '#11846C',
    description:
      'The PATCH method applies partial modifications to a resource.',
    name: 'PATCH',
  },
  {
    colour: '#871386',
    description:
      'The LINK method is used to establish one or more relationships between the resource identified by the effective request URI and one or more other resources.',
    name: 'LINK',
  },
  {
    colour: '#F99C2B',
    description:
      'The PURGE method in HTTP is used to clear cached content from servers and intermediaries, like CDNs, by requesting the removal of specific resources.',
    name: 'PURGE',
  },
];

export const LIST_OF_EXTRAS_HTTP_METHODS: {
  colour: string;
  name: string;
  key: string;
  description?: string;
}[] = [
  {
    colour: '#f28606',
    name: 'ANY',
    key: 'x-amazon-apigateway-any-method',
    description:
      'ANY method represents any HTTP method that is to be supplied at run time.',
  },
];

export const LIST_OF_ALL_HTTP_METHODS = [
  'get',
  'head',
  'post',
  'put',
  'delete',
  'connect',
  'options',
  'trace',
  'patch',
  'link',
  'purge',
  'any',
];

export default LIST_OF_HTTP_METHODS;
