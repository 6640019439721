import { ApiClass } from './Api';
import BaseEntityClass from './BaseEntity';
import { IntegrationAvailableClass } from './IntegrationAvailableInterface';

export type ActionIntegrationType = 'custom' | 'managed';

export type ActionType = 'api_schedule_action' | 'api_event_action' | undefined;

export interface ActionInterface {
  UUID: string;
  actionType: ActionType;
  actionVersion: string;
  action_apiUUID: string;
  action_appUUID: string;
  action_orgUUID: string;
  context: any;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  description: string;
  enabled: boolean;
  g_orgUUID: string;
  integrationType: ActionIntegrationType;
  integrationUUID: string;
  itemType: 'action';
  name: string;
  scheduleARN: string;
  scheduledRate?: number;
  eventCodes?: string[];
  status: string;
}

type ActionExtraDataType = {
  managedIntegration?: IntegrationAvailableClass;
  api?: ApiClass;
};

export class ActionClass extends BaseEntityClass {
  UUID: string;
  actionType: ActionType;
  actionVersion: string;
  action_apiUUID: string;
  action_appUUID: string;
  action_orgUUID: string;
  context: { [key in string]: any } | null;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  description: string;
  enabled: boolean;
  g_orgUUID: string;
  integrationType: string;
  integrationUUID: string;
  itemType: 'action';
  name: string;
  scheduleARN: string;
  scheduledRate?: number;
  eventCodes?: string[];
  status: string;

  managedIntegration?: IntegrationAvailableClass;
  api?: ApiClass;

  constructor(data: ActionInterface, extraData?: ActionExtraDataType) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });

    this.UUID = data.UUID;
    this.actionType = data.actionType;
    this.actionVersion = data.actionVersion;
    this.action_apiUUID = data.action_apiUUID;
    this.action_appUUID = data.action_appUUID;
    this.action_orgUUID = data.action_orgUUID;
    this.context = data.context;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.description = data.description;
    this.enabled = data.enabled;
    this.g_orgUUID = data.g_orgUUID;
    this.integrationType = data.integrationType;
    this.integrationUUID = data.integrationUUID;
    this.itemType = data.itemType;
    this.name = data.name;
    this.scheduleARN = data.scheduleARN;
    this.scheduledRate = data.scheduledRate;
    this.eventCodes = data.eventCodes;
    this.status = data.status;

    this.managedIntegration = extraData?.managedIntegration;
    this.api = extraData?.api;
  }

  get category() {
    return this.actionType === 'api_schedule_action' ? 'Schedule' : 'Event';
  }
}
