import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ListMode = 'grid' | 'table';

interface ListState {
  mode: ListMode;
}

const initialState: ListState = {
  mode: 'grid',
};

const listSlice = createSlice({
  name: 'list',
  initialState: initialState,
  reducers: {
    setListMode: (state, action: PayloadAction<ListMode>) => {
      state.mode = action.payload;
    },
  },
});

export const { setListMode } = listSlice.actions;

export const selectListMode = (state: RootState) => state.list.mode;

export default listSlice.reducer;
