import FieldType from '@/utils/models/FieldType';
import { Button, Form } from 'antd';
import { getColSize, getFieldSize } from './const/functions';
import { useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import {
  useEditSlackIntegrationMutation,
  useLazyGetSlackCallbackParamsQuery,
} from '@/utils/services/integration/slack-integration-endpoints';
import { VscError } from 'react-icons/vsc';
import {
  INTEGRATION_SLACK_SETUP_COMPLETE,
  INTEGRATION_SLACK_SETUP_ERROR,
} from '@/utils/constants';
import useRole from '@/utils/hooks/useRole';

/* eslint-disable no-template-curly-in-string */
const ConnectSlackField = ({ field }: { field: FieldType }) => {
  const { hasPermissionToUpdateIntegration } = useRole();

  const { orgID, myIntegrationID } = useParams<{
    orgID: string;
    myIntegrationID: string;
  }>();
  const [getSlackCallbackUri, { isLoading: isGetSlackCallbackLoading }] =
    useLazyGetSlackCallbackParamsQuery();

  const [webhookError, setWebhookError] = useState<
    { error: string; error_description?: string } | undefined
  >();

  const [editSlackIntegration] = useEditSlackIntegrationMutation();

  const handleConnect = async () => {
    const { callback_uri } = await getSlackCallbackUri({ orgID }).unwrap();

    const openURL = callback_uri;

    const newWindow = window.open(
      openURL,
      '_blank',
      'location=yes,height=720,width=1020,scrollbars=yes,status=yes'
    );

    window.addEventListener(
      'message',
      (event) => handleWindowMessages(event, newWindow),
      false
    );
  };

  const handleWindowMessages = async (
    event: MessageEvent<any>,
    newWindow: Window | null
  ) => {
    if (
      event.source === newWindow &&
      event.data.key === INTEGRATION_SLACK_SETUP_COMPLETE
    ) {
      newWindow?.close();

      const code = event.data.message;

      await editSlackIntegration({
        orgID,
        myIntegrationID,
        code,
      }).unwrap();

      window.location.reload();
    }

    if (
      event.source === newWindow &&
      event.data.key === INTEGRATION_SLACK_SETUP_ERROR
    ) {
      newWindow?.close();

      setWebhookError({
        error_description: event.data.message.error_description,
        error: event.data.message.error,
      });
    }
  };

  return (
    <div className={`${getColSize('extralarge')} flex gap-2`}>
      <Form.Item
        name={field.attribute}
        label={field.name}
        tooltip={field.description}
        className={`${getFieldSize('extralarge')}`}
      >
        <div className='flex gap-2 w-full items-center'>
          <Button
            disabled={!myIntegrationID || !hasPermissionToUpdateIntegration}
            loading={isGetSlackCallbackLoading}
            onClick={handleConnect}
          >
            Change connection
          </Button>

          {webhookError ? (
            <div className='flex gap-2 text-xs items-center text-gray-700'>
              <VscError size={20} color='red' />
              <div className='flex flex-col'>
                <span className='font-semibold text-red-400'>Error</span>
                {webhookError.error_description && (
                  <span className='text-red-400'>
                    {webhookError.error_description}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className='flex gap-1 text-xs items-center text-gray-700'>
              Connected
              <MdCheckCircle size={18} color='#1B222D' />
            </div>
          )}
        </div>
      </Form.Item>
    </div>
  );
};

export default ConnectSlackField;
