import { IncidentPolicyClass } from '@/utils/models/IncidentPolicy';
import { Descriptions, PageHeader } from 'antd';
import Item from '../List/Item';

const IncidentPolicyDetails = ({
  incidentPolicy,
  extra,
}: {
  incidentPolicy?: IncidentPolicyClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {incidentPolicy && (
        <PageHeader
          subTitle='Incident Policy'
          title={incidentPolicy.name}
          extra={extra}
        >
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={incidentPolicy.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{incidentPolicy.status}</span>
            </Descriptions.Item>
            <Descriptions.Item label='Period'>
              {incidentPolicy.period}
            </Descriptions.Item>
            <Descriptions.Item label='Frequency'>
              {incidentPolicy.frequency}
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default IncidentPolicyDetails;
