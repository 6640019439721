import { Button } from 'antd';
import './item.css';
import { ReactNode } from 'react';
import ItemBadge from './ItemBadge';

interface ItemRootProps extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  onClick?: () => void;
  deleteOnClick?: () => void;
  disabled?: boolean;
  badge?: string;
  id?: string;
  backgroundColor?: string;
  isUpgradeRequired?: boolean;
  additionalClassNames?: string;
}

const ItemRoot = ({
  children,
  onClick,
  deleteOnClick,
  disabled,
  badge,
  id,
  backgroundColor,
  isUpgradeRequired = false,
  additionalClassNames,
}: ItemRootProps) => {
  return (
    <div
      style={{
        outlineColor: isUpgradeRequired ? '#F28606' : '#1f2937',
      }}
      className={`${onClick ? 'hover:outline cursor-pointer' : ''} ${
        (disabled || !onClick) && !backgroundColor
          ? 'bg-gray-200 opacity-80'
          : backgroundColor || 'bg-white'
      } w-full px-4 pt-5 pb-4 rounded-sm 
      list-grid-item flex-col justify-between items-start gap-4 inline-flex 
      transition-all relative ${additionalClassNames}`}
      onClick={onClick}
      id={id}
    >
      <>
        {deleteOnClick ? (
          <div className='flex justify-between w-full items-center'>
            {children}
            <Button
              onClick={(evt) => {
                evt.preventDefault();
                deleteOnClick();
              }}
              danger
            >
              Delete
            </Button>
          </div>
        ) : (
          children
        )}

        {badge && (
          <span className='absolute top-2 right-2'>
            <ItemBadge text={badge} />
          </span>
        )}
      </>
    </div>
  );
};

export default ItemRoot;
