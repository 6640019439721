import { ReactNode } from 'react';

interface ListEmptyProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  buttonTxt: string;
  handleClick: () => void;
  smallTxt?: string | ReactNode;
}

const ListEmpty = ({
  title,
  subtitle,
  buttonTxt,
  handleClick,
  smallTxt,
}: ListEmptyProps) => {
  return (
    <main
      className='animate-fade-up opacity-0 flex w-full flex-col items-center justify-center p-32 gap-4 
    bg-white rounded-md border-dashed border-2'
      style={{ animationDelay: '0.05s', animationFillMode: 'forwards' }}
    >
      <h1
        className='animate-fade-up text-center text-first-dark opacity-0 text-5xl font-semibold'
        style={{ animationDelay: '0.15s', animationFillMode: 'forwards' }}
      >
        {title}
      </h1>
      <p
        className='animate-fade-up text-center text-gray-500 opacity-0 text-lg w-3/5 2xl:w-3/4'
        style={{ animationDelay: '0.25s', animationFillMode: 'forwards' }}
      >
        {subtitle}
      </p>
      <div>
        <button
          onClick={handleClick}
          className='animate-fade-up opacity-0 bg-second hover:bg-second-light transition-all text-lg
          text-white my-4 px-4 py-2 rounded-sm'
          style={{ animationDelay: '0.35s', animationFillMode: 'forwards' }}
        >
          {buttonTxt}
        </button>
      </div>
      <span
        className='animate-fade-up opacity-0 text-xs'
        style={{ animationDelay: '0.45s', animationFillMode: 'forwards' }}
      >
        {smallTxt}
      </span>
    </main>
  );
};

export default ListEmpty;
