import {
  useListRequestsLogsQuery,
  useLazyListRequestsLogsQuery,
} from '@/utils/services/request/request-endpoints';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';
import Loader from '../Loader/Loader';
import List from '@/components/List';
import { useMemo } from 'react';
import { RequestClass } from '@/utils/models/Request';
import { ColumnsType } from 'antd/lib/table';
import { MdNavigateNext, MdOutlineFilterAlt, MdWarning } from 'react-icons/md';
import StatusCodeTag from '../Tag/StatusCodeTag';
import MethodTag from '../Tag/MethodTag';
import { Tooltip } from 'antd';
import RequestTag from '../Tag/RequestTag';
import { FiltersGroupInterface } from '@/utils/models/Filter';
import { useHistory, useParams } from 'react-router-dom';
import usePaginationQueryParam, {
  PaginationDataType,
} from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useRequestIDQueryParam from '@/utils/hooks/QueryParam/useRequestIDQueryParam';
import Dashboard from '@/components/Dashboard';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';

const DashboardRequestsTable = ({
  defaultFilters,
  defaultOptions,
  title,
  showHeaderOptions = true,
}: {
  defaultFilters?: FiltersGroupInterface[];
  defaultOptions?: {
    pollingInterval?: number;
    skip?: boolean;
  };
  title?: React.ReactNode;
  showHeaderOptions?: boolean;
}) => {
  const { apiID, orgID } = useParams<{ apiID: string; orgID: string }>();
  const { data: organisation } = useGetOrganisationQuery({ orgID });
  const { params, options } = useGetEndpointSettings();
  const history = useHistory();

  const { pagination } = usePaginationQueryParam();
  const { setRequestID } = useRequestIDQueryParam();

  const filtersGroupData = defaultFilters || params.filters;
  const dynamicParams = { ...params, filters: filtersGroupData };

  const { data, isLoading, isFetching, refetch, fulfilledTimeStamp } =
    useListRequestsLogsQuery(
      { ...pagination, ...dynamicParams },
      defaultOptions || options
    );
  const requests = data?.requests;
  const total = data?.total;

  const priorityTags = useMemo(() => {
    const tags: string[] = [];

    filtersGroupData?.forEach((filterGroup) => {
      filterGroup.groups?.forEach((filterCondition) => {
        if (filterCondition.field === 'tags' && filterCondition.values) {
          tags.push(...filterCondition.values);
        }
      });
    });

    return tags;
  }, [filtersGroupData]);

  const [fetchRequests] = useLazyListRequestsLogsQuery();

  const handleReloadData = () => {
    refetch();
  };

  const fetchDataToDownload = async (
    pagination?: PaginationDataType
  ): Promise<any[]> => {
    const { requests } = await fetchRequests(
      { ...params, ...pagination },
      true
    ).unwrap();
    return requests?.map((request) => request.mapDataToDownload) || [];
  };

  const loading = isLoading || isFetching;
  const reloadButtonDisabled = loading;

  const tableFilters: {
    methods: { text: string; value: string }[];
    status: { text: number; value: number }[];
  } = useMemo(() => {
    if (!Array.isArray(requests)) return { methods: [], status: [] };

    const methods = new Set<string>();
    const status = new Set<number>();
    const tags = new Set<string>();

    requests.forEach((request) => {
      methods.add(request.request.method);
      status.add(request.response.statusCode);
      request.tags?.forEach((tag) => tags.add(tag));
    });

    return {
      methods: Array.from(methods).map((m) => ({ text: m, value: m })),
      status: Array.from(status)
        .map((s) => ({ text: s, value: s }))
        .sort((a, b) => a.value - b.value),
    };
  }, [requests]);

  const columns: ColumnsType<RequestClass> = [
    {
      title: 'Status',
      key: 'statusCode',
      filters: tableFilters?.status,
      filterIcon: <MdOutlineFilterAlt size={18} />,
      onFilter: (value, request) => request?.response?.statusCode === value,
      render: (_, request) => (
        <StatusCodeTag code={request?.response?.statusCode?.toString()} />
      ),
    },
    {
      title: 'Method',
      key: 'method',
      filterIcon: <MdOutlineFilterAlt size={18} />,
      render: (_, request) => <MethodTag name={request.request.method} />,
      filters: tableFilters?.methods,
      onFilter: (value, request) => request.request.method === value,
    },
    {
      title: 'Date Created',
      key: 'dateCreated',
      render: (_, request) => (
        <Tooltip placement='topLeft' title={request.created}>
          <span className='text-sm whitespace-nowrap'>
            {request.createdFromNow}
          </span>
        </Tooltip>
      ),
      sorter: (a, b) => a.dateCreated - b.dateCreated,
    },
    {
      title: 'Source',
      key: 'source',
      dataIndex: 'source',
      render: (_, request) => {
        return (
          <Tooltip placement='topLeft' title={request.typeLabel}>
            <img src={request.avatar} alt='logo' className='h-6' />
          </Tooltip>
        );
      },
    },
    {
      title: 'Host',
      key: 'domain',
      render: (_, request) => (
        <span className='text-sm'>{request?.request?.domain}</span>
      ),
    },
    {
      title: 'Path',
      key: 'path',
      render: (_, request) => (
        <div style={{ maxWidth: '12rem' }}>
          <span className='text-sm break-words'>{request?.request?.path}</span>
        </div>
      ),
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      width: 400,
      render: (_, request) => {
        if (!request.tags) return <></>;

        const priorityTagsList = request.tags.filter((tag) =>
          priorityTags.includes(tag)
        );

        const nonPriorityTagsList = request.tags.filter(
          (tag) => !priorityTags.includes(tag)
        );

        const allTags = priorityTagsList.concat(nonPriorityTagsList);

        const plusTagsTotal = allTags.length - 4;

        return (
          <div className='leading-loose'>
            {allTags.slice(0, 4).map((tag) => (
              <RequestTag tagValue={tag} key={tag} />
            ))}
            {plusTagsTotal > 0 && (
              <p className='text-sm py-0 my-0'>+{plusTagsTotal} tags</p>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, request) => (
        <button
          className='whitespace-nowrap flex items-center focus:outline-none'
          data-testid='show-drawer'
          onClick={() => setRequestID(request.UUID)}
        >
          View details
          <MdNavigateNext className='ml-1' size={18} />
        </button>
      ),
    },
  ];

  if (!apiID) {
    columns.splice(5, 0, {
      title: 'API',
      key: 'api',
      render: (_, request) => {
        if (!request.api) {
          return (
            <Tooltip title='The API that this log was collected for has been deleted. You can still view and use this log in analysis.'>
              <div className='flex items-center gap-1 w-full'>
                <MdWarning color='red' />
                <span className='text-red-500'>API deleted</span>
              </div>
            </Tooltip>
          );
        }

        const api = request.api;
        return (
          <button
            onClick={() =>
              history.push(
                `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`
              )
            }
          >
            <div className='flex items-center gap-x-1 shrink w-40'>
              <img
                className='h-5 opacity-90 mr-1'
                src={api.avatar}
                alt={api.name}
              />
              <span>View</span>
              <span>{api.name}</span>
              <MdNavigateNext size={18} />
            </div>
          </button>
        );
      },
    });
  }

  if (organisation?.showSequencingFeature) {
    columns.splice(6, 0, {
      title: 'API Call Sequence',
      key: 'apiCallSequence',
      render: (_, request) => (
        <button
          className={`whitespace-nowrap flex items-center focus:outline-none`}
          data-testid='show-api-call-sequence'
          onClick={() => {
            setRequestID(request.UUID);
            sessionStorage.setItem('sectionId', 'api-call-sequence-section');
          }}
        >
          View sequence
          <MdNavigateNext className='ml-1' size={18} />
        </button>
      ),
    });
  }

  return (
    <div className='flex flex-col gap-4'>
      {showHeaderOptions && (
        <div className='flex items-center gap-4 justify-between'>
          {title ? (
            title
          ) : (
            <div className='flex items-center gap-4'>
              <span className='text-xl'>Requests</span>
              <Loader dark={false} spinning={loading} />
            </div>
          )}

          <div className='flex items-center gap-4'>
            <List.Update
              fulfilledTimeStamp={fulfilledTimeStamp}
              disabled={reloadButtonDisabled}
              handleUpdate={handleReloadData}
            />
            <List.Download
              disabled={total === 0}
              buttonText='Download Requests'
              fetchData={fetchDataToDownload}
              loading={loading}
              fileEntityName='requests'
              numberOfItems={total}
            />
          </div>
        </div>
      )}

      <List.Pagination dataSource={requests} useURI total={total}>
        <List.Table
          expandable={{
            expandedRowRender: (request) => (
              <div className='overscroll-contain overflow-auto max-h-80'>
                <span className='text-xs text-gray-600 overflow-scroll '>
                  {request?.response?.body}
                </span>
              </div>
            ),
            rowExpandable: (request) =>
              request.response.body !== undefined &&
              request.response.body !== '""' &&
              request.response.body !== '',
          }}
          ignoreMode
          columns={columns}
          loading={loading}
        />
      </List.Pagination>

      <Dashboard.RequestsDetailsDrawer />
    </div>
  );
};

export default DashboardRequestsTable;
