import { useParams, useHistory } from 'react-router-dom';
import Container from '@/components/Section/Container';
import { Result, Spin } from 'antd';
import {
  useAcceptInviteToOrgMutation,
  useGetInviteToOrgQuery,
} from '@/utils/services/user/user-endpoints';
import { MdPersonAddAlt1 } from 'react-icons/md';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';
import withAuthorizedRequired from '@/utils/lib/firetail-auth/wrappers/withAuthorizedRequired';

const InviteToOrg = () => {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: invite, isError: getInviteIsError } = useGetInviteToOrgQuery({
    uuid,
  });
  const [acceptInviteToOrg, { isLoading: acceptInviteToOrgIsLoading }] =
    useAcceptInviteToOrgMutation();

  const handleAcceptInviteToOrg = () => {
    acceptInviteToOrg({ uuid })
      .unwrap()
      .then(() => {
        history.push(
          `/organisations/${invite?.orgUUID}/integrations/available`
        );
      });
  };

  if (getInviteIsError)
    return (
      <div className='pt-14' data-testid='show-unauthorized'>
        <Result
          status={'error'}
          title='Bad Request'
          subTitle='The Invite has expired or does not exist, ask the organization owner to resend the invite.'
        />
      </div>
    );

  if (invite)
    return (
      <Container>
        <Spin spinning={acceptInviteToOrgIsLoading}>
          <div className='md:container md:mx-auto bg-white rounded-sm items-center text-center'>
            <section className='py-20'>
              <div className='flex flex-col gap-6 justify-center items-center'>
                <MdPersonAddAlt1 size={48} />
                <h1 className='title-font text-4xl font-bold text-first'>
                  You've been invited to an organization!{' '}
                </h1>
                <span className='text-xl text-gray-600 mb-6'>
                  {invite.message}
                </span>
                <div className='flex gap-2 justify-center'>
                  <button
                    className='inline-flex text-white font-bold bg-second border-0 
  py-2 px-6 focus:outline-none hover:bg-second-light rounded-sm text-lg'
                    onClick={handleAcceptInviteToOrg}
                  >
                    Accept Invitation
                  </button>
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </Container>
    );

  return <FullScreenLoader />;
};

const PageWithAuthorizedRequired = withAuthorizedRequired(InviteToOrg);

export default withAuthenticationRequired(PageWithAuthorizedRequired);
