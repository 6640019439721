import { MILISECONDS_TO_INVALIDATE_TAGS_TWO_SECONDS } from '@/utils/constants';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import {
  FilterClass,
  FilterInterface,
  FiltersGroupInterface,
} from '@/utils/models/Filter';
import { FieldsData } from '@/utils/models/FieldType';
import { savedFiltersQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';

interface FilterGenericParams extends OrgGenericParams {
  filterID: string;
}

interface AddFilterParams extends OrgGenericParams {
  data: { name: string; filters: FiltersGroupInterface[]; resource: string };
}

interface ListFilterParams extends OrgGenericParams {
  data?: { search_value?: string; filters?: FieldsData[] };
}

export const filtersConfigurationsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listFilterConfig: build.query<FilterClass[], ListFilterParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/search?resource=filter`,
        method: 'POST',
        data: { ...data, resource: 'filter' },
      }),
      transformResponse: (rawResult: { filter: FilterInterface[] }) =>
        rawResult.filter
          .map((filters) => new FilterClass(filters))
          .sort(
            (a, b) => b.dateAddedInMicroSeconds - a.dateAddedInMicroSeconds
          ),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ UUID }) => ({
                type: 'Filters' as const,
                id: UUID,
              })),
              { type: 'Filters', id: 'LIST' },
            ]
          : [{ type: 'Filters', id: 'LIST' }],
    }),
    addFilterConfig: build.mutation<FilterClass, AddFilterParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/filters/saved`,
        method: 'POST',
        data: { ...data },
      }),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          savedFiltersQuotaEndpoint.util.updateQueryData(
            'saved_filters',
            { orgID },
            (draft) => mockIncrementQuota(draft)
          )
        );

        setTimeout(() => {
          dispatch(
            filtersConfigurationsEndpoints.util.invalidateTags([
              'Filters',
              'Quotas',
            ])
          );
        }, MILISECONDS_TO_INVALIDATE_TAGS_TWO_SECONDS);
      },
    }),
    deleteFilterConfig: build.mutation<void, FilterGenericParams>({
      query: ({ orgID, filterID }) => ({
        url: `/organisations/${orgID}/filters/saved/${filterID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          savedFiltersQuotaEndpoint.util.updateQueryData(
            'saved_filters',
            { orgID },
            (draft) => mockDecrementQuota(draft)
          )
        );

        setTimeout(() => {
          dispatch(
            filtersConfigurationsEndpoints.util.invalidateTags([
              'Filters',
              'Quotas',
            ])
          );
        }, MILISECONDS_TO_INVALIDATE_TAGS_TWO_SECONDS);
      },
    }),
  }),
});

export const {
  useAddFilterConfigMutation,
  useDeleteFilterConfigMutation,
  useLazyListFilterConfigQuery,
  useListFilterConfigQuery,
} = filtersConfigurationsEndpoints;

export default filtersConfigurationsEndpoints;
