import { Switch, Route } from 'react-router-dom';
import app from './appID/app';
import settings from './settings';
import ApiID from './appID/apis/apiID';
import Container from '@/components/Section/Container';
import useRole from '@/utils/hooks/useRole';

const AppID = () => {
  const { hasPermissionToGetApi } = useRole();

  return (
    <Container>
      <Switch>
        {hasPermissionToGetApi && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID'
            component={ApiID}
          />
        )}
        <Route
          path='/organisations/:orgID/applications/:appID/settings'
          component={settings}
        />
        <Route
          path='/organisations/:orgID/applications/:appID'
          component={app}
        />
      </Switch>
    </Container>
  );
};

export default AppID;
