import {
  SpecificationVersionClass,
  SpecificationVersionInterface,
} from '@/utils/models/SpecificationVersion';
import { baseApi } from '../base-api';

interface VersionGenericParams {
  orgID: string;
  appID: string;
  apiID: string;
  specificationID: string;
}

interface AddVersionParam extends VersionGenericParams {
  specData: any;
}

export interface GetSpecificationVersionParams {
  orgID: string;
  specificationID: string;
  versionID: string;
}

export const versionEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addSpecificationVersion: build.mutation<
      SpecificationVersionInterface,
      AddVersionParam
    >({
      query: ({ orgID, specificationID, appID, apiID, specData }) => {
        return {
          url: `/organisations/${orgID}/collections/${specificationID}/versions`,
          method: 'POST',
          data: {
            app_uuid: appID,
            api_uuid: apiID,
            spec_type: 'OAS3.0',
            spec_data: specData,
          },
        };
      },
      invalidatesTags: (result, error, { specificationID }) => [
        { type: 'Specifications', id: specificationID },
      ],
    }),
    getSpecificationVersion: build.query<
      SpecificationVersionClass,
      GetSpecificationVersionParams
    >({
      query: ({ orgID, specificationID, versionID }) =>
        `/organisations/${orgID}/collections/${specificationID}/versions/${versionID}`,
      transformResponse: (rawResult: {
        collection_version: SpecificationVersionInterface;
      }) => new SpecificationVersionClass(rawResult.collection_version),
    }),
  }),
});

export const {
  useAddSpecificationVersionMutation,
  useGetSpecificationVersionQuery,
  useLazyGetSpecificationVersionQuery,
} = versionEndpoints;
