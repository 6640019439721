import { useParams } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { useMemo } from 'react';
import Dashboard from '@/components/Dashboard';
import useQuery from '@/utils/hooks/useQuery';

const Requests = () => {
  const { appID, apiID } = useParams<{
    appID: string;
    apiID: string;
  }>();

  const query = useQuery();
  const requestsWhichIncludeTags = query.get('requests-which-include-tags');

  const defaultFilters = useMemo(() => {
    const appCondition: ConditionType = {
      id: nanoid(),
      field: 'appUUID',
      operator: 'is-one-of',
      values: [appID],
      disabled: true,
    };
    const apiCondition: ConditionType = {
      id: nanoid(),
      field: 'apiUUID',
      operator: 'is-one-of',
      values: [apiID],
      disabled: true,
    };
    const filtersData: FilterGroupType[] = [
      {
        id: nanoid(),
        operator: 'include',
        groups: [appCondition, apiCondition],
        disabled: true,
      },
    ];

    if (requestsWhichIncludeTags) {
      const tagsCondition: ConditionType = {
        id: nanoid(),
        field: 'tags',
        operator: 'is-one-of',
        values: requestsWhichIncludeTags.split(','),
        disabled: false,
      };
      filtersData.push({
        id: nanoid(),
        operator: 'include',
        groups: [tagsCondition],
        disabled: false,
      });
    }

    return filtersData;
  }, [apiID, appID, requestsWhichIncludeTags]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-2 rounded-sm'>
        <Dashboard.Filters.Root defaultFilters={defaultFilters}>
          <Dashboard.Filters.Groups />

          <Dashboard.Filters.SelectorsWrapper>
            <Dashboard.Filters.SelectPollingInterval />
            <Dashboard.Filters.SelectDateTime />
            <Dashboard.Filters.CreateAlertButton />
          </Dashboard.Filters.SelectorsWrapper>
        </Dashboard.Filters.Root>
      </div>

      <Dashboard.RequestsTable />
    </div>
  );
};

export default Requests;
