import Item from '@/components/List/Item';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import List from '@/components/List';
import { useListAlertsQuery } from '@/utils/services/alerting/alerting-endpoints';
import Alerts from '@/components/Alerts';
import { AlertClass } from '@/utils/models/Alert';
import useRole from '@/utils/hooks/useRole';
import { Button } from 'antd';
import { useState } from 'react';
import {
  useGetAnomalyAlertingQuotasQuery,
  useGetStaticAlertingQuotasQuery,
} from '@/utils/services/quota/quota-endpoints';
import useCategoryFilterQueryParam from '@/utils/hooks/QueryParam/useCategoryFilterQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';

const ExistingAlerts = () => {
  const { hasPermissionToCreateAlerting, hasPermissionToGetAlerting } =
    useRole();
  const { orgID } = useParams<{ orgID: string }>();

  const location = useLocation();
  const history = useHistory();

  const { category: alertType } = useCategoryFilterQueryParam();
  const { query } = useSearchQueryParam();
  const { pagination } = usePaginationQueryParam();

  const { data: listAlertsData, isLoading: isListAlertsDataLoading } =
    useListAlertsQuery({ orgID, query, ...pagination, alertType });

  const { data: anomalyQuota, isLoading: isAnomalyQuotaLoading } =
    useGetAnomalyAlertingQuotasQuery({
      orgID,
    });
  const { data: staticQuota, isLoading: isStaticQuotaLoading } =
    useGetStaticAlertingQuotasQuery({
      orgID,
    });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCreateAlert = () => setIsOpen(true);

  const isStaticUpgradeRequired = staticQuota?.max === 0;

  const isAnomalyUpgradeRequired = anomalyQuota?.max === 0;

  const handleAlertSelected = (alert: AlertClass) => {
    if (hasPermissionToGetAlerting) {
      history.replace({
        ...location,
        pathname: `/organisations/${orgID}/monitor-alerting/${alert.UUID}`,
      });
    }
    return;
  };

  return (
    <>
      <Alerts.Creation
        onCloseModal={() => setIsOpen(false)}
        open={isOpen}
        anomalyQuota={anomalyQuota}
        staticQuota={staticQuota}
      />

      <Alerts.Edition />

      <List.Root
        renderEmpty={
          hasPermissionToCreateAlerting
            ? {
                isEmpty: listAlertsData?.total === 0 && !query,
                loading: isListAlertsDataLoading,
                element: (
                  <List.Empty
                    title='Create an Alert'
                    subtitle='Create alerts to receive notifications when pre-defined limits are exceeded, or anomalous behaviour is discovered.'
                    buttonTxt='Create Alert'
                    handleClick={handleCreateAlert}
                  />
                ),
              }
            : undefined
        }
      >
        <List.Header>
          <List.Search placeholder='Search alerts' />

          <List.Settings>
            {hasPermissionToCreateAlerting && staticQuota && anomalyQuota && (
              <Button
                id='create-alerting-button'
                onClick={handleCreateAlert}
                type='primary'
                disabled={isAnomalyQuotaLoading || isStaticQuotaLoading}
              >
                Create Alert
              </Button>
            )}
            <List.QuotaIndicator
              quota={staticQuota}
              tooltipText={
                staticQuota?.max === 0
                  ? 'Your current plan does not support static alerts.'
                  : `You are currently using ${
                      staticQuota?.current as number
                    } out of your ${
                      staticQuota?.max as number
                    } allocated static alerts.`
              }
              quotaUsedText='used (Static)'
            />
            <List.QuotaIndicator
              quota={anomalyQuota}
              tooltipText={
                anomalyQuota?.max === 0
                  ? 'Your current plan does not support anomaly detection alerts.'
                  : `You are currently using ${
                      anomalyQuota?.current as number
                    } out of your ${
                      anomalyQuota?.max as number
                    } allocated anomaly detection alerts.`
              }
              quotaUsedText='used (Anomaly)'
            />
          </List.Settings>
        </List.Header>

        <List.CategoryFilter manuallyCategories={['static', 'anomaly']} />

        <List.Pagination
          dataSource={listAlertsData?.alerts}
          total={listAlertsData?.total}
          useURI
        >
          <List.Grid
            item={{
              render: (item: AlertClass) => (
                <Item.Root
                  key={item.UUID}
                  onClick={() => handleAlertSelected(item)}
                  id={item.UUID}
                  disabled={!item.enabled}
                  badge={item.category}
                  additionalClassNames='h-32'
                >
                  <Item.Header>
                    <Item.Title>{item.name}</Item.Title>
                  </Item.Header>

                  <Item.Footer>
                    <Item.Date
                      createdFromNow={item.createdFromNow}
                      created={item.created}
                    />
                    <Item.Button
                      label=''
                      isVisible={
                        (item.config.type === 'static' &&
                          isStaticUpgradeRequired) ||
                        (item.config.type === 'anomaly' &&
                          isAnomalyUpgradeRequired)
                      }
                      isUpgradeRequired={
                        (item.config.type === 'static' &&
                          isStaticUpgradeRequired) ||
                        (item.config.type === 'anomaly' &&
                          isAnomalyUpgradeRequired)
                      }
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={
              isListAlertsDataLoading ||
              isAnomalyQuotaLoading ||
              isStaticQuotaLoading
            }
            ignoreMode
          />
        </List.Pagination>
      </List.Root>
    </>
  );
};

export default ExistingAlerts;
