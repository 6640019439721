import Fields from '@/components/Form/FieldComponents';
import { RequestClass } from '@/utils/models/Request';

const DashboardRequestDetailsMetadata = ({ log }: { log: RequestClass }) => {
  if (!log?.metadata) return null;

  return (
    <div className='gap-3 flex flex-col'>
      <h1 className='font-semibold' style={{ fontSize: 20 }}>
        Metadata
      </h1>

      <Fields.CodeBlock
        field={{
          value: JSON.stringify(log?.metadata),
          language: 'json',
          inputType: 'code-block',
          name: 'metadata',
          attribute: 'metadata',
        }}
      />
    </div>
  );
};

export default DashboardRequestDetailsMetadata;
