import { useParams } from 'react-router-dom';
import Dashboard from '@/components/Dashboard';

const DashboardPage = () => {
  const { orgID } = useParams<{ orgID: string }>();

  return (
    <section>
      <Dashboard.Overview />

      <div className='bg-first px-8' style={{ minHeight: '84vh' }}>
        <Dashboard.Filters.Root dark useURI useStorageKey={orgID}>
          <Dashboard.Filters.Wrapper>
            <Dashboard.Filters.Groups />
          </Dashboard.Filters.Wrapper>
          <Dashboard.Filters.SelectorsWrapper>
            <Dashboard.Filters.SelectPollingInterval />
            <Dashboard.Filters.SelectDateTime />
            <Dashboard.Filters.CreateAlertButton />
          </Dashboard.Filters.SelectorsWrapper>
        </Dashboard.Filters.Root>

        <div
          className='grid lg:grid-cols-12 grid-cols-1 py-8 gap-x-8 gap-y-16'
          style={{ minHeight: '64vh' }}
        >
          <div className='lg:col-span-5'>
            <Dashboard.RequestsLineChart />
          </div>
          <div className='lg:col-start-7 lg:col-span-6'>
            <Dashboard.RequestsMapChart />
          </div>
          <div className='lg:col-span-5'>
            <Dashboard.RequestsDonutChart />
          </div>
          <div className='lg:col-start-7 lg:col-span-6'>
            <Dashboard.FindingsMetricsLineChart />
          </div>
        </div>
      </div>

      <div className='px-8 pt-8 min-h-screen bg-gray-100 pb-28'>
        <Dashboard.RequestsTable />
      </div>
    </section>
  );
};

export default DashboardPage;
