import { store } from '@/app/store';
import applicationEndpoints, {
  ListApplicationsParams,
  ListApplicationsResponse,
} from './application-endpoints';
import { ApplicationClass } from '@/utils/models/Application';

export const listApplications = async (params: ListApplicationsParams) => {
  const appsResult = await store.dispatch<any>(
    applicationEndpoints.endpoints.listApplications.initiate(params)
  );
  const data = appsResult.data as ListApplicationsResponse;
  return data.apps;
};

export const getApplication = async (orgID: string, appID: string) => {
  const appResult = await store.dispatch<any>(
    applicationEndpoints.endpoints.getApplication.initiate({ orgID, appID })
  );
  const data = appResult.data as ApplicationClass;
  return data;
};
