const LIST_OF_HTTP_STATUS_CODE: {
  code: string;
  title: string;
  description: string;
}[] = [
  {
    code: '100',
    title: 'Continue',
    description:
      'The server has received the request headers and the client should proceed to send the request body.',
  },
  {
    code: '101',
    title: 'Switching Protocols',
    description:
      'The server agrees to switch protocols specified in the Upgrade header field.',
  },
  {
    code: '102',
    title: 'Processing',
    description:
      'The server is processing the request, but no response is available yet.',
  },
  {
    code: '103',
    title: 'Early Hints',
    description:
      'The server is sending some hints to the client before sending the final response.',
  },
  {
    code: '200',
    title: 'OK',
    description: 'The request has succeeded.',
  },
  {
    code: '201',
    title: 'Created',
    description:
      'The request has been fulfilled, and a new resource is created as a result.',
  },
  {
    code: '202',
    title: 'Accepted',
    description:
      'The request has been accepted for processing, but it is not yet completed.',
  },
  {
    code: '203',
    title: 'Non-Authoritative Information',
    description:
      'The request was successful, but the returned information may be from a different source.',
  },
  {
    code: '204',
    title: 'No Content',
    description:
      'The request was successful, but there is no content to return.',
  },
  {
    code: '205',
    title: 'Reset Content',
    description:
      'The request was successful, and the client should reset the document view it sent the request from.',
  },
  {
    code: '206',
    title: 'Partial Content',
    description:
      'The request was successful, but only a partial response was returned due to a range header.',
  },
  {
    code: '207',
    title: 'Multi-Status',
    description:
      'The XML response returned contains information about multiple actions that occurred on the server, which may be independent.',
  },
  {
    code: '208',
    title: 'Already Reported',
    description:
      'The response has already been sent in a previous response to the same request.',
  },
  {
    code: '226',
    title: 'IM Used',
    description:
      'The request was successful, and the response is a representation of the result of one or more instance-manipulations applied to the current instance.',
  },
  {
    code: '300',
    title: 'Multiple Choices',
    description: 'The request has more than one possible response.',
  },
  {
    code: '301',
    title: 'Moved Permanently',
    description: 'The request has been permanently redirected to a new URI.',
  },
  {
    code: '302',
    title: 'Found',
    description:
      'The request has been temporarily redirected to a different URI.',
  },
  {
    code: '303',
    title: 'See Other',
    description:
      'The response to the request can be found under a different URI, and should be retrieved using a GET method on that resource.',
  },
  {
    code: '304',
    title: 'Not Modified',
    description:
      'The cached version of the requested resource is up to date, and there is no need to send it again.',
  },
  {
    code: '305',
    title: 'Use Proxy',
    description:
      'The requested resource must be accessed through the proxy specified in the Location field.',
  },
  {
    code: '306',
    title: 'Switch Proxy',
    description:
      'This response code is no longer used, but was reserved in previous versions of the protocol for future use.',
  },
  {
    code: '307',
    title: 'Temporary Redirect',
    description: 'The request has been temporarily redirected to a new URI.',
  },
  {
    code: '308',
    title: 'Permanent Redirect',
    description: 'The request has been permanently redirected to a new URI.',
  },
  {
    code: '400',
    title: 'Bad Request',
    description:
      'The server could not understand the request, or it was malformed.',
  },
  {
    code: '401',
    title: 'Unauthorized',
    description: 'The request requires user authentication.',
  },
  {
    code: '402',
    title: 'Payment Required',
    description:
      'Reserved for future use. The original intention was that this code might be used as part of some form of digital cash or micropayment scheme.',
  },
  {
    code: '403',
    title: 'Forbidden',
    description:
      'The server understood the request, but is refusing to fulfill it.',
  },
  {
    code: '404',
    title: 'Not Found',
    description: 'The server did not find anything matching the requested URI.',
  },
  {
    code: '405',
    title: 'Method Not Allowed',
    description:
      'The method specified in the request is known by the server, but is not allowed for the requested resource.',
  },
  {
    code: '406',
    title: 'Not Acceptable',
    description:
      'The server can only generate a response that is not accepted by the client.',
  },
  {
    code: '407',
    title: 'Proxy Authentication Required',
    description: 'The client must authenticate itself with the proxy.',
  },
  {
    code: '408',
    title: 'Request Timeout',
    description:
      'The client did not produce a request within the time that the server was prepared to wait.',
  },
  {
    code: '409',
    title: 'Conflict',
    description:
      'The request could not be completed due to a conflict in the current state of the resource.',
  },
  {
    code: '410',
    title: 'Gone',
    description:
      'The requested resource is no longer available and will not be available again.',
  },
  {
    code: '411',
    title: 'Length Required',
    description:
      'The server refuses to accept the request without a defined Content-Length header.',
  },
  {
    code: '412',
    title: 'Precondition Failed',
    description:
      'The server does not meet one of the preconditions specified by the client in the request.',
  },
  {
    code: '413',
    title: 'Payload Too Large',
    description:
      'The server refuses to process the request because the payload is too large.',
  },
  {
    code: '414',
    title: 'URI Too Long',
    description:
      'The server refuses to service the request because the URI is longer than what the server is willing to interpret.',
  },
  {
    code: '415',
    title: 'Unsupported Media Type',
    description:
      'The server refuses to service the request because the entity of the request is in a format not supported by the requested resource.',
  },
  {
    code: '416',
    title: 'Range Not Satisfiable',
    description:
      'The requested range cannot be fulfilled, either because it is outside the range of the source resource or it is not a valid range.',
  },
  {
    code: '417',
    title: 'Expectation Failed',
    description:
      "The expectation given in the request's Expect header field could not be met by at least one of the inbound servers.",
  },
  {
    code: '418',
    title: "I'm a teapot",
    description:
      "Any attempt to brew coffee with a teapot should result in the error code '418 I'm a teapot'.",
  },
  {
    code: '421',
    title: 'Misdirected Request',
    description:
      'The request was directed at a server that is not able to produce a response.',
  },
  {
    code: '422',
    title: 'Unprocessable Entity',
    description:
      'The server understands the content type of the request entity, and the syntax of the request entity is correct, but it was unable to process the contained instructions.',
  },
  {
    code: '423',
    title: 'Locked',
    description: 'The source or destination resource of a method is locked.',
  },
  {
    code: '424',
    title: 'Failed Dependency',
    description:
      'The method could not be performed on the resource because the requested action depends on another action and that action failed.',
  },
  {
    code: '425',
    title: 'Too Early',
    description:
      'The server is unwilling to risk processing a request that might be replayed.',
  },
  {
    code: '426',
    title: 'Upgrade Required',
    description:
      'The server refuses to perform the request using the current protocol, but might be willing to do so after the client upgrades to a different protocol.',
  },
  {
    code: '428',
    title: 'Precondition Required',
    description: 'The origin server requires the request to be conditional.',
  },
  {
    code: '429',
    title: 'Too Many Requests',
    description:
      'The user has sent too many requests in a given amount of time.',
  },
  {
    code: '431',
    title: 'Request Header Fields Too Large',
    description:
      'The server is unwilling to process the request because its header fields are too large.',
  },
  {
    code: '451',
    title: 'Unavailable For Legal Reasons',
    description:
      'The server is denying access to the resource as a consequence of a legal demand.',
  },
  {
    code: '500',
    title: 'Internal Server Error',
    description:
      'The server encountered an unexpected condition that prevented it from fulfilling the request.',
  },
  {
    code: '501',
    title: 'Not Implemented',
    description:
      'The server does not support the functionality required to fulfill the request.',
  },
  {
    code: '502',
    title: 'Bad Gateway',
    description:
      'The server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.',
  },
  {
    code: '503',
    title: 'Service Unavailable',
    description:
      'The server is currently unable to handle the request due to a temporary overload or maintenance of the server.',
  },
  {
    code: '504',
    title: 'Gateway Timeout',
    description:
      'The server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access to fulfill the request.',
  },
  {
    code: '505',
    title: 'HTTP Version Not Supported',
    description:
      'The server does not support, or refuses to support, the major version of HTTP used in the request.',
  },
  {
    code: '506',
    title: 'Variant Also Negotiates',
    description:
      'The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process.',
  },
  {
    code: '507',
    title: 'Insufficient Storage',
    description:
      'The method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.',
  },
  {
    code: '508',
    title: 'Loop Detected',
    description:
      'The server detected an infinite loop while processing the request.',
  },
  {
    code: '510',
    title: 'Not Extended',
    description:
      'Further extensions to the request are required for the server to fulfill it.',
  },
  {
    code: '511',
    title: 'Network Authentication Required',
    description: 'The client needs to authenticate to gain network access.',
  },
];

export default LIST_OF_HTTP_STATUS_CODE;
