import List from '@/components/List';
import Item from '@/components/List/Item';
import {
  FindingClass,
  SeverityFindingType,
  StatusFindingType,
} from '@/utils/models/Finding';
import { useListFindingsQuery } from '@/utils/services/finding/finding-endpoints';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useRole from '@/utils/hooks/useRole';
import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { FieldsData } from '@/utils/models/FieldType';
import SeverityTag from '../Tag/SeverityTag';
import StatusTicketTag from '@/components/Tag/StatusTicketTag';

const FindingsListingSortedBySeverities = ({
  filters,
}: {
  filters?: FieldsData[];
}) => {
  const { hasPermissionToGetFinding } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [sort, setSort] = useState<'asc' | 'desc'>('asc');
  const history = useHistory();
  const location = useLocation();

  const {
    data: findingsData,
    isLoading: listFindingsLoading,
    isFetching: listFindingsFetching,
  } = useListFindingsQuery({
    orgID,
    sort: { sort_field: 'severity', sort_order: sort },
    filters: filters,
    pageSize: 10,
  });

  const columns: ColumnsType<FindingClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, finding) =>
        hasPermissionToGetFinding ? (
          <button
            onClick={() => handleFindingSelected(finding)}
            className='text-base text-blue-600 font-semibold text-left'
          >
            {finding.mapping?.title || finding.code}
          </button>
        ) : (
          <span className='text-base font-semibold text-left'>
            {finding.mapping?.title || finding.code}
          </span>
        ),
    },
    {
      title: 'Description',
      key: 'description',
      width: 400,
      render: (_, finding) => (
        <Item.Description>{finding.mapping?.description}</Item.Description>
      ),
    },
    {
      title: 'API',
      dataIndex: 'api.name',
      render: (_, finding) =>
        finding.api ? (
          <Item.LogoAndName
            logo={finding.api?.avatar}
            name={finding.api?.name}
          />
        ) : (
          <></>
        ),
    },
    {
      title: 'Severity',
      sorter: true,
      dataIndex: 'severity',
      key: 'severity',
      render: (severity: SeverityFindingType, finding) => (
        <SeverityTag severity={severity} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: StatusFindingType, finding) => (
        <StatusTicketTag status={status} />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, finding) => (
        <Tooltip title={finding.created}>
          <span className='whitespace-nowrap'>{finding.createdFromNow}</span>
        </Tooltip>
      ),
    },
  ];

  const handleFindingSelected = (finding: FindingClass) => {
    history.replace({
      ...location,
      pathname: `/organisations/${orgID}/posture-management/findings/${finding.UUID}`,
    });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const orderEnum: { ascend: 'asc'; descend: 'desc' } = {
      ascend: 'asc',
      descend: 'desc',
    };

    if (!Array.isArray(sorter) && sorter?.order) {
      setSort(orderEnum[sorter.order]);
    }
  };

  return (
    <>
      <List.Root>
        <List.Table
          dataSource={findingsData?.findings}
          columns={columns}
          loading={listFindingsLoading || listFindingsFetching}
          ignoreMode
          onTableChange={handleTableChange}
        />

        <div className='w-full flex justify-end my-4'>
          <Button
            onClick={() => {
              const uri = encodeURI(
                `/organisations/${orgID}/posture-management/findings?filters=${JSON.stringify(
                  filters
                )}`
              );
              history.push(uri);
            }}
          >
            View All Findings
          </Button>
        </div>
      </List.Root>
    </>
  );
};

export default FindingsListingSortedBySeverities;
