import './FindingInspectorRoot.css';
import { FindingClass } from '@/utils/models/Finding';
import { Skeleton } from 'antd';
import { Children, cloneElement } from 'react';

const FindingInspectorRoot = ({
  finding,
  loading,
  children,
}: {
  finding?: FindingClass;
  loading?: boolean;
  children: React.ReactElement[] | React.ReactElement;
}) => {
  if (loading) return <Skeleton active />;

  const renderChildren = () => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        finding,
      });
    });
  };

  return (
    <div
      className='relative flex flex-col border-l border-r border-b border-gray-300
     bg-white'
    >
      {renderChildren()}
    </div>
  );
};

export default FindingInspectorRoot;
