import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import existing from './integrations/existing';
import Available from './integrations/available';
import Heading from '@/components/Section/Heading';
import Container from '@/components/Section/Container';
import List from '@/components/List';
import useRole from '@/utils/hooks/useRole';

const AVAILABLE_INTEGRATIONS = 'available';
const EXISTING_INTEGRATIONS = 'existing';

const Integrations = () => {
  const {
    hasPermissionToCreateIntegration,
    hasPermissionToListIntegration,
    hasPermissionToGetIntegration,
  } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const navigator = useHistory();
  const tabs = [];

  if (hasPermissionToCreateIntegration)
    tabs.push({
      label: 'Create integration',
      key: AVAILABLE_INTEGRATIONS,
    });
  if (hasPermissionToListIntegration)
    tabs.push({ label: 'Existing integrations', key: EXISTING_INTEGRATIONS });

  return (
    <>
      <Heading text='Integrations' />
      <Container>
        <List.Tabs
          onChange={(key) =>
            navigator.replace(`/organisations/${orgID}/integrations/${key}`)
          }
          tabs={tabs}
        />
        <Switch>
          {hasPermissionToCreateIntegration && (
            <Route
              path={`/organisations/:orgID/integrations/${AVAILABLE_INTEGRATIONS}/:integrationAvailableID`}
              component={Available}
            />
          )}

          {hasPermissionToCreateIntegration && (
            <Route
              path={`/organisations/:orgID/integrations/${AVAILABLE_INTEGRATIONS}`}
              component={Available}
            />
          )}

          {hasPermissionToGetIntegration && (
            <Route
              path={`/organisations/:orgID/integrations/${EXISTING_INTEGRATIONS}/:myIntegrationID`}
              component={existing}
            />
          )}

          {hasPermissionToListIntegration && (
            <Route
              path={`/organisations/:orgID/integrations/${EXISTING_INTEGRATIONS}`}
              component={existing}
            />
          )}

          {hasPermissionToCreateIntegration && (
            <Redirect
              from='/organisations/:orgID/integrations'
              to={`/organisations/:orgID/integrations/${AVAILABLE_INTEGRATIONS}`}
            />
          )}

          {!hasPermissionToCreateIntegration &&
            hasPermissionToListIntegration && (
              <Redirect
                from='/organisations/:orgID/integrations'
                to={`/organisations/:orgID/integrations/${EXISTING_INTEGRATIONS}`}
              />
            )}
        </Switch>
      </Container>
    </>
  );
};

export default Integrations;
