import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Form, Input, Switch } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useEffect } from 'react';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useParams } from 'react-router-dom';

const AlertIdentificationandActivation = ({
  formInstance,
  alert,
  forceType,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  useEffect(() => {
    if (!alert) return;

    formInstance.setFieldsValue({
      name: alert.name,
      enabled: alert.enabled,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  useEffect(() => {
    if (isEdition) return;

    formInstance.setFieldsValue({
      enabled: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!alert && isEdition) return <></>;

  const sectionProps: any = {
    title: 'INVISIBLE',
    showTitle: false,
  };

  return (
    <AlertSectionWrapper
      title={sectionProps.title}
      showTitle={sectionProps.showTitle}
      info={sectionProps.info}
    >
      <div className='grid grid-cols-6 gap-6'>
        <Form.Item
          name='name'
          label={'Name'}
          rules={[{ required: true, message: 'Name is required' }]}
          className='col-span-3'
        >
          <Input placeholder='Alert name' />
        </Form.Item>

        <Form.Item
          name='enabled'
          label={'Enabled'}
          valuePropName='checked'
          className='col-span-3'
          tooltip='Toggle to activate or deactivate the alert.'
        >
          <Switch defaultChecked={alert?.enabled} />
        </Form.Item>
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertIdentificationandActivation;
