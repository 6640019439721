import Dropdown from '@/components/DataDisplay/Dropdown';
import Loader from '@/components/Loader/Loader';
import LIST_OF_API_STATUS_FINDINGS from '@/utils/constants/listOfStatusFindings';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';
import { counterFormat, dateFormat } from '@/utils/methods/displayFormat';
import { FindingClass, StatusFindingType } from '@/utils/models/Finding';
import { useGetFindingsMetricsQuery } from '@/utils/services/finding/finding-endpoints';
import { Line } from '@ant-design/plots';
import { Spin } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';

const DashboardFindingsMetricsLineChart = ({ light }: { light?: boolean }) => {
  const {
    params,
    options,
    filtersFieldsData: filters,
  } = useGetEndpointSettings();

  const [filterByStatus, setFilterByStatus] =
    useState<StatusFindingType>('Open');

  const {
    currentData: findingsMetricsData,
    isLoading,
    isFetching,
  } = useGetFindingsMetricsQuery(
    {
      orgID: params.orgID,
      dateTime: params.dateTime,
      filters,
      filterByStatus,
    },
    options
  );

  const currentTime = moment();
  const timeRangeMilliseconds = (params.dateTime.value || 7) * 1000;
  const startDate = currentTime.subtract(timeRangeMilliseconds, 'milliseconds');

  const filteredLineChartData =
    findingsMetricsData?.lineChart.filter((data) => {
      const dataTime = moment(data.datetimeUTC);
      return dataTime.isAfter(startDate);
    }) || [];

  const dateTime = params?.dateTime;

  const textColor = light ? '' : 'text-anti-flash';

  return (
    <div className='flex flex-col gap-4'>
      <Header
        light={light}
        filterByStatus={filterByStatus}
        setFilterByStatus={setFilterByStatus}
        loading={isLoading || isFetching}
      />

      <div>
        <span className={`${textColor} text-xs`}>Findings</span>
        <Spin spinning={isLoading || isFetching}>
          <Line
            height={250}
            data={filteredLineChartData}
            xField={'datetimeUTC'}
            yField={'value'}
            seriesField={'severity'}
            theme={{
              components: {
                axis: {
                  common: {
                    grid: {
                      line: {
                        type: 'line',
                        style: light
                          ? undefined
                          : {
                              stroke: '#44575F',
                              lineWidth: 1,
                              lineDash: null,
                            },
                      },
                    },
                    line: {
                      style: { lineWidth: 1, stroke: '#708186' },
                    },
                  },
                },
              },
            }}
            legend={{
              itemName: { style: { fill: light ? undefined : '#F2FFFE' } },
              position: 'bottom-left',
            }}
            appendPadding={[10, 0, 20, 0]}
            color={[
              FindingClass.getSeverityColor('critical'),
              FindingClass.getSeverityColor('high'),
              FindingClass.getSeverityColor('medium'),
              FindingClass.getSeverityColor('low'),
              FindingClass.getSeverityColor('information'),
            ]}
            xAxis={{
              nice: true,
              label: {
                offset: 16,
                style: light
                  ? undefined
                  : {
                      fill: '#F2FFFE',
                      fontSize: 12,
                    },
                formatter: (date: any) => dateFormat(date, dateTime?.value),
              },
              tickLine: {
                style: {
                  lineWidth: 0,
                },
              },
            }}
            yAxis={{
              label: {
                formatter: (v: any) => counterFormat(v),
                style: light
                  ? undefined
                  : {
                      fill: '#F2FFFE',
                      fontSize: 12,
                    },
              },
            }}
            tooltip={{
              customContent: (title: any, items: any) => {
                const format = (date: string) =>
                  moment(date).format('D MMM YYYY  |  H:mm');
                return (
                  <div className='py-2 text-center'>
                    <p className='mb-3'>{format(title)}</p>
                    <div className='flex flex-col gap-3'>
                      {items.map((item: any, index: number) => (
                        <div
                          key={index.toString()}
                          className='flex items-center justify-between'
                        >
                          <div className='flex items-center pr-4'>
                            <span
                              style={{
                                backgroundColor: item?.color,
                                width: 10,
                                height: 10,
                              }}
                              className='mr-1 rounded-full'
                            />
                            <span>{item?.data?.severity}</span>
                          </div>
                          <span>
                            {`${item?.data?.value}`.replace(
                              /\d{1,3}(?=(\d{3})+$)/g,
                              (s) => `${s},`
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              },
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

const Header = ({
  light,
  filterByStatus,
  setFilterByStatus,
  loading,
}: {
  light?: boolean;
  filterByStatus: StatusFindingType;
  setFilterByStatus: (filterByStatus: StatusFindingType) => void;
  loading: boolean;
}) => {
  const { orgID, apiID, appID } = useParams<{
    orgID: string;
    apiID?: string;
    appID?: string;
  }>();
  const textColor = light ? '' : 'text-anti-flash';
  const linkTextColor = light ? '' : 'text-gray-300';
  const dark = !light;

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <Dropdown
            dark={dark}
            menu={{
              items: LIST_OF_API_STATUS_FINDINGS.filter(
                (status) => status !== filterByStatus
              ).map((status) => ({
                key: status,
                label: status,
              })),
              onClick: (item) =>
                setFilterByStatus(item.key as StatusFindingType),
            }}
            label={
              <span className='capitalize'>
                {LIST_OF_API_STATUS_FINDINGS.find(
                  (status) => status === filterByStatus
                )}
              </span>
            }
            dropdownRender={(menu) => (
              <div className={dark ? 'dropdown-content-dark' : ''}>{menu}</div>
            )}
          />
          <span className={`${textColor} text-xl`}>Findings by Severity</span>
        </div>

        <Loader spinning={loading} />
      </div>

      <div className='justify-end items-start gap-1 inline-flex'>
        <Link
          to={
            apiID
              ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/findings`
              : `/organisations/${orgID}/posture-management/findings`
          }
          className='inline-flex hover:opacity-80 items-center'
        >
          <div
            className={`text-right ${linkTextColor} text-sm font-normal leading-none tracking-tight`}
          >
            View findings
          </div>
          <MdOutlineExpandMore
            className={`${linkTextColor} transition-all focus:outline-none -rotate-90`}
            size={16}
          />
        </Link>
      </div>
    </div>
  );
};

export default DashboardFindingsMetricsLineChart;
