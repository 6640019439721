import { useAppSelector } from '@/app/hooks';
import { dashboardFiltersSelectors } from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import DashboardFiltersGroupsConditionalsRows from './DashboardFiltersGroups/DashboardFiltersGroupsConditionalsRows';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import DashboardFiltersGroupsFooter from './DashboardFiltersGroups/DashboardFiltersGroupsFooter';
import { Children, cloneElement } from 'react';

interface DashboardFiltersGroupsProps {
  dark?: boolean;
  handleAddFilterGroup?: () => void;
  handleAddCondition?: (filter: FilterGroupType) => void;
  collapse?: boolean;
  hasWrapper?: boolean;
  handleEditCondition?: (
    filter: FilterGroupType,
    condition: ConditionType
  ) => void;
  disable?: boolean;
  showSaveLoad?: boolean;
  children?: React.ReactElement[] | React.ReactElement;
  size?: 'small' | 'large';
  maxHeight?: string;
  hideFooter?: boolean;
}

const DashboardFiltersGroups = ({
  dark = false,
  handleAddFilterGroup,
  handleAddCondition,
  handleEditCondition,
  collapse = false,
  hasWrapper = false,
  disable = false,
  showSaveLoad = true,
  children,
  size = 'large',
  maxHeight,
  hideFooter,
}: DashboardFiltersGroupsProps) => {
  const myFilters = useAppSelector(dashboardFiltersSelectors.selectAll);

  if (hasWrapper && myFilters.length === 0) return <></>;

  const renderChildren = () => {
    return Children.map(children, (child) => {
      if (child)
        return cloneElement(child, {
          dark,
        });
    });
  };

  return (
    <div id='filters-dark' className={`${collapse ? 'hidden' : ''}`}>
      <DashboardFiltersGroupsConditionalsRows
        dark={dark}
        handleAddCondition={handleAddCondition}
        handleEditCondition={handleEditCondition}
        disable={disable}
        size={size}
        maxHeight={maxHeight}
      />
      <DashboardFiltersGroupsFooter
        disable={disable}
        dark={dark}
        handleAddFilterGroup={handleAddFilterGroup}
        showSaveLoad={showSaveLoad}
      />

      {renderChildren()}
    </div>
  );
};

export default DashboardFiltersGroups;
