import { RootState } from '@/app/store';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export const dashboardFiltersAdapter = createEntityAdapter<FilterGroupType>({
  selectId: (filter) => filter.id,
});

const dashboardFiltersSlice = createSlice({
  name: 'dashboardFilters',
  initialState: dashboardFiltersAdapter.getInitialState(),
  reducers: {
    filterSetted: dashboardFiltersAdapter.setOne,
    filterConditionUpdatedOrRemovedOne: (
      state,
      action: PayloadAction<{
        selectedFilter: FilterGroupType;
        selectedCondition: ConditionType;
        conditionUpdated?: ConditionType;
      }>
    ) => {
      const { selectedFilter, selectedCondition, conditionUpdated } =
        action.payload;

      const conditionIndex = selectedFilter.groups.findIndex(
        (c) => c.id === selectedCondition.id
      );

      if (conditionIndex > -1) {
        const conditions = [...selectedFilter.groups];

        if (conditionUpdated) {
          // Update condition
          conditions[conditionIndex] = {
            ...conditionUpdated,
            id: selectedCondition.id,
          };
        } else {
          // Delete condition
          conditions.splice(conditionIndex, 1);
        }

        if (conditions.length > 0) {
          dashboardFiltersAdapter.updateOne(state, {
            id: selectedFilter.id,
            changes: { groups: conditions },
          });
        } else {
          dashboardFiltersAdapter.removeOne(state, selectedFilter.id);
        }
      }
    },
    filterUpdatedOne: dashboardFiltersAdapter.updateOne,
    filterRemoved: dashboardFiltersAdapter.removeOne,
    filterSetAll: dashboardFiltersAdapter.setAll,
  },
});

export const {
  filterSetted,
  filterRemoved,
  filterSetAll,
  filterUpdatedOne,
  filterConditionUpdatedOrRemovedOne,
} = dashboardFiltersSlice.actions;

export const dashboardFiltersSelectors =
  dashboardFiltersAdapter.getSelectors<RootState>(
    (state) => state.dashboardFilters
  );

export default dashboardFiltersSlice.reducer;
