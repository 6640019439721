import { FieldSize } from '@/utils/models/FieldType';

export const getFieldSize = (fieldSize: FieldSize): string => {
  switch (fieldSize) {
    case 'small':
      return 'col-span-1';
    case 'middle':
      return 'col-span-2';
    case 'large':
      return 'col-span-3';
    case 'extralarge':
      return 'col-span-4';
  }
};

export const getColSize = (colSize: FieldSize): string => {
  let colSpan = '';
  switch (colSize) {
    case 'small':
      colSpan = 'col-span-1';
      break;
    case 'middle':
      colSpan = 'col-span-2';
      break;
    case 'large':
      colSpan = 'col-span-3';
      break;
    case 'extralarge':
      colSpan = 'col-span-4';
      break;
  }
  return `${colSpan} grid grid-cols-4 gap-4`;
};

export const byteSize = (str: string) => new Blob([str]).size;
