import LIST_OF_DURATION_SELECTOR from '@/utils/constants/listOfDurationSelector';
import {
  StaticDateTime,
  StaticDateTimeMode,
} from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';
import { DatePicker, Radio, Space } from 'antd';
import moment, { Moment } from 'moment';

const { RangePicker } = DatePicker;

interface FindingsDatePickerProps {
  dateTime?: StaticDateTime;
  setDateTime: (value: StaticDateTime) => void;
}
const ListFilterDateTimeSelector = ({
  dateTime = {},
  setDateTime,
}: FindingsDatePickerProps) => {
  const handleSetDateTime = (dateTimeState: StaticDateTime) => {
    setDateTime(dateTimeState);
  };

  const handleModeOnChange = ({ mode }: { mode: StaticDateTimeMode }) => {
    const value: StaticDateTime = { mode, start: undefined, end: undefined };
    handleSetDateTime(value);
  };

  const handleRestartDatePicker = () => {
    const value: StaticDateTime = {
      mode: 'fixed',
      start: undefined,
      end: undefined,
    };
    handleSetDateTime(value);
  };
  const handleRangePickerOnChange = (range: any) => {
    if (range) {
      const value: StaticDateTime = {
        mode: 'fixed',
        start: range[0].utc().format(),
        end: range[1].utc().format(),
      };
      handleSetDateTime(value);
    } else handleRestartDatePicker();
  };

  return (
    <div className='flex flex-col gap-2 '>
      <p>Duration</p>

      <div className='md:flex md:justify-start'>
        <Radio.Group
          value={dateTime.mode}
          onChange={(evt) => handleModeOnChange({ mode: evt.target.value })}
        >
          {LIST_OF_DURATION_SELECTOR.map((i) => (
            <Radio.Button
              onClick={() => {
                if (i.key === dateTime.mode) setDateTime({});
              }}
              key={i.key}
              data-testid={i.key}
              value={i.key}
            >
              {i.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        {dateTime.mode === 'fixed' && (
          <Space direction='vertical' size={12} className='ml-2'>
            <RangePicker
              disabledDate={disabledDate}
              allowClear={false}
              showTime={{ format: 'HH:mm' }}
              format={(value) => moment(value).format('D MMM YYYY  |  H:mm')}
              value={
                dateTime.mode === 'fixed'
                  ? [
                      dateTime.start ? moment(dateTime.start) : null,
                      dateTime.end ? moment(dateTime.end) : null,
                    ]
                  : null
              }
              onChange={handleRangePickerOnChange}
            />
          </Space>
        )}
      </div>
    </div>
  );
};

const disabledDate = (current: Moment, startDate?: string | null) => {
  if (!current) return false;
  const start = startDate ? moment(startDate) : moment().subtract(90, 'days');
  const currentEndOfDay = moment(current).endOf('day');
  return currentEndOfDay < start || currentEndOfDay > moment().endOf('day');
};

export default ListFilterDateTimeSelector;
