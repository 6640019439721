import MethodTag from '@/components/Tag/MethodTag';
import RequestTag from '@/components/Tag/RequestTag';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';

const TagContentRender = (props: any) => {
  const { label, closable, onClose, typeValue } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const name = typeof label === 'string' ? label : label?.props?.name;

  if (typeof name !== 'string') return <></>;

  if (typeValue === 'tags') {
    return (
      <RequestTag
        showTooltip={false}
        tagValue={name}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      />
    );
  }

  if (typeValue === 'response.statusCode') {
    return (
      <StatusCodeTag
        showTooltip={false}
        code={name}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      />
    );
  }

  if (typeValue === 'request.method') {
    return (
      <MethodTag
        showTooltip={false}
        name={name}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      />
    );
  }

  return <></>;
};

export default TagContentRender;
