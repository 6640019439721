import { FilterClass } from '@/utils/models/Filter';
import { Form, Select } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

const OperatorField = ({
  onChange,
  typeSelected,
}: {
  onChange: () => void;
  typeSelected?: FilterClass;
}) => (
  <Form.Item
    className='mt-4'
    key='operator'
    name='operator'
    label='Operator'
    rules={[{ required: true, message: 'Operator is required' }]}
  >
    <Select
      data-testid='select-operator'
      placeholder='Select operator'
      onChange={onChange}
      suffixIcon={
        <MdOutlineKeyboardArrowDown size={16} className='text-[#262d35]' />
      }
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => {
        const value = option?.children || option?.value;
        if (typeof value !== 'string') return false;
        return value.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {typeSelected &&
        typeSelected.operators.map((op) => {
          return (
            <Select.Option data-testid={op} key={op} value={op}>
              {op.replace(/-/g, ' ')}
            </Select.Option>
          );
        })}
    </Select>
  </Form.Item>
);

export default OperatorField;
