import {
  ActionsQuotaType,
  AnomalyAlertingQuotaType,
  GenericQuotaType,
  IncidentPoliciesQuotaType,
  IntegrationsQuotaType,
  OrganizationQuotaType,
  QuotaClass,
  QuotaInterface,
  StaticAlertingQuotaType,
} from '@/utils/models/Quota';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { listPlans } from '../billing/billing-export-data';

type ResourcesType =
  | 'actions'
  | 'logging'
  | 'incident_policies'
  | 'api'
  | 'api_token'
  | 'app'
  | 'app_token'
  | 'static_alerting'
  | 'anomaly_alerting'
  | 'saved_filters'
  | 'specification'
  | 'specification_version'
  | 'integrations'
  | 'findings'
  | 'organization';

export interface QuotasForLoggingDurationResponse {
  durations: { label: string; value: number; key: string; info: string }[];
  retention_days: number;
}

type PlanQuotaRow = {
  key: string;
  value: string;
  indexOrder: number;
};

type PlanQuotaSection = {
  title: string;
  rows: PlanQuotaRow[];
  indexOrder: number;
};

interface PlanQuotasResponse {
  planUUID: string;
  sections: PlanQuotaSection[];
}

export interface QuotaUsageItem {
  current: number;
  max: number;
  name: string;
  suffix?: string;
}

export interface QuotaUsage {
  [category: string]: QuotaUsageItem[];
}

export interface UsageData {
  plan_uuid: string;
  quotas: QuotaUsage;
}

function createQuotaEndpoint<T>(resourceName: ResourcesType) {
  return baseApi.injectEndpoints({
    endpoints: (build) => ({
      [resourceName]: build.query<T, OrgGenericParams>({
        query: ({ orgID }) =>
          `/organisations/${orgID}/quotas/resource/${resourceName}?get=quotas`,
        transformResponse: (rawResult: { quota: T }) => rawResult.quota,
        providesTags: ['Quotas'],
      }),
    }),
  });
}

export const anomalyAlertingQuotaEndpoint =
  createQuotaEndpoint<AnomalyAlertingQuotaType>('anomaly_alerting');

export const staticAlertingQuotaEndpoint =
  createQuotaEndpoint<StaticAlertingQuotaType>('static_alerting');

export const integrationsQuotaEndpoint =
  createQuotaEndpoint<IntegrationsQuotaType>('integrations');

const organisationQuotaEndpoint =
  createQuotaEndpoint<OrganizationQuotaType>('organization');

export const incidentsPolicyQuotaEndpoint =
  createQuotaEndpoint<IncidentPoliciesQuotaType>('incident_policies');

export const appQuotaEndpoint = createQuotaEndpoint<GenericQuotaType>('app');

export const apiQuotaEndpoint = createQuotaEndpoint<GenericQuotaType>('api');

const appTokenQuotaEndpoint =
  createQuotaEndpoint<GenericQuotaType>('app_token');

const apiTokenQuotaEndpoint =
  createQuotaEndpoint<GenericQuotaType>('api_token');

export const specificationQuotaEndpoint =
  createQuotaEndpoint<GenericQuotaType>('specification');

export const savedFiltersQuotaEndpoint =
  createQuotaEndpoint<GenericQuotaType>('saved_filters');

export const actionsQuotaEndpoint =
  createQuotaEndpoint<ActionsQuotaType>('actions');

export const quotaEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listQuotas: build.query<QuotaClass, OrgGenericParams>({
      query({ orgID }) {
        return `/organisations/${orgID}/quotas`;
      },
      transformResponse: (rawResult: { quotas: QuotaInterface }) =>
        new QuotaClass(rawResult.quotas),
      providesTags: ['Quotas'],
    }),
    getPlanQuotas: build.query<PlanQuotasResponse[], OrgGenericParams>({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        const plans = await listPlans(orgID);
        const plan_uuids = plans.map((plan) => plan.uuid);

        const quotasByPlanIDResult = await fetchWithBQ(
          `/organisations/${orgID}/quotas/plans?plan_uuids=${plan_uuids.join(
            ','
          )}`
        );

        const rawResult = quotasByPlanIDResult.data as {
          details: {
            items: { [key: string]: { [key: string]: string } }[];
            plan_uuid: string;
          }[];
        };

        const quotas: PlanQuotasResponse[] = rawResult.details.map((detail) => {
          const sections: PlanQuotaSection[] = [];

          for (const title in detail.items) {
            const rows: PlanQuotaRow[] = [];
            if (Object.prototype.hasOwnProperty.call(detail.items, title)) {
              const item = detail.items[title];

              for (const key in item) {
                if (Object.prototype.hasOwnProperty.call(item, key)) {
                  const value = item[key] as any;

                  let rowIndexOrder = 0;

                  if (title === 'Integrations') {
                    const mockRows = {
                      'Max active integrations': 1,
                      'AWS API Gateway Logging (FireHose)': 3,
                      'AWS AppSync Logging': 3,
                      'GitHub Repo API Discovery (Docker)': 3,
                      Jira: 3,
                      PagerDuty: 3,
                      'SMTP Email': 3,
                      ServiceNow: 3,
                      Splunk: 3,
                      Teams: 3,
                      'Wiz API Discovery': 3,
                    };

                    try {
                      // @ts-ignore
                      rowIndexOrder = mockRows[key] ?? 2;
                    } catch (e) {
                      rowIndexOrder = 2;
                    }
                  }

                  rows.push({
                    key,
                    value,
                    indexOrder: rowIndexOrder,
                  });
                }
              }
            }

            let sectionIndexOrder = 2;
            if (title === 'APIs') sectionIndexOrder = 0;
            if (title === 'Logging') sectionIndexOrder = 1;

            sections.push({
              title,
              rows: rows.sort((a, b) => a.indexOrder - b.indexOrder),
              indexOrder: sectionIndexOrder,
            });
          }

          return {
            planUUID: detail.plan_uuid,
            sections: sections.sort((a, b) => a.indexOrder - b.indexOrder),
          };
        });

        return { data: quotas };
      },
    }),
    getQuotasForLoggingDuration: build.query<
      QuotasForLoggingDurationResponse,
      OrgGenericParams
    >({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        const quotasForLoggingDurationResult = await fetchWithBQ(
          `/organisations/${orgID}/quotas/logging/duration?get=quotas`
        );

        const quotas = quotasForLoggingDurationResult.data as {
          durations: { name: string; value: number }[];
          retention_days: number;
        };

        const transformedDurations = quotas.durations.map((item) => ({
          key: item.name.toLowerCase().replace(/\s/g, '-'),
          label: item.name,
          info: `Data loaded from the ${item.name.toLowerCase()}`,
          value: item.value,
        }));

        transformedDurations.push({
          value: -1, // -1 is the value for fixed field.
          info: 'Data loaded from a custom range',
          key: 'fixed',
          label: 'Custom',
        });

        return {
          data: {
            durations: transformedDurations,
            retention_days: quotas.retention_days,
          },
        };
      },
    }),
    getQuotaUsage: build.query<UsageData, OrgGenericParams>({
      query({ orgID }) {
        return `/organisations/${orgID}/quotas/usage`;
      },
      providesTags: ['Quotas'],
    }),
  }),
});

export const {
  useListQuotasQuery,
  useLazyListQuotasQuery,
  useLazyGetQuotasForLoggingDurationQuery,
  useGetQuotasForLoggingDurationQuery,
  useGetPlanQuotasQuery,
  useLazyGetQuotaUsageQuery,
  useGetQuotaUsageQuery,
} = quotaEndpoints;

export const { useAnomaly_alertingQuery: useGetAnomalyAlertingQuotasQuery } =
  anomalyAlertingQuotaEndpoint;

export const { useStatic_alertingQuery: useGetStaticAlertingQuotasQuery } =
  staticAlertingQuotaEndpoint;

export const { useIntegrationsQuery: useGetIntegrationsQuotasQuery } =
  integrationsQuotaEndpoint;

export const { useOrganizationQuery: useGetOrganisationQuotasQuery } =
  organisationQuotaEndpoint;

export const { useIncident_policiesQuery: useGetIncidentsPolicyQuotasQuery } =
  incidentsPolicyQuotaEndpoint;

export const { useAppQuery: useGetApplicationQuotasQuery } = appQuotaEndpoint;

export const { useApiQuery: useGetApiQuotasQuery } = apiQuotaEndpoint;

export const { useApp_tokenQuery: useGetAppTokenQuotasQuery } =
  appTokenQuotaEndpoint;

export const { useApi_tokenQuery: useGetApiTokenQuotasQuery } =
  apiTokenQuotaEndpoint;

export const { useSpecificationQuery: useGetSpecificationQuotasQuery } =
  specificationQuotaEndpoint;

export const { useSaved_filtersQuery: useGetSavedFiltersQuotasQuery } =
  savedFiltersQuotaEndpoint;

export const { useActionsQuery: useGetActionsQuotasQuery } =
  actionsQuotaEndpoint;

export default quotaEndpoints;
