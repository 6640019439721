import { Tooltip } from 'antd';
import { ReactNode } from 'react';

const ItemTitle = ({
  children,
  tooltipTitle,
}: {
  children: ReactNode;
  tooltipTitle?: string;
}) => {
  let component = (
    <div
      className='text-gray-800 text-lg font-semibold 
      leading-tight tracking-wide break-all line-clamp-1'
      data-testid={children}
    >
      {children}
    </div>
  );

  if (tooltipTitle) {
    component = <Tooltip title={tooltipTitle}>{component}</Tooltip>;
  }

  return component;
};

export default ItemTitle;
