import { SeverityFindingType } from '@/utils/models/Finding';
import { useUpdateFindingSeverityMutation } from '@/utils/services/finding/finding-endpoints';
import { useParams } from 'react-router-dom';
import LIST_OF_SEVERITY_FINDINGS from '@/utils/constants/listOfSeverityFindings';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SeverityTag from '@/components/Tag/SeverityTag';
import DataDisplay from '@/components/DataDisplay';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import useRole from '@/utils/hooks/useRole';
import useStaticDateTimeQueryParam from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';

const FindingsSelectSeverity = ({
  findingID,
  severity: currentSeverity,
  buttonMinWidth,
  buttonClassName,
}: {
  findingID: string;
  severity: SeverityFindingType;
  buttonMinWidth?: string;
  buttonClassName?: string;
}) => {
  const { hasPermissionToUpdateFinding } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [updateSeverity] = useUpdateFindingSeverityMutation();

  const { filters } = useFiltersQueryParam();
  const { dateTime } = useStaticDateTimeQueryParam();

  const handleUpdateSeverity = async (newSeverity: SeverityFindingType) => {
    await updateSeverity({
      orgID,
      findingID,
      currentSeverity,
      newSeverity,
      filters,
      dateTime,
    });
  };

  const handleConfirmSeverityUpdate = (
    severityToUpdate: SeverityFindingType
  ) => {
    Modal.confirm({
      title: 'Confirm severity update',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className='pt-2'>
          <span className='text-gray-500'>From:</span>
          <SeverityTag severity={currentSeverity} />
          <br />
          <span className='text-gray-500 pt-2'>To:</span>
          <SeverityTag severity={severityToUpdate} />
        </div>
      ),
      okText: 'Update',
      cancelText: 'Cancel',
      onOk: () => handleUpdateSeverity(severityToUpdate),
    });
  };

  return (
    <DataDisplay.Dropdown
      trigger={['click']}
      menu={{
        items: LIST_OF_SEVERITY_FINDINGS.filter(
          (severity) => severity !== currentSeverity
        ).map((severity) => ({
          label: <SeverityTag severity={severity} />,
          key: severity,
        })),
        onClick: (evt) => {
          evt.domEvent.stopPropagation();
          const { key: severity } = evt;
          handleConfirmSeverityUpdate(severity as SeverityFindingType);
        },
      }}
      disabled={!hasPermissionToUpdateFinding}
      buttonClassName={`border border-zinc-200 px-2 py-1 rounded-sm min-w-[${
        buttonMinWidth || '10rem'
      }] ${buttonClassName}`}
    >
      <SeverityTag severity={currentSeverity} />
    </DataDisplay.Dropdown>
  );
};

export default FindingsSelectSeverity;
