import { EventClass, EventInterface } from '@/utils/models/Event';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { getEventsMappings } from '../local-api-export-data';

export interface EventParams extends OrgGenericParams {
  eventID: string;
  supressErrors?: boolean;
}

export interface EventBulkParams extends OrgGenericParams {
  eventIDs: string[];
  supressErrors?: boolean;
}

interface ListEventsParams extends OrgGenericParams {
  marker?: string | null;
}

export interface ListEventsResponse {
  events: EventClass[];
  marker: string | null;
}

export const eventEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listEvents: build.query<ListEventsResponse, ListEventsParams>({
      async queryFn({ orgID, marker }, _queryApi, _extraOptions, fetchWithBQ) {
        const LIMIT = 20;

        let url = `/organisations/${orgID}/events?limit=${LIMIT}`;
        if (marker) url = url.concat(`&marker=${marker}`);

        const eventsResult = await fetchWithBQ(url);
        const { events, marker: newMarker } = eventsResult.data as {
          events: EventInterface[];
          marker: string | null;
        };

        const mappingsResult = await getEventsMappings();

        return {
          data: {
            events: events.map(
              (event) =>
                new EventClass(event, {
                  mapping: mappingsResult
                    ? mappingsResult[event.code]
                    : undefined,
                })
            ),
            marker: newMarker,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.events.map(({ UUID }) => ({
                type: 'Events' as const,
                id: UUID,
              })),
              { type: 'Events', id: 'LIST' },
            ]
          : [{ type: 'Events', id: 'LIST' }],
    }),
    getEvent: build.query<EventClass, EventParams>({
      async queryFn(
        { orgID, eventID, supressErrors },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        try {
          const eventResult = await fetchWithBQ(
            `/organisations/${orgID}/events/${eventID}${
              supressErrors ? '?ntae' : '' // ntae = not throw an error
            }`
          );
          const { event } = eventResult.data as {
            event: EventInterface;
          };

          const mappingsResult = await getEventsMappings();

          return {
            data: new EventClass(event, {
              mapping: mappingsResult ? mappingsResult[event.code] : undefined,
            }),
          };
        } catch (e: any) {
          return { error: e.message };
        }
      },
      providesTags: (result, error, { eventID }) => [
        { type: 'Events', id: eventID },
      ],
    }),
    getEventsByUUIDs: build.query<EventClass[], EventBulkParams>({
      query: ({ orgID, eventIDs, supressErrors }) => ({
        url: `/organisations/${orgID}/events/bulk${
          supressErrors ? '?ntae' : ''
        }`,
        method: 'POST',
        data: {
          event_uuids: Array.from(new Set(eventIDs)),
        },
      }),
      transformResponse: async (rawResult: { events: EventInterface[] }) => {
        const mappingsResult = await getEventsMappings();

        return rawResult.events.map((event) => {
          return new EventClass(event, {
            mapping: mappingsResult ? mappingsResult[event.code] : undefined,
          });
        });
      },
    }),
  }),
});

export const {
  useListEventsQuery,
  useLazyListEventsQuery,
  useGetEventQuery,
  useLazyGetEventQuery,
  useGetEventsByUUIDsQuery,
  useLazyGetEventsByUUIDsQuery,
} = eventEndpoints;
