import {
  clearInterceptAxiosError,
  selectHttpError,
} from '@/utils/services/base-reducer';
import { Modal } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const HttpError = () => {
  const dispatch = useDispatch();
  const httpError = useSelector(selectHttpError);

  const removeItem = () => {
    sessionStorage.removeItem('httpError');
    dispatch(clearInterceptAxiosError());
  };

  useEffect(() => {
    if (httpError) {
      const httpErrorData = sessionStorage.getItem('httpError');
      if (httpErrorData) return;

      sessionStorage.setItem('httpError', JSON.stringify(httpError));

      Modal.error({
        title: httpError.title,
        content: httpError.content,
        onOk: removeItem,
      });
    } else removeItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpError]);

  return <></>;
};

export default HttpError;
