import { Modal, Tooltip } from 'antd';
import { IoMdStar } from 'react-icons/io';

export type DecisionItemType = {
  title: string;
  value: string;
  description?: string;
  icon?: React.ReactNode;
  isBeta?: boolean;
  disabled?: boolean;
  disabledText?: string;
  isUpgradeRequired?: boolean;
  tooltipText?: string;
};

const DecisionGroup = ({
  decisionOptions,
  handleDecisionMade,
}: {
  decisionOptions: DecisionItemType[];
  handleDecisionMade: (decision: DecisionItemType) => void;
}) => {
  const hasOneUpgradeRequired = decisionOptions.some(
    (option) => option.isUpgradeRequired
  );

  return (
    <div className='flex flex-row justify-center gap-4 items-stretch'>
      {decisionOptions.map((option) => {
        const {
          title,
          description,
          icon,
          value,
          isBeta,
          disabled,
          disabledText,
          isUpgradeRequired,
          tooltipText,
        } = option;

        const cardContent = (
          <div
            key={value}
            id={value}
            className={`border ${hasOneUpgradeRequired ? 'h-44' : ''} ${
              disabled
                ? 'bg-gray-100 opacity-70 cursor-not-allowed'
                : `border-gray-300 ${
                    isUpgradeRequired
                      ? 'hover:border-second'
                      : 'hover:border-gray-400'
                  } hover:shadow cursor-pointer`
            } self-auto rounded-lg shadow-sm transition duration-300 ease-in-out text-center 
            p-4 w-80 items-center flex flex-col gap-2 relative justify-center`}
            onClick={disabled ? undefined : () => handleDecisionMade(option)}
          >
            {isBeta && (
              <span className='text-xs rounded-full bg-gray-200 py-1 px-2 uppercase absolute right-2 top-2'>
                Beta
              </span>
            )}
            {icon}
            <p className='font-semibold text-xl'>{title}</p>
            {description && (
              <p className='text-gray-600 text-sm'>{description}</p>
            )}
            {isUpgradeRequired && (
              <div
                style={{
                  height: 40,
                  color: '#393A3F',
                  fontSize: 14,
                  borderColor: isUpgradeRequired ? '#F28606' : '#C7CACD',
                }}
                className='flex items-center font-semibold border rounded-md py-1 px-4 mt-2'
              >
                <div className='flex gap-2 items-center opacity-80'>
                  <IoMdStar color='#F28606' size={18} />
                  <span className=''>Upgrade Plan</span>
                </div>
              </div>
            )}
          </div>
        );

        if (disabled && disabledText) {
          return (
            <Tooltip title={disabledText} key={value}>
              {cardContent}
            </Tooltip>
          );
        }

        if (tooltipText) {
          return (
            <Tooltip title={tooltipText} key={value}>
              {cardContent}
            </Tooltip>
          );
        }

        return cardContent;
      })}
    </div>
  );
};

const ModalDecision = ({
  handleDecisionMade,
  onClose,
  open,
  options,
  title,
}: {
  handleDecisionMade: (decision: DecisionItemType) => void;
  open: boolean;
  onClose: () => void;
  options: DecisionItemType[];
  title: string;
}) => {
  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      width={800}
      open={open}
      style={{ top: 15 }}
      title={title}
      onCancel={onClose}
      footer={null}
      forceRender
    >
      <DecisionGroup
        decisionOptions={options}
        handleDecisionMade={(option) => {
          handleDecisionMade(option);
          onClose();
        }}
      />
    </Modal>
  );
};

export default ModalDecision;
