import FieldType from '@/utils/models/FieldType';
import { FieldBuilder } from '../Fields';
import { useEffect, useState } from 'react';
import { getFieldSize } from './const/functions';

const TabsField = ({
  field,
  fieldBuilder,
}: {
  field: FieldType;
  fieldBuilder?: FieldBuilder;
}) => {
  const [selectTab, setSelectTab] = useState<any | undefined>();
  useEffect(() => {
    if (field.values && field.values.length) {
      setSelectTab(field.values[0]);
    }
    return () => {
      setSelectTab(undefined);
    };
  }, [field]);

  return (
    <div
      className={`${getFieldSize(field.fieldSize || 'extralarge')} gap-0 mb-3`}
    >
      {field?.values?.map((field) => (
        <button
          key={field.attribute}
          className={`h-14 px-4 tab-button py-5 border border-zinc-400 justify-start items-start inline-flex ${
            field.attribute === selectTab?.attribute ? 'border-b-white' : ''
          }`}
          onClick={(evt) => {
            evt.preventDefault();
            setSelectTab(field);
          }}
          style={{ transform: 'translate(0px, 1px)', zIndex: 99999 }}
          disabled={field.disabled}
        >
          {field.value}
        </button>
      ))}
      {selectTab && fieldBuilder && fieldBuilder.getFormItem(selectTab)}
    </div>
  );
};

export default TabsField;
