import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Form, Input } from 'antd';
import { getColSize, getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const InputTextField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  if (field?.validation?.regex) {
    rules.push({
      pattern:
        typeof field.validation.regex === 'string'
          ? new RegExp(`${field.validation.regex}`, 'g')
          : field.validation.regex,
      message: field?.validation?.regex_description || '${label} is invalid',
    });
  }

  let input = (
    <Input
      type={field.htmlType || 'text'}
      maxLength={field.validation?.max || 128}
      minLength={field.validation?.min || 0}
      placeholder={field.placeholder}
      disabled={field.disabled}
    />
  );

  if (field?.sensitive) {
    input = (
      <Input.Password
        maxLength={field.validation?.max || 128}
        minLength={field.validation?.min || 0}
        placeholder={field.placeholder}
        disabled={field.disabled}
      />
    );
  }

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'middle')}
      >
        {input}
      </Form.Item>
    </div>
  );
};

export default InputTextField;
