import { ActionClass } from '@/utils/models/Action';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ActionPaginationResponse } from './actions-endpoints';

export const mockListActionsAfterAddition = ({
  draft,
  action,
}: {
  draft: MaybeDrafted<ActionPaginationResponse>;
  action: ActionClass;
}) => {
  action.dateAddedInMicroSeconds = new Date().getTime() * 1000;
  draft.action.unshift(action);
  return draft;
};

export const mockListActionsAfterDeletion = ({
  draft,
  actionID,
}: {
  draft: MaybeDrafted<ActionPaginationResponse>;
  actionID: string;
}) => {
  return {
    action: draft.action.filter((action) => action.UUID !== actionID),
    total: draft.total,
  };
};

export const mockListActionsAfterEdition = ({
  draft,
  action,
}: {
  draft: MaybeDrafted<ActionPaginationResponse>;
  action: ActionClass;
}) => {
  const index = draft.action.findIndex((a) => a.UUID === action.UUID);
  if (index !== -1) {
    draft.action[index] = action;
  }
  return draft;
};

export const mockGetActionAfterEdition = ({
  action,
}: {
  action: ActionClass;
}): MaybeDrafted<ActionClass> => {
  return {
    ...action,
    created: action.created,
    createdFromNow: action.createdFromNow,
    category:
      action.actionType === 'api_schedule_action' ? 'Schedule' : 'Event',
  };
};
