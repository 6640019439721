import { useState } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import FormsModal from '@/components/Modal/FormsModal';
import Fields from '@/components/Form/FieldComponents';
import Container from '@/components/Section/Container';
import List from '@/components/List';
import {
  useAddApplicationMutation,
  useListApplicationsQuery,
} from '@/utils/services/application/application-endpoints';
import Item from '@/components/List/Item';
import { ApplicationClass } from '@/utils/models/Application';
import { ColumnsType } from 'antd/lib/table';
import Heading from '@/components/Section/Heading';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetApplicationQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';

const Applications = () => {
  const { hasPermissionToCreateApplication } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const { pagination } = usePaginationQueryParam();
  const { query } = useSearchQueryParam();

  const { data: listApplicationsData, isLoading: isListApplicationsLoading } =
    useListApplicationsQuery({ orgID, query, ...pagination });

  const [addApplication, { isLoading: addApplicationIsLoading }] =
    useAddApplicationMutation();

  const { data: applicationQuota, isLoading: isAppQuotaLoading } =
    useGetApplicationQuotasQuery({
      orgID,
    });

  const upgradeRequired =
    applicationQuota &&
    (applicationQuota.current as number) >= (applicationQuota?.max as number);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const history = useHistory();

  const handleCloseModal = () => setShowCreateModal(false);

  const handleOpenModal = () => {
    if (applicationQuota) {
      if (upgradeRequired) {
        setIsPlanModalOpen(true);
        return;
      }
    }

    setShowCreateModal(true);
  };

  const handleCreateApp = async (values: { name: string }) => {
    await addApplication({
      orgID,
      data: {
        name: values.name.trim(),
      },
    }).unwrap();
    handleCloseModal();
  };

  const handleApplicationSelected = (application: ApplicationClass) =>
    history.push(
      `/organisations/${application.g_orgUUID}/applications/${application.UUID}`
    );

  const columns: ColumnsType<ApplicationClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, application) => (
        <button
          onClick={() => handleApplicationSelected(application)}
          className='text-base text-blue-600 font-semibold text-left'
        >
          {application.name}
        </button>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, application) => (
        <Item.Date
          createdFromNow={application.createdFromNow}
          created={application.created}
        />
      ),
    },
    {
      title: 'Resources',
      key: 'resources',
      render: (_, application) => {
        const baseURL = `/organisations/${application.g_orgUUID}/applications/${application.UUID}`;
        return (
          <Space size='middle'>
            <Link
              to={`${baseURL}/apis`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              APIs
            </Link>
            <Link
              to={`${baseURL}/tokens`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Tokens
            </Link>
            <Link
              to={`${baseURL}/specifications`}
              className='text-first-light px-2 py-2 hover:text-first hover:underline'
            >
              Specifications
            </Link>
          </Space>
        );
      },
    },
  ];

  const isLoading = isListApplicationsLoading || isAppQuotaLoading;

  return (
    <>
      <Billing.Modal
        titleText='You have reached your maximum amount of created applications.'
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <Heading text='Applications' />
      <Container>
        <FormsModal
          title='Create Application'
          confirmLoading={addApplicationIsLoading}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleCreateApp}
          showModal={showCreateModal}
          fields={[
            <Fields.InputText
              key='app-name'
              field={{
                attribute: 'name',
                name: 'Application Name',
                placeholder: 'my-app',
                validation: {
                  required: true,
                  regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s]+[a-zA-Z0-9]$`,
                  regex_description:
                    'The name must be alphanumeric and between 3 and 128 characters.',
                },
                inputType: 'text',
                fieldSize: 'extralarge',
                colSize: 'extralarge',
              }}
            />,
          ]}
        />

        <List.Root
          renderEmpty={{
            isEmpty: listApplicationsData?.total === 0 && !query,
            loading: isListApplicationsLoading,
            element: (
              <List.Empty
                title='Create an Application'
                subtitle='Create an application to group several APIs, making it easier to use filters, reports, alerts and integrations.'
                buttonTxt='Create Application'
                handleClick={handleOpenModal}
              />
            ),
          }}
        >
          <List.Header>
            <List.Search placeholder='Search applications' />
            <List.Settings>
              {hasPermissionToCreateApplication && (
                <Button
                  onClick={handleOpenModal}
                  disabled={isAppQuotaLoading}
                  type='primary'
                >
                  <div className='flex flex-row'>
                    Create Application
                    {upgradeRequired && (
                      <Tooltip title='Upgrade Required'>
                        <MdWarning
                          style={{ color: '#FFE25E' }}
                          size={16}
                          className='ml-2 self-center'
                        />
                      </Tooltip>
                    )}
                  </div>
                </Button>
              )}

              <List.QuotaIndicator
                quota={applicationQuota}
                tooltipText={`You are currently using ${
                  applicationQuota?.current as number
                } out of your ${
                  applicationQuota?.max as number
                } allocated applications.`}
              />
            </List.Settings>
            <List.Segmented />
          </List.Header>

          <List.Pagination
            dataSource={listApplicationsData?.apps}
            useURI
            total={listApplicationsData?.total}
          >
            <List.Grid
              item={{
                render: (application: ApplicationClass) => (
                  <Item.Root
                    key={application.key}
                    onClick={() => handleApplicationSelected(application)}
                    id={application.key}
                  >
                    <Item.Header>
                      <Item.Title>{application.name}</Item.Title>
                    </Item.Header>
                    <Item.Footer>
                      <Item.Date
                        createdFromNow={application.createdFromNow}
                        created={application.created}
                      />
                    </Item.Footer>
                  </Item.Root>
                ),
              }}
              loading={isLoading}
            />

            <List.Table columns={columns} loading={isLoading} />
          </List.Pagination>
        </List.Root>
      </Container>
    </>
  );
};

export default Applications;
