import TabType from '@/utils/models/TabType';
import { Tabs, Tooltip } from 'antd';
import { MdOutlineInfo } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

export type ListTabType = {
  label?: string | React.ReactNode;
  key: string;
  isBeta?: boolean;
  isNew?: boolean;
  tooltip?: string;
};

const ListTabs = ({
  onChange,
  tabs,
  selectedTab,
}: {
  onChange: (key: string) => void;
  tabs: ListTabType[];
  selectedTab?: string;
}) => {
  const location = useLocation();
  const path = location?.pathname.split('/');
  const currentTab = selectedTab || path[path.length - 1];

  const getTabType = (tab: ListTabType): TabType => ({
    label: (
      <div
        className='text-center text-gray-800 text-[16px] font-semibold
      leading-none tracking-wide flex gap-2 items-center capitalize'
      >
        {tab.label || tab.key}

        {tab.isNew && (
          <span className='text-xs rounded-lg bg-gray-200 p-1 uppercase flex items-center gap-1'>
            New
          </span>
        )}

        {tab.isBeta && (
          <span className='text-xs rounded-lg bg-gray-200 p-1 uppercase flex items-center gap-1'>
            Beta
          </span>
        )}

        {tab.tooltip && (
          <Tooltip
            className='cursor-pointer'
            title={<span className='text-first'>{tab.tooltip}</span>}
            color={'#F2F4F6'}
          >
            <MdOutlineInfo size={18} className='text-gray-800' />
          </Tooltip>
        )}
      </div>
    ),
    key: tab.key,
  });

  return (
    <Tabs
      onChange={onChange}
      items={tabs.map((tab) => getTabType(tab))}
      activeKey={currentTab}
    />
  );
};

export default ListTabs;
