import Dashboard from '@/components/Dashboard';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { nanoid } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const ApiDashboard = () => {
  const { appID, apiID } = useParams<{
    appID: string;
    apiID: string;
  }>();

  const defaultFilters = useMemo(() => {
    const appCondition: ConditionType = {
      id: nanoid(),
      field: 'appUUID',
      operator: 'is-one-of',
      values: [appID],
      disabled: true,
    };
    const apiCondition: ConditionType = {
      id: nanoid(),
      field: 'apiUUID',
      operator: 'is-one-of',
      values: [apiID],
      disabled: true,
    };
    const filtersData: FilterGroupType[] = [
      {
        id: nanoid(),
        operator: 'include',
        groups: [appCondition, apiCondition],
        disabled: true,
      },
    ];
    return filtersData;
  }, [apiID, appID]);

  return (
    <>
      <div className='flex flex-col'>
        <div className='bg-white p-2 rounded-sm mb-0'>
          <Dashboard.Filters.Root defaultFilters={defaultFilters}>
            <Dashboard.Filters.Groups />

            <Dashboard.Filters.SelectorsWrapper>
              <Dashboard.Filters.SelectPollingInterval />
              <Dashboard.Filters.SelectDateTime />
              <Dashboard.Filters.CreateAlertButton />
            </Dashboard.Filters.SelectorsWrapper>
          </Dashboard.Filters.Root>
        </div>
      </div>
      <div
        className='grid lg:grid-cols-2 grid-cols-1 py-2 gap-x-2 gap-y-2'
        style={{ minHeight: '64vh' }}
      >
        <div className='bg-white p-8'>
          <Dashboard.RequestsLineChart light />
        </div>

        <div className='bg-white p-8'>
          <Dashboard.RequestsMapChart light />
        </div>

        <div className='bg-white p-8'>
          <Dashboard.RequestsDonutChart light />
        </div>

        <div className='bg-white p-8'>
          <Dashboard.FindingsMetricsLineChart light />
        </div>
      </div>
    </>
  );
};

export default ApiDashboard;
