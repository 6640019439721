import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  loadingStates: Record<string, boolean>;
}

const initialState: UIState = {
  loadingStates: {},
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setLoadingState: (
      state,
      action: PayloadAction<{ endpointId: string; isLoading: boolean }>
    ) => {
      const { endpointId, isLoading } = action.payload;
      state.loadingStates[endpointId] = isLoading;
    },
  },
});

export const { setLoadingState } = alertSlice.actions;
export default alertSlice.reducer;
