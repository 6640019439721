import { Empty, Tag, Tooltip } from 'antd';
import { GiPadlock, GiPadlockOpen } from 'react-icons/gi';
import { useMemo } from 'react';
import MethodTag from '../Tag/MethodTag';
import StatusCodeTag from '../Tag/StatusCodeTag';
import { useParams } from 'react-router-dom';
import { useGetSpecificationQuery } from '@/utils/services/specification/specification-endpoints';
import { SpecificationVersionClass } from '@/utils/models/SpecificationVersion';
import { EndpointDetailInterface } from '@/utils/models/SpecificationVersionDetail';
import { useAppSelector } from '@/app/hooks';
import { dashboardFiltersSelectors } from '../Dashboard/DashboardFilters/dashboardFiltersSlice';
import {
  getActiveFilterGroupKey,
  getSelectedEndpoint,
} from '@/utils/methods/specification';

const Parameters = ({
  endpoint,
  type,
}: {
  endpoint: any;
  type: 'path' | 'query' | 'header';
}) => {
  const items: any[] = endpoint.parameters.filter(
    (param: any) => param?.in === type
  );

  if (items.length > 0) {
    return (
      <div className='mb-4'>
        <h4 className='text-lg font-medium mb-4 capitalize'>
          {type} Parameters
        </h4>
        {items.map((param: any, index: number) => (
          <div key={index.toString()} className='pb-2'>
            <div className='flex items-center justify-between mb-2 w-2/5 hover:bg-gray-50 px-2 py-2 rounded-xl'>
              <div>
                <Tooltip title={param?.description}>
                  <span className='font-semibold pr-2'>{param?.name}</span>
                </Tooltip>
                <span className='text-gray-500'>{param?.schema?.type}</span>
              </div>
              {param?.required === true ? (
                <span className='text-orange-500'>required</span>
              ) : (
                <span className='text-green-500'>optional</span>
              )}
            </div>
            {param?.example && (
              <span className='text-gray-500 opacity-60'>
                Example: <Tag>{param?.example}</Tag>
              </span>
            )}
          </div>
        ))}
      </div>
    );
  }

  return <></>;
};

const OpenAPIContent = ({
  latestVersion,
  endpoint,
}: {
  endpoint: EndpointDetailInterface;
  latestVersion: SpecificationVersionClass;
}) => {
  const unauthed: boolean = useMemo(() => {
    let unauthedEndpoint: boolean =
      Object.keys(
        latestVersion?.specification?.components?.securitySchemes || {}
      ).length === 0;

    if (endpoint.security) {
      unauthedEndpoint = endpoint.security.length === 0;
    }

    return unauthedEndpoint;
  }, [latestVersion, endpoint]);

  return (
    <div className='w-full py-2'>
      <div className='mb-10'>
        {endpoint.summary && (
          <h2 className='text-3xl font-semibold mb-4 capitalize'>
            {endpoint.summary}
          </h2>
        )}

        <div className='flex items-center justify-between'>
          <div className='px-3 py-2 bg-gray-50 rounded-xl flex'>
            <MethodTag name={endpoint.method} />{' '}
            <span className='font-semibold'>{endpoint.path}</span>
          </div>
          <div>
            {unauthed ? (
              <span
                className='capitalize text-orange-500 font-normal px-3 py-2 text-lg rounded-lg flex items-center'
                style={{
                  borderColor: '#F87315',
                  borderWidth: '1px',
                }}
              >
                unauthenticated
                <GiPadlockOpen className='ml-2' size={20} />
              </span>
            ) : (
              <span
                className='capitalize text-green-500 font-normal p-2 text-lg rounded-lg flex items-center'
                style={{
                  borderColor: '#21C55D',
                  borderWidth: '1px',
                }}
              >
                authenticated
                <GiPadlock className='ml-2' size={20} />
              </span>
            )}
          </div>
        </div>

        {endpoint.description && <p className='mt-3'>{endpoint.description}</p>}
      </div>

      {endpoint.parameters && (
        <div className='mb-10'>
          <h3 className='text-2xl font-semibold mb-4'>Request</h3>

          <Parameters type='path' endpoint={endpoint} />
          <Parameters type='query' endpoint={endpoint} />
          <Parameters type='header' endpoint={endpoint} />
        </div>
      )}

      {endpoint.responses &&
        endpoint.responses.map((response, index) => (
          <div className='mb-10' key={index.toString()}>
            <div className='flex items-center mb-4'>
              <span className='text-2xl pb-1 font-semibold mr-2'>
                Responses
              </span>
              <StatusCodeTag code={response.statusCode} />
            </div>
            <p className='text-gray-700'>{response.description}</p>

            {response.headers && response.headers.length > 0 && (
              <div>
                <h4 className='text-lg font-medium my-4 capitalize'>Headers</h4>

                {response.headers.map((item, index) => (
                  <div
                    key={index.toString()}
                    className='flex items-center justify-between'
                  >
                    <p>
                      <span className='font-semibold pr-2'>{item.header}</span>
                      <span className='text-gray-500'>
                        {item?.schema?.type}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const SpecificationEndpointDetails = () => {
  const { orgID, specificationID } = useParams<{
    orgID: string;
    specificationID: string;
  }>();
  const { data: specification } = useGetSpecificationQuery({
    orgID,
    specificationID,
  });
  const filters = useAppSelector(dashboardFiltersSelectors.selectAll);

  if (!specification) return <></>;

  const { version: latestVersion } = specification;
  if (!latestVersion) return <></>;

  const { endpoints } = latestVersion;

  const activeEndpointKey = getActiveFilterGroupKey(filters);
  const selectedEndpoint = getSelectedEndpoint(endpoints, activeEndpointKey);

  return (
    <>
      <div className='flex justify-between my-3 items-center'>
        <div className='flex items-center'>
          <span className='text-lg font-semibold capitalize'>
            {latestVersion.specification?.info?.title}
          </span>
        </div>

        <div className='text-xs'>
          <span className='mx-3'>
            OpenAPI: {latestVersion.specification?.openapi}
          </span>
          <span>Version: {latestVersion.specification?.info?.version}</span>
        </div>
      </div>
      <div className='mb-3'>
        <span className='text-xs'>
          {latestVersion.specification?.info?.description}
        </span>
      </div>

      {selectedEndpoint ? (
        <OpenAPIContent
          latestVersion={latestVersion}
          endpoint={selectedEndpoint}
        />
      ) : (
        <section data-testid='show-empty' className='my-28'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </section>
      )}
    </>
  );
};

export default SpecificationEndpointDetails;
