export interface UserInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  dateLastActiveInMicroSeconds: number;
  email: string;
  itemType: string;
  status: string;
  sub: string;
  AWSMarketplaceFlow?: boolean;
}

export class UserClass implements UserInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  dateLastActiveInMicroSeconds: number;
  email: string;
  itemType: string;
  status: string;
  sub: string;
  AWSMarketplaceFlow?: boolean;

  constructor(data: UserInterface) {
    this.UUID = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.dateLastActiveInMicroSeconds = data.dateLastActiveInMicroSeconds;
    this.email = data.email;
    this.itemType = data.itemType;
    this.status = data.status;
    this.sub = data.sub;
    this.AWSMarketplaceFlow = data.AWSMarketplaceFlow;
  }

  get isActive(): boolean {
    return this.status === 'active';
  }

  get isAWSMarketplaceFlowPending(): boolean {
    return this.AWSMarketplaceFlow === true;
  }
}
