import { ApiClass, ApiInterface } from '@/utils/models/Api';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { FieldsData } from '@/utils/models/FieldType';
import { getFindingsOverview } from '../finding/finding-export-data';
import {
  FindingsBulkResponse,
  FindingsOverviewResponse,
} from '../finding/finding-endpoints';
import { ApplicationClass } from '@/utils/models/Application';
import { getMockCacheListing, mockListApisAfterDeletion } from './api-methods';
import {
  getApplication,
  listApplications,
} from '../application/application-export-data';
import { listApis } from './api-export-data';
import { apiQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';
import { setLoadingState } from '@/components/Apis/ApiSlice';
import { getRequestTags } from '../org/org-export-data';
import { RequestTagInterface } from '@/utils/models/RequestTag';

export interface AppGenericParams extends OrgGenericParams {
  appID: string;
}

interface ApiGenericParams extends AppGenericParams {
  apiID: string;
}

interface GetApiParams extends OrgGenericParams {
  apiID: string;
  fetchSeverities?: boolean;
}

interface GetApiLinkedRepoParams extends OrgGenericParams {
  appID: string;
  apiID: string;
}

interface ListAvailableLinksParams extends OrgGenericParams {
  marker?: number;
  size?: number;
  data: {
    sort?: { field: string; order: 'asc' | 'desc' };
    search_value?: string;
    filters?: FieldsData[];
  };
}

interface ListOrgLinksParams extends ListAvailableLinksParams {
  appID: string;
  apiID: string;
}

interface LinkRepoParams extends GetApiLinkedRepoParams {
  destinationID: string;
}

export interface ListApisParams extends OrgGenericParams {
  filters?: FieldsData[];
  pageSize?: number;
  marker?: number;
  fetchSeverities?: boolean;
  fetchApplication?: boolean;
  query?: string;
  skipBulk?: boolean;
}

export interface ListApisPagination {
  apis: ApiClass[];
  marker?: number;
  total: number;
  isEmpty: boolean;
}

export interface ListApisLinkResponse {
  apis: ApiClass[];
  marker?: number;
  total: number;
}

interface AddApiParams extends AppGenericParams {
  data: { name: string; api_type: string };
  filters?: FieldsData[];
}

interface DeleteApiParams extends ListApisParams, ApiGenericParams {}

interface LoggingAddParams extends ApiGenericParams {
  data: { integration_uuid: string; stage_name: string };
}

export interface LinkedRepo {
  UUID: string;
  appUUID: string;
  automated: boolean;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  dateModifiedInMicroSeconds: number;
  destination_apiUUID: string;
  g_orgUUID: string;
  itemType: string;
  repoType: string;
  source_apiUUID: string;
  status: string;
}

interface LinksResponse {
  links: LinkedRepo[];
  marker: null | number;
  total: number;
}

export const apiEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listApis: build.query<ListApisPagination, ListApisParams>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const {
          orgID,
          filters,
          pageSize,
          marker,
          fetchSeverities,
          fetchApplication,
          query,
          skipBulk,
        } = args;

        if (fetchSeverities)
          sessionStorage.setItem('listApisArgs', JSON.stringify(args));
        const params: string[] = ['resource=apis'];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);

        const url = `/organisations/${orgID}/search?${params.join('&')}`;
        const searchResult = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            resource: 'api',
            filters,
            sort: { order: 'desc' },
            search_value: query,
          },
        });
        const {
          apis,
          marker: nextMarker,
          total,
        } = searchResult.data as {
          apis: ApiInterface[];
          marker?: number;
          total: number;
        };

        const isEmpty = (!filters || filters.length === 0) && total === 0;

        let findingsBulk: FindingsBulkResponse;
        if (apis.length > 0 && fetchSeverities && !skipBulk) {
          findingsBulk = await getFindingsOverview({
            orgID,
            apiUUIDItems: apis.map((a) => a.UUID),
          });
        }

        let applications: ApplicationClass[] | undefined;
        if (apis.length > 0 && fetchApplication) {
          const appUUIDs = new Set(apis.map((api) => api.api_appUUID));
          const appFilters = [
            { field: 'appUUID', values: Array.from(appUUIDs) },
          ];
          applications = await listApplications({
            orgID,
            filters: appFilters,
          });
        }

        let tagsData: RequestTagInterface[] = [];
        if (apis.findIndex((a) => !!a.observedTags) > -1) {
          tagsData = await getRequestTags(orgID);
        }

        return {
          data: {
            apis: apis.map((api) => {
              let severities: Partial<FindingsOverviewResponse> | undefined;
              if (findingsBulk && findingsBulk[api.UUID]) {
                severities = findingsBulk[api.UUID].severities;
              }

              const application = applications?.find(
                (app) => app.UUID === api.api_appUUID
              );

              let requestTags: RequestTagInterface[] = [];
              if (api.observedTags && tagsData.length > 0) {
                requestTags = tagsData.filter((t) =>
                  api.observedTags?.includes(t.key)
                );
              }

              return new ApiClass(api, {
                severities,
                application,
                requestTags,
              });
            }),
            marker: nextMarker,
            total,
            isEmpty,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.apis.map(({ UUID }) => ({
                type: 'Apis' as const,
                id: UUID,
              })),
              { type: 'Apis', id: 'LIST' },
            ]
          : [{ type: 'Apis', id: 'LIST' }],
    }),
    getApi: build.query<ApiClass, GetApiParams>({
      async queryFn(
        { orgID, apiID, fetchSeverities = false },
        _queryApi,
        _extraOptions
      ) {
        const apis = await listApis({
          orgID,
          filters: [
            {
              field: 'apiUUID',
              values: [apiID],
            },
          ],
          fetchSeverities,
        });

        if (apis.length !== 1) {
          return { error: 'API not found' };
        }

        const api = apis[0];

        return { data: api };
      },
      providesTags: (result, error, { apiID }) => [{ type: 'Apis', id: apiID }],
    }),
    addApi: build.mutation<ApiClass, AddApiParams>({
      async queryFn(
        { orgID, appID, data },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const rawApiResult = await fetchWithBQ({
          url: `/organisations/${orgID}/applications/${appID}/apis`,
          method: 'POST',
          data: { ...data, region_name: 'eu-west-1' },
        });

        const { api } = rawApiResult.data as {
          api: ApiInterface;
        };

        const application = await getApplication(orgID, appID);

        return { data: new ApiClass(api, { application }) };
      },
      async onQueryStarted({ orgID, filters }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const listApisArgs = JSON.parse(
            sessionStorage.getItem('listApisArgs') || '{}'
          );

          dispatch(
            apiEndpoints.util.updateQueryData(
              'listApis',
              listApisArgs,
              (draft) =>
                getMockCacheListing({
                  draft,
                  api: response.data,
                  filters,
                })
            )
          );

          dispatch(
            apiQuotaEndpoint.util.updateQueryData('api', { orgID }, (draft) =>
              mockIncrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              apiEndpoints.util.invalidateTags([
                { type: 'Apis', id: 'LIST' },
                'Quotas',
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteApi: build.mutation<void, DeleteApiParams>({
      query: ({ orgID, appID, apiID }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ orgID, apiID }, { dispatch, queryFulfilled }) {
        const listApisArgs = JSON.parse(
          sessionStorage.getItem('listApisArgs') || '{}'
        );

        queryFulfilled.then(() => {
          dispatch(
            apiEndpoints.util.updateQueryData(
              'listApis',
              listApisArgs,
              (draft) => {
                return mockListApisAfterDeletion({ apiUUID: apiID, draft });
              }
            )
          );

          dispatch(
            apiQuotaEndpoint.util.updateQueryData('api', { orgID }, (draft) =>
              mockDecrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              apiEndpoints.util.invalidateTags([
                { type: 'Apis', id: 'LIST' },
                'Applications',
                'Tokens',
                'Specifications',
                'Actions',
                'Events',
                'Filters',
                'Findings',
                'Incidents',
                'Integrations',
                'Quotas',
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    loggingApi: build.mutation<void, LoggingAddParams>({
      query: ({ orgID, appID, apiID, data }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/logging`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ apiID }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(
            apiEndpoints.util.invalidateTags([{ type: 'Apis', id: apiID }])
          );
        }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
      },
    }),
    listLinkedRepos: build.query<LinksResponse, GetApiLinkedRepoParams>({
      query: ({ orgID, appID, apiID }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/code-link`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.links.map(({ UUID }) => ({
                type: 'Links' as const,
                id: UUID,
              })),
              { type: 'Links', id: 'LIST' },
            ]
          : [{ type: 'Links', id: 'LIST' }],
    }),
    addLinkedRepo: build.mutation<void, LinkRepoParams>({
      query: ({ orgID, appID, apiID, destinationID }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/code-link/destination-api/${destinationID}`,
        method: 'PUT',
      }),
      async onQueryStarted({ apiID }, { dispatch, queryFulfilled }) {
        dispatch(
          setLoadingState({ endpointId: 'listLinkedRepos', isLoading: true })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Failed to add linked repo:', error);
        } finally {
          setTimeout(() => {
            dispatch(apiEndpoints.util.invalidateTags(['Links']));
            dispatch(
              setLoadingState({
                endpointId: 'listLinkedRepos',
                isLoading: false,
              })
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        }
      },
    }),
    deleteLinkedRepo: build.mutation<void, LinkRepoParams>({
      query: ({ orgID, appID, apiID, destinationID }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/code-link/${destinationID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ apiID }, { dispatch, queryFulfilled }) {
        dispatch(
          setLoadingState({ endpointId: 'listLinkedRepos', isLoading: true })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Failed to delete linked repo:', error);
        } finally {
          setTimeout(() => {
            dispatch(apiEndpoints.util.invalidateTags(['Links']));
            dispatch(
              setLoadingState({
                endpointId: 'listLinkedRepos',
                isLoading: false,
              })
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        }
      },
    }),

    listAvailableLinks: build.query<ListApisLinkResponse, ListOrgLinksParams>({
      query: ({ orgID, appID, apiID, data }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/code-link/apis/search`,
        method: 'POST',
        data,
      }),
      transformResponse: (rawResult: {
        apis: ApiInterface[];
        marker: number | undefined;
        total: number;
      }) => ({
        apis: rawResult.apis.map((api) => new ApiClass(api)),
        marker: rawResult.marker,
        total: rawResult.total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.apis.map(({ UUID }) => ({
                type: 'Links' as const,
                id: UUID,
              })),
              { type: 'Links', id: 'LIST' },
            ]
          : [{ type: 'Links', id: 'LIST' }],
    }),
    // Don't use this for now.
    listOrgLinks: build.query<ListApisLinkResponse, ListAvailableLinksParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/code-link/search`,
        method: 'POST',
        data,
      }),
      // TODO: Re-enable these when needed.
      // transformResponse: (rawResult: {
      //   apis: ApiInterface[];
      //   marker: number | undefined;
      //   total: number;
      // }) => ({
      //   apis: rawResult.apis.map((api) => new ApiClass(api)),
      //   marker: rawResult.marker,
      //   total: rawResult.total,
      // }),
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.apis.map(({ UUID }) => ({
      //           type: 'Links' as const,
      //           id: UUID,
      //         })),
      //         { type: 'Links', id: 'LIST' },
      //       ]
      //     : [{ type: 'Links', id: 'LIST' }],
    }),
  }),
});

export const {
  useListApisQuery,
  useLazyListApisQuery,
  useAddApiMutation,
  useGetApiQuery,
  useLazyGetApiQuery,
  useDeleteApiMutation,
  useLoggingApiMutation,
  useListLinkedReposQuery,
  useAddLinkedRepoMutation,
  useDeleteLinkedRepoMutation,
  useListAvailableLinksQuery,
  useLazyListAvailableLinksQuery,
  useListOrgLinksQuery,
} = apiEndpoints;

export default apiEndpoints;
