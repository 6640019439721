import { DailyMetricsContextClass } from '@/utils/models/DailyMetrics';
import { EventContextClass } from '@/utils/models/EventContext';
import DailyMetricsPerfomanceOverview from './DailyMetrics/DailyMetricsPerfomanceOverview';
import DailyMetricsHistogramStatusCode from './DailyMetrics/DailyMetricsHistogramStatusCode';

const DailyMetricsEventContext = ({
  context,
}: {
  context: EventContextClass;
}) => {
  const dailyMetrics = new DailyMetricsContextClass(context.created);

  return (
    <div className='flex flex-col gap-4 divide-y'>
      <DailyMetricsHistogramStatusCode dailyMetrics={dailyMetrics} />

      <DailyMetricsPerfomanceOverview dailyMetrics={dailyMetrics} />
    </div>
  );
};

export default DailyMetricsEventContext;
