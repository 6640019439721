import { ConditionOperatorType, FilterClass } from '@/utils/models/Filter';
import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';

const NumberField = ({
  type,
  rules,
  operator,
}: {
  rules?: Rule[];
  type?: FilterClass;
  operator?: ConditionOperatorType;
}) => {
  const isNumberMode =
    type &&
    (operator === 'greater-than' || operator === 'less-than') &&
    type.type === 'float';
  if (isNumberMode)
    return (
      <Form.Item
        rules={rules}
        key='value'
        name='value'
        label={type.dynamic_key ?? 'Value'}
        className='w-full'
      >
        <InputNumber min={0} className='w-full' />
      </Form.Item>
    );

  return <></>;
};

export default NumberField;
