import { Spin } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLazyCreateCheckoutSessionQuery,
  useListPlansQuery,
} from '@/utils/services/billing/billing-endpoints';
import PlanCard from './Plans/PlanCard';

const BillingPlansCards = ({
  showTitle = true,
  showDescription = true,
  descriptionText,
  titleText,
}: {
  showTitle?: boolean;
  showDescription?: boolean;
  descriptionText?: string;
  titleText?: string;
}) => {
  const { orgID } = useParams<{ orgID: string }>();

  const [billingPeriodState, setBillingPeriodState] = useState<
    'monthly' | 'yearly'
  >('monthly');

  const [isComparePlansOpen, setIsComparePlansOpen] = useState(false);
  const {
    data: plans,
    isLoading: isListPlansLoading,
    isFetching: isListPlansFetching,
  } = useListPlansQuery({ orgID });

  const [
    createCheckoutSession,
    {
      isLoading: isCreateCheckoutSessionLoading,
      isFetching: isCreateCheckoutSessionFetching,
    },
  ] = useLazyCreateCheckoutSessionQuery();

  const handleSelectPlan = (uuid: string) => {
    createCheckoutSession({
      orgID,
      planID: uuid,
      billingPeriod: billingPeriodState,
    })
      .unwrap()
      .then((url) => window.open(url, '_self'));
  };

  const isLoading =
    isListPlansLoading ||
    isListPlansFetching ||
    isCreateCheckoutSessionLoading ||
    isCreateCheckoutSessionFetching;

  return (
    <section className='text-gray-600 body-font overflow-hidden'>
      <Spin spinning={isLoading}>
        <div className='mx-3'>
          <div className='flex flex-col text-center w-full gap-6'>
            {(showTitle || showDescription) && (
              <div className='flex flex-col text-center w-full '>
                {showTitle && (
                  <h1
                    style={{
                      animationDelay: `0.05s`,
                      animationFillMode: 'forwards',
                    }}
                    className='animate-fade-up opacity-0 md:text-4xl text-xl font-medium title-font mb-2 text-gray-900'
                  >
                    {titleText || 'Choose the perfect plan for you'}
                  </h1>
                )}

                {showDescription && (
                  <p
                    style={{
                      animationDelay: `0.10s`,
                      animationFillMode: 'forwards',
                    }}
                    className='animate-fade-up opacity-0 lg:w-2/3 text-sm mx-auto leading-relaxed lg:text-base text-gray-500'
                  >
                    {descriptionText ? (
                      descriptionText
                    ) : (
                      <>
                        FireTail offers a range of ways to access our
                        industry-leading API security technology.
                        <br />
                        Simply choose the plan that works best for you or let us
                        know if you have any questions.
                      </>
                    )}
                  </p>
                )}
              </div>
            )}

            <div
              style={{
                animationDelay: `0.15s`,
                animationFillMode: 'forwards',
              }}
              className='animate-fade-up opacity-0 flex mx-auto border-2 border-second rounded-sm overflow-hidden font-semibold'
            >
              <button
                className={`py-1 px-4 focus:outline-none ${
                  billingPeriodState === 'monthly'
                    ? 'bg-second text-white'
                    : 'bg-white text-gray-500'
                }`}
                onClick={(evt) => {
                  evt.preventDefault();
                  setBillingPeriodState('monthly');
                }}
              >
                Monthly
              </button>

              <button
                className={`py-1 px-4 focus:outline-none ${
                  billingPeriodState === 'yearly'
                    ? 'bg-second text-white'
                    : 'bg-white text-gray-500'
                }`}
                onClick={(evt) => {
                  evt.preventDefault();
                  setBillingPeriodState('yearly');
                }}
              >
                Yearly
              </button>
            </div>

            <div className='flex justify-center gap-2'>
              {plans
                ?.filter((plan) => plan.is_visible)
                .map((plan) => {
                  return (
                    <PlanCard
                      key={plan.uuid}
                      plan={plan}
                      onClick={handleSelectPlan}
                      billingPeriod={billingPeriodState}
                      isComparePlansOpen={isComparePlansOpen}
                      toggleComparePlans={() =>
                        setIsComparePlansOpen(!isComparePlansOpen)
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Spin>
    </section>
  );
};

export default BillingPlansCards;
