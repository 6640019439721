import './date-picker.css';
import { DatePicker as DatePickerAnt } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdOutlineCalendarToday, MdOutlineExpandMore } from 'react-icons/md';

interface GenericDatePickerProps {
  value: moment.Moment | null;
  onChange: (time: moment.Moment | null) => void;
  disabledDate: (current: moment.Moment) => boolean;
  placeholder?: string;
  disabled?: boolean;
  dark?: boolean;
  dateFormat?: string;
  styleOverrides?: {
    datePickerStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
  };
}

const GenericDatePicker = ({
  value,
  onChange,
  disabledDate,
  placeholder,
  disabled = false,
  dark = false,
  dateFormat = 'D MMM YYYY  |  H:mm',
  styleOverrides = {},
}: GenericDatePickerProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [datePickerValue, setDatePickerValue] = useState<moment.Moment | null>(
    value
  );

  useEffect(() => setDatePickerValue(value), [value]);

  const datePickerIsPoppedUpOrClosed = (isPoppedUp: boolean) => {
    const isClosed = !isPoppedUp;
    if (isClosed) onChange(datePickerValue);
    setDropdownIsOpen(isPoppedUp);
  };

  const defaultDatePickerStyle = {
    background: '#1A222C',
    padding: '6px 10px 6px 2px',
    letterSpacing: '0.01em',
    lineHeight: '112%',
    width: 282,
    ...styleOverrides.datePickerStyle,
  };

  const defaultContainerStyle = {
    borderRadius: '2px',
    outline: '1px solid #7D7D7D',
    ...styleOverrides.containerStyle,
  };

  return (
    <div style={defaultContainerStyle} className='flex items-center relative'>
      <MdOutlineCalendarToday
        className={`${dark ? 'text-anti-flash' : 'text-first'} mx-2 absolute`}
        size={16}
      />
      <DatePickerAnt
        disabled={disabled}
        disabledDate={disabledDate}
        className={`ant-picker-custom ${
          dark ? 'ant-picker-dark' : ''
        } cursor-pointer`}
        format={dateFormat}
        allowClear={false}
        showToday={false}
        showNow={false}
        showTime={{ format: 'HH:mm' }}
        placeholder={placeholder}
        panelRender={(panelRender) => (
          <div className={dark ? `ant-picker-panel-dark` : ''}>
            {panelRender}
          </div>
        )}
        value={datePickerValue}
        onSelect={(value) => setDatePickerValue(value)}
        bordered={false}
        style={defaultDatePickerStyle}
        onOpenChange={datePickerIsPoppedUpOrClosed}
        popupClassName={dark ? 'ant-picker-dropdown-dark' : ''}
        suffixIcon={
          <MdOutlineExpandMore
            className={`${
              dark ? 'text-anti-flash' : 'text-first'
            } transition-all focus:outline-none ${
              dropdownIsOpen ? '-rotate-180' : ''
            }`}
            size={20}
          />
        }
      />
    </div>
  );
};

export default GenericDatePicker;
