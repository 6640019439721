import { ReactElement, ReactNode } from 'react';

interface ListRootProps {
  children: ReactNode;
  listEmptyComponent?: ReactElement;
  dataSource?: any[];
  isDataEmpty?: boolean;
  renderEmpty?: {
    isEmpty?: boolean;
    element: ReactElement;
    loading?: boolean;
  };
}

const ListRoot = ({ children, renderEmpty }: ListRootProps) => {
  if (renderEmpty) {
    if (renderEmpty.isEmpty) return renderEmpty.element;

    return <div>{children}</div>;
  }

  return <div>{children}</div>;
};

export default ListRoot;
