import { FilterClass } from '@/utils/models/Filter';
import { AutocompleteFilterOptionsType } from '@/utils/services/resource/resource-endpoints';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';

const TextField = ({
  type,
  rules,
  options,
}: {
  rules?: Rule[];
  type?: FilterClass;
  options?: AutocompleteFilterOptionsType[];
}) => {
  const isTextMode =
    type && !type.source_from && !options && type.type !== 'bool';
  if (isTextMode)
    return (
      <Form.Item
        rules={rules}
        key='value'
        name='value'
        label={type.dynamic_key ?? 'Value'}
      >
        <Input />
      </Form.Item>
    );

  return <></>;
};

export default TextField;
