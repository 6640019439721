import { IncidentClass } from '@/utils/models/Incident';
import { Descriptions } from 'antd';
import Item from '../List/Item';
import moment from 'moment';
import { GitHubIncidentContextType } from '@/utils/models/IncidentContext';

const githubLogo = require('@/assets/images/github_repository.png');

const IncidentsContext = ({ incident }: { incident?: IncidentClass }) => {
  if (!incident) return <></>;

  let contextContent = <></>;

  if (incident.context.isGitHubIncidentContextType) {
    const GIHUB_DOMAIN = 'https://github.com';
    const context = incident.context.data as GitHubIncidentContextType;
    const repoLink = `${GIHUB_DOMAIN}/${context.repositoryOwner}/${context.repositoryName}`;
    const repoOwnerLink = `${GIHUB_DOMAIN}/${context.repositoryOwner}`;
    const branchName = context.ref.split('/').pop();
    const branchLink = `${GIHUB_DOMAIN}/${context.repositoryOwner}/${context.repositoryName}/tree/${branchName}`;
    const usernameLink = `${GIHUB_DOMAIN}/${context.headCommitUsername}`;
    const filesLink = context.file_urls.map((file) => ({
      name: file,
      link: `${GIHUB_DOMAIN}/${context.repositoryOwner}/${context.repositoryName}/blob/${branchName}/${file}`,
    }));

    contextContent = (
      <>
        <Descriptions.Item label='Trigger Source & Time'>
          <div className='items-center flex flex-row'>
            <img
              alt='Github Logo'
              src={githubLogo}
              className='mr-1'
              style={{ maxHeight: '20px' }}
            />
            | {moment(context.timeTriggered / 1000).format('lll')}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label='Actor'>{context.actor}</Descriptions.Item>
        <Descriptions.Item label='Actor ID'>
          {context.actorId}
        </Descriptions.Item>
        <Descriptions.Item label='Event Name'>
          {context.eventName}
        </Descriptions.Item>
        <Descriptions.Item label='File URLs'>
          <div className='flex gap-2'>
            {filesLink.map((file, index) => (
              <a
                className='underline hover:underline hover:text-second'
                target='_blank'
                rel='noreferrer'
                href={file.link}
                key={index.toString()}
              >
                <span>{file.name}</span>
              </a>
            ))}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label='Head Commit Username'>
          <a
            className='underline hover:underline hover:text-second'
            target='_blank'
            rel='noreferrer'
            href={usernameLink}
          >
            {context.headCommitUsername}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label='Private'>
          {context.private ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label='Run ID'>{context.runId}</Descriptions.Item>
        <Descriptions.Item label='Sha'>{context.sha}</Descriptions.Item>
        <Descriptions.Item label='Repository ID'>
          {context.repositoryId}
        </Descriptions.Item>
        <Descriptions.Item label='Workflow Ref' className='mr-2'>
          {context.workflowRef}
        </Descriptions.Item>
        <Descriptions.Item label='Repository Owner'>
          <a
            className='underline hover:underline hover:text-second'
            target='_blank'
            rel='noreferrer'
            href={repoOwnerLink}
          >
            {context.repositoryOwner}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label='Repository'>
          <a
            className='underline hover:underline hover:text-second'
            target='_blank'
            rel='noreferrer'
            href={repoLink}
          >
            {context.repositoryName}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label='Branch'>
          <a
            className='underline hover:underline hover:text-second'
            target='_blank'
            rel='noreferrer'
            href={branchLink}
          >
            {branchName}
          </a>
        </Descriptions.Item>
      </>
    );
  }

  const triggerContext = incident.context.triggerContext;

  return (
    <div className='flex flex-col gap-2'>
      <h3 className='font-semibold text-lg'>Context</h3>

      <Descriptions bordered size='small' column={2}>
        <Descriptions.Item label='Trigger'>
          <Item.LogoAndName
            logo={triggerContext.logo}
            name={triggerContext.name}
          />
        </Descriptions.Item>

        {contextContent}
      </Descriptions>
    </div>
  );
};

export default IncidentsContext;
