import { ApiClass } from '@/utils/models/Api';
import { EventClass } from '@/utils/models/Event';
import { Page, Text, View, Document, Image, Link } from '@react-pdf/renderer';
import { ftLogo, reportStyles } from './styles/consts';

interface ReportData {
  api_uuids?: string[];
  event_uuids?: string[];
  code?: string;
  findings?: {
    'False Positive': number;
    Ignored: number;
    Open: number;
    Remediated: number;
    'Risk Accepted': number;
    Total: number;
  };
  owasp_name?: string;
  apiDetails?: ApiClass[];
  eventDetails?: EventClass[];
}

const ReportPdf = ({
  reportData,
  mappings,
  orgID,
  title,
}: {
  reportData?: ReportData[];
  mappings?: any;
  orgID?: string;
  title: string;
}) => {
  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return window.location.origin;
    }
    return '';
  };

  const baseURL = getBaseUrl();

  return (
    <Document>
      {reportData
        ?.sort((a, b) => {
          const getNormalizedOwaspName = (name: string) =>
            name.trim().toLowerCase();
          const aName = getNormalizedOwaspName(a.owasp_name || '');
          const bName = getNormalizedOwaspName(b.owasp_name || '');

          const extractParts = (name: string) => {
            const parts: (string | number)[] = [];
            let current = '';
            let isNumber = false;

            for (const char of name) {
              const charIsNumber = !isNaN(Number(char));
              if (
                charIsNumber !== isNumber ||
                (charIsNumber && current.length > 0 && isNaN(Number(current)))
              ) {
                if (current.length > 0) {
                  parts.push(isNumber ? Number(current) : current);
                }
                current = '';
                isNumber = charIsNumber;
              }
              current += char;
            }

            if (current.length > 0) {
              parts.push(isNumber ? Number(current) : current);
            }

            return parts;
          };

          const partsA = extractParts(aName);
          const partsB = extractParts(bName);

          for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
            const partA = partsA[i];
            const partB = partsB[i];

            if (partA === undefined) return -1;
            if (partB === undefined) return 1;

            if (typeof partA === 'number' && typeof partB === 'number') {
              if (partA !== partB) return partA - partB;
            } else if (typeof partA === 'string' && typeof partB === 'string') {
              const comparison = partA.localeCompare(partB);
              if (comparison !== 0) return comparison;
            } else {
              return typeof partA === 'number' ? -1 : 1;
            }
          }

          return 0;
        })
        .map((reportItem, index) => (
          <Page key={`page-${index}`} size='A4' style={reportStyles.page}>
            <View style={reportStyles.header} key={`header-${index}`}>
              <Text style={reportStyles.title} key={`title-${index}`}>
                {title}
              </Text>
              <Link src='https://www.firetail.io/finding'>
                <Image
                  style={reportStyles.logo}
                  src={ftLogo}
                  key={`logo-${index}`}
                />
              </Link>
            </View>

            {reportItem.owasp_name && (
              <View
                style={reportStyles.section}
                key={`section-owasp-name-${index}`}
              >
                <Link
                  src={
                    mappings && mappings.length > 0
                      ? mappings?.find(
                          (mapping: { title: string }) =>
                            mapping.title === reportItem.owasp_name
                        )?.link || ''
                      : ''
                  }
                  style={{ marginBottom: '5px' }}
                  key={`link-owasp-name-${index}`}
                >
                  <Text
                    style={reportStyles.title}
                    key={`text-owasp-name-${index}`}
                  >
                    {reportItem.owasp_name}
                  </Text>
                </Link>
                {mappings && mappings.length > 0 && (
                  <Text
                    style={reportStyles.description}
                    key={`description-owasp-name-${index}`}
                  >
                    {
                      mappings.find(
                        (mapping: { title: string }) =>
                          mapping.title === reportItem.owasp_name
                      )?.description
                    }
                  </Text>
                )}
              </View>
            )}

            {reportItem.code && mappings && (
              <View
                style={reportStyles.section}
                key={`section-code-${reportItem.code}`}
              >
                {mappings[reportItem.code] ? (
                  <Text
                    style={reportStyles.title}
                    key={`text-code-${reportItem.code}`}
                  >
                    {mappings[reportItem.code].title}
                  </Text>
                ) : (
                  <Text
                    style={reportStyles.title}
                    key={`text-code-${reportItem.code}`}
                  >
                    {reportItem.code}
                  </Text>
                )}
                {mappings[reportItem.code] && (
                  <Text
                    style={reportStyles.description}
                    key={`description-code-${reportItem.code}`}
                  >
                    {mappings[reportItem.code].description || ''}
                  </Text>
                )}
              </View>
            )}

            {reportItem.findings && (
              <View style={reportStyles.section}>
                <Text style={reportStyles.dataPointHeader}>
                  Findings Summary:
                </Text>
                <View style={reportStyles.table}>
                  <View
                    style={[
                      reportStyles.tableRow,
                      { backgroundColor: '#E4E4E4' },
                    ]}
                  >
                    <Text style={reportStyles.tableCellHeader}>Status</Text>
                    <Text style={reportStyles.tableCellHeader}>Count</Text>
                  </View>
                  {Object.entries(reportItem.findings)
                    .sort((a, b) => {
                      const order = [
                        'Open',
                        'Remediated',
                        'False Positive',
                        'Risk Accepted',
                        'Ignored',
                        'Total',
                      ];
                      const firstIndex = order.indexOf(a[0]);
                      const secondIndex = order.indexOf(b[0]);
                      return firstIndex - secondIndex;
                    })
                    .map(([key, value], idx) => (
                      <View
                        key={`finding-row-${key}-${idx}`}
                        style={reportStyles.tableRow}
                      >
                        <Text style={reportStyles.tableCell}>{key}</Text>
                        <Text style={reportStyles.tableCell}>{value}</Text>
                      </View>
                    ))}
                </View>
              </View>
            )}

            {reportItem.apiDetails && reportItem.apiDetails.length > 0 && (
              <View style={reportStyles.section} key={`api-details-${index}`}>
                <Text style={reportStyles.dataPointHeader}>Affected APIs:</Text>
                <View style={reportStyles.table}>
                  <View style={reportStyles.tableRow}>
                    <Text
                      style={[
                        reportStyles.tableCellFull,
                        { backgroundColor: '#E4E4E4' },
                      ]}
                    >
                      API Name
                    </Text>
                  </View>
                  {reportItem.apiDetails.map((api, apiIndex) => (
                    <View
                      key={`api-row-${api.UUID}`}
                      style={reportStyles.tableRow}
                    >
                      <View
                        style={{
                          ...reportStyles.tableCellFull,
                        }}
                      >
                        <Link
                          src={`${baseURL}/organisations/${orgID}/applications/${api.api_appUUID}/apis/${api.UUID}/dashboard`}
                          key={`api-link-${api.UUID}`}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            color: '#666',
                          }}
                        >
                          <Text style={{ flexShrink: 1 }}>{api.name}</Text>
                          <Image
                            src={api.avatar}
                            style={{ width: 13, height: 13, marginLeft: 5 }}
                          />
                        </Link>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            )}

            {reportItem.eventDetails && reportItem.eventDetails.length > 0 && (
              <View style={reportStyles.section} key={`event-details-${index}`}>
                <Text style={reportStyles.dataPointHeader}>Events:</Text>
                <View style={reportStyles.table}>
                  <View style={reportStyles.tableRow}>
                    <Text
                      style={[
                        reportStyles.tableCellHalf,
                        { backgroundColor: '#E4E4E4' },
                      ]}
                    >
                      Title
                    </Text>
                    <Text
                      style={[
                        reportStyles.tableCellHalf,
                        { backgroundColor: '#E4E4E4' },
                      ]}
                    >
                      Date Occurred
                    </Text>
                  </View>
                  {reportItem.eventDetails
                    .sort(
                      (a, b) =>
                        b.dateOccurredInMicroSeconds -
                        a.dateOccurredInMicroSeconds
                    )
                    .map((event) => (
                      <View
                        key={`event-row-${event.UUID}`}
                        style={reportStyles.tableRow}
                      >
                        <View
                          style={[
                            reportStyles.tableCellHalf,
                            { flexDirection: 'row' },
                          ]}
                        >
                          <Link
                            src={`${baseURL}/organisations/${orgID}/posture-management/events/${event.UUID}`}
                            key={`event-link-${event.UUID}`}
                            style={{
                              display: 'flex',
                              color: '#666',
                            }}
                          >
                            <Text style={{ flexShrink: 1, color: '#666' }}>
                              {event.mapping?.title || 'Unknown Event'}
                            </Text>
                          </Link>
                        </View>
                        <Text style={reportStyles.tableCellHalf}>
                          {new Date(
                            event.dateOccurredInMicroSeconds / 1000
                          ).toLocaleString('en-US', { timeZone: 'UTC' })}{' '}
                          UTC
                        </Text>
                      </View>
                    ))}
                </View>
              </View>
            )}

            <View style={reportStyles.footer} key={`footer-${index}`}>
              <Text style={reportStyles.footerText}>
                Item {index + 1} of {reportData?.length}
              </Text>
            </View>
          </Page>
        ))}
    </Document>
  );
};

export default ReportPdf;
