import { FieldBuilder } from '@/components/Form/Fields';
import { AlertClass, AlertType } from '@/utils/models/Alert';
import FieldType from '@/utils/models/FieldType';
import { FormInstance } from 'antd';
import { useEffect } from 'react';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useParams } from 'react-router-dom';

const AlertIntegration = ({
  formInstance,
  alert,
  forceType,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  useEffect(() => {
    if (!alert) return;

    formInstance.setFieldsValue({
      notification_integration_uuid: alert.notification_integration_uuid,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  const fieldBuilder = new FieldBuilder(formInstance);

  const selectIntegrationField: FieldType = {
    attribute: 'notification_integration_uuid',
    inputType: 'select',
    placeholder: 'Notification',
    name: 'Select or create a notification integration',
    sourceFrom: {
      resource: 'integration',
      filters: [{ field: 'category', values: ['notification'] }],
      creationOptions: {
        categories: ['notification'],
      },
    },
    validation: {
      required: true,
    },
    fieldSize: 'extralarge',
    colSize: 'extralarge',
  };

  const selectIntegrationFormItem = fieldBuilder.getFormItem(
    selectIntegrationField
  );

  if (!alert && isEdition) return <></>;

  const sectionProps: any = {
    title: 'Notification',
    info: `Select your preferred method to receive alert notifications.`,
  };

  return (
    <AlertSectionWrapper
      title={sectionProps.title}
      showTitle={sectionProps.showTitle}
      info={sectionProps.info}
    >
      {selectIntegrationFormItem}
    </AlertSectionWrapper>
  );
};

export default AlertIntegration;
