import { Button, Modal, Spin } from 'antd';
import {
  useGetApiQuery,
  useDeleteApiMutation,
  ListApisParams,
} from '@/utils/services/api/api-endpoints';
import Apis from '@/components/Apis';
import { useHistory, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRole from '@/utils/hooks/useRole';

const Details = () => {
  const { hasPermissionToDeleteApi } = useRole();
  const { appID, orgID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const { data: api, isLoading: apiIsLoading } = useGetApiQuery({
    orgID,
    apiID,
  });
  const history = useHistory();
  const [deleteApi] = useDeleteApiMutation();

  const handleDeleteApi = async () => {
    await deleteApi({ orgID, appID, apiID }).unwrap();
    const params = sessionStorage.getItem('PARAMS_LIST');
    let parsed = {} as ListApisParams;
    if (params) {
      parsed = JSON.parse(params) as ListApisParams;

      if (parsed.filters?.some((f) => f.field === 'appUUID')) {
        history.push(`/organisations/${orgID}/applications/${appID}`);
        return;
      }
    }
    history.push(`/organisations/${orgID}/apis`);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this API?`,
      icon: <ExclamationCircleOutlined />,
      content: `Name: ${api?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteApi();
      },
    });
  };

  const extra = [];
  if (hasPermissionToDeleteApi) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => showDeleteConfirm()}
        danger
      >
        Delete API
      </Button>
    );
  }

  return (
    <>
      <Spin spinning={apiIsLoading}>
        <div className='flex flex-col gap-6 bg-white'>
          <Apis.Details api={api} extra={extra} />
        </div>
      </Spin>
    </>
  );
};

export default Details;
