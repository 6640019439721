import { ApiClass } from '@/utils/models/Api';
import { Descriptions, PageHeader } from 'antd';
import Item from '@/components/List/Item';
import { getRegionFlagIcon } from '@/utils/regionFlags';
import moment from 'moment';

const DescriptionItem = ({
  label,
  value,
  enableCopy = false,
  enableLink = false,
}: {
  label: string;
  value?: string | string[];
  enableCopy?: boolean;
  enableLink?: boolean;
}) => {
  if (!value) return <></>;

  const values = Array.isArray(value) ? Array.from(new Set(value)) : [value];

  const ItemComponent = ({ value }: { value: string }): JSX.Element => {
    if (enableCopy) {
      return (
        <Item.CopyButton value={value as string} message={`${label} Copied`} />
      );
    }
    if (enableLink) {
      return <Item.LinkRedirect href={value} />;
    }
    return <>{value}</>;
  };

  return (
    <Descriptions.Item label={label}>
      <div className='max-h-20 overflow-auto flex flex-col gap-1'>
        {values.map((value, index) => (
          <div key={index.toString()}>
            <ItemComponent value={value} />
          </div>
        ))}
      </div>
    </Descriptions.Item>
  );
};

const ApiIntegrationDetail = ({ api }: { api?: ApiClass }) => {
  if (!api || !api.ft_integration) return <></>;
  const integration = api.ft_integration;

  let awsResource;
  if (integration.api_gw_version === '1')
    awsResource = `https://${integration.region}.console.aws.amazon.com/apigateway/home?region=${integration.region}#/apis/${integration.api_id}/resources`;
  else if (integration.api_gw_version === '2')
    awsResource = `https://${integration.region}.console.aws.amazon.com/apigateway/main/api-detail?api=${integration.api_id}&region=${integration.region}`;
  else if (api.type === 'aws_app_sync')
    awsResource = `https://${integration.region}.console.aws.amazon.com/appsync/home?region=${integration.region}#/${integration.api_id}/v1/home`;

  return (
    <PageHeader
      avatar={{ src: api?.avatar }}
      subTitle='Integration'
      title={api.typeLabel}
    >
      <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
        {DescriptionItem({
          label: 'Name',
          value: integration.name,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API Management Name',
          value: integration.apiManagementName,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Description',
          value: integration.description,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API ID',
          value: integration.api_id,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API Management ID',
          value: integration.apiManagementID,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Resource Type',
          value:
            integration.resource_type !== 'aws_api_gateway'
              ? integration.resource_type
              : undefined,
        })}
        {DescriptionItem({
          label: 'Default Hostname',
          value: integration.defaultHostname,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Project Number',
          value: integration.projectNumber,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'ETag',
          value: integration.etag,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Public IP Addresses',
          value: integration.publicIPAddresses,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Creation Time',
          value: integration.dateCreated,
        })}
        {DescriptionItem({
          label: 'Platform Version',
          value: integration.platformVersion,
        })}
        {DescriptionItem({
          label: 'Public Network Access',
          value: integration.publicNetworkAccess,
        })}
        {DescriptionItem({
          label: 'Protocols',
          value: integration.protocols,
        })}
        {DescriptionItem({
          label: 'Resource Group',
          value: integration.resourceGroup,
        })}
        {DescriptionItem({
          label: 'Subscription ID',
          value: integration.subscriptionID,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Type',
          value: integration.type,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API Type',
          value: integration.apiType,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Item Type',
          value: integration.itemType,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Account ID',
          value: integration.account_id,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'ARN',
          value: integration.arn,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Authentication Type',
          value: integration.authenticationType,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'Discovered Endpoints',
          value: integration.discovered_endpoints,
          enableLink: true,
        })}
        {DescriptionItem({
          label: 'Service url',
          value: integration.serviceUrl,
          enableLink: true,
        })}
        {integration.region && (
          <Descriptions.Item label='Region Name'>
            <div className='flex items-center gap-2'>
              {getRegionFlagIcon(integration.region, undefined, 4)}
              <Item.CopyButton
                value={integration.region}
                message={`Region Name Copied`}
              />
            </div>
          </Descriptions.Item>
        )}
        {DescriptionItem({
          label: 'API Path',
          value: integration.apiPath,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API Management Region Name',
          value: integration.apiManagementRegionName,
          enableCopy: true,
        })}
        {DescriptionItem({
          label: 'API Gateway Version',
          value: integration.api_gw_version,
        })}
        {DescriptionItem({
          label: 'AWS Resource',
          value: awsResource,
          enableLink: true,
        })}
        {DescriptionItem({
          label: 'Repository',
          value: api.metadata?.html_url,
          enableLink: true,
        })}
        {DescriptionItem({
          label: 'Visibility',
          value: api.metadata?.visibility,
        })}
        {DescriptionItem({
          label: 'Repository Id',
          value: integration.repository_identifier,
          enableCopy: true,
        })}
        {integration.repository_last_analysed_at &&
          DescriptionItem({
            label: 'Last Analysed at',
            value: moment(integration.repository_last_analysed_at).format(
              'lll'
            ),
          })}
        {api.scanSource.logo && (
          <Descriptions.Item label='Scan Source'>
            <Item.Logo
              name={api.scanSource.label}
              key={api.scanSource.label}
              logoUrl={api.scanSource.logo}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </PageHeader>
  );
};

const ApiDetails = ({
  api,
  extra,
}: {
  api?: ApiClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {api && (
        <PageHeader subTitle='API' title={api.name} extra={extra}>
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={api.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Created by'>
              {api.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {api.created}
            </Descriptions.Item>
            {!api.ft_integration && (
              <Descriptions.Item label='Type'>
                {api.typeLabel}
              </Descriptions.Item>
            )}
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{api.status}</span>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}

      <ApiIntegrationDetail api={api} />
    </div>
  );
};

export default ApiDetails;
