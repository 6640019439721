import { store } from '@/app/store';
import apiEndpoints, {
  ListApisPagination,
  ListApisParams,
} from './api-endpoints';
import { ApiClass } from '@/utils/models/Api';

export const listApis = async (params: ListApisParams) => {
  const apisResult = await store.dispatch<any>(
    apiEndpoints.endpoints.listApis.initiate(params)
  );
  const data = apisResult.data as ListApisPagination;
  return data.apis;
};

export const getAPI = async ({
  orgID,
  apiID,
}: {
  orgID: string;
  apiID: string;
}) => {
  const rawResult = await store.dispatch<any>(
    apiEndpoints.endpoints.getApi.initiate({ orgID, apiID })
  );
  const api = rawResult.data as ApiClass;
  return api;
};

export const listAPIsByUUID = async ({
  orgID,
  UUIDs,
}: {
  orgID: string;
  UUIDs: string[];
}) => {
  const removeDuplicates = (arr: string[]) => Array.from(new Set(arr));

  const filters = [
    {
      field: 'apiUUID',
      values: removeDuplicates(UUIDs),
    },
  ];

  const apis = await listApis({ orgID, filters });
  return apis;
};
