const FullScreenLoader = ({ loading = true }: { loading?: boolean }) => {
  if (!loading) return null;

  return (
    <div
      className='top-0 bottom-0 right-0 left-0 fixed overflow-hidden'
      style={{
        zIndex: 1001,
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      }}
    >
      <div
        className='absolute w-full top-1/2 text-center items-center flex 
      justify-center'
      >
        <img
          src={require('../../assets/images/logo-dark.png')}
          alt='logo'
          className='animate-spin-fast h-12 w-h-12 opacity-80'
        />
      </div>
    </div>
  );
};

export default FullScreenLoader;
