import { ReactNode } from 'react';

const ListHeader = ({
  children,
  isCode,
}: {
  children: ReactNode;
  isCode?: boolean;
}) => (
  <div
    className={`grid grid-cols-12 items-center mb-4 gap-x-4 ${isCode ? 'list-header' : ''}`}
  >
    {children}
  </div>
);

export default ListHeader;
