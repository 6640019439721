import ApplicationDetails from '@/components/Applications/ApplicationDetails';
import { ApplicationClass } from '@/utils/models/Application';
import { EventContextClass } from '@/utils/models/EventContext';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const AppEventContext = ({ context }: { context: EventContextClass }) => {
  const history = useHistory();

  const extraButtons = (application: ApplicationClass) => {
    return [
      <Button
        key='application'
        onClick={() => {
          history.push(
            `/organisations/${application.app_orgUUID}/applicationlications/${application.UUID}`
          );
        }}
      >
        {application?.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const application = new ApplicationClass(context.created);
    content = (
      <ApplicationDetails
        application={application}
        extra={extraButtons(application)}
      />
    );
  }

  if (context.isDeleted) {
    content = (
      <ApplicationDetails application={new ApplicationClass(context.before)} />
    );
  }

  if (context.isChanged) {
    const application = new ApplicationClass(context.after);
    content = (
      <ApplicationDetails
        application={application}
        extra={extraButtons(application)}
      />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default AppEventContext;
