import moment from 'moment';
import { RoleClass, RoleInterface } from './Role';

type OrganisationFeaturesType =
  | 'anomaly_detection'
  | 'actions'
  | 'sequencing'
  | 'code_to_cloud'
  | 'code_to_spec';

export interface OrganisationInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  dateAssignedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  name: string;
  orgUUID: string;
  org_email: string;
  status: string;
  plan_uuid?: string;
  accountType?: number;
  billing_id?: string;
  createdBy?: string;
  addedBy?: string;
  inProduction?: false;
  orgAdmin?: string;
  showStripeBilling?: boolean;
  region?: string;
  features?: OrganisationFeaturesType[];
  billingPlatform?: string;
}

type OrganisationExtraDataType = {
  role?: RoleInterface;
};

export class OrganisationClass implements OrganisationInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  dateAssignedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  name: string;
  orgUUID: string;
  org_email: string;
  status: string;
  plan_uuid?: string;
  accountType?: number;
  billing_id?: string;
  createdBy?: string;
  addedBy?: string;
  inProduction?: false;
  orgAdmin?: string;
  showStripeBilling: boolean;
  region?: string;
  features?: OrganisationFeaturesType[];
  billingPlatform?: string;

  key?: string;
  role?: RoleClass;

  constructor(
    org: OrganisationInterface,
    extraData?: OrganisationExtraDataType
  ) {
    this.UUID = org.g_orgUUID;
    this.key = org.g_orgUUID;
    this.dateAssignedInMicroSeconds = org.dateAssignedInMicroSeconds;
    this.g_orgUUID = org.g_orgUUID;
    this.dateAddedInMicroSeconds = org.dateAddedInMicroSeconds;
    this.itemType = org.itemType;
    this.name = org.name;
    this.orgUUID = org.orgUUID;
    this.org_email = org.org_email;
    this.status = org.status;
    this.plan_uuid = org.plan_uuid;
    this.accountType = org.accountType;
    this.billing_id = org.billing_id;
    this.createdBy = org.createdBy;
    this.addedBy = org.addedBy;
    this.inProduction = org.inProduction;
    this.orgAdmin = org.orgAdmin;
    this.showStripeBilling = org.showStripeBilling !== false;
    this.region = org.region;
    this.features = org.features;
    this.billingPlatform = org.billingPlatform;

    if (extraData?.role) {
      this.role = new RoleClass(extraData.role);
    }
  }

  get showAnomalyDetectionFeature(): boolean {
    if (!this.features) return false;
    return this.features.includes('anomaly_detection');
  }

  get showActionsFeature(): boolean {
    if (!this.features) return false;
    return this.features.includes('actions');
  }

  get showCodeToCloudFeature(): boolean {
    if (!this.features) return false;
    return this.features.includes('code_to_cloud');
  }

  get showCodeToSpecFeature(): boolean {
    if (!this.features) return false;
    return this.features.includes('code_to_spec');
  }

  get showSequencingFeature(): boolean {
    if (!this.features) return false;
    return this.features.includes('sequencing');
  }

  get isBillingPlatformStripe(): boolean {
    return this.billingPlatform ? this.billingPlatform === 'stripe' : true;
  }

  get created(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }
}
