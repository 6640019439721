import { useGetNotificationHistoryQuery } from '@/utils/services/integration/integration-endpoints';
import { useParams } from 'react-router-dom';
import IntegrationNotificationHistoryHeader from './IntegrationNotificationHistoryHeader';
import IntegrationNotificationHistoryBody from './IntegrationNotificationHistoryBody';
import { Result, Skeleton } from 'antd';

const IntegrationNotificationHistoryDetails = ({
  notificationHistoryID,
  showHeader = false,
}: {
  notificationHistoryID: string;
  showHeader?: boolean;
}) => {
  const { orgID, myIntegrationID } = useParams<{
    orgID: string;
    myIntegrationID: string;
  }>();

  const {
    data: notificationHistoryData,
    isLoading,
    isFetching,
    isError,
  } = useGetNotificationHistoryQuery(
    {
      orgID,
      myIntegrationID,
      notificationHistoryID,
    },
    { skip: !myIntegrationID }
  );

  if (notificationHistoryData) {
    return (
      <div className='flex flex-col gap-4'>
        {showHeader && (
          <IntegrationNotificationHistoryHeader
            notification={notificationHistoryData}
            showCopyLinkButton
          />
        )}

        <IntegrationNotificationHistoryBody
          notification={notificationHistoryData}
        />
      </div>
    );
  }

  if (isLoading || isFetching) {
    return (
      <div className='my-4 w-full text-center'>
        <Skeleton active />
      </div>
    );
  }

  if (isError) {
    return (
      <div className='' data-testid='show-unauthorized'>
        <Result status='error' title='Bad request' />
      </div>
    );
  }

  return <></>;
};

export default IntegrationNotificationHistoryDetails;
