import { ActionClass } from '@/utils/models/Action';
import { Descriptions, PageHeader } from 'antd';
import Item from '../List/Item';

const ActionDetails = ({
  action,
  extra,
}: {
  action?: ActionClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {action && (
        <PageHeader subTitle='Action' title={action.name} extra={extra}>
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={action.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Created by'>
              {action.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {action.created}
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{action.status}</span>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default ActionDetails;
