import Billing from '@/components/Billing';
import List from '@/components/List';
import Item from '@/components/List/Item';
import IncidentPolicyModal from '@/components/Modal/IncidentPolicyModal';
import { IncidentPolicyClass } from '@/utils/models/IncidentPolicy';
import { useListIncidentPoliciesQuery } from '@/utils/services/incident-policy/incident-policy-endpoints';
import { useGetIncidentsPolicyQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import useRole from '@/utils/hooks/useRole';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const IncidentsPolicyPage = () => {
  const { hasPermissionToCreateIncidentPolicy } = useRole();
  const history = useHistory();
  const { orgID } = useParams<{ orgID: string }>();

  const { pagination } = usePaginationQueryParam();
  const { query } = useSearchQueryParam();

  const {
    data: listIncidentsPolicesData,
    isLoading,
    isFetching,
  } = useListIncidentPoliciesQuery({
    orgID,
    query,
    ...pagination,
  });

  const { data: incidentPolicyQuota, isLoading: isQuotaLoading } =
    useGetIncidentsPolicyQuotasQuery({ orgID });

  const upgradeRequired =
    incidentPolicyQuota &&
    (incidentPolicyQuota.current as number) >=
      (incidentPolicyQuota?.max as number);

  const [createOpen, setCreateOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const handleEdit = (incidentPolicy: IncidentPolicyClass) => {
    history.push(
      `/organisations/${orgID}/posture-management/incidents-policy/${incidentPolicy.UUID}`
    );
  };

  const handleCreateClick = () => {
    if (upgradeRequired) {
      setIsPlanModalOpen(true);
      return;
    }

    setCreateOpen(true);
  };

  const columns: ColumnsType<IncidentPolicyClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, incidentPolicy) => (
        <button
          onClick={() => handleEdit(incidentPolicy)}
          className='text-base text-blue-600 font-semibold text-left'
        >
          {incidentPolicy.name}
        </button>
      ),
    },
    {
      title: 'Minimum Severities',
      key: 'minimumSeverities',
      render: (_, incidentPolicy) => (
        <Item.SeveritiesOverview
          severities={incidentPolicy.minimumSeverities}
        />
      ),
    },
    {
      title: 'Created From Now',
      key: 'createdFromNow',
      render: (_, incidentPolicy) => (
        <Item.Date
          created={incidentPolicy.created}
          createdFromNow={incidentPolicy.createdFromNow}
        />
      ),
    },
  ];

  return (
    <div>
      <IncidentPolicyModal
        open={createOpen}
        onCloseModal={() => setCreateOpen(false)}
      />
      <Billing.Modal
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
        titleText='You have reached your limit of incident policies.'
      />
      <List.Root>
        <List.Header>
          <List.Search placeholder='Search integrations' />
          <List.Settings>
            <>
              {hasPermissionToCreateIncidentPolicy && (
                <Button onClick={() => handleCreateClick()} type='primary'>
                  <div className='flex flex-row'>
                    Create Incident Policy
                    {upgradeRequired && (
                      <Tooltip title='Upgrade Required'>
                        <MdWarning
                          style={{ color: '#FFE25E' }}
                          size={16}
                          className='ml-2 self-center'
                        />
                      </Tooltip>
                    )}
                  </div>
                </Button>
              )}
              <List.QuotaIndicator
                quota={incidentPolicyQuota}
                tooltipText={`You are currently using ${
                  incidentPolicyQuota?.current as number
                } out of your ${
                  incidentPolicyQuota?.max as number
                } allocated incident policies.`}
              />
            </>
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <List.Pagination
          dataSource={listIncidentsPolicesData?.incidents}
          useURI
          total={listIncidentsPolicesData?.total}
        >
          <List.Grid
            item={{
              render: (incidentPolicy: IncidentPolicyClass) => (
                <Item.Root
                  key={incidentPolicy.key}
                  onClick={() => handleEdit(incidentPolicy)}
                  id={incidentPolicy.key}
                >
                  <Item.Header>
                    <Item.Title>{incidentPolicy.name}</Item.Title>
                  </Item.Header>

                  <Item.SeveritiesOverview
                    showAllSeverities={false}
                    severities={incidentPolicy.minimumSeverities}
                  />

                  <Item.Footer>
                    <Item.Date
                      created={incidentPolicy.created}
                      createdFromNow={incidentPolicy.createdFromNow}
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={isFetching || isLoading || isQuotaLoading}
          />

          <List.Table
            columns={columns}
            loading={isFetching || isLoading || isQuotaLoading}
          />
        </List.Pagination>
      </List.Root>
    </div>
  );
};

export default IncidentsPolicyPage;
