import DashboardFiltersCreateAlertButton from './DashboardFiltersCreateAlertButton';
import DashboardFiltersSelectDateTime from './DashboardFiltersDateTime/DashboardFiltersSelectDateTime';
import DashboardFiltersGroups from './DashboardFiltersGroups';
import DashboardFiltersSelectPollingInterval from './DashboardFiltersPollingInterval/DashboardFiltersSelectPollingInterval';
import DashboardFiltersRoot from './DashboardFiltersRoot';
import DashboardFiltersSelectorsWrapper from './DashboardFiltersSelectorsWrapper';
import DashboardFiltersWrapper from './DashboardFiltersWrapper';

const DashboardFilters = {
  Root: DashboardFiltersRoot,
  Wrapper: DashboardFiltersWrapper,
  SelectorsWrapper: DashboardFiltersSelectorsWrapper,
  Groups: DashboardFiltersGroups,
  SelectPollingInterval: DashboardFiltersSelectPollingInterval,
  SelectDateTime: DashboardFiltersSelectDateTime,
  CreateAlertButton: DashboardFiltersCreateAlertButton,
};

export default DashboardFilters;
