const Heading = ({
  text,
  extra,
}: {
  text: string;
  extra?: React.ReactNode;
}) => {
  return (
    <div
      className='px-8 py-6 bg-first-light grid grid-cols-1 lg:grid-cols-12
    items-center lg:gap-8'
    >
      <span
        className='text-anti-flash font-semibold lg:col-span-6 text-left 
        text-3xl lg:text-4xl xl:text-5xl'
      >
        {text}
      </span>
      <div className='col-span-6'>{extra}</div>
    </div>
  );
};

export default Heading;
