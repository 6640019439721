import { useEffect, useMemo, useState } from 'react';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import { LineChartType } from '@/utils/models/HistographType';
import { counterFormat } from '@/utils/methods/displayFormat';

const LineChart = ({
  lineChartData,
  maxValue,
  annotations = [],
  configCustom = {},
  height,
}: {
  lineChartData?: LineChartType;
  maxValue?: number;
  annotations?: any[];
  classStyle?: string;
  configCustom?: any;
  height?: number;
}) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (lineChartData?.data) {
      setData(lineChartData.data);
    }
  }, [lineChartData]);

  useEffect(() => {
    return () => {
      setData([]);
    };
  }, []);

  const Chart = useMemo(() => {
    const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'app',
      tooltip: {
        title: (date: any) => moment(date).format('MMM Do H:mm'),
      },
      xAxis: {
        label: {
          formatter: (date: any) => moment(date).format('MMM Do H:mm'),
        },
      },
      meta: {
        date: { range: [0.05, 0.95] },
      },
      yAxis: {
        label: {
          formatter: (v: any) => counterFormat(v),
        },
        max: maxValue,
      },
      annotations: annotations,
      appendPadding: [10, 0, 20, 0],
      color: ['#071F20', '#F99C2B', '#995297', '#7D7D7D', '#733907', '#ffb944'],
      legend: false,
    };

    const params = { ...config, ...configCustom };
    return (
      <div style={{ height: height }}>
        <Line {...params} />
      </div>
    );
  }, [height, annotations, configCustom, data, maxValue]);

  return Chart;
};

export default LineChart;
