import { useCallback } from 'react';
import {
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const markerDefault = withDefault(StringParam, '');

interface UseInfiniteScrollQueryProps {
  updateType?: UrlUpdateType;
}

function useInfiniteScrollQueryParam(props?: UseInfiniteScrollQueryProps) {
  const updateType: UrlUpdateType = props?.updateType || 'push';

  const [{ marker }, setQuery] = useQueryParams({
    marker: markerDefault,
  });

  const setMarker = useCallback(
    (nextMarker?: string) => {
      setQuery({ marker: nextMarker }, updateType);
    },
    [setQuery, updateType]
  );

  return { marker, setMarker };
}

export default useInfiniteScrollQueryParam;
