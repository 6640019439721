import React, { useCallback, useEffect } from 'react';
import DashboardFiltersConditionModal from './DashboardFiltersConditionModal/DashboardFiltersConditionModal';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  dashboardFiltersSelectors,
  filterSetAll,
} from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import { setDashboardFiltersGroups } from './dashboardFiltersGroupsSlice';
import { JsonParam, useQueryParams, withDefault } from 'use-query-params';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';

interface DashboardFiltersRootProps {
  dark?: boolean;
  excludeTypes?: Array<string>;
  children: React.ReactElement[] | React.ReactElement;
  useURI?: boolean;
  useStorageKey?: string;
  defaultFilters?: FilterGroupType[];
}

const filtersDefault = withDefault(JsonParam, []);

const DashboardFiltersRoot = ({
  excludeTypes = [],
  dark = false,
  children,
  useURI = false,
  useStorageKey,
  defaultFilters,
}: DashboardFiltersRootProps) => {
  const [selectedFilter, setSelectedFilter] = React.useState<FilterGroupType>();
  const [selectedCondition, setSelectedCondition] =
    React.useState<ConditionType>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isStateInitialized, setIsStateInitialized] = React.useState(false);
  const filters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const dispatch = useAppDispatch();

  const { setPagination } = usePaginationQueryParam();

  const [{ filters: filtersQuery }, setQuery] = useQueryParams({
    filters: filtersDefault,
  });

  const setFiltersQuery = useCallback(
    (newFilters: FilterGroupType[]) => {
      setQuery(
        { filters: newFilters.length ? newFilters : undefined },
        'replaceIn'
      );

      // Reset pagination when filters change
      setPagination();
    },
    [setQuery, setPagination]
  );

  useEffect(() => {
    setIsStateInitialized(true);

    if (defaultFilters) {
      dispatch(filterSetAll(defaultFilters));
      return;
    }

    let filtersURI, filtersStorage;
    if (useURI) filtersURI = filtersQuery;
    if (useStorageKey) filtersStorage = sessionStorage.getItem(useStorageKey);
    const filtersData = filtersURI || filtersStorage;
    if (!filtersData) dispatch(filterSetAll([]));
    else {
      try {
        const filters: FilterGroupType[] =
          typeof filtersData === 'string'
            ? JSON.parse(filtersData)
            : filtersData;
        dispatch(filterSetAll(filters));
      } catch (err: any) {
        dispatch(filterSetAll([]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters]);

  useEffect(() => {
    if (!isStateInitialized) return;

    const filtersData = JSON.stringify(filters);
    if (useURI) {
      setFiltersQuery(filters);
    }

    if (useStorageKey) {
      sessionStorage.setItem(useStorageKey, filtersData);
    }

    dispatch(setDashboardFiltersGroups(filters));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isStateInitialized]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFilter(undefined);
    setSelectedCondition(undefined);
  };

  const handleAddFilterGroup = () => {
    setIsModalOpen(true);
  };

  const handleAddCondition = (filter: FilterGroupType) => {
    setIsModalOpen(true);
    setSelectedFilter(filter);
  };

  const handleEditCondition = (
    filter: FilterGroupType,
    condition: ConditionType
  ) => {
    setIsModalOpen(true);
    setSelectedFilter(filter);
    setSelectedCondition(condition);
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        handleAddFilterGroup,
        handleAddCondition,
        handleEditCondition,
        dark,
      });
    });
  };

  return (
    <>
      <DashboardFiltersConditionModal
        isModalOpen={isModalOpen}
        selectedFilter={selectedFilter}
        selectedCondition={selectedCondition}
        closeModal={handleCloseModal}
        excludeTypes={excludeTypes}
      />

      {renderChildren()}
    </>
  );
};

export default DashboardFiltersRoot;
