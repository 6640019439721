import { useEffect, useMemo, useState } from 'react';
import { useParams, Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Result, Button, Modal } from 'antd';
import org from './orgID/org';
import OrganisationStorage from '@/storage/OrganisationStorage';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import {
  clearCurrentOrganisation,
  setOrganisation,
} from '@/components/Navbar/navSlice';
import { OrganisationInterface } from '@/utils/models/Organisation';
import useRole from '@/utils/hooks/useRole';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';
import withAuthorizedRequired from '@/utils/lib/firetail-auth/wrappers/withAuthorizedRequired';

const OrgID = () => {
  const { hasPermissionToGetOrganisation } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const {
    data: organisation,
    error: getOrganisationError,
    isSuccess: getOrganisationSuccess,
  } = useGetOrganisationQuery({ orgID });
  const dispatch = useDispatch();
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    OrganisationInterface | undefined
  >();
  const router = useHistory();

  const handleRefreshOrganisation = () => {
    router.go(0);
  };

  const handleGoToOrganisation = () => {
    if (selectedOrganisation) {
      router.push(`/organisations/${selectedOrganisation.UUID}`);
    }
    setSelectedOrganisation(undefined);
  };

  const verifyOrganisation = (eventKey: string | null) => {
    if (OrganisationStorage.getKey() === eventKey) {
      const org = OrganisationStorage.get();
      if (orgID !== org?.UUID) {
        setSelectedOrganisation(org);
      } else {
        setSelectedOrganisation(undefined);
      }
    }
  };

  const handleEvent = (event: any) => verifyOrganisation(event.key);

  useEffect(() => {
    window.addEventListener('storage', handleEvent);

    return () => {
      window.removeEventListener('storage', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID]);

  useEffect(() => {
    if (organisation) {
      setSelectedOrganisation(undefined);
      dispatch(setOrganisation(organisation));
    }
  }, [organisation, dispatch]);

  const Organisation = useMemo(() => {
    if (hasPermissionToGetOrganisation) {
      return <Route path='/organisations/:orgID' component={org} />;
    }
    return <></>;
  }, [hasPermissionToGetOrganisation]);

  if (getOrganisationError) {
    const error: any = getOrganisationError;
    return (
      <div className='pt-16' data-testid='show-unauthorized'>
        <Result
          status={error?.status}
          title={error?.title}
          subTitle={error?.content}
          extra={
            <Button
              type='primary'
              onClick={() => {
                if (error?.isPendingAwsActivation) {
                  window.location.reload();
                } else {
                  dispatch(clearCurrentOrganisation());
                  router.push(`/organisations`);
                }
              }}
            >
              {error?.isPendingAwsActivation ? 'Retry' : 'Back Home'}
            </Button>
          }
        />
      </div>
    );
  }

  if (getOrganisationSuccess) {
    return (
      <>
        <Modal
          maskStyle={{ backdropFilter: 'blur(5px)' }}
          closable={false}
          title='Select one organisation'
          open={selectedOrganisation !== undefined}
          footer={null}
        >
          <p className='mb-6'>
            You have more than one organization selected on other tabs, select
            one to continue browsing.
          </p>

          <div className='flex gap-2 w-full justify-end'>
            <Button key='back' onClick={handleRefreshOrganisation}>
              Stay in this organization
            </Button>
            <Button
              key='submit'
              type='primary'
              onClick={handleGoToOrganisation}
            >
              Go to {selectedOrganisation?.name}
            </Button>
          </div>
        </Modal>

        <Switch>{Organisation}</Switch>
      </>
    );
  }

  return <FullScreenLoader />;
};

const PageWithAuthorizedRequired = withAuthorizedRequired(OrgID);

export default withAuthenticationRequired(PageWithAuthorizedRequired);
