import { DataNode } from 'antd/lib/tree';
import BaseEntityClass from './BaseEntity';
import {
  DetailInterface,
  EndpointDetailInterface,
} from './SpecificationVersionDetail';
import { LIST_OF_ALL_HTTP_METHODS } from '../constants/listOfHttpMethods';

export interface SpecificationVersionInterface {
  UUID: string;
  collection_uuid: string;
  dateAddedInMicroSeconds: number;
  spec_type: string;
  spec?: string;
  g_orgUUID?: string;
}

export class SpecificationVersionClass
  extends BaseEntityClass
  implements SpecificationVersionInterface
{
  UUID: string;
  collection_uuid: string;
  dateAddedInMicroSeconds: number;
  spec_type: string;
  spec?: string;
  specification?: DetailInterface;
  groupPaths: string[];
  endpoints: EndpointDetailInterface[];
  treeDataNode?: DataNode[];
  g_orgUUID?: string;

  constructor(data: SpecificationVersionInterface) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });
    this.UUID = data.UUID;
    this.collection_uuid = data.collection_uuid;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.spec_type = data.spec_type;
    this.g_orgUUID = data.g_orgUUID;
    this.spec = data.spec;
    this.endpoints = [];
    this.groupPaths = [];

    if (this.spec) {
      const specification: DetailInterface = JSON.parse(this.spec);
      this.specification = specification;
      this.#setEndpointsAndGroupPaths(specification);
    }
  }

  #setEndpointsAndGroupPaths = ({ paths }: DetailInterface) => {
    const groups = new Set<string>();
    if (paths) {
      Object.keys(paths).forEach((path) => {
        const pathBody = paths[path];
        if (pathBody) {
          Object.keys(pathBody).forEach((method: string) => {
            if (LIST_OF_ALL_HTTP_METHODS.includes(method.toLowerCase())) {
              this.endpoints?.push({
                ...pathBody[method],
                method,
                path,
                key: `${method}::${path}`,
                responses: objToArray(pathBody[method].responses, 'statusCode'),
              });
            }
          });
          if (path.startsWith('/')) {
            const pathSplited = path.split('/');
            if (pathSplited.length > 1) {
              groups.add(pathSplited[1]);
            }
          }
        }
      });
      this.groupPaths = Array.from(groups);
    }
  };
}

const objToArray = (obj: any, attribute: string): any[] => {
  if (!obj) return [];
  const array: any = [];
  Object.keys(obj).forEach((key) => {
    array.push({ ...obj[key], [attribute]: key });
  });
  return array;
};
