const ItemSpan = ({ text }: { text?: string }) => (
  <span
    className='text-gray-600 text-xs font-normal 
  leading-none tracking-wide shrink break-all'
  >
    {text}
  </span>
);

export default ItemSpan;
