import './date-picker.css';
import moment, { Moment } from 'moment';
import Dropdown from '@/components/DataDisplay/Dropdown';
import { MdRestartAlt } from 'react-icons/md';
import GenericDatePicker from './GenericDatePicker';
import useDynamicDateTimeQueryParam, {
  DynamicDateTime,
} from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';

export interface DurationSelectorItem {
  key: string;
  label: string;
  value: number;
}

interface GenericDateTimePickerProps {
  dark?: boolean;
  dateFormat?: string;
  hideTitle?: boolean;
  disabled?: boolean;
  excludeDurationOptions?: string[];
}

const GenericDateTimePicker = ({
  dark = false,
  dateFormat = 'YYYY-MM-DDTHH:mm:ssZ',
  hideTitle = false,
  disabled = false,
  excludeDurationOptions = [],
}: GenericDateTimePickerProps) => {
  const { dateTime, loggingDurationQuery, setDateTime } =
    useDynamicDateTimeQueryParam();
  const { value, start, end } = dateTime || {};

  const durationOptions =
    loggingDurationQuery.data?.durations.filter(
      (d) => !excludeDurationOptions.includes(d.key)
    ) || [];

  const handleModeOnChange = (key: string) => {
    const opt = durationOptions.find((i) => i.key === key);
    const newDateTime: DynamicDateTime = {
      value: opt?.value,
      start: undefined,
      end: undefined,
    };
    setDateTime(newDateTime);
  };

  const disabledDate = (current: Moment, startDate?: string | null) => {
    if (!current) return false;
    const start = startDate
      ? moment(startDate)
      : moment().subtract(loggingDurationQuery.data?.retention_days, 'days');
    const currentEndOfDay = moment(current).endOf('day');
    return currentEndOfDay < start || currentEndOfDay > moment().endOf('day');
  };

  const handleRestartDatePicker = () => {
    const newDateTime: DynamicDateTime = {
      value: -1, // -1 is the value for fixed field.
      start: undefined,
      end: undefined,
    };
    setDateTime(newDateTime);
  };

  const handleStartDatePickerOnChange = (start: Moment | null) => {
    const newDateTime: DynamicDateTime = {
      value,
      start: start ? start.utc().format(dateFormat) : undefined,
      end: undefined,
    };
    setDateTime(newDateTime);
  };

  const handleEndDatePickerOnChange = (end: Moment | null) => {
    const newDateTime: DynamicDateTime = {
      value,
      start,
      end: end ? end.utc().format(dateFormat) : undefined,
    };
    setDateTime(newDateTime);
  };

  return (
    <div
      className={`flex items-center ${
        dark ? 'text-anti-flash' : 'text-first'
      } gap-x-4`}
    >
      <div>
        {!hideTitle && <p className='text-xs pb-2'>Duration selector</p>}
        <Dropdown
          dark={dark}
          menu={{
            items: durationOptions,
            onClick: (info) => handleModeOnChange(info.key),
          }}
          label={
            <span>
              {loggingDurationQuery.isLoading
                ? 'Loading...'
                : durationOptions.find((i) => i.value === value)?.label}{' '}
            </span>
          }
          dropdownRender={(menu) => (
            <div className={dark ? 'dropdown-content-dark' : ''}>{menu}</div>
          )}
          disabled={disabled}
        />
      </div>

      {value === -1 && (
        <div className='flex flex-col'>
          {!hideTitle && (
            <div className='flex justify-between items-center'>
              <p className='text-xs pb-2'>Date selector</p>
              <button
                onClick={handleRestartDatePicker}
                className='flex items-center pb-2 focus:outline-none'
              >
                <MdRestartAlt size={16} />
                <span className='ml-1 text-xs'>Reset</span>
              </button>
            </div>
          )}
          <div className='flex gap-4'>
            <GenericDatePicker
              disabledDate={disabledDate}
              onChange={handleStartDatePickerOnChange}
              value={start ? moment(start) : null}
              placeholder='Start date'
              key='select-start-date'
              data-testid='select-start-date'
              dark={dark}
              disabled={disabled}
            />
            <GenericDatePicker
              disabledDate={(current) => disabledDate(current, start)}
              value={end ? moment(end) : null}
              onChange={handleEndDatePickerOnChange}
              placeholder='End date'
              disabled={start === null || disabled}
              key='select-end-date'
              data-testid='select-end-date'
              dark={dark}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericDateTimePicker;
