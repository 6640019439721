import moment from 'moment';
import { SeveritiesFindingType } from './Finding';
import { EventClass } from './Event';
import {
  GitHubIncidentContextType,
  IncidentContextClass,
  IncidentContextType,
} from './IncidentContext';
import { ApiClass } from './Api';

export type StatusIncidentType = 'OPEN' | 'CLOSED';

export interface IncidentInterface {
  UUID: string;
  context: IncidentContextType;
  dateAddedInMicroSeconds: number;
  eventUUID?: string;
  g_orgUUID: string;
  incidentPolicy: string;
  incident_orgUUID: string;
  itemType: 'incident';
  apiName: string;
  apiUUID: string;
  notificationIntegrations: string[];
  severities: SeveritiesFindingType;
  status: StatusIncidentType;
  indexName?: string;
}

type IncidentExtraDataType = {
  events?: EventClass[];
  resources?: { apis?: ApiClass[] };
};

export class IncidentClass {
  UUID: string;
  context: IncidentContextClass;
  dateAddedInMicroSeconds: number;
  eventUUID?: string;
  g_orgUUID: string;
  incidentPolicy: string;
  incident_orgUUID: string;
  itemType: 'incident';
  notificationIntegrations: string[];
  severities: SeveritiesFindingType;
  apiName: string;
  apiUUID: string;
  status: StatusIncidentType;
  key: string;
  indexName?: string;

  events?: EventClass[];
  resources?: { apis?: ApiClass[]; apiIDs?: string[] };
  originalData?: IncidentInterface;

  constructor(data: IncidentInterface, extraData?: IncidentExtraDataType) {
    this.originalData = data;
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.context = new IncidentContextClass(data.context);
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.eventUUID = data.eventUUID;
    this.g_orgUUID = data.g_orgUUID;
    this.incidentPolicy = data.incidentPolicy;
    this.incident_orgUUID = data.incident_orgUUID;
    this.itemType = data.itemType;
    this.notificationIntegrations = data.notificationIntegrations;
    this.severities = data.severities;
    this.status = data.status;
    this.apiName = data.apiName;
    this.apiUUID = data.apiUUID;
    this.indexName = data.indexName;
    this.events = extraData?.events;
    this.resources = extraData?.resources;
  }

  get mapDataToDownload() {
    return {
      ID: this.UUID,
      'Date Added in Microseconds': this.dateAddedInMicroSeconds,
      'Creation Time': this.created,
      'Incident Policy': this.incidentPolicy,
      'Index Name': this.indexName,
      'Notifications Integrations': this.notificationIntegrations,
      Status: this.status,
      Context: this.context,
      'Organization ID': this.incident_orgUUID,
      Events: this.events,
      'Event ID': this.eventUUID,
      Severities: this.severities,
    };
  }

  get name(): string {
    if (this.context?.isGitHubIncidentContextType) {
      const context = this.context.data as GitHubIncidentContextType;
      const timestamp = context.timeTriggered;
      return `Github incident triggered on ${moment(timestamp / 1000).format(
        'lll'
      )}`;
    }

    return `Incident triggered on ${this.created}`;
  }

  get created(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }

  get createdUtcFormat(): string {
    return moment(this.dateAddedInMicroSeconds / 1000)
      .utc()
      .format();
  }

  get severitiesTotal(): number {
    return Object.keys(this.severities).reduce(
      // @ts-ignore
      (aggregator, severity) => this.severities[severity] + aggregator,
      0
    );
  }
}
