import Incidents from '@/components/Incidents';
import List from '@/components/List';
import Item from '@/components/List/Item';
import { IncidentClass, StatusIncidentType } from '@/utils/models/Incident';
import { useListIncidentsQuery } from '@/utils/services/incident/incident-endpoints';
import { ColumnsType } from 'antd/lib/table';
import useRole from '@/utils/hooks/useRole';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';

const IncidentsPage = () => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToGetIncident } = useRole();

  const { pagination } = usePaginationQueryParam();
  const { query } = useSearchQueryParam();

  const { data: incidentsData, isLoading: incidentsDataIsLoading } =
    useListIncidentsQuery({ orgID, ...pagination });

  const location = useLocation();
  const history = useHistory();

  const columns: ColumnsType<IncidentClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, incident) =>
        hasPermissionToGetIncident ? (
          <button
            onClick={() => handleIncidentSelected(incident)}
            className='text-base text-blue-600 font-semibold text-left whitespace-nowrap'
          >
            {incident?.name}
          </button>
        ) : (
          incident?.name
        ),
    },
    {
      title: 'Trigger',
      dataIndex: 'resource',
      render: (_, incident) => (
        <Item.LogoAndName
          logo={incident.context.triggerContext.logo}
          name={incident.context.triggerContext.name}
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: StatusIncidentType, incident) => (
        <Incidents.SelectStatus incidentID={incident.UUID} status={status} />
      ),
    },
    {
      title: 'Related Findings',
      key: 'incidents',
      width: 500,
      render: (_, incident) => (
        <Item.SeveritiesOverview severities={incident.severities} />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, incident) => (
        <Item.Date
          created={incident.created}
          createdFromNow={incident.createdFromNow}
        />
      ),
    },
  ];

  const fetchDataToDownload: () => Promise<any[]> = async () => {
    return Promise.resolve(
      incidentsData?.incidents?.map((incident) => incident.mapDataToDownload) ||
        []
    );
  };

  const incidents: IncidentClass[] = incidentsData
    ? filterIncidentsBySearchQuery(incidentsData.incidents, query)
    : [];

  const handleIncidentSelected = (incident: IncidentClass) =>
    history.replace({
      ...location,
      pathname: `${location.pathname}/${incident.UUID}`,
    });

  return (
    <div>
      <Incidents.Drawer />

      <List.Root>
        <List.Header>
          <List.Search placeholder='Search incidents' />

          <List.Settings>
            <List.Download
              disabled={incidentsData?.total === 0 && !query}
              fetchData={fetchDataToDownload}
              loading={incidentsDataIsLoading}
            />
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <List.Pagination
          dataSource={incidents}
          useURI
          total={query ? incidents.length : incidentsData?.total}
        >
          <List.Grid
            item={{
              render: (incident: IncidentClass) => (
                <Item.Root
                  key={incident.key}
                  onClick={
                    hasPermissionToGetIncident
                      ? () => handleIncidentSelected(incident)
                      : undefined
                  }
                  id={incident.key}
                  disabled={incident.status === 'CLOSED'}
                >
                  <Item.Header>
                    <Item.Title>{incident?.name}</Item.Title>
                  </Item.Header>

                  <Item.SeveritiesOverview
                    showAllSeverities={false}
                    severities={incident.severities}
                  />

                  <Incidents.SelectStatus
                    incidentID={incident.UUID}
                    status={incident.status}
                  />

                  <Item.Footer>
                    <Item.LogoAndName
                      logo={incident.context.triggerContext.logo}
                      name={incident.context.triggerContext.name}
                    />
                    <Item.Date
                      created={incident.created}
                      createdFromNow={incident.createdFromNow}
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={incidentsDataIsLoading}
          />

          <List.Table columns={columns} loading={incidentsDataIsLoading} />
        </List.Pagination>
      </List.Root>
    </div>
  );
};

const filterIncidentsBySearchQuery = (
  incidents: IncidentClass[],
  searchQuery: string
): IncidentClass[] => {
  const sq = searchQuery.toLowerCase();
  return incidents.filter((incident) => {
    const uuid = incident.UUID.toLowerCase();
    return uuid?.includes(sq);
  });
};

export default IncidentsPage;
