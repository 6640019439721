import { nanoid } from '@reduxjs/toolkit';
import BaseEntityClass from './BaseEntity';
import moment from 'moment';
import { GenericObject } from '../constants';

export type ConditionOperatorType =
  | 'is-one-of'
  | 'is-not-one-of'
  | 'exist'
  | 'not-exist'
  | 'bool'
  | 'greater-than'
  | 'less-than';

export type ConditionType = {
  id: string;
  field: string;
  operator: ConditionOperatorType;
  value?: string | number;
  values?: Array<string>;
  addonAfter?: string;
  disabled?: boolean;
};

export type ConditionFormType = {
  id: string;
  field: string;
  operator: ConditionOperatorType;
  value?: string | number;
  values?: Array<string>;
  addonAfter?: string;
  disabled?: boolean;
};

export type FilterGroupOperatorType = 'include' | 'exclude';

export type FilterGroupType = {
  operator: FilterGroupOperatorType;
  groups: ConditionType[];
  id: string;
  disabled?: boolean;
};

export interface ConditionInterface {
  field: string;
  operator: ConditionOperatorType;
  value?: string | string[] | number;
  values?: Array<string>;
}

export interface FiltersGroupInterface {
  operator: FilterGroupOperatorType;
  groups: ConditionInterface[];
  id?: string;
}

export interface FilterInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  filterSource: string;
  filters: FilterGroupType[];
  filters_orgUUID: string;
  g_orgUUID: string;
  itemType: string;
  name: string;
  resource: string;
  status: string;
  dynamic_key: string;
  type: string;
  code: string;
  dynamic: boolean;
  operators: ConditionOperatorType[];
  category: string;
  source_from?: { resource: string; filters: GenericObject[] };
  display_name: string;
}

export class FilterClass extends BaseEntityClass implements FilterInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  filterSource: string;
  filters: FilterGroupType[];
  filters_orgUUID: string;
  g_orgUUID: string;
  itemType: string;
  name: string;
  resource: string;
  status: string;
  dynamic_key: string;
  type: string;
  code: string;
  dynamic: boolean;
  operators: ConditionOperatorType[];
  category: string;
  source_from?: { resource: string; filters: GenericObject[] };
  display_name: string;

  constructor(data: FilterInterface) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data?.dateAddedInMicroSeconds,
    });
    this.UUID = data.UUID;
    this.createdBy = data.createdBy;
    this.filterSource = data.filterSource;
    this.filters_orgUUID = data.filters_orgUUID;
    this.dateAddedInMicroSeconds = data?.dateAddedInMicroSeconds;
    this.g_orgUUID = data.g_orgUUID;
    this.itemType = data.itemType;
    this.name = data.name;
    this.resource = data.resource;
    this.status = data.status;
    this.filters = data.filters;
    this.dynamic_key = data.dynamic_key;
    this.type = data.type;
    this.code = data.code;
    this.dynamic = data.dynamic;
    this.operators = data.operators;
    this.category = data.category;
    this.source_from = data.source_from;
    this.display_name = data.display_name;
  }

  static mapFiltersGroups(filters: FilterGroupType[]): FilterGroupType[] {
    const filtersGroups: FilterGroupType[] = [];
    filters?.forEach((item) => {
      if (item.hasOwnProperty('groups')) {
        const filter: FilterGroupType = {
          ...item,
          id: nanoid(),
          groups: item.groups.map((condition) => {
            const newCondition: ConditionType = {
              ...condition,
              id: nanoid(),
            };

            return newCondition;
          }),
        };
        filtersGroups.push(filter);
      }
    });

    return filtersGroups;
  }

  get filtersGroups(): FilterGroupType[] {
    return FilterClass.mapFiltersGroups(this.filters);
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }
}
