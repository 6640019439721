import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FindingMappingInterface } from '../models/Finding';
import { EventMappingInterface } from '../models/Event';

export interface OwaspMappingInterface {
  title: string;
  description: string;
  link: string;
}

export interface MappingInterface {
  [key: string]: string[];
}

// Get public files
export const localApi = createApi({
  reducerPath: 'localApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    listFindingMappings: builder.query<
      { [key: string]: FindingMappingInterface },
      void
    >({
      query: () => 'ft-code-findings-mappings.json',
    }),
    listEventsMappings: builder.query<
      { [key: string]: EventMappingInterface },
      void
    >({
      query: () => 'ft-code-events-mappings.json',
    }),
    listOwaspMappings: builder.query<OwaspMappingInterface[], void>({
      query: () => 'ft-code-owasp-mappings.json',
    }),
    listMitreTechniqueMappings: builder.query<MappingInterface, void>({
      query: () => 'ft-code-findings-mitre-techniques.json',
    }),
    listMitreTacticsMappings: builder.query<MappingInterface, void>({
      query: () => 'ft-code-findings-mitre-tactics.json',
    }),
    listMitreCweTop25Mappings: builder.query<MappingInterface, void>({
      query: () => 'ft-code-findings-mitre-cwe-top-25.json',
    }),
    listCisAsgMappings: builder.query<MappingInterface, void>({
      query: () => 'ft-code-findings-cis-asg.json',
    }),
    listFindingsOwaspMappings: builder.query<MappingInterface, void>({
      query: () => 'ft-code-findings-owasp.json',
    }),
  }),
});

export const {
  useListFindingMappingsQuery,
  useListEventsMappingsQuery,
  useListOwaspMappingsQuery,
} = localApi;
