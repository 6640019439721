import { SeverityFindingType } from '../models/Finding';

const LIST_OF_SEVERITY_FINDINGS: SeverityFindingType[] = [
  'information',
  'low',
  'medium',
  'high',
  'critical',
];

export default LIST_OF_SEVERITY_FINDINGS;
