import Alerts from '@/components/Alerts';
import { useState } from 'react';
import { MdNotificationAdd } from 'react-icons/md';
import useRole from '@/utils/hooks/useRole';

interface DashboardFiltersCreateAlertButtonProps {
  dark?: boolean;
}

const DashboardFiltersCreateAlertButton = ({
  dark = false,
}: DashboardFiltersCreateAlertButtonProps) => {
  const { hasPermissionToCreateAlerting } = useRole();
  const [creationModalOpen, setCreationModalOpen] = useState<boolean>(false);

  return (
    <div className='flex items-center mt-2 gap-x-4'>
      <Alerts.Creation
        onCloseModal={() => setCreationModalOpen(false)}
        open={creationModalOpen}
        useCurrentFilters
      />
      {hasPermissionToCreateAlerting && (
        <button
          data-testid='add-filter-group'
          onClick={() => setCreationModalOpen(true)}
          className={`flex items-center focus:outline-none mt-5 ${
            dark ? 'text-anti-flash' : ''
          }`}
        >
          <MdNotificationAdd size={24} className='mr-1' />
          Create Alert
        </button>
      )}
    </div>
  );
};

export default DashboardFiltersCreateAlertButton;
