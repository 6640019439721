import { counterFormat } from '@/utils/methods/displayFormat';
import { FindingClass, SeverityFindingType } from '@/utils/models/Finding';
import { ReactNode } from 'react';

const SeverityTag = ({
  severity,
  compact = false,
  showCount = false,
  count,
}: {
  severity: SeverityFindingType;
  compact?: boolean;
  children?: ReactNode;
  count?: number;
  showCount?: boolean;
}) => {
  return (
    <div className='flex items-center gap-x-2'>
      {!compact && (
        <div
          style={{
            backgroundColor: FindingClass.getSeverityColor(severity),
            width: 10,
            height: 10,
          }}
          className='rounded-full'
        />
      )}
      <div className='font-semibold flex' style={{ gap: 3 }}>
        {compact ? (
          <span
            className='text-white rounded-sm px-2 uppercase text-center'
            style={{ backgroundColor: FindingClass.getSeverityColor(severity) }}
          >
            {severity.charAt(0)}
          </span>
        ) : (
          <span
            style={{ color: FindingClass.getSeverityColor(severity) }}
            className='uppercase text-[12px] font-normal 
            leading-none tracking-wide whitespace-nowrap shrink'
          >
            {severity}
          </span>
        )}

        {showCount && (
          <>
            {compact ? (
              <div
                className='rounded-sm px-2 text-center'
                style={{
                  backgroundColor:
                    FindingClass.getSeveritySecundaryColor(severity),
                }}
              >
                <span
                  className='font-semibold'
                  style={{
                    color: FindingClass.getSeverityColor(severity),
                  }}
                >
                  {counterFormat(count)}
                </span>
              </div>
            ) : (
              <div className='rounded-sm text-center'>
                <span
                  style={{
                    color: FindingClass.getSeverityColor(severity),
                  }}
                >
                  : {counterFormat(count)}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SeverityTag;
