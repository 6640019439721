import { useAppSelector } from '@/app/hooks';
import { Affix } from 'antd';
import React, { useState } from 'react';
import { MdAdd, MdOutlineExpandMore, MdUpload } from 'react-icons/md';
import DashboardFiltersCounter from './DashboardFiltersCounter';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { dashboardFiltersSelectors } from './dashboardFiltersSlice';
import DashboardFiltersSaveLoadModal from './DashboardFiltersSaveLoadModal';
import useRole from '@/utils/hooks/useRole';

interface DashboardFiltersWrapperProps {
  dark?: boolean;
  handleAddFilterGroup?: () => void;
  handleAddCondition?: (filter: FilterGroupType) => void;
  children: React.ReactElement[] | React.ReactElement;
  handleEditCondition?: (
    filter: FilterGroupType,
    condition: ConditionType
  ) => void;
}

const DashboardFiltersWrapper = ({
  dark = false,
  handleAddFilterGroup,
  handleAddCondition,
  handleEditCondition,
  children,
}: DashboardFiltersWrapperProps) => {
  const { hasPermissionToCreateFilterConfiguration } = useRole();
  const myFilters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [affixState, setAffixState] = useState<boolean | undefined>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formToShow, setFormToShow] = useState<'save' | 'load'>();

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        handleAddFilterGroup,
        handleAddCondition,
        handleEditCondition,
        collapse,
        dark,
        hasWrapper: true,
      });
    });
  };

  return (
    <>
      <DashboardFiltersSaveLoadModal
        onCloseModal={() => setIsModalOpen(false)}
        open={isModalOpen}
        formToShow={formToShow}
      />
      <div className='py-3'>
        <Affix offsetTop={0} onChange={(affix) => setAffixState(affix)}>
          <div
            className={`${
              dark ? 'bg-first-light' : 'bg-white'
            } grid gap-4 transition-all ${
              affixState ? '-mx-8 py-3 px-12' : 'rounded-sm p-3'
            }`}
          >
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <span
                  className={`${dark ? 'text-anti-flash' : ''} mr-3`}
                  style={{ fontSize: 20 }}
                >
                  Requests
                </span>
                <div
                  className={`transition-all ${
                    collapse ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  {myFilters.length > 0 && (
                    <DashboardFiltersCounter
                      dark={dark}
                      count={myFilters.length}
                    />
                  )}
                </div>
              </div>
              {myFilters.length > 0 ? (
                <button
                  className={`${
                    dark ? 'text-anti-flash' : ''
                  } transition-all focus:outline-none ${
                    collapse ? '' : '-rotate-180'
                  }`}
                  onClick={() => setCollapse((prev) => !prev)}
                >
                  <MdOutlineExpandMore size={20} />
                </button>
              ) : (
                <div className='flex flex-row'>
                  <button
                    data-testid='add-filter-group'
                    onClick={handleAddFilterGroup}
                    className={`${
                      dark ? 'text-anti-flash' : ''
                    } flex items-center focus:outline-none ${
                      myFilters.length < 10 ? 'visible' : 'invisible'
                    } mr-2`}
                  >
                    <MdAdd size={16} className='mr-1' />
                    Add Filter Group
                  </button>

                  {hasPermissionToCreateFilterConfiguration && (
                    <button
                      className={`${
                        dark ? 'text-anti-flash' : ''
                      } flex items-center focus:outline-none ${
                        myFilters.length < 10 ? 'visible' : 'invisible'
                      }`}
                      onClick={() => {
                        setIsModalOpen(true);
                        setFormToShow('load');
                      }}
                    >
                      <MdUpload size={16} className='mr-1' /> Load/Delete Filter
                    </button>
                  )}
                </div>
              )}
            </div>

            {renderChildren()}
          </div>
        </Affix>
      </div>
    </>
  );
};

export default DashboardFiltersWrapper;
