import ItemSpan from '../List/Item/ItemSpan';

const StatusTicketTag = ({ status }: { status: string }) => (
  <div className='flex items-center gap-x-1 text-gray-600 uppercase whitespace-nowrap'>
    <span className='text-sm material-symbols-outlined'>sell</span>

    <ItemSpan text={status} />
  </div>
);

export default StatusTicketTag;
