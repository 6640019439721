import ApiDetails from './ApiDetails';
import ApisItemCard from './ApisItemCard';
import ApisListing from './ApisListing';

const Apis = {
  Listing: ApisListing,
  Details: ApiDetails,
  ItemCard: ApisItemCard,
};

export default Apis;
