import { Route, Switch, useHistory } from 'react-router-dom';
import useQuery from '@/utils/hooks/useQuery';
import { useEffect } from 'react';
import GithubIntegration from '@/pages/organisations/orgID/github-integration';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';

const GithubInstallPage = () => {
  const history = useHistory();
  const query = useQuery();
  const orgID = query.get('state');
  const installCode = query.get('code');
  const installID = query.get('installation_id');

  useEffect(() => {
    if (!orgID || !installCode || !installID) return;

    history.push(
      `/organisations/${orgID}/github-integration?code=${installCode}&installation_id=${installID}`
    );
  }, [orgID, installCode, installID, history]);

  return (
    <Switch>
      <Route
        path='/organisations/:orgID/github-integration'
        exact
        component={GithubIntegration}
      />
    </Switch>
  );
};

export default withAuthenticationRequired(GithubInstallPage);
