import AlertCreation from './AlertCreation';
import AlertDetails from './AlertDetails';
import AlertEdition from './AlertEdition';

const Alerts = {
  Creation: AlertCreation,
  Edition: AlertEdition,
  Details: AlertDetails,
};

export default Alerts;
