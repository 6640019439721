import { Form, Modal, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IntegrationClass } from '@/utils/models/Integration';
import {
  ListMyIntegrationParams,
  useAddIntegrationMutation,
  useDeleteMyIntegrationMutation,
  useEditMyIntegrationMutation,
  useLazyGetIntegrationAvailableQuery,
  useLazyGetMyIntegrationQuery,
} from '@/utils/services/integration/integration-endpoints';
import useRole from '@/utils/hooks/useRole';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import IntegrationForm from './IntegrationForm';
import FullScreenLoader from '../Loader/FullScreenLoader';
import IntegrationModalFooter from './IntegrationModalFooter';
import IntegrationModalHeader from './IntegrationModalHeader';
import { StringParam, useQueryParam } from 'use-query-params';
import IntegrationNotificationHistoryRoot from './IntegrationNotificationHistory/IntegrationNotificationHistoryRoot';
import Billing from '@/components/Billing';
import useQuery from '@/utils/hooks/useQuery';
import { STATE_VARIABLES_KEY } from '@/utils/constants';

interface IntegrationsModalProps {
  integrationAvailableManually?: IntegrationAvailableClass;
  idsParamsManually?: {
    integrationAvailableID: string;
    myIntegrationID?: string;
  };
  params?: Partial<ListMyIntegrationParams>;
  onClose?: () => void;
  handleSubmit?: (integrationUUID: string) => void;
}

export type IntegrationModalTabType = 'add' | 'edit' | 'notificationHistory';

const IntegrationModal = ({
  integrationAvailableManually,
  params,
  idsParamsManually,
  onClose,
  handleSubmit,
}: IntegrationsModalProps) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const {
    hasPermissionToUpdateIntegration,
    hasPermissionToDeleteIntegration,
    hasPermissionToCreateIntegration,
  } = useRole();
  const errorShownRef = useRef(false);

  const {
    orgID,
    myIntegrationID: myIntegrationIDFromRouter,
    integrationAvailableID: integrationAvailableIDFromRouter,
  } = useParams<{
    orgID: string;
    myIntegrationID: string;
    integrationAvailableID: string;
  }>();

  const myIntegrationID =
    idsParamsManually?.myIntegrationID || myIntegrationIDFromRouter;

  const integrationAvailableID =
    idsParamsManually?.integrationAvailableID ||
    integrationAvailableIDFromRouter;

  const [myIntegrationData, setMyIntegrationData] = useState<
    undefined | IntegrationClass
  >();

  const [integrationAvailableData, setIntegrationAvailableData] = useState<
    IntegrationAvailableClass | undefined
  >();

  const [tab, setTab] = useState<IntegrationModalTabType>('add');

  const [notificationHistoryID, setNotificationHistoryID] = useQueryParam(
    'notificationHistoryID',
    StringParam
  );

  const [form] = Form.useForm();

  const [
    getMyIntegration,
    {
      isLoading: isGetMyIntegrationLoading,
      isFetching: isGetMyIntegrationFetching,
      isError,
    },
  ] = useLazyGetMyIntegrationQuery();

  const [
    getIntegrationAvailable,
    {
      isLoading: gettingIntegrationAvailable,
      isFetching: fetchingIntegrationAvailable,
    },
  ] = useLazyGetIntegrationAvailableQuery();

  const [, { isLoading: addIntegrationIsLoading }] = useAddIntegrationMutation({
    fixedCacheKey: 'addIntegration',
  });

  const [deleteIntegration, { isLoading: deleteIntegrationIsLoading }] =
    useDeleteMyIntegrationMutation();

  const [, { isLoading: editIntegrationIsLoading }] =
    useEditMyIntegrationMutation({
      fixedCacheKey: 'editIntegration',
    });

  const integrationAvailable =
    integrationAvailableManually ||
    integrationAvailableData ||
    myIntegrationData?.available;

  const isOpen = !!integrationAvailable;

  const onSubmit = async (myIntegrationID: string) => {
    handleCloseModal();
    handleSubmit && handleSubmit(myIntegrationID);
  };

  const showDeleteConfirm = (integration: IntegrationClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this integration?`,
      icon: <ExclamationCircleOutlined />,
      content: `Name: ${integration.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteIntegration({
            orgID,
            myIntegrationID: integration.UUID,
          });

          handleSubmit && handleSubmit(integration.UUID);
          handleCloseModal();
        } catch (err) {}
        return Promise.resolve();
      },
    });
  };

  const removeIDFromPathname = () => {
    if (myIntegrationIDFromRouter || integrationAvailableIDFromRouter) {
      const pathname = location.pathname.split('/');
      pathname.pop(); // remove id

      query.get('notificationHistoryID') &&
        query.delete('notificationHistoryID');
      query.get(STATE_VARIABLES_KEY) && query.delete(STATE_VARIABLES_KEY);

      history.replace({
        ...location,
        pathname: pathname.join('/'),
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    if (myIntegrationID) {
      getMyIntegration({ orgID, myIntegrationID }, true)
        .unwrap()
        .then((myIntegrationData) => {
          setMyIntegrationData(myIntegrationData);
          if (notificationHistoryID) setTab('notificationHistory');
          else setTab('edit');
        })
        .catch(() => removeIDFromPathname());
    } else {
      setMyIntegrationData(undefined);
      setNotificationHistoryID(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, myIntegrationID, getMyIntegration]);

  useEffect(() => {
    if (integrationAvailableID) {
      getIntegrationAvailable(
        {
          orgID,
          integrationAvailableID,
        },
        true
      )
        .unwrap()
        .then((integrationAvailable) => {
          setIntegrationAvailableData(integrationAvailable);
        })
        .catch(() => removeIDFromPathname());
    } else setIntegrationAvailableData(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, integrationAvailableID, getIntegrationAvailable]);

  useEffect(() => {
    if (isError) {
      if (!errorShownRef.current) {
        message.error('There was an error fetching this integration.');
        errorShownRef.current = true;
      }
    } else {
      errorShownRef.current = false;
    }
  }, [isError]);

  const handleCloseModal = () => {
    removeIDFromPathname();
    onClose && onClose();
  };

  const isUpgradeRequired =
    integrationAvailable?.plan_upgrade_required ||
    integrationAvailable?.hasReachedQuotaLimit;

  const getBillingCustomTitle = (): string => {
    if (integrationAvailable?.hasReachedQuotaLimit)
      return `You have reached your quota of integrations.`;

    return `Your current plan does not support the ${integrationAvailable?.name} integration.`;
  };

  return (
    <>
      <FullScreenLoader
        loading={
          isGetMyIntegrationFetching ||
          isGetMyIntegrationLoading ||
          gettingIntegrationAvailable ||
          fetchingIntegrationAvailable
        }
      />

      <Modal
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        style={{ top: 15 }}
        open={isOpen}
        okText='Submit'
        cancelText='Cancel'
        onCancel={handleCloseModal}
        width={isUpgradeRequired ? '95%' : '75%'}
        onOk={() => form.submit()}
        footer={
          <IntegrationModalFooter
            deleteButton={{
              isVisible: tab === 'edit',
              onClick: () =>
                myIntegrationData && showDeleteConfirm(myIntegrationData),
              disabled: !hasPermissionToDeleteIntegration,
            }}
            submitButton={{
              isVisible:
                !isUpgradeRequired &&
                tab !== 'notificationHistory' &&
                integrationAvailable?.has_submit,
              onClick: () => form.submit(),
              isLoading:
                addIntegrationIsLoading ||
                editIntegrationIsLoading ||
                deleteIntegrationIsLoading,
              disabled:
                (tab === 'edit' && !hasPermissionToUpdateIntegration) ||
                (tab === 'add' && !hasPermissionToCreateIntegration),
            }}
            closeButton={{ onClick: handleCloseModal }}
            createdFromNow={myIntegrationData?.createdFromNow}
            created={myIntegrationData?.created}
          />
        }
        forceRender
      >
        {isUpgradeRequired ? (
          <div
            className='overflow-auto -m-6 p-6'
            style={{ backgroundColor: 'rgb(243 244 246)', maxHeight: '82vh' }}
          >
            <Billing.Cards titleText={getBillingCustomTitle()} />
          </div>
        ) : (
          <>
            <IntegrationModalHeader
              tab={tab}
              setTab={(tabValue) => {
                setNotificationHistoryID(undefined);
                setTab(tabValue);
              }}
              logoUrl={integrationAvailable?.logoURL}
              name={integrationAvailable?.name}
              description={integrationAvailable?.description}
              hasNotificationHistory={myIntegrationData?.hasNotificationHistory}
              helpUrl={integrationAvailable?.help_url}
            />

            <div
              className='overflow-auto -m-6 p-6 flex flex-col gap-8 z-10'
              style={{
                maxHeight: '70vh',
                paddingLeft: '7rem',
                paddingRight: '7rem',
              }}
            >
              {tab === 'notificationHistory' && (
                <IntegrationNotificationHistoryRoot
                  notificationHistoryID={notificationHistoryID}
                  setNotificationHistoryID={setNotificationHistoryID}
                />
              )}

              {(tab === 'add' || tab === 'edit') && (
                <IntegrationForm
                  params={params}
                  formInstance={form}
                  onSubmit={onSubmit}
                  myIntegrationData={myIntegrationData}
                  integrationAvailableManually={integrationAvailable}
                />
              )}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default IntegrationModal;
