import { IncidentPolicyClass } from '@/utils/models/IncidentPolicy';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListIncidentPoliciesResponse } from './incident-policy-endpoints';

export const mockListIncidentPoliciesAfterAdition = ({
  draft,
  incidentPolicy,
}: {
  draft: MaybeDrafted<ListIncidentPoliciesResponse>;
  incidentPolicy: IncidentPolicyClass;
}) => {
  incidentPolicy.dateAddedInMicroSeconds = new Date().getTime() * 1000;
  draft.incidents.unshift(incidentPolicy);
  draft.total += 1;
};

export const mockListIncidentPoliciesAfterDeletion = ({
  draft,
  incidentPolicyID,
}: {
  draft: MaybeDrafted<ListIncidentPoliciesResponse>;
  incidentPolicyID: string;
}) => {
  draft.incidents = draft.incidents.filter(
    (incident) => incident.UUID !== incidentPolicyID
  );
  draft.total -= 1;
  return draft;
};

export const mockListIncidentPoliciesAfterEdition = ({
  draft,
  incidentPolicy,
}: {
  draft: MaybeDrafted<ListIncidentPoliciesResponse>;
  incidentPolicy: IncidentPolicyClass;
}) => {
  incidentPolicy.dateAddedInMicroSeconds = new Date().getTime() * 1000;

  const index = draft.incidents.findIndex(
    (incident) => incident.UUID === incidentPolicy.UUID
  );
  if (index === -1) return draft;

  draft.incidents[index] = incidentPolicy;
  return draft;
};
