import { RootState } from '@/app/store';
import LIST_OF_POLLING_INTERVALS from '@/utils/constants/listOfPollingIntervals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DashboardPollingIntervalState {
  value: number;
  key: string;
}

const initialState: DashboardPollingIntervalState =
  LIST_OF_POLLING_INTERVALS[3];

const dashboardPollingIntervalSlice = createSlice({
  name: 'dashboardPollingInterval',
  initialState: initialState,
  reducers: {
    setDashboardPollingInterval: (
      state,
      action: PayloadAction<DashboardPollingIntervalState>
    ) => {
      state.key = action.payload.key;
      state.value = action.payload.value;
    },
  },
});

export const { setDashboardPollingInterval } =
  dashboardPollingIntervalSlice.actions;

export const selectDashboardPollingInterval = (state: RootState) =>
  state.dashboardPollingInterval;

export const selectDashboardPollingIntervalValue = (state: RootState) =>
  state.dashboardPollingInterval.value;

export default dashboardPollingIntervalSlice.reducer;
