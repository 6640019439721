import { Text, View, Document, Page, Image, Link } from '@react-pdf/renderer';
import { ftLogo, QuotaStyles } from './styles/consts';
import {
  UsageData,
  QuotaUsageItem,
} from '@/utils/services/quota/quota-endpoints';
import { PlanClass } from '@/utils/models/Plan';

const QuotaPdf = ({
  quotaData,
  plans,
  orgID,
}: {
  quotaData?: UsageData;
  plans?: PlanClass[];
  orgID: string;
}) => {
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}`;
  };

  const currentDate = new Date();
  const currentDateFormatted = formatDate(currentDate);

  const firstDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const firstDayFormatted = formatDate(firstDayCurrentMonth);

  const formatNumber = (number: number) =>
    new Intl.NumberFormat('en-US').format(number);

  const renderUsageItem = (item: QuotaUsageItem) => {
    let suffix = item.suffix ? ` ${item.suffix}` : '';
    return `${formatNumber(item.current)}/${formatNumber(item.max)}${suffix}`;
  };

  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return window.location.origin;
    }
    return '';
  };

  const baseURL = getBaseUrl();

  return (
    <Document>
      <Page style={QuotaStyles.page}>
        <View style={QuotaStyles.header}>
          <Image style={QuotaStyles.logo} src={ftLogo} />
          <Text style={QuotaStyles.title}>
            {`Platform Usage Report: ${firstDayFormatted} - ${currentDateFormatted}`}
          </Text>
          <Text></Text>
        </View>
        <View style={QuotaStyles.container}>
          {plans?.map((plan, index) => (
            <Link
              src={`${baseURL}/organisations/${orgID}/settings/subscription`}
              key={index}
              style={
                plan.is_active_plan
                  ? {
                      ...QuotaStyles.card,
                      ...QuotaStyles.activeCard,
                      marginRight: index % 2 === 0 ? '2%' : 0,
                    }
                  : {
                      ...QuotaStyles.card,
                      marginRight: index % 2 === 0 ? '2%' : 0,
                    }
              }
            >
              <Text style={QuotaStyles.planPrice}>
                {plan.name} {plan.is_active_plan ? '(Current Plan)' : ''}
              </Text>
              {plan.points.map((feature, idx) => (
                <Text key={idx} style={QuotaStyles.feature}>
                  - {feature.details} {feature.tick && '✓'}
                </Text>
              ))}
            </Link>
          ))}
        </View>
        {Object.entries(quotaData?.quotas || {}).map(([category, items]) => (
          <View key={category}>
            <Text style={QuotaStyles.categoryHeader}>{category}</Text>
            {items.map((item, idx) => (
              <View key={idx} style={QuotaStyles.tableRow}>
                <Text style={QuotaStyles.tableCell}>{item.name}</Text>
                <Text style={QuotaStyles.tableCell}>
                  {renderUsageItem(item)}
                </Text>
              </View>
            ))}
          </View>
        ))}
        <Text style={QuotaStyles.footer}>{currentDateFormatted}</Text>
      </Page>
    </Document>
  );
};

export default QuotaPdf;
