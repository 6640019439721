import { FieldsData } from './FieldType';
import BaseEntityClass from './BaseEntity';
import { SeveritiesFindingType } from './Finding';

export interface IncidentPolicyInterface {
  name: string;
  dateAddedInMicroSeconds: number;
  frequency: number;
  period: number;
  UUID: string;
  g_orgUUID: string;
  incidentpolicy_orgUUID: string;
  itemType: string;
  minimumSeverities: SeveritiesFindingType;
  notificationIntegrations: string[];
  status: string;
  version: string;
  filters: FieldsData[];
}

export class IncidentPolicyClass
  extends BaseEntityClass
  implements IncidentPolicyInterface
{
  UUID: string;
  dateAddedInMicroSeconds: number;
  name: string;
  frequency: number;
  period: number;
  key: string;
  g_orgUUID: string;
  status: string;
  incidentpolicy_orgUUID: string;
  itemType: string;
  minimumSeverities: SeveritiesFindingType;
  notificationIntegrations: string[];
  version: string;
  filters: FieldsData[];

  constructor(data: IncidentPolicyInterface) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });

    this.UUID = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.name = data.name;
    this.frequency = data.frequency;
    this.period = data.period;
    this.key = data.UUID;
    this.g_orgUUID = data.g_orgUUID;
    this.status = data.status;
    this.incidentpolicy_orgUUID = data.incidentpolicy_orgUUID;
    this.itemType = data.itemType;
    this.minimumSeverities = data.minimumSeverities;
    this.notificationIntegrations = data.notificationIntegrations;
    this.version = data.version;
    this.filters = data.filters;
  }
}
