import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { baseApi } from '@/utils/services/base-api';
import baseReducer from '@/utils/services/base-reducer';
import listSlice from '@/components/List/listSlice';
import navbarSlice from '@/components/Navbar/navSlice';
import { localApi } from '@/utils/services/local-api';
import dashboardPollingIntervalSlice from '@/components/Dashboard/DashboardFilters/DashboardFiltersPollingInterval/dashboardPollingIntervalSlice';
import dashboardFiltersGroupsSlice from '@/components/Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import dashboardFiltersSlice from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import AlertSlice from '@/components/Alerts/Sections/AlertSlice';
import ApiSlice from '@/components/Apis/ApiSlice';

const middleware = [thunk, baseApi.middleware, localApi.middleware];

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [localApi.reducerPath]: localApi.reducer,
    base: baseReducer,
    list: listSlice,
    navbar: navbarSlice,
    alert: AlertSlice,
    apis: ApiSlice,
    dashboardFilters: dashboardFiltersSlice,
    dashboardFiltersGroups: dashboardFiltersGroupsSlice,
    dashboardPollingInterval: dashboardPollingIntervalSlice,
  },
  middleware,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
