import { useCallback } from 'react';
import {
  NumberParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

export type PaginationDataType = {
  page?: number;
  marker?: number;
  pageSize?: number;
};

export const ITEMS_PER_PAGE = 20;
export const MARKER = 0;
export const CURRENT_PAGE = 1;

export const PAGINATION = {
  page: CURRENT_PAGE,
  marker: MARKER,
  pageSize: ITEMS_PER_PAGE,
};

const pageDefault = withDefault(NumberParam, PAGINATION.page);
const markerDefault = withDefault(NumberParam, PAGINATION.marker);
const pageSizeDefault = withDefault(NumberParam, PAGINATION.pageSize);

interface UsePaginationQueryParamProps {
  updateType?: UrlUpdateType;
}

function usePaginationQueryParam(props?: UsePaginationQueryParamProps) {
  const updateType: UrlUpdateType = props?.updateType || 'replaceIn';

  const [pagination, setQuery] = useQueryParams({
    page: pageDefault,
    marker: markerDefault,
    pageSize: pageSizeDefault,
  });

  const setPagination = useCallback(
    (data?: PaginationDataType) =>
      setQuery(
        data
          ? data
          : { page: undefined, marker: undefined, pageSize: undefined },
        updateType
      ),
    [setQuery, updateType]
  );

  return { pagination, setPagination };
}

export default usePaginationQueryParam;
