/* eslint-disable no-template-curly-in-string */
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useAddIncidentPolicyMutation,
  useDeleteIncidentPolicyMutation,
  useEditIncidentPolicyMutation,
  useLazyGetIncidentPolicyQuery,
} from '@/utils/services/incident-policy/incident-policy-endpoints';
import { IncidentPolicyClass } from '@/utils/models/IncidentPolicy';
import {
  POLICY_FREQUENCY_OPTIONS,
  POLICY_PERIOD_OPTIONS,
} from '@/utils/constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/lib/form';
import List from '../List';
import DashboardFiltersGroupsConditionalsRows from '../Dashboard/DashboardFilters/DashboardFiltersGroups/DashboardFiltersGroupsConditionalsRows';
import { nanoid } from '@reduxjs/toolkit';
import DashboardFiltersRoot from '../Dashboard/DashboardFilters/DashboardFiltersRoot';
import useRole from '@/utils/hooks/useRole';
import { useAutocompleteFilterOptionsQuery } from '@/utils/services/resource/resource-endpoints';
import FieldType, { FieldsData } from '@/utils/models/FieldType';
import { ValueType } from '../Form/DebounceSelectField';
import { FieldBuilder } from '../Form/Fields';
import FullScreenLoader from '../Loader/FullScreenLoader';
import { SeveritiesFindingType } from '@/utils/models/Finding';

const IncidentPolicyModal = ({
  open,
  onCloseModal,
}: {
  open: boolean;
  onCloseModal: () => void;
}) => {
  const { orgID, incidentPolicyID } = useParams<{
    orgID: string;
    incidentPolicyID?: string;
  }>();

  const {
    hasPermissionToUpdateIncidentPolicy,
    hasPermissionToCreateIncidentPolicy,
    hasPermissionToDeleteIncidentPolicy,
  } = useRole();

  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();

  const [filters, setFilters] = useState<FieldsData[]>([]);
  const [filtersToSubmit, setFiltersToSubmit] = useState<FieldsData[]>([]);
  const [listFilters, setListFilters] = useState<FieldsData[]>([]);
  const [validationError, setValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [frequencyState, setFrequencyState] = useState<number>();

  const [incidentPolicy, setIncidentPolicy] = useState<IncidentPolicyClass>();

  const [editIncidentPolicy, { isLoading: editIncidentPolicyIsLoading }] =
    useEditIncidentPolicyMutation();

  const [addIncidentPolicy, { isLoading: addIncidentPolicyIsLoading }] =
    useAddIncidentPolicyMutation();

  const [deleteIncidentPolicy, { isLoading: deleteIncidentPolicyIsLoading }] =
    useDeleteIncidentPolicyMutation();

  const [
    getIncidentPolicy,
    {
      isFetching: isFetchingIncidentPolicy,
      isLoading: isLoadingIncidentPolicy,
    },
  ] = useLazyGetIncidentPolicyQuery();

  const { data: resourceData } = useAutocompleteFilterOptionsQuery({
    field: 'resources',
    orgID,
    query: '**',
    resource: 'incident_policy',
  });

  const loading =
    deleteIncidentPolicyIsLoading ||
    editIncidentPolicyIsLoading ||
    addIncidentPolicyIsLoading;

  const resetInitialFields = () => {
    const initialSeverityValues: SeveritiesFindingType = {
      CRITICAL: 0,
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
      INFORMATIONAL: 0,
    };
    const initialFormValues = {
      minimumSeverities: initialSeverityValues,
      period: POLICY_PERIOD_OPTIONS[4].value,
      frequency: POLICY_FREQUENCY_OPTIONS[3].value,
    };
    form.setFieldsValue(initialFormValues);
    setFrequencyState(initialFormValues.frequency);
  };

  const removeIDFromPathname = () => {
    if (incidentPolicyID) {
      const pathname = location.pathname.split('/');
      pathname.pop(); // remove id
      history.replace({ pathname: pathname.join('/') });
    }
  };

  const onClose = () => {
    onCloseModal();
    removeIDFromPathname();
    form.resetFields();
    setFilters([]);
    setErrorMessage('');
    setIncidentPolicy(undefined);
  };

  const validateSum = async (_: RuleObject, value: number) => {
    const values = form.getFieldsValue() as {
      minimumSeverities: SeveritiesFindingType;
    };
    const sum = Object.values(values.minimumSeverities).reduce(
      (acc, val) => acc + (val || 0),
      0
    );

    if (sum < 1) {
      setValidationError(true);
      setErrorMessage('You must have at least 1 minimum severity.');
      return Promise.reject();
    } else {
      if (validationError) {
        setValidationError(false);
        setErrorMessage('');
      }
      return Promise.resolve();
    }
  };

  const validatePeriod = async (_: RuleObject, period: number) => {
    const frequency = form.getFieldValue('frequency');

    if (frequency > period) {
      return Promise.reject(
        'Period must be greater than or equal to the frequency.'
      );
    }

    return Promise.resolve();
  };

  const showDeleteConfirm = (incidentPolicy: IncidentPolicyClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this incident policy?`,
      icon: <ExclamationCircleOutlined />,
      content: `Name: ${incidentPolicy.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return deleteIncidentPolicy({
          orgID,
          incidentPolicyID: incidentPolicy.UUID,
        }).then((_) => {
          onClose();
        });
      },
    });
  };

  const onEdit = async () => {
    const minimumSeverities: SeveritiesFindingType = {
      CRITICAL: form.getFieldValue(['minimumSeverities', 'CRITICAL']) || 0,
      HIGH: form.getFieldValue(['minimumSeverities', 'HIGH']) || 0,
      MEDIUM: form.getFieldValue(['minimumSeverities', 'MEDIUM']) || 0,
      LOW: form.getFieldValue(['minimumSeverities', 'LOW']) || 0,
      INFORMATIONAL:
        form.getFieldValue(['minimumSeverities', 'INFORMATIONAL']) || 0,
    };

    const response = await editIncidentPolicy({
      orgID,
      incidentPolicyID: incidentPolicy?.UUID || '',
      data: {
        minimumSeverities,
        notificationIntegrations: form.getFieldValue(
          'notificationIntegrations'
        ),
        name: form.getFieldValue('name'),
        frequency: form.getFieldValue('frequency'),
        period: form.getFieldValue('period'),
        filters: filtersToSubmit,
      },
    }).unwrap();

    if (response) {
      onClose();
    }
  };

  const onSave = async () => {
    const minimumSeverities = {
      CRITICAL: form.getFieldValue(['minimumSeverities', 'CRITICAL']) || 0,
      HIGH: form.getFieldValue(['minimumSeverities', 'HIGH']) || 0,
      MEDIUM: form.getFieldValue(['minimumSeverities', 'MEDIUM']) || 0,
      LOW: form.getFieldValue(['minimumSeverities', 'LOW']) || 0,
      INFORMATIONAL:
        form.getFieldValue(['minimumSeverities', 'INFORMATIONAL']) || 0,
    };

    const response = await addIncidentPolicy({
      orgID,
      data: {
        minimumSeverities,
        notificationIntegrations: form.getFieldValue(
          'notificationIntegrations'
        ),
        name: form.getFieldValue('name'),
        frequency: form.getFieldValue('frequency'),
        period: form.getFieldValue('period'),
        filters: filtersToSubmit,
      },
    }).unwrap();

    if (response) {
      onClose();
    }
  };

  useEffect(() => {
    if (incidentPolicyID) {
      getIncidentPolicy({ orgID, incidentPolicyID }, true)
        .unwrap()
        .then((incidentPolicyResponse) => {
          setIncidentPolicy(incidentPolicyResponse);

          const initialSeverityValues = {
            CRITICAL: incidentPolicyResponse?.minimumSeverities?.CRITICAL,
            HIGH: incidentPolicyResponse?.minimumSeverities?.HIGH,
            MEDIUM: incidentPolicyResponse?.minimumSeverities?.MEDIUM,
            LOW: incidentPolicyResponse?.minimumSeverities?.LOW,
            INFORMATIONAL:
              incidentPolicyResponse?.minimumSeverities?.INFORMATIONAL,
          };

          const initialFormValues = {
            name: incidentPolicyResponse.name,
            minimumSeverities: initialSeverityValues,
            period: incidentPolicyResponse.period,
            frequency: incidentPolicyResponse.frequency,
            notificationIntegrations:
              incidentPolicyResponse.notificationIntegrations,
          };

          form.setFieldsValue(initialFormValues);
          form.validateFields(['period']);
          setFrequencyState(incidentPolicyResponse.frequency);
        })
        .catch(() => {
          removeIDFromPathname();
        });
    } else resetInitialFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentPolicyID, open, orgID, getIncidentPolicy]);

  useEffect(() => {
    if (incidentPolicy)
      if (
        incidentPolicy.filters.length > 0 &&
        resourceData &&
        resourceData?.length > 0
      ) {
        const allValues: string[] = [];
        incidentPolicy.filters.forEach((filter) => {
          filter?.values?.forEach((value) => {
            allValues.push(value);
          });
        });

        setFilters(
          [
            {
              field: 'apiUUID',
              values:
                allValues
                  .map((value) => {
                    const found = resourceData.find((r) => {
                      const val = r.value as string;
                      return val === value;
                    })?.value as string;

                    if (found && found.startsWith('api:')) {
                      return found.replace('api:', '');
                    }
                    return '';
                  })
                  .filter((value) => value !== '') || [],
            },
            {
              field: 'appUUID',
              values:
                allValues
                  .map((value) => {
                    const found = resourceData.find((r) => {
                      const val = r.value as string;
                      return val === value;
                    })?.value as string;

                    if (found && found.startsWith('app:')) {
                      return found.replace('app:', '');
                    }
                    return '';
                  })
                  .filter((value) => value !== '') || [],
            },
          ].filter((v) => v.values.length > 0)
        );
      }
  }, [incidentPolicy, resourceData]);

  useEffect(() => {
    if (filters.length > 0 && resourceData && resourceData?.length > 0) {
      const allValues: string[] = [];
      filters.forEach((filter) => {
        filter?.values?.forEach((value) => {
          allValues.push(value);
        });
      });

      setFiltersToSubmit([
        {
          field: 'resources',
          values:
            allValues.map((value) => {
              const found = resourceData.find((r) => {
                const val = r.value as string;
                if (val.startsWith('api:')) {
                  return val.replace('api:', '') === value;
                }
                if (val.startsWith('app:')) {
                  return val.replace('app:', '') === value;
                }
                return false;
              })?.value as string;

              return found;
            }) || [],
        },
      ]);

      setListFilters([
        {
          field: 'resources',
          values:
            allValues.map((value) => {
              const found = resourceData.find((r) => {
                const val = r.value as string;
                if (val.startsWith('api:')) {
                  return val.replace('api:', '') === value;
                }
                if (val.startsWith('app:')) {
                  return val.replace('app:', '') === value;
                }
                return '';
              })?.value as string;

              return found;
            }) || [],
        },
      ]);
    }

    if (filters.length === 0) {
      setListFilters([]);
      setFiltersToSubmit([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fieldBuilder = new FieldBuilder(form);

  const selectIntegrationField: FieldType = {
    attribute: 'notificationIntegrations',
    inputType: 'multiselect',
    placeholder: 'Notification Integrations',
    name: 'Notification Integrations',
    sourceFrom: {
      resource: 'integration',
      filters: [{ field: 'category', values: ['notification'] }],
      creationOptions: {
        categories: ['notification'],
      },
    },
    validation: {
      required: true,
      max: 10,
    },
    fieldSize: 'extralarge',
    colSize: 'extralarge',
  };

  const selectIntegrationFormItem = fieldBuilder.getFormItem(
    selectIntegrationField
  );

  const isOpen = !!incidentPolicy || open;

  const showFooter =
    hasPermissionToDeleteIncidentPolicy ||
    hasPermissionToUpdateIncidentPolicy ||
    hasPermissionToCreateIncidentPolicy;

  const isFormDisabled =
    !hasPermissionToUpdateIncidentPolicy &&
    !hasPermissionToCreateIncidentPolicy;

  return (
    <>
      <FullScreenLoader
        loading={isFetchingIncidentPolicy || isLoadingIncidentPolicy}
      />

      <Modal
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        forceRender
        width={1000}
        style={{ top: 50 }}
        okText='Confirm'
        data-testid={isOpen ? 'incidents-policy-modal' : ''}
        open={isOpen}
        title={
          <div className='flex items-center gap-x-2'>
            <span>Incident Policy</span>
          </div>
        }
        onCancel={onClose}
        footer={
          showFooter ? (
            <div className='flex justify-between'>
              <div>
                {incidentPolicy && hasPermissionToDeleteIncidentPolicy && (
                  <Button
                    loading={loading}
                    danger
                    onClick={() => showDeleteConfirm(incidentPolicy)}
                    disabled={loading}
                  >
                    Delete
                  </Button>
                )}
              </div>
              {((incidentPolicy && hasPermissionToUpdateIncidentPolicy) ||
                (!incidentPolicy && hasPermissionToCreateIncidentPolicy)) && (
                <Button
                  loading={loading}
                  type='primary'
                  onClick={() => form.submit()}
                  disabled={loading}
                >
                  Confirm
                </Button>
              )}
            </div>
          ) : null
        }
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={incidentPolicy ? onEdit : onSave}
          disabled={isFormDisabled}
        >
          <div
            className='grid grid-cols-4 gap-4 overflow-auto -m-6 p-6'
            style={{
              maxHeight: '60vh',
              paddingLeft: '7rem',
              paddingRight: '7rem',
            }}
          >
            <Form.Item
              label='Name'
              name='name'
              rules={[{ required: true, message: 'Name is required' }]}
              className='col-span-4'
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={'frequency'}
              label='Frequency'
              rules={[{ required: true, message: 'Frequency is required' }]}
              className='col-span-2'
              tooltip='How frequently the policy is checked.'
            >
              <Select
                className='w-1/2'
                onChange={(frequency) => {
                  form.validateFields(['period']);
                  setFrequencyState(frequency);
                }}
              >
                {POLICY_FREQUENCY_OPTIONS.map((f) => (
                  <Select.Option key={f.id} value={f.value}>
                    {f.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={'period'}
              label='Period'
              rules={[
                { required: true, message: 'Period is required' },
                {
                  validator: validatePeriod,
                },
              ]}
              className='col-span-2'
              tooltip='How far back the policy check will go (for example, occurrences in the last 15 minutes etc)'
            >
              <Select className='w-1/4'>
                {POLICY_PERIOD_OPTIONS.filter(
                  (p) => frequencyState && p.value >= frequencyState
                ).map((p) => (
                  <Select.Option key={p.id} value={p.value}>
                    {p.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div className='col-span-4'>
              <div className='mb-2'>Minimum Severities</div>
              <div className={`flex flex-col gap-x-1`}>
                {errorMessage && (
                  <Alert
                    message={errorMessage}
                    type='error'
                    className='mb-4'
                    showIcon
                  />
                )}
                <div className='grid grid-cols-5 gap-2'>
                  {['CRITICAL', 'HIGH', 'MEDIUM', 'LOW', 'INFORMATIONAL'].map(
                    (severity) => {
                      return (
                        <Form.Item
                          key={severity}
                          name={['minimumSeverities', severity]}
                          label={
                            <span className='capitalize'>
                              {severity.toLowerCase()}
                            </span>
                          }
                          rules={[
                            {
                              validator: validateSum,
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={10000}
                            className='w-full border border-black'
                          />
                        </Form.Item>
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            <div className='col-span-4'>{selectIntegrationFormItem}</div>

            <div className='col-span-4'>
              <div className='mb-2 font-semibold'>Filters</div>
              <div className='flex flex-col'>
                {filters && (
                  <DashboardFiltersRoot
                    defaultFilters={filters.map((filter) => ({
                      groups: [
                        {
                          field: filter.field,
                          id: nanoid(),
                          operator: 'is-one-of',
                          values: filter.values,
                          disabled: true,
                        },
                      ],
                      id: nanoid(),
                      operator: 'include',
                      disabled: true,
                    }))}
                  >
                    <DashboardFiltersGroupsConditionalsRows
                      disable={isFormDisabled}
                      whichLabel='Findings'
                    />
                  </DashboardFiltersRoot>
                )}
              </div>
              <div className='flex flex-row'>
                <List.Filter
                  resource='incident_policy'
                  onAdd={(filters) => {
                    setFilters(
                      [
                        {
                          field: 'apiUUID',
                          values: filters.resources
                            ? filters.resources
                                .filter((resource: string) =>
                                  resource.startsWith('api:')
                                )
                                .map((resource: string) =>
                                  resource.replace('api:', '')
                                )
                            : [],
                        },
                        {
                          field: 'appUUID',
                          values: filters.resources
                            ? filters.resources
                                .filter((resource: string) =>
                                  resource.startsWith('app:')
                                )
                                .map((resource: string) =>
                                  resource.replace('app:', '')
                                )
                            : [],
                        },
                      ].filter((item) => item.values.length > 0)
                    );
                  }}
                  prevDef
                  manuallyAddedFilters={listFilters}
                  renderOptionLabel={(options: ValueType[]) => {
                    return options.map((opt: ValueType) => (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label
                          ?.toString()
                          .replace(/devTagAsApi|devTagAsApp$/, '')}
                        {opt.label?.toString().includes('devTagAsApi') && (
                          <Tag
                            style={{
                              position: 'absolute',
                              top: '15%',
                              right: '5%',
                            }}
                            className='bg-slate-300'
                          >
                            API
                          </Tag>
                        )}
                        {opt.label?.toString().includes('devTagAsApp') && (
                          <Tag
                            style={{
                              position: 'absolute',
                              top: '15%',
                              right: '5%',
                            }}
                            className='bg-slate-300'
                          >
                            APP
                          </Tag>
                        )}
                      </Select.Option>
                    ));
                  }}
                  renderTags
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default IncidentPolicyModal;
