import {
  MemberClass,
  MemberInterface,
  MemberRoleInterface,
} from '@/utils/models/Member';
import baseApi from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';

interface ListMembersParams extends OrgGenericParams {
  query?: string;
}

interface AddMemberParams extends OrgGenericParams {
  data: { members: { email: string; roleID: number }[] };
}

interface ResendEmailParams extends OrgGenericParams {
  data: { email: string };
}

interface DeleteMemberParams extends OrgGenericParams {
  data: { members: { email: string }[] };
}

export const memberEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listMembers: build.query<MemberClass[], ListMembersParams>({
      async queryFn({ orgID, query }, _queryApi, _extraOptions, fetchWithBQ) {
        let result;
        if (query) {
          result = await fetchWithBQ({
            url: `/organisations/${orgID}/members/search`,
            method: 'POST',
            data: { search_value: query },
          });
        } else {
          result = await fetchWithBQ(`/organisations/${orgID}/members`);
        }
        const { members } = result.data as {
          members: MemberInterface[];
        };
        return {
          data: members
            .sort(
              (memberA, memberB) =>
                Number(memberA.roleID) - Number(memberB.roleID)
            )
            .map((member) => new MemberClass(member)),
        };
      },
      providesTags: ['Members'],
    }),
    upsertMembers: build.mutation<void, AddMemberParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/members`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(memberEndpoints.util.invalidateTags(['Members']));

          setTimeout(() => {
            dispatch(memberEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteMembers: build.mutation<void, DeleteMemberParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/members`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(memberEndpoints.util.invalidateTags(['Members']));

          setTimeout(() => {
            dispatch(memberEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    resendEmail: build.mutation<void, ResendEmailParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/email/resend_invite`,
        method: 'PUT',
        data,
      }),
    }),
    listMemberRoles: build.query<MemberRoleInterface[], OrgGenericParams>({
      query: ({ orgID }) => `/organisations/${orgID}/roles`,
      transformResponse: (rawResult: { roles: MemberRoleInterface[] }) =>
        rawResult.roles,
    }),
  }),
});

export const {
  useListMembersQuery,
  useLazyListMembersQuery,
  useUpsertMembersMutation,
  useDeleteMembersMutation,
  useResendEmailMutation,
  useListMemberRolesQuery,
} = memberEndpoints;
