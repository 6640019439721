import { AlertClass } from '@/utils/models/Alert';
import { FilterClass } from '@/utils/models/Filter';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListAlertingResponse } from './alerting-endpoints';

export const mockGetAlertAfterEdition = ({ alert }: { alert: AlertClass }) => {
  return {
    ...alert,
    created: alert.created,
    createdFromNow: alert.createdFromNow,
    category: alert.config.type === 'anomaly' ? 'Anomaly Detection' : 'Static',
    filtersGroups: FilterClass.mapFiltersGroups(alert.filters),
    type: alert.config.type || 'static',
    managed: alert.managed,
    managed_alert_uuid: alert.managed_alert_uuid,
  };
};

export const mockListAlertAfterEdition = ({
  draft,
  alert,
}: {
  draft: MaybeDrafted<ListAlertingResponse>;
  alert: AlertClass;
}) => {
  const index = draft.alerts.findIndex((a) => a.UUID === alert.UUID);
  if (index !== -1) {
    draft.alerts[index] = alert;
  }
  return draft;
};

export const mockListAlertAfterAdition = ({
  draft,
  alert,
}: {
  draft: MaybeDrafted<ListAlertingResponse>;
  alert: AlertClass;
}) => {
  draft.alerts.unshift(alert);
  draft.total += 1;
};

export const mockListAlertAfterDeletion = ({
  draft,
  alertID,
}: {
  draft: MaybeDrafted<ListAlertingResponse>;
  alertID: string;
}) => {
  draft.alerts = draft.alerts.filter((app) => app.UUID !== alertID);
  draft.total -= 1;
  return draft;
};
