import ItemBadge from './ItemBadge';
import ItemButton from './ItemButton';
import ItemCheckBox from './ItemCheckBox';
import ItemCopyButton from './ItemCopyButton';
import ItemDate from './ItemDate';
import ItemDescription from './ItemDescription';
import ItemFooter from './ItemFooter';
import ItemHeader from './ItemHeader';
import ItemLinkRedirect from './ItemLinkRedirect';
import ItemLogo from './ItemLogo';
import ItemLogoAndName from './ItemLogoAndName';
import ItemRegionFlag from './ItemRegionFlag';
import ItemRoot from './ItemRoot';
import ItemSeveritiesOverview from './ItemSeveritiesOverview';
import ItemSpan from './ItemSpan';
import ItemTitle from './ItemTitle';
import ItemUUID from './ItemUUID';

const Item = {
  Root: ItemRoot,
  Header: ItemHeader,
  Footer: ItemFooter,
  Button: ItemButton,
  Date: ItemDate,
  CheckBox: ItemCheckBox,
  Title: ItemTitle,
  Description: ItemDescription,
  Logo: ItemLogo,
  Badge: ItemBadge,
  LogoAndName: ItemLogoAndName,
  SeveritiesOverview: ItemSeveritiesOverview,
  UUID: ItemUUID,
  CopyButton: ItemCopyButton,
  LinkRedirect: ItemLinkRedirect,
  RegionFlag: ItemRegionFlag,
  Span: ItemSpan,
};

export default Item;
