import { useGetQuotasForLoggingDurationQuery } from '@/utils/services/quota/quota-endpoints';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  JsonParam,
  QueryParamConfig,
  UrlUpdateType,
  useQueryParams,
} from 'use-query-params';

export interface DynamicDateTime {
  start?: string;
  end?: string;
  value?: number;
}

interface UseDateTimeQueryProps {
  updateType?: UrlUpdateType;
}

function useDynamicDateTimeQueryParam(props?: UseDateTimeQueryProps) {
  const updateType: UrlUpdateType = props?.updateType || 'replaceIn';
  const { orgID } = useParams<{ orgID: string }>();

  const loggingDurationQuery = useGetQuotasForLoggingDurationQuery({ orgID });
  const { data: loggingDurationData } = loggingDurationQuery;

  const [{ dateTime }, setQueryParams] = useQueryParams<{
    dateTime: QueryParamConfig<DynamicDateTime | undefined>;
  }>({
    dateTime: JsonParam,
  });

  const dt = useMemo(() => {
    let dt = dateTime;
    if (!dateTime && loggingDurationData) {
      dt = {
        start: undefined,
        end: undefined,
        value: loggingDurationData.retention_days * 24 * 60 * 60,
      };
    }

    return dt;
  }, [dateTime, loggingDurationData]);

  const setDateTime = useCallback(
    (newDateTime?: DynamicDateTime) => {
      setQueryParams(
        {
          dateTime: newDateTime,
        },
        updateType
      );
    },
    [setQueryParams, updateType]
  );

  return {
    dateTime: dt,
    setDateTime,
    loggingDurationQuery,
  };
}

export default useDynamicDateTimeQueryParam;
