import { FindingClass } from '@/utils/models/Finding';
import { Editor, EditorProps } from '@monaco-editor/react';

const FindingInspectorCodeBlock = ({ finding }: { finding?: FindingClass }) => {
  const specification = finding?.specification;
  const report = finding?.report;

  const defineEditorTheme = (editor: any, monaco: any) => {
    monaco.editor.defineTheme('FindingTheme', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#FAFAFA',
        'scrollbar.shadow': '#ffffff',
      },
    });

    monaco.editor.setTheme('FindingTheme');
  };

  if (specification?.version?.specification) {
    const handleEditorDidMount = (editor: any, monaco: any) => {
      defineEditorTheme(editor, monaco);

      if (report && report?.range?.start?.line && report?.range?.end?.line) {
        editor.deltaDecorations(
          [],
          [
            {
              range: new monaco.Range(
                report.range.start.line,
                report.range.start.character || 0,
                report.range.end.line,
                report.range.end.character || 0
              ),
              options: {
                isWholeLine: true,
                className: 'monaco-editor-decoration',
              },
            },
          ]
        );

        const lineNumber = report.range.start.line - 2;
        const column = 1;

        editor.setPosition({ lineNumber, column });
        editor.revealLineInCenter(lineNumber);
      }
    };

    const props: EditorProps = {
      options: {
        readOnly: true,
        minimap: { enabled: false },
        glyphMargin: true,
        scrollBeyondLastLine: false,
      },
      height: '35vh',
      defaultLanguage: 'json',
      defaultValue: JSON.stringify(
        specification?.version?.specification,
        null,
        4
      ),
      theme: 'Active4D',
      onMount: handleEditorDidMount,
    };

    return <Editor {...props} />;
  }

  if (report) {
    const handleEditorDidMount = (editor: any, monaco: any) => {
      defineEditorTheme(editor, monaco);
    };

    const props: EditorProps = {
      options: {
        readOnly: true,
        minimap: { enabled: false },
        glyphMargin: true,
        scrollBeyondLastLine: false,
      },
      height: '35vh',
      defaultLanguage: 'json',
      defaultValue: JSON.stringify(report, null, 4),
      theme: 'Active4D',
      onMount: handleEditorDidMount,
    };

    return <Editor {...props} />;
  }

  return <></>;
};

export default FindingInspectorCodeBlock;
