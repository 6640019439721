import { Tag, Tooltip } from 'antd';
import { getStyleColors, getStatusCodeTagColor } from '@/components/Tag/utils';
import LIST_OF_HTTP_STATUS_CODE from '@/utils/constants/listOfHttpStatusCode';

const StatusCodeTag = ({
  code,
  dark = false,
  onMouseDown,
  closable,
  onClose,
  showTooltip = true,
  showTitle = false,
}: {
  code?: string | number;
  dark?: boolean;
  onMouseDown?: any;
  closable?: boolean;
  onClose?: any;
  showTooltip?: boolean;
  showTitle?: boolean;
}) => {
  if (!code) return <></>;

  code = code.toString();

  const statusCode = LIST_OF_HTTP_STATUS_CODE.find(
    (status) => `${status.code}` === code
  );
  const color = getStatusCodeTagColor(code);
  const style = getStyleColors(color, dark);

  const TagComponent = (
    <Tag
      key={code}
      data-testid={code}
      color={color}
      style={style}
      onMouseDown={onMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {code} {showTitle && statusCode && `- ${statusCode?.title}`}
    </Tag>
  );

  if (showTooltip) {
    return <Tooltip title={statusCode?.description}>{TagComponent}</Tooltip>;
  }

  return TagComponent;
};

export default StatusCodeTag;
