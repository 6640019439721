import { counterFormat } from '@/utils/methods/displayFormat';
import { useGetRequestsLogCountersQuery } from '@/utils/services/org/org-endpoints';
import { Pie } from '@ant-design/plots';
import { Empty, Tooltip } from 'antd';
import { useMemo } from 'react';
import Loader from '../Loader/Loader';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';
import { GenericObject } from '@/utils/constants';

const DashboardRequestsDonutChart = ({ light }: { light?: boolean }) => {
  const { params, options } = useGetEndpointSettings();
  const textColor = light ? '' : 'text-anti-flash';
  const {
    currentData: logCountersData,
    isLoading,
    isFetching,
  } = useGetRequestsLogCountersQuery(params, options);

  const getStatusColor = (status: string) => {
    switch (status) {
      case '2XX':
        return '#227AFF';
      case '4XX':
        return '#F28705';
      case '5XX':
        return '#cf1322';
      default:
        return '#FFFFFF';
    }
  };

  const Chart = useMemo(() => {
    const BulletStatus = ({
      status,
      value,
      breakdown,
    }: {
      status: string;
      value: number;
      breakdown: GenericObject;
    }) => {
      return (
        <Tooltip
          title={
            <div className='flex flex-col'>
              <div className='flex flex-row border-b border-white'>
                {status}: {value}
              </div>
              {Object.entries(breakdown).map(([key, val]) => (
                <div className='flex flex-row' key={key}>
                  {`${key}: ${val ?? 0}`}
                </div>
              ))}
            </div>
          }
          color={'black'}
        >
          <div className='flex items-center'>
            <div
              style={{
                backgroundColor: getStatusColor(status),
                width: 10,
                height: 10,
              }}
              className='mr-2 rounded-full'
            />
            <span>{status}</span>
          </div>
        </Tooltip>
      );
    };
    const totalRequests: number = logCountersData
      ? logCountersData.reduce((a, b) => a + b.value, 0)
      : 0;
    return (
      <div className='flex items-center'>
        <Pie
          className='text-left'
          height={200}
          width={200}
          data={logCountersData || []}
          angleField={'value'}
          colorField={'key'}
          radius={1}
          innerRadius={0.8}
          legend={false}
          label={{
            type: 'inner',
            content: () => '',
          }}
          pieStyle={{
            lineWidth: 0,
          }}
          statistic={{
            title: false,
            content: {
              customHtml: () => '',
            },
          }}
          color={({ key }) => getStatusColor(key)}
          tooltip={{
            customContent: (title, data) => {
              if (title && data) {
                const displayData = data[0];
                return (
                  <div className='mt-1'>
                    <div className=''>
                      <div className='mb-1 border-b border-black'>
                        {title}: {displayData?.value}
                      </div>
                      {displayData.data.breakdown &&
                        Object.entries(displayData.data.breakdown).map(
                          ([key, val]) => (
                            <div className='mb-1' key={key}>
                              {`${key}: ${val ?? 0}`}
                            </div>
                          )
                        )}
                    </div>
                  </div>
                );
              }
            },
          }}
        />

        <div className={`${textColor} ml-8 w-full`}>
          <div className='grid'>
            <span className='text-4xl font-semibold'>
              {counterFormat(totalRequests)}
            </span>
            <span className={textColor}>Total requests</span>
          </div>

          <div className={`mt-3 ${textColor} grid grid-cols-6 gap-2`}>
            {logCountersData?.map((c) => (
              <BulletStatus
                key={c.key}
                status={c.key}
                value={c.value}
                breakdown={c.breakdown}
              />
            ))}
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logCountersData]);

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex items-center gap-4'>
        <span className={`${textColor} text-xl`}>
          API requests grouped by status code
        </span>
        <Loader spinning={isLoading || isFetching} />
      </div>
      {logCountersData && logCountersData?.length > 0 ? (
        Chart
      ) : (
        <div className='py-6'>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span className={textColor}>No data</span>}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardRequestsDonutChart;
