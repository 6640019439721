import FieldType from '@/utils/models/FieldType';
import { getFieldSize } from './const/functions';

const HeaderField = ({ field }: { field: FieldType }) => {
  return (
    <div className={`flex ${getFieldSize(field.fieldSize || 'extralarge')}`}>
      {field.avatar && (
        <img
          className='h-6 mt-1 mr-2'
          key='github'
          alt='github'
          src={field.avatar}
        />
      )}
      {field.name && <span className='pr-3 text-2xl'>{field.name}</span>}
    </div>
  );
};

export default HeaderField;
