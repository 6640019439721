import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../services/organisation/organisation-endpoints';

function useRole() {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: organisation } = useGetOrganisationQuery({ orgID });

  const hasPermissionTo = useMemo(() => {
    return organisation?.role?.hasPermissionTo();
  }, [organisation]);

  const hasPermissionToListApplication = hasPermissionTo?.list?.application;

  const hasPermissionToListApi = hasPermissionTo?.list?.api;

  const hasPermissionToListIntegration = hasPermissionTo?.list?.integration;

  const hasPermissionToGetApplication = hasPermissionTo?.get?.application;

  const hasPermissionToGetSpecification = hasPermissionTo?.get?.specification;

  const hasPermissionToUpdateMember = hasPermissionTo?.update?.member;

  const hasPermissionToDeleteMember = hasPermissionTo?.delete?.member;

  const hasPermissionToCreateMember = hasPermissionTo?.create?.member;

  const hasPermissionToResendEmail = hasPermissionTo?.resend?.email;

  const hasPermissionToListMember = hasPermissionTo?.list?.member;

  const hasPermissionToUpdateBillingPlan =
    hasPermissionTo?.update?.billingPlan &&
    organisation?.isBillingPlatformStripe;

  const hasPermissionToUpdateBillingDetails =
    hasPermissionTo?.update?.billingDetails &&
    organisation?.isBillingPlatformStripe &&
    organisation?.showStripeBilling;

  const hasPermissionToListFinding = hasPermissionTo?.list?.finding;

  const hasPermissionToGetFinding = hasPermissionTo?.get?.finding;

  const hasPermissionToListIncidentPolicy = hasPermissionTo?.list?.incident;

  const hasPermissionToGetIncidentPolicy = hasPermissionTo?.get?.incidentPolicy;

  const hasPermissionToUpdateIncidentPolicy =
    hasPermissionTo?.update.incidentPolicy;

  const hasPermissionToCreateIncidentPolicy =
    hasPermissionTo?.create.incidentPolicy;

  const hasPermissionToDeleteIncidentPolicy =
    hasPermissionTo?.delete.incidentPolicy;

  const hasPermissionToListIncident = hasPermissionTo?.list?.incident;

  const hasPermissionToGetIncident = hasPermissionTo?.get?.incident;

  const hasPermissionToUpdateIncident = hasPermissionTo?.update?.incident;

  const hasPermissionToCreateAnomalyDetection =
    organisation?.showAnomalyDetectionFeature;

  const hasPermissionToListEvent = hasPermissionTo?.list?.event;

  const hasPermissionToGetEvent = hasPermissionTo?.get.event;

  const hasPermissionToCreateAlerting = hasPermissionTo?.create?.alerting;

  const hasPermissionToGetAlerting = hasPermissionTo?.get?.alerting;

  const hasPermissionToListAlerting = hasPermissionTo?.list?.alerting;

  const hasPermissionToGetIntegration = hasPermissionTo?.get?.integration;

  const hasPermissionToCreateIntegration = hasPermissionTo?.create?.integration;

  const hasPermissionToDeleteApplication = hasPermissionTo?.delete?.application;

  const hasPermissionToListApplicationToken =
    hasPermissionTo?.list?.applicationToken;

  const hasPermissionToListSpecification = hasPermissionTo?.list?.specification;

  const hasPermissionToUpdateSpecification =
    hasPermissionTo?.update?.specification;

  const hasPermissionToCreateSpecificationVersion =
    hasPermissionTo?.create?.specificationVersion;

  const hasPermissionToDeleteSpecification =
    hasPermissionTo?.delete?.specification;

  const hasPermissionToDeleteApi = hasPermissionTo?.delete?.api;

  const hasPermissionToListApiToken = hasPermissionTo?.list?.apiToken;

  const hasPermissionToGetApi = hasPermissionTo?.get?.api;

  const hasPermissionToCreateApplication = hasPermissionTo?.create?.application;

  const hasPermissionToGetOrganisation = hasPermissionTo?.get?.organisation;

  const hasPermissionToCreateApiToken = hasPermissionTo?.create?.apiToken;

  const hasPermissionToCreateApplicationToken =
    hasPermissionTo?.create?.applicationToken;

  const hasPermissionToDeleteApiToken = hasPermissionTo?.delete?.apiToken;

  const hasPermissionToDeleteApplicationToken =
    hasPermissionTo?.delete?.applicationToken;

  const hasPermissionToCreateSpecification =
    hasPermissionTo?.create?.specification;

  const hasPermissionToDeleteAlerting = hasPermissionTo?.delete?.alerting;

  const hasPermissionToUpdateAlerting = hasPermissionTo?.update?.alerting;

  const hasPermissionToGetAlertingPreview =
    hasPermissionTo?.get?.alertingPreview;

  const hasPermissionToUpdateIntegration = hasPermissionTo?.update?.integration;

  const hasPermissionToDeleteIntegration = hasPermissionTo?.delete?.integration;

  const hasPermissionToUpdateFinding = hasPermissionTo?.update?.finding;

  const hasPermissionToCreateFilterConfiguration =
    hasPermissionTo?.create?.filterConfiguration;

  const hasPermissionToDeleteFilterConfiguration =
    hasPermissionTo?.delete?.filterConfiguration;

  const hasPermissionToCreateApi = hasPermissionTo?.create?.api;

  const hasPermissionToUpdateApi = hasPermissionTo?.update?.api;

  const hasPermissionToListActions =
    hasPermissionTo?.list.action && organisation?.showActionsFeature;

  const hasPermissionToGetActions =
    hasPermissionTo?.get.action && organisation?.showActionsFeature;

  const hasPermissionToDeleteActions =
    hasPermissionTo?.delete.action && organisation?.showActionsFeature;

  const hasPermissionToUpdateActions =
    hasPermissionTo?.update.action && organisation?.showActionsFeature;

  const hasPermissionToCreateActions =
    hasPermissionTo?.create.action && organisation?.showActionsFeature;

  const hasPermissionToListReports = hasPermissionTo?.list.report;

  const hasPermissionToGetReports = hasPermissionTo?.get.report;

  return {
    hasPermissionToUpdateApi,
    hasPermissionToResendEmail,
    hasPermissionToCreateApi,
    hasPermissionToCreateFilterConfiguration,
    hasPermissionToCreateAlerting,
    hasPermissionToCreateIntegration,
    hasPermissionToCreateSpecificationVersion,
    hasPermissionToCreateApplication,
    hasPermissionToCreateApiToken,
    hasPermissionToCreateApplicationToken,
    hasPermissionToCreateSpecification,
    hasPermissionToCreateMember,
    hasPermissionToListApi,
    hasPermissionToListApplication,
    hasPermissionToListIntegration,
    hasPermissionToListAlerting,
    hasPermissionToListMember,
    hasPermissionToListFinding,
    hasPermissionToListIncidentPolicy,
    hasPermissionToCreateIncidentPolicy,
    hasPermissionToUpdateIncidentPolicy,
    hasPermissionToDeleteIncidentPolicy,
    hasPermissionToListIncident,
    hasPermissionToListEvent,
    hasPermissionToListApplicationToken,
    hasPermissionToListSpecification,
    hasPermissionToListApiToken,
    hasPermissionToUpdateFinding,
    hasPermissionToUpdateMember,
    hasPermissionToUpdateBillingPlan,
    hasPermissionToUpdateBillingDetails,
    hasPermissionToUpdateSpecification,
    hasPermissionToUpdateAlerting,
    hasPermissionToUpdateIntegration,
    hasPermissionToUpdateIncident,
    hasPermissionToDeleteFilterConfiguration,
    hasPermissionToDeleteMember,
    hasPermissionToDeleteApplication,
    hasPermissionToDeleteSpecification,
    hasPermissionToDeleteApi,
    hasPermissionToDeleteApiToken,
    hasPermissionToDeleteApplicationToken,
    hasPermissionToDeleteAlerting,
    hasPermissionToDeleteIntegration,
    hasPermissionToGetApplication,
    hasPermissionToGetSpecification,
    hasPermissionToGetFinding,
    hasPermissionToGetIncident,
    hasPermissionToGetAlerting,
    hasPermissionToGetIntegration,
    hasPermissionToGetApi,
    hasPermissionToGetOrganisation,
    hasPermissionToGetAlertingPreview,
    hasPermissionToCreateAnomalyDetection,
    hasPermissionToGetEvent,
    hasPermissionToListActions,
    hasPermissionToGetActions,
    hasPermissionToDeleteActions,
    hasPermissionToUpdateActions,
    hasPermissionToCreateActions,
    hasPermissionToGetIncidentPolicy,
    hasPermissionToListReports,
    hasPermissionToGetReports,
  };
}

export default useRole;
