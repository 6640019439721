import FieldType from '@/utils/models/FieldType';
import { getFieldSize } from './const/functions';
import useFormsVariables from '@/utils/hooks/useFormsVariables';

const LabelField = ({ field }: { field: FieldType }) => {
  const { replaceValues } = useFormsVariables();
  const fieldValue = replaceValues(field.value);

  return (
    <div className={`flex ${getFieldSize(field.fieldSize || 'middle')}`}>
      {field.name && <span className='pr-3'>{field.name}</span>}
      <div dangerouslySetInnerHTML={{ __html: fieldValue }} />
    </div>
  );
};

export default LabelField;
