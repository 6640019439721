import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const TextAreaField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  return (
    <Form.Item
      key={field.attribute}
      name={field.attribute}
      label={field.name}
      rules={rules}
      tooltip={field.description}
      className={getFieldSize(field.fieldSize || 'extralarge')}
    >
      <TextArea
        rows={field.validation?.rows || 4}
        placeholder={field.placeholder}
        maxLength={field.validation?.max || 256}
        minLength={field.validation?.min || 0}
        className={'h-24'}
        disabled={field.disabled}
      />
    </Form.Item>
  );
};

export default TextAreaField;
