import { AlertClass } from '@/utils/models/Alert';
import { Button, FormInstance, Modal } from 'antd';
import useRole from '@/utils/hooks/useRole';
import { useDeleteAlertMutation } from '@/utils/services/alerting/alerting-endpoints';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const AlertFooter = ({
  alert,
  handleClose,
  formInstance,
  isLoading,
  showSubmit = true,
  showUpdate = true,
}: {
  alert?: AlertClass;
  handleClose: () => void;
  formInstance: FormInstance<any>;
  isLoading?: boolean;
  showSubmit?: boolean;
  showUpdate?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isCreation = !alertID;
  const {
    hasPermissionToDeleteAlerting,
    hasPermissionToUpdateAlerting,
    hasPermissionToCreateAlerting,
  } = useRole();

  const [deleteAlert, { isLoading: isDeleting }] = useDeleteAlertMutation();

  const handleDeleteAlert = (alert: AlertClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this alert?`,
      icon: <ExclamationCircleOutlined />,
      content: `Name: ${alert.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return deleteAlert({ orgID: alert.alert_org_uuid, alertID: alert.UUID })
          .unwrap()
          .then(handleClose);
      },
    });
  };

  return (
    <div
      className='w-full fixed bottom-0 z-50 -mx-6 px-16 py-3 flex justify-between bg-white border-t 
    border-gray-300'
    >
      {alert && hasPermissionToDeleteAlerting ? (
        <Button
          loading={isDeleting}
          onClick={() => handleDeleteAlert(alert)}
          danger
          id='delete-alert-button'
        >
          Delete
        </Button>
      ) : (
        <span></span>
      )}

      <div className='text-right flex gap-2'>
        <Button onClick={handleClose}>Cancel</Button>

        {showUpdate && alert && hasPermissionToUpdateAlerting && (
          <Button
            loading={isLoading}
            type='primary'
            onClick={() => formInstance.submit()}
            id='update-alert-button'
          >
            Update
          </Button>
        )}

        {showSubmit && isCreation && hasPermissionToCreateAlerting && (
          <Button
            loading={isLoading}
            type='primary'
            onClick={() => formInstance.submit()}
            id='submit-alert-button'
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default AlertFooter;
