import { RootState } from '@/app/store';
import {
  ConditionInterface,
  FilterGroupType,
  FiltersGroupInterface,
} from '@/utils/models/Filter';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DashboardFiltersGroupsState {
  filtersGroups?: FiltersGroupInterface[];
}

const initialState: DashboardFiltersGroupsState = {};

const dashboardFiltersGroupsSlice = createSlice({
  name: 'dashboardFiltersGroups',
  initialState: initialState,
  reducers: {
    setDashboardFiltersGroups: (
      state,
      action: PayloadAction<FilterGroupType[]>
    ) => {
      const filtersToSubimit: FiltersGroupInterface[] = action.payload.map(
        (filter) => {
          const groups = filter?.groups?.map((condition) => {
            const field = condition.addonAfter
              ? condition.field.replace('*', condition.addonAfter)
              : condition.field;
            const group: ConditionInterface = {
              field,
              operator: condition.operator,
            };
            if (condition.value) {
              if (Array.isArray(condition.value)) {
                group.value = String(condition.value[0]);
              } else {
                group.value = condition.value;
              }
            }
            if (condition.values) group.values = condition.values;
            return group;
          });

          return {
            groups,
            operator: filter.operator,
          };
        }
      );
      state.filtersGroups = filtersToSubimit;
    },
  },
});

export const { setDashboardFiltersGroups } =
  dashboardFiltersGroupsSlice.actions;

export const selectDashboardFiltersGroups = (state: RootState) =>
  state.dashboardFiltersGroups.filtersGroups;

export default dashboardFiltersGroupsSlice.reducer;
