import { Alert, Button, Collapse, Form, Select } from 'antd';
import { useMemo, useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import { APIGATEWAYLOGGING, LOGGING_INTEGRATION_ID } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import {
  useLazyGetIntegrationAvailableQuery,
  useListMyIntegrationsQuery,
} from '@/utils/services/integration/integration-endpoints';
import Fields from '@/components/Form/FieldComponents';
import {
  useGetApiQuery,
  useLoggingApiMutation,
} from '@/utils/services/api/api-endpoints';
import useRole from '@/utils/hooks/useRole';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import IntegrationModal from '@/components/Integration/IntegrationModal';

const { Panel } = Collapse;

const Logging = () => {
  const { hasPermissionToUpdateApi } = useRole();
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const { data: api } = useGetApiQuery({
    orgID,
    apiID,
  });

  const [
    getIntegrationAvailable,
    { isLoading: getIntegrationAvailableIsLoading },
  ] = useLazyGetIntegrationAvailableQuery();
  const [showUpdateLoggingModal, setShowUpdateLoggingModal] =
    useState<boolean>(false);
  const [integrationAvailable, setIntegrationAvailable] =
    useState<IntegrationAvailableClass>();
  const [initialValuesFromLoggingModal, setInitialValuesFromLoggingModal] =
    useState<any>();

  const listMyLoggingIntegrationParams = {
    orgID,
    filters: [
      { field: 'integration_type', values: [APIGATEWAYLOGGING] },
      {
        field: 'region',
        values: api?.region_name ? [api.region_name] : [],
      },
    ],
  };

  const { data: loggingIntegrations, isLoading: filterIntegrationsIsLoading } =
    useListMyIntegrationsQuery(listMyLoggingIntegrationParams, {
      skip: showUpdateLoggingModal === false,
    });

  const [loggingApi, { isLoading: loggingApiIsLoading }] =
    useLoggingApiMutation();

  const stages: { name: string; data: any }[] = useMemo(() => {
    if (api?.ft_integration?.stages) {
      const keys = Object.keys(api?.ft_integration.stages);
      return keys.map((key) => ({
        name: key,
        data: api?.ft_integration?.stages[key],
      }));
    }
    return [];
  }, [api]);

  const openUpdateLoggingModal = async () => {
    setInitialValuesFromLoggingModal({});
    setShowUpdateLoggingModal(true);
  };

  const fillIntegrationValues =
    async (): Promise<IntegrationAvailableClass> => {
      const loggingIntegration = await getIntegrationAvailable(
        {
          orgID,
          integrationAvailableID: LOGGING_INTEGRATION_ID,
        },
        true
      ).unwrap();
      if (!loggingIntegration.fields) return loggingIntegration;
      const fields = structuredClone(loggingIntegration.fields);
      const indexRegion = fields.findIndex((f) => f.attribute === 'region');
      fields[indexRegion].value = api?.region_name;
      fields[indexRegion].disabled = true;
      const indexApp = fields.findIndex((f) => f.attribute === 'app_uuid');
      fields[indexApp].value = api?.api_appUUID;
      fields[indexApp].disabled = true;
      loggingIntegration.setFields(fields);
      return loggingIntegration;
    };

  const openCreateLoggingIntegrationModal = async () => {
    setIntegrationAvailable(await fillIntegrationValues());
    setShowUpdateLoggingModal(false);
  };

  const handleCloseModal = () => setShowUpdateLoggingModal(false);

  const handleUpdateLogging = async ({
    stage_name,
    integration_uuid,
  }: {
    stage_name: string;
    integration_uuid: string;
  }) => {
    await loggingApi({
      orgID: orgID,
      appID: appID,
      apiID: apiID,
      data: { integration_uuid, stage_name },
    }).unwrap();
    handleCloseModal();
  };

  const handleAddedIntegration = async (integrationID: string) => {
    setInitialValuesFromLoggingModal({
      integration_uuid: integrationID,
    });
    setShowUpdateLoggingModal(true);
  };

  const loggingIntegrationsFilteredByApp =
    loggingIntegrations?.integrations.filter(
      (i) => i?.inputs?.app_uuid === appID
    ) || [];

  return (
    <div>
      <IntegrationModal
        integrationAvailableManually={integrationAvailable}
        onClose={() => {
          setIntegrationAvailable(undefined);
          setShowUpdateLoggingModal(true);
        }}
        handleSubmit={handleAddedIntegration}
        params={listMyLoggingIntegrationParams}
      />

      <FormsModal
        title='Update Logging'
        confirmLoading={loggingApiIsLoading}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleUpdateLogging}
        showModal={showUpdateLoggingModal}
        initValues={initialValuesFromLoggingModal}
        fields={[
          <div className='flex' key='select_integration'>
            <Form.Item
              name='integration_uuid'
              label='Select an Integration'
              className='w-full'
              rules={[{ required: true, message: 'Integration is required' }]}
            >
              <Select loading={filterIntegrationsIsLoading}>
                {loggingIntegrationsFilteredByApp.map(
                  (item: any, index: number) => (
                    <Select.Option key={index.toString()} value={item.UUID}>
                      {item.name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item label=' ' className='ml-1'>
              <Button
                loading={getIntegrationAvailableIsLoading}
                onClick={openCreateLoggingIntegrationModal}
              >
                Create
              </Button>
            </Form.Item>
          </div>,
          <Form.Item
            key='stage_name'
            name='stage_name'
            label='Select a Stage'
            rules={[{ required: true, message: 'Stage is required' }]}
          >
            <Select>
              {stages.map((stage, index: number) => (
                <Select.Option key={index.toString()} value={stage.name}>
                  {stage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>,
        ]}
      />

      {api?.isMissingAccessLogging && (
        <Alert
          message='Select at least one stage to update the gateway logging api'
          type='warning'
          className='mb-4'
          showIcon
        />
      )}

      <div className='mb-2 flex justify-between items-center'>
        <h3 className='text-lg'>Stages</h3>
        {hasPermissionToUpdateApi && (
          <Button onClick={openUpdateLoggingModal} type='primary'>
            Update Logging
          </Button>
        )}
      </div>

      <Collapse defaultActiveKey={stages.map((stage) => stage.name)}>
        {stages.map((stage) => (
          <Panel
            header={<span className='font-medium'>{stage.name}</span>}
            key={stage.name}
          >
            <Fields.CodeBlock
              field={{
                attribute: stage.name,
                name: stage.name,
                language: 'json',
                inputType: 'code-block',
                value: JSON.stringify(stage.data),
              }}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Logging;
