import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Button, Input, Tooltip, Form, FormInstance, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { getFieldSize } from './const/functions';
import { useCopyToClipboard } from 'usehooks-ts';
import { useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import InputTextField from './InputTextField';
import { useAddTokenMutation } from '@/utils/services/token/token-endpoints';
import { useParams } from 'react-router-dom';
import useRole from '@/utils/hooks/useRole';

/* eslint-disable no-template-curly-in-string */
const CreateSecretToken = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  const { hasPermissionToCreateApplicationToken } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [, copy] = useCopyToClipboard();
  const [addToken, { isLoading: addTokenIsLoading }] = useAddTokenMutation();
  const [openCreateTokenModal, setOpenCreateTokenModal] =
    useState<boolean>(false);

  const rules: Rule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }

  const handleCopySecretToken = () => {
    formInstance
      .validateFields([field.attribute])
      .then((fields) => {
        copy(fields[field.attribute]);
        message.success('Secret Token Copied');
      })
      .catch(() => {});
  };

  const handleOpenCreateTokenModal = () => {
    const FIELDS = ['app_uuid', 'api_uuid'];
    formInstance
      .validateFields(FIELDS)
      .then(() => setOpenCreateTokenModal(true))
      .catch(() => {});
  };

  const onSubmitCreateToken = (values: { tokenName: string }) => {
    const appID = formInstance.getFieldValue('app_uuid');
    const apiID = formInstance.getFieldValue('api_uuid');
    addToken({
      orgID,
      appID,
      apiID,
      data: {
        name: values.tokenName.trim(),
      },
      forceSaveIn: 'app',
    })
      .unwrap()
      .then((token) => {
        formInstance.setFieldValue(field.attribute, token.token_secret);
        handleCloseCreateTokenModal();
      });
  };

  const handleCloseCreateTokenModal = () => setOpenCreateTokenModal(false);

  const tokenValueExist =
    formInstance.getFieldValue(field.attribute) !== undefined;

  if (hasPermissionToCreateApplicationToken) {
    return (
      <>
        <FormsModal
          title='Create Token'
          confirmLoading={addTokenIsLoading}
          handleCloseModal={handleCloseCreateTokenModal}
          handleSubmit={onSubmitCreateToken}
          showModal={openCreateTokenModal}
          fields={[
            <InputTextField
              key='token-name'
              field={{
                attribute: 'tokenName',
                name: 'Token Name',
                placeholder: 'my-token',
                inputType: 'text',
                validation: { required: true },
                fieldSize: 'extralarge',
                colSize: 'extralarge',
              }}
            />,
          ]}
        />

        <div
          className={`flex gap-1 ${getFieldSize(
            field.fieldSize || 'middle'
          )} relative`}
        >
          {field.disabled && (
            <Form.Item
              label={field.name}
              rules={rules}
              tooltip={field.description}
              className='w-full'
            >
              <Input value='<encrypted on server>' disabled />
            </Form.Item>
          )}

          {!field.disabled && (
            <Form.Item
              label={field.name}
              rules={rules}
              tooltip={field.description}
              name={field.attribute}
              className='w-full'
            >
              <Input.Password
                disabled={field.disabled}
                maxLength={field.validation?.max || 128}
                minLength={field.validation?.min || 0}
                className={`${tokenValueExist ? 'visible' : 'invisible'}`}
              />
            </Form.Item>
          )}

          {!field.disabled && (
            <Form.Item
              className={`${tokenValueExist ? 'visible' : 'invisible'}`}
              label=' '
            >
              <Tooltip title='Copy secret token'>
                <Button
                  onClick={handleCopySecretToken}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Form.Item>
          )}

          {!field.disabled && (
            <Form.Item
              className={`${!tokenValueExist && 'absolute'}`}
              label=' '
            >
              <Button onClick={handleOpenCreateTokenModal}>Create</Button>
            </Form.Item>
          )}
        </div>
      </>
    );
  }

  return <></>;
};

export default CreateSecretToken;
