import BaseEntityClass from './BaseEntity';

export interface NotificationHistoryInterface {
  UUID: string;
  dateAddedInMicroSeconds: number;
  error: boolean;
  g_orgUUID: string;
  history_orgUUID: string;
  notification_type: string;
  request: any;
  response: any;
  indexing_history_uuid?: string;
  indexName?: string;
  itemType: 'notificationHistory';
  notification_integrationUUID?: string;
  s3_bucket: string;
  users_notificationUUID: string;
}

export class NotificationHistoryClass
  extends BaseEntityClass
  implements NotificationHistoryInterface
{
  UUID: string;
  dateAddedInMicroSeconds: number;
  error: boolean;
  g_orgUUID: string;
  history_orgUUID: string;
  notification_type: string;
  request: any;
  response: any;
  users_notificationUUID: string;
  indexing_history_uuid?: string;
  indexName?: string;
  itemType: 'notificationHistory';
  notification_integrationUUID?: string;
  s3_bucket: string;

  constructor(data: NotificationHistoryInterface) {
    super(data);

    this.UUID = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.error = data.error;
    this.g_orgUUID = data.g_orgUUID;
    this.history_orgUUID = data.history_orgUUID;
    this.notification_type = data.notification_type;
    this.request = data.request;
    this.response = data.response;
    this.users_notificationUUID = data.users_notificationUUID;
    this.indexing_history_uuid = data.indexing_history_uuid;
    this.indexName = data.indexName;
    this.itemType = data.itemType;
    this.notification_integrationUUID = data.notification_integrationUUID;
    this.s3_bucket = data.s3_bucket;
  }
}
