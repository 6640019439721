import { ApiClass } from '@/utils/models/Api';
import { Tag } from 'antd';
import { BiSolidError } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

const ApiTagsGroup = ({ api }: { api: ApiClass }) => {
  const hasTagsGroup = api.tagsGroup.length > 0;
  const history = useHistory();

  return (
    <div className='flex flex-wrap gap-y-2'>
      {api.tagsGroup.map((tagGroup) => (
        <Tag
          key={tagGroup.key}
          color='red'
          className={`flex gap-1 items-center 
    ${hasTagsGroup ? 'visible cursor-pointer hover:scale-110 duration-150' : 'invisible'}`}
          onClick={(evt) => {
            if (hasTagsGroup) {
              evt.stopPropagation();
              history.push(
                `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}/requests?requests-which-include-tags=${tagGroup.tags.map((t) => t.key).join(',')}`
              );
            }
          }}
        >
          <BiSolidError />
          {tagGroup.display_name}
        </Tag>
      ))}
    </div>
  );
};

export default ApiTagsGroup;
