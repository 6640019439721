import { Modal, message, Alert, Button } from 'antd';
import { useCopyToClipboard } from 'usehooks-ts';
import { TokenClass } from '@/utils/models/Token';

const NewTokenModal = ({
  token,
  onOk,
}: {
  token?: TokenClass;
  onOk: () => void;
}) => {
  const [, copy] = useCopyToClipboard();
  const openModal = !!token;

  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      style={{ top: 150 }}
      title='New Token'
      open={openModal}
      onCancel={onOk}
      footer={[
        <Button key='onok' onClick={onOk} type='primary'>
          OK
        </Button>,
      ]}
      width={1000}
    >
      <div>
        <h3 className='truncate text-2xl font-bold mb-0'>{token?.name}</h3>
        <p className='text-xs mt-1'>{token?.created}</p>
        <div className='my-4'>
          <b>Token Secret</b>
          <div
            onClick={() => {
              copy(token?.token_secret || '');
              message.success('Secret Token Copied');
            }}
            style={{ cursor: 'pointer' }}
            className='flex'
          >
            <p className='font-medium pr-1'>{token?.token_secret}</p>
          </div>
        </div>
        <Alert
          message='Make sure to copy your personal access token now. You won’t be
      able to see it again!'
          type='warning'
          showIcon
        />
      </div>
    </Modal>
  );
};

export default NewTokenModal;
