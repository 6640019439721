// @ts-nocheck
import { useEffect } from 'react';

// HubSpot Embed Code
const script = document.createElement('script');
script.type = 'text/javascript';
script.id = 'hs-script-loader';
script.async = true;
script.defer = true;
script.src = '//js.hs-scripts.com/23167483.js';

interface IUseHubspot {
  init?: boolean;
}

function useHubspot(props?: IUseHubspot) {
  const addHubspotScript = () => {
    document.body.appendChild(script);
  };

  const removeHubspotScript = () => {
    document.body.removeChild(script);
  };

  const removeHubspotWidget = () => {
    if (!window?.HubSpotConversations) return;

    setTimeout(() => window?.HubSpotConversations.widget.remove(), 800);
  };

  const addHubspotWidget = () => {
    if (!window?.HubSpotConversations) return;

    const status = window.HubSpotConversations.widget.status();
    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }
  };

  const openHubspotChat = () => {
    if (!window?.HubSpotConversations) return;

    window.HubSpotConversations.widget.open();
  };

  useEffect(() => {
    if (props?.init) addHubspotScript();

    return () => {
      if (props?.init) removeHubspotScript();
    };
  }, [props?.init]);

  return {
    removeHubspotWidget,
    addHubspotWidget,
    openHubspotChat,
  };
}

export default useHubspot;
