import { DashboardOverviewDataType } from '@/utils/models/DashboardOverviewDataType';
import AggregateDataType from '@/utils/models/AggregateDataType';
import { FiltersGroupInterface } from '@/utils/models/Filter';
import HistographType, {
  DataPointsHistographType,
  LineChartType,
} from '@/utils/models/HistographType';
import { LogCountersDataType } from '@/utils/models/LogCountersDataType';
import { baseApi } from '../base-api';
import { DynamicDateTime } from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';
import { calcDateTime } from '../methods';
import { RequestTagInterface } from '@/utils/models/RequestTag';

export interface OrgGenericParams {
  orgID: string;
  supressErrors?: boolean;
}

export interface FilterDataParams extends OrgGenericParams {
  search_value?: string;
  field: string;
}

export interface OrgDashboardParams extends OrgGenericParams {
  dateTime: DynamicDateTime;
  filters: FiltersGroupInterface[];
  pageSize?: number;
  marker?: number;
}

export const orgEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRequestsHistograph: build.query<LineChartType, OrgDashboardParams>({
      query(data) {
        const { orgID, dateTime, filters } = data;
        const { start, end } = calcDateTime(dateTime);
        return {
          url: `/organisations/${orgID}/data/query?type=requests_histograph`,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'requests_histograph',
              filters,
            },
          },
        };
      },
      transformResponse: (rawResult: { graph: HistographType }) => {
        let dataPoints: DataPointsHistographType[] = [];
        rawResult.graph.data.forEach((app) => {
          dataPoints = [
            ...dataPoints,
            ...app.data.map((dataPoints) => ({
              date: dataPoints.date,
              value: dataPoints.value,
              app: app.name,
              uuid: app.UUID,
            })),
          ];
        });
        return { ...rawResult.graph, data: dataPoints };
      },
    }),
    getDashboardOverview: build.query<
      DashboardOverviewDataType,
      OrgDashboardParams
    >({
      query(data) {
        const { orgID, dateTime } = data;
        const { start, end } = calcDateTime(dateTime);
        return {
          url: `/organisations/${orgID}/data/query?type=dashboard_overview`,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'dashboard_overview',
              filters: [],
            },
          },
        };
      },
      transformResponse: (rawResult: { data: DashboardOverviewDataType }) =>
        rawResult.data,
    }),
    getRequestsLogCounters: build.query<
      LogCountersDataType[],
      OrgDashboardParams
    >({
      query(data) {
        const { orgID, dateTime, filters } = data;
        const { start, end } = calcDateTime(dateTime);
        return {
          url: `/organisations/${orgID}/data/query?type=log_counters`,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'log_counters',
              filters,
            },
          },
        };
      },
      transformResponse: (rawResult: { data: LogCountersDataType[] }) => {
        const counters: LogCountersDataType[] = [];
        const c1 = rawResult.data.find((c) => c.key === '2XX');
        if (c1 && c1.value > 0) counters.push(c1);
        const c2 = rawResult.data.find((c) => c.key === '4XX');
        if (c2 && c2.value > 0) counters.push(c2);
        const c3 = rawResult.data.find((c) => c.key === '5XX');
        if (c3 && c3.value > 0) counters.push(c3);
        const c4 = rawResult.data.find((c) => c.key === 'XXX');
        if (c4 && c4.value > 0) counters.push(c4);
        return counters;
      },
    }),
    getAggregateLatLonData: build.query<
      AggregateDataType[],
      OrgDashboardParams
    >({
      query(data) {
        const { orgID, dateTime, filters } = data;
        const { start, end } = calcDateTime(dateTime);
        return {
          url: `/organisations/${orgID}/data/query?type=aggregate_lat_lon_data`,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'aggregate_lat_lon_data',
              aggregate_size: 1000,
              filters,
            },
          },
        };
      },
      transformResponse: (rawResult: { data: AggregateDataType[] }) =>
        rawResult.data,
    }),
    getRequestTags: build.query<RequestTagInterface[], OrgGenericParams>({
      query: ({ orgID }) => `/organisations/${orgID}/tags`,
      transformResponse: (rawResult: {
        tags: RequestTagInterface[];
        groups: {
          display_name: string;
          key: string;
        }[];
      }) => {
        return rawResult.tags
          .sort((a, b) => a.colour.localeCompare(b.colour))
          .map((tag) => {
            const group = rawResult.groups.find((g) => g.key === tag.group);
            return {
              ...tag,
              group_display_name: group?.display_name,
              group: group?.key,
            };
          });
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAggregateLatLonDataQuery,
  useGetDashboardOverviewQuery,
  useGetRequestsLogCountersQuery,
  useGetRequestTagsQuery,
  useGetRequestsHistographQuery,
} = orgEndpoints;

export default orgEndpoints;
