const LIST_OF_DURATION_SELECTOR = [
  {
    key: 'last-hour',
    label: 'Last hour',
    info: 'Data loaded from the last hour',
  },
  {
    key: 'last-day',
    label: 'Last day',
    info: 'Data loaded from the last day',
  },
  {
    key: 'last-month',
    label: 'Last month',
    info: 'Data loaded from the last month',
  },
  {
    key: 'last-three-months',
    label: 'Last 3 months',
    info: 'Data loaded from the last 3 months',
  },
  {
    key: 'fixed',
    label: 'Custom',
    info: 'Data loaded from the custom dates',
  },
];

export default LIST_OF_DURATION_SELECTOR;
