const firetailLogoPath = require('@/assets/images/logo-dark.png');
const awsAppSyncLogo = require('@/assets/images/aws-appsync-logo.png');
const awsLambdaLogo = require('@/assets/images/aws-lambda.png');
const gitHubLogo = require('@/assets/images/github_repository.png');
const apiManagement = require('@/assets/images/api-management.png');
const appServiceApiApps = require('@/assets/images/app-service-api-apps.png');
const googleCloudLogo = require('@/assets/images/google-cloud.png');
const gcloudApiGatwayLogo = require('@/assets/images/gcloud-api-gateway-service.png');
const azureLogo = require('@/assets/images/azure.png');
const awsApiGatewayLogo = require('@/assets/images/aws-api-gateway.png');

const LIST_OF_IMAGES_PATHS: { name: string; path: string }[] = [
  {
    name: 'library',
    path: firetailLogoPath,
  },
  {
    name: 'aws',
    path: 'https://cdn.services.firetail.app/assets/images/integrations/amazon-web-services-48.png',
  },
  {
    name: 'aws_api_gateway',
    path: awsApiGatewayLogo,
  },
  {
    name: 'aws_lb_application',
    path: 'https://cdn.services.firetail.app/assets/images/integrations/aws-load-balancer.svg',
  },
  {
    name: 'aws_alb_log_request',
    path: 'https://cdn.services.firetail.app/assets/images/integrations/aws-load-balancer.svg',
  },
  {
    name: 'aws_lb_network',
    path: 'https://cdn.services.firetail.app/assets/images/integrations/aws-load-balancer.svg',
  },
  {
    name: 'aws_app_sync',
    path: awsAppSyncLogo,
  },
  {
    name: 'appsync_log_request',
    path: awsAppSyncLogo,
  },
  {
    name: 'aws_lambda',
    path: awsLambdaLogo,
  },
  {
    name: 'github_repository',
    path: gitHubLogo,
  },
  {
    name: 'github_action_upload',
    path: gitHubLogo,
  },
  {
    name: 'azure_api_management_service',
    path: apiManagement,
  },
  {
    name: 'azure_api_management_service_api',
    path: appServiceApiApps,
  },
  {
    name: 'azure_apim_log_request',
    path: appServiceApiApps,
  },
  {
    name: 'gcloud_api_gateway_service',
    path: gcloudApiGatwayLogo,
  },
  {
    name: 'gcp_apigw_log_request',
    path: googleCloudLogo,
  },
  {
    name: 'gcloud',
    path: googleCloudLogo,
  },
  {
    name: 'azure',
    path: azureLogo,
  },
  {
    name: 'wiz',
    path: 'https://cdn.services.firetail.app/assets/images/integrations/wiz.png',
  },
  {
    name: 'owasp',
    path: 'https://owasp.org/assets/images/logo.png',
  },
  {
    name: 'cwe',
    path: 'https://cdn.services.firetail.app/assets/images/frameworks/cwe-top-25.png',
  },
  {
    name: 'cis',
    path: 'https://cdn.services.firetail.app/assets/images/frameworks/cis.jpg',
  },
  {
    name: 'att-ck',
    path: 'https://cdn.services.firetail.app/assets/images/frameworks/mitre.png',
  },
];

export const getLogoPathByName = (name?: string): string => {
  if (name) {
    const logo = LIST_OF_IMAGES_PATHS.find(
      (logo) => logo.name.toLocaleLowerCase() === name.toLocaleLowerCase()
    );
    return logo?.path || firetailLogoPath;
  }

  return firetailLogoPath;
};
