import FieldType from '@/utils/models/FieldType';
import { Button, FormInstance } from 'antd';
import useFormsVariables, {
  StateVariableType,
} from '@/utils/hooks/useFormsVariables';

const ButtonField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  const {
    replaceValues,
    requiredAttributes: requiredAttributesFromStateVariables,
  } = useFormsVariables();

  const handleOpenBrowser = () => {
    if (typeof field.value === 'string') {
      let value = field.value;
      const regex = /\$*\{\w+}/g;
      const matchAll = value.matchAll(regex);
      const list = Array.from(matchAll).map((m: any) =>
        m[0].substring(2, m[0].length - 1)
      );
      const requiredAttributesFromFieldValue = Array.from(new Set(list));

      const requiredAttributes = new Set([
        ...requiredAttributesFromStateVariables,
        ...requiredAttributesFromFieldValue,
      ]);

      formInstance
        .validateFields(Array.from(requiredAttributes))
        .then((fields) => {
          // Replace Values from State Variables
          value = replaceValues(value);

          const formVariables: StateVariableType[] = Object.keys(fields).map(
            (attribute) => ({
              attribute,
              key: attribute,
              value: fields[attribute],
            })
          );
          // Replace Values from Field Value
          value = replaceValues(value, formVariables);

          window.open(
            value,
            '_blank',
            'location=yes,height=720,width=1020,scrollbars=yes,status=yes'
          );
        })
        .catch(() => {});
    }
  };

  return (
    <div className='w-full'>
      <Button onClick={handleOpenBrowser} className='mt-2'>
        {field.name}
      </Button>
      {field.description && (
        <div
          className='mb-2 mt-1'
          dangerouslySetInnerHTML={{ __html: field.description }}
        />
      )}
    </div>
  );
};

export default ButtonField;
