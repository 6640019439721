import { ReactNode } from 'react';

const ItemHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className='w-full flex-col justify-start items-start gap-2 flex'>
      {children}
    </div>
  );
};

export default ItemHeader;
