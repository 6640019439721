import { store } from '@/app/store';
import {
  ListSpecificationsParams,
  specificationEndpoints,
} from './specification-endpoints';
import { SpecificationClass } from '@/utils/models/Specification';
import {
  GetSpecificationVersionParams,
  versionEndpoints,
} from './version-endpoints';
import { SpecificationVersionClass } from '@/utils/models/SpecificationVersion';

export const listSpecifications = async (params: ListSpecificationsParams) => {
  const specificationsResult = await store.dispatch<any>(
    specificationEndpoints.endpoints.listSpecifications.initiate(params)
  );
  const data = specificationsResult.data as SpecificationClass[];
  return data;
};

export const getSpecification = async (
  orgID: string,
  specificationID: string,
  versionID?: string
) => {
  const specificationResult = await store.dispatch<any>(
    specificationEndpoints.endpoints.getSpecification.initiate({
      orgID,
      specificationID,
      versionID,
    })
  );
  const data = specificationResult.data as SpecificationClass;
  return data;
};

export const getSpecificationVersion = async (
  params: GetSpecificationVersionParams
) => {
  const versionResult = await store.dispatch<any>(
    versionEndpoints.endpoints.getSpecificationVersion.initiate(params)
  );
  const specificationVersion = versionResult.data as SpecificationVersionClass;
  return specificationVersion;
};
