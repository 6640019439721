const ButtonNavbar = ({
  label,
  onClick,
  buttonID,
}: {
  label: string;
  onClick: () => void;
  buttonID: string;
}) => (
  <button
    className='flex items-center text-first dark:text-anti-flash 
hover:text-second dark:hover:text-second
 focus:outline-none text-base whitespace-nowrap'
    onClick={onClick}
    id={buttonID}
  >
    {label}
    <svg
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      className='w-4 h-4 ml-1'
      viewBox='0 0 24 24'
    >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>
  </button>
);

export default ButtonNavbar;
