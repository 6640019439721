import {
  FindingClass,
  FindingInterface,
  FindingReportInterface,
  SeverityFindingType,
  SortType,
  StatusFindingType,
} from '@/utils/models/Finding';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { FieldsData } from '@/utils/models/FieldType';
import { listApis } from '../api/api-export-data';
import { SpecificationClass } from '@/utils/models/Specification';
import {
  FindingsMetricsData,
  FindingsMetricsInterface,
  FindingsOverviewInterface,
  ListFindingsPaginationResponse,
  formatFindingsMetricsData,
  formatOverviewResponse,
  getFindingApi,
  getMockCacheListing,
  getMockCacheOverview,
  handleSecurityFrameworksFilter,
} from './findings-methods';
import { getFindingMappings } from '../local-api-export-data';
import { getSpecification } from '../specification/specification-export-data';
import { StaticDateTime } from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';
import { calcDateTime } from '../methods';
import { DynamicDateTime } from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';

export interface ListFindingsParams extends OrgGenericParams {
  dateTime?: StaticDateTime;
  filters?: FieldsData[];
  page?: number;
  marker?: number;
  pageSize?: number;
  sort?: SortType;
}

export interface FindingsMetricsParams extends OrgGenericParams {
  dateTime: DynamicDateTime;
  filters: FieldsData[];
  filterByStatus?: StatusFindingType;
}

interface FindingParams extends OrgGenericParams {
  findingID: string;
}

interface UpdateStatusParams extends FindingParams {
  status: StatusFindingType;
  filters?: FieldsData[];
}

interface UpdateSeverityParams extends FindingParams {
  currentSeverity: SeverityFindingType;
  newSeverity: SeverityFindingType;
  filters?: FieldsData[];
  dateTime?: StaticDateTime;
}

export interface FindingsOverviewResponse {
  critical: number;
  high: number;
  information: number;
  low: number;
  medium: number;
}

interface FindingsOverviewParams extends OrgGenericParams {
  dateTime?: StaticDateTime;
  filters?: FieldsData[];
}

interface FindingsBulkParams extends OrgGenericParams {
  apiUUIDItems: string[];
}

export interface FindingsBulkResponse {
  [key: string]: {
    severities: Partial<FindingsOverviewResponse>;
  };
}

interface SubSection {
  title: string;
  description: string;
  link: string;
}

export interface ConvertedMetric {
  title: string;
  description: string;
  link: string;
  count: number;
  code: string;
  names: string[];
}

export const findingEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listFindings: build.query<
      ListFindingsPaginationResponse,
      ListFindingsParams
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orgID, dateTime, filters, sort, marker, pageSize } = args;
        sessionStorage.setItem('listFindingsArgs', JSON.stringify(args));

        const body: {
          data: { filters: FieldsData[] };
          start_date?: string | null;
          end_date?: string | null;
          sort?: SortType;
        } = { data: { filters: [] } };

        if (dateTime && 'mode' in dateTime) {
          const { start, end } = calcDateTime(dateTime);
          body.start_date = start;
          body.end_date = end;
        }

        if (filters) {
          body.data.filters = await handleSecurityFrameworksFilter(filters);
        }
        if (sort) body.sort = sort;

        const params: string[] = [];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);

        const searchResult = await fetchWithBQ({
          url: `/organisations/${orgID}/findings/search?${params.join('&')}`,
          method: 'POST',
          data: body,
        });
        const {
          findings,
          marker: nextMarker,
          total,
        } = searchResult.data as {
          findings: FindingInterface[];
          marker: number;
          total: number;
        };

        const mappingsResult = await getFindingMappings();
        const apisResult = await listApis({
          orgID,
          filters: [
            {
              field: 'apiUUID',
              values: [
                ...new Set(
                  findings.flatMap((finding) => {
                    return finding.resources
                      .filter((resource) => resource.startsWith('api:'))
                      .map((resource) => resource.slice(4));
                  })
                ),
              ],
            },
          ],
          pageSize,
        });

        return {
          data: {
            findings: findings.map((finding) => {
              const api = getFindingApi(finding, apisResult);
              return new FindingClass(finding, {
                mapping: mappingsResult
                  ? mappingsResult[finding.code]
                  : undefined,
                api,
              });
            }),
            marker: nextMarker,
            total,
          },
        };
      },
      providesTags: (result) => [{ type: 'Findings', id: 'LIST' }],
    }),
    getFinding: build.query<FindingClass, FindingParams>({
      async queryFn(
        { orgID, findingID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const findingResult = await fetchWithBQ(
          `/organisations/${orgID}/findings/${findingID}`
        );
        const { finding } = findingResult.data as {
          finding: FindingInterface;
        };

        let report: FindingReportInterface | undefined = undefined;
        if (
          finding.itemType === 'finding' ||
          finding.itemType === 'detection'
        ) {
          const reportResult = await fetchWithBQ(
            `/organisations/${orgID}/findings/${findingID}/report`
          );
          const reportData = reportResult.data as {
            report: FindingReportInterface;
          };
          report = reportData.report;
        }

        const mappingsResult = await getFindingMappings();

        const apisResult = await listApis({ orgID });
        const api = getFindingApi(finding, apisResult);

        let specification: SpecificationClass | undefined = undefined;
        if (finding.context.collection_uuid) {
          specification = await getSpecification(
            orgID,
            finding.context.collection_uuid,
            finding.context.collection_version_uuid
          );
        }

        return {
          data: new FindingClass(finding, {
            mapping: mappingsResult ? mappingsResult[finding.code] : undefined,
            report,
            api,
            specification,
          }),
        };
      },
      providesTags: (result, error, { findingID }) => [
        { type: 'Findings', id: findingID },
      ],
    }),
    updateFindingStatus: build.mutation<null, UpdateStatusParams>({
      query: ({ orgID, findingID, status }) => ({
        url: `/organisations/${orgID}/findings/${findingID}/status`,
        method: 'PUT',
        data: { status },
      }),
      async onQueryStarted(
        { status, findingID, filters },
        { dispatch, queryFulfilled }
      ) {
        queryFulfilled.then(() => {
          const listFindingsArgs = JSON.parse(
            sessionStorage.getItem('listFindingsArgs') || '{}'
          );

          dispatch(
            findingEndpoints.util.updateQueryData(
              'listFindings',
              listFindingsArgs,
              (draft) =>
                getMockCacheListing({
                  findingID,
                  dataType: 'status',
                  data: status,
                  draft,
                  filters,
                })
            )
          );
        });
      },
      invalidatesTags: (result, error, { findingID }) => [
        { type: 'Findings', id: findingID },
      ],
    }),
    updateFindingSeverity: build.mutation<null, UpdateSeverityParams>({
      query: ({ orgID, findingID, newSeverity }) => ({
        url: `/organisations/${orgID}/findings/${findingID}/severity`,
        method: 'PUT',
        data: { severity: newSeverity },
      }),
      async onQueryStarted(
        { currentSeverity, newSeverity, findingID, orgID, filters, dateTime },
        { dispatch, queryFulfilled }
      ) {
        queryFulfilled.then(() => {
          const listFindingsArgs = JSON.parse(
            sessionStorage.getItem('listFindingsArgs') || '{}'
          );

          dispatch(
            findingEndpoints.util.updateQueryData(
              'getFindingsOverview',
              { orgID, dateTime, filters },
              (draft) =>
                getMockCacheOverview({ draft, currentSeverity, newSeverity })
            )
          );

          dispatch(
            findingEndpoints.util.updateQueryData(
              'listFindings',
              listFindingsArgs,
              (draft) =>
                getMockCacheListing({
                  findingID,
                  dataType: 'severity',
                  data: newSeverity,
                  draft,
                  filters,
                })
            )
          );
        });
      },
      invalidatesTags: (result, error, { findingID }) => [
        { type: 'Findings', id: findingID },
      ],
    }),
    getFindingsBulk: build.query<FindingsBulkResponse, FindingsBulkParams>({
      query: ({ orgID, apiUUIDItems }) => {
        return {
          url: `/organisations/${orgID}/findings/count/bulk`,
          method: 'POST',
          data: { apiUUIDItems },
        };
      },
      transformResponse: (rawResult: { counts: FindingsBulkResponse }) =>
        rawResult.counts,
      providesTags: () => [{ type: 'Findings', id: 'LIST' }],
    }),
    getFindingsOverview: build.query<
      FindingsOverviewInterface,
      FindingsOverviewParams
    >({
      async queryFn(
        { orgID, filters, dateTime },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const body: {
          data: { filters: FieldsData[] };
          start_date?: string | null;
          end_date?: string | null;
        } = { data: { filters: [] } };

        if (dateTime && 'mode' in dateTime) {
          const { start, end } = calcDateTime(dateTime);
          body.start_date = start;
          body.end_date = end;
        }

        if (filters) {
          body.data.filters = await handleSecurityFrameworksFilter(filters);
        }

        const result = await fetchWithBQ({
          url: `/organisations/${orgID}/findings/count`,
          method: 'POST',
          data: body,
        });

        const { counts } = result.data as {
          counts: FindingsOverviewResponse;
        };

        return { data: formatOverviewResponse(counts) };
      },
      providesTags: () => [{ type: 'Findings', id: 'LIST' }],
    }),
    getFindingsMetrics: build.query<FindingsMetricsData, FindingsMetricsParams>(
      {
        async queryFn(
          { orgID, filters, dateTime, filterByStatus },
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) {
          const { start, end } = calcDateTime(dateTime);
          const url = `/organisations/${orgID}/data/query?type=finding_metrics`;

          const removeNotAllowedFields = filters.filter(
            (filter) => filter.field === 'appUUID' || filter.field === 'apiUUID'
          );

          const result = await fetchWithBQ({
            url,
            method: 'POST',
            data: {
              start_date: start,
              end_date: end,
              data: {
                type: 'finding_metrics',
                filters: removeNotAllowedFields,
              },
            },
          });

          const { data: metrics } = result.data as {
            data: FindingsMetricsInterface;
          };

          return { data: formatFindingsMetricsData(metrics, filterByStatus) };
        },
      }
    ),
    getFindingsOwaspMetrics: build.query<
      ConvertedMetric[],
      FindingsMetricsParams
    >({
      async queryFn(
        { orgID, filters, dateTime },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const { start, end } = calcDateTime(dateTime);
        const url = `/organisations/${orgID}/data/query`;

        const removeNotAllowedFields = filters.filter(
          (filter) => filter.field === 'appUUID' || filter.field === 'apiUUID'
        );

        const mappingsResult = await getFindingMappings();

        const result = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'finding_owasp',
              filters: removeNotAllowedFields,
            },
          },
        });

        const { data: metrics } = result.data as {
          data: { [key: string]: number };
        };

        const convertedMetrics = Object.keys(metrics).map((key: string) => {
          const [code, ...titleArray] = key.split(' ');
          const title = titleArray.join(' ');
          let description = '';
          let link = '';
          let names: string[] = [];

          Object.keys(mappingsResult).forEach((mappingKey) => {
            const entry = mappingsResult[mappingKey];
            entry.frameworks.forEach((framework) => {
              if (framework.sub_sections) {
                framework.sub_sections.forEach((subSection: SubSection) => {
                  if (subSection.title === key) {
                    description = subSection.description || '';
                    link = subSection.link || '';
                    if (!names.includes(mappingKey)) {
                      names.push(mappingKey);
                    }
                  }
                });
              }
            });
          });

          const metric: ConvertedMetric = {
            title,
            description,
            link,
            count: metrics[key],
            code,
            names,
          };

          return metric;
        });

        return { data: convertedMetrics };
      },
    }),
  }),
});

export const {
  useLazyGetFindingQuery,
  useUpdateFindingStatusMutation,
  useUpdateFindingSeverityMutation,
  useListFindingsQuery,
  useLazyListFindingsQuery,
  useGetFindingsOverviewQuery,
  useGetFindingsMetricsQuery,
  useGetFindingsOwaspMetricsQuery,
} = findingEndpoints;
