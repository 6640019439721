import { StatusFindingType } from '@/utils/models/Finding';
import LIST_OF_API_STATUS_FINDINGS from '@/utils/constants/listOfStatusFindings';
import { useParams } from 'react-router-dom';
import { useUpdateFindingStatusMutation } from '@/utils/services/finding/finding-endpoints';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import StatusTicketTag from '@/components/Tag/StatusTicketTag';
import DataDisplay from '@/components/DataDisplay';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import useRole from '@/utils/hooks/useRole';

const FindingsSelectStatus = ({
  status: currentStatus,
  findingID,
}: {
  status: StatusFindingType;
  findingID: string;
}) => {
  const { hasPermissionToUpdateFinding } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [updateStatus] = useUpdateFindingStatusMutation();

  const { filters } = useFiltersQueryParam();

  const handleUpdateStatus = async (statusToUpdate: StatusFindingType) => {
    await updateStatus({
      orgID,
      findingID,
      status: statusToUpdate,
      filters,
    });
  };

  const handleConfirmStatusUpdate = (statusToUpdate: StatusFindingType) => {
    Modal.confirm({
      title: 'Confirm status update',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className='pt-2'>
          <span className='text-gray-500'>From:</span>
          <StatusTicketTag status={currentStatus} />
          <br />
          <span className='text-gray-500 pt-2'>To:</span>
          <StatusTicketTag status={statusToUpdate} />
        </div>
      ),
      okText: 'Update',
      cancelText: 'Cancel',
      onOk: () => handleUpdateStatus(statusToUpdate),
    });
  };

  return (
    <DataDisplay.Dropdown
      trigger={['click']}
      menu={{
        items: LIST_OF_API_STATUS_FINDINGS.filter(
          (status) => status !== currentStatus
        ).map((status) => ({
          label: <StatusTicketTag status={status} />,
          key: status,
        })),
        onClick: (evt) => {
          evt.domEvent.stopPropagation();
          const { key: status } = evt;
          handleConfirmStatusUpdate(status as StatusFindingType);
        },
      }}
      buttonClassName='border border-zinc-200 px-2 py-1 rounded-sm min-w-[9rem]'
      disabled={!hasPermissionToUpdateFinding}
    >
      <StatusTicketTag status={currentStatus} />
    </DataDisplay.Dropdown>
  );
};

export default FindingsSelectStatus;
