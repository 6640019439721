import { Alert, Spin } from 'antd';
import { useGetAnomalyAlertPreviewQuery } from '@/utils/services/alerting/alerting-endpoints';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { AlertClass } from '@/utils/models/Alert';
import { selectDashboardFiltersGroups } from '@/components/Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import ShadedLineChart from '@/components/DataDisplay/ShadedLineChart';
import { forwardRef, useImperativeHandle } from 'react';
import AlertStaticChartPreview from './AlertStaticChartPreview';

interface AlertAnomalyChartPreviewProps {
  alert?: AlertClass;
  period: number;
  sensitivity?: number;
  alertStaticChartPreviewRef?: React.MutableRefObject<undefined>;
}

const AlertAnomalyChartPreview = forwardRef(
  (
    {
      alert,
      period,
      sensitivity,
      alertStaticChartPreviewRef,
    }: AlertAnomalyChartPreviewProps,
    ref
  ) => {
    const { orgID, alertID } = useParams<{ orgID: string; alertID?: string }>();
    const isEdition = !!alertID;
    const isCreation = !isEdition;

    const filtersGroups = useAppSelector(selectDashboardFiltersGroups);

    const metricName = alert?.config?.metric_name || 'log_count';
    const metricStat = alert?.config?.metric_stat || 'sum';

    const {
      currentData: lineChartData,
      isLoading,
      isFetching,
      refetch,
    } = useGetAnomalyAlertPreviewQuery(
      {
        orgID,
        alertID: alert?.UUID || '',
        filters: filtersGroups || [],
        period,
        sensitivity,
        metricName,
        metricStat,
      },
      { skip: sensitivity === undefined || alert === undefined }
    );

    const isEmpty =
      (lineChartData && lineChartData.data_points.length === 0) || false;

    useImperativeHandle(ref, () => ({
      refreshData() {
        refetch();

        if (lineChartData?.is_model_training) {
          // @ts-ignore
          alertStaticChartPreviewRef?.current?.refreshData();
        }
      },
    }));

    return (
      <Spin spinning={isLoading || isFetching}>
        {isCreation && (
          <Alert
            message='Anomaly graph preview will be available after the model has finished training on the dataset for the new alert.'
            type='warning'
            showIcon
          />
        )}

        {(lineChartData?.is_model_training || isEmpty) && (
          <div className='flex flex-col gap-2 mb-4'>
            {lineChartData?.is_model_training && (
              <Alert
                message='Anomaly graph preview will be available after the model has finished training on the dataset.'
                type='warning'
                showIcon
              />
            )}

            {isEmpty && (
              <Alert
                message='In order for the model to learn it requires logs, check that your logging integration or filters are set correctly.'
                type='warning'
                showIcon
              />
            )}
          </div>
        )}

        {lineChartData?.is_model_training && (
          <AlertStaticChartPreview
            period={period}
            ref={alertStaticChartPreviewRef}
            metricName={metricName}
            metricStat={metricStat}
          />
        )}

        {!isEmpty && lineChartData?.is_model_training === false && (
          <ShadedLineChart lineChartData={lineChartData} />
        )}
      </Spin>
    );
  }
);

export default AlertAnomalyChartPreview;
