import {
  AustraliaIcon,
  BahrainIcon,
  BrazilIcon,
  CanadaIcon,
  EnglandIcon,
  FranceIcon,
  GermanyIcon,
  HongkongIcon,
  IndiaIcon,
  IndonesiaIcon,
  IrelandIcon,
  IsraelIcon,
  ItalyIcon,
  JapanIcon,
  SingaporeIcon,
  SouthAfricaIcon,
  SouthKoreaIcon,
  SpainIcon,
  SwedenIcon,
  SwitzerlandIcon,
  UAEIcon,
  USAIcon,
} from '@/assets/images/flags';

export const getRegionFlagIcon = (
  region: string,
  classes: string = '',
  size?: number
) => {
  let icon;
  switch (region) {
    case 'us-east-1':
    case 'us-east-2':
    case 'us-west-1':
    case 'us-west-2':
      icon = <USAIcon size={size} />;
      break;
    case 'ca-central-1':
      icon = <CanadaIcon size={size} />;
      break;
    case 'eu-north-1':
      icon = <SwedenIcon size={size} />;
      break;
    case 'eu-west-1':
      icon = <IrelandIcon size={size} />;
      break;
    case 'eu-west-2':
      icon = <EnglandIcon size={size} />;
      break;
    case 'eu-west-3':
      icon = <FranceIcon size={size} />;
      break;
    case 'eu-central-1':
      icon = <GermanyIcon size={size} />;
      break;
    case 'eu-south-1':
      icon = <ItalyIcon size={size} />;
      break;
    case 'af-south-1':
      icon = <SouthAfricaIcon size={size} />;
      break;
    case 'ap-northeast-1':
      icon = <JapanIcon size={size} />;
      break;
    case 'ap-northeast-2':
      icon = <SouthKoreaIcon size={size} />;
      break;
    case 'ap-southeast-1':
      icon = <SingaporeIcon size={size} />;
      break;
    case 'ap-southeast-2':
    case 'ap-southeast-4':
      icon = <AustraliaIcon size={size} />;
      break;
    case 'ap-east-1':
      icon = <HongkongIcon size={size} />;
      break;
    case 'ap-south-1':
    case 'ap-south-2':
      icon = <IndiaIcon size={size} />;
      break;
    case 'me-south-1':
      icon = <BahrainIcon size={size} />;
      break;
    case 'sa-east-1':
      icon = <BrazilIcon size={size} />;
      break;
    case 'eu-central-2':
      icon = <SwitzerlandIcon size={size} />;
      break;
    case 'il-central-1':
      icon = <IsraelIcon size={size} />;
      break;
    case 'eu-south-2':
      icon = <SpainIcon size={size} />;
      break;
    case 'ap-southeast-3':
      icon = <IndonesiaIcon size={size} />;
      break;
    case 'me-central-1':
      icon = <UAEIcon size={size} />;
      break;
  }
  if (icon) return <div className={classes}>{icon}</div>;
  return <></>;
};
