import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import { returnToKey } from './hooks/useAuth';
import { STORAGE_KEY_PREFIX } from '@/utils/constants';

const FireTailAuth = ({ children }: { children: React.ReactNode }) => {
  const handlePostLogin = () => {
    const returnTo = sessionStorage.getItem(returnToKey);
    if (returnTo) {
      sessionStorage.removeItem(returnToKey);
      window.location.assign(decodeURI(returnTo));
    }
  };

  const handlePostLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const authConfig: TAuthConfig = {
    clientId: window.appConfig.REACT_APP_CLIENT_ID,
    authorizationEndpoint: window.appConfig.REACT_APP_AUTHORIZATION_ENDPOINT,
    tokenEndpoint: window.appConfig.REACT_APP_TOKEN_ENDPOINT,
    redirectUri: window.location.origin,
    scope: window.appConfig.REACT_APP_SCOPE,
    storage: 'local',
    extraAuthParameters: {
      audience: window.appConfig.REACT_APP_AUDIENCE,
      response_mode: 'query',
    },
    logoutEndpoint: window.appConfig.REACT_APP_LOGOUT_ENDPOINT,
    autoLogin: false,
    storageKeyPrefix: STORAGE_KEY_PREFIX,
    postLogin: handlePostLogin,
    refreshTokenExpiresIn: window.appConfig.REACT_APP_REFRESH_TOKEN_EXPIRES_IN,
    refreshTokenExpiryStrategy: 'absolute',
    postLogout: handlePostLogout,
  };

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>;
};

export default FireTailAuth;
