import { Tooltip } from 'antd';
import { ReactNode } from 'react';

const ItemFooter = ({
  children,
  onClick,
  style,
  tooltipTitle,
  justifyContent = 'space-between',
}: {
  children: ReactNode;
  onClick?: () => void;
  style?: any;
  tooltipTitle?: string;
  justifyContent?: 'space-between' | 'start' | 'end';
}) => {
  let component = (
    <div
      className='flex items-end gap-8 w-full'
      style={{ justifyContent, ...style }}
    >
      {children}
    </div>
  );

  if (onClick) {
    component = (
      <div
        className='hover:border-gray-200 p-1 -m-1 rounded-sm cursor-pointer
transition-all border border-transparent w-full'
        onClick={(evt) => {
          evt.stopPropagation();
          onClick();
        }}
      >
        {component}
      </div>
    );
  }

  if (tooltipTitle) {
    component = <Tooltip title={tooltipTitle}>{component}</Tooltip>;
  }

  return component;
};

export default ItemFooter;
