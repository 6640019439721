import {
  OrganisationClass,
  OrganisationInterface,
} from '@/utils/models/Organisation';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { RoleInterface } from '@/utils/models/Role';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';

interface SearchParams {
  query?: string;
}

interface AddOrganisationParams {
  data: { name: string };
}

export const organisationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listOrganisations: build.query<OrganisationClass[], SearchParams | void>({
      async queryFn(param, _queryApi, _extraOptions, fetchWithBQ) {
        let result: OrganisationInterface[];
        if (param?.query) {
          const searchResult = await fetchWithBQ({
            url: `/organisations/search`,
            method: 'POST',
            data: { search_value: param.query },
          });
          const { organisations } = searchResult.data as {
            organisations: OrganisationInterface[];
          };
          result = organisations;
        } else {
          const listResult = await fetchWithBQ(`/organisations`);
          const { organisations } = listResult.data as {
            organisations: OrganisationInterface[];
          };
          result = organisations;
        }
        return {
          data: result
            .sort(
              (orgA, orgB) =>
                Number(orgB.dateAddedInMicroSeconds) -
                Number(orgA.dateAddedInMicroSeconds)
            )
            .map((org) => new OrganisationClass(org)),
        };
      },
      providesTags: ['Organisations'],
    }),
    getOrganisation: build.query<OrganisationClass, OrgGenericParams>({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        const organisationResult = await fetchWithBQ(`/organisations/${orgID}`);
        if (organisationResult.error) {
          return { error: formatRejected(organisationResult.error) };
        }

        const roleResult = await fetchWithBQ(`/organisations/${orgID}/role`);
        if (roleResult.error) {
          return { error: formatRejected(roleResult.error) };
        }

        const { organisation } = organisationResult.data as {
          organisation: OrganisationInterface;
        };
        const role = roleResult.data as RoleInterface;

        return {
          data: new OrganisationClass(organisation, { role }),
        };
      },
      providesTags: ['Organisations'],
    }),
    addOrganisation: build.mutation<
      OrganisationInterface,
      AddOrganisationParams
    >({
      query: ({ data }) => ({
        url: `/organisations`,
        method: 'POST',
        data: { ...data, region_name: 'eu-west-1' },
      }),
      transformResponse: (rawResult: { organisation: OrganisationInterface }) =>
        new OrganisationClass(rawResult.organisation),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            organisationEndpoints.util.invalidateTags(['Organisations'])
          );

          setTimeout(() => {
            dispatch(organisationEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteOrganisation: build.mutation<{ success: boolean }, OrgGenericParams>({
      query({ orgID }) {
        return {
          url: `/organisations/${orgID}`,
          method: 'DELETE',
        };
      },
      transformResponse: () => ({ success: true }),
      transformErrorResponse: () => ({ success: false }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            organisationEndpoints.util.invalidateTags(['Organisations'])
          );

          setTimeout(() => {
            dispatch(organisationEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    getRole: build.query({
      query: ({ orgID }) => `/organisations/${orgID}/role`,
    }),
  }),
});

function formatRejected(errorResponse: any) {
  const err = {
    title: 'Bad Request',
    content: errorResponse?.data?.message,
    status: 'error',
    isPendingAwsActivation: false,
  };

  if (errorResponse?.status === 401 || errorResponse?.status === 403) {
    err.title = 'Access Denied';
    err.content = 'Sorry, you are not authorized to access this page.';
    err.status = '403';
  }

  if (errorResponse?.data?.code === 'pending_aws_activation') {
    err.title = 'Pending activation from AWS Marketplace';
    err.status = '403';
    err.isPendingAwsActivation = true;
  }

  return err;
}

export const {
  useListOrganisationsQuery,
  useLazyListOrganisationsQuery,
  useGetOrganisationQuery,
  useLazyGetOrganisationQuery,
  useAddOrganisationMutation,
  useDeleteOrganisationMutation,
  useGetRoleQuery,
} = organisationEndpoints;

export default organisationEndpoints;
