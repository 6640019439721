import { Tooltip } from 'antd';
import ItemSpan from './ItemSpan';

const ItemLogoAndName = ({
  logo,
  name,
  tooltipTitle,
}: {
  logo?: string;
  name?: string;
  tooltipTitle?: string;
}) => {
  let component = (
    <div className='flex items-center gap-x-2 shrink'>
      <img className='h-5 opacity-90' src={logo} alt={name} />
      <ItemSpan text={name} />
    </div>
  );

  if (tooltipTitle) {
    component = <Tooltip title={tooltipTitle}>{component}</Tooltip>;
  }

  return component;
};
export default ItemLogoAndName;
