import GenericDateTimePicker from '@/components/Generic/GenericDateTimePicker';
import useDynamicDateTimeQueryParam from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import { useEffect } from 'react';

interface DashboardFiltersSelectDateTimeProps {
  dark?: boolean;
}

const DashboardFiltersSelectDateTime = ({
  dark = false,
}: DashboardFiltersSelectDateTimeProps) => {
  const { dateTime } = useDynamicDateTimeQueryParam();
  const { setPagination } = usePaginationQueryParam();

  useEffect(() => {
    if (!dateTime) return;

    // Reset pagination when dateTime changes
    setPagination();
  }, [dateTime, setPagination]);

  return <GenericDateTimePicker dark={dark} />;
};

export default DashboardFiltersSelectDateTime;
