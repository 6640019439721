import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import LIST_OF_HTTP_STATUS_CODE from '@/utils/constants/listOfHttpStatusCode';
import { counterFormat } from '@/utils/methods/displayFormat';
import { DailyMetricsContextClass } from '@/utils/models/DailyMetrics';
import { MdOutlineHttp } from 'react-icons/md';

const DailyMetricsHistogramStatusCode = ({
  dailyMetrics,
}: {
  dailyMetrics: DailyMetricsContextClass;
}) => {
  return (
    <>
      {dailyMetrics.hasHistogramStatusCode && (
        <div>
          <div className='flex gap-2 items-center'>
            <MdOutlineHttp size={32} />

            <h4 className='text-xl font-semibold'>Status Code Distribution:</h4>
          </div>

          <p>
            The HTTP status code distribution provides insights into the
            outcomes of requests made to your web service.
          </p>

          <div
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 
          2xl:grid-cols-4 gap-3 my-3'
          >
            {dailyMetrics.histogramStatusCode.buckets
              .filter((status) => status.doc_count)
              .map((status, index) => {
                const httpStatusCode = LIST_OF_HTTP_STATUS_CODE.find(
                  (statusCode) => +statusCode.code === status.key
                );

                return (
                  <div key={index.toString()}>
                    {httpStatusCode ? (
                      <div className='flex items-center'>
                        <StatusCodeTag code={httpStatusCode.code} showTitle />
                        <div className='flex gap-1 text-lg'>
                          <span>:</span>
                          <span>
                            {counterFormat(status.doc_count)} Requests
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default DailyMetricsHistogramStatusCode;
