import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { UrlUpdateType } from 'use-query-params';
import { useDebounceValue } from 'usehooks-ts';

interface ListCustomSearchProps {
  placeholder: string;
  debounceTime?: number;
  updateType?: UrlUpdateType;
}

export default function ListCustomSearch({
  placeholder,
  debounceTime = 400,
  updateType,
}: ListCustomSearchProps) {
  const { query, setSearch } = useSearchQueryParam({ updateType });
  const [value, setValue] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useDebounceValue(
    '',
    debounceTime
  );

  useEffect(() => {
    // Skip the first render
    if (debouncedValue === '') return;

    setSearch(debouncedValue);
  }, [debouncedValue, setSearch]);

  // Initialize the value with the query from the URL
  useEffect(() => setValue(query), [query]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setDebouncedValue(inputValue);
    setValue(inputValue);

    // Reset search if the input is empty
    if (inputValue === '') setSearch(undefined);
  };

  return (
    <Input
      placeholder={placeholder}
      allowClear
      prefix={<MdSearch />}
      value={value}
      onChange={handleOnChange}
      className='col-span-6'
    />
  );
}
