import { useEffect, useState } from 'react';
import OrganisationStorage from '@/storage/OrganisationStorage';
import { useHistory } from 'react-router-dom';
import { useLazyGetUserQuery } from '@/utils/services/user/user-endpoints';
import OrganisationsList from '@/components/Organisations/OrganisationsList';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';
import withAuthorizedRequired from '@/utils/lib/firetail-auth/wrappers/withAuthorizedRequired';

const Organisations = () => {
  const [getUser] = useLazyGetUserQuery();
  const [showOrganisations, setShowOrganisations] = useState<boolean>(false);
  const history = useHistory();
  const { user: profile } = useAuth();

  useEffect(() => {
    const localStorageKey = `timer-${profile?.email}`;
    const savedTime = localStorage.getItem(localStorageKey);
    if (savedTime) {
      localStorage.removeItem(localStorageKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleAWSMarketplaceFlowPending = async () => {
      const user = await getUser(undefined, true).unwrap();
      if (user.isAWSMarketplaceFlowPending)
        history.push('/signup-from-aws-marketplace');
    };

    const handleOrganisationSelected = () => {
      const organisation = OrganisationStorage.get();
      if (organisation)
        history.push(`/organisations/${organisation.g_orgUUID}`);
    };

    handleAWSMarketplaceFlowPending();
    handleOrganisationSelected();
    setShowOrganisations(true);
  }, [getUser, history]);

  if (showOrganisations) return <OrganisationsList />;

  return <FullScreenLoader />;
};

const PageWithAuthorizedRequired = withAuthorizedRequired(Organisations);

export default withAuthenticationRequired(PageWithAuthorizedRequired);
