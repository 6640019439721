import { RootState } from '@/app/store';
import OrganisationStorage, { SummaryOrg } from '@/storage/OrganisationStorage';
import { OrganisationInterface } from '@/utils/models/Organisation';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NavbarTabItem = {
  key: string;
  label: string;
  redirectTo?: string;
};

interface NavbarState {
  selectedOrgs: SummaryOrg[];
  currentOrg?: OrganisationInterface;
  tabItems: NavbarTabItem[];
  activeTabKey?: string;
}

const initialState: NavbarState = {
  selectedOrgs: [],
  tabItems: [],
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: initialState,
  reducers: {
    setOrganisation: (state, action: PayloadAction<OrganisationInterface>) => {
      /* Current organisations */
      const organisation = action.payload;
      OrganisationStorage.update = organisation;
      state.currentOrg = organisation;

      /* List with selected organisations */
      const selectedOrgs = OrganisationStorage.getSelectedOrgs();
      let previousOrgs: SummaryOrg[] = [];
      if (selectedOrgs.length > 0) {
        const existInSelectedOrgs = selectedOrgs.find(
          (org) => org.UUID === organisation.UUID
        );

        if (!existInSelectedOrgs) {
          previousOrgs = [organisation, ...selectedOrgs];
        } else {
          const index = selectedOrgs.indexOf(existInSelectedOrgs);
          selectedOrgs.splice(index, 1);
          previousOrgs = [organisation, ...selectedOrgs];
        }

        if (previousOrgs.length > 3) {
          previousOrgs = [
            previousOrgs[0],
            previousOrgs[1],
            previousOrgs[2],
            previousOrgs[3],
          ];
        }
      } else {
        previousOrgs.push(organisation);
      }
      const orgs: SummaryOrg[] = previousOrgs.map((org) => ({
        UUID: org.UUID,
        name: org.name,
      }));
      OrganisationStorage.updateSelectedOrgs = orgs;
      state.selectedOrgs = orgs;
    },
    clearCurrentOrganisation: (state) => {
      state.currentOrg = undefined;
      OrganisationStorage.clear();
    },
    deletedOrganisation: (state, action: PayloadAction<string>) => {
      /* Current organisations */
      state.currentOrg = undefined;
      OrganisationStorage.clear();

      /* List with selected organisations */
      const selectedOrgs = OrganisationStorage.getSelectedOrgs();
      const org = selectedOrgs.find((org) => org.UUID === action.payload);
      if (org) {
        const index = selectedOrgs.indexOf(org);
        selectedOrgs.splice(index, 1);
        OrganisationStorage.updateSelectedOrgs = selectedOrgs;
        state.selectedOrgs = selectedOrgs;
      }
    },
    setTabNavbarItems: (state, action: PayloadAction<NavbarTabItem[]>) => {
      state.tabItems = action.payload;
    },
    setActiveTabKey: (state, action: PayloadAction<string | undefined>) => {
      state.activeTabKey = action.payload;
    },
  },
});

export const {
  setOrganisation,
  clearCurrentOrganisation,
  deletedOrganisation,
  setTabNavbarItems,
  setActiveTabKey,
} = navbarSlice.actions;

export const selectSelectedOrgsNavbar = (state: RootState) =>
  state.navbar.selectedOrgs;

export const selectCurrentOrgNavbar = (state: RootState) =>
  state.navbar.currentOrg;

export const selectTabItemsNavbar = (state: RootState) => state.navbar.tabItems;

export const selectActiveTabNavbar = (state: RootState) =>
  state.navbar.activeTabKey;

export default navbarSlice.reducer;
