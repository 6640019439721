import { store } from '@/app/store';
import { integrationEndpoints } from './integration-endpoints';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import { IntegrationClass } from '@/utils/models/Integration';

export const listIntegrationsAvailable = async (orgID: string) => {
  const response = await store.dispatch<any>(
    integrationEndpoints.endpoints.listIntegrationsAvailable.initiate({
      orgID,
    })
  );
  const integrationsAvailable = response.data as IntegrationAvailableClass[];
  return integrationsAvailable;
};

export const getIntegrationAvailable = async ({
  orgID,
  integrationAvailableID,
}: {
  orgID: string;
  integrationAvailableID: string;
}) => {
  const response = await store.dispatch<any>(
    integrationEndpoints.endpoints.getIntegrationAvailable.initiate({
      orgID,
      integrationAvailableID,
    })
  );
  const integrationAvailable = response.data as IntegrationAvailableClass;
  return integrationAvailable;
};

export const getMyIntegration = async ({
  orgID,
  myIntegrationID,
}: {
  orgID: string;
  myIntegrationID: string;
}) => {
  const response = await store.dispatch<any>(
    integrationEndpoints.endpoints.getMyIntegration.initiate({
      orgID,
      myIntegrationID,
    })
  );
  const myIntegration = response.data as IntegrationClass;
  return myIntegration;
};

export const getMyIntegrations = async ({
  orgID,
  myIntegrationIDs,
}: {
  orgID: string;
  myIntegrationIDs: string[];
}) => {
  const promises = myIntegrationIDs.map((myIntegrationID) =>
    getMyIntegration({ orgID, myIntegrationID })
  );

  const myIntegrations = await Promise.all(promises);

  return myIntegrations.filter(
    (myIntegration) => myIntegration !== undefined
  ) as IntegrationClass[];
};
