import { counterFormat } from '@/utils/methods/displayFormat';
import { FindingClass } from '@/utils/models/Finding';

const FindingInspectorAlertMessage = ({
  finding,
  noWrap = false,
}: {
  finding?: FindingClass;
  noWrap?: boolean;
}) => {
  const report = finding?.report;
  const mapping = finding?.mapping;

  if (!report && !mapping) return <></>;

  const getCustomDescription = (): string => {
    if (finding.itemType !== 'detection') {
      return report?.message || report?.description || '';
    }

    if (!report || !mapping) return '';

    const { title: finding_title } = mapping;

    // Split the title into words
    const words_title = finding_title.split(' ');
    // Remove the last word
    const average_behavior: 'elevated' | 'reduced' = words_title
      .pop()
      ?.toLocaleLowerCase() as any;
    // Join the remaining words and lowercase them
    const context_data = words_title.join(' ').toLowerCase();
    // Verify the unit system
    const unit = context_data.includes('time') ? 'ms' : 'bytes';

    const { current_mean_average, previous_mean_average, previous_variance } =
      report;

    const { distance_in_standard_deviations } = finding.context || {};

    const phrase1 = `There has been ${
      average_behavior === 'elevated' ? 'an increase' : 'a reduction'
    } in the ${context_data}. `;

    let phrase2 = '';
    if (previous_mean_average)
      phrase2 = `Previously, the average size was '${counterFormat(previous_mean_average)} ${unit}'. `;

    let phrase3 = '';
    if (previous_variance)
      phrase3 = `The variance of the previous data was '${counterFormat(previous_variance)} ${unit}²'. `;

    let phrase4 = '';
    if (current_mean_average)
      phrase4 = `The current average size has ${average_behavior} to '${counterFormat(current_mean_average)} ${unit}'. `;

    let phrase5 = '';
    if (
      typeof distance_in_standard_deviations === 'number' &&
      distance_in_standard_deviations
    )
      phrase5 = `This change corresponds to a shift of '${counterFormat(distance_in_standard_deviations)}' 
      standard deviations from the previous mean, indicating a ${
        average_behavior === 'elevated'
          ? 'significant elevation'
          : 'notable reduction'
      }.`;

    return phrase1 + phrase2 + phrase3 + phrase4 + phrase5;
  };

  const card = (
    <div
      className={`relative border-b ${
        noWrap ? '' : 'border-t border-gray-300 shadow-sm'
      } 
      bg-white`}
    >
      <div className='flex flex-col gap-4 px-10 py-6'>
        {(report?.message || report?.description) && (
          <div className='flex flex-col gap-1'>
            <h6 className='font-semibold'>Description</h6>
            <CustomParagraphComponent
              color={finding?.severityColor}
              text={getCustomDescription()}
            />
          </div>
        )}

        {mapping?.remediation && (
          <div className='flex flex-col gap-1'>
            <h6 className='font-semibold'>Remediation</h6>
            <CustomParagraphComponent
              color={finding?.severityColor}
              text={mapping?.remediation}
            />
          </div>
        )}
      </div>

      {!noWrap && (
        <div
          className='absolute h-full w-1 bottom-0'
          style={{ backgroundColor: finding?.severityColor }}
        />
      )}
    </div>
  );

  if (noWrap) return card;

  return (
    <div className='pl-8 pr-0 pb-4 bg-[#FAFAFA] relative border-b border-gray-300'>
      {card}
    </div>
  );
};

const CustomParagraphComponent = ({
  text,
  color = '#DC2625',
}: {
  text?: string;
  color?: string;
}) => {
  if (!text) return <></>;

  try {
    const regex = /[`|'].*?[`|']/g;
    const matchAll = text.matchAll(regex);

    const words = Array.from(matchAll).map((m: any) =>
      m[0].substring(1, m[0].length - 1)
    );

    let paragraph = text;

    function getSpan(str: string) {
      return `<span style="color: ${color}; font-weight: 600;">${str}</span>`;
    }

    for (const word of words) {
      paragraph = paragraph.replace(
        new RegExp(`'` + word + `'`, 'g'),
        getSpan(word)
      );

      paragraph = paragraph.replace(
        new RegExp('`' + word + '`', 'g'),
        getSpan(word)
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: paragraph }} />;
  } catch (err) {
    return <p>{text}</p>;
  }
};

export default FindingInspectorAlertMessage;
