import { store } from '@/app/store';
import { EventClass } from '@/utils/models/Event';
import {
  EventParams,
  ListEventsResponse,
  eventEndpoints,
} from './event-endpoints';

export const listEvents = async ({ orgID }: { orgID: string }) => {
  const eventsResult = await store.dispatch<any>(
    eventEndpoints.endpoints.listEvents.initiate({ orgID })
  );

  const data = eventsResult.data as ListEventsResponse;

  return data.events;
};

export const getEventByUUID = async ({
  orgID,
  eventID,
  supressErrors,
}: EventParams) => {
  const eventResult = await store.dispatch<any>(
    eventEndpoints.endpoints.getEvent.initiate({
      orgID,
      eventID,
      supressErrors,
    })
  );
  const event = eventResult.data as EventClass;
  return event;
};

export const getEventsByUUIDs = async ({
  orgID,
  eventsIDs,
  supressErrors,
}: {
  orgID: string;
  eventsIDs: string[];
  supressErrors?: boolean;
}) => {
  const promises = eventsIDs.map((eventID) =>
    getEventByUUID({ orgID, eventID, supressErrors })
  );
  const events = await Promise.all(promises);
  return events.filter((event) => event !== undefined) as EventClass[];
};
