import moment from 'moment';
import { RequestIPDetails } from './Request';

export interface SequenceInterface {
  UUID: string;
  order: number;
  method: string;
  path: string;
  dateCreatedInDatetime: string;
  dateCreated: number;
  statusCode: number;
  avatar: string;
  typeLabel: string;
  tags: string[];
  domain: string;
  diffFromPreviousRequest: number;
  ip: string;
  ipDetails: RequestIPDetails;
}

export class SequenceClass implements SequenceInterface {
  UUID: string;
  order: number;
  method: string;
  path: string;
  dateCreatedInDatetime: string;
  dateCreated: number;
  statusCode: number;
  avatar: string;
  typeLabel: string;
  tags: string[];
  domain: string;
  diffFromPreviousRequest: number;
  ip: string;
  ipDetails: RequestIPDetails;

  key?: string;

  constructor(data: SequenceInterface) {
    this.key = data.UUID;
    this.UUID = data.UUID;
    this.order = data.order;
    this.method = data.method;
    this.path = data.path;
    this.dateCreatedInDatetime = data.dateCreatedInDatetime;
    this.dateCreated = data.dateCreated;
    this.statusCode = data.statusCode;
    this.avatar = data.avatar;
    this.typeLabel = data.typeLabel;
    this.domain = data.domain;
    this.tags = data.tags;
    this.diffFromPreviousRequest = data.diffFromPreviousRequest;
    this.ip = data.ip;
    this.ipDetails = data.ipDetails;
  }

  get created(): string {
    return moment.utc(this.dateCreated).local().format('D/MM/Y hh:mm:ss.SS A');
  }
}
