import { DefaultOptionType } from 'antd/lib/select';
import { isValidElement } from 'react';

export const handleFilterOptions = (
  input: string,
  option?: DefaultOptionType
) => {
  if (!option) return false;

  let value: string | number | null | undefined;
  if (isValidElement(option.label)) {
    value = option.label.props?.name || option.value;
  } else if (
    typeof option.label === 'string' ||
    typeof option.label === 'number'
  ) {
    value = option.label;
  } else {
    value = option.value;
  }

  value = String(value);

  return value.toLowerCase().includes(input.toLowerCase());
};
