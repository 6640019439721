import DashboardFilters from './DashboardFilters';
import DashboardFindingsOverview from './DashboardFindingsOverview';
import DashboardRequestsLineChart from './DashboardRequestsLineChart';
import DashboardOverview from './DashboardOverview';
import DashboardRequestsTable from './DashboardRequestsTable';
import DashboardRequestsDonutChart from './DashboardRequestsDonutChart';
import DashboardFindingsMetricsLineChart from './DashboardFindingsMetricsLineChart';
import DashboardRequestsMapChart from './DashboardRequestsMapChart';
import DashboardRequestDetailsRoot from './DashboardRequestDetails/DashboardRequestDetailsRoot';

const Dashboard = {
  Filters: DashboardFilters,
  Overview: DashboardOverview,
  RequestsLineChart: DashboardRequestsLineChart,
  RequestsDonutChart: DashboardRequestsDonutChart,
  RequestsMapChart: DashboardRequestsMapChart,
  RequestsTable: DashboardRequestsTable,
  RequestsDetailsDrawer: DashboardRequestDetailsRoot,
  FindingsOverview: DashboardFindingsOverview,
  FindingsMetricsLineChart: DashboardFindingsMetricsLineChart,
};

export default Dashboard;
