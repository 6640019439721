import Specification from '@/components/Specification';
import { EventContextClass } from '@/utils/models/EventContext';
import { SpecificationClass } from '@/utils/models/Specification';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const SpecificationEventContext = ({
  context,
}: {
  context: EventContextClass;
}) => {
  const history = useHistory();

  const extraButtons = (specification: SpecificationClass) => {
    return [
      <Button
        key='specification'
        onClick={() => {
          history.push(
            `/organisations/${specification.collection_orgUUID}/applications/${specification.collection_appUUID}/apis/${specification.collection_apiUUID}/specifications/${specification.UUID}`
          );
        }}
      >
        {specification.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const specification = new SpecificationClass(context.created);
    content = (
      <Specification.Details
        specification={specification}
        extra={extraButtons(specification)}
      />
    );
  }

  if (context.isDeleted) {
    content = (
      <Specification.Details
        specification={new SpecificationClass(context.before)}
      />
    );
  }

  if (context.isChanged) {
    const specification = new SpecificationClass(context.after);
    content = (
      <Specification.Details
        specification={specification}
        extra={extraButtons(specification)}
      />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default SpecificationEventContext;
