import FieldType from '@/utils/models/FieldType';
import { FieldBuilder } from '../Fields';
import { getFieldSize } from './const/functions';

const StepByStepField = ({
  field,
  fieldBuilder,
}: {
  field: FieldType;
  fieldBuilder?: FieldBuilder;
}) => {
  return (
    <div
      className={`${getFieldSize(
        field.fieldSize || 'extralarge'
      )} border border-zinc-400 p-6`}
    >
      {field?.fields?.map((field, index: number) => (
        <div key={index.toString()}>{fieldBuilder?.getFormItem(field)}</div>
      ))}
    </div>
  );
};

export default StepByStepField;
