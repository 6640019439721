import { Descriptions, PageHeader } from 'antd';
import Item from '../List/Item';
import { IncidentClass } from '@/utils/models/Incident';

const IncidentDetails = ({
  incident,
  extra,
}: {
  incident?: IncidentClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {incident && (
        <PageHeader subTitle='Alert' title={incident.name} extra={extra}>
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={incident.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {incident.created}
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{incident.status}</span>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default IncidentDetails;
