// Time Format
import moment from 'moment';

export const counterFormat = (counter?: number | string) => {
  if (typeof counter === 'string') {
    return formatString(counter);
  }

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(counter || 0);
};

const formatString = (text: string): string => {
  // Check if the text contains a number
  const match = text.match(/(\d+)/);

  if (!match) {
    // If no number is found, return the original text
    return text;
  }

  // Get the number from the text
  const number = match[1];

  // Convert the number to an integer
  const numberInt = parseInt(number, 10);

  // Format the number
  const formattedNumber = counterFormat(numberInt);

  // Replace the number in the text with the formatted number
  const formattedString = text.replace(number, formattedNumber);

  return formattedString;
};

export const dateFormat = (date: string, value?: number) => {
  if (value) {
    if (value >= 2592000) {
      return moment(date).format('D MMM');
    }
    if (value <= 86400) {
      return moment(date).format('H:mm');
    }
  }

  return moment(date).format('D MMM, H:mm');
};

export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  if (i < 0) return parseFloat(bytes.toFixed(3)) + ' Bytes';

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
  );
};

export function formatMilliseconds(ms: number) {
  const isNegative = ms < 0;
  if (isNegative) ms = -ms;

  const msInSecond = 1000;
  const msInMinute = msInSecond * 60;
  const msInHour = msInMinute * 60;
  const msInDay = msInHour * 24;

  const days = Math.floor(ms / msInDay);
  ms %= msInDay;

  const hours = Math.floor(ms / msInHour);
  ms %= msInHour;

  const minutes = Math.floor(ms / msInMinute);
  ms %= msInMinute;

  const seconds = Math.floor(ms / msInSecond);
  ms %= msInSecond;

  const parts = [];
  if (isNegative) parts.push(`-`);
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  if (seconds > 0) parts.push(`${seconds}s`);
  if (ms > 0 || parts.length === 0) parts.push(`${ms}ms`);

  return parts.join(' ');
}
