const SelectOption = ({
  children,
}: {
  children: React.ReactNode;
  name: string;
}) => {
  return <>{children}</>;
};

export default SelectOption;
