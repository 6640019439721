import { AlertClass, AlertType } from '@/utils/models/Alert';
import { HiOutlineRefresh } from 'react-icons/hi';
import { Affix, Button, Form, FormInstance } from 'antd';
import AlertStaticChartPreview from './AlertStaticChartPreview';
import AlertAnomalyChartPreview from './AlertAnomalyChartPreview';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useMemo, useRef } from 'react';
import useWatchWithDebounce from '@/utils/hooks/useWatchWithDebounce';
import { useParams } from 'react-router-dom';

const AlertPreview = ({
  formInstance,
  alert,
  forceType,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  const alertStaticChartPreviewRef = useRef();
  const alertAnomalyChartPreviewRef = useRef();

  const thresholdConfigValue: number | undefined = Form.useWatch(
    ['config', 'threshold_value'],
    formInstance
  );

  const periodConfigValue: number | undefined = Form.useWatch(
    ['config', 'period'],
    formInstance
  );

  const metricNameConfigValue: string | undefined = Form.useWatch(
    ['config', 'metric_name'],
    formInstance
  );

  const metricStatConfigValue: string | undefined = Form.useWatch(
    ['config', 'metric_stat'],
    formInstance
  );

  const sensitivityConfigValue = useWatchWithDebounce<number | null>({
    formInstance,
    attribute: ['config', 'sensitivity'],
  });

  const alertType = forceType || alert?.type;

  const ChartComponent = useMemo(() => {
    if (
      alertType === 'static' &&
      periodConfigValue &&
      metricNameConfigValue &&
      metricStatConfigValue
    ) {
      return (
        <AlertStaticChartPreview
          threshold={thresholdConfigValue}
          period={periodConfigValue}
          ref={alertStaticChartPreviewRef}
          metricName={metricNameConfigValue}
          metricStat={metricStatConfigValue}
        />
      );
    }

    if (alertType === 'anomaly' && periodConfigValue) {
      return (
        <AlertAnomalyChartPreview
          sensitivity={sensitivityConfigValue ?? undefined}
          period={periodConfigValue}
          alert={alert}
          ref={alertAnomalyChartPreviewRef}
          alertStaticChartPreviewRef={alertStaticChartPreviewRef}
        />
      );
    }

    return <></>;
  }, [
    alert,
    alertType,
    periodConfigValue,
    metricNameConfigValue,
    metricStatConfigValue,
    thresholdConfigValue,
    sensitivityConfigValue,
  ]);

  if (!alert && isEdition) return <></>;

  return (
    <Affix offsetTop={77.1}>
      <AlertSectionWrapper
        title='Preview'
        info={`This is a visual preview of your alert. It illustrates the potential frequency and patterns of alert triggers over time.`}
        extra={
          <Button
            onClick={() => {
              if (alertType === 'static') {
                // @ts-ignore
                alertStaticChartPreviewRef.current.refreshData();
              }

              if (alertType === 'anomaly') {
                // @ts-ignore
                alertAnomalyChartPreviewRef.current.refreshData();
              }
            }}
            className='flex gap-1 items-center'
            size='small'
            style={{ backgroundColor: '#fff' }}
          >
            Refresh
            <HiOutlineRefresh />
          </Button>
        }
      >
        {ChartComponent}
      </AlertSectionWrapper>
    </Affix>
  );
};

export default AlertPreview;
