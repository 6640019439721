import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

export const mockIncrementQuota = <T>(
  draft: MaybeDrafted<{ current?: null | number }>
): T => {
  draft.current = draft.current ? draft.current + 1 : 1;
  return draft as T;
};

export const mockDecrementQuota = <T>(
  draft: MaybeDrafted<{ current?: null | number }>
): T => {
  draft.current = draft.current ? draft.current - 1 : 0;
  return draft as T;
};
