import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ListMode, selectListMode, setListMode } from './listSlice';

const ListSegmented = () => {
  const defaultMode: ListMode = useAppSelector(selectListMode);
  const dispatch = useAppDispatch();
  return (
    <div className='h-8 justify-end items-center gap-2.5 inline-flex'>
      <div className='bg-opacity-70 rounded-2xl border border-zinc-500 bg-zinc-300 justify-start items-start gap-1 flex relative'>
        <div
          className={`absolute bg-white transition-all pl-2.5 pr-3 py-[7px] rounded-2xl justify-start 
        items-center gap-1 flex ${
          defaultMode === 'table' ? 'right-0' : 'right-[3.8rem]'
        }`}
        >
          <div className='w-[44px] h-4' />
        </div>

        <div
          className='pl-2.5 pr-3 py-[7px] rounded-2xl justify-start items-center gap-1 flex cursor-pointer'
          onClick={() => dispatch(setListMode('grid'))}
        >
          <div className='w-[44px] h-4 relative'>
            <div className='w-[44px] h-4 absolute flex items-center'>
              <span className='material-symbols-outlined text-lg'>
                grid_view
              </span>
              <div className='text-gray-800 text-[12px] font-normal leading-none tracking-wide pl-1'>
                Grid
              </div>
            </div>
          </div>
        </div>

        <div
          className='pl-1 pr-2.5 py-[7px] justify-start items-center gap-1 flex cursor-pointer'
          onClick={() => dispatch(setListMode('table'))}
        >
          <div className='w-[44px] h-4 relative'>
            <div className='w-[44px] h-4 absolute flex items-center'>
              <span className='material-symbols-outlined text-lg'>
                format_list_bulleted
              </span>
              <div className='text-gray-800 text-[12px] font-normal leading-none tracking-wide pl-1'>
                List
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSegmented;
