export interface MemberInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  dateAssignedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  member_orgUUID: string;
  name: string;
  orgUUID: string;
  org_email: string;
  roleID: number;
  roleName: string;
  status: string;
}

export class MemberClass implements MemberInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  dateAssignedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  member_orgUUID: string;
  name: string;
  orgUUID: string;
  org_email: string;
  roleID: number;
  roleName: string;
  status: string;
  key: string;

  constructor(data: MemberInterface) {
    this.key = `${data.org_email}-${data.dateAddedInMicroSeconds}`;
    this.UUID = data.UUID;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.dateAssignedInMicroSeconds = data.dateAssignedInMicroSeconds;
    this.g_orgUUID = data.g_orgUUID;
    this.itemType = data.itemType;
    this.member_orgUUID = data.member_orgUUID;
    this.name = data.name;
    this.orgUUID = data.orgUUID;
    this.org_email = data.org_email;
    this.roleID = data.roleID;
    this.roleName = data.roleName;
    this.status = data.status;
  }
}

export interface MemberRoleInterface {
  friendly: string;
  id: number;
  name: string;
}
