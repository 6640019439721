import {
  IntegrationClass,
  IntegrationInterface,
} from '@/utils/models/Integration';
import { OrgGenericParams } from '../org/org-endpoints';
import { ListMyIntegrationParams } from './integration-endpoints';
import { getSlackCallbackParams } from './slack-integration-export-data';
import { getIntegrationAvailable } from './integration-export-data';
import { addIntegrationQueryFulfilled } from './integration-methods';
import baseApi from '../base-api';

interface AddSlackIntegrationParams
  extends OrgGenericParams,
    ListMyIntegrationParams {
  code: string;
}

interface EditSlackIntegrationParams extends AddSlackIntegrationParams {
  myIntegrationID: string;
}

export interface GetSlackCallbackParams {
  client_id: string;
  redirect_uri: string;
  callback_uri: string;
}

export const slackIntegrationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSlackCallbackParams: build.query<
      GetSlackCallbackParams,
      OrgGenericParams
    >({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(
          `/organisations/${orgID}/slack/install`
        );

        const response = result.data as GetSlackCallbackParams;

        const customRedirectURI =
          window.location.origin +
          '/' +
          response.redirect_uri.split('/').slice(3).join('/');

        const callback_uri = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${response?.client_id}&redirect_uri=${customRedirectURI}`;

        return {
          data: {
            client_id: response.client_id,
            redirect_uri: customRedirectURI,
            callback_uri,
          },
        };
      },
    }),
    addSlackIntegration: build.mutation<
      IntegrationClass,
      AddSlackIntegrationParams
    >({
      async queryFn({ orgID, code }, _queryApi, _extraOptions, fetchWithBQ) {
        const params = await getSlackCallbackParams({ orgID });

        const result = await fetchWithBQ({
          url: `/organisations/${orgID}/slack/install`,
          method: 'POST',
          data: {
            code,
            redirect_uri: params.redirect_uri,
            client_id: params.client_id,
          },
        });

        const { integration } = result.data as {
          integration: IntegrationInterface;
        };

        const available = await getIntegrationAvailable({
          orgID,
          integrationAvailableID: integration.integrationUUID,
        });

        return {
          data: new IntegrationClass(integration, {
            available,
          }),
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
          addIntegrationQueryFulfilled(response, dispatch)
        );
      },
    }),
    editSlackIntegration: build.mutation<
      IntegrationClass,
      EditSlackIntegrationParams
    >({
      async queryFn(
        { orgID, myIntegrationID, code },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const params = await getSlackCallbackParams({ orgID });

        const result = await fetchWithBQ({
          url: `/organisations/${orgID}/slack/install/${myIntegrationID}`,
          method: 'PUT',
          data: {
            code,
            redirect_uri: params.redirect_uri,
            client_id: params.client_id,
          },
        });

        const { integration } = result.data as {
          integration: IntegrationInterface;
        };

        const available = await getIntegrationAvailable({
          orgID,
          integrationAvailableID: integration.integrationUUID,
        });

        return {
          data: new IntegrationClass(integration, {
            available,
          }),
        };
      },
    }),
  }),
});

export const {
  useAddSlackIntegrationMutation,
  useGetSlackCallbackParamsQuery,
  useLazyGetSlackCallbackParamsQuery,
  useEditSlackIntegrationMutation,
} = slackIntegrationEndpoints;
