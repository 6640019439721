import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { AutoComplete, Form } from 'antd';
import { getColSize, getFieldSize } from './const/functions';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';
import { useParams } from 'react-router-dom';

/* eslint-disable no-template-curly-in-string */
const AutoCompleteField = ({ field }: { field: FieldType }) => {
  const { orgID, apiID } = useParams<{ orgID: string; apiID?: string }>();

  const { data: apiData } = useGetApiQuery(
    { orgID, apiID: apiID || '' },
    { skip: !apiID || field.sourceFrom?.resource !== 'api-endpoints' }
  );

  const rules: Rule[] = [];

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  if (field?.validation?.regex) {
    rules.push({
      pattern:
        typeof field.validation.regex === 'string'
          ? new RegExp(`${field.validation.regex}`, 'g')
          : field.validation.regex,
      message: field?.validation?.regex_description || '${label} is invalid',
    });
  }

  let options: { label: string; value: string; logoURL?: string }[] = [];

  if (field.sourceFrom?.resource === 'api-endpoints') {
    const apiEndpoints = apiData?.ft_integration?.discovered_endpoints;
    options =
      apiEndpoints?.map((endpoint) => ({
        label: endpoint,
        value: endpoint,
      })) || [];
  }

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'middle')}
      >
        <AutoComplete
          maxLength={field.validation?.max || 128}
          placeholder={field.placeholder}
          disabled={field.disabled}
          options={options}
        />
      </Form.Item>
    </div>
  );
};

export default AutoCompleteField;
