import { FINDINGS_FIELD_BY_RESOURCE } from '@/utils/constants';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import LIST_OF_SEVERITY_FINDINGS from '@/utils/constants/listOfSeverityFindings';
import LIST_OF_API_STATUS_FINDINGS from '@/utils/constants/listOfStatusFindings';
import { getFindingMappings } from '../local-api-export-data';
import { listEvents } from '../events/event-export-data';
import { FilterClass, FilterInterface } from '@/utils/models/Filter';
import { listApis } from '../api/api-export-data';
import { listApplications } from '../application/application-export-data';
import LIST_OF_SECURITY_FRAMEWORKS from '@/utils/constants/listOfSecurityFrameworks';

export type ResourceType =
  | 'api'
  | 'finding'
  | 'incident_policy'
  | 'log'
  | 'linkedRepository';

interface ListFieldsByResourceParams extends OrgGenericParams {
  resource: ResourceType;
}

interface AutocompleteFilterOptionsParams extends ListFieldsByResourceParams {
  field: string;
  query: string;
  appID?: string;
  apiID?: string;
}

export type FieldByResource = { key: string; value: string };

export type AutocompleteFilterOptionsType = {
  disabled?: boolean;
  key?: string;
  label: React.ReactNode;
  value: string | number;
  avatar?: string;
};

export const resourceEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listFieldsByResource: build.query<
      FieldByResource[],
      ListFieldsByResourceParams
    >({
      async queryFn(
        { orgID, resource },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        if (resource === 'finding') {
          return { data: FINDINGS_FIELD_BY_RESOURCE };
        }

        const url = `/organisations/${orgID}/filters?resource=${resource}`;
        const resourceResult = await fetchWithBQ(url);

        const { filters } = resourceResult.data as {
          filters: FieldByResource[];
        };

        return { data: filters };
      },
    }),
    listLogFilterFieldsByResource: build.query<FilterClass[], OrgGenericParams>(
      {
        async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
          const url = `/organisations/${orgID}/filters?resource=log`;
          const resourceResult = await fetchWithBQ(url);

          const { filters } = resourceResult.data as {
            filters: FilterInterface[];
          };

          const filtersInstances = filters.map(
            (filter) => new FilterClass(filter)
          );

          return { data: filtersInstances };
        },
      }
    ),
    autocompleteFilterOptions: build.query<
      AutocompleteFilterOptionsType[],
      AutocompleteFilterOptionsParams
    >({
      async queryFn(
        { orgID, resource, field, query, apiID, appID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const isAPI = field === 'apiUUID' || field === 'apis';
        if (isAPI) {
          const apis = await listApis({ orgID, query });
          return {
            data: apis.map((api) => ({
              label: api.name,
              value: api.UUID,
              disabled: api.UUID === apiID,
              avatar: api.avatar,
              key: api.UUID,
            })),
          };
        }

        const isAPP = field === 'appUUID' || field === 'apps';
        if (isAPP) {
          const applications = await listApplications({ orgID, query });
          return {
            data: applications.map((application) => ({
              label: application.name,
              value: application.UUID,
              disabled: application.UUID === appID,
            })),
          };
        }

        const isResource = field === 'resources';
        if (isResource) {
          const apis = await listApis({ orgID });
          const applications = await listApplications({ orgID });

          const apiData = apis.map((api) => ({
            label: `${api.name}devTagAsApi`,
            value: `api:${api.UUID}`,
            disabled: api.UUID === apiID,
          }));

          const applicationData = applications.map((application) => ({
            label: `${application.name}devTagAsApp`,
            value: `app:${application.UUID}`,
            disabled: application.UUID === appID,
          }));

          return {
            data: [...apiData, ...applicationData],
          };
        }

        if (resource === 'finding') {
          if (field === 'severity') {
            return {
              data: LIST_OF_SEVERITY_FINDINGS.map((severity) => ({
                label: severity,
                value: severity,
              })),
            };
          }

          if (field === 'status') {
            return {
              data: LIST_OF_API_STATUS_FINDINGS.map((status) => ({
                label: status,
                value: status,
              })),
            };
          }

          if (field === 'security-frameworks') {
            return {
              data: LIST_OF_SECURITY_FRAMEWORKS,
            };
          }

          if (field === 'code') {
            const mappings = await getFindingMappings();
            const removeDuplicateMappings = new Set(Object.keys(mappings));
            const removeIgnoredMappings = Array.from(
              removeDuplicateMappings
            ).filter((key) => !key.startsWith('owasp:a'));

            return {
              data: removeIgnoredMappings.map((key) => ({
                label: mappings[key].title,
                value: key,
              })),
            };
          }

          if (field === 'events') {
            const events = await listEvents({ orgID });
            return {
              data: events.map((event) => ({
                label: event.mapping?.title || event.code,
                value: event.UUID,
              })),
            };
          }
        }

        const resourceResult = await fetchWithBQ({
          url: `/organisations/${orgID}/filters/autocomplete`,
          method: 'POST',
          data: {
            resource,
            field,
            search_value: `*${query}*`,
          },
        });

        const { data: options } = resourceResult.data as {
          data: { name: string; value: string }[];
        };

        return {
          data: options.map((o) => ({ label: o.name, value: o.value })),
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useListFieldsByResourceQuery,
  useLazyAutocompleteFilterOptionsQuery,
  useAutocompleteFilterOptionsQuery,
  useListLogFilterFieldsByResourceQuery,
} = resourceEndpoints;

export default resourceEndpoints;
