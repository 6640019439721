import { baseApi } from '../base-api';
import {
  SpecificationClass,
  SpecificationInterface,
} from '@/utils/models/Specification';
import { OrgGenericParams } from '../org/org-endpoints';
import { getSpecificationVersion } from './specification-export-data';
import { ApiClass } from '@/utils/models/Api';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { specificationQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';
import { listAPIsByUUID } from '../api/api-export-data';

export interface ListSpecificationsParams extends OrgGenericParams {
  appID: string;
  apiID?: string;
  query?: string;
  fetchApis?: boolean;
}

interface AddSpecificationParams extends OrgGenericParams {
  appID: string;
  apiID: string;
  name: string;
  specData: any;
}

interface GenerateSpecificationParams extends OrgGenericParams {
  appID: string;
  apiID: string;
  specData: { startDate: string; endDate: string };
}

interface SpecificationGenericParams {
  orgID: string;
  specificationID: string;
  versionID?: string;
}

interface UpdateSpecificationParams extends SpecificationGenericParams {
  name: string;
}

export interface GeneratedSpec {
  components: object;
  info: {
    contact: {
      email: string;
    };
    description: string;
    title: string;
    version: string;
  };
  openapi: string;
  paths: {
    [key: string]: any;
  };
  security: any[];
  servers: any[];
}

export const specificationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listSpecifications: build.query<
      SpecificationClass[],
      ListSpecificationsParams
    >({
      async queryFn(
        { orgID, appID, apiID, query, fetchApis = true },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let collectionsResult;
        if (query) {
          const url = apiID
            ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/collections/search`
            : `/organisations/${orgID}/applications/${appID}/collections/search`;
          collectionsResult = await fetchWithBQ({
            url,
            method: 'POST',
            data: { search_value: query },
          });
        } else {
          const url = apiID
            ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/collections`
            : `/organisations/${orgID}/applications/${appID}/collections`;
          collectionsResult = await fetchWithBQ(url);
        }

        const { collections } = collectionsResult.data as {
          collections: SpecificationInterface[];
        };

        let apis: ApiClass[] | undefined;
        if (fetchApis && collections.length > 0) {
          apis = await listAPIsByUUID({
            orgID,
            UUIDs: collections.map(
              (collection) => collection.collection_apiUUID
            ),
          });
        }

        return {
          data: collections
            .sort(
              (collectionA, collectionB) =>
                Number(collectionB.dateAddedInMicroSeconds) -
                Number(collectionA.dateAddedInMicroSeconds)
            )
            .map((specification) => {
              const api = apis?.find(
                (api) => api.UUID === specification.collection_apiUUID
              );
              return new SpecificationClass(specification, { api });
            }),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ UUID }) => ({
                type: 'Specifications' as const,
                id: UUID,
              })),
              { type: 'Specifications', id: 'LIST' },
            ]
          : [{ type: 'Specifications', id: 'LIST' }],
    }),
    addSpecification: build.mutation<
      SpecificationClass,
      AddSpecificationParams
    >({
      query: ({ orgID, appID, apiID, specData, name }) => ({
        url: `/organisations/${orgID}/collections`,
        method: 'POST',
        data: {
          api_uuid: apiID,
          app_uuid: appID,
          spec_type: 'OAS3.0',
          name,
          spec_data: specData,
        },
      }),
      transformResponse: (rawResult: { collection: SpecificationInterface }) =>
        new SpecificationClass(rawResult.collection),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            specificationEndpoints.util.invalidateTags([
              { type: 'Specifications', id: 'LIST' },
            ])
          );

          dispatch(
            specificationQuotaEndpoint.util.updateQueryData(
              'specification',
              { orgID },
              (draft) => mockIncrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(specificationEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    getSpecification: build.query<
      SpecificationClass,
      SpecificationGenericParams
    >({
      async queryFn(
        { orgID, specificationID, versionID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const collectionResult = await fetchWithBQ(
          `/organisations/${orgID}/collections/${specificationID}`
        );

        if (collectionResult.error) {
          const { data } = collectionResult.error as {
            data: { message: string };
          };
          throw new Error(data.message);
        }

        const { collection } = collectionResult.data as {
          collection: SpecificationInterface;
        };

        const version = await getSpecificationVersion({
          orgID,
          specificationID,
          versionID: versionID || collection.latestVersionUUID,
        });

        return {
          data: new SpecificationClass(collection, { version }),
        };
      },
      providesTags: (result, error, { specificationID }) => [
        { type: 'Specifications', id: specificationID },
      ],
    }),
    updateSpecificationName: build.mutation<
      SpecificationInterface,
      UpdateSpecificationParams
    >({
      query: ({ orgID, specificationID, name }) => ({
        url: `/organisations/${orgID}/collections/${specificationID}`,
        method: 'PUT',
        data: {
          name,
        },
      }),
      transformResponse: (rawResult: { collection: SpecificationInterface }) =>
        rawResult.collection,
      invalidatesTags: (result, error, { specificationID }) => [
        { type: 'Specifications', id: specificationID },
        { type: 'Specifications', id: 'LIST' },
      ],
    }),
    deleteSpecification: build.mutation<void, SpecificationGenericParams>({
      query: ({ orgID, specificationID }) => ({
        url: `/organisations/${orgID}/collections/${specificationID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            specificationEndpoints.util.invalidateTags([
              { type: 'Specifications', id: 'LIST' },
            ])
          );

          dispatch(
            specificationQuotaEndpoint.util.updateQueryData(
              'specification',
              { orgID },
              (draft) => mockDecrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(specificationEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    generateSpecification: build.mutation<
      GeneratedSpec,
      GenerateSpecificationParams
    >({
      query: ({ orgID, apiID, appID, specData }) => ({
        url: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/spec`,
        method: 'POST',
        data: {
          start_date: specData.startDate,
          end_date: specData.endDate,
        },
      }),
      transformResponse: (rawResult: { spec: GeneratedSpec }) => rawResult.spec,
      invalidatesTags: () => [{ type: 'Specifications', id: 'LIST' }],
    }),
  }),
});

export const {
  useListSpecificationsQuery,
  useAddSpecificationMutation,
  useGetSpecificationQuery,
  useLazyGetSpecificationQuery,
  useUpdateSpecificationNameMutation,
  useDeleteSpecificationMutation,
  useGenerateSpecificationMutation,
} = specificationEndpoints;
