import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Form, FormInstance, InputNumber } from 'antd';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useParams } from 'react-router-dom';

const AlertAdditionalConfig = ({
  formInstance,
  alert,
  forceType,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
  isReadOnly?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  const evaluationPeriodsConfigValue: number | undefined = Form.useWatch(
    ['config', 'evaluation_periods'],
    formInstance
  );

  const datapointsToAlarmConfigValue: number | undefined = Form.useWatch(
    ['config', 'datapoints_to_alarm'],
    formInstance
  );

  const alertType = forceType || alert?.type;
  if (alertType === 'static' || (!alert && isEdition)) return <></>;

  let datapointsInputStatus = undefined;
  if (
    evaluationPeriodsConfigValue !== undefined &&
    datapointsToAlarmConfigValue !== undefined &&
    datapointsToAlarmConfigValue > evaluationPeriodsConfigValue
  ) {
    datapointsInputStatus = 'error' as 'error';
  }

  return (
    <AlertSectionWrapper
      isDefaultCollapseOpen={false}
      title='Additional Configuration'
      info={`Set the number of evaluation periods and the minimum number of data points required to trigger an alarm.`}
    >
      <div className='grid grid-cols-6 gap-6'>
        <div className='col-span-2'>
          <Form.Item
            label='Datapoints to alarm'
            tooltip='Define the number of datapoints within the evaluation period that must be anomalous to trigger an alarm.'
          />

          <div className='flex gap-3 items-center -mt-14'>
            <InputNumber
              min={1}
              status={datapointsInputStatus}
              max={evaluationPeriodsConfigValue || 5}
              value={datapointsToAlarmConfigValue}
              formatter={(value) => `${value ? Math.round(value) : ''}`}
              placeholder='1'
              onPressEnter={(e) => e.preventDefault()}
              onChange={(value) =>
                formInstance.setFieldsValue({
                  config: { datapoints_to_alarm: value ?? undefined },
                })
              }
              disabled={isReadOnly}
            />

            <span className='whitespace-nowrap text-gray-500 col-span-1'>
              out of
            </span>

            <InputNumber
              min={1}
              max={5}
              value={evaluationPeriodsConfigValue}
              formatter={(value) => `${value ? Math.round(value) : ''}`}
              placeholder='1'
              onPressEnter={(e) => e.preventDefault()}
              onChange={(value) =>
                formInstance.setFieldsValue({
                  config: { evaluation_periods: value ?? undefined },
                })
              }
              disabled={isReadOnly}
            />
          </div>
        </div>

        <Form.Item hidden name={['config', 'evaluation_periods']}>
          <InputNumber disabled={isReadOnly} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              type: 'number',
              max: evaluationPeriodsConfigValue || 5,
            },
          ]}
          hidden
          name={['config', 'datapoints_to_alarm']}
        >
          <InputNumber disabled={isReadOnly} />
        </Form.Item>
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertAdditionalConfig;
