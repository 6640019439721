import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import {
  IncidentClass,
  IncidentInterface,
  StatusIncidentType,
} from '@/utils/models/Incident';
import { EventClass } from '@/utils/models/Event';
import {
  IncidentContextClass,
  IncidentPolicyContextType,
} from '@/utils/models/IncidentContext';
import { listAPIsByUUID } from '../api/api-export-data';
import { ApiClass } from '@/utils/models/Api';
import { getEventByUUID, getEventsByUUIDs } from '../events/event-export-data';

interface IncidentParams extends OrgGenericParams {
  incidentID: string;
}

interface UpdateStatusParams extends IncidentParams {
  status: StatusIncidentType;
}

interface ListIncidentsParams extends OrgGenericParams {
  pageSize?: number;
  marker?: number;
}

interface ListIncidentsResponse {
  incidents: IncidentClass[];
  total: number;
}

export const incidentEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listIncidents: build.query<ListIncidentsResponse, ListIncidentsParams>({
      query: ({ orgID, pageSize, marker }) => {
        const resource = 'incident';

        const params: string[] = [`resource=${resource}`];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);
        const url = `/organisations/${orgID}/search?${params.join('&')}`;

        return {
          url,
          method: 'POST',
          data: {
            resource,
            sort: { order: 'desc' },
          },
        };
      },
      transformResponse: (rawResult: {
        incident: IncidentInterface[];
        total: number;
      }) => ({
        incidents: rawResult.incident.map(
          (incident) => new IncidentClass(incident)
        ),
        total: rawResult.total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.incidents.map(({ UUID }) => ({
                type: 'Incidents' as const,
                id: UUID,
              })),
              { type: 'Incidents', id: 'LIST' },
            ]
          : [{ type: 'Incidents', id: 'LIST' }],
    }),
    getIncident: build.query<IncidentClass, IncidentParams>({
      async queryFn(
        { orgID, incidentID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const incidentResult = await fetchWithBQ(
          `/organisations/${orgID}/incidents/${incidentID}`
        );
        const { incident } = incidentResult.data as {
          incident: IncidentInterface;
        };

        let events: EventClass[] | undefined;
        if (incident.eventUUID) {
          const event = await getEventByUUID({
            orgID,
            eventID: incident.eventUUID,
          });
          events = [event];
        }

        if (
          incident.context &&
          'eventUUIDs' in incident.context &&
          incident.context.eventUUIDs
        ) {
          events = await getEventsByUUIDs({
            orgID,
            eventsIDs: incident.context.eventUUIDs,
          });
        }

        const context = new IncidentContextClass(incident.context);
        let resources: { apis?: ApiClass[]; apiIDs?: string[] } | undefined;
        if (context.isIncidentPolicyContextType) {
          const data = context.data as IncidentPolicyContextType;
          const apiIDs = data.resources
            .filter((resource) => resource.startsWith('api:'))
            .map((resource) => resource.replace('api:', ''));
          const apis = await listAPIsByUUID({ orgID, UUIDs: apiIDs });
          resources = { apis, apiIDs };
        }

        return {
          data: new IncidentClass(incident, { events, resources }),
        };
      },
      providesTags: (result, error, { incidentID }) => [
        { type: 'Incidents', id: incidentID },
      ],
    }),
    updateIncidentStatus: build.mutation<null, UpdateStatusParams>({
      query: ({ orgID, incidentID, status }) => ({
        url: `/organisations/${orgID}/incidents/${incidentID}/status`,
        method: 'PUT',
        data: { status },
      }),
      invalidatesTags: (result, error, { incidentID }) => [
        { type: 'Incidents', id: incidentID },
      ],
    }),
  }),
});

export const {
  useListIncidentsQuery,
  useUpdateIncidentStatusMutation,
  useLazyGetIncidentQuery,
} = incidentEndpoints;
