import { AiOutlineLineChart } from 'react-icons/ai';
import { DecisionItemType } from '../../components/Modal/ModalDecision';
import { MdAutoGraph } from 'react-icons/md';
import useRole from '@/utils/hooks/useRole';
import {
  AnomalyAlertingQuotaType,
  StaticAlertingQuotaType,
} from '../models/Quota';

interface UseAlertDecisionItemsProps {
  key?: string;
  iconSize?: number;
  staticQuota?: StaticAlertingQuotaType;
  anomalyQuota?: AnomalyAlertingQuotaType;
}

function useAlertDecisionItems(
  props?: UseAlertDecisionItemsProps
): DecisionItemType[] {
  const { hasPermissionToCreateAnomalyDetection } = useRole();
  let staticQuota: StaticAlertingQuotaType | undefined;
  let anomalyQuota: AnomalyAlertingQuotaType | undefined;
  if (props) {
    staticQuota = props.staticQuota;
    anomalyQuota = props.anomalyQuota;
  }
  const staticUpgradeRequired =
    (staticQuota?.current as number) >= (staticQuota?.max as number) ||
    (staticQuota?.max as number) === 0;
  const anomalyUpgradeRequired =
    (anomalyQuota?.current as number) >= (anomalyQuota?.max as number) ||
    (anomalyQuota?.max as number) === 0;

  const generateAnomalyText = () => {
    if (anomalyQuota?.max === 0) {
      return 'Your current plan does not support anomaly detection alerts.';
    }

    if (anomalyUpgradeRequired) {
      return `You are currently using ${Number(
        anomalyQuota?.current
      )} out of your ${Number(
        anomalyQuota?.max
      )} allocated anomaly detection alerts.`;
    }

    return undefined;
  };

  const alertTypes: DecisionItemType[] = [
    {
      title: 'Static',
      description: 'Use a static value as a threshold',
      value: 'static',
      icon: <AiOutlineLineChart size={props?.iconSize || 26} />,
      isUpgradeRequired: staticUpgradeRequired,
      tooltipText: staticUpgradeRequired
        ? `You are currently using ${Number(
            staticQuota?.current
          )} out of your ${Number(staticQuota?.max)} allocated static alerts.`
        : undefined,
    },
    {
      title: 'Anomaly Detection',
      description: 'Use a band as a threshold',
      value: 'anomaly',
      icon: <MdAutoGraph size={props?.iconSize || 26} />,
      disabled: !hasPermissionToCreateAnomalyDetection,
      isBeta: true,
      isUpgradeRequired: anomalyUpgradeRequired,
      tooltipText: generateAnomalyText(),
    },
  ];

  if (props?.key) {
    return alertTypes.filter((alertType) => alertType.value === props.key);
  }

  return alertTypes;
}

export default useAlertDecisionItems;
