import List from '@/components/List';
import Item from '@/components/List/Item';
import {
  FindingClass,
  SeverityFindingType,
  StatusFindingType,
} from '@/utils/models/Finding';
import {
  useLazyListFindingsQuery,
  useListFindingsQuery,
} from '@/utils/services/finding/finding-endpoints';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import FindingDrawer from './Item/FindingDrawer';
import FindingsOverview from './FindingsOverview';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import useRole from '@/utils/hooks/useRole';
import useSortQueryParam from '@/utils/hooks/QueryParam/useSortQueryParam';
import FindingsSelectStatus from './FindingsSelectStatus';
import FindingsSelectSeverity from './FindingsSelectSeverity';
import SeverityTag from '../Tag/SeverityTag';
import usePaginationQueryParam, {
  PaginationDataType,
} from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import useStaticDateTimeQueryParam from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';
import FindingsSecurityFrameworks from './FindingsSecurityFrameworks';

const FindingsListing = () => {
  const { hasPermissionToGetFinding } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const history = useHistory();
  const location = useLocation();

  const { filters } = useFiltersQueryParam();
  const { dateTime } = useStaticDateTimeQueryParam();
  const { pagination } = usePaginationQueryParam();
  const { sort, setSort } = useSortQueryParam();
  const { query } = useSearchQueryParam();

  const {
    data: findingsData,
    isLoading: listFindingsLoading,
    isFetching: listFindingsFetching,
  } = useListFindingsQuery({
    orgID,
    filters,
    dateTime,
    sort,
    ...pagination,
  });

  const [
    listFindingsToDownload,
    { isLoading: findingsListToDownloadIsLoading },
  ] = useLazyListFindingsQuery();

  const findings: FindingClass[] = filterFindingsBySearchQuery(
    query,
    findingsData?.findings
  );

  const fetchDataToDownload = async (
    pagination?: PaginationDataType
  ): Promise<any[]> => {
    const data = await listFindingsToDownload(
      { orgID, filters, dateTime, sort, ...pagination },
      true
    ).unwrap();

    return data.findings.map((finding) => finding.mapDataToDownload);
  };

  const columns: ColumnsType<FindingClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, finding) =>
        hasPermissionToGetFinding ? (
          <button
            onClick={() => handleFindingSelected(finding)}
            className='text-base text-blue-600 font-semibold text-left'
          >
            {finding.mapping?.title || finding.code}
          </button>
        ) : (
          <span className='text-base font-semibold text-left'>
            {finding.mapping?.title || finding.code}
          </span>
        ),
    },
    {
      title: 'Description',
      key: 'description',
      width: 400,
      render: (_, finding) => (
        <Item.Description>{finding.mapping?.description}</Item.Description>
      ),
    },
    {
      title: 'API',
      dataIndex: 'api.name',
      render: (_, finding) =>
        finding.api ? (
          <Item.LogoAndName
            logo={finding.api?.avatar}
            name={finding.api?.name}
          />
        ) : (
          <></>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: StatusFindingType, finding) => (
        <FindingsSelectStatus status={status} findingID={finding.UUID} />
      ),
    },
    {
      title: 'Severity',
      sorter: true,
      dataIndex: 'severity',
      key: 'severity',
      render: (severity: SeverityFindingType, finding) => (
        <FindingsSelectSeverity severity={severity} findingID={finding.UUID} />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, finding) => (
        <Item.Date
          created={finding.created}
          createdFromNow={finding.createdFromNow}
        />
      ),
    },
  ];

  const handleFindingSelected = (finding: FindingClass) => {
    history.replace({
      ...location,
      pathname: `${location.pathname}/${finding.UUID}`,
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const orderEnum: { ascend: 'asc'; descend: 'desc' } = {
      ascend: 'asc',
      descend: 'desc',
    };

    if (!Array.isArray(sorter)) {
      const sort = sorter.order
        ? {
            sort_field: sorter.columnKey as string,
            sort_order: orderEnum[sorter.order],
          }
        : undefined;
      setSort(sort);
    }
  };

  return (
    <>
      <FindingDrawer />

      <List.Root>
        <List.Header>
          <List.Search placeholder='Search findings' />

          <List.Settings>
            <List.Filter showDateTimeSelector resource='finding' />
            <List.Download
              numberOfItems={findingsData?.total}
              fetchData={fetchDataToDownload}
              loading={findingsListToDownloadIsLoading}
            />
          </List.Settings>

          <List.Segmented />
        </List.Header>

        <div className='grid grid-cols-1 lg:grid-cols-6 gap-4 pb-5'>
          <div className='lg:col-span-4'>
            <FindingsOverview />
          </div>

          <div className='lg:col-span-2'>
            <FindingsSecurityFrameworks />
          </div>
        </div>

        <List.Pagination
          dataSource={findings}
          useURI
          total={query ? findings.length : findingsData?.total}
        >
          <List.Grid
            item={{
              render: (finding: FindingClass) => (
                <Item.Root
                  key={finding.key}
                  onClick={
                    hasPermissionToGetFinding
                      ? () => handleFindingSelected(finding)
                      : undefined
                  }
                  id={finding.key}
                >
                  <Item.Header>
                    <div className='flex gap-2 items-center'>
                      {finding?.severity && (
                        <SeverityTag compact severity={finding.severity} />
                      )}
                      <Item.Title>
                        {finding.mapping?.title || finding.code}
                      </Item.Title>
                    </div>
                    <Item.Description>
                      {finding.mapping?.description}
                      {finding.context.id && (
                        <div className='flex flex-row my-2'>
                          <div className='font-semibold mr-2'>ID:</div>
                          {finding.context.id}
                        </div>
                      )}
                    </Item.Description>
                  </Item.Header>

                  <div className='flex items-center gap-x-2 w-full'>
                    <FindingsSelectStatus
                      status={finding.status}
                      findingID={finding.UUID}
                    />

                    <FindingsSelectSeverity
                      severity={finding.severity}
                      findingID={finding.UUID}
                      buttonMinWidth='9rem'
                      buttonClassName='w-full'
                    />
                  </div>

                  <Item.Footer>
                    {finding?.api && (
                      <Item.LogoAndName
                        logo={finding.api?.avatar}
                        name={finding.api?.name}
                      />
                    )}

                    <Item.Date
                      created={finding.created}
                      createdFromNow={finding.createdFromNow}
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={listFindingsLoading || listFindingsFetching}
          />

          <List.Table
            columns={columns}
            loading={listFindingsLoading || listFindingsFetching}
            onTableChange={handleTableChange}
          />
        </List.Pagination>
      </List.Root>
    </>
  );
};

const filterFindingsBySearchQuery = (
  searchQuery: string,
  findings?: FindingClass[]
): FindingClass[] => {
  if (!findings) return [];

  const sq = searchQuery.toLowerCase();
  return findings.filter((finding) => {
    const title = finding.mapping?.title.toLowerCase();
    const description = finding.mapping?.description.toLowerCase();
    const code = finding.code.toLowerCase();
    const uuid = finding.UUID.toLowerCase();
    return (
      uuid?.includes(sq) ||
      title?.includes(sq) ||
      description?.includes(sq) ||
      code.includes(sq)
    );
  });
};

export default FindingsListing;
