import { Tooltip } from 'antd';
import { CSSProperties } from 'react';
import { MdInfo } from 'react-icons/md';

interface QuotaIndicatorProps {
  quota?: {
    current?: null | number;
    max?: null | number;
  };
  tooltipText: string;
  quotaUsedText?: string;
  dark?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ListQuotaIndicator = ({
  quota,
  tooltipText,
  quotaUsedText,
  dark = false,
  className,
  style,
}: QuotaIndicatorProps) => {
  if (typeof quota?.current !== 'number' || typeof quota?.max !== 'number')
    return null;

  return (
    <div
      className={`flex flex-row font-normal ${
        (quota.current as number) >= (quota?.max as number)
          ? 'text-second'
          : dark
            ? 'text-anti-flash'
            : ''
      } transition-all self-center text-nowrap ${className || ''}`}
      style={style}
    >
      <Tooltip className='flex gap-1 items-center' title={tooltipText}>
        {`${quota.current}/${quota.max} ${quotaUsedText || 'used'}`}
        <MdInfo size={20} />
      </Tooltip>
    </div>
  );
};

export default ListQuotaIndicator;
