import MethodTag from '@/components/Tag/MethodTag';
import RequestTag from '@/components/Tag/RequestTag';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import { RequestClass } from '@/utils/models/Request';
import { Tooltip } from 'antd';

const DashboardRequestDetailsGrid = ({ log }: { log: RequestClass }) => {
  return (
    <div className='flex border-t border-b -mx-6 px-6'>
      <div className='grid gap-2 py-3 border-r pr-5'>
        <span>Source</span>
        <Tooltip title={log.typeLabel}>
          <img src={log.avatar} alt='logo' className='h-6 opacity-75' />
        </Tooltip>
      </div>

      <div className='grid gap-2 py-3 border-r px-5'>
        <span>Status</span>
        <div>
          <StatusCodeTag code={log.response?.statusCode?.toString()} />
        </div>
      </div>

      {log.request?.method && (
        <div className='grid gap-2 py-3 border-r px-5'>
          <span>Method</span>
          <div>
            <MethodTag name={log.request?.method} />
          </div>
        </div>
      )}

      <div className='grid gap-2 py-3 border-r px-5'>
        <span>Path</span>
        <span style={{ maxWidth: 400 }} className='break-words'>
          {log.request?.path}
        </span>
      </div>

      <div className='grid gap-2 py-3 px-5'>
        <span>Tags</span>
        <div className='leading-loose'>
          {log.tags?.map((tag) => <RequestTag tagValue={tag} key={tag} />)}
        </div>
      </div>
    </div>
  );
};

export default DashboardRequestDetailsGrid;
