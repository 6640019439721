const DashboardFiltersCounter = ({
  count,
  dark = false,
}: {
  count: number;
  dark?: boolean;
}) => (
  <div
    className={`${
      dark ? 'bg-anti-flash' : 'bg-first'
    } rounded-full relative flex items-center`}
    style={{ width: 25, height: 25 }}
  >
    <span
      className={`absolute ${
        dark ? 'text-first' : 'text-anti-flash'
      } text-center`}
      style={{
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 25,
      }}
    >
      {count}
    </span>
  </div>
);

export default DashboardFiltersCounter;
