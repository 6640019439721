import FieldType from '@/utils/models/FieldType';
import { Form, Modal } from 'antd';
import { ReactNode, isValidElement, useEffect } from 'react';
import { FieldBuilder } from '../Form/Fields';

const FormsModal = ({
  showModal,
  handleSubmit,
  confirmLoading,
  handleCloseModal,
  title,
  fields,
  initValues,
  okText,
}: {
  showModal: boolean;
  confirmLoading?: boolean;
  title: string;
  handleCloseModal: () => void;
  handleSubmit: (values: any) => void;
  fields: ReactNode[] | FieldType[];
  initValues?: object;
  okText?: string;
}) => {
  const [form] = Form.useForm();
  const fieldBuilder = new FieldBuilder(form);

  useEffect(() => {
    form.resetFields();

    if (initValues && showModal) {
      form.setFieldsValue(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues, showModal]);

  const handleClose = () => {
    form.resetFields();
    handleCloseModal();
  };

  const formItems = fields.map((field) => {
    if (isValidElement(field)) return field;
    return fieldBuilder.getFormItem(field as FieldType);
  });

  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      title={title}
      open={showModal}
      okText={okText}
      onOk={() => form.submit()}
      width={'50%'}
      confirmLoading={confirmLoading}
      onCancel={handleClose}
      forceRender
    >
      <Form layout='vertical' form={form} onFinish={handleSubmit}>
        <div
          className='grid grid-cols-1 gap-4 overflow-auto -m-6 p-6'
          style={{
            maxHeight: '78vh',
            paddingLeft: '7rem',
            paddingRight: '7rem',
          }}
        >
          {formItems.map((field) => field)}
        </div>
      </Form>
    </Modal>
  );
};

export default FormsModal;
