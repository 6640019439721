const LinkNavbar = ({
  children,
  href,
}: {
  children: React.ReactNode;
  href: string;
}) => (
  <a
    className='items-center 
      focus:outline-none dark:hover:text-second hover:text-second
       text-gray-800 dark:text-gray-50 text-base hidden md:inline-flex'
    onClick={() => {}}
    href={href}
    target='_blank'
    rel='noreferrer'
  >
    {children}
  </a>
);

export default LinkNavbar;
