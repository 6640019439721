import { counterFormat, dateFormat } from '@/utils/methods/displayFormat';
import { useGetRequestsHistographQuery } from '@/utils/services/org/org-endpoints';
import moment from 'moment';
import Loader from '../Loader/Loader';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';
import { Line } from '@ant-design/plots';
import { useParams } from 'react-router-dom';

const LINE_COLORS = [
  '#227AFF',
  '#E68D11',
  '#8EC1F8',
  '#5E401C',
  '#F0EAD2',
  '#7D7D7D',
  '#733907',
  '#ffb944',
  '#D60EB6',
  '#1E4FFF',
];

const DashboardRequestsLineChart = ({ light }: { light?: boolean }) => {
  const { appID } = useParams<{ appID?: string }>();
  const { params, options } = useGetEndpointSettings();

  const {
    currentData: lineChartData,
    isLoading,
    isFetching,
  } = useGetRequestsHistographQuery(params, options);
  const { dateTime } = params;

  const textColor = light ? '' : 'text-anti-flash';

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex items-center gap-4'>
        <span className={`${textColor} text-xl`}>
          API requests grouped by {appID ? 'app' : 'apps'}
        </span>
        <Loader spinning={isLoading || isFetching} />
      </div>

      <div>
        <span className={`${textColor} text-xs`}>Requests</span>

        <Line
          height={400}
          data={lineChartData?.data || []}
          xField='date'
          yField='value'
          seriesField='uuid'
          color={LINE_COLORS}
          theme={{
            components: {
              axis: {
                common: {
                  grid: {
                    line: {
                      type: 'line',
                      style: {
                        stroke: '#44575F',
                        lineWidth: 1,
                        lineDash: null,
                      },
                    },
                  },
                  line: {
                    style: { lineWidth: 1, stroke: '#708186' },
                  },
                },
              },
            },
          }}
          legend={
            appID
              ? false
              : {
                  itemName: {
                    formatter(text) {
                      const appName = lineChartData?.data.find(
                        (d) => d?.uuid === text
                      )?.app;
                      return appName || text;
                    },
                    style: { fill: light ? undefined : '#F2FFFE' },
                  },
                  position: 'bottom-left',
                }
          }
          xAxis={{
            nice: true,
            label: {
              offset: 16,
              style: light
                ? undefined
                : {
                    fill: '#F2FFFE',
                    fontSize: 12,
                  },
              formatter: (date: any) => dateFormat(date, dateTime.value),
            },
            tickLine: {
              style: {
                lineWidth: 0,
              },
            },
          }}
          yAxis={{
            label: {
              formatter: (v: any) => counterFormat(v),
              style: light
                ? undefined
                : {
                    fill: '#F2FFFE',
                    fontSize: 12,
                  },
            },
          }}
          tooltip={{
            customContent: (title: any, items: any) => {
              const format = (date: string) =>
                moment(date).format('D MMM YYYY  |  H:mm');
              return (
                <div className='py-2 text-center'>
                  <p className='mb-3'>{format(title)}</p>
                  <div className='flex flex-col gap-3'>
                    {items.map((item: any, index: number) => (
                      <div
                        key={index.toString()}
                        className='flex items-center justify-between'
                      >
                        <div className='flex items-center pr-4'>
                          <span
                            style={{
                              backgroundColor: item?.color,
                              width: 10,
                              height: 10,
                            }}
                            className='mr-1 rounded-full'
                          />
                          <span>{item?.data?.app}</span>
                        </div>
                        <span>
                          {`${item?.data?.value}`.replace(
                            /\d{1,3}(?=(\d{3})+$)/g,
                            (s) => `${s},`
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            },
          }}
          appendPadding={[10, 0, 20, 0]}
        />
      </div>
    </div>
  );
};

export default DashboardRequestsLineChart;
