import { SortType } from '@/utils/models/Finding';
import { useCallback } from 'react';
import {
  JsonParam,
  QueryParamConfig,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const sortDefaultValue = withDefault(JsonParam, {});

interface UseSortQueryParam {
  updateType?: UrlUpdateType;
}

function useSortQueryParam(props?: UseSortQueryParam) {
  const updateType: UrlUpdateType = props?.updateType || 'push';

  const [{ sort }, setQueryParams] = useQueryParams<{
    sort: QueryParamConfig<SortType>;
  }>({
    sort: sortDefaultValue,
  });

  const setSort = useCallback(
    (newSort?: SortType) => {
      setQueryParams({ sort: newSort }, updateType);
    },
    [setQueryParams, updateType]
  );

  return { sort, setSort };
}

export default useSortQueryParam;
