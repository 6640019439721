import SeverityTag from '@/components/Tag/SeverityTag';
import {
  SeveritiesFindingType,
  SeverityFindingType,
} from '@/utils/models/Finding';
import { Tooltip } from 'antd';

const ItemSeveritiesOverview = ({
  severities,
  showAllSeverities = true,
  onClick,
}: {
  severities: SeveritiesFindingType;
  showAllSeverities?: boolean;
  onClick?: () => void;
}) => {
  const SeverityCount = ({
    count,
    severity,
  }: {
    count?: number;
    severity: SeverityFindingType;
  }) => {
    const showSeverityCount = showAllSeverities || count;

    return (
      <div
        className={`${count ? 'grayscale-0' : 'grayscale opacity-50'} ${
          showSeverityCount ? 'block' : 'hidden'
        }`}
      >
        <SeverityTag showCount compact severity={severity} count={count} />
      </div>
    );
  };

  const isEmpty =
    !severities?.CRITICAL &&
    !severities?.HIGH &&
    !severities?.MEDIUM &&
    !severities?.LOW &&
    !severities?.INFORMATIONAL;

  const isVisible = showAllSeverities || !isEmpty;
  const isClickable = onClick && isVisible;

  return (
    <div className='flex'>
      <Tooltip
        className={`h-7 
        ${isClickable ? 'cursor-pointer rounded-md p-1 bg-gray-50 hover:bg-gray-100 hover:scale-105 duration-150' : ''}`}
        title={isVisible ? 'Findings' : undefined}
      >
        <div
          className={`flex items-center gap-2`}
          onClick={(evt) => {
            if (isClickable) {
              evt.stopPropagation();
              onClick();
            }
          }}
        >
          <SeverityCount count={severities?.CRITICAL} severity='critical' />
          <SeverityCount count={severities?.HIGH} severity='high' />
          <SeverityCount count={severities?.MEDIUM} severity='medium' />
          <SeverityCount count={severities?.LOW} severity='low' />
          <SeverityCount
            count={severities?.INFORMATIONAL}
            severity='information'
          />
        </div>
      </Tooltip>
    </div>
  );
};
export default ItemSeveritiesOverview;
