import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Form, Checkbox } from 'antd';
import { getColSize, getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const ToggleField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  return (
    !field.hidden && (
      <Form.Item
        key={field.attribute}
        name={field.attribute}
        label={field.name}
        rules={rules}
        valuePropName='checked'
        tooltip={field.description}
        className={`${getColSize(field.colSize || 'small')}`}
      >
        <Checkbox
          className={`left-align-item ${getFieldSize(field.fieldSize || 'small')}`}
          disabled={field.disabled}
        />
      </Form.Item>
    )
  );
};

export default ToggleField;
