import './alert.css';
import { Collapse, Tooltip } from 'antd';
import { MdOutlineInfo } from 'react-icons/md';

const { Panel } = Collapse;

const AlertSectionWrapper = ({
  children,
  info,
  title,
  showTitle = true,
  extra,
  isDefaultCollapseOpen = true,
}: {
  children: React.ReactNode;
  info?: string;
  title?: string;
  showTitle?: boolean;
  extra?: React.ReactNode;
  isDefaultCollapseOpen?: boolean;
}) => {
  const header = (
    <div className='flex items-center'>
      <h6 className={`text-lg ${showTitle ? 'visible' : 'invisible'}`}>
        {title}
      </h6>

      {info && (
        <Tooltip title={info}>
          <MdOutlineInfo className='ml-1' size={16} />
        </Tooltip>
      )}
    </div>
  );

  const content = <div className='rounded-xs bg-white p-4'>{children}</div>;

  if (!isDefaultCollapseOpen) {
    return (
      <Collapse
        className='alerting-section-collapse'
        defaultActiveKey={isDefaultCollapseOpen ? ['1'] : []}
        ghost
      >
        <Panel forceRender header={header} key='1'>
          {content}
        </Panel>
      </Collapse>
    );
  }

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between items-end'>
        {header}

        {extra && <div>{extra}</div>}
      </div>

      {content}
    </div>
  );
};

export default AlertSectionWrapper;
