import { Button, Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@/components/Section/Container';
import { ReactNode, useEffect, useState } from 'react';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import {
  useAddGithubIntegrationMutation,
  useLazyGetGithubIntegrationFieldsQuery,
  useLazyGetGithubIntegrationQuery,
  useUpdateGithubIntegrationMutation,
} from '@/utils/services/integration/integration-endpoints';
import FieldType from '@/utils/models/FieldType';
import { FieldBuilder } from '@/components/Form/Fields';
import useQuery from '@/utils/hooks/useQuery';
import { IntegrationClass } from '@/utils/models/Integration';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';

const githubLogo = require('@/assets/images/github_repository.png');

const GithubIntegration = () => {
  const { orgID } = useParams<{
    orgID: string;
  }>();
  const history = useHistory();
  const query = useQuery();
  const installCode = query.get('code');
  const installID = query.get('installation_id');
  const [githubIntegrationFields, setGithubIntegrationFields] = useState<
    undefined | FieldType[]
  >();
  const [githubIntegration, setGithubIntegration] =
    useState<IntegrationClass>();
  const [form] = Form.useForm();
  const [getGithubFields, { isLoading: isGithubFieldsLoading }] =
    useLazyGetGithubIntegrationFieldsQuery();
  const [getGithubIntegration, { isLoading: isGithubIntegrationLoading }] =
    useLazyGetGithubIntegrationQuery();
  const [addGithubIntegration] = useAddGithubIntegrationMutation();
  const [updateGithubIntegration] = useUpdateGithubIntegrationMutation();

  useEffect(() => {
    if (orgID && !githubIntegrationFields) {
      getGithubFields({ orgID }).then((r) =>
        setGithubIntegrationFields(r.data)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID]);

  useEffect(() => {
    if (orgID && installID) {
      getGithubIntegration({ orgID, installID }).then((response) => {
        if (response.data) setGithubIntegration(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, installID]);

  // Integration/install exists.
  useEffect(() => {
    if (githubIntegration && installID && installCode) {
      updateGithubIntegration({ orgID, installID, code: installCode })
        .unwrap()
        .then((r) =>
          history.push(
            `/organisations/${orgID}/integrations/existing/${r.UUID}`
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [githubIntegration, installID, orgID]);

  const handleSubmit = (data: any) => {
    if (githubIntegrationFields && installID && installCode) {
      addGithubIntegration({
        orgID,
        name: data.name,
        appID: data.app_uuid,
        code: installCode,
        installID,
        scanFrequency: data.scan_frequency,
        deleteArchivedRepoApi: data.delete_archived_repo_api,
        enableAiEnrichment: data.enable_ai_enrichment,
        ignoreArchivedRepos: data.ignore_archived_repos,
      })
        .unwrap()
        .then((r) =>
          history.push(
            `/organisations/${orgID}/integrations/existing/${r.UUID}`
          )
        );
    }
  };

  useEffect(() => {
    if (!githubIntegrationFields) return;

    const values: any = {};
    githubIntegrationFields.forEach((field: FieldType) => {
      values[field.attribute] = field.value;
    });

    form.setFieldsValue(values);
  }, [githubIntegrationFields, form]);

  if (isGithubFieldsLoading || isGithubIntegrationLoading || !orgID)
    return <FullScreenLoader />;

  const githubFields = () => {
    if (githubIntegrationFields) {
      const fieldBuilder = new FieldBuilder(form);
      const formFields: ReactNode[] = [];
      // Make sure fields sent to post come from fields returned from API.
      githubIntegrationFields.forEach((field: FieldType) => {
        formFields.push(fieldBuilder.getFormItem({ ...field }));
      });
      return formFields;
    }
  };

  // integration/install doesn't exist
  if (!isGithubIntegrationLoading && !githubIntegration && orgID) {
    return (
      <Container>
        <div className='md:container md:mx-auto bg-white rounded-xl shadow-md items-center text-center'>
          <section className='px-8 py-8'>
            <div className='flex flex-row justify-center mb-4'>
              <img
                alt='Github Logo'
                src={githubLogo}
                className='mr-2 mt-1'
                style={{ maxHeight: '20px' }}
              />
              <div className='text-xl font-semibold'>Github Repo Scanning</div>
            </div>
            <Form
              layout='vertical'
              form={form}
              onFinish={handleSubmit}
              scrollToFirstError
              className='grid grid-cols-4 gap-4'
            >
              {githubFields()}
            </Form>
            <Button onClick={() => form.submit()}>Submit</Button>
          </section>
        </div>
      </Container>
    );
  }

  return <></>;
};

export default withAuthenticationRequired(GithubIntegration);
