import { getLogoPathByName } from '../constants/listOfImagesPaths';

export type GitHubIncidentContextType = {
  actor: string;
  actorId: string;
  contextType: 'github_action_upload';
  eventName: string;
  file_urls: string[];
  headCommitUsername: string;
  private: true;
  ref: string;
  repositoryId: string;
  repositoryName: string;
  repositoryOwner: string;
  runId: string;
  sha: string;
  timeTriggered: number;
  timeTriggeredUTCString: string;
  workflowRef: string;
};

export type IncidentPolicyContextType = {
  eventUUIDs: string[];
  findingTags: string[];
  incidentPolicyName: string;
  resources: string[];
  snapshotS3Bucket: string;
  snapshotS3KeyPrefix: string;
};

export type IncidentContextType =
  | GitHubIncidentContextType
  | IncidentPolicyContextType
  | null;

export class IncidentContextClass {
  data: IncidentContextType;

  constructor(ctx: IncidentContextType) {
    this.data = ctx;
  }

  get isGitHubIncidentContextType(): boolean {
    return (
      this.data !== null &&
      this.data !== undefined &&
      'contextType' in this.data &&
      this.data.contextType === 'github_action_upload'
    );
  }

  get isIncidentPolicyContextType(): boolean {
    return (
      this.data !== null &&
      this.data !== undefined &&
      'incidentPolicyName' in this.data
    );
  }

  get triggerContext(): { name: string; logo: string } {
    if (this.isGitHubIncidentContextType) {
      const context = this.data as GitHubIncidentContextType;
      return {
        name: 'GitHub',
        logo: getLogoPathByName(context.contextType),
      };
    }
    return { name: 'FireTail', logo: getLogoPathByName() };
  }
}
