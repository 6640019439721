import { useEffect, useState, useMemo } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { sortBy } from 'lodash';
import { useGetAggregateLatLonDataQuery } from '@/utils/services/org/org-endpoints';
import Loader from '@/components/Loader/Loader';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';

const geoUrl = 'https://unpkg.com/world-atlas@1.1.4/world/110m.json';

const DashboardRequestsMapChart = ({ light }: { light?: boolean }) => {
  const { params, options } = useGetEndpointSettings();
  const {
    currentData: mapChartData,
    isLoading,
    isFetching,
  } = useGetAggregateLatLonDataQuery(params, options);
  const [maxValue, setMaxValue] = useState<number>();

  useEffect(() => {
    if (!mapChartData) return;

    if (mapChartData.length > 0) {
      const sortedCities = sortBy(mapChartData, (c) => -c.value);
      setMaxValue(sortedCities[0].value);
    }
  }, [mapChartData]);

  const popScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, maxValue || 0])
        .range([0, 18]),
    [maxValue]
  );

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex items-center gap-4'>
        <span className={`${light ? '' : 'text-anti-flash'} text-xl`}>
          API requests grouped by top 1000 locations
        </span>
        <Loader spinning={isLoading || isFetching} />
      </div>
      <div
        className='animate-fade-up opacity-0'
        style={{ animationDelay: '0.03s', animationFillMode: 'forwards' }}
      >
        <ComposableMap projection='geoMercator' height={400}>
          <ZoomableGroup center={[20, 15]} zoom={0.8}>
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill='#343E4C'
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                ))
              }
            </Geographies>
            {mapChartData &&
              maxValue &&
              mapChartData.map(({ lon, lat, value }, index) => {
                return (
                  <Marker key={index.toString()} coordinates={[lon, lat]}>
                    <circle fill='#227AFF' r={popScale(value)} />
                  </Marker>
                );
              })}
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </div>
  );
};

export default DashboardRequestsMapChart;
