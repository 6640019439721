import Item from '@/components/List/Item';
import FindingsSelectStatus from '../../FindingsSelectStatus';
import FindingsSelectSeverity from '../../FindingsSelectSeverity';
import { FindingClass } from '@/utils/models/Finding';
import { useHistory } from 'react-router-dom';

const FindingInspectorFooter = ({ finding }: { finding?: FindingClass }) => {
  const history = useHistory();

  const orgUUID = finding?.g_orgUUID || finding?.finding_orgUUID;
  const appUUID =
    finding?.api?.api_appUUID || finding?.specification?.collection_appUUID;
  const apiUUID =
    finding?.api?.UUID || finding?.specification?.collection_apiUUID;
  const specificationUUID = finding?.specification?.UUID;

  return (
    <div className='flex gap-6 justify-between items-center pl-5 pr-3 py-4'>
      <div className='flex gap-6 items-center'>
        {finding?.api && orgUUID && appUUID && apiUUID && (
          <div
            className='cursor-pointer hover:shadow-sm hover:bg-gray-50 transition-all p-1 rounded-md'
            onClick={() =>
              history.push(
                `/organisations/${orgUUID}/applications/${appUUID}/apis/${apiUUID}`
              )
            }
          >
            <Item.LogoAndName
              logo={finding.api?.avatar}
              name={finding.api?.name}
            />
          </div>
        )}

        {finding?.specification &&
          orgUUID &&
          appUUID &&
          apiUUID &&
          specificationUUID && (
            <div
              className='cursor-pointer hover:shadow-sm hover:bg-gray-50 transition-all px-1 py-2 rounded-md'
              onClick={() =>
                history.push(
                  `/organisations/${orgUUID}/applications/${appUUID}/apis/${apiUUID}/specifications/${specificationUUID}`
                )
              }
            >
              <Item.Span
                text={`Specification: ${finding?.specification?.name}`}
              />
            </div>
          )}
      </div>

      {finding && (
        <div className='flex items-center gap-x-2'>
          <FindingsSelectStatus
            status={finding.status}
            findingID={finding.UUID}
          />

          <FindingsSelectSeverity
            severity={finding.severity}
            findingID={finding.UUID}
          />
        </div>
      )}
    </div>
  );
};

export default FindingInspectorFooter;
