import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';
import { interceptAxiosError } from './base-reducer';

const skipInterceptAxiosError: (err: AxiosError) => boolean = (err) => {
  // When the request was canceled before returning a response from the server
  if (err.code === 'ERR_CANCELED') return true;

  // When the request was filled in some component of the dashboard
  if (err?.config?.url?.endsWith('/data/query')) return true;

  // When github install doesn't find an integration
  if (
    err.config?.url?.includes('/github/install/') &&
    // @ts-ignore
    err.response?.data?.message === 'integration not found'
  ) {
    return true;
  }

  const regexToGetCollection =
    /organisations\/[a-fA-F0-9-]+\/collections\/[a-fA-F0-9-]+/g;
  const matchAllRegexToGetCollection =
    err?.config?.url?.matchAll(regexToGetCollection);
  if (
    matchAllRegexToGetCollection &&
    Array.from(matchAllRegexToGetCollection).length > 0 &&
    err.config?.method?.toLowerCase() === 'get'
  )
    return true;

  const regexToGetIntegration =
    /organisations\/[a-fA-F0-9-]+\/integrations\/[a-fA-F0-9-]+/g;
  const matchAllRegexToGetIntegration = err?.config?.url?.matchAll(
    regexToGetIntegration
  );
  if (
    matchAllRegexToGetIntegration &&
    Array.from(matchAllRegexToGetIntegration).length > 0 &&
    err.config?.method?.toLowerCase() === 'get'
  )
    return true;

  const regexToGetAPI =
    /organisations\/[a-fA-F0-9-]+\/applications\/[a-fA-F0-9-]+\/apis\/[a-fA-F0-9-]+/g;
  const matchAllRegexToGetAPI = err?.config?.url?.matchAll(regexToGetAPI);
  if (
    matchAllRegexToGetAPI &&
    Array.from(matchAllRegexToGetAPI).length > 0 &&
    err.config?.method?.toLowerCase() === 'get' &&
    err?.config?.url?.endsWith('/logging') === false
  )
    return true;

  // ntae = not throw an error
  if (err?.config?.url?.endsWith('ntae')) return true;

  // @ts-ignore
  if (err?.response?.data?.message) {
    // @ts-ignore
    console.error(`API error message: ${err.response.data.message}`);
  }

  return false;
};

const axiosBaseQuery =
  (): BaseQueryFn<any> =>
  async (params, { dispatch, signal }) => {
    try {
      // abort multiplos requests
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => source.cancel());
      const cancelToken = source.token;

      const additionalParams = { cancelToken };

      const fullParams =
        typeof params === 'string'
          ? { url: params, ...additionalParams }
          : { ...params, ...additionalParams };

      axios.defaults.baseURL = window.appConfig.REACT_APP_SERVER_DOMAIN;
      const result = await axios(fullParams);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (!skipInterceptAxiosError(err)) {
        dispatch(interceptAxiosError(err));
      }

      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const baseApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Organisations',
    'Apis',
    'Applications',
    'Integrations',
    'Specifications',
    'Findings',
    'Incidents',
    'IncidentPolicy',
    'Events',
    'Alerts',
    'Tokens',
    'Members',
    'Filters',
    'Actions',
    'Reports',
    'Quotas',
    'Links',
  ],
  endpoints: (build) => ({}),
});

export default baseApi;
