import { store } from '@/app/store';
import { FindingsBulkResponse, findingEndpoints } from './finding-endpoints';

export const getFindingsOverview = async ({
  orgID,
  apiUUIDItems,
}: {
  orgID: string;
  apiUUIDItems: string[];
}) => {
  const result = await store.dispatch<any>(
    findingEndpoints.endpoints.getFindingsBulk.initiate({
      orgID,
      apiUUIDItems,
    })
  );
  return result.data as FindingsBulkResponse;
};
