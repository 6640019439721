import moment from 'moment';
import { IntegrationAvailableClass } from './IntegrationAvailableInterface';

export interface IntegrationInterface {
  UUID: string;
  category: string;
  dateAddedInMicroSeconds: number;
  enabled: boolean;
  integrationUUID: string;
  name: string;
  status: string;
  itemType: string;
  integration_type: string;
  g_orgUUID: string;
  inputs?: any;
  plan_upgrade_required?: boolean;
}

type ExtraDataType = {
  available?: IntegrationAvailableClass;
};

export class IntegrationClass implements IntegrationInterface {
  UUID: string;
  category: string;
  dateAddedInMicroSeconds: number;
  enabled: boolean;
  integrationUUID: string;
  name: string;
  status: string;
  itemType: string;
  integration_type: string;
  g_orgUUID: string;
  inputs?: any;
  key: string;
  available?: IntegrationAvailableClass;
  plan_upgrade_required?: boolean;

  constructor(data: IntegrationInterface, extraData?: ExtraDataType) {
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.enabled = data.plan_upgrade_required || data.enabled;
    this.integrationUUID = data.integrationUUID;
    this.name = data.name;
    this.status = data.status;
    this.itemType = data.itemType;
    this.integration_type = data.integration_type;
    this.g_orgUUID = data.g_orgUUID;
    this.inputs = data.inputs;
    this.plan_upgrade_required = data.plan_upgrade_required;

    if (extraData?.available) {
      const available = structuredClone(extraData.available);
      available?.fields?.forEach((field) => {
        field.value =
          data.inputs && data.inputs.hasOwnProperty(field.attribute)
            ? data.inputs[field.attribute]
            : null;
      });
      this.available = available;
    }

    this.category = extraData?.available
      ? extraData?.available?.integration_category
      : data.category;
  }

  get created(): string {
    return `${moment(this.dateAddedInMicroSeconds / 1000).format(
      'll'
    )} | ${moment(this.dateAddedInMicroSeconds / 1000).format('HH:mm')}`;
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }

  get hasNotificationHistory(): boolean {
    return (
      this.category === 'notification' ||
      this.available?.integration_type === 'WIZSCAN'
    );
  }
}
