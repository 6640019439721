import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Form, FormInstance, InputNumber } from 'antd';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const AlertSensitivity = ({
  alert,
  forceType,
  formInstance,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
  isReadOnly?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  useEffect(() => {
    if (isEdition) return;

    formInstance.setFieldsValue({
      config: {
        sensitivity: 2,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertType = forceType || alert?.type;
  if (alertType === 'static' || (!alert && isEdition)) return <></>;

  const title = 'Sensitivity';
  const info = `The sensitivity of the anomaly detection. Higher sensitivity values detect smaller anomalies; lower sensitivity reduces false positives but may only detect significant anomalies.`;

  return (
    <AlertSectionWrapper title={title} info={info}>
      <div className='grid grid-cols-6 gap-6'>
        <Form.Item
          label='Alert sensitivity level'
          name={['config', 'sensitivity']}
          className='col-span-2'
          tooltip='Adjust the sensitivity level of the anomaly detection.'
          rules={[
            {
              required: true,
              message: 'Sensitivity level is required',
            },
            {
              type: 'number',
              max: 1000,
              message: 'Sensitivity level maximum is 1000',
            },
            {
              type: 'number',
              min: 0.00000001,
              message: 'Sensitivity level minimum is 0.00000001',
            },
          ]}
        >
          <InputNumber
            step='0.1'
            style={{ width: '100%' }}
            min={1e-5}
            max={1000}
            placeholder='2'
            disabled={isReadOnly}
          />
        </Form.Item>
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertSensitivity;
