export interface ReportInterface {
  UUID: string;
  description: string;
  icon_url: string;
  report_name: string;
  supports_finding_filters: boolean;
  title: string;
}

export class ReportClass {
  UUID: string;
  description: string;
  icon_url: string;
  report_name: string;
  supports_finding_filters: boolean;
  title: string;

  constructor(data: ReportInterface) {
    this.UUID = data.UUID;
    this.description = data.description;
    this.icon_url = data.icon_url;
    this.report_name = data.report_name;
    this.supports_finding_filters = data.supports_finding_filters;
    this.title = data.title;
  }
}
