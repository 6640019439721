import { EventClass } from '@/utils/models/Event';
import List from '../List';
import Item from '../List/Item';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import IncidentsContext from '../Incidents/IncidentsContext';
import { IncidentClass } from '@/utils/models/Incident';
import EventsDrawer from './Item/EventsDrawer';
import useRole from '@/utils/hooks/useRole';

type ListingMode = { show: boolean; ignoreMode: boolean };

const EventsListing = ({
  events,
  loading = false,
  grid = { show: true, ignoreMode: false },
  table = { show: true, ignoreMode: false },
  incident,
  currentPageMarker,
}: {
  events?: EventClass[];
  loading?: boolean;
  grid?: ListingMode;
  table?: ListingMode;
  incident?: IncidentClass;
  currentPageMarker?: string | null;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToGetEvent } = useRole();
  const history = useHistory();
  const location = useLocation();

  const handleSelectEvent = (event: EventClass) => {
    history.replace({
      ...location,
      pathname: `/organisations/${orgID}/posture-management/events/${event.UUID}`,
    });
  };

  const handleRedirectToFindings = (event: EventClass) => {
    const url = encodeURI(
      `/organisations/${orgID}/posture-management/findings?filters=[{"field":"events","values":["${event.UUID}"]}]`
    );
    history.push(url);
  };

  const columns: ColumnsType<EventClass> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, event) =>
        hasPermissionToGetEvent ? (
          <button
            onClick={() => handleSelectEvent(event)}
            className='text-base text-blue-600 font-semibold text-left'
          >
            {event.mapping?.title || event.code}
          </button>
        ) : (
          event.mapping?.title || event.code
        ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (_, event) => (
        <Item.Description>{event.mapping?.description}</Item.Description>
      ),
    },
    {
      title: 'Related Findings',
      key: 'findings',
      width: 500,
      render: (_, event) => (
        <Item.SeveritiesOverview
          severities={event.initialFindingSeverities}
          showAllSeverities={false}
          onClick={() => handleRedirectToFindings(event)}
        />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, event) => (
        <Item.Date
          created={event.created}
          createdFromNow={event.createdFromNow}
        />
      ),
    },
  ];

  return (
    <>
      <EventsDrawer
        redirectToFindings={(event) => handleRedirectToFindings(event)}
      />

      <List.InfiniteScroll
        dataSource={events || []}
        currentPageMarker={currentPageMarker}
        loading={loading}
        updateType='replaceIn'
      >
        {grid.show ? (
          <List.Grid
            item={{
              render: (event: EventClass) => {
                const title = event.mapping?.title || event.code;
                const description = event.mapping?.description;

                return (
                  <Item.Root
                    key={event.UUID}
                    onClick={
                      hasPermissionToGetEvent
                        ? () => handleSelectEvent(event)
                        : undefined
                    }
                    id={event.UUID}
                  >
                    <Item.Header>
                      <Item.Title>{title}</Item.Title>
                      <Item.Description>{description}</Item.Description>
                    </Item.Header>

                    <Item.SeveritiesOverview
                      showAllSeverities={false}
                      severities={event.initialFindingSeverities}
                      onClick={() => handleRedirectToFindings(event)}
                    />

                    <Item.Footer>
                      <Item.Date
                        created={event.created}
                        createdFromNow={event.createdFromNow}
                      />
                    </Item.Footer>
                  </Item.Root>
                );
              },
            }}
            loading={loading}
            ignoreMode={grid.ignoreMode}
          />
        ) : (
          <></>
        )}

        {table.show ? (
          <List.Table
            columns={columns}
            loading={loading}
            ignoreMode={table.ignoreMode}
            expandable={{
              expandedRowRender: () => {
                return <IncidentsContext incident={incident} />;
              },
              rowExpandable: () => !!incident,
            }}
          />
        ) : (
          <></>
        )}
      </List.InfiniteScroll>
    </>
  );
};

export default EventsListing;
