import { MdOutlineLaunch } from 'react-icons/md';

const ItemLinkRedirect = ({
  label,
  href,
}: {
  label?: string | React.ReactNode;
  href: string;
}) => {
  return (
    <span className='flex gap-1'>
      <a
        href={href}
        className='underline hover:underline hover:text-second'
        target='_blank'
        rel='noreferrer'
      >
        {label || href}
      </a>
      <span className='pt-1'>
        <MdOutlineLaunch size={12} />
      </span>
    </span>
  );
};

export default ItemLinkRedirect;
