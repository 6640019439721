import { store } from '@/app/store';
import orgEndpoints from './org-endpoints';
import { RequestTagInterface } from '@/utils/models/RequestTag';

export const getRequestTags = async (orgID: string) => {
  const response = await store.dispatch<any>(
    orgEndpoints.endpoints.getRequestTags.initiate({
      orgID,
    })
  );
  const tags = response.data as RequestTagInterface[];
  return tags;
};
