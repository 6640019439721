import Findings from '@/components/Findings';

const FindingsTab = () => {
  return (
    <div>
      <Findings.Listing />
    </div>
  );
};

export default FindingsTab;
