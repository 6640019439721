export interface RoleInterface {
  email: string;
  permissions: string[];
  roleID: number;
  rolaName: string;
}

type MapPermissionsType = { [key: string]: { [key: string]: boolean } };

export class RoleClass implements RoleInterface {
  email: string;
  permissions: string[];
  roleID: number;
  rolaName: string;

  constructor(data: RoleInterface) {
    this.email = data.email;
    this.permissions = data.permissions;
    this.roleID = data.roleID;
    this.rolaName = data.rolaName;
  }

  get #per() {
    return this.permissions.reduce((all: MapPermissionsType, item) => {
      const [role, action] = item.split(':');
      all[role] = all[role] || {};
      all[role][action] = true;
      return all;
    }, {});
  }

  hasPermissionTo() {
    return {
      resend: {
        email: !!this.#per?.email?.resend,
      },
      create: {
        organisation: !!this.#per?.org?.create, // unused
        application: !!this.#per?.app?.create,
        applicationToken: !!this.#per?.app_token?.create,
        api: !!this.#per?.api?.create,
        apiToken: !!this.#per?.api_token?.create,
        member: !!this.#per?.member?.create,
        integration: !!this.#per?.integrations?.create,
        specification: !!this.#per?.collections?.create,
        specificationVersion: !!this.#per?.collections_version?.create,
        alerting: !!this.#per?.alerting?.create,
        incidentPolicy: !!this.#per?.incident_policies?.create,
        filterConfiguration: !!this.#per?.filters?.post,
        action: !!this.#per?.actions?.create,
      },
      update: {
        organisation: !!this.#per?.org?.update, // unused
        application: !!this.#per?.app?.update, // unused
        applicationToken: !!this.#per?.app_token?.update, // unused
        api: !!this.#per?.api?.update, // unused
        apiToken: !!this.#per?.api_token?.update, // unused
        member: !!this.#per?.member?.update,
        integration: !!this.#per?.integrations?.update,
        specification: !!this.#per?.collections?.update,
        specificationVersion: !!this.#per?.collections_version?.update, // unused
        finding: !!this.#per?.findings?.update,
        incident: !!this.#per?.incidents?.update,
        incidentPolicy: !!this.#per?.incident_policies?.update,
        alerting: !!this.#per?.alerting?.update,
        billingDetails: !!this.#per?.billing?.updateDetails,
        billingPlan: !!this.#per?.billing?.updatePlan,
        action: !!this.#per?.actions?.update,
      },
      delete: {
        organisation: !!this.#per?.org?.delete, // unused
        application: !!this.#per?.app?.delete,
        applicationToken: !!this.#per?.app_token?.delete,
        api: !!this.#per?.api?.delete,
        apiToken: !!this.#per?.api_token?.delete,
        member: !!this.#per?.member?.delete,
        integration: !!this.#per?.integrations?.delete,
        specification: !!this.#per?.collections?.delete,
        specificationVersion: !!this.#per?.collections_version?.delete, // unused
        incidentPolicy: !!this.#per?.incident_policies?.delete,
        filterConfiguration: !!this.#per?.filters?.delete,
        alerting: !!this.#per?.alerting?.delete,
        action: !!this.#per?.actions?.delete,
      },
      list: {
        organisation: !!this.#per?.org?.list, // unused
        application: !!this.#per?.app?.list,
        applicationToken: !!this.#per?.app_token?.list,
        api: !!this.#per?.api?.list,
        apiToken: !!this.#per?.api_token?.list,
        member: !!this.#per?.member?.list,
        integration: !!this.#per?.integrations?.list,
        log: !!this.#per?.log?.list, // unused
        specification: !!this.#per?.collections?.list,
        specificationVersion: !!this.#per?.collections_version?.list, // unused
        finding: !!this.#per?.findings?.list,
        event: !!this.#per?.events?.list,
        incident: !!this.#per?.incidents?.list,
        incidentPolicy: !!this.#per?.incident_policies?.list,
        notificationHistory: !!this.#per?.notification_history?.list, // unused
        alerting: !!this.#per?.alerting?.list,
        action: !!this.#per?.actions?.list,
        report: !!this.#per?.reporting?.list,
      },
      get: {
        organisation: !!this.#per?.org?.get,
        application: !!this.#per?.app?.get,
        applicationToken: !!this.#per?.app_token?.get, // unused
        api: !!this.#per?.api?.get,
        apiToken: !!this.#per?.api_token?.get, // unused
        member: !!this.#per?.member?.get, // unused
        integration: !!this.#per?.integrations?.get,
        specification: !!this.#per?.collections?.get,
        specificationVersion: !!this.#per?.collections_version?.get, // unused
        finding: !!this.#per?.findings?.get,
        event: !!this.#per?.events?.get, // unused
        incident: !!this.#per?.incidents?.get, // unused
        incidentPolicy: !!this.#per?.incident_policies?.get,
        notificationHistory: !!this.#per?.notification_history?.get, // unused
        filterConfiguration: !!this.#per?.filters?.get, // unused
        alerting: !!this.#per?.alerting?.get,
        alertingPreview: !!this.#per?.alerting?.preview,
        action: !!this.#per?.actions?.get,
        report: !!this.#per?.reporting?.get,
      },
    };
  }
}
