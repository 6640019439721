import FieldType from './FieldType';
import { IntegrationsQuotaType } from './Quota';

type ActionButtonProps = {
  text?: string;
  openURL?: string;
  connectSlack?: boolean;
};
export interface IntegrationAvailableInterface {
  UUID: string;
  description: string;
  enabled: boolean;
  fields?: Array<FieldType>;
  has_enabled_toggle?: boolean;
  hide_enable_on_create?: boolean;
  has_regions: boolean;
  has_submit: boolean;
  help_url: string;
  integration_category: string;
  integration_type: string;
  logoURL: string;
  logo_url: string;
  plan_upgrade_required?: boolean;
  name: string;
  toggle_index?: number;
  supports_actions?: boolean;
  notification_type?: string;
  managed_action?: boolean;
  custom_action_button?: ActionButtonProps;
}

type ExtraDataType = {
  quotas?: IntegrationsQuotaType;
};

export class IntegrationAvailableClass
  implements IntegrationAvailableInterface
{
  UUID: string;
  description: string;
  enabled: boolean;
  fields?: Array<FieldType>;
  has_enabled_toggle?: boolean;
  has_regions: boolean;
  has_submit: boolean;
  help_url: string;
  integration_category: string;
  integration_type: string;
  logoURL: string;
  logo_url: string;
  name: string;
  toggle_index?: number;
  supports_actions?: boolean;
  notification_type?: string;
  managed_action?: boolean;
  custom_action_button?: ActionButtonProps;
  plan_upgrade_required: boolean;
  quotas?: IntegrationsQuotaType;

  constructor(data: IntegrationAvailableInterface, extraData?: ExtraDataType) {
    this.UUID = data.UUID;
    this.description = data.description;
    this.enabled = data.enabled;
    this.fields = data.fields;
    this.has_enabled_toggle = data.has_enabled_toggle;
    this.has_regions = data.has_regions;
    this.has_submit = data.has_submit;
    this.help_url = data.help_url;
    this.integration_category = data.integration_category;
    this.integration_type = data.integration_type;
    this.logoURL = data.logoURL;
    this.logo_url = data.logo_url;
    this.name = data.name;
    this.toggle_index = data.toggle_index;
    this.supports_actions = data.supports_actions;
    this.notification_type = data.notification_type;
    this.managed_action = data.managed_action;
    this.custom_action_button = data.custom_action_button;
    this.plan_upgrade_required = data.plan_upgrade_required === true;
    this.quotas = extraData?.quotas;
  }

  get category(): string {
    return this.integration_category;
  }

  setFields(fields: Array<FieldType>) {
    this.fields = fields;
  }

  get isDisabled(): boolean {
    return this.managed_action || false;
  }

  get hasReachedQuotaLimit(): boolean {
    if (!this.quotas) return false;

    return (
      typeof this.quotas.current === 'number' &&
      typeof this.quotas.max === 'number' &&
      this.quotas.current >= this.quotas.max
    );
  }
}
