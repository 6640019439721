import { useAppSelector } from '@/app/hooks';
import { selectDashboardPollingIntervalValue } from '@/components/Dashboard/DashboardFilters/DashboardFiltersPollingInterval/dashboardPollingIntervalSlice';
import { selectDashboardFiltersGroups } from '@/components/Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'antd';
import { FieldsData } from '../models/FieldType';
import useDynamicDateTimeQueryParam from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';

function useGetEndpointSettings() {
  const { orgID } = useParams<{ orgID: string }>();
  const pollingInterval: number = useAppSelector(
    selectDashboardPollingIntervalValue
  );
  const filters = useAppSelector(selectDashboardFiltersGroups);
  const { dateTime } = useDynamicDateTimeQueryParam();

  const filtersFieldsData: FieldsData[] = useMemo(() => {
    const fieldsData: FieldsData[] = [];
    filters?.forEach((filter) => {
      filter.groups?.forEach((group) => {
        const index = fieldsData.findIndex((f) => f.field === group.field);
        const values = group.values || [];
        if (index > -1) {
          fieldsData[index].values = Array.from(
            new Set([...(fieldsData[index].values ?? []), ...values])
          );
        } else {
          fieldsData.push({ field: group.field, values });
        }
      });
    });

    return fieldsData;
  }, [filters]);

  const params = useMemo(
    () => ({ orgID, dateTime: dateTime || {}, filters: filters || [] }),
    [orgID, dateTime, filters]
  );

  const isDateTimeValid: boolean = useMemo(() => {
    const { value, start, end } = dateTime || {};

    if (typeof value === 'undefined') return false;

    if (value !== -1) return true;

    if (!start || !end) return false;

    if (moment(start) > moment(end)) {
      Modal.error({ content: 'Start date must be before end date.' });
      return false;
    }

    return true;
  }, [dateTime]);

  const options = useMemo(() => {
    return { pollingInterval, skip: !filters || !isDateTimeValid };
  }, [pollingInterval, filters, isDateTimeValid]);

  return { params, options, filtersFieldsData };
}

export default useGetEndpointSettings;
