import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  selectDashboardPollingInterval,
  setDashboardPollingInterval,
} from './dashboardPollingIntervalSlice';
import Dropdown from '@/components/DataDisplay/Dropdown';
import LIST_OF_POLLING_INTERVALS from '@/utils/constants/listOfPollingIntervals';

interface DashboardFiltersSelectPollingIntervalProps {
  dark?: boolean;
}

const DashboardFiltersSelectPollingInterval = ({
  dark = false,
}: DashboardFiltersSelectPollingIntervalProps) => {
  const dispatch = useAppDispatch();
  const { key } = useAppSelector(selectDashboardPollingInterval);

  const handleSetDashboardPollingInterval = ({
    key: optionKey,
  }: {
    key: string;
  }) => {
    const option = LIST_OF_POLLING_INTERVALS.find((o) => o.key === optionKey);
    if (option) dispatch(setDashboardPollingInterval(option));
  };

  return (
    <div className={`text-left ${dark ? 'text-anti-flash' : 'text-first'}`}>
      <p className='text-xs pb-2'>Polling interval</p>
      <Dropdown
        dark={dark}
        icon={
          <span className='material-symbols-outlined' style={{ fontSize: 16 }}>
            avg_pace
          </span>
        }
        menu={{
          items: LIST_OF_POLLING_INTERVALS.map((p) => ({
            key: p.key,
            label: p.key,
          })),
          onClick: (item) => handleSetDashboardPollingInterval(item),
        }}
        label={
          <span className='capitalize'>
            {LIST_OF_POLLING_INTERVALS.find((i) => i.key === key)?.key}
          </span>
        }
        dropdownRender={(menu) => (
          <div className={dark ? 'dropdown-content-dark' : ''}>{menu}</div>
        )}
      />
    </div>
  );
};

export default DashboardFiltersSelectPollingInterval;
