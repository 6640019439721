import useQuery from '@/utils/hooks/useQuery';
import { useEffect } from 'react';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import {
  INTEGRATION_SLACK_SETUP_COMPLETE,
  INTEGRATION_SLACK_SETUP_ERROR,
} from '@/utils/constants';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';

const SlackInstallPage = () => {
  const query = useQuery();
  const code = query.get('code');
  const error = query.get('error');
  const error_description = query.get('error_description');

  useEffect(() => {
    if (error) {
      window.opener.postMessage(
        {
          key: INTEGRATION_SLACK_SETUP_ERROR,
          message: { error, error_description },
        },
        '*'
      );
      return;
    }

    if (!code) return;

    const postMessageData = {
      key: INTEGRATION_SLACK_SETUP_COMPLETE,
      message: code,
    };

    window.opener.postMessage(postMessageData, '*');
  }, [code, error, error_description]);

  return <FullScreenLoader />;
};

export default withAuthenticationRequired(SlackInstallPage);
