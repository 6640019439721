import { Spin } from 'antd';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useGetAlertPreviewQuery } from '@/utils/services/alerting/alerting-endpoints';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { selectDashboardFiltersGroups } from '@/components/Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import LineChart from '@/components/DataDisplay/LineChart';
import { METRIC_NAME_OPTIONS, METRIC_STAT_OPTIONS } from '@/utils/constants';

interface AlertStaticChartPreviewProps {
  period: number;
  threshold?: number;
  metricName: string;
  metricStat: string;
}

const AlertStaticChartPreview = forwardRef(
  (
    { threshold, period, metricName, metricStat }: AlertStaticChartPreviewProps,
    ref
  ) => {
    const { orgID } = useParams<{ orgID: string }>();

    const metricNameOption = METRIC_NAME_OPTIONS.find(
      (m) => m.value === metricName
    );

    const metricStatOption = METRIC_STAT_OPTIONS.find(
      (m) => m.value === metricStat
    );

    const filtersGroups = useAppSelector(selectDashboardFiltersGroups);

    let isMetricSupportNotValid = false;
    if (metricNameOption?.supportMetricStat) {
      isMetricSupportNotValid =
        !metricNameOption.supportMetricStat.includes(metricStat);
    }

    const skipRequest = isMetricSupportNotValid;

    const {
      currentData: lineChartData,
      isLoading,
      isFetching,
      refetch,
    } = useGetAlertPreviewQuery(
      {
        orgID,
        filters: filtersGroups || [],
        period,
        metricName,
        metricStat,
      },
      { skip: skipRequest }
    );

    useImperativeHandle(ref, () => ({
      refreshData() {
        refetch();
      },
    }));

    const max = useMemo(() => {
      let max;
      if (lineChartData && threshold && Array.isArray(lineChartData?.data)) {
        const maxPoint = Math.max(
          ...lineChartData.data.map((point) => point.value)
        );
        if (maxPoint < threshold) max = threshold + 5;
      }
      return max;
    }, [threshold, lineChartData]);

    const annotations = [
      {
        type: 'line',
        start: ['min', threshold],
        end: ['max', threshold],
        text: {
          content: 'Threshold',
          offsetY: 18,
          style: {
            fill: 'red',
          },
        },
        style: {
          lineDash: [2, 2],
          stroke: 'red',
        },
      },
    ];

    return (
      <Spin spinning={isLoading || isFetching}>
        <h6 className='text-xs text-gray-600 pb-1'>
          {metricNameOption?.description} ({metricStatOption?.label})
        </h6>

        <LineChart
          lineChartData={lineChartData}
          maxValue={max}
          annotations={annotations}
        />
      </Spin>
    );
  }
);

export default AlertStaticChartPreview;
