import { SpecificationVersionInterface } from '@/utils/models/SpecificationVersion';
import { baseApi } from '../base-api';
import { UserClass, UserInterface } from '@/utils/models/User';
import { InviteToOrgInterface } from '@/utils/models/InviteToOrganisation';

interface TokenRegisterParam {
  token: string;
}

interface InviteToOrgParam {
  uuid: string;
}

export const userEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    tokenRegister: build.mutation<
      SpecificationVersionInterface,
      TokenRegisterParam
    >({
      query: ({ token }) => {
        return {
          url: '/aws-marketplace/token/register',
          method: 'POST',
          data: {
            token,
          },
        };
      },
    }),
    getUser: build.query<UserClass, void>({
      query: () => '/me',
      transformResponse: (rawResponse: { user: UserInterface }) =>
        new UserClass(rawResponse.user),
    }),
    getIsBusinessEmail: build.query<{ message: string }, void>({
      query: () => '/user/verify/domain',
      transformErrorResponse: (response: {
        status: number;
        data: { code?: string; message?: string };
      }) => {
        const isBusinessEmail =
          response.data?.code !== 'business_email_required';

        return { ...response, isBusinessEmail };
      },
    }),
    resendUserEmailVerification: build.query<void, void>({
      query: () => '/user/verify/email',
    }),
    getInviteToOrg: build.query<InviteToOrgInterface, InviteToOrgParam>({
      query: ({ uuid }) => `/webhook/${uuid}`,
      transformResponse: (rawResponse: { webhook: InviteToOrgInterface }) =>
        rawResponse.webhook,
    }),
    acceptInviteToOrg: build.mutation<void, InviteToOrgParam>({
      query: ({ uuid }) => ({
        url: `/webhook/${uuid}`,
        method: 'POST',
        data: { accepted: true },
      }),
    }),
  }),
});

export const {
  useTokenRegisterMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyResendUserEmailVerificationQuery,
  useGetInviteToOrgQuery,
  useAcceptInviteToOrgMutation,
  useGetIsBusinessEmailQuery,
  useLazyGetIsBusinessEmailQuery,
} = userEndpoints;
