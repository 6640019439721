import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { AnomalyPreviewType } from '@/utils/models/HistographType';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';

const ShadedLineChart = ({
  height,
  legend,
  lineChartData,
}: {
  lineChartData?: AnomalyPreviewType;
  height?: number;
  legend?: React.ReactNode;
}) => {
  const [data, setData] = useState<AnomalyPreviewType | undefined>();

  useEffect(() => {
    setData(lineChartData);
  }, [lineChartData]);

  const combinedDateData = useMemo(() => {
    if (!data) return [];

    const allDates = [
      ...data.date_data,
      ...data.high_date_data,
      ...data.low_date_data,
    ];
    const uniqueDates = Array.from(new Set(allDates));
    uniqueDates.sort();

    return uniqueDates;
  }, [data]);

  const mapDataToDates = (
    dates: string[],
    dataPoints: number[],
    dateData: string[]
  ) => {
    return dates.map((date) => {
      const index = dateData.indexOf(date);
      return index !== -1 ? dataPoints[index] : null;
    });
  };

  const mappedDataPoints = data
    ? mapDataToDates(combinedDateData, data.data_points, data.date_data)
    : [];
  const mappedHighData = data
    ? mapDataToDates(combinedDateData, data.high, data.high_date_data)
    : [];
  const mappedLowData = data
    ? mapDataToDates(combinedDateData, data.low, data.low_date_data)
    : [];

  const transformedData = {
    labels: combinedDateData.map((d) => moment(d).format('MMM Do H:mm')),
    datasets: [
      {
        label: '',
        data: mappedDataPoints,
        backgroundColor: 'rgb(0, 0, 0)',
        borderColor: 'rgba(0, 0, 0, 1)',
        shadingRange: combinedDateData.map((date, index) => ({
          min: mappedLowData[index] ?? null,
          max: mappedHighData[index] ?? null,
        })),
        pointRadius: 0,
      },
    ],
  };

  const shadowPlugin = useMemo(
    () => ({
      id: 'customShadow',
      beforeDraw: (chart: any) => {
        const {
          ctx,
          scales: { y },
        } = chart;
        ctx.save();
        const meta = chart.getDatasetMeta(0);
        const dataset = chart.data.datasets[0];

        const hasShading = dataset.shadingRange.some(
          (range: any) => range.min !== null || range.max !== null
        );

        if (!hasShading) {
          ctx.restore();
          return;
        }

        ctx.fillStyle = 'rgba(184, 184, 184, 0.5)';
        ctx.beginPath();
        let firstPointFound = false;

        dataset.shadingRange.forEach((range: any, index: number) => {
          const point = meta.data[index];
          if (point && range.min !== null && range.max !== null) {
            if (!firstPointFound) {
              ctx.moveTo(point.x, y.getPixelForValue(range.min));
              firstPointFound = true;
            }
            ctx.lineTo(point.x, y.getPixelForValue(range.min));
          }
        });

        if (!firstPointFound) {
          ctx.restore();
          return;
        }

        for (let i = dataset.shadingRange.length - 1; i >= 0; i--) {
          const range = dataset.shadingRange[i];
          const point = meta.data[i];
          if (point && range.min !== null && range.max !== null) {
            ctx.lineTo(point.x, y.getPixelForValue(range.max));
          }
        }

        ctx.closePath();
        ctx.fill();
        ctx.restore();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combinedDateData]
  );

  const datasetBounds = useMemo(() => {
    if (!data) return { min: 0, max: 0 };

    const allValues = [...data.data_points, ...data.high, ...data.low];
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    return { min, max };
  }, [data]);

  const yGraceValue = useMemo(() => {
    const range = datasetBounds.max - datasetBounds.min;
    const gracePercentage = 0.1;
    const calculatedGrace = range * gracePercentage;
    const minGrace = 20;
    const maxGrace = 50;

    return Math.max(Math.min(calculatedGrace, maxGrace), minGrace);
  }, [datasetBounds]);

  const options = useMemo(
    () => ({
      responsive: true,
      scales: {
        y: {
          beginAtZero: datasetBounds.min >= 0,
          grace: yGraceValue,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }),
    [yGraceValue, datasetBounds]
  );

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    shadowPlugin
  );

  return (
    <div>
      <div style={{ height: height ? height - 30 : undefined }}>
        <Line
          options={options}
          data={transformedData}
          plugins={[shadowPlugin]}
        />
      </div>
      {legend}
    </div>
  );
};

export default ShadedLineChart;
