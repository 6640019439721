import Fields from '@/components/Form/FieldComponents';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import { NotificationHistoryClass } from '@/utils/models/NotificationHistory';
import { Tabs } from 'antd';

const IntegrationNotificationHistoryBody = ({
  notification,
}: {
  notification: NotificationHistoryClass;
}) => {
  const items = [];
  if (notification?.request) {
    items.push({
      label: `Request`,
      key: 'request',
      children: (
        <Fields.CodeBlock
          field={{
            value: JSON.stringify(notification?.request),
            language: 'json',
            inputType: 'code-block',
            name: 'request',
            attribute: 'request',
          }}
        />
      ),
    });
  }

  if (notification?.response) {
    items.push({
      label: (
        <span className='flex items-center gap-2'>
          Response
          <StatusCodeTag code={notification?.response?.status_code} />
        </span>
      ),
      key: 'response',
      children: (
        <Fields.CodeBlock
          field={{
            value: JSON.stringify(notification?.response),
            language: 'json',
            inputType: 'code-block',
            name: 'response',
            attribute: 'response',
          }}
        />
      ),
    });
  }

  return <Tabs items={items} />;
};

export default IntegrationNotificationHistoryBody;
