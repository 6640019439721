import { IntegrationClass } from '@/utils/models/Integration';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IntegrationApiResponse,
  IntegrationData,
  integrationEndpoints,
} from './integration-endpoints';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { integrationsQuotaEndpoint } from '../quota/quota-endpoints';
import { mockIncrementQuota } from '../quota/quota-methods';
import { FieldsData } from '@/utils/models/FieldType';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';

export const mockListIntegrationsAfterAddition = ({
  draft,
  integration,
}: {
  draft: MaybeDrafted<IntegrationData>;
  integration: IntegrationClass;
}) => {
  integration.dateAddedInMicroSeconds = new Date().getTime() * 1000;
  draft.integrations.unshift(integration);
  return draft;
};

export const mockListIntegrationsAfterDeletion = ({
  draft,
  integrationUUID,
}: {
  draft: MaybeDrafted<IntegrationApiResponse>;
  integrationUUID: string;
}) => {
  const index = draft.integration.findIndex(
    (integration) => integration.UUID === integrationUUID
  );
  if (index !== -1) {
    draft.integration.splice(index, 1);
  }
};

export const mockListIntegrationsAfterEdition = ({
  draft,
  integrations,
}: {
  draft: MaybeDrafted<IntegrationApiResponse>;
  integrations: IntegrationClass;
}) => {
  const index = draft.integration.findIndex(
    (item) => item.UUID === integrations.UUID
  );
  draft.integration[index] = integrations;
  return draft;
};

export const mockGetIntegrationAfterEdition = ({
  integration,
}: {
  integration: IntegrationClass;
}) => {
  return {
    ...integration,
    created: integration.created,
    createdFromNow: integration.createdFromNow,
    hasNotificationHistory: integration.hasNotificationHistory,
  };
};

export const addIntegrationQueryFulfilled = (
  response: {
    data: IntegrationClass;
    meta: {} | undefined;
  },
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  const params = sessionStorage.getItem('MY_INTEGRATIONS_PARAMS');
  const storageParams = typeof params === 'string' ? JSON.parse(params) : {};
  dispatch(
    integrationEndpoints.util.updateQueryData(
      'listMyIntegrations',
      storageParams,
      (draft) =>
        mockListIntegrationsAfterAddition({
          draft,
          integration: response.data,
        })
    )
  );

  dispatch(
    integrationsQuotaEndpoint.util.updateQueryData(
      'integrations',
      { orgID: storageParams?.orgID },
      (draft) => mockIncrementQuota(draft)
    )
  );

  setTimeout(() => {
    dispatch(
      integrationEndpoints.util.invalidateTags([
        'Quotas',
        { type: 'Integrations', id: 'LIST' },
      ])
    );
  }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
};

export const isIntegrationData = (
  data: any
): data is IntegrationApiResponse => {
  return (
    data && Array.isArray(data.integration) && typeof data.total === 'number'
  );
};

export const transformApiResponseToInternalData = (
  apiResponse: IntegrationData
): IntegrationApiResponse => {
  return {
    integration: apiResponse.integrations,
    marker: apiResponse.marker,
    total: apiResponse.total,
  };
};

export const formatFiltersToSupportCategories = ({
  filters,
  integrationsAvailable,
}: {
  filters: FieldsData[] | undefined;
  integrationsAvailable: IntegrationAvailableClass[];
}) => {
  if (!filters) return;

  let copy = [...filters];

  const hasFilterCategory = copy?.find((f) => f.field === 'category');
  if (hasFilterCategory) {
    // If the category filter is present, we need to add the integrations UUIDs
    if (hasFilterCategory?.values && hasFilterCategory?.values?.length > 0) {
      hasFilterCategory.values.forEach((category) => {
        // If the category is 'all', we don't need to filter by category
        if (category !== 'all') {
          // Get the integrations UUIDs that match the category
          const integrations = integrationsAvailable.filter(
            (i) => i.category === category
          );
          // Add the integrations UUIDs to the filter
          copy.push({
            field: 'integration_uuid',
            values: integrations.map((i) => i.UUID),
          });
        }
      });
    }

    // Remove the category filter
    copy = copy.filter((f) => f.field !== 'category');
  }

  return copy;
};
