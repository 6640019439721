import Apis from '@/components/Apis';
import Item from '@/components/List/Item';
import { ApiClass } from '@/utils/models/Api';
import { EventContextClass } from '@/utils/models/EventContext';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

const ApiEventContext = ({ context }: { context: EventContextClass }) => {
  const history = useHistory();

  const extraButtons = (api: ApiClass) => {
    return [
      <Button
        key='api'
        onClick={() => {
          history.push(
            `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`
          );
        }}
      >
        <Item.LogoAndName logo={api?.avatar} name={api?.name} />
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const api = new ApiClass(context.created);
    content = <Apis.Details api={api} extra={extraButtons(api)} />;
  }

  if (context.isDeleted) {
    content = <Apis.Details api={new ApiClass(context.before)} />;
  }

  if (context.isChanged) {
    const api = new ApiClass(context.after);
    content = <Apis.Details api={api} extra={extraButtons(api)} />;
  }

  return <div className='-mx-6'>{content}</div>;
};

export default ApiEventContext;
