import { FindingClass } from '@/utils/models/Finding';
import { Collapse } from 'antd';
import { MdOutlineLaunch } from 'react-icons/md';

const FindingOWASP = ({ finding }: { finding?: FindingClass }) => {
  const mapping = finding?.mapping;

  return (
    <div className='lg:pb-6 lg:pr-6 flex flex-col gap-4 -mt-4 pt-4'>
      {mapping?.frameworks?.map((framework, index) => (
        <div key={index.toString()} className='flex flex-col gap-2'>
          <div>
            <a
              href={framework.link}
              className='underline hover:underline hover:text-second font-semibold text-lg'
              target='_blank'
              rel='noreferrer'
            >
              {framework?.title}
            </a>
          </div>

          <Collapse defaultActiveKey={['0']}>
            {framework?.sub_sections?.map((section: any, index: number) => (
              <Collapse.Panel
                header={section?.title}
                key={index.toString()}
                extra={
                  <MdOutlineLaunch
                    size={16}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(section?.link, '_blank');
                    }}
                    className='hover:text-second'
                  />
                }
              >
                <p className='text-sm'>{section?.description}</p>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default FindingOWASP;
