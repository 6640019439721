import IncidentDetails from './IncidentDetails';
import IncidentsDrawer from './IncidentsDrawer';
import IncidentsSelectStatus from './IncidentsSelectStatus';

const Incidents = {
  Drawer: IncidentsDrawer,
  SelectStatus: IncidentsSelectStatus,
  Details: IncidentDetails,
};

export default Incidents;
