import FindingDrawer from './Item/FindingDrawer';
import FindingsOverview from './FindingsOverview';
import FindingsSelectSeverity from './FindingsSelectSeverity';
import FindingsSelectStatus from './FindingsSelectStatus';
import FindingsListing from './FindingsListing';
import FindingsSecurityFrameworks from './FindingsSecurityFrameworks';

const Findings = {
  Drawer: FindingDrawer,
  SelectSeverity: FindingsSelectSeverity,
  SelectStatus: FindingsSelectStatus,
  Overview: FindingsOverview,
  Listing: FindingsListing,
  Frameworks: FindingsSecurityFrameworks,
};

export default Findings;
