import { AutocompleteFilterOptionsType } from '@/utils/services/resource/resource-endpoints';
import { Select, SelectProps, Spin, Tag } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
export type ValueType<
  T extends AutocompleteFilterOptionsType = AutocompleteFilterOptionsType,
> = T;
interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  renderOptionLabel?: (options: ValueType[]) => JSX.Element[];
  renderTags?: boolean;
}
function DebounceSelectField<T extends ValueType = ValueType>({
  fetchOptions,
  debounceTimeout = 800,
  renderOptionLabel,
  renderTags,
  ...props
}: DebounceSelectProps<T>) {
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchRef = useRef(0);

  const loadOptions = useCallback(
    (value?: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      fetchOptions(value || '').then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setLoading(false);
        setOptions(newOptions);
      });
    },
    [fetchOptions]
  );
  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [loadOptions, debounceTimeout]);
  const handleSearch = useCallback(
    (value?: string) => {
      setLoading(true);
      if (value) debounceFetcher(value);
      else loadOptions();
    },
    [debounceFetcher, loadOptions]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSearch, []);

  return (
    <Select
      onSearch={handleSearch}
      notFoundContent={loading ? <Spin size='small' /> : undefined}
      filterOption={(input, option) => {
        const optionData = options.find(
          (opt) => String(opt.value) === String(option?.value)
        );
        if (!optionData) return false;
        const label =
          typeof optionData.label === 'string' ? optionData.label : '';
        const searchableLabel = label.replace(/devTagAsApi|devTagAsApp$/, '');
        const inputLower = input.toLowerCase();
        return searchableLabel.toLowerCase().includes(inputLower);
      }}
      tagRender={
        renderTags
          ? (tag) => {
              const { value, onClose, disabled } = tag;
              const option = options.find((opt) => opt.value === value);
              const rawLabel = option ? (option.label as string) : '';
              if (rawLabel.endsWith('devTagAsApi')) {
                return (
                  <>
                    <Tag
                      closable={!disabled}
                      onClose={onClose}
                      className='my-1 py-1'
                    >
                      {rawLabel.replace('devTagAsApi', '')}
                      <Tag className='bg-slate-300 ml-2 mr-0'>API</Tag>
                    </Tag>
                  </>
                );
              } else if (rawLabel.endsWith('devTagAsApp')) {
                return (
                  <>
                    <Tag
                      closable={!disabled}
                      onClose={onClose}
                      className='my-1 py-1'
                    >
                      {rawLabel.replace('devTagAsApp', '')}
                      <Tag className='bg-slate-300 ml-2 mr-0'>APP</Tag>
                    </Tag>
                  </>
                );
              } else {
                return (
                  <>
                    <Tag closable={!disabled} onClose={onClose}>
                      {rawLabel}
                    </Tag>
                  </>
                );
              }
            }
          : undefined
      }
      {...props}
    >
      {renderOptionLabel
        ? renderOptionLabel(options)
        : options.map((opt) => (
            <Select.Option
              key={opt.value}
              value={opt.value}
              disabled={opt.disabled}
            >
              {opt.label}
            </Select.Option>
          ))}
    </Select>
  );
}
export default DebounceSelectField;
