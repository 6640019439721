import Actions from '@/components/Actions';
import { ActionClass } from '@/utils/models/Action';
import { EventClass } from '@/utils/models/Event';
import { EventContextClass } from '@/utils/models/EventContext';
import { useGetActionQuery } from '@/utils/services/actions/actions-endpoints';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

const ActionEventContext = ({
  event,
  context,
}: {
  context: EventContextClass;
  event?: EventClass;
}) => {
  const { orgID } = useParams<{ orgID: string }>();

  const actionUUID = event?.organizedResources?.action.length
    ? event?.organizedResources?.action[0]
    : undefined;

  const { currentData: actionData } = useGetActionQuery(
    { orgID, actionID: actionUUID || '' },
    { skip: actionUUID === undefined || !context.isActionTriggered }
  );

  const history = useHistory();

  const extraButtons = (action: ActionClass) => {
    return [
      <Button
        key='action-button'
        onClick={() => {
          history.push(
            `/organisations/${action.g_orgUUID}/applications/${action.action_appUUID}/apis/${action.action_apiUUID}/actions/${action.UUID}`
          );
        }}
      >
        {action.name}
      </Button>,
    ];
  };

  let content = <></>;

  if (context.isCreated) {
    const action = new ActionClass(context.created);
    content = <Actions.Details action={action} extra={extraButtons(action)} />;
  }

  if (context.isDeleted) {
    const action = new ActionClass(context.before);
    content = <Actions.Details action={action} />;
  }

  if (context.isChanged) {
    const action = new ActionClass(context.after);
    content = (
      <Actions.Details action={actionData} extra={extraButtons(action)} />
    );
  }

  if (context.isActionTriggered && actionData) {
    content = (
      <Actions.Details action={actionData} extra={extraButtons(actionData)} />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default ActionEventContext;
