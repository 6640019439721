import {
  ANOMALY_THRESHOLD_OPTIONS,
  PERIOD_OPTIONS,
  RUNS_EVERY_OPTIONS,
  STATIC_THRESHOLD_OPTIONS,
} from '@/utils/constants';
import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Form, InputNumber, Radio, Select } from 'antd';
import { FormInstance } from 'rc-field-form';
import AlertSectionWrapper from './AlertSectionWrapper';
import RadioOptions from '@/components/Form/RadioOptions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const AlertConditions = ({
  formInstance,
  alert,
  forceType,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
  isReadOnly?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  const thresholdConditionValue: string | undefined = Form.useWatch(
    ['config', 'threshold_condition'],
    formInstance
  );

  const comparisonOperatorConditionValue: string | undefined = Form.useWatch(
    ['config', 'comparison_operator'],
    formInstance
  );

  const periodConfigValue: number | undefined = Form.useWatch(
    ['config', 'period'],
    formInstance
  );

  const alertType = forceType || alert?.type;

  useEffect(() => {
    if (isEdition) return;

    const PERIOD = PERIOD_OPTIONS[4].value; // 1 day

    let threshold_condition;
    if (alertType === 'static') {
      threshold_condition = STATIC_THRESHOLD_OPTIONS[0].value; // Greater
    }

    let comparison_operator;
    if (alertType === 'anomaly') {
      comparison_operator = ANOMALY_THRESHOLD_OPTIONS[1].value; // Greater than the band
    }

    formInstance.setFieldsValue({
      config: {
        period: PERIOD,
        threshold_condition,
        comparison_operator,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!alert && isEdition) return <></>;

  const sectionProps: any = {
    title: 'Conditions',
    info: `Define the parameters that will trigger the alert.`,
  };

  const PeriodConfigFormItem = () => {
    return (
      <Form.Item
        name={['config', 'period']}
        label='within the last...'
        rules={[{ required: true, message: 'Period is required' }]}
        className='col-span-2'
        tooltip='Specify the time period for the system to evaluate requests for the alert conditions.'
      >
        <Select disabled={(isEdition && alertType === 'anomaly') || isReadOnly}>
          {PERIOD_OPTIONS.map((p) => (
            <Select.Option id={p.id} key={p.id} value={p.value}>
              {p.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <AlertSectionWrapper
      title={sectionProps.title}
      showTitle={sectionProps.showTitle}
      info={sectionProps.info}
    >
      <div className='grid grid-cols-6 gap-6'>
        {alertType === 'static' && (
          <>
            <Form.Item
              label='Trigger this alert, whenever the number of requests is.. '
              tooltip='Set the alert trigger based on whether the number of requests exceeds, falls below, or matches a specific threshold.'
              name={['config', 'threshold_condition']}
              rules={[
                {
                  required: true,
                  message: 'Operand is required',
                },
              ]}
              className='col-span-6'
            >
              <Radio.Group className='w-full' disabled={isReadOnly}>
                <RadioOptions
                  active={thresholdConditionValue}
                  options={STATIC_THRESHOLD_OPTIONS}
                  isReadOnly={isReadOnly}
                />
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={`${
                thresholdConditionValue === '==' ? 'to...' : 'than...'
              }`}
              name={['config', 'threshold_value']}
              tooltip='Define the threshold value.'
              rules={[
                {
                  required: true,
                  message: 'Threshold is required',
                },
                {
                  type: 'number',
                  max: 1e12,
                  message: 'Threshold is 1000000000000',
                },
              ]}
              className='col-span-2'
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={1e12}
                formatter={(value) => `${value ? Math.round(value) : ''}`}
                placeholder='100'
                onPressEnter={(e) => e.preventDefault()}
                disabled={isReadOnly}
              />
            </Form.Item>

            <PeriodConfigFormItem />

            <Form.Item
              label='Run this check every'
              tooltip='How often the system runs alert checks.'
              className='col-span-2'
            >
              {
                RUNS_EVERY_OPTIONS.find(
                  (opt) => opt.value === periodConfigValue
                )?.label
              }
            </Form.Item>
          </>
        )}

        {alertType === 'anomaly' && (
          <>
            <Form.Item
              label='Whenever the number of requests is...'
              tooltip='Set the alert trigger based on whether the number of requests is outside of, greater or lower than the band.'
              name={['config', 'comparison_operator']}
              rules={[
                {
                  required: true,
                  message: 'Operand is required',
                },
              ]}
              className='col-span-6'
            >
              <Radio.Group className='w-full' disabled={isReadOnly}>
                <RadioOptions
                  active={comparisonOperatorConditionValue}
                  options={ANOMALY_THRESHOLD_OPTIONS}
                  isReadOnly={isReadOnly}
                />
              </Radio.Group>
            </Form.Item>

            <PeriodConfigFormItem />
          </>
        )}
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertConditions;
