import { Button, message, Result, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyResendUserEmailVerificationQuery } from '@/utils/services/user/user-endpoints';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';

const Unverified = () => {
  const [resendEmail, { isLoading }] =
    useLazyResendUserEmailVerificationQuery();
  const { user: profile, loginWithRedirect } = useAuth();
  const location = useLocation();
  const [disableResendEmailButton, setDisableResendEmailButton] =
    useState<boolean>(false);
  const [secondsLeft, setSecondsLeft] = useState(60);

  const localStorageKey = `timer-end-${profile?.email}`;

  const calculateTimeLeft = () => {
    const savedEndTime = localStorage.getItem(localStorageKey);
    const currentTime = Date.now();
    const timeLeft = savedEndTime
      ? parseInt(savedEndTime, 10) - currentTime
      : 0;

    return Math.max(Math.floor(timeLeft / 1000), 0);
  };

  useEffect(() => {
    let endTime = parseInt(localStorage.getItem(localStorageKey) || '', 10);
    if (!endTime) {
      endTime = Date.now() + 60000;
      localStorage.setItem(localStorageKey, endTime.toString());
      handleResendEmail();
    }

    setSecondsLeft(calculateTimeLeft());
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setSecondsLeft(newTimeLeft);
      if (newTimeLeft <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.email, localStorageKey]);

  useEffect(() => {
    if (secondsLeft > 0 && !disableResendEmailButton)
      setDisableResendEmailButton(true);
    if (secondsLeft === 0 && disableResendEmailButton)
      setDisableResendEmailButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  const login = () =>
    loginWithRedirect({
      appState: {
        returnTo: location.pathname,
      },
    });

  const handleResendEmail = () => {
    resendEmail()
      .unwrap()
      .then(() => {
        message.success('Email sent with success');
        setDisableResendEmailButton(true);
        const endTime = Date.now() + 60000;
        localStorage.setItem(localStorageKey, endTime.toString());
        setSecondsLeft(calculateTimeLeft());
        const interval = setInterval(() => {
          const newTimeLeft = calculateTimeLeft();
          setSecondsLeft(newTimeLeft);
          if (newTimeLeft <= 0) {
            clearInterval(interval);
          }
        }, 1000);
      });
  };

  return (
    <div className='pt-24'>
      <Spin spinning={isLoading}>
        <Result
          status='warning'
          title={
            <h1 className='text-xl mt-8 text-first'>
              <div>We have sent a verification email to {profile?.email},</div>
              <div>
                please check your inbox and verify your account before
                proceeding.
              </div>
            </h1>
          }
          extra={[
            secondsLeft ? <>{secondsLeft}</> : <></>,
            <Button
              key='resend'
              onClick={handleResendEmail}
              disabled={disableResendEmailButton}
              className='ml-2'
            >
              Resend email
            </Button>,
            <Button key='try-again' onClick={login}>
              Check Status
            </Button>,
          ]}
        />
      </Spin>
    </div>
  );
};

export default withAuthenticationRequired(Unverified);
