import { ReactNode } from 'react';

const ItemDescription = ({ children }: { children: ReactNode }) => (
  <div className='w-full h-10'>
    <div
      className='text-gray-800 text-sm font-normal 
         tracking-wide line-clamp-2'
    >
      {children}
    </div>
  </div>
);

export default ItemDescription;
