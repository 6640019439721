import { Checkbox, Col, Form, InputNumber, Radio, Row, message } from 'antd';
import { json2csv } from 'json-2-csv';
import { ReactNode, useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import { MdFileDownload } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { PaginationDataType } from '@/utils/hooks/QueryParam/usePaginationQueryParam';

const ListDownload = ({
  fetchData,
  loading,
  fileEntityName,
  buttonText = 'Download',
  disabled,
  numberOfItems,
}: {
  fetchData: (pagination?: PaginationDataType) => Promise<any[]>;
  loading?: boolean;
  fileEntityName?: string;
  buttonText?: string;
  disabled?: boolean;
  numberOfItems?: number;
}) => {
  const location = useLocation();
  const [data, setData] = useState<any[]>();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const format = 'csv';
  const total = numberOfItems || data?.length || 0;
  const attributes: string[] =
    Array.isArray(data) && data.length > 0
      ? Object.getOwnPropertyNames(data[0])
      : [];
  const initFormValues: {
    total?: number;
    format: string;
    selectedAttributes: string[];
  } = { total, format, selectedAttributes: attributes };
  const showModal = !!data;

  const handleCloseModal = () => setData(undefined);

  const handleOpenModal = async () => {
    const response = await fetchData();
    if (response?.length) setData(response);
    else message.warning('There is no data to download.');
  };

  const handleDownload = async ({
    selectedAttributes,
    total,
  }: {
    selectedAttributes: string[];
    format: string;
    total: number;
  }) => {
    if (!data) return;

    setIsConfirmLoading(true);

    let dataToDownload = data;

    try {
      if (total > data.length) {
        const pageSize = 100;
        const promises: any = [];
        for (let index = 0; index < total / pageSize; index++) {
          promises.push(fetchData({ marker: index * pageSize, pageSize }));
        }

        dataToDownload = [];

        const results = await Promise.all(promises);
        results.forEach((result) => {
          dataToDownload = [...dataToDownload, ...result];
        });
      }

      const items = sliceItems(dataToDownload, total);
      const csvContent = await convertItemsToCSVContent(
        items,
        selectedAttributes
      );

      setIsConfirmLoading(false);

      handleCloseModal();

      downloadCSV(csvContent);
    } catch (err) {
      message.error('Error to process data to download.');
    }
  };

  const downloadCSV = (csvContent: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = getFileName();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const getFileName = () => {
    const pathname = location.pathname.split('/');
    const entity = pathname.pop();
    return `${fileEntityName || entity}-${new Date().getTime()}.csv`;
  };

  const downloadFormFields: ReactNode[] = [
    <Form.Item key='format' required name='format' label='Format'>
      <Radio.Group>
        <Radio checked value='csv'>
          CSV
        </Radio>
      </Radio.Group>
    </Form.Item>,
    <Form.Item
      required
      key='selectedAttributes'
      name='selectedAttributes'
      label='Columns'
      rules={[
        {
          type: 'array',
          min: 1,
          message: 'Attributes cannot be less than 1 in length',
        },
      ]}
    >
      <Checkbox.Group style={{ width: '100%' }}>
        <Row>
          {attributes.map((attribute) => (
            <Col key={attribute} span={12}>
              <Checkbox checked value={attribute}>
                {attribute}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>,
    <Form.Item
      key='total'
      rules={[
        {
          required: true,
          message: 'Total number of items is required',
        },
      ]}
      name='total'
      label='Total number of items'
    >
      <InputNumber min={1} max={total} />
    </Form.Item>,
  ];

  return (
    <>
      <FormsModal
        title='Download Data'
        handleCloseModal={handleCloseModal}
        handleSubmit={handleDownload}
        showModal={showModal}
        initValues={initFormValues}
        fields={downloadFormFields}
        okText='Download'
        confirmLoading={isConfirmLoading}
      />
      <button
        disabled={disabled}
        className={`flex items-center gap-x-1 ${
          (disabled || loading) && 'opacity-50'
        }`}
        onClick={handleOpenModal}
      >
        <MdFileDownload />
        <span>{buttonText}</span>
      </button>
    </>
  );
};

function sliceItems(allItems: any[], total: number) {
  return allItems.slice(0, total);
}

async function convertItemsToCSVContent(items: any[], attributes: string[]) {
  const sliceColumns = items.map((item) => {
    const newItem: any = {};
    attributes.forEach((attribute) => (newItem[attribute] = item[attribute]));
    return newItem;
  });

  const options = {
    emptyFieldValue: '',
    expandNestedObjects: false,
  };
  return await json2csv(sliceColumns, options);
}

export default ListDownload;
