import { RequestClass } from '@/utils/models/Request';
import { Drawer, message, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import useRequestIDQueryParam from '@/utils/hooks/QueryParam/useRequestIDQueryParam';
import { useLazyGetRequestLogQuery } from '@/utils/services/request/request-endpoints';
import { useCallback, useEffect, useState } from 'react';
import DashboardRequestDetailsHeader from './DashboardRequestDetailsHeader';
import DashboardRequestDetailsGrid from './DashboardRequestDetailsGrid';
import DashboardRequestDetailsSequences from './DashboardRequestDetailsSequences';
import DashboardRequestDetailsGeneral from './DashboardRequestDetailsGeneral';
import DashboardRequestDetailsMetadata from './DashboardRequestDetailsMetadata';
import DashboardRequestDetailsResponse from './DashboardRequestDetailsResponse';
import DashboardRequestDetailsRequest from './DashboardRequestDetailsRequest';
import useHubspot from '@/utils/hooks/useHubspot';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';

const DashboardRequestDetailsRoot = () => {
  const { orgID } = useParams<{ orgID: string }>();
  const [log, setLog] = useState<RequestClass>();
  const { addHubspotWidget, removeHubspotWidget } = useHubspot();
  const { requestID, setRequestID } = useRequestIDQueryParam();
  const { data: organisation } = useGetOrganisationQuery({ orgID });

  const [
    getRequest,
    {
      isLoading: isGetRequestLoading,
      isFetching: isGetRequestFetching,
      isSuccess: isGetRequestSuccess,
    },
  ] = useLazyGetRequestLogQuery();

  const onClose = useCallback(() => {
    setRequestID();
    setLog(undefined);
  }, [setRequestID]);

  useEffect(() => {
    if (!requestID) return;

    getRequest({ orgID, requestID })
      .then((requestData) => {
        if (!requestData.data) {
          message.error('Log not found');
          return onClose();
        }

        setLog(requestData.data);
      })
      .catch(() => onClose());
  }, [requestID, getRequest, orgID, onClose]);

  const isOpen = requestID !== undefined;

  useEffect(() => {
    if (isOpen) removeHubspotWidget();
    else {
      addHubspotWidget();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isLoading = isGetRequestLoading || isGetRequestFetching;

  return (
    <>
      <Drawer closable={false} width={'100%'} placement='right' open={isOpen}>
        <DashboardRequestDetailsHeader onClose={onClose} />

        {isLoading && (
          <div className='my-12'>
            <Skeleton active />
          </div>
        )}

        {!isLoading && isGetRequestSuccess && log && (
          <>
            <DashboardRequestDetailsGrid log={log} />

            <div className='flex flex-col lg:flex-row lg:divide-x lg:-mb-6'>
              <div className='w-full lg:w-1/2 gap-8 flex flex-col py-6 lg:pr-6'>
                <DashboardRequestDetailsGeneral log={log} />

                {organisation?.showSequencingFeature && (
                  <DashboardRequestDetailsSequences log={log} />
                )}
              </div>

              <div className='w-full  lg:w-1/2 flex flex-col gap-8 py-6 lg:pl-6'>
                <DashboardRequestDetailsMetadata log={log} />

                <DashboardRequestDetailsResponse log={log} />

                <DashboardRequestDetailsRequest log={log} />
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default DashboardRequestDetailsRoot;
