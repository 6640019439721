import { message as altMessage } from 'antd';
import { MdContentCopy } from 'react-icons/md';
import { useCopyToClipboard } from 'usehooks-ts';

const ItemCopyButton = ({
  value,
  message = 'Copied with success.',
}: {
  value: string;
  message?: string;
}) => {
  const [, copy] = useCopyToClipboard();
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        copy(value);
        altMessage.success(message);
      }}
      className='flex text-start gap-1 break-all'
    >
      <span>{value}</span>
      <span className='pt-1'>
        <MdContentCopy size={12} />
      </span>
    </button>
  );
};

export default ItemCopyButton;
