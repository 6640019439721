import { ReportClass } from '@/utils/models/Report';
import { baseApi } from '../base-api';
import { FieldsData } from '@/utils/models/FieldType';

interface GenericParams {
  orgID: string;
}

interface ReportParams extends GenericParams {
  reportID: string;
  filters?: FieldsData[];
}

export interface ReportInterface {
  UUID: string;
  description: string;
  icon_url: string;
  report_name: string;
  supports_finding_filters: boolean;
  documentData?: any[];
  title: string;
}

interface ReportResponse {
  UUID: string;
  data: any[];
}

export const reportingEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listAvailableReports: build.query<ReportClass[], GenericParams>({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const response = await fetchWithBQ({
            url: `/organisations/${orgID}/reporting`,
            method: 'GET',
          });
          const data = response.data as { reports: ReportInterface[] };
          const reports = data.reports.map((report) => new ReportClass(report));
          return { data: reports };
        } catch (error) {
          const errorResponse = error as { message: string };
          return { error: errorResponse.message };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ UUID }) => ({
                type: 'Reports' as const,
                id: UUID,
              })),
              { type: 'Reports', id: 'LIST' },
            ]
          : [{ type: 'Reports', id: 'LIST' }],
    }),
    getReport: build.mutation<ReportResponse, ReportParams>({
      query: ({ orgID, reportID, filters }) => {
        return {
          url: `/organisations/${orgID}/reporting/${reportID}/details`,
          method: 'POST',
          data: {
            filters,
          },
        };
      },
      transformResponse: (response: { data: any[] }, meta, arg) => {
        return { UUID: arg.reportID, data: response.data };
      },
    }),
  }),
});

export const { useGetReportMutation, useListAvailableReportsQuery } =
  reportingEndpoints;
