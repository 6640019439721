import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { Modal } from 'antd';
import ConditionForm from './Form';

const DashboardFiltersConditionModal = ({
  selectedFilter,
  selectedCondition,
  isModalOpen,
  closeModal,
  excludeTypes = [],
}: {
  selectedFilter?: FilterGroupType;
  selectedCondition?: ConditionType;
  isModalOpen: boolean;
  closeModal: () => void;
  excludeTypes?: Array<string>;
}) => {
  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      style={{ top: 15 }}
      maskClosable={false}
      width={904}
      title={
        <span className='text-[#262D35] text-sm font-normal'>Requests</span>
      }
      open={isModalOpen}
      onCancel={closeModal}
      forceRender
      footer={null}
    >
      <ConditionForm
        selectedFilter={selectedFilter}
        selectedCondition={selectedCondition}
        excludeTypes={excludeTypes}
        formSubmited={closeModal}
        initForm={isModalOpen}
      />
    </Modal>
  );
};

export default DashboardFiltersConditionModal;
