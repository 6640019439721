import MethodTag from '@/components/Tag/MethodTag';
import RequestTag from '@/components/Tag/RequestTag';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import { OPERATORS } from '@/utils/constants';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { useListApisQuery } from '@/utils/services/api/api-endpoints';
import { useListApplicationsQuery } from '@/utils/services/application/application-endpoints';
import { useListLogFilterFieldsByResourceQuery } from '@/utils/services/resource/resource-endpoints';
import { useParams } from 'react-router-dom';

interface DashboardFiltersGroupsConditionalButtonProps {
  dark?: boolean;
  condition: ConditionType;
  filter: FilterGroupType;
  handleEditCondition?: (
    filter: FilterGroupType,
    condition: ConditionType
  ) => void;
  disable?: boolean;
}

const DashboardFiltersGroupsConditionalButton = ({
  dark = false,
  handleEditCondition,
  condition,
  filter,
  disable = false,
}: DashboardFiltersGroupsConditionalButtonProps) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: logFilters } = useListLogFilterFieldsByResourceQuery({ orgID });
  const field = logFilters?.find((t) => t.code === condition.field);
  const operator = OPERATORS[condition.operator];
  const conditionValues: string[] = condition.values || [];
  const conditionValue: string = condition.value
    ? condition.value.toString()
    : '';
  let component: React.ReactNode = conditionValues.join(', ');

  const { data: listApisData } = useListApisQuery(
    {
      orgID,
      filters: [
        {
          field: 'apiUUID',
          values: conditionValues,
        },
      ],
    },
    { skip: condition.field !== 'apiUUID' }
  );
  const apis = listApisData?.apis;

  const { data: listAppsData } = useListApplicationsQuery(
    { orgID },
    { skip: condition.field !== 'appUUID' }
  );
  const applications = listAppsData?.apps;

  const generateFieldName = () => {
    if (condition.addonAfter) {
      if (condition.field.endsWith(`.${condition.addonAfter}`)) {
        return condition.field;
      } else if (condition.field.endsWith('.')) {
        return `${condition.field}${condition.addonAfter}`;
      } else {
        return `${condition.field}.${condition.addonAfter}`;
      }
    }

    return field?.display_name || condition.field;
  };

  switch (condition.field) {
    case 'tags':
      component = conditionValues.map((tag) => (
        <RequestTag dark={dark} tagValue={tag} key={tag} />
      ));
      break;
    case 'request.method':
      component = conditionValues.map((method) => (
        <MethodTag name={method} key={method} />
      ));
      break;
    case 'response.statusCode':
      component = conditionValues.map((code) => (
        <StatusCodeTag dark={dark} code={code} key={code} />
      ));
      break;
    case 'apiUUID':
      component = conditionValues
        .map((v) => apis?.find((api) => api.UUID === v)?.name)
        .join(', ');
      break;
    case 'appUUID':
      component = conditionValues
        .map((v) => applications?.find((app) => app.UUID === v)?.name)
        .join(', ');
      break;
    default:
      if (operator === 'is greater than' || operator === 'is less than') {
        component = conditionValue;
      } else {
        component = conditionValues
          ? conditionValues.join(', ')
          : conditionValue;
      }
      break;
  }

  const dataTestid = conditionValues.join(', ');
  return (
    <button
      disabled={condition.disabled || disable}
      data-testid={`${generateFieldName()} ${operator} ${dataTestid}`}
      className={`p-2 text-left leading-loose
      ${
        dark
          ? 'hover:bg-[#2B3C53]'
          : `${
              condition.disabled || disable
                ? 'opacity-70 bg-gray-50'
                : 'hover:bg-gray-100'
            }`
      }`}
      onClick={(evt) => {
        evt.preventDefault();
        if (handleEditCondition) handleEditCondition(filter, condition);
      }}
      style={{
        marginLeft: '1px',
        marginTop: '1px',
        outline: `1px solid ${dark ? '#565861' : '#D9D9D9'}`,
      }}
    >
      <span>{generateFieldName()}</span>
      &nbsp;
      <span className='font-semibold'>{operator}</span>
      &nbsp;
      {typeof component === 'string' ? (
        <span>{component}</span>
      ) : (
        <>
          &nbsp;
          {component}
        </>
      )}
    </button>
  );
};

export default DashboardFiltersGroupsConditionalButton;
