import { store } from '@/app/store';
import { billingEndpoints } from './billing-endpoints';
import { PlanClass } from '@/utils/models/Plan';

export const listPlans = async (orgID: string) => {
  const response = await store.dispatch<any>(
    billingEndpoints.endpoints.listPlans.initiate({
      orgID,
    })
  );
  const plans = response.data as PlanClass[];
  return plans;
};
