import { formatMilliseconds } from '@/utils/methods/displayFormat';
import { RequestClass } from '@/utils/models/Request';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';
import { useGetApplicationQuery } from '@/utils/services/application/application-endpoints';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';

const DashboardRequestDetailsGeneral = ({ log }: { log: RequestClass }) => {
  const { currentData: organisation } = useGetOrganisationQuery({
    orgID: log.orgUUID,
  });

  const { currentData: application, isError: isGetAppError } =
    useGetApplicationQuery({
      orgID: log.orgUUID,
      appID: log.appUUID,
      supressErrors: true,
    });

  const { currentData: api, isError: isGetApiError } = useGetApiQuery({
    orgID: log.orgUUID,
    apiID: log.apiUUID,
  });

  return (
    <div className='gap-3 flex flex-col'>
      <h1 className='font-semibold' style={{ fontSize: 20 }}>
        General
      </h1>

      <Descriptions size='small' column={{ xs: 1, sm: 1, lg: 2 }} bordered>
        <Descriptions.Item label='Date Created'>
          {log?.created}
        </Descriptions.Item>

        {log?.plan && (
          <Descriptions.Item label='Retention period'>
            {log?.plan}
          </Descriptions.Item>
        )}

        {log?.associatedUser && (
          <Descriptions.Item label='API Token Owner'>
            {log?.associatedUser}
          </Descriptions.Item>
        )}

        <Descriptions.Item label='Source'>{log?.source}</Descriptions.Item>

        <Descriptions.Item label='Version'>{log?.version}</Descriptions.Item>

        <Descriptions.Item label='Process Type'>
          {log?.processType}
        </Descriptions.Item>

        {log?.request?.headers['User-Agent'] && (
          <Descriptions.Item label='User Agent'>
            <div className='grid gap-1'>
              {log?.request.headers['User-Agent'].map((agent, index) => (
                <span
                  key={index.toString()}
                  className='text-xs text-gray-500 italic'
                >
                  {agent}
                </span>
              ))}
            </div>
          </Descriptions.Item>
        )}

        <Descriptions.Item label='Execution Time'>
          {formatMilliseconds(log?.executionTime)}
        </Descriptions.Item>

        <Descriptions.Item label='Item Type'>{log?.itemType}</Descriptions.Item>

        <Descriptions.Item label='Host'>
          {log?.request?.domain}
        </Descriptions.Item>

        {log?.request.ip && (
          <Descriptions.Item label='IP Address'>
            {log.request.ip}{' '}
            {RequestClass.isPrivateIP(log.request.ip)
              ? '(Private)'
              : '(Public)'}
          </Descriptions.Item>
        )}

        {log?.request?.ipDetails?.city && (
          <Descriptions.Item label='IP location' className='capitalize'>
            {[log?.request.ipDetails.city, log?.request.ipDetails.country].join(
              ', '
            )}
          </Descriptions.Item>
        )}

        {organisation && (
          <Descriptions.Item label='Organization'>
            <Link
              className='underline hover:underline '
              to={`/organisations/${organisation.UUID}`}
            >
              {organisation.name}
            </Link>
          </Descriptions.Item>
        )}

        <Descriptions.Item label='Application'>
          {application && (
            <Link
              className='underline hover:underline '
              to={`/organisations/${application.app_orgUUID}/applications/${application.UUID}`}
            >
              {application.name}
            </Link>
          )}

          {isGetAppError && <span className='text-red-500'>Deleted</span>}
        </Descriptions.Item>

        <Descriptions.Item label='API'>
          {api && (
            <Link
              className='underline hover:underline '
              to={`/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`}
            >
              {api.name}
            </Link>
          )}

          {isGetApiError && <span className='text-red-500'>Deleted</span>}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default DashboardRequestDetailsGeneral;
