import { ApiClass } from './Api';
import BaseEntityClass from './BaseEntity';
import { SpecificationVersionClass } from './SpecificationVersion';

export interface SpecificationInterface {
  UUID: string;
  collection_apiUUID: string;
  collection_appUUID: string;
  collection_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  dateUpdatedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  latestVersionUUID: string;
  name: string;
  status: string;
  type: string;
  updatedBy: string;
}

type SpecificationExtraDataType = {
  version?: SpecificationVersionClass;
  api?: ApiClass;
};

export class SpecificationClass
  extends BaseEntityClass
  implements SpecificationInterface
{
  UUID: string;
  collection_apiUUID: string;
  collection_appUUID: string;
  collection_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  dateUpdatedInMicroSeconds: number;
  g_orgUUID: string;
  itemType: string;
  latestVersionUUID: string;
  name: string;
  status: string;
  type: string;
  updatedBy: string;
  version?: SpecificationVersionClass;

  api?: ApiClass;

  constructor(
    data: SpecificationInterface,
    extraData?: SpecificationExtraDataType
  ) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });
    this.UUID = data.UUID;
    this.collection_apiUUID = data.collection_apiUUID;
    this.collection_appUUID = data.collection_appUUID;
    this.collection_orgUUID = data.collection_orgUUID;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.dateUpdatedInMicroSeconds = data.dateUpdatedInMicroSeconds;
    this.g_orgUUID = data.g_orgUUID;
    this.itemType = data.itemType;
    this.latestVersionUUID = data.latestVersionUUID;
    this.name = data.name;
    this.status = data.status;
    this.type = data.type;
    this.updatedBy = data.updatedBy;
    this.version = extraData?.version;
    this.api = extraData?.api;
  }
}
