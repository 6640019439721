import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString from 'query-string';
import orgID from './pages/organisations/orgID';
import organisations from './pages/organisations';
import Home from './pages/home';
import invite_to_org from './pages/invite_to_org';
import awsMarketplace from './pages/aws-marketplace';
import SignupFromAWSMarktplacePage from './pages/signup-from-aws-marketplace';
import VerifiedByEmailPage from './pages/verified-by-email';
import NonBusiness from './pages/non-business';
import TopNavbar from './components/Navbar/TopNavbar';
import HttpError from './components/Modal/HttpError';
import GithubIntegration from './pages/organisations/orgID/github-integration';
import GithubInstallPage from './pages/github/install';
import SlackInstallPage from './pages/slack/install';
import useHubspot from './utils/hooks/useHubspot';
import axios from 'axios';
import { useEffect, useState } from 'react';
import FullScreenLoader from './components/Loader/FullScreenLoader';
import useAuth from './utils/lib/firetail-auth/hooks/useAuth';

const App = () => {
  useHubspot({ init: true });

  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth();

  const [waiting, setWaiting] = useState<boolean>(true);

  const setAxiosTokenInterceptor = (): void => {
    axios.defaults.baseURL = window.appConfig.REACT_APP_SERVER_DOMAIN;
    axios.interceptors.request.use(
      async (config: any) => {
        const token = await getAccessTokenSilently();
        config['headers']['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  async function initAxios() {
    setAxiosTokenInterceptor();
    setWaiting(false);
  }

  useEffect(() => {
    if (isLoading === false) {
      if (isAuthenticated) {
        initAxios();
      } else {
        setWaiting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    // If the user starts the login flow in other tab, we need to re-init axios
    // to set the token interceptor in the current tab.
    if (isLoading) setWaiting(true);
  }, [isLoading]);

  if (waiting || isLoading) return <FullScreenLoader />;

  const Body = () => {
    return (
      <BrowserRouter>
        <HttpError />
        <TopNavbar />
        <QueryParamProvider
          adapter={ReactRouter5Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/non-business' exact component={NonBusiness} />
            <Route path='/organisations' exact component={organisations} />
            <Route
              path='/verified-by-email'
              exact
              component={VerifiedByEmailPage}
            />
            <Route
              path='/organisations/:orgID/github-integration'
              component={GithubIntegration}
            />
            <Route path='/organisations/:orgID' component={orgID} />
            <Route
              path='/invite_to_org/:uuid'
              exact
              component={invite_to_org}
            />
            <Route
              path='/signup-from-aws-marketplace'
              exact
              component={SignupFromAWSMarktplacePage}
            />
            <Route path='/aws-marketplace' exact component={awsMarketplace} />
            <Route path='/github/install' exact component={GithubInstallPage} />
            <Route path='/slack/install' exact component={SlackInstallPage} />
            <Redirect from='*' to='/' />
          </Switch>
        </QueryParamProvider>
      </BrowserRouter>
    );
  };
  return <Body />;
};
export default App;
