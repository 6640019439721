import { store } from '@/app/store';
import {
  GetSlackCallbackParams,
  slackIntegrationEndpoints,
} from './slack-integration-endpoints';

export const getSlackCallbackParams = async ({ orgID }: { orgID: string }) => {
  const response = await store.dispatch<any>(
    slackIntegrationEndpoints.endpoints.getSlackCallbackParams.initiate({
      orgID,
    })
  );
  const slackCallbackParams = response.data;
  return slackCallbackParams as GetSlackCallbackParams;
};
