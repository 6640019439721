import { ApiClass } from '@/utils/models/Api';
import {
  FindingClass,
  FindingInterface,
  SeverityFindingType,
  StatusFindingType,
} from '@/utils/models/Finding';
import moment from 'moment';
import { FindingsOverviewResponse } from './finding-endpoints';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { FieldsData } from '@/utils/models/FieldType';
import LIST_OF_API_STATUS_FINDINGS from '@/utils/constants/listOfStatusFindings';
import {
  getCisAsgMappings,
  getFindingsOwaspMappings,
  getMitreCweTop25Mappings,
  getMitreTacticsMappings,
  getMitreTechniqueMappings,
} from '../local-api-export-data';

export interface FindingsMetricsInterface {
  datetime: number[];
  metrics: {
    [key: string]: {
      critical: number[];
      high: number[];
      informational: number[];
      medium: number[];
      low: number[];
      total: number[];
    };
  }[];
}

type FindingsMetricsItem = {
  status: string;
  severity: string;
  value: number;
  datetimeUTC: string;
  datetime: number;
};

export type LineChartHistographType = {
  severity: string;
  value: number;
  datetimeUTC: string;
  datetime: number;
  status: StatusFindingType;
};

export interface FindingsMetricsData {
  lineChart: LineChartHistographType[];
}

export interface ListFindingsPaginationResponse {
  findings: FindingClass[];
  marker: number;
  total: number;
}

type CounterType = { value: number; percentage: number };

export interface FindingsOverviewInterface {
  critical: CounterType;
  high: CounterType;
  information: CounterType;
  low: CounterType;
  medium: CounterType;
}

export function formatFindingsMetricsData(
  originalData: FindingsMetricsInterface,
  filterByStatus?: StatusFindingType
): FindingsMetricsData {
  const findingsMetricsItem: FindingsMetricsItem[] = [];

  originalData.metrics.forEach((metric) => {
    const status = Object.keys(metric)[0];

    const severities = Object.keys(metric[status]);

    severities.forEach((severity) => {
      // @ts-ignore
      const values: number[] = metric[status][severity];

      values.forEach((value, index) => {
        const datetime = originalData.datetime[index];
        findingsMetricsItem.push({
          status,
          severity,
          value,
          datetime,
          datetimeUTC: moment(datetime * 1000)
            .utc()
            .format(),
        });
      });
    });
  });

  return {
    lineChart: formatLineChartData(findingsMetricsItem, filterByStatus),
  };
}

function formatLineChartData(
  items: FindingsMetricsItem[],
  filterByStatus?: StatusFindingType
): LineChartHistographType[] {
  const datetimes = Array.from(new Set(items.map((item) => item.datetime)));
  const status = filterByStatus
    ? [filterByStatus]
    : LIST_OF_API_STATUS_FINDINGS;
  const severities = ['critical', 'high', 'medium', 'low', 'informational'];

  const lineChartHistographData: LineChartHistographType[] = [];

  datetimes.forEach((datetime) => {
    status.forEach((status) => {
      severities.forEach((severity) => {
        const item = items.find(
          (i) =>
            i.datetime === datetime &&
            i.status === status &&
            i.severity === severity
        );

        if (item) {
          lineChartHistographData.push({
            datetime: item.datetime,
            datetimeUTC: item.datetimeUTC,
            severity: item.severity,
            value: item.value,
            status: item.status as StatusFindingType,
          });
        }
      });
    });
  });

  return lineChartHistographData;
}

export const getFindingApi = (
  finding: FindingInterface,
  apis: ApiClass[]
): ApiClass | undefined => {
  const apiResource = finding.resources.find((r) => r.startsWith('api:'));
  if (!apiResource) return;
  const apiUUID = apiResource.replace('api:', '');
  return apis.find((api) => api.UUID === apiUUID);
};

export const getMockCacheOverview = ({
  currentSeverity,
  newSeverity,
  draft,
}: {
  currentSeverity: SeverityFindingType;
  newSeverity: SeverityFindingType;
  draft: MaybeDrafted<FindingsOverviewInterface>;
}) => {
  const counts: FindingsOverviewResponse = {
    critical: draft.critical.value,
    high: draft.high.value,
    medium: draft.medium.value,
    low: draft.low.value,
    information: draft.information.value,
  };
  counts[currentSeverity] -= 1;
  counts[newSeverity] += 1;
  return formatOverviewResponse(counts);
};

export const getMockCacheListing = ({
  findingID,
  dataType,
  data,
  draft,
  filters,
}: {
  findingID: string;
  dataType: 'severity' | 'status';
  data: SeverityFindingType | StatusFindingType;
  draft: MaybeDrafted<ListFindingsPaginationResponse>;
  filters?: FieldsData[];
}) => {
  let findings = draft.findings as FindingClass[];
  const updatedFindingIndex = findings.findIndex((f) => f.UUID === findingID);
  if (updatedFindingIndex > -1) {
    // @ts-ignore
    findings[updatedFindingIndex][dataType] = data;
  }
  const totalBeforeFilters = findings.length;
  const filterByDataType = filters?.find((f) => f.field === dataType);
  if (filterByDataType) {
    findings = findings.filter((f) =>
      filterByDataType?.values?.includes(f[dataType])
    );
  }
  const totalRemoved = totalBeforeFilters - findings.length;
  return {
    findings,
    marker: draft.marker,
    total: draft.total - totalRemoved,
  };
};

export const formatOverviewResponse = (
  counts: FindingsOverviewResponse
): FindingsOverviewInterface => {
  const { critical, high, medium, low, information } = counts;
  const total = critical + high + medium + low + information;

  if (total === 0) {
    return {
      critical: {
        value: critical,
        percentage: 0,
      },
      high: {
        value: high,
        percentage: 0,
      },
      medium: {
        value: medium,
        percentage: 0,
      },
      low: {
        value: low,
        percentage: 0,
      },
      information: {
        value: information,
        percentage: 0,
      },
    };
  }

  return {
    critical: {
      value: critical,
      percentage: (critical / total) * 100,
    },
    high: {
      value: high,
      percentage: (high / total) * 100,
    },
    medium: {
      value: medium,
      percentage: (medium / total) * 100,
    },
    low: {
      value: low,
      percentage: (low / total) * 100,
    },
    information: {
      value: information,
      percentage: (information / total) * 100,
    },
  };
};

export const handleSecurityFrameworksFilter = async (
  filters: FieldsData[]
): Promise<FieldsData[]> => {
  const copyFilters = [...filters];

  const index = copyFilters.findIndex((f) => f.field === 'security-frameworks');
  if (index === -1) return copyFilters;

  const frameworks = copyFilters[index].values || [];
  let codesFromFrameworks = new Set<string>();

  for (let i = 0; i < frameworks.length; i++) {
    const framework = frameworks[i];
    switch (framework) {
      case 'owasp':
        codesFromFrameworks = new Set<string>([
          ...Array.from(codesFromFrameworks),
          ...Object.entries(await getFindingsOwaspMappings())
            .filter((a) => {
              const al = a?.[1] as any[];
              return al.length;
            })
            .map((a) => a?.[0]),
        ]);
        break;
      case 'cis-asg':
        codesFromFrameworks = new Set<string>([
          ...Array.from(codesFromFrameworks),
          ...Object.entries(await getCisAsgMappings())
            .filter((a) => {
              const al = a?.[1] as any[];
              return al.length;
            })
            .map((a) => a?.[0]),
        ]);
        break;
      case 'mitre-cwe-top-25':
        codesFromFrameworks = new Set<string>([
          ...Array.from(codesFromFrameworks),
          ...Object.entries(await getMitreCweTop25Mappings())
            .filter((a) => {
              const al = a?.[1] as any[];
              return al.length;
            })
            .map((a) => a?.[0]),
        ]);
        break;
      case 'mitre-tactics':
        codesFromFrameworks = new Set<string>([
          ...Array.from(codesFromFrameworks),
          ...Object.entries(await getMitreTacticsMappings())
            .filter((a) => {
              const al = a?.[1] as any[];
              return al.length;
            })
            .map((a) => a?.[0]),
        ]);
        break;
      case 'mitre-techniques':
        codesFromFrameworks = new Set<string>([
          ...Array.from(codesFromFrameworks),
          ...Object.entries(await getMitreTechniqueMappings())
            .filter((a) => {
              const al = a?.[1] as any[];
              return al.length;
            })
            .map((a) => a?.[0]),
        ]);
        break;
    }
  }

  const currentCodes =
    copyFilters.find((f) => f.field === 'code')?.values || [];

  const mergedCodes = new Set<string>([
    ...currentCodes,
    ...Array.from(codesFromFrameworks),
  ]);

  copyFilters[index] = {
    field: 'code',
    values: Array.from(mergedCodes),
  };

  return copyFilters;
};
