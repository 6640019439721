import { Button } from 'antd';
import { GoLinkExternal } from 'react-icons/go';

const ModalDocsLink = ({ link }: { link: string }) => {
  return (
    <a
      className='mr-3 self-center'
      target='_blank'
      href={link}
      rel='noreferrer'
    >
      <Button className='mr-3 flex flex-row'>
        Docs
        <GoLinkExternal className='mt-1 ml-2' />
      </Button>
    </a>
  );
};

export default ModalDocsLink;
