import { Button, Form, FormInstance, Tooltip } from 'antd';
import { useState } from 'react';
import FieldType from '@/utils/models/FieldType';
import useRole from '@/utils/hooks/useRole';
import ChooseIntegrationModal from '@/components/Modal/ChooseIntegrationModal';
import IntegrationModal from '@/components/Integration/IntegrationModal';
import { useParams } from 'react-router-dom';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetIntegrationsQuotasQuery } from '@/utils/services/quota/quota-endpoints';

const CreateIntegrationButton = ({
  field,
  formInstance,
  mode,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
  mode?: 'multiple' | 'tags';
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToCreateIntegration } = useRole();

  const [openChooseIntegrationModal, setOpenChooseIntegrationModal] =
    useState<boolean>(false);
  const [isBillingOpen, setIsBillingOpen] = useState<boolean>(false);

  const [ids, setIds] = useState<{
    integrationAvailableID: string;
    myIntegrationID?: string;
  }>();

  const { data: integrationQuota } = useGetIntegrationsQuotasQuery(
    { orgID },
    { skip: field.sourceFrom?.resource !== 'integration' }
  );

  const upgradeRequired =
    (integrationQuota?.current as number) >= (integrationQuota?.max as number);

  const handleCreateClick = () => {
    if (upgradeRequired) {
      setIsBillingOpen(true);
      return;
    }

    setOpenChooseIntegrationModal(true);
  };

  const showCreateIntegrationButton =
    hasPermissionToCreateIntegration &&
    ((field.sourceFrom?.resource === 'integration' && !field.disabled) ||
      field.allowCreate);

  if (showCreateIntegrationButton) {
    return (
      <>
        <Billing.Modal
          open={isBillingOpen}
          setIsOpen={() => setIsBillingOpen(false)}
          titleText='You have reached your maximum amount of created integrations.'
        />

        <IntegrationModal
          idsParamsManually={ids}
          handleSubmit={(integrationID) => {
            if (mode) {
              const values = formInstance.getFieldValue(field.attribute) || [];
              formInstance.setFieldsValue({
                [field.attribute]: [...values, integrationID],
              });
            } else {
              formInstance.setFieldValue(field.attribute, integrationID);
            }
          }}
          onClose={() => setIds(undefined)}
          params={{ filters: field.sourceFrom?.filters }}
        />

        <ChooseIntegrationModal
          open={openChooseIntegrationModal}
          handleClose={() => setOpenChooseIntegrationModal(false)}
          categories={field.sourceFrom?.creationOptions?.categories}
          supportsActions={field.sourceFrom?.creationOptions?.supportsActions}
          onSelected={(ids) => setIds(ids)}
        />

        <Form.Item
          label={field.name === undefined ? field.name : ' '}
          className='ml-1'
        >
          <Button
            className='flex gap-2 items-center'
            onClick={handleCreateClick}
            disabled={field.disabled}
          >
            Create{' '}
            {upgradeRequired && (
              <Tooltip title='Upgrade Required'>
                <MdWarning style={{ color: '#FFE25E' }} size={16} />
              </Tooltip>
            )}
          </Button>
        </Form.Item>
      </>
    );
  }

  return <></>;
};

export default CreateIntegrationButton;
