import { FilterClass } from '@/utils/models/Filter';
import { useListLogFilterFieldsByResourceQuery } from '@/utils/services/resource/resource-endpoints';
import { Form, Select } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const TypeField = ({
  excludeTypes = [],
  onChange,
}: {
  excludeTypes?: Array<string>;
  onChange: (v: string) => void;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: logFilters } = useListLogFilterFieldsByResourceQuery({ orgID });

  const TYPES = logFilters?.filter((type) => !excludeTypes.includes(type.code));

  const groupedTypes = TYPES?.reduce<Record<string, FilterClass[]>>(
    (acc, type) => {
      const category = type.category || 'Other';
      if (!acc[category]) acc[category] = [];
      acc[category].push(type);
      return acc;
    },
    {}
  );

  return (
    <Form.Item
      key='field'
      name='field'
      label='Type'
      rules={[{ required: true, message: 'Type is required' }]}
    >
      <Select
        data-testid='select-type'
        placeholder='Select type'
        onChange={onChange}
        suffixIcon={
          <MdOutlineKeyboardArrowDown size={16} className='text-[#262d35]' />
        }
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) => {
          const value = option?.children || option?.value;
          if (typeof value !== 'string') return false;
          return value.toLowerCase().includes(input.toLowerCase());
        }}
      >
        {groupedTypes &&
          Object.entries(groupedTypes).map(([groupName, types]) => (
            <Select.OptGroup key={groupName} label={groupName}>
              {types.map((type) => (
                <Select.Option key={type.code} value={type.code}>
                  {type.display_name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
      </Select>
    </Form.Item>
  );
};

export default TypeField;
