import Specification from '@/components/Specification';
import { EventContextClass } from '@/utils/models/EventContext';
import { SpecificationVersionClass } from '@/utils/models/SpecificationVersion';

const SpecificationVersionEventContext = ({
  context,
}: {
  context: EventContextClass;
}) => {
  let content = <></>;

  if (context.isCreated) {
    const specificationVersion = new SpecificationVersionClass(context.created);
    content = (
      <Specification.VersionDetails
        specificationVersion={specificationVersion}
      />
    );
  }

  if (context.isDeleted) {
    content = (
      <Specification.VersionDetails
        specificationVersion={new SpecificationVersionClass(context.before)}
      />
    );
  }

  if (context.isChanged) {
    const specificationVersion = new SpecificationVersionClass(context.after);
    content = (
      <Specification.VersionDetails
        specificationVersion={specificationVersion}
      />
    );
  }

  return <div className='-mx-6'>{content}</div>;
};

export default SpecificationVersionEventContext;
