import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Form, FormInstance, Select } from 'antd';
import AlertSectionWrapper from './AlertSectionWrapper';
import { useEffect } from 'react';
import { METRIC_NAME_OPTIONS, METRIC_STAT_OPTIONS } from '@/utils/constants';
import { useParams } from 'react-router-dom';

const AlertMetricSelection = ({
  formInstance,
  alert,
  forceType,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
  isReadOnly?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  const metricNameFieldAttribute = ['config', 'metric_name'];
  const metricStatFieldAttribute = ['config', 'metric_stat'];

  const metricNameConfigValue: string | undefined = Form.useWatch(
    metricNameFieldAttribute,
    formInstance
  );

  const title = 'Metrics';
  const info = `Define a specific metric to monitor for unusual activity.`;

  const metricNameDefault = METRIC_NAME_OPTIONS[0];

  const currentMetricName = METRIC_NAME_OPTIONS.find(
    (m) => m.value === metricNameConfigValue
  );
  const metricStatOptionsFilterd = currentMetricName?.supportMetricStat
    ? METRIC_STAT_OPTIONS.filter((m) =>
        currentMetricName.supportMetricStat.includes(m.value)
      )
    : METRIC_STAT_OPTIONS;

  useEffect(() => {
    if (
      !currentMetricName?.supportMetricStat ||
      currentMetricName?.supportMetricStat.length === 0
    )
      return;

    formInstance.setFieldsValue({
      config: {
        metric_stat: currentMetricName.supportMetricStat[0],
      },
    });
  }, [currentMetricName, formInstance]);

  useEffect(() => {
    if (isEdition) {
      formInstance
        .validateFields([metricNameFieldAttribute, metricStatFieldAttribute])
        .catch((validateErrors) => {
          const config: any = {};

          if (!validateErrors?.values?.config?.metric_name) {
            config.metric_name = metricNameDefault.value;
          }

          formInstance.setFieldsValue({ config });
        });
    } else {
      formInstance.setFieldsValue({
        config: {
          metric_name: metricNameDefault.value,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertType = forceType || alert?.type;
  if (alertType === 'anomaly' || (!alert && isEdition)) return <></>;

  return (
    <AlertSectionWrapper title={title} info={info}>
      <div className='grid grid-cols-6 gap-6'>
        <Form.Item
          name={metricNameFieldAttribute}
          label='Metric name'
          id='metric_name'
          rules={[{ required: true, message: 'Metric name is required' }]}
          className='col-span-3'
          tooltip='Select the type of metric you want to track.'
        >
          <Select disabled={isReadOnly}>
            {METRIC_NAME_OPTIONS.map((metric) => (
              <Select.Option
                id={metric.value}
                key={metric.value}
                value={metric.value}
              >
                {metric.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={metricStatFieldAttribute}
          label='Metric stat'
          id='metric_stat'
          rules={[{ required: true, message: 'Metric stat is required' }]}
          className='col-span-3'
          tooltip='Choose the statistical operation for the selected metric.'
        >
          <Select disabled={isReadOnly}>
            {metricStatOptionsFilterd.map((metric) => (
              <Select.Option
                id={metric.value}
                key={metric.value}
                value={metric.value}
              >
                {metric.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertMetricSelection;
