import {
  useParams,
  Switch,
  Route,
  Redirect,
  Link,
  useHistory,
} from 'react-router-dom';
import { Breadcrumb, Button, PageHeader, Result, Spin } from 'antd';
import Apis from './apis';
import tokens from './tokens';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import { useGetApplicationQuery } from '@/utils/services/application/application-endpoints';
import List from '@/components/List';
import SpecificationsPage from '../specifications';
import useRole from '@/utils/hooks/useRole';

const App = () => {
  const {
    hasPermissionToListApplicationToken,
    hasPermissionToListSpecification,
    hasPermissionToListApi,
  } = useRole();
  const { appID, orgID } = useParams<{ appID: string; orgID: string }>();
  const { data: organisation } = useGetOrganisationQuery({ orgID });
  const {
    data: application,
    isLoading: getAppicationIsLoading,
    isSuccess,
  } = useGetApplicationQuery({
    orgID,
    appID,
  });
  const history = useHistory();

  const tabs = [];
  if (hasPermissionToListApi) {
    tabs.push({ label: 'APIs', key: 'apis' });
  }
  if (hasPermissionToListApplicationToken) {
    tabs.push({ label: 'Tokens', key: 'tokens' });
  }
  if (hasPermissionToListSpecification) {
    tabs.push({ label: 'Specifications', key: 'specifications' });
  }

  if (isSuccess && !application) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
      />
    );
  }

  return (
    <>
      <Spin spinning={getAppicationIsLoading}>
        <div className='p-1 bg-white mb-4 border'>
          {isSuccess && (
            <div className='pt-3 pl-4 mb-0'>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={`/organisations/${orgID}`}>
                    {organisation?.name}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/organisations/${orgID}/applications/${appID}`}>
                    {application?.name}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          )}
          <PageHeader
            ghost={false}
            title={application?.name}
            subTitle='Application'
            onBack={() => window.history.back()}
            extra={[
              <Button
                id='settings-button'
                onClick={() =>
                  history.push(
                    `/organisations/${orgID}/applications/${appID}/settings`
                  )
                }
                key='1'
              >
                Settings
              </Button>,
            ]}
          />
        </div>
      </Spin>

      <List.Tabs
        onChange={(key) =>
          history.replace(
            `/organisations/${orgID}/applications/${appID}/${key}`
          )
        }
        tabs={tabs}
      />

      <Switch>
        {hasPermissionToListApplicationToken && (
          <Route
            path='/organisations/:orgID/applications/:appID/tokens'
            component={tokens}
          />
        )}
        {hasPermissionToListApi && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis'
            component={Apis}
          />
        )}
        {hasPermissionToListSpecification && (
          <Route
            path='/organisations/:orgID/applications/:appID/specifications'
            component={SpecificationsPage}
            exact
          />
        )}
        {hasPermissionToListApi && (
          <Redirect
            from='/organisations/:orgID/applications/:appID'
            to='/organisations/:orgID/applications/:appID/apis'
          />
        )}
      </Switch>
    </>
  );
};

export default App;
