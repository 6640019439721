const ItemLogo = ({
  logoUrl,
  name,
  height,
}: {
  logoUrl?: string;
  name?: string;
  height?: number;
}) => {
  return <img className='h-8' style={{ height }} src={logoUrl} alt={name} />;
};

export default ItemLogo;
