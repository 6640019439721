export interface PlanInterface {
  currency: string;
  monthly_pricing: string;
  monthly_pricing_id: string;
  name: string;
  points: { details: string; tick: boolean }[];
  show_contact: false;
  button_text?: string;
  subtitle: string;
  uuid: string;
  yearly_pricing: string;
  yearly_pricing_id: string;
  is_visible?: boolean;
  show_button?: boolean;
  is_active_plan?: boolean;
  show_popular?: boolean;
}

export class PlanClass implements PlanInterface {
  currency: string;
  monthly_pricing: string;
  monthly_pricing_id: string;
  name: string;
  points: { details: string; tick: boolean }[];
  show_contact: false;
  button_text: string;
  subtitle: string;
  uuid: string;
  yearly_pricing: string;
  yearly_pricing_id: string;
  is_visible: boolean;
  show_button: boolean;
  is_active_plan: boolean;
  show_popular: boolean;

  constructor(plan: PlanInterface) {
    this.currency = plan.currency;
    this.monthly_pricing = plan.monthly_pricing;
    this.monthly_pricing_id = plan.monthly_pricing_id;
    this.name = plan.name;
    this.points = plan.points;
    this.show_contact = plan.show_contact;
    this.subtitle = plan.subtitle;
    this.uuid = plan.uuid;
    this.yearly_pricing = plan.yearly_pricing;
    this.yearly_pricing_id = plan.yearly_pricing_id;
    this.show_popular = plan.show_popular ?? false;
    this.button_text = plan.button_text ?? 'Select Plan';
    this.is_visible = plan.is_visible ?? true;
    this.show_button = plan.show_button ?? true;
    this.is_active_plan = plan.is_active_plan ?? false;
  }

  get discount() {
    const monthly = parseInt(this.monthly_pricing);
    const yearly = parseInt(this.yearly_pricing);
    const discount = monthly * 12 - yearly;
    return discount > 0 ? discount.toString() : '';
  }
}
