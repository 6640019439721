import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LIST_OF_HTTP_METHODS from '@/utils/constants/listOfHttpMethods';
import { useLazyGetSpecificationQuery } from '@/utils/services/specification/specification-endpoints';
import useQuery from '@/utils/hooks/useQuery';
import { getSelectedEndpoint } from '@/utils/methods/specification';
import { useAppSelector } from '@/app/hooks';
import { dashboardFiltersSelectors } from '../Dashboard/DashboardFilters/dashboardFiltersSlice';
import Dashboard from '@/components/Dashboard';

const SpecificationOverview = ({
  specificationID,
}: {
  specificationID?: string;
}) => {
  const [defaultFilters, setDefaultFilters] = useState<{
    initialized: boolean;
    filters: FilterGroupType[];
  }>({ initialized: false, filters: [] });
  const filters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const [getSpec, { data: specification }] = useLazyGetSpecificationQuery();
  const params = useQuery();
  const activeEndpointKey = params.get('activeEndpointKey') || undefined;
  const domainFilterGroupExist = !!filters.find(
    (f) => f.id === 'include.appUUID.apiUUID'
  );

  useEffect(() => {
    if (specificationID) {
      getSpec({ orgID, specificationID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificationID, orgID]);

  useEffect(() => {
    if (specification) {
      const filtersGroups: FilterGroupType[] = [];

      if (!defaultFilters.initialized || domainFilterGroupExist) {
        const appCondition: ConditionType = {
          id: 'appUUID',
          field: 'appUUID',
          operator: 'is-one-of',
          values: [appID],
        };
        const apiCondition: ConditionType = {
          id: 'apiUUID',
          field: 'apiUUID',
          operator: 'is-one-of',
          values: [apiID],
        };
        filtersGroups.push({
          id: 'include.appUUID.apiUUID',
          operator: 'include',
          groups: [appCondition, apiCondition],
        });
      }

      const endpoints = specification?.version?.endpoints || [];
      const selectedEndpoint = getSelectedEndpoint(
        endpoints,
        activeEndpointKey
      );
      if (selectedEndpoint) {
        const groups: ConditionType[] = [];
        const method = LIST_OF_HTTP_METHODS.find(
          (m) =>
            m.name.toLocaleLowerCase() ===
            selectedEndpoint.method.toLocaleLowerCase()
        );
        if (method) {
          const methodCondition: ConditionType = {
            id: 'request.method',
            field: 'request.method',
            operator: 'is-one-of',
            values: [selectedEndpoint.method.toUpperCase()],
          };
          groups.push(methodCondition);
        }
        const pathCondition: ConditionType = {
          id: 'request.resource',
          field: 'request.resource',
          operator: 'is-one-of',
          values: [selectedEndpoint.path],
        };
        groups.push(pathCondition);
        filtersGroups.push({
          id: 'include.request.method.request.resource',
          operator: 'include',
          groups,
        });
      }

      setDefaultFilters({ initialized: true, filters: filtersGroups });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specification, activeEndpointKey]);

  return (
    <div className='flex flex-col gap-8'>
      <div>
        <Dashboard.Filters.Root defaultFilters={defaultFilters.filters}>
          <Dashboard.Filters.Groups />

          <Dashboard.Filters.SelectorsWrapper>
            <Dashboard.Filters.SelectPollingInterval />
            <Dashboard.Filters.SelectDateTime />
            <Dashboard.Filters.CreateAlertButton />
          </Dashboard.Filters.SelectorsWrapper>
        </Dashboard.Filters.Root>
      </div>

      <Dashboard.RequestsLineChart light />

      <Dashboard.RequestsTable />
    </div>
  );
};

export default SpecificationOverview;
