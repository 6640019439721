import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@/components/Section/Container';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';

const Home = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const signupWithRedirect = () =>
    loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/organisations');
    }
  }, [isAuthenticated, history, location]);

  if (isAuthenticated) return <FullScreenLoader />;

  if (!isAuthenticated) {
    return (
      <Container>
        <div
          className='md:container md:mx-auto py-4 md:px-5 h-full bg-white rounded-xl shadow-md  
md:py-24 items-center text-center'
        >
          <h1 className='title-font text-3xl md:text-6xl font-bold md:mb-4 text-first'>
            API Security <span className='text-second'>by Design</span>
          </h1>
          <p
            className='mb-8 leading-relaxed md:w-2/3 text-base font-light lg:text-xl 
  md:mx-auto text-first'
          >
            Implement and enforce best practices in authentication,
            authorization, validation and sanitization of modern API requests
            and responses. Plus, centralize API audit trails to ensure
            governance and compliance.
          </p>
          <div className='flex justify-center'>
            <button
              data-testid='show-started'
              id='show-started'
              onClick={signupWithRedirect}
              className='inline-flex text-white font-bold bg-second border-0 
  py-2 px-6 focus:outline-none hover:bg-second-light rounded-xl text-lg'
            >
              Get Started
            </button>
          </div>
        </div>
      </Container>
    );
  }

  return <FullScreenLoader />;
};

export default Home;
