import MethodTag from '@/components/Tag/MethodTag';
import RequestTag from '@/components/Tag/RequestTag';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import LIST_OF_HTTP_METHODS from '@/utils/constants/listOfHttpMethods';
import LIST_OF_HTTP_STATUS_CODE from '@/utils/constants/listOfHttpStatusCode';
import { ConditionOperatorType, FilterClass } from '@/utils/models/Filter';
import { useGetRequestTagsQuery } from '@/utils/services/org/org-endpoints';
import { useListApisQuery } from '@/utils/services/api/api-endpoints';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import Select, { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { MdCheckBox, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import StatusCodeGroupButton, {
  StatusCodeGroupType,
} from './StatusCodeGroupButton';
import TagContentRender from './TagContentRender';
import SelectOption from './SelectOption';
import { useListApplicationsQuery } from '@/utils/services/application/application-endpoints';
import { AutocompleteFilterOptionsType } from '@/utils/services/resource/resource-endpoints';
import { handleFilterOptions } from '@/utils/methods/searchFormatting';

type CheckboxState = {
  group: StatusCodeGroupType;
  check: boolean;
};

const defaulCheckboxState: CheckboxState[] = [
  { group: '1XX', check: false },
  { group: '2XX', check: false },
  { group: '3XX', check: false },
  { group: '4XX', check: false },
  { group: '5XX', check: false },
];

const SelectField = ({
  type,
  rules,
  operator,
  getFormValues,
  setFormValues,
  autocompleteOptions,
  isOptionsLoading = false,
  dynamicAutocompleteOptions,
  addOnAfterValue,
}: {
  rules?: Rule[];
  type?: FilterClass;
  operator?: ConditionOperatorType;
  getFormValues: () => string[];
  setFormValues: (values: string[]) => void;
  autocompleteOptions?: AutocompleteFilterOptionsType[];
  isOptionsLoading?: boolean;
  dynamicAutocompleteOptions?: AutocompleteFilterOptionsType[];
  addOnAfterValue?: any[];
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: requestTags } = useGetRequestTagsQuery({ orgID });
  const { data: listAppsData } = useListApplicationsQuery({ orgID });
  const applications = listAppsData?.apps;
  const { data: listApisData } = useListApisQuery({ orgID });
  const apis = listApisData?.apis;
  const [checkboxState, setCheckboxState] = useState<CheckboxState[]>(
    structuredClone(defaulCheckboxState)
  );
  let activeOnDeselectStatusCode: boolean = false;

  if (!type || !operator || operator === 'exist' || operator === 'not-exist')
    return <></>;
  if (type.type === 'bool') return <></>;
  if (
    (!type.source_from &&
      !autocompleteOptions &&
      operator !== 'is-one-of' &&
      operator !== 'is-not-one-of') ||
    operator === 'greater-than' ||
    operator === 'less-than'
  )
    return <></>;

  const isMultipleValues =
    operator === 'is-one-of' || operator === 'is-not-one-of';
  const formItem = isMultipleValues ? 'values' : 'value';

  let selectMode: 'tags' | 'multiple' = 'tags';

  if (type.source_from) {
    if (type.dynamic !== false) selectMode = 'multiple';
  }

  let selectOptions: DefaultOptionType[] = [];
  let tagRenderIsActive = false;

  const getHttpStatusCodeMultipleOptions = (): DefaultOptionType[] => {
    const options: DefaultOptionType[] = checkboxState.map(
      ({ group, check }) => ({
        label: (
          <StatusCodeGroupButton
            group={group}
            check={check}
            getFormValues={getFormValues}
            setFormValues={setFormValues}
            setCheckboxState={(checked) =>
              handleSetCheckboxStateByGroup(group, checked)
            }
          />
        ),
        options: LIST_OF_HTTP_STATUS_CODE.filter((status) =>
          status.code.startsWith(group.charAt(0))
        ).map((status) => ({
          label: (
            <SelectOption name={status.code}>
              <StatusCodeTag showTooltip={false} code={status.code} />
            </SelectOption>
          ),
          value: status.code,
        })),
      })
    );

    return options;
  };

  const handleOnDeselect = (statusCode: string) => {
    if (activeOnDeselectStatusCode) {
      const state = checkboxState.find(
        (state) => state.group.charAt(0) === statusCode.charAt(0)
      );
      if (state) handleSetCheckboxStateByGroup(state.group, false);
    }
  };

  const handleSetCheckboxStateByGroup = (
    group: StatusCodeGroupType,
    checked: boolean
  ) => {
    const index = checkboxState.findIndex((g) => g.group === group);
    setCheckboxState((prev) => {
      const draft = [...prev];
      draft[index].check = checked;
      return draft;
    });
  };

  if (type.code === 'request.method') {
    tagRenderIsActive = true;
    selectOptions = LIST_OF_HTTP_METHODS.map((method) => ({
      label: (
        <SelectOption name={method.name}>
          <MethodTag showTooltip={false} name={method.name} />
        </SelectOption>
      ),
      value: method.name,
    }));
  } else if (type.code === 'response.statusCode') {
    tagRenderIsActive = true;
    selectMode = 'multiple';
    if (isMultipleValues) {
      activeOnDeselectStatusCode = true;
      selectOptions = getHttpStatusCodeMultipleOptions();
    } else {
      selectOptions = LIST_OF_HTTP_STATUS_CODE.map((statusCode) => ({
        label: (
          <SelectOption name={statusCode.code}>
            <StatusCodeTag showTooltip={false} code={statusCode.code} />
          </SelectOption>
        ),
        value: statusCode.code,
      }));
    }
  } else if (type.code === 'tags' && requestTags) {
    tagRenderIsActive = true;
    selectOptions = requestTags.map((tag) => ({
      label: (
        <SelectOption name={tag.display_name}>
          <RequestTag showTooltip={false} tagValue={tag.display_name} />
        </SelectOption>
      ),
      value: tag.key,
    }));
  } else if (type.source_from?.resource === 'app' && applications) {
    selectMode = 'multiple';
    selectOptions = applications.map((app) => ({
      label: (
        <SelectOption name={app.name}>
          <span data-testid={app.UUID} key={app.UUID}>
            {app.name}
          </span>
        </SelectOption>
      ),
      value: app.UUID,
    }));
  } else if (type.source_from?.resource === 'api' && apis) {
    selectMode = 'multiple';
    selectOptions = apis.map((api) => ({
      label: (
        <SelectOption name={api.name}>
          <div className='flex items-center gap-2'>
            <img
              className='mr-1'
              src={api.avatar}
              alt={`${api.name}'s avatar`}
              style={{ maxHeight: '20px', maxWidth: '20px' }}
            />

            <span data-testid={api.UUID} key={api.UUID}>
              {api.name}
            </span>
          </div>
        </SelectOption>
      ),
      value: api.UUID,
    }));
  } else if (
    type.dynamic &&
    addOnAfterValue &&
    addOnAfterValue.length > 0 &&
    dynamicAutocompleteOptions &&
    dynamicAutocompleteOptions?.length > 0
  ) {
    selectOptions = dynamicAutocompleteOptions.map((path) => ({
      label: (
        <SelectOption name={path.label as string}>
          <span data-testid={path.value} key={path.value}>
            {path.label}
          </span>
        </SelectOption>
      ),
      value: path.value,
    }));
  } else if (
    !type.dynamic &&
    autocompleteOptions &&
    autocompleteOptions?.length > 0
  ) {
    selectOptions = autocompleteOptions.map((path) => ({
      label: (
        <SelectOption name={path.label as string}>
          <span data-testid={path.value} key={path.value}>
            {path.label}
          </span>
        </SelectOption>
      ),
      value: path.value,
    }));
  }

  const tagRender = tagRenderIsActive
    ? (props: any) => <TagContentRender {...props} typeValue={type.code} />
    : undefined;

  return (
    <Form.Item
      rules={rules}
      key={isMultipleValues ? 'values' : 'value'}
      name={isMultipleValues ? 'values' : 'value'}
      label={capitalizeFirstLetter(isMultipleValues ? 'values' : 'value')}
    >
      <Select
        tagRender={tagRender}
        showSearch
        filterOption={handleFilterOptions}
        optionFilterProp='children'
        mode={selectMode}
        placeholder={`Select ${formItem}`}
        suffixIcon={
          <MdOutlineKeyboardArrowDown size={16} className='text-[#262d35]' />
        }
        menuItemSelectedIcon={<MdCheckBox size={24} />}
        options={selectOptions}
        disabled={isOptionsLoading}
        onDeselect={handleOnDeselect}
        popupClassName='scroll-dropdown-class'
        className='custom-select'
      />
    </Form.Item>
  );
};

function capitalizeFirstLetter(txt: string) {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
}

export default SelectField;
