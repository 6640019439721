import { useGetDashboardOverviewQuery } from '@/utils/services/org/org-endpoints';
import Statitic from '../DataDisplay/Statitic';
import useGetEndpointSettings from '@/utils/hooks/useGetEndpointSettings';
import { useGetQuotasForLoggingDurationQuery } from '@/utils/services/quota/quota-endpoints';
import { useParams } from 'react-router-dom';

const DashboardOverview = () => {
  const { orgID } = useParams<{ orgID: string }>();
  const { params, options } = useGetEndpointSettings();
  const { data } = useGetDashboardOverviewQuery(params, options);
  const { dateTime } = params;
  const { data: durationData } = useGetQuotasForLoggingDurationQuery({ orgID });

  const info = durationData?.durations.find(
    (i) => i.value === dateTime?.value
  )?.info;

  return (
    <div
      className='px-8 bg-first-light grid lg:grid-cols-12 grid-cols-1 items-center lg:gap-8'
      style={{ minHeight: 100 }}
    >
      <span
        className='text-anti-flash font-semibold lg:col-span-6 text-left'
        style={{ fontSize: 48 }}
      >
        Dashboard
      </span>
      <div
        className='lg:col-span-6 mb-2 lg:mb-0'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '40px',
        }}
      >
        <Statitic value={data?.app_count.value} label='Total Apps' />
        <Statitic value={data?.api_count.value} label='Total APIs' />
        <Statitic
          value={data?.api_endpoints_count.value}
          label='API Endpoints'
        />
        <Statitic
          value={data?.pii_data_count.value}
          label='Detected PII'
          info={info}
        />
        <Statitic
          value={data?.request_count.value}
          label='Requests'
          info={info}
        />
      </div>
    </div>
  );
};

export default DashboardOverview;
