import { useEffect, useMemo } from 'react';
import ListCheckableTag from './ListCheckableTag';
import useCategoryFilterQueryParam from '@/utils/hooks/QueryParam/useCategoryFilterQueryParam';

const ListCategoryFilter = ({
  dataSource,
  manuallyCategories,
}: {
  dataSource?: any[];
  manuallyCategories?: string[];
}) => {
  const { category, setCategory } = useCategoryFilterQueryParam();

  const handleOnChange = (selected?: string) => {
    setCategory(selected ? selected : 'all');
  };

  const categories = useMemo(() => {
    let categoriesNames = ['all'];

    if (manuallyCategories) return [...categoriesNames, ...manuallyCategories];

    if (dataSource) {
      categoriesNames = [
        ...categoriesNames,
        ...new Set(dataSource.map((item) => item.category)),
      ];
    }

    return categoriesNames;
  }, [dataSource, manuallyCategories]);

  useEffect(() => {
    if (categories.length === 1 || categories.includes(category)) return;

    setCategory('all');
  }, [category, categories, setCategory]);

  return (
    <div className='flex flex-row items-center mb-4'>
      <ListCheckableTag
        itemSelected={category}
        items={categories}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default ListCategoryFilter;
