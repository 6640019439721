type DailyMetricsHistogramStatusCode = {
  buckets: { doc_count: number; key: number }[];
};

export type DailyMetricsPercentiles = {
  values: {
    '1.0': number;
    '5.0': number;
    '25.0': number;
    '50.0': number;
    '75.0': number;
    '95.0': number;
    '99.0': number;
  };
};

export type DailyMetricsStats = {
  avg: number;
  count: number;
  max: number;
  min: number;
  std_deviation: number;
  std_deviation_bounds: {
    lower: number;
    lower_population: number;
    lower_sampling: number;
    upper: number;
    upper_population: number;
    upper_sampling: number;
  };
  std_deviation_population: number;
  std_deviation_sampling: number;
  sum: number;
  sum_of_squares: number;
  variance: number;
  variance_population: number;
  variance_sampling: number;
};

export type DailyMetricsUnits = 'milliseconds' | 'bytes';

export class DailyMetricsContextClass {
  histogramStatusCode: DailyMetricsHistogramStatusCode;

  combinedHeaderSizePercentiles: DailyMetricsPercentiles;
  combinedPayloadSizePercentiles: DailyMetricsPercentiles;
  executionTimePercentiles: DailyMetricsPercentiles;
  requestHeaderSizePercentiles: DailyMetricsPercentiles;
  requestPayloadSizePercentiles: DailyMetricsPercentiles;
  responseHeaderSizePercentiles: DailyMetricsPercentiles;
  responsePayloadSizePercentiles: DailyMetricsPercentiles;

  combinedHeaderSizeStats: DailyMetricsStats;
  combinedPayloadSizeStats: DailyMetricsStats;
  executionTimeStats: DailyMetricsStats;
  requestHeaderSizeStats: DailyMetricsStats;
  requestPayloadSizeStats: DailyMetricsStats;
  responseHeaderSizeStats: DailyMetricsStats;
  responsePayloadSizeStats: DailyMetricsStats;

  constructor(data: any) {
    this.histogramStatusCode = data.histogram_statusCode;

    this.combinedHeaderSizePercentiles = data.percentiles_combinedHeaderSize;
    this.combinedPayloadSizePercentiles = data.percentiles_combinedPayloadSize;
    this.executionTimePercentiles = data.percentiles_executionTime;
    this.requestHeaderSizePercentiles = data.percentiles_requestHeaderSize;
    this.requestPayloadSizePercentiles = data.percentiles_requestPayloadSize;
    this.responseHeaderSizePercentiles = data.percentiles_responseHeaderSize;
    this.responsePayloadSizePercentiles = data.percentiles_responsePayloadSize;

    this.combinedHeaderSizeStats = data.stats_combinedHeaderSize;
    this.combinedPayloadSizeStats = data.stats_combinedPayloadSize;
    this.executionTimeStats = data.stats_executionTime;
    this.requestHeaderSizeStats = data.stats_requestHeaderSize;
    this.requestPayloadSizeStats = data.stats_requestPayloadSize;
    this.responseHeaderSizeStats = data.stats_responseHeaderSize;
    this.responsePayloadSizeStats = data.stats_responsePayloadSize;
  }

  get hasHistogramStatusCode(): boolean {
    return this.histogramStatusCode.buckets.length > 0;
  }

  get hasPerformanceOverview(): boolean {
    return this.hasHistogramStatusCode;
  }
}
