import { StyleSheet } from '@react-pdf/renderer';

export const ftLogo = require('@/assets/images/logo-dark.png');

export const QuotaStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 24,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 10,
    borderBottomWidth: 2,
    borderColor: '#F0F0F0',
  },
  logo: {
    width: 60,
    height: 60,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 10,
  },
  categoryHeader: {
    marginTop: 12,
    padding: 8,
    backgroundColor: '#F0F0F0',
    fontWeight: 'bold',
    fontSize: 16,
  },
  table: {
    marginTop: 2,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E4',
    paddingTop: 4,
    paddingBottom: 4,
    alignItems: 'center',
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'left',
    padding: 8,
  },
  tableCell: {
    fontSize: 12,
    flex: 1,
    textAlign: 'left',
    padding: 8,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 10,
  },
  card: {
    width: '48%',
    textDecoration: 'none',
    color: 'unset',
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#cccccc',
    borderRadius: 5,
    backgroundColor: '#fff',
  },
  activeCard: {
    borderColor: 'orange',
    borderWidth: 2,
  },
  planPrice: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  feature: {
    fontSize: 10,
    marginTop: 2,
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    borderTop: 2,
    borderColor: '#F0F0F0',
    textAlign: 'center',
    paddingTop: '15px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const reportStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    paddingBottom: 10,
    borderBottom: 2,
    borderColor: '#F0F0F0',
  },
  logo: {
    width: 60,
    height: 60,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#E07A5F',
    marginBottom: '10px',
  },
  section: {
    padding: 10,
    paddingBottom: 20,
    borderRadius: 8,
    marginBottom: 5,
  },
  code: {
    fontSize: 14,
    marginBottom: 2,
    color: '#3D405B',
    fontWeight: 500,
  },
  description: {
    fontSize: 12,
    marginBottom: 4,
    color: '#333333',
  },
  eventsHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 2,
    color: '#333333',
  },
  events: {
    fontSize: 11,
    marginBottom: 2,
    marginLeft: 5,
    fontFamily: 'Courier',
    padding: 3,
  },
  dataPointHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#333333',
  },
  dataPointText: {
    fontSize: 12,
    marginBottom: 2,
    marginLeft: 5,
    color: '#3D405B',
    padding: 3,
    borderRadius: 4,
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    borderTop: 2,
    borderColor: '#F0F0F0',
    paddingTop: '15px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    fontSize: 12,
    color: '#3D405B',
  },
  subHead: {
    fontSize: 11,
    fontFamily: 'Courier',
    padding: 3,
  },
  table: {
    marginTop: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E4E4E4',
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 5,
    backgroundColor: '#F0F0F0',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#E4E4E4',
    borderLeftWidth: 1,
    borderLeftColor: '#E4E4E4',
  },
  tableCell: {
    fontSize: 12,
    padding: 5,
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#E4E4E4',
    borderLeftWidth: 1,
    borderLeftColor: '#E4E4E4',
  },
  apiName: {
    fontSize: 11,
    fontFamily: 'Helvetica',
    padding: 3,
    paddingLeft: 10,
    color: '#555',
  },
  eventHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 2,
    color: '#333',
    backgroundColor: '#f0f0f0',
    padding: 5,
  },
  eventText: {
    fontSize: 10,
    padding: 3,
    fontFamily: 'Helvetica',
    color: '#666',
  },
  tableCellHalf: {
    fontSize: 12,
    padding: 5,
    textAlign: 'left',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#E4E4E4',
    borderLeftWidth: 1,
    borderLeftColor: '#E4E4E4',
  },
  tableCellFull: {
    fontSize: 12,
    padding: 5,
    textAlign: 'left',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#E4E4E4',
    borderLeftWidth: 1,
    borderLeftColor: '#E4E4E4',
  },
});
