import { store } from '@/app/store';
import { actionsEndpoints } from './actions-endpoints';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';

export const listActionAvailableIntegrations = async (orgID: string) => {
  const result = await store.dispatch<any>(
    actionsEndpoints.endpoints.listActionAvailableIntegrations.initiate({
      orgID,
    })
  );

  const data = result.data as {
    custom: IntegrationAvailableClass[];
    managed: IntegrationAvailableClass[];
  };

  return data;
};
