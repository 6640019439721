const ListCheckableTag = ({
  itemSelected,
  items,
  onChange,
}: {
  itemSelected?: string;
  items: string[];
  onChange: (itemSelected: string | undefined) => void;
}) => {
  const selectItem = ({
    item,
    isChecked,
  }: {
    item: string;
    isChecked: boolean;
  }) => {
    let newItemSelected = isChecked ? item : undefined;

    if (item === 'all' || (item === itemSelected && !isChecked)) {
      newItemSelected = 'all';
    }

    onChange(newItemSelected);
  };

  if (items.length <= 1) return <></>;

  return (
    <div className='w-full text-center'>
      <div className='justify-center items-center gap-2 flex flex-wrap'>
        {items.map((item) => (
          <div
            key={item}
            className={`px-3 py-2 border-2 rounded-2xl transition-all font-semibold ${
              itemSelected === item
                ? 'border-first bg-first-light text-gray-50'
                : 'text-first hover:bg-zinc-200'
            } justify-start items-start flex cursor-pointer`}
            onClick={() =>
              selectItem({ item, isChecked: itemSelected !== item })
            }
          >
            <span className='text-sm leading-none tracking-wide capitalize'>
              {item}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListCheckableTag;
