import { getLogoPathByName } from '@/utils/constants/listOfImagesPaths';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import { ReactNode, useEffect, useState } from 'react';

const FindingsSecurityFrameworks = () => {
  const { filters, setFilterValue, getFilterValue } = useFiltersQueryParam();

  const [activeFrameworks, setActiveFrameworks] = useState<string[]>([]);

  const handleOnClick = async (framework: string) => {
    const activeFrameworks = getFilterValue('security-frameworks') || [];
    const isActive = activeFrameworks.includes(framework);

    if (isActive) {
      setFilterValue({
        field: 'security-frameworks',
        values: activeFrameworks.filter((f) => f !== framework),
      });
    } else {
      setFilterValue({
        field: 'security-frameworks',
        values: [...activeFrameworks, framework],
      });
    }
  };

  useEffect(() => {
    const securityFrameworks = filters?.find(
      (f) => f.field === 'security-frameworks'
    );
    if (securityFrameworks)
      setActiveFrameworks(securityFrameworks.values || []);
    else setActiveFrameworks([]);
  }, [filters]);

  const noActiveFrameworks = activeFrameworks.length === 0;

  return (
    <div className='flex gap-2 2xl:gap-4'>
      <Card
        isSelect={noActiveFrameworks || activeFrameworks.includes('owasp')}
        label='API Top 10'
        onClick={handleOnClick}
        framework='owasp'
      >
        <div>
          <img
            src={getLogoPathByName('owasp')}
            alt='OWASP'
            className='h-7 -ml-1'
          />
        </div>
      </Card>

      <Card
        isSelect={noActiveFrameworks || activeFrameworks.includes('cis-asg')}
        label='API Security Guide'
        onClick={handleOnClick}
        framework='cis-asg'
      >
        <div>
          <img src={getLogoPathByName('cis')} alt='CIS' className='h-7' />
        </div>
      </Card>

      <Card
        isSelect={
          noActiveFrameworks || activeFrameworks.includes('mitre-cwe-top-25')
        }
        label='CWE Top 25'
        onClick={handleOnClick}
        framework='mitre-cwe-top-25'
      >
        <div>
          <img src={getLogoPathByName('cwe')} alt='CWE' className='h-7' />
        </div>
      </Card>

      <Card
        isSelect={
          noActiveFrameworks || activeFrameworks.includes('mitre-tactics')
        }
        label='Tactics'
        onClick={handleOnClick}
        framework='mitre-tactics'
      >
        <div>
          <img
            src={getLogoPathByName('att-ck')}
            alt='ATT&CK'
            className='h-7 -ml-1'
          />
        </div>
      </Card>

      <Card
        isSelect={
          noActiveFrameworks || activeFrameworks.includes('mitre-techniques')
        }
        label='Techniques'
        onClick={handleOnClick}
        framework='mitre-techniques'
      >
        <div>
          <img
            src={getLogoPathByName('att-ck')}
            alt='ATT&CK'
            className='h-7 -ml-1'
          />
        </div>
      </Card>
    </div>
  );
};

const Card = ({
  children,
  label,
  onClick,
  framework,
  isSelect = false,
}: {
  children: ReactNode;
  label: string;
  onClick: (framework: string) => void;
  framework: string;
  isSelect?: boolean;
}) => {
  return (
    <div
      onClick={() => onClick(framework)}
      className={`bg-white hover:scale-105 transition-all p-2 w-full
rounded-sm flex flex-col gap-2 2xl:gap-1 cursor-pointer shadow-sm h-[68px]
${isSelect ? '' : 'grayscale opacity-60'}`}
    >
      <p
        className='text-xs 2xl:text-sm text-gray-400 capitalize 
      font-light whitespace-nowrap'
      >
        {label}
      </p>

      {children}
    </div>
  );
};

export default FindingsSecurityFrameworks;
