import { StatusFindingType } from '../models/Finding';

const LIST_OF_API_STATUS_FINDINGS: StatusFindingType[] = [
  'Open',
  'Remediated',
  'Ignored',
  'Risk Accepted',
  'False Positive',
];

export default LIST_OF_API_STATUS_FINDINGS;
