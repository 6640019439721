import List from '@/components/List';
import Item from '@/components/List/Item';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useRole from '@/utils/hooks/useRole';
import { useListActionsQuery } from '@/utils/services/actions/actions-endpoints';
import ActionModal from './ActionModal';
import { ActionClass, ActionType } from '@/utils/models/Action';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import QuotasResourceModal from '../Modal/QuotasResourceModal';
import { useGetActionsQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import useCategoryFilterQueryParam from '@/utils/hooks/QueryParam/useCategoryFilterQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';

const ActionsListing = () => {
  const { hasPermissionToGetActions, hasPermissionToCreateActions } = useRole();
  const { orgID, apiID } = useParams<{ orgID: string; apiID?: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isResourceModalOpen, setIsResourceModalOpen] =
    useState<boolean>(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const { pagination } = usePaginationQueryParam();
  const { category } = useCategoryFilterQueryParam();
  const { query } = useSearchQueryParam();

  const { data: actionsQuota, isLoading: isActionsQuotaLoading } =
    useGetActionsQuotasQuery({
      orgID,
    });

  // TODO: Replace this logic if we use backend business logic.
  const upgradeRequired =
    actionsQuota &&
    (actionsQuota.current as number) >= (actionsQuota?.max as number);

  let actionType: ActionType;
  if (category === 'schedule') actionType = 'api_schedule_action';
  else if (category === 'event') actionType = 'api_event_action';

  const {
    data: actionData,
    isLoading: isActionsLoading,
    isFetching: isActionsFetching,
  } = useListActionsQuery({
    orgID,
    apiID,
    query,
    actionType,
    ...pagination,
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      key: 'title',
      render: (_, action) =>
        hasPermissionToGetActions ? (
          <button
            onClick={() => handleActionSelected(action)}
            className='text-base text-blue-600 font-semibold text-left'
          >
            {action.name}
          </button>
        ) : (
          <span className='text-base font-semibold text-left'>
            {action.name}
          </span>
        ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (category) => (
        <span>
          <Item.Badge text={category} />
        </span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, action) => (
        <Item.Date
          created={action.created}
          createdFromNow={action.createdFromNow}
        />
      ),
    },
  ];

  const handleActionSelected = (action: any) => {
    history.push({
      ...location,
      pathname: `${location.pathname}/${action.UUID}`,
    });
  };

  const handleCreateAction = () => {
    if (upgradeRequired) {
      setIsPlanModalOpen(true);
      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      <ActionModal onCloseModal={() => setIsOpen(false)} open={isOpen} />

      <QuotasResourceModal
        resource='Active Actions'
        title='Active Actions'
        handleCloseModal={() => setIsResourceModalOpen(false)}
        showModal={isResourceModalOpen}
      />

      <Billing.Modal
        titleText='You have reached your limit of created Actions.'
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <List.Root>
        <List.Header>
          <List.Search placeholder='Search actions' />

          <List.Settings>
            {hasPermissionToCreateActions && (
              <Button
                id='create-action-button'
                onClick={() => handleCreateAction()}
                type='primary'
                disabled={isActionsQuotaLoading}
              >
                <div className='flex flex-row'>
                  Create Action
                  {upgradeRequired && (
                    <Tooltip title='Upgrade Required'>
                      <MdWarning
                        style={{ color: '#FFE25E' }}
                        size={16}
                        className='ml-2 self-center'
                      />
                    </Tooltip>
                  )}
                </div>
              </Button>
            )}

            <div
              className='hover:cursor-pointer'
              onClick={() => setIsResourceModalOpen(true)}
            >
              <List.QuotaIndicator
                quota={actionsQuota}
                tooltipText={
                  actionsQuota?.max === 0
                    ? 'Your current plan does not support Actions.'
                    : `You are currently using ${
                        actionsQuota?.current as number
                      } out of your ${
                        actionsQuota?.max as number
                      } allocated Actions.`
                }
              />
            </div>
          </List.Settings>

          <List.Segmented />
        </List.Header>

        <List.CategoryFilter manuallyCategories={['event', 'schedule']} />

        <List.Pagination
          dataSource={actionData?.action}
          total={actionData?.total}
          useURI
        >
          <List.Grid
            item={{
              render: (action: ActionClass) => (
                <Item.Root
                  key={action.key}
                  onClick={
                    hasPermissionToGetActions
                      ? () => handleActionSelected(action)
                      : undefined
                  }
                  id={action.key}
                  badge={action.category}
                  disabled={!action.enabled}
                >
                  <Item.Header>
                    <Item.Title>{action.name}</Item.Title>
                  </Item.Header>

                  <Item.Footer>
                    <Item.Date
                      created={action.created}
                      createdFromNow={action.createdFromNow}
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={
              isActionsLoading || isActionsFetching || isActionsQuotaLoading
            }
          />

          <List.Table
            columns={columns}
            loading={
              isActionsLoading || isActionsFetching || isActionsQuotaLoading
            }
          />
        </List.Pagination>
      </List.Root>
    </>
  );
};

export default ActionsListing;
