import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import { Button, Result } from 'antd';

const NonBusiness = () => {
  const { loginWithRedirect } = useAuth();

  return (
    <div className='pt-24'>
      <Result
        status='warning'
        title={
          <h1 className='text-xl mt-8 text-first'>
            FireTail only supports the use of business emails. Please sign up
            with a business email.
          </h1>
        }
        extra={[
          <Button
            key='logOut'
            onClick={() =>
              loginWithRedirect({
                authorizationParams: { screen_hint: 'signup' },
              })
            }
          >
            Sign Up With a Business Email
          </Button>,
        ]}
      />
    </div>
  );
};

export default NonBusiness;
