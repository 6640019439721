import IntegrationDetails from '@/components/Integration/IntegrationDetails';
import { EventContextClass } from '@/utils/models/EventContext';
import { IntegrationClass } from '@/utils/models/Integration';

const IntegrationEventContext = ({
  context,
}: {
  context: EventContextClass;
}) => {
  let content = <></>;

  if (context.isCreated) {
    const integration = new IntegrationClass(context.created);
    content = <IntegrationDetails integration={integration} />;
  }

  if (context.isDeleted) {
    content = (
      <IntegrationDetails integration={new IntegrationClass(context.before)} />
    );
  }

  if (context.isChanged) {
    const integration = new IntegrationClass(context.after);
    content = <IntegrationDetails integration={integration} />;
  }

  return <div className='-mx-6'>{content}</div>;
};

export default IntegrationEventContext;
