import { useCallback } from 'react';
import {
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const defaultRequestID = withDefault(StringParam, undefined);

interface useRequestIDQueryParamProps {
  updateType?: UrlUpdateType;
}

function useRequestIDQueryParam(props?: useRequestIDQueryParamProps) {
  const updateType: UrlUpdateType = props?.updateType || 'replaceIn';

  const [{ requestID }, setRequestID] = useQueryParams({
    requestID: defaultRequestID,
  });

  const setID = useCallback(
    (requestID?: string) => {
      setRequestID({ requestID }, updateType);
    },
    [setRequestID, updateType]
  );

  return { requestID, setRequestID: setID };
}

export default useRequestIDQueryParam;
