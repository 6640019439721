import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Form, Select } from 'antd';
import { getColSize, getFieldSize } from './const/functions';

/* eslint-disable no-template-curly-in-string */
const MultiInputTextField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  if (field?.validation?.regex) {
    rules.push(() => ({
      validator(_: any, value: string | Array<string>) {
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          if (
            typeof element === 'string' &&
            field?.validation?.regex &&
            !element.match(field.validation.regex)
          ) {
            return Promise.reject(
              new Error(field.validation.regex_description || 'Invalid input')
            );
          }
        }
        return Promise.resolve();
      },
    }));
  }

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        key={field.attribute}
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'extralarge')}
      >
        <Select
          mode='tags'
          style={{ width: '100%' }}
          placeholder={field.placeholder}
          maxTagTextLength={field.validation?.max || 256}
          open={false}
          disabled={field.disabled}
        />
      </Form.Item>
    </div>
  );
};

export default MultiInputTextField;
