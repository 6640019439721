// Tag Utils

export const getStatusCodeTagColor = (code: string) => {
  let color;
  if (code.startsWith('1')) {
    color = 'blue';
  } else if (code.startsWith('2')) {
    color = 'green';
  } else if (code.startsWith('3')) {
    color = 'purple';
  } else if (code.startsWith('4')) {
    color = 'orange';
  } else if (code.startsWith('5')) {
    color = 'red';
  }
  return color;
};

export const getStyleColors = (color?: string, dark?: boolean) => {
  let bg;
  let border;
  let fontColor;

  if (dark) {
    fontColor = '#F2F4F6';
    switch (color) {
      case 'blue':
        bg = '#2B4151';
        border = '#329FDC';
        break;
      case 'purple':
        bg = '#343351';
        border = '#9064DC';
        break;
      case 'green':
        bg = '#305244';
        border = '#3F8D5C';
        break;
      case 'red':
        bg = '#4F343B';
        border = '#904044';
        break;
      case 'yellow':
        bg = '#504C39';
        border = '#947E40';
        break;
      case 'orange':
        bg = '#473E3B';
        border = '#FF9447';
        break;
      default:
        // Gray
        bg = '#2F353C';
        border = '#717171';
        break;
    }
  } else {
    fontColor = '#484848';
    switch (color) {
      case 'blue':
        bg = '#e6f7ff';
        border = '#91d5ff';
        break;
      case 'purple':
        bg = '#f9f0ff';
        border = '#d3adf7';
        break;
      case 'green':
        bg = '#E6FAEB';
        border = '#73EA95';
        break;
      case 'red':
        bg = '#FFF0EF';
        border = '#F57171';
        break;
      case 'yellow':
        bg = '#FFFAED';
        border = '#FBD36A';
        break;
      case 'orange':
        bg = '#ffc98459';
        border = '#f28705';
        break;
      default:
        // Gray
        bg = '#F1F1F1';
        border = '#8A8A8A';
        break;
    }
  }

  return {
    backgroundColor: bg,
    borderColor: border,
    color: fontColor,
  };
};
