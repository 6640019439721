import { counterFormat } from '@/utils/methods/displayFormat';
import { MdOutlineCheck, MdOutlineExpandMore } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useGetPlanQuotasQuery } from '@/utils/services/quota/quota-endpoints';

const ComparePlans = ({
  isComparePlansOpen,
  toggleComparePlans,
  planUUID,
}: {
  isComparePlansOpen: boolean;
  toggleComparePlans: () => void;
  planUUID: string;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { data: quotas } = useGetPlanQuotasQuery({
    orgID,
  });

  const sections = quotas?.find(
    (quota) => quota.planUUID === planUUID
  )?.sections;

  const renderValue = useCallback((value?: string) => {
    if (!value || value === ':cross:') {
      return <RxCross2 color='red' />;
    }

    if (value === ':tick:') {
      return <MdOutlineCheck color='green' />;
    }

    return counterFormat(value);
  }, []);

  return (
    <div className='py-3 text-left flex flex-col gap-2'>
      <button
        className='text-lg text-first py-1 rounded-sm w-full focus:outline-none
        hover:bg-gray-50 transition-all text-left flex gap-1 items-center'
        onClick={(evt) => {
          evt.preventDefault();
          toggleComparePlans();
        }}
      >
        <MdOutlineExpandMore
          className={`text-first transition-all focus:outline-none ${
            isComparePlansOpen ? '' : '-rotate-90'
          }`}
          size={28}
        />
        Compare Plans
      </button>

      <div
        className={`${
          isComparePlansOpen ? 'h-full' : 'h-0'
        } transition-all overflow-hidden w-full`}
      >
        <div
          className={`${
            isComparePlansOpen ? 'block' : 'hidden'
          } flex flex-col gap-3`}
        >
          {sections?.map((section, index) => (
            <div className='w-full ' key={index.toString()}>
              <div className='rounded-tl-sm rounded-tr-sm'>
                <h6 className='py-1 title-font font-medium text-first-light'>
                  {section.title}
                </h6>
              </div>

              <div className='flex flex-col divide-y divide-[#dde8ff] border-t border-b border-[#dde8ff]'>
                {section.rows.map((row, index) => (
                  <div
                    key={index.toString()}
                    className={`py-1 text-sm gap-2 md:gap-6 2xl:gap-12 grid grid-cols-2 items-center ${
                      index % 2 === 1 ? 'bg-white' : 'bg-[#fcfdff]'
                    }`}
                  >
                    <p>{row.key}</p>
                    <div className='flex justify-start'>
                      {renderValue(row.value)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComparePlans;
