import baseApi from '../base-api';
import { OrgDashboardParams, OrgGenericParams } from '../org/org-endpoints';
import { FiltersGroupInterface } from '@/utils/models/Filter';
import { RequestClass, RequestInterface } from '@/utils/models/Request';
import { listAPIsByUUID } from '../api/api-export-data';
import { listRequestsLogs } from './request-export-data';
import { SequenceClass } from '@/utils/models/Sequence';
import { calcDateTime } from '../methods';
import moment from 'moment';

interface GetRequestLogParams extends OrgGenericParams {
  requestID: string;
}

interface ListHistorySequencesParams extends OrgGenericParams {
  historySequencesIDs: string[];
  currentLogID: string;
}

export interface RequestsLogsPagination {
  requests: RequestClass[];
  marker?: number;
  total: number;
  isEmpty: boolean;
}

export const requestEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listRequestsLogs: build.query<RequestsLogsPagination, OrgDashboardParams>({
      async queryFn(
        { orgID, filters, pageSize, marker, dateTime },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const params: string[] = ['resouce=requests_logs'];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);

        const { start, end } = calcDateTime(dateTime);

        const url = `/organisations/${orgID}/data/query?${params.join('&')}`;
        const result = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            start_date: start,
            end_date: end,
            data: {
              type: 'requests_logs',
              filters,
            },
          },
        });

        const {
          data: requests,
          marker: nextMarker,
          total,
        } = result.data as {
          data: RequestInterface[];
          marker?: number;
          total: number;
        };

        const isEmpty = (!filters || filters.length === 0) && total === 0;

        const apiUUIDs = requests.map((request) => request.apiUUID);
        const apis = await listAPIsByUUID({ orgID, UUIDs: apiUUIDs });
        const requestsWithExtraData = requests.map((request) => {
          const api = apis.find((api) => api.UUID === request.apiUUID);
          return new RequestClass(request, { api });
        });

        return {
          data: {
            requests: requestsWithExtraData,
            marker: nextMarker,
            total,
            isEmpty,
          },
        };
      },
    }),
    getRequestLog: build.query<RequestClass, GetRequestLogParams>({
      async queryFn(
        { orgID, requestID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const currentLogFilter: FiltersGroupInterface[] = [
          {
            groups: [
              {
                field: 'UUID',
                operator: 'is-one-of',
                values: [requestID],
              },
            ],
            operator: 'include',
          },
        ];

        const requestsLogs: RequestsLogsPagination = await listRequestsLogs({
          orgID,
          filters: currentLogFilter,
        });

        if (requestsLogs.requests.length !== 1) {
          return {
            error: 'Request log not found',
          };
        }

        const requestLog = requestsLogs.requests[0];

        return {
          data: requestLog,
        };
      },
    }),
    listHistorySequences: build.query<
      SequenceClass[],
      ListHistorySequencesParams
    >({
      async queryFn(
        { orgID, historySequencesIDs, currentLogID },
        _queryApi,
        _extraOptions
      ) {
        const historySequencesFilters: FiltersGroupInterface[] = [
          {
            groups: [
              {
                field: 'UUID',
                operator: 'is-one-of',
                values: [...historySequencesIDs, currentLogID],
              },
            ],
            operator: 'include',
          },
        ];

        const historySequences: RequestsLogsPagination = await listRequestsLogs(
          {
            orgID,
            filters: historySequencesFilters,
          }
        );

        const requests = [...historySequences.requests]
          .sort((a, b) => b.dateCreated - a.dateCreated)
          .slice(0, 10);

        const historySequencesData = requests.map(
          (request, currentIndex, array) => {
            let diffFromPreviousRequest = 0;
            if (currentIndex > 0) {
              const previousRequest = array[currentIndex - 1];

              const previousMs = moment(previousRequest.dateCreated).valueOf();

              const currentMs = moment(request.dateCreated).valueOf();

              diffFromPreviousRequest = previousMs - currentMs;
            }

            return new SequenceClass({
              order: currentIndex + 1,
              UUID: request.UUID,
              method: request.request.method,
              path: request.request.path,
              dateCreatedInDatetime: request.dateCreatedInDatetime,
              dateCreated: request.dateCreated,
              statusCode: request.response.statusCode,
              avatar: request.avatar,
              typeLabel: request.typeLabel,
              domain: request.request.domain,
              tags: request.tags,
              ip: request.request.ip,
              ipDetails: request.request.ipDetails,
              diffFromPreviousRequest,
            });
          }
        );

        return {
          data: historySequencesData,
        };
      },
    }),
  }),
});

export const {
  useLazyListRequestsLogsQuery,
  useListRequestsLogsQuery,
  useGetRequestLogQuery,
  useListHistorySequencesQuery,
  useLazyGetRequestLogQuery,
} = requestEndpoints;
