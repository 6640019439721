import FieldType from '@/utils/models/FieldType';
import { Button, Form, Upload, UploadFile, UploadProps } from 'antd';
import { Rule } from 'antd/lib/form';
import { useEffect, useState } from 'react';

const UploadFileField = ({ field }: { field: FieldType }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const rules: Rule[] = [];

  useEffect(() => {
    if (field.resetUpload) {
      setFileList([]);
    }
  }, [field]);

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: 'File is required',
    });
  }

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    accept: '.json, .yaml',
    maxCount: 1,
  };

  return (
    <Form.Item
      name={field.attribute}
      label={field.name}
      rules={rules}
      tooltip={field.description}
    >
      <Upload {...props} disabled={field.disabled}>
        <Button type='primary' disabled={field.disabled}>
          Select File
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadFileField;
