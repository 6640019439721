import FieldType from '@/utils/models/FieldType';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useCallback, useEffect, useState } from 'react';
import { FieldBuilder } from '../Form/Fields';
import { DecisionItemType } from '../Modal/ModalDecision';
import { useParams } from 'react-router-dom';
import { ActionClass, ActionIntegrationType } from '@/utils/models/Action';
import {
  useAddActionMutation,
  useUpdateActionMutation,
} from '@/utils/services/actions/actions-endpoints';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import { GrAction, GrCubes } from 'react-icons/gr';
import useRole from '@/utils/hooks/useRole';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';

interface ActionFormProps {
  formInstance: FormInstance<any>;
  action?: ActionClass;
  customActionIntegrations?: Array<IntegrationAvailableClass>;
  managedActionIntegrations?: Array<IntegrationAvailableClass>;
  onSubmitted: () => void;
  actionType?: DecisionItemType;
}

const ActionForm = ({
  formInstance,
  action,
  customActionIntegrations = [],
  managedActionIntegrations = [],
  actionType,
  onSubmitted,
}: ActionFormProps) => {
  const { apiID, orgID } = useParams<{
    apiID: string;
    orgID: string;
  }>();
  const { hasPermissionToCreateActions, hasPermissionToUpdateActions } =
    useRole();

  const fieldBuilder = new FieldBuilder(formInstance);

  const [integrationTypeState, setIntegrationTypeState] =
    useState<ActionIntegrationType>('managed');

  const [managedIntegrationState, setManagedIntegrationState] =
    useState<IntegrationAvailableClass>();

  const [addAction] = useAddActionMutation();

  const [updateAction] = useUpdateActionMutation();

  const { data: api } = useGetApiQuery({ orgID, apiID });

  const scrollToDiv = () => {
    setTimeout(() => {
      const element = document.getElementById('context_inputs_api_endpoint');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const setFormState = useCallback(
    (actionData?: ActionClass) => {
      setManagedIntegrationState(actionData?.managedIntegration);
      setIntegrationTypeState(
        (actionData?.integrationType as ActionIntegrationType) ?? 'managed'
      );

      formInstance.resetFields();
      let contextCustom: { key: string; value: unknown }[] = [];

      if (actionData?.context?.custom) {
        contextCustom = Object.entries(actionData.context.custom).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
      }

      formInstance.setFieldsValue({
        name: actionData?.name,
        enabled: actionData?.enabled ?? true,
        description: actionData?.description,
        action_apiUUID: actionData?.action_apiUUID,
        integrationUUID: actionData?.integrationUUID,
        actionType: actionData?.actionType,
        eventCodes: actionData?.eventCodes,
        scheduledRate: actionData?.scheduledRate ?? 15,
        integrationType: actionData?.integrationType ?? 'managed',
        context: { custom: contextCustom, inputs: actionData?.context?.inputs },
      });
    },
    [formInstance]
  );

  useEffect(() => setFormState(action), [action, actionType, setFormState]);

  const handleSubmit = (values: any) => {
    const defaultData = {
      ...values,
      integrationType: undefined,
      context: { custom: {} },
    };

    if (Array.isArray(values.context.custom)) {
      if (values.context.custom.length === 0) {
        defaultData.context.custom = {};
      }

      if (values.context.custom.length > 0) {
        values.context.custom.forEach(
          ({ key, value }: { key: string; value: string }) => {
            defaultData.context.custom[key] = value;
          }
        );
      }
    } else {
      defaultData.context = values.context;
    }

    if (action) handleUpdateAction(action.UUID, defaultData);
    else handleAddAction(defaultData);
  };

  const handleUpdateAction = (actionID: string, data: any) => {
    updateAction({ orgID, actionID, data })
      .unwrap()
      .then(() => clearStatesAndOnSubmit());
  };

  const handleAddAction = (data: any) => {
    const creationData = {
      ...data,
      action_apiUUID: apiID,
      actionType: actionType?.value,
    };

    addAction({ orgID, data: creationData })
      .unwrap()
      .then(() => clearStatesAndOnSubmit());
  };

  const clearStatesAndOnSubmit = () => {
    setFormState();
    onSubmitted();
  };

  const handleFormValuesChange = (changedValues: any) => {
    if ('integrationType' in changedValues) {
      setIntegrationTypeState(changedValues.integrationType);
      setManagedIntegrationState(undefined);
      formInstance.setFieldValue('integrationUUID', undefined);
      formInstance.setFieldValue('context', undefined);
    }

    if (
      integrationTypeState === 'managed' &&
      'integrationUUID' in changedValues
    ) {
      const integrationAvailable = managedActionIntegrations?.find(
        (i) => i.UUID === changedValues.integrationUUID
      );
      setManagedIntegrationState(integrationAvailable);

      const defaultValues = integrationAvailable?.fields?.reduce(
        (previousValue, _, index, fields) => {
          const f = fields[index];
          const currentValue: string | undefined = formInstance.getFieldValue([
            'context',
            'inputs',
            [f.attribute],
          ]);

          return {
            ...previousValue,
            [f.attribute]: currentValue || f.value,
          };
        },
        {}
      );
      formInstance.setFieldsValue({ context: { inputs: defaultValues } });
    }
  };

  const renderFormFields = () => {
    const fields: Array<FieldType> = [
      {
        attribute: 'apiName',
        name: api?.name || '',
        inputType: 'header',
        avatar: api?.avatar,
      },
      {
        attribute: 'name',
        inputType: 'text',
        placeholder: 'My action',
        name: 'Name',
        validation: {
          required: true,
          max: 256,
        },
        fieldSize: 'extralarge',
        colSize: 'extralarge',
      },
      {
        attribute: 'description',
        inputType: 'textarea',
        placeholder: 'Nice action there',
        name: 'Description',
        validation: {
          max: 500,
          rows: 5,
        },
        fieldSize: 'extralarge',
      },
      {
        attribute: 'enabled',
        inputType: 'switch',
        name: 'Enabled',
        fieldSize: 'extralarge',
        colSize: 'extralarge',
        hidden: !action,
      },
    ];

    if (actionType?.value === 'api_event_action') {
      fields.push({
        attribute: 'eventCodes',
        inputType: 'multiselect',
        placeholder: 'API specification created',
        name: 'Events',
        sourceFrom: { resource: 'action-event-codes' },
        validation: { required: true },
        fieldSize: 'extralarge',
        colSize: 'extralarge',
      });
    }

    if (actionType?.value === 'api_schedule_action') {
      fields.push({
        attribute: 'scheduledRate',
        description:
          'The rate in minutes at which the action should be run (with a minimum of 15).',
        inputType: 'number',
        placeholder: '15',
        name: 'Scheduled Rate (Minutes)',
        validation: {
          min: 15,
          max: 65535,
          required: true,
        },
        fieldSize: 'extralarge',
        colSize: 'extralarge',
      });
    }

    fields.push({
      attribute: 'integrationType',
      inputType: 'detailed-radio-group',
      name: 'Integration Type',
      values: [
        {
          value: 'custom',
          label: 'Custom',
          description: 'Run your own custom integration.',
          icon: <GrCubes size={24} />,
          selected: integrationTypeState !== 'custom',
        },
        {
          value: 'managed',
          label: 'Managed',
          description: 'Out-of-the-box integrations provided by FireTail',
          icon: <GrAction size={24} />,
          selected: integrationTypeState !== 'managed',
        },
      ],
      validation: {
        required: true,
      },
      fieldSize: 'extralarge',
      colSize: 'extralarge',
      columns: 2,
    });

    if (integrationTypeState === 'custom') {
      fields.push(
        {
          attribute: 'integrationUUID',
          inputType: 'select',
          placeholder: 'Integration',
          name: 'Integration',
          sourceFrom: {
            resource: 'integration',
            filters: [
              {
                field: 'notification_type',
                values: customActionIntegrations?.map(
                  (i) => i.notification_type || ''
                ),
              },
            ],
            creationOptions: {
              categories: ['notification'],
              supportsActions: true,
            },
          },
          validation: {
            required: true,
          },
          fieldSize: 'extralarge',
          colSize: 'extralarge',
        },
        {
          attribute: ['context', 'custom'],
          description: 'Key/value pairs for your actions environment.',
          inputType: 'map',
          validation: { max: 5 },
          name: 'Context',
          update: true,
          value: {},
        }
      );
    }

    if (integrationTypeState === 'managed') {
      fields.push({
        attribute: 'integrationUUID',
        inputType: 'radio-group',
        placeholder: 'Managed Action',
        name: 'Managed Action',
        values: managedActionIntegrations?.map((i) => ({
          label: i.name,
          value: i.UUID,
          description: i.description,
        })),
        validation: {
          required: true,
        },
        fieldSize: 'extralarge',
        colSize: 'extralarge',
        columns: 1,
        onValueChange: scrollToDiv,
      });
    }

    if (managedIntegrationState) {
      managedIntegrationState.fields?.forEach((field) => {
        fields.push({
          ...field,
          attribute: ['context', 'inputs', field.attribute],
        });
      });
    }

    return fields.map((field) => fieldBuilder.getFormItem(field as FieldType));
  };

  return (
    <Form
      layout='vertical'
      form={formInstance}
      onFinish={handleSubmit}
      scrollToFirstError
      onValuesChange={handleFormValuesChange}
      style={{
        maxWidth: '50vw',
      }}
      disabled={!hasPermissionToCreateActions && !hasPermissionToUpdateActions}
    >
      <div
        className='grid grid-cols-3 gap-4 overflow-auto -m-6 py-6'
        style={{
          maxHeight: '78vh',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          alignItems: 'center',
        }}
      >
        {renderFormFields()}
      </div>
    </Form>
  );
};

export default ActionForm;
