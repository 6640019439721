import Billing from '@/components/Billing';

const Subscription = () => {
  return (
    <div className='mb-16 mt-6 -mx-4'>
      <Billing.Cards />
    </div>
  );
};

export default Subscription;
