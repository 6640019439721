import { useHistory, useParams } from 'react-router-dom';
import List from '@/components/List';
import { useListSpecificationsQuery } from '@/utils/services/specification/specification-endpoints';
import { useState } from 'react';
import Item from '../List/Item';
import { SpecificationClass } from '@/utils/models/Specification';
import { ColumnsType } from 'antd/lib/table';
import { Button, message, Tooltip } from 'antd';
import useRole from '@/utils/hooks/useRole';
import SpecificationModal from '../Modal/SpecificationModal';
import { useListRequestsLogsQuery } from '@/utils/services/request/request-endpoints';
import Specification from '.';
import { MdFileDownload, MdWarning } from 'react-icons/md';
import {
  useGetQuotasForLoggingDurationQuery,
  useGetSpecificationQuotasQuery,
} from '@/utils/services/quota/quota-endpoints';
import Billing from '@/components/Billing';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import { useLazyGetSpecificationVersionQuery } from '@/utils/services/specification/version-endpoints';
import downloadJSON from '@/utils/methods/downloadJSON';

const SpecificationListing = () => {
  const {
    hasPermissionToCreateSpecification,
    hasPermissionToGetSpecification,
  } = useRole();
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID?: string;
  }>();
  const { query } = useSearchQueryParam();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);

  const {
    data: listSpecificationsData,
    isLoading: isListSpecificationsDataLoading,
  } = useListSpecificationsQuery({ orgID, appID, apiID });

  const { data: durationData } = useGetQuotasForLoggingDurationQuery({ orgID });
  const [getSpecVersion] = useLazyGetSpecificationVersionQuery();

  const history = useHistory();
  const { data } = useListRequestsLogsQuery(
    {
      orgID,
      filters: apiID
        ? [
            {
              groups: [
                { field: 'apiUUID', operator: 'is-one-of', values: [apiID] },
              ],
              operator: 'include',
            },
          ]
        : [],
      dateTime: {
        value: durationData?.retention_days === 90 ? 7776000 : 604800,
      },
    },
    { skip: !showUploadModal }
  );

  const { data: specificationQuota, isLoading: isSpecificationQuotaLoading } =
    useGetSpecificationQuotasQuery({
      orgID,
    });

  const upgradeRequired =
    specificationQuota &&
    (specificationQuota?.current as number) >=
      (specificationQuota?.max as number);

  const specificationsFilteredBySearch: SpecificationClass[] =
    listSpecificationsData
      ? filterSpecificationsBySearchQuery(listSpecificationsData, query)
      : [];

  const handleCloseModal = () => setShowUploadModal(false);

  const handleOpenModal = () => {
    if (upgradeRequired) {
      setIsPlanModalOpen(true);
      return;
    }

    setShowUploadModal(true);
  };

  const handleSpecificationSelected = (specification: SpecificationClass) => {
    return history.push(
      `/organisations/${orgID}/applications/${appID}/apis/${specification.api?.UUID}/specifications/${specification.UUID}`
    );
  };

  const columns: ColumnsType<SpecificationClass> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, specification) =>
        hasPermissionToGetSpecification ? (
          <button
            onClick={() => handleSpecificationSelected(specification)}
            className='text-base text-blue-600 font-semibold text-left'
          >
            {specification.name}
          </button>
        ) : (
          <span className='text-base font-semibold text-left'>
            {specification.name}
          </span>
        ),
    },
    {
      title: 'UUID',
      dataIndex: 'UUID',
      width: 350,
      render: (uuid) => <Item.UUID uuid={uuid} />,
    },

    {
      title: 'API',
      dataIndex: 'api',
      render: (_, specification) => (
        <Item.LogoAndName
          logo={specification.api?.avatar}
          name={specification.api?.name}
        />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, specification) => (
        <Item.Date
          createdFromNow={specification.createdFromNow}
          created={specification.created}
        />
      ),
    },
  ];

  return (
    <>
      <SpecificationModal
        handleCloseModal={handleCloseModal}
        showModal={showUploadModal}
        okText='Submit'
        disableGeneration={!data || data?.total === 0}
      />

      <Specification.Drawer />

      <Billing.Modal
        titleText='You have reached your maximum amount of specifications.'
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <List.Root
        renderEmpty={
          hasPermissionToCreateSpecification
            ? {
                isEmpty: listSpecificationsData?.length === 0 && !query,
                loading: isListSpecificationsDataLoading,
                element: (
                  <List.Empty
                    title='Upload/Generate a Specification'
                    subtitle={`Set up a discovery integration such as API Gateway scanning or GitHub repository scanning to import specification documents that describe the operation of your APIs. You can also upload a specification document directly. Common formats are OpenAPI (formerly Swagger) and JSON schema.`}
                    buttonTxt={'Upload/Generate a Specification'}
                    handleClick={handleOpenModal}
                  />
                ),
              }
            : undefined
        }
      >
        <List.Header>
          <List.Search placeholder='Search specifications' />

          <List.Settings>
            {hasPermissionToCreateSpecification && (
              <Button
                onClick={handleOpenModal}
                type='primary'
                className='flex items-center'
                disabled={isSpecificationQuotaLoading}
              >
                <div className='flex flex-row'>
                  Upload/Generate Specification
                  {upgradeRequired && (
                    <Tooltip title='Upgrade Required'>
                      <MdWarning
                        style={{ color: '#FFE25E' }}
                        size={16}
                        className='ml-2 self-center'
                      />
                    </Tooltip>
                  )}
                </div>
              </Button>
            )}

            <List.QuotaIndicator
              quota={specificationQuota}
              tooltipText={
                specificationQuota?.max === 0
                  ? 'Your current does not support API specifications.'
                  : `You are currently using ${
                      specificationQuota?.current as number
                    } out of your ${
                      specificationQuota?.max as number
                    } allocated API specifications.`
              }
            />
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <List.Pagination dataSource={specificationsFilteredBySearch}>
          <List.Grid
            item={{
              render: (specification: SpecificationClass) => (
                <Item.Root
                  key={specification.key}
                  onClick={
                    hasPermissionToGetSpecification
                      ? () => handleSpecificationSelected(specification)
                      : undefined
                  }
                  id={specification.key}
                >
                  <Item.Header>
                    <Item.Title>{specification.name}</Item.Title>
                  </Item.Header>

                  <div className='flex gap-2 items-center'>
                    <Item.UUID uuid={specification.UUID} />

                    <Button
                      className='flex items-center gap-2'
                      onClick={async (evt) => {
                        evt.stopPropagation();
                        const version = await getSpecVersion({
                          orgID,
                          specificationID: specification.UUID,
                          versionID: specification.latestVersionUUID,
                        }).unwrap();

                        if (version) {
                          downloadJSON(version.spec, `${version.UUID}.json`);
                        } else message.error('No specification version found');
                      }}
                    >
                      <span>Download</span>

                      <MdFileDownload />
                    </Button>
                  </div>

                  <Item.Footer>
                    {specification?.api && (
                      <Item.LogoAndName
                        logo={specification.api?.avatar}
                        name={specification.api?.name}
                      />
                    )}
                    <Item.Date
                      createdFromNow={specification.createdFromNow}
                      created={specification.created}
                    />
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={
              isListSpecificationsDataLoading || isSpecificationQuotaLoading
            }
          />

          <List.Table
            columns={columns}
            loading={
              isListSpecificationsDataLoading || isSpecificationQuotaLoading
            }
          />
        </List.Pagination>
      </List.Root>
    </>
  );
};

const filterSpecificationsBySearchQuery = (
  specifications: SpecificationClass[],
  searchQuery: string
): SpecificationClass[] => {
  const sq = searchQuery.toLowerCase();
  return specifications.filter((specification) => {
    return (
      specification.name.toLowerCase()?.includes(sq) ||
      specification.UUID.toLowerCase()?.includes(sq)
    );
  });
};

export default SpecificationListing;
