import { useParams } from 'react-router-dom';
import { useLazyGetSpecificationQuery } from '@/utils/services/specification/specification-endpoints';
import SwaggerUI from 'swagger-ui-react';
import { Empty } from 'antd';
import './Swagger.css';
import { useEffect } from 'react';

const SpecificationSwaggerUIPreview = ({
  specificationID,
}: {
  specificationID?: string;
}) => {
  const { orgID } = useParams<{
    orgID: string;
  }>();
  const [getSpec, { data: specification }] = useLazyGetSpecificationQuery();

  useEffect(() => {
    if (specificationID) {
      getSpec({ orgID, specificationID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificationID, orgID]);

  return specification && specification?.version?.spec ? (
    <SwaggerUI spec={specification?.version?.spec} />
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description='No specification preview found.'
    />
  );
};

export default SpecificationSwaggerUIPreview;
