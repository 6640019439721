import { Switch, Route } from 'react-router-dom';
import Api from './apiID/api';
import useRole from '@/utils/hooks/useRole';

const ApiID = () => {
  const { hasPermissionToGetApi } = useRole();

  return (
    <Switch>
      {hasPermissionToGetApi && (
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID'
          component={Api}
        />
      )}
    </Switch>
  );
};

export default ApiID;
