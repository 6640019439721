import Container from '@/components/Section/Container';
import useQuery from '@/utils/hooks/useQuery';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const logoWithName = require('../assets/images/logo-dark.png');

const SignupFromAWSMarketplacePage = () => {
  const history = useHistory();
  const { loginWithRedirect, isAuthenticated } = useAuth();
  const query = useQuery();
  const token = query.get('x-amzn-marketplace-token');
  const awsMarketplacePage = `/aws-marketplace?x-amzn-marketplace-token=${token}`;

  useEffect(() => {
    if (isAuthenticated) history.push(awsMarketplacePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awsMarketplacePage, isAuthenticated]);

  const signup = () =>
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
      appState: {
        returnTo: awsMarketplacePage,
      },
    });

  const login = () =>
    loginWithRedirect({
      appState: {
        returnTo: awsMarketplacePage,
      },
    });

  return (
    <Container>
      <div
        className='md:container md:mx-auto bg-white rounded-xl shadow-md  
items-center text-center'
      >
        <section className='px-8 py-32'>
          <div className='flex flex-col gap-6 justify-center items-center'>
            <div className='flex flex-row align-middle'>
              <h1 className='title-font text-3xl md:text-6xl font-bold md:mb-4 text-first'>
                Welcome to <span className='text-second'>FireTail</span>
              </h1>
              <img
                src={logoWithName}
                alt='logo-white'
                className='h-12 ml-5 mt-1'
              />
            </div>
            <span className='text-xl text-gray-600 mb-6'>
              You've been redirected from the AWS Marketplace. To proceed,
              please log in or sign up.
            </span>
            <div className='flex gap-2 justify-center'>
              <button
                className='inline-flex text-white font-bold bg-second border-0 
    py-2 px-6 focus:outline-none hover:bg-second-light rounded-xl text-lg'
                onClick={login}
              >
                Log In
              </button>
              <button
                className='inline-flex text-white font-bold bg-second border-0 
    py-2 px-6 focus:outline-none hover:bg-second-light rounded-xl text-lg'
                onClick={signup}
              >
                Sign Up
              </button>
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default SignupFromAWSMarketplacePage;
