import { Badge } from 'antd';
import { MdClose } from 'react-icons/md';

const ListFilterButton = ({
  counter,
  openModal,
  clearFilter,
  prevDef,
}: {
  counter: number;
  openModal: () => void;
  clearFilter: () => void;
  prevDef?: boolean;
}) => {
  return (
    <div className='flex items-center'>
      <button
        className='flex items-center gap-x-1'
        onClick={
          prevDef
            ? (e) => {
                e.preventDefault();
                openModal();
              }
            : openModal
        }
      >
        <span className='material-symbols-outlined text-lg'>tune</span>
        <span>Filters</span>
        {counter > 0 && <Badge color='gray' count={counter} />}
      </button>
      {counter > 0 && (
        <button
          data-testid='clear-button'
          className='flex items-center p-1'
          onClick={clearFilter}
        >
          <MdClose size={18} />
        </button>
      )}
    </div>
  );
};

export default ListFilterButton;
