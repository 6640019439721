import { ComponentType, FC, useEffect, useState } from 'react';
import {
  useLazyGetIsBusinessEmailQuery,
  useLazyGetUserQuery,
} from '@/utils/services/user/user-endpoints';
import Unverified from '@/pages/unverified';
import { Result } from 'antd';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';

interface WithAuthorizedRequiredOptions {
  onRedirect?: () => JSX.Element;
}

const redirectToUnverifiedPage = (): JSX.Element => <Unverified />;

const VERIFIED_ATT = 'https://_verified';

const withAuthorizedRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthorizedRequiredOptions = {}
): FC<P> => {
  const { onRedirect = redirectToUnverifiedPage } = options;
  return function WithAuthorizedRequired(props: P): JSX.Element {
    const { decodedTokenData, logoutWithRedirect } = useAuth();

    const [getUser, { data: user, isError: isGetUserError, error: userError }] =
      useLazyGetUserQuery();
    const [isBusinessEmail, setIsBusinessEmail] = useState<boolean>();
    const [getIsBusinessEmail, { isError: isGetBusinessEmailError }] =
      useLazyGetIsBusinessEmailQuery();

    // STEP 1 -- Check if the token is verified //
    const tokenIsVerified =
      decodedTokenData && decodedTokenData[VERIFIED_ATT] === true;

    // STEP 2 -- Check if it is a business email //
    useEffect(() => {
      const checkBusinessEmail = async () => {
        // conditional for unit tests
        const preferCacheValue = window.appConfig.REACT_APP_ENV !== 'test';
        getIsBusinessEmail(undefined, preferCacheValue).then((response) => {
          const error: any = response.error;
          if (error && error.isBusinessEmail === false) {
            setIsBusinessEmail(false);
          }

          if (!error) {
            setIsBusinessEmail(true);
          }
        });
      };

      if (tokenIsVerified) checkBusinessEmail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenIsVerified]);

    // STEP 3 -- Get user //
    useEffect(() => {
      if (isBusinessEmail) {
        // conditional for unit tests
        const preferCacheValue = window.appConfig.REACT_APP_ENV !== 'test';
        getUser(undefined, preferCacheValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBusinessEmail]);

    if (isBusinessEmail === false) {
      logoutWithRedirect({
        appState: { returnTo: `/non-business` },
      });
    }

    if (
      userError &&
      typeof userError === 'object' &&
      'status' in userError &&
      userError.status === 401
    )
      return onRedirect();

    if (isGetUserError || isGetBusinessEmailError) {
      let subTitle;
      if (isGetUserError) subTitle = 'Failed to get user details.';

      return (
        <div className='pt-16' data-testid='show-unauthorized'>
          <Result status='error' title={'Bad Request'} subTitle={subTitle} />
        </div>
      );
    }

    if (tokenIsVerified === true && user?.isActive === true)
      return <Component {...props} />;

    if (tokenIsVerified === false || user?.isActive === false)
      return onRedirect();

    if (tokenIsVerified === undefined)
      return (
        <div className='pt-16' data-testid='show-unauthorized'>
          <Result
            status='error'
            title={'Bad Request'}
            subTitle={'Failed to verify the user'}
          />
        </div>
      );

    return <FullScreenLoader />;
  };
};

export default withAuthorizedRequired;
