import SeverityTag from '@/components/Tag/SeverityTag';
import { FindingClass } from '@/utils/models/Finding';
import { Tag } from 'antd';

const FindingInspectorHeader = ({ finding }: { finding?: FindingClass }) => {
  const mapping = finding?.mapping;

  return (
    <div className='w-full relative bg-white shadow-sm border-b border-gray-300'>
      <div className='pl-8 pr-3 py-6 flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='flex gap-2'>
            {finding?.severity && (
              <SeverityTag compact severity={finding.severity} />
            )}
            <h3 className='font-semibold text-lg'>
              {mapping?.title || finding?.code}
            </h3>
          </div>

          <p className=''>{finding?.mapping?.description}</p>
        </div>

        <div className='flex gap-2 text-xs text-gray-600'>
          <span>{finding?.created}</span>
          <span className='text-gray-200'>|</span>
          <span>{finding?.code}</span>
        </div>

        <div className='grid gap-2'>
          <div className='leading-loose'>
            {finding?.tags?.map((tag) => <Tag key={tag}>{tag}</Tag>)}
          </div>
        </div>
      </div>

      <div
        className='absolute h-1 w-full top-0'
        style={{ backgroundColor: finding?.severityColor }}
      />
    </div>
  );
};

export default FindingInspectorHeader;
