import useHubspot from '@/utils/hooks/useHubspot';
import { PlanClass } from '@/utils/models/Plan';
import ComparePlans from './ComparePlans';

const PlanCard = ({
  plan,
  showPrices = true,
  billingPeriod,
  onClick,
  isComparePlansOpen,
  toggleComparePlans,
}: {
  plan: PlanClass;
  showPrices?: boolean;
  billingPeriod: 'monthly' | 'yearly';
  onClick: (uuid: string) => void;
  isComparePlansOpen: boolean;
  toggleComparePlans: () => void;
}) => {
  const { openHubspotChat } = useHubspot();

  const Badge = ({ text }: { text: string }) => (
    <span
      className='bg-second text-white px-3 py-1 tracking-widest text-xs 
absolute right-2 top-2 rounded-sm'
    >
      {text}
    </span>
  );

  return (
    <div className='w-1/4'>
      <div
        className={`h-full p-3 rounded-sm ${
          plan.is_active_plan && 'border-second border-2'
        } 
  flex flex-col justify-between relative overflow-hidden bg-white animate-fade-up opacity-0`}
        style={{
          animationDelay: `0.25s`,
          animationFillMode: 'forwards',
        }}
      >
        <div>
          {plan.is_active_plan ? (
            <Badge text='ACTIVE PLAN' />
          ) : (
            <>{plan.show_popular && <Badge text='POPULAR' />}</>
          )}

          <h2 className='text-left text-lg tracking-widest title-font mb-1 font-medium uppercase'>
            {plan.name}
          </h2>

          {showPrices && (
            <h1 className='text-5xl text-gray-900 leading-none flex items-center'>
              {plan.show_contact
                ? 'Get in touch'
                : `${plan.currency === 'USD' ? '$' : '€'} ${
                    billingPeriod === 'monthly'
                      ? plan?.monthly_pricing
                      : plan?.yearly_pricing
                  }`}
            </h1>
          )}

          <div className='border-b border-gray-200 w-full mt-2 mb-4' />
          <h3 className='mb-4 text-gray-600 text-left h-12'>{plan.subtitle}</h3>
          {plan.points.map(
            (point: { tick: boolean; details: string }, index: number) => (
              <p
                key={index.toString()}
                className='flex items-center text-gray-600 mb-2'
              >
                <span
                  className={`w-4 h-4 mr-2 inline-flex items-center justify-center 
    ${point.tick && 'bg-first text-white'} rounded-full flex-shrink-0`}
                >
                  {point.tick && (
                    <svg
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2.5'
                      className='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>
                  )}
                </span>
                {point.details}
              </p>
            )
          )}

          <ComparePlans
            toggleComparePlans={toggleComparePlans}
            isComparePlansOpen={isComparePlansOpen}
            planUUID={plan.uuid}
          />
        </div>

        {plan.show_button && (
          <div>
            {plan.discount && (
              <div className='text-xs mt-3 mb-2 text-right tracking-widest'>
                Save with annual billing!{' '}
                <span className='bg-orange-100 px-2 py-1 rounded-sm font-medium'>
                  ${plan.discount} off
                </span>
              </div>
            )}
            <button
              onClick={(evt) => {
                evt.preventDefault();

                if (plan.show_contact) {
                  openHubspotChat();
                } else {
                  onClick(plan.uuid);
                }
              }}
              className={`flex items-center text-white hover:bg-first bg-first-light
                 border-0 py-2 px-4 w-full focus:outline-none rounded-sm`}
            >
              {plan.button_text}
              <svg
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-4 h-4 ml-auto'
                viewBox='0 0 24 24'
              >
                <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
