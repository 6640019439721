export type CreatedEventContextType = {
  created: any;
};

export type ChangedOrDeletedEventContextType = {
  before: any;
  after: any;
};

type ActionContextType = {
  actionType: string;
};

export type EventContextType =
  | CreatedEventContextType
  | ChangedOrDeletedEventContextType
  | ActionContextType
  | null;

export class EventContextClass {
  eventCode: string;
  context: EventContextType;

  constructor(ctx: EventContextType, eventCode: string) {
    this.context = ctx;
    this.eventCode = eventCode;
  }

  get isCreated(): boolean {
    return (
      this.context !== null &&
      this.context !== undefined &&
      'created' in this.context
    );
  }

  get created() {
    if (this.isCreated) {
      const ctx = this.context as CreatedEventContextType;
      return ctx.created;
    }

    return undefined;
  }

  get isChangedOrDeleted(): boolean {
    return (
      this.context !== null &&
      this.context !== undefined &&
      'before' in this.context &&
      'after' in this.context
    );
  }

  get isDeleted(): boolean {
    return (
      this.isChangedOrDeleted &&
      this.eventCode.toLowerCase().includes('deleted')
    );
  }

  get isChanged(): boolean {
    return (
      this.isChangedOrDeleted &&
      !this.eventCode.toLowerCase().includes('deleted')
    );
  }

  get before() {
    if (this.isChangedOrDeleted) {
      const ctx = this.context as ChangedOrDeletedEventContextType;
      return ctx.before;
    }

    return undefined;
  }

  get after() {
    if (this.isChangedOrDeleted) {
      const ctx = this.context as ChangedOrDeletedEventContextType;
      return ctx.after;
    }

    return undefined;
  }

  #isContextOf(type: string): boolean {
    if (this.isCreated) {
      const ctx = this.context as CreatedEventContextType;
      return ctx.created.itemType === type;
    }

    if (this.isChangedOrDeleted) {
      const ctx = this.context as ChangedOrDeletedEventContextType;
      return ctx.after.itemType === type;
    }

    return false;
  }

  get isIntegration(): boolean {
    return this.#isContextOf('integration');
  }

  get isSpecificationVersion(): boolean {
    return this.#isContextOf('collection_version');
  }

  get isSpecification(): boolean {
    return this.#isContextOf('collection');
  }

  get isIncident(): boolean {
    return this.#isContextOf('incident');
  }

  get isIncidentPolicy(): boolean {
    return this.#isContextOf('incident_policy');
  }

  get isAlert(): boolean {
    return this.#isContextOf('monitor');
  }

  get isAPI(): boolean {
    return this.#isContextOf('api');
  }

  get isApp(): boolean {
    return this.#isContextOf('app');
  }

  get isDailyMetrics(): boolean {
    if (this.isCreated) {
      const ctx = this.context as CreatedEventContextType;
      return 'histogram_statusCode' in ctx.created;
    }

    return false;
  }

  get isActionTriggered(): boolean {
    if (!!this.context && 'actionType' in this.context) {
      const actionType = (this.context as ActionContextType).actionType;
      return (
        actionType === 'api_schedule_action' ||
        actionType === 'api_event_action'
      );
    }

    return false;
  }

  get isAction(): boolean {
    return this.isActionTriggered || this.#isContextOf('action');
  }
}
