import { NotificationHistoryClass } from '@/utils/models/NotificationHistory';
import { message } from 'antd';
import { MdOutlineContentCopy } from 'react-icons/md';
import { TfiAlert } from 'react-icons/tfi';
import { VscCheck } from 'react-icons/vsc';
import { useCopyToClipboard } from 'usehooks-ts';

const IntegrationNotificationHistoryHeader = ({
  notification,
  showCopyLinkButton = false,
}: {
  notification: NotificationHistoryClass;
  showCopyLinkButton?: boolean;
}) => {
  const [, copy] = useCopyToClipboard();

  return (
    <div className='flex justify-between items-center'>
      <div className='flex gap-2 items-center'>
        {notification.error ? (
          <TfiAlert color='red' size={18} />
        ) : (
          <VscCheck color='green' size={18} />
        )}

        <span
          className='bg-zinc-100 px-2 py-1 rounded-md text-xs 
text-gray-600 '
        >
          {notification.UUID}
        </span>
      </div>

      <div className='flex items-center gap-2'>
        <div
          onClick={(evt) => {
            evt.stopPropagation();
            copy(window.location.href);
            message.success('Link Copied');
          }}
          className={`flex items-center gap-1 px-2 py-1 rounded-md bg-zinc-100 text-gray-700 
            hover:bg-zinc-200 transition-all duration-200 ease-in-out cursor-pointer ${
              showCopyLinkButton ? 'opacity-100' : 'opacity-0'
            }`}
        >
          <p className='text-xs text-gray-700'>Link</p>
          <MdOutlineContentCopy size={12} />
        </div>

        <span className='text-xs text-gray-700'>{notification.created}</span>
      </div>
    </div>
  );
};

export default IntegrationNotificationHistoryHeader;
