import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { AlertClass } from '@/utils/models/Alert';
import AlertsDrawer from './AlertsDrawer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useEditAlertMutation,
  useLazyGetAlertQuery,
} from '@/utils/services/alerting/alerting-endpoints';

const AlertEdition = () => {
  const history = useHistory();
  const { orgID, alertID } = useParams<{ orgID: string; alertID?: string }>();

  const location = useLocation();

  const isEdition = !!alertID;

  const [getAlert, { isLoading: isGettingAlert, isFetching: isFetchingAlert }] =
    useLazyGetAlertQuery();

  const [editAlert, { isLoading: isEditing }] = useEditAlertMutation();

  const [formInstance] = Form.useForm();

  const [alert, setAlert] = useState<AlertClass>();

  const handleOnCloseAlertEdition = () => {
    setAlert(undefined);
    history.replace({
      ...location,
      pathname: `/organisations/${orgID}/monitor-alerting`,
    });
  };

  const handleSubmitAlert = async (data: any) => {
    if (!alert) return;

    return editAlert({
      orgID,
      alertID: alert?.UUID,
      data,
    }).unwrap();
  };

  useEffect(() => {
    if (!alertID) return;

    getAlert({ orgID, alertID }, true)
      .unwrap()
      .then((alertResponse) => setAlert(alertResponse))
      .catch(handleOnCloseAlertEdition);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, alertID, getAlert]);

  return (
    <AlertsDrawer
      isOpen={isEdition}
      formInstance={formInstance}
      onClose={handleOnCloseAlertEdition}
      alert={alert}
      isGettingAlert={isGettingAlert || isFetchingAlert}
      onSubmit={handleSubmitAlert}
      isSubmittingAlert={isEditing}
    />
  );
};

export default AlertEdition;
