import DataDisplay from '../DataDisplay';
import { useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { StatusIncidentType } from '@/utils/models/Incident';
import StatusTicketTag from '@/components/Tag/StatusTicketTag';
import { useUpdateIncidentStatusMutation } from '@/utils/services/incident/incident-endpoints';
import useRole from '@/utils/hooks/useRole';

const IncidentsSelectStatus = ({
  status: currentStatus,
  incidentID,
}: {
  status: StatusIncidentType;
  incidentID: string;
}) => {
  const { hasPermissionToUpdateIncident } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [updateStatus] = useUpdateIncidentStatusMutation();

  const handleUpdateStatus = async (statusToUpdate: StatusIncidentType) => {
    await updateStatus({
      orgID,
      incidentID,
      status: statusToUpdate,
    });
  };

  const handleConfirmStatusUpdate = (statusToUpdate: StatusIncidentType) => {
    Modal.confirm({
      title: 'Confirm status update',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className='pt-2'>
          <span className='text-gray-500'>From:</span>
          <StatusTicketTag status={currentStatus} />
          <br />
          <span className='text-gray-500 pt-2'>To:</span>
          <StatusTicketTag status={statusToUpdate} />
        </div>
      ),
      okText: 'Update',
      cancelText: 'Cancel',
      onOk: () => handleUpdateStatus(statusToUpdate),
    });
  };

  const options: StatusIncidentType[] = ['OPEN', 'CLOSED'];

  return (
    <DataDisplay.Dropdown
      trigger={['click']}
      menu={{
        items: options
          .filter((status) => status !== currentStatus)
          .map((status) => ({
            label: <StatusTicketTag status={status} />,
            key: status,
          })),
        onClick: (evt) => {
          evt.domEvent.stopPropagation();
          const { key: status } = evt;
          handleConfirmStatusUpdate(status as StatusIncidentType);
        },
      }}
      buttonClassName='border border-zinc-200 px-2 py-1 rounded-sm min-w-[7rem]'
      disabled={!hasPermissionToUpdateIncident}
    >
      <StatusTicketTag status={currentStatus} />
    </DataDisplay.Dropdown>
  );
};

export default IncidentsSelectStatus;
