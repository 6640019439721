import { createRoot } from 'react-dom/client';
import 'antd/dist/antd.less';
import './tailwind.css';
import './index.css';
import 'highlight.js/styles/docco.css';
import App from './App';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { store } from './app/store';
import FireTailAuth from './utils/lib/firetail-auth/FireTailAuth';

if (window.appConfig.ENABLE_SENTRY) {
  const {
    BUILD_VERSION,
    REACT_APP_ENV,
    REACT_APP_DSN_SENTRY,
    SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    SENTRY_TRACES_SAMPLE_RATE,
  } = window.appConfig;

  Sentry.init({
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    dsn: REACT_APP_DSN_SENTRY,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    replaysSessionSampleRate: SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    environment: REACT_APP_ENV,
    release: BUILD_VERSION,
  });
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <FireTailAuth>
    <Provider store={store}>
      <App />
    </Provider>
  </FireTailAuth>
);
