import { Button, Tooltip } from 'antd';

type ButtonProps = {
  isLoading?: boolean;
  onClick?: () => void;
  isVisible?: boolean;
  disabled?: boolean;
};

const IntegrationModalFooter = ({
  deleteButton,
  submitButton,
  closeButton,
  createdFromNow,
  created,
}: {
  deleteButton: ButtonProps;
  submitButton: ButtonProps;
  closeButton: ButtonProps;
  createdFromNow?: string;
  created?: string;
}) => {
  return (
    <div className='flex justify-between my-2'>
      <div className='flex gap-4 items-center'>
        {deleteButton.isVisible && (
          <Button
            data-testid='integration-delete-button'
            disabled={deleteButton.disabled}
            onClick={deleteButton.onClick}
            danger
          >
            Delete
          </Button>
        )}

        {createdFromNow && (
          <Tooltip title={created}>
            <span className='whitespace-nowrap text-sm opacity-80'>
              Created {createdFromNow}
            </span>
          </Tooltip>
        )}
      </div>

      <div className='w-full text-right'>
        <Button data-testid='close-button' onClick={closeButton.onClick}>
          Close
        </Button>

        {submitButton.isVisible && (
          <Button
            data-testid='integration-submit-button'
            disabled={submitButton.disabled}
            onClick={submitButton.onClick}
            type='primary'
            loading={submitButton.isLoading}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default IntegrationModalFooter;
