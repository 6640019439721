import moment from 'moment';

type CommonAttributes = {
  UUID: string;
  dateAddedInMicroSeconds: number;
};

export default class BaseEntityClass {
  #dateAddedInMicroSeconds: number;
  key: string;

  constructor(data: CommonAttributes) {
    this.key = data.UUID;
    this.#dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
  }

  get created(): string {
    return moment(this.#dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.#dateAddedInMicroSeconds / 1000).fromNow();
  }
}
