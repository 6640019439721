import { useAppDispatch, useAppSelector } from '@/app/hooks';
import Dashboard from '@/components/Dashboard';
import { AlertType, AlertClass } from '@/utils/models/Alert';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AlertSectionWrapper from './AlertSectionWrapper';
import { dashboardFiltersSelectors } from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import { useListManagedAlertsQuery } from '@/utils/services/alerting/alerting-endpoints';
import { Form, FormInstance, Radio, Select } from 'antd';
import RadioOptions from '@/components/Form/RadioOptions';
import './alert.css';
import {
  FilterClass,
  FilterGroupType,
  FiltersGroupInterface,
} from '@/utils/models/Filter';
import { setDashboardFiltersGroups } from '@/components/Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import useRole from '@/utils/hooks/useRole';
import DebounceSelectField from '@/components/Form/DebounceSelectField';
import { useLazyAutocompleteFilterOptionsQuery } from '@/utils/services/resource/resource-endpoints';
import { useLazyListApisQuery } from '@/utils/services/api/api-endpoints';

const AlertFilters = ({
  alert,
  forceType,
  useCurrentFilters = false,
  formInstance,
  onManagedFilterChange,
}: {
  alert?: AlertClass;
  forceType?: AlertType;
  useCurrentFilters?: boolean;
  formInstance: FormInstance<any>;
  onManagedFilterChange?: (filterID?: string) => void;
}) => {
  const { alertID, orgID } = useParams<{ alertID?: string; orgID: string }>();
  const dispatch = useAppDispatch();
  const { hasPermissionToCreateAlerting, hasPermissionToUpdateAlerting } =
    useRole();
  const isEdition = !!alertID;
  const [autocompleteFilterOptions] = useLazyAutocompleteFilterOptionsQuery();
  const [activeManagedFilter, setActiveManagedFilter] = useState<string>(
    alert?.managed ? alert?.managed_alert_uuid || '' : ''
  );
  const [selectedAlertFilters, setSelectedAlertFilters] = useState<
    FilterGroupType[]
  >([]);
  const filters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const [filterType, setFilterType] = useState<'custom' | 'managed'>(
    alert?.managed || (!alert && (!filters || filters.length === 0))
      ? 'managed'
      : 'custom'
  );

  const [fetchApis] = useLazyListApisQuery();

  const { data: managedAlerts } = useListManagedAlertsQuery({ orgID });

  const handleUpdateActiveManagedFilter = (filterID: string) => {
    setActiveManagedFilter(filterID);
    if (onManagedFilterChange) {
      onManagedFilterChange(filterID);
    }
  };

  useEffect(() => {
    if (alert) {
      const apiFilter = alert?.filters?.find((f) =>
        f.groups.some(
          (group) => group.field === 'apiUUID' && group.operator === 'is-one-of'
        )
      );
      formInstance.setFieldsValue({
        managedFilter: alert?.managed ? alert?.managed_alert_uuid : '',
        managedFilterApiIDs: apiFilter?.groups.find(
          (group) => group.field === 'apiUUID'
        )?.values,
        filterType: alert?.managed ? 'managed' : 'custom',
      });

      setFilterType(alert.managed ? 'managed' : 'custom');
      handleUpdateActiveManagedFilter(
        (alert.managed ? alert.managed_alert_uuid : '') || ''
      );

      setSelectedAlertFilters(() => {
        const filtersToSet = [];
        if (apiFilter) {
          filtersToSet.push(apiFilter);
        }

        if (alert.managed && managedAlerts) {
          filtersToSet.push(
            ...(managedAlerts?.find(
              (managedAlert) => managedAlert.UUID === alert.managed_alert_uuid
            )?.filters as FilterGroupType[])
          );
        }

        if (!alert.managed) {
          const nonApiFilters = alert.filters?.filter((f) => f !== apiFilter);
          filtersToSet.push(...nonApiFilters);
        }

        return [...filtersToSet];
      });

      if (alert.managed && alert.filters) {
        const foundItem = alert?.filters?.find((item) =>
          item.groups.some((group) => group.field === 'apiUUID')
        );
        if (!foundItem) return undefined;

        const groupWithApiUUID = foundItem.groups[0];
        if (!groupWithApiUUID || !groupWithApiUUID?.values?.length)
          return undefined;

        formInstance.setFieldValue(
          'managedFilterApiIDs',
          groupWithApiUUID?.values
        );
      }
    } else if (managedAlerts?.length) {
      const firstAlert = managedAlerts[0];

      if (!filters || filters.length === 0) {
        formInstance.setFieldsValue({
          filterType: 'managed',
          managedFilter: firstAlert.UUID,
        });

        setFilterType('managed');
        handleUpdateActiveManagedFilter(firstAlert.UUID);
        setSelectedAlertFilters(
          FilterClass.mapFiltersGroups(firstAlert.filters as FilterGroupType[])
        );
      } else {
        formInstance.setFieldsValue({
          filterType: 'custom',
        });
        setFilterType('custom');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, managedAlerts]);

  const defaultFilters = useMemo(() => {
    if (useCurrentFilters) return filters;
    if (selectedAlertFilters.length > 0) {
      const parsedSelectedFilters =
        FilterClass.mapFiltersGroups(selectedAlertFilters);
      return parsedSelectedFilters;
    }
    return [];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlertFilters, useCurrentFilters, filterType]);

  useEffect(() => {
    if (defaultFilters && filterType === 'managed') {
      dispatch(setDashboardFiltersGroups(defaultFilters));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters]);

  const isEmptyFilters = defaultFilters.length === 0;
  const noFiltersWereSetOnAnomalyAlert =
    alert?.type === 'anomaly' && isEmptyFilters && isEdition;

  useEffect(() => {
    formInstance.setFieldValue('managedFilter', activeManagedFilter);
  }, [activeManagedFilter, formInstance]);

  if (!alert && isEdition) return <></>;

  const alertType = forceType || alert?.type;

  // TODO: Fix this bug.
  const NoFilters = () => {
    if (isEmptyFilters) {
      return <span className='opacity-60'>No filters were set.</span>;
    }

    return <></>;
  };

  const sectionProps: any = {
    title: 'Filters',
    info: `Apply filters to customize the alert.`,
  };

  if (isEdition && alertType === 'anomaly') {
    sectionProps.info = `Filters cannot be changed on existing alerts, if you want to change filters you will need to create a new anomaly detection alert.`;
  }

  const handleFetchOptions = async (query: string) => {
    const options = await autocompleteFilterOptions(
      {
        orgID,
        resource: 'api',
        field: 'apiUUID',
        query,
      },
      true
    ).unwrap();

    const apiFilter = alert?.filtersGroups.find((f) =>
      f.groups.find((g) => g.field === 'apiUUID' && g.operator === 'is-one-of')
    );

    if (apiFilter && options.length) {
      const apis = apiFilter.groups.find((g) => g.field === 'apiUUID')?.values;
      const apisNotFound = [];

      if (apis) {
        for (const api of apis) {
          const isApiFound = options.some((option) => option.value === api);
          if (!isApiFound) {
            apisNotFound.push(api);
          }
        }

        const apisToAdd = await fetchApis({
          orgID,
          filters: [{ field: 'apiUUID', values: [...apisNotFound] }],
        });

        if (apisToAdd.data?.apis.length) {
          const allApisPresent = [
            ...options,
            ...apisToAdd.data.apis.map((api) => ({
              avatar: api.avatar,
              key: api.UUID,
              value: api.UUID,
              label: api.name,
            })),
          ];
          return allApisPresent;
        }
      }
    }

    return options;
  };

  const handleApiChange = (apiStrings: any) => {
    setSelectedAlertFilters((prevFilters) => {
      const apis = apiStrings as string[];
      if (Array.isArray(apis) && apis.length === 0) {
        return FilterClass.mapFiltersGroups(
          prevFilters.filter(
            (filter) =>
              !filter.groups.some((group) => group.field === 'apiUUID')
          )
        );
      } else {
        const apiFilter: FiltersGroupInterface = {
          groups: [
            {
              field: 'apiUUID',
              operator: 'is-one-of',
              values: Array.isArray(apis) ? apis.map((api) => api) : [apis],
            },
          ],
          operator: 'include',
        };

        let found = false;
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.groups.some((group) => group.field === 'apiUUID')) {
            found = true;
            return { ...filter, groups: [apiFilter.groups[0]] };
          }
          return filter;
        });

        if (!found) {
          updatedFilters.push(apiFilter as FilterGroupType);
        }

        return FilterClass.mapFiltersGroups(
          updatedFilters as FilterGroupType[]
        );
      }
    });
  };

  return (
    <AlertSectionWrapper
      title={sectionProps.title}
      showTitle={sectionProps.showTitle}
      info={sectionProps.info}
      isDefaultCollapseOpen={!noFiltersWereSetOnAnomalyAlert}
    >
      <Form.Item name='filterType'>
        <Radio.Group
          onChange={(event) => {
            setFilterType(event.target.value);
            if (event.target.value === 'custom' && onManagedFilterChange) {
              onManagedFilterChange(undefined);
            }
            if (event.target.value === 'managed') {
              if (!activeManagedFilter) {
                if (isEdition && alert?.managed && alert?.managed_alert_uuid) {
                  setActiveManagedFilter(alert.managed_alert_uuid);
                } else if (managedAlerts) {
                  setActiveManagedFilter(managedAlerts[0].UUID);
                }
              }
            }
          }}
          value={filterType}
          className='w-full mb-2'
          disabled={alertType === 'anomaly' && isEdition}
        >
          <RadioOptions
            options={[
              {
                label: 'Managed',
                value: 'managed',
                description: 'FireTail-provided preconfigured alerts.',
              },
              {
                label: 'Custom',
                value: 'custom',
                description: 'Create your own custom filters for alerts.',
              },
            ]}
            active={filterType}
          />
        </Radio.Group>
      </Form.Item>
      {filterType === 'custom' && (
        <Dashboard.Filters.Root defaultFilters={defaultFilters}>
          <NoFilters />

          {!noFiltersWereSetOnAnomalyAlert ? (
            <Dashboard.Filters.Groups
              disable={
                (alertType === 'anomaly' && isEdition) ||
                (!hasPermissionToCreateAlerting &&
                  !hasPermissionToUpdateAlerting)
              }
              showSaveLoad={alertType === 'static' && isEdition}
              size='small'
              maxHeight={'80vh'}
            />
          ) : (
            <></>
          )}
        </Dashboard.Filters.Root>
      )}
      {filterType === 'managed' && (
        <>
          <Form.Item
            name='managedFilter'
            style={{ width: '100%', marginBottom: 0 }}
          >
            <Radio.Group
              style={{ width: '100%', overflowX: 'auto' }}
              className='no-scrollbar'
              disabled={alertType === 'anomaly' && isEdition}
              onChange={(e) => {
                handleUpdateActiveManagedFilter(e.target.value);
                if (onManagedFilterChange)
                  onManagedFilterChange(e.target.value);

                const foundAlert =
                  managedAlerts?.find(
                    (filter) => filter.UUID === e.target.value
                  )?.filters || [];

                setSelectedAlertFilters((prev) => {
                  const apiFilter = prev.find((filter) =>
                    filter.groups.some((group) => group.field === 'apiUUID')
                  );
                  if (
                    apiFilter &&
                    apiFilter.groups.some(
                      (group) =>
                        group?.values?.length && group.values.length > 0
                    )
                  ) {
                    return FilterClass.mapFiltersGroups([
                      ...(foundAlert as FilterGroupType[]),
                      apiFilter,
                    ]);
                  }

                  return FilterClass.mapFiltersGroups(
                    foundAlert as FilterGroupType[]
                  );
                });
              }}
            >
              <div
                className='grid gap-1'
                style={{
                  gridTemplateColumns: `repeat(${2}, minmax(0, 1fr))`,
                }}
              >
                {managedAlerts?.map((alert, index) => {
                  return (
                    <Radio
                      key={index.toString()}
                      value={alert.UUID}
                      id={alert.UUID}
                      className={`border ${activeManagedFilter === alert.UUID ? 'border-[#7d7d7d] bg-gray-50' : ''} rounded-md p-2 m-0 text-sm`}
                    >
                      <span className='flex items-center'>{alert.name}</span>
                      <span className='text-xs'>{alert.description}</span>
                    </Radio>
                  );
                })}
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label='Filter traffic by API.'
            name='managedFilterApiIDs'
            style={{ width: '100%', marginBottom: 0 }}
            className='mt-2'
          >
            <DebounceSelectField
              fetchOptions={(value) => handleFetchOptions(value)}
              renderOptionLabel={(options) => {
                return options.map((opt) => (
                  <Select.Option key={opt.value} value={opt.value}>
                    <div className='flex flex-row'>
                      <img
                        alt='API Avatar'
                        className='h-5 mr-2 self-center'
                        src={opt.avatar}
                      />
                      <div>{opt.label}</div>
                    </div>
                  </Select.Option>
                ));
              }}
              mode='multiple'
              allowClear
              onChange={handleApiChange}
              disabled={alertType === 'anomaly' && isEdition}
            />
          </Form.Item>
        </>
      )}
    </AlertSectionWrapper>
  );
};

export default AlertFilters;
