import { Button, Modal } from 'antd';
import { useEffect } from 'react';
import useQuery from '@/utils/hooks/useQuery';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import BillingPlansCards from './BillingPlansCards';

const BillingPlansModal = ({
  open,
  setIsOpen,
  helperText,
  titleText,
}: {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  helperText?: string;
  titleText?: string;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const showPage = query.get('show');

  const { data: organisation } = useGetOrganisationQuery({ orgID });

  useEffect(() => {
    if (showPage === 'billing') {
      if (organisation?.isBillingPlatformStripe) setIsOpen(true);
      else alert('Upgrade plan is required to access this feature');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPage, location.hash, organisation?.isBillingPlatformStripe]);

  const handleClose = () => {
    setIsOpen(false);

    query.delete('show');
    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      open={open}
      onCancel={handleClose}
      width={'95%'}
      style={{ top: 25 }}
      footer={
        <div className='relative w-full text-right bg-white h-8'>
          <Button
            className='absolute -right-20'
            data-testid='close-button'
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      }
      bodyStyle={{ backgroundColor: 'rgb(243 244 246)' }}
    >
      <div
        className='overflow-auto -m-6 p-6'
        style={{
          maxHeight: '90vh',
        }}
      >
        <BillingPlansCards titleText={titleText} descriptionText={helperText} />
      </div>
    </Modal>
  );
};

export default BillingPlansModal;
