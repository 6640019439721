import { useCallback } from 'react';
import { JsonParam, QueryParamConfig, useQueryParams } from 'use-query-params';

export type StaticDateTimeMode =
  | 'last-hour'
  | 'last-day'
  | 'last-month'
  | 'last-three-months'
  | 'fixed';

export interface StaticDateTime {
  start?: string;
  end?: string;
  mode?: StaticDateTimeMode;
}

function useStaticDateTimeQueryParam() {
  const [{ dateTime }, setQueryParams] = useQueryParams<{
    dateTime: QueryParamConfig<StaticDateTime | undefined>;
  }>({
    dateTime: JsonParam,
  });

  const setDateTime = useCallback(
    (newDateTime?: StaticDateTime) => {
      setQueryParams({ dateTime: newDateTime });
    },
    [setQueryParams]
  );

  return { dateTime, setDateTime };
}

export default useStaticDateTimeQueryParam;
