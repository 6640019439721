import BaseEntityClass from './BaseEntity';

export interface TokenInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  g_appUUID: string;
  g_orgUUID: string;
  g_tokenUUID: string;
  itemType: string;
  name: string;
  region: string;
  status: string;
  token_secret?: string;
  app_token_appUUID?: string;
  app_token_orgUUID?: string;
  api_token_apiUUID?: string;
  api_token_appUUID?: string;
  api_token_orgUUID?: string;
  g_apiUUID?: string;
}

export class TokenClass extends BaseEntityClass implements TokenInterface {
  UUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  g_appUUID: string;
  g_orgUUID: string;
  g_tokenUUID: string;
  itemType: string;
  name: string;
  region: string;
  status: string;
  token_secret?: string;
  app_token_appUUID?: string;
  app_token_orgUUID?: string;
  api_token_apiUUID?: string;
  api_token_appUUID?: string;
  api_token_orgUUID?: string;
  g_apiUUID?: string;

  constructor(data: TokenInterface) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.g_appUUID = data.g_appUUID;
    this.g_orgUUID = data.g_orgUUID;
    this.g_tokenUUID = data.g_tokenUUID;
    this.itemType = data.itemType;
    this.name = data.name;
    this.region = data.region;
    this.status = data.status;
    this.token_secret = data.token_secret;
    this.app_token_appUUID = data.app_token_appUUID;
    this.app_token_orgUUID = data.app_token_orgUUID;
    this.api_token_apiUUID = data.api_token_apiUUID;
    this.api_token_appUUID = data.api_token_appUUID;
    this.api_token_orgUUID = data.api_token_orgUUID;
    this.g_apiUUID = data.g_apiUUID;
  }
}
