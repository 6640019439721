import { useHistory, useLocation, useParams } from 'react-router-dom';
import Item from '@/components/List/Item';
import List from '@/components/List';
import { useListMyIntegrationsQuery } from '@/utils/services/integration/integration-endpoints';
import { IntegrationClass } from '@/utils/models/Integration';
import { ColumnsType } from 'antd/lib/table';
import useRole from '@/utils/hooks/useRole';
import IntegrationModal from '@/components/Integration/IntegrationModal';
import { useGetIntegrationsQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useCategoryFilterQueryParam from '@/utils/hooks/QueryParam/useCategoryFilterQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const ExistingIntegrations = () => {
  const { hasPermissionToGetIntegration, hasPermissionToCreateIntegration } =
    useRole();
  const { orgID } = useParams<{ orgID: string }>();

  const location = useLocation();
  const history = useHistory();

  const { pagination } = usePaginationQueryParam();
  const { query } = useSearchQueryParam();
  const { category } = useCategoryFilterQueryParam();

  const {
    data: listMyIntegrationsData,
    isLoading: isListMyIntegrationsDataLoading,
    isFetching: isListMyIntegrationsDataFetching,
  } = useListMyIntegrationsQuery({
    orgID,
    filters: [{ field: 'category', values: [category] }],
    query,
    ...pagination,
  });

  const { data: integrationsQuota } = useGetIntegrationsQuotasQuery({
    orgID,
  });

  const handleEdit = async (myIntegration: IntegrationClass) => {
    history.replace({
      ...location,
      pathname: `/organisations/${orgID}/integrations/existing/${myIntegration.UUID}`,
    });
  };

  const columns: ColumnsType<IntegrationClass> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, integration) => (
        <div className='flex items-center gap-2'>
          {hasPermissionToGetIntegration ? (
            <button
              onClick={() => handleEdit(integration)}
              className={`text-base ${
                integration?.available?.plan_upgrade_required
                  ? 'text-gray-500'
                  : 'text-blue-600'
              } font-semibold text-left flex gap-2 items-center`}
            >
              {integration.name}

              {integration?.available?.plan_upgrade_required && (
                <span className='font-normal text-xs text-gray-500'>
                  [Upgrade Required]
                </span>
              )}
            </button>
          ) : (
            <span className='text-base font-semibold text-left'>
              {integration.name}
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (category) => (
        <span>
          <Item.Badge text={category} />
        </span>
      ),
    },
    {
      title: 'Type',
      key: 'avatar',
      width: 150,
      render: (_, integration) => {
        if (!integration.available) return <></>;
        return (
          <div className='justify-center flex'>
            <img
              className='h-6'
              src={integration.available.logoURL}
              alt={integration.available.name}
            />
          </div>
        );
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, integration) => (
        <Item.Date
          createdFromNow={integration.createdFromNow}
          created={integration.created}
        />
      ),
    },
  ];

  const handleCreateIntegration = () =>
    history.push(`/organisations/${orgID}/integrations`);

  const loading =
    isListMyIntegrationsDataLoading || isListMyIntegrationsDataFetching;

  return (
    <>
      <IntegrationModal />

      <List.Root
        renderEmpty={
          hasPermissionToCreateIntegration
            ? {
                isEmpty:
                  category === 'all' &&
                  listMyIntegrationsData?.total === 0 &&
                  !query,
                loading: isListMyIntegrationsDataLoading,
                element: (
                  <List.Empty
                    title='Create an Integration'
                    subtitle='Create an integration to start discovering and logging your APIs.'
                    buttonTxt='Create Integration'
                    handleClick={handleCreateIntegration}
                  />
                ),
              }
            : undefined
        }
      >
        <List.Header>
          <List.Search placeholder='Search integrations' />
          <List.Settings>
            <List.QuotaIndicator
              quota={integrationsQuota}
              tooltipText={
                integrationsQuota?.max === 0
                  ? 'Your current plan does not support integrations.'
                  : `You are currently using ${
                      integrationsQuota?.current as number
                    } out of your ${
                      integrationsQuota?.max as number
                    } allocated integrations.`
              }
            />
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <List.CategoryFilter
          manuallyCategories={['discovery', 'logging', 'notification']}
        />

        <List.Pagination
          dataSource={listMyIntegrationsData?.integrations}
          useURI
          total={listMyIntegrationsData?.total}
        >
          <List.Grid
            item={{
              render: (myIntegration: IntegrationClass) => (
                <Item.Root
                  key={myIntegration.key}
                  onClick={() => handleEdit(myIntegration)}
                  id={myIntegration.key}
                  disabled={!myIntegration.enabled}
                  badge={myIntegration.category}
                  isUpgradeRequired={
                    myIntegration.available?.plan_upgrade_required
                  }
                >
                  <Item.Header>
                    <Item.Title>{myIntegration.name}</Item.Title>
                  </Item.Header>

                  <Item.Footer>
                    <Item.Button
                      label={'Edit'}
                      isUpgradeRequired={myIntegration?.plan_upgrade_required}
                    />

                    {myIntegration.available && (
                      <Item.Logo
                        logoUrl={myIntegration.available.logoURL}
                        name={myIntegration.name}
                      />
                    )}
                  </Item.Footer>
                </Item.Root>
              ),
            }}
            loading={loading}
          />

          <List.Table size='large' columns={columns} loading={loading} />
        </List.Pagination>
      </List.Root>
    </>
  );
};

export default ExistingIntegrations;
