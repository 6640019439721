import { Button, Collapse, Empty, Spin } from 'antd';
import { NotificationHistoryClass } from '@/utils/models/NotificationHistory';
import { useListNotificationsHistoryQuery } from '@/utils/services/integration/integration-endpoints';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import IntegrationNotificationHistoryHeader from './IntegrationNotificationHistoryHeader';
import IntegrationNotificationHistoryDetails from './IntegrationNotificationHistoryDetails';

const { Panel } = Collapse;

const IntegrationNotificationHistoryRoot = ({
  notificationHistoryID,
  setNotificationHistoryID,
}: {
  notificationHistoryID: string | null | undefined;
  setNotificationHistoryID: (newValue: any, updateType?: any) => void;
}) => {
  const { orgID, myIntegrationID } = useParams<{
    orgID: string;
    myIntegrationID: string;
  }>();

  const [
    existingNotificationHistoryIDFromURL,
    setExistingNotificationHistoryIDFromURL,
  ] = useState<string | undefined | null>(notificationHistoryID);

  const [notificationsHistory, setNotificationsHistory] = useState<
    NotificationHistoryClass[]
  >([]);

  const [customPageMarker, setCustomPageMarker] = useState<
    string | undefined | null
  >();

  const { data, isLoading, isFetching } = useListNotificationsHistoryQuery(
    {
      orgID,
      myIntegrationID,
      marker: customPageMarker,
    },
    { skip: !myIntegrationID }
  );

  useEffect(() => {
    if (!data) return;

    setNotificationsHistory((prev) => {
      if (prev.length === 0) {
        return data.notifications;
      }

      return [...prev, ...data.notifications];
    });
  }, [data]);

  const currentPageMarker = data?.marker;

  if (!notificationsHistory || notificationsHistory.length === 0) {
    return (
      <Spin spinning={isLoading || isFetching}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Spin>
    );
  }

  const hasExistingNotificationHistoryIDFromURL =
    !!existingNotificationHistoryIDFromURL;

  const removeNotificationHistoryID = () => {
    setNotificationHistoryID(undefined);
    setExistingNotificationHistoryIDFromURL(undefined);
  };

  const loadMoreNotifications = () => {
    setCustomPageMarker(currentPageMarker);
  };

  return (
    <div className='flex flex-col gap-4' style={{ minHeight: '65vh' }}>
      <div className='flex items-center justify-between'>
        <h4 className='text-xl'>
          {hasExistingNotificationHistoryIDFromURL
            ? 'Notification Details'
            : 'Recent Deliveries'}
        </h4>

        {hasExistingNotificationHistoryIDFromURL && (
          <Button onClick={removeNotificationHistoryID}>See all history</Button>
        )}
      </div>

      {hasExistingNotificationHistoryIDFromURL && (
        <IntegrationNotificationHistoryDetails
          showHeader
          notificationHistoryID={existingNotificationHistoryIDFromURL}
        />
      )}

      {!hasExistingNotificationHistoryIDFromURL && (
        <div>
          <Collapse
            bordered={false}
            accordion
            className='notification-custom-collapse'
            onChange={(notificationHistoryID) => {
              if (typeof notificationHistoryID === 'string') {
                setNotificationHistoryID(notificationHistoryID);
              } else {
                removeNotificationHistoryID();
              }
            }}
          >
            {notificationsHistory?.map((notification) => (
              <Panel
                className='notification-collapse-custom-panel'
                showArrow={false}
                header={
                  <IntegrationNotificationHistoryHeader
                    notification={notification}
                    showCopyLinkButton={
                      notification.UUID === notificationHistoryID
                    }
                  />
                }
                key={notification.UUID}
              >
                <IntegrationNotificationHistoryDetails
                  notificationHistoryID={notification.UUID}
                />
              </Panel>
            ))}
          </Collapse>

          {currentPageMarker && (
            <div className='w-full text-center my-4'>
              <Button
                loading={isLoading || isFetching}
                onClick={loadMoreNotifications}
              >
                Load More
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IntegrationNotificationHistoryRoot;
