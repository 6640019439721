import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListApisPagination } from './api-endpoints';
import { ApiClass } from '@/utils/models/Api';
import { FieldsData } from '@/utils/models/FieldType';

export const getMockCacheListing = ({
  draft,
  api,
  filters,
}: {
  draft: MaybeDrafted<ListApisPagination>;
  api: ApiClass;
  filters?: FieldsData[] | undefined;
}) => {
  if (
    !filters ||
    filters.length === 0 ||
    filters.some((f) => f.field === 'appUUID')
  ) {
    draft.apis.unshift(api);
    draft.total += 1;
    draft.isEmpty = false;
  }

  return draft;
};

export const mockListApisAfterDeletion = ({
  draft,
  apiUUID,
}: {
  draft: MaybeDrafted<ListApisPagination>;
  apiUUID: string;
}) => {
  draft.apis = draft.apis.filter((api) => api.UUID !== apiUUID);
  draft.total -= 1;
  draft.isEmpty = draft.total === 0;
  return draft;
};
