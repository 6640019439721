import { Modal } from 'antd';
import jsYaml from 'js-yaml';

const prepareSpecificationToUpload = async (
  file: File,
  callback: (specData: any) => void
) => {
  const reader = new FileReader();
  reader.onload = async (e: any) => {
    const body = e.target.result;
    try {
      const isJSON = isJsonFile(file);
      const isYAML = isYamlFile(file);
      if (!isJSON && !isYAML)
        throw new Error(
          `Could not identify the file extension, please check and try again. Allowed formats are ".json" and ".yaml".`
        );

      let specData;
      if (isJSON) specData = JSON.parse(body);
      else if (isYAML) specData = jsYaml.load(body);

      if (!specData?.info?.title)
        throw new Error(
          'The title attribute is required. Please add a title for your specification.'
        );
      if (!specData?.info?.version)
        throw new Error(
          'The version attribute is required. Please add a version for your specification.'
        );

      callback(specData);
    } catch (err: any) {
      Modal.error({ title: 'Invalid spec', content: err?.message });
    }
  };
  reader.readAsText(file);
};

function getFileExtension(filename: string) {
  const extension = filename.split('.').pop();
  return extension;
}

function isJsonFile(file: File): boolean {
  const isJSON =
    file.type === 'application/json' || getFileExtension(file.name) === 'json';
  return isJSON;
}

function isYamlFile(file: File): boolean {
  const isYAML =
    file.type === 'application/x-yaml' ||
    getFileExtension(file.name) === 'yaml' ||
    getFileExtension(file.name) === 'yml';
  return isYAML;
}

export default prepareSpecificationToUpload;
