import { ComponentType, useEffect, FC } from 'react';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

const defaultOnRedirecting = (): JSX.Element => <></>;

const defaultOnBeforeAuthentication = async (): Promise<void> => {
  /* noop */
};

const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: {
    returnTo?: string;
    onRedirecting?: () => JSX.Element;
    onBeforeAuthentication?: () => Promise<void>;
  } = {}
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      returnTo = defaultReturnTo(),
      onRedirecting = defaultOnRedirecting,
      onBeforeAuthentication = defaultOnBeforeAuthentication,
    } = options;

    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth();

    useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }

      const opts = { appState: { returnTo } };
      (async (): Promise<void> => {
        await onBeforeAuthentication();
        loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      isAuthenticated,
      loginWithRedirect,
      onBeforeAuthentication,
      returnTo,
    ]);

    // Listen for logout events from other tabs and redirect to the home page
    // when logged out in another tab.
    // See /src/utils/lib/firetail-auth/hooks/useAuth.ts
    useEffect(() => {
      const key = 'logout';
      const storageEventHandler = (event: StorageEvent) => {
        if (event.key !== key || event.storageArea !== localStorage) return;
        window.location.assign('/');
      };
      if (typeof window === 'undefined') return;
      window.addEventListener('storage', storageEventHandler, false);
      return () =>
        window.removeEventListener('storage', storageEventHandler, false);
    }, []);

    if (isLoading) return <FullScreenLoader />;

    return isAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};

export default withAuthenticationRequired;
