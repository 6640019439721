import moment from 'moment';
import { SeveritiesFindingType } from './Finding';
import { EventContextClass, EventContextType } from './EventContext';
import { organizeResourcesFromList } from '../services/methods';

export interface EventMappingInterface {
  code?: string;
  title: string;
  description: string;
}

export interface EventInterface {
  UUID: string;
  code: string;
  context: EventContextType;
  dateOccurredInMicroSeconds: number;
  dateAddedInMicroSeconds: number;
  g_orgUUID: string;
  event_orgUUID: string;
  version: string;
  source: string;
  resources: string[];
  initialFindingSeverities: SeveritiesFindingType;
}

type EventExtraDataType = {
  mapping?: EventMappingInterface;
};

export class EventClass {
  UUID: string;
  code: string;
  context: EventContextClass;
  dateAddedInMicroSeconds: number;
  dateOccurredInMicroSeconds: number;
  g_orgUUID: string;
  resources: string[];
  version: string;
  source: string;
  event_orgUUID: string;
  initialFindingSeverities: SeveritiesFindingType;
  key: string;

  mapping?: EventMappingInterface;
  originalData: EventInterface;

  constructor(data: EventInterface, extraData?: EventExtraDataType) {
    this.originalData = data;
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.dateOccurredInMicroSeconds = data.dateOccurredInMicroSeconds;
    this.context = new EventContextClass(data.context, data.code);
    this.code = data.code;
    this.event_orgUUID = data.event_orgUUID;
    this.resources = data.resources;
    this.version = data.version;
    this.source = data.source;
    this.g_orgUUID = data.g_orgUUID;
    this.initialFindingSeverities = data.initialFindingSeverities;
    this.mapping = extraData?.mapping;
  }

  get mapDataToDownload() {
    return {
      ID: this.UUID,
      Name: this.mapping?.title,
      Description: this.mapping?.description,
      Code: this.code,
      Context: this.context,
      'Date Added in Microseconds': this.dateAddedInMicroSeconds,
      'Date Ocurred in Microseconds': this.dateOccurredInMicroSeconds,
      'Creation Time': this.created,
      'Organization ID': this.event_orgUUID,
      Resources: this.resources,
      Version: this.version,
      Source: this.source,
    };
  }

  get created(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }

  get organizedResources() {
    return organizeResourcesFromList(this.resources);
  }

  get isSeveritiesEmpty(): boolean {
    return (
      !this.initialFindingSeverities?.CRITICAL &&
      !this.initialFindingSeverities?.HIGH &&
      !this.initialFindingSeverities?.MEDIUM &&
      !this.initialFindingSeverities?.LOW &&
      !this.initialFindingSeverities?.INFORMATIONAL
    );
  }
}
