import { Radio } from 'antd';

const RadioOptions = ({
  options,
  active,
  isReadOnly,
  containerClassName = '',
  radioClassName = '',
  labelClassName = '',
}: {
  options: Array<{
    value: string;
    label: string;
    description: string;
    disabled?: boolean;
  }>;
  active?: string;
  isReadOnly?: boolean;
  containerClassName?: string;
  radioClassName?: string;
  labelClassName?: string;
}) => {
  return (
    <div
      className={`flex gap-1 w-full ${containerClassName}`}
      style={{ overflowX: 'auto' }}
    >
      {options.map((opt, index: number) => (
        <Radio
          key={index.toString()}
          disabled={opt.disabled || isReadOnly}
          value={opt.value}
          id={opt.value}
          className={`border ${active === opt.value ? 'border-[#7d7d7d] bg-gray-50' : ''}
                      rounded-sm p-2 m-0 ${radioClassName} grow`}
        >
          <p className={`text-sm ${labelClassName}`}>{opt.label}</p>
          <p className={`text-xs text-gray-500`}>{opt.description}</p>
        </Radio>
      ))}
    </div>
  );
};

export default RadioOptions;
