import {
  counterFormat,
  formatBytes,
  formatMilliseconds,
} from '@/utils/methods/displayFormat';
import {
  DailyMetricsContextClass,
  DailyMetricsPercentiles,
  DailyMetricsStats,
  DailyMetricsUnits,
} from '@/utils/models/DailyMetrics';
import { Box } from '@ant-design/plots';
import { Descriptions } from 'antd';
import { VscDashboard } from 'react-icons/vsc';

const PercentileBoxChart = ({
  data,
  unit,
}: {
  data: any[];
  unit: DailyMetricsUnits;
}) => {
  return (
    <div>
      {unit && <p className='text-gray-500 text-xs mb-2 capitalize'>{unit}</p>}

      <Box
        height={200}
        data={data}
        xField='x'
        legend={false}
        xAxis={false}
        yField={['99%', '75%', '50%', '25%', '1%']}
        boxStyle={{ stroke: '#545454', fill: '#1890FF', fillOpacity: 0.3 }}
        animation={false}
        tooltip={{
          customContent: (title: any, items: any) => {
            return (
              <div className='py-2 text-center'>
                <p className='mb-3'>{title}</p>
                <div className='flex flex-col gap-2'>
                  {items.map((item: any, index: number) => {
                    const name = item?.name;
                    const value = +item?.value;
                    return (
                      <div
                        key={index.toString()}
                        className='flex items-center justify-between gap-4'
                      >
                        <span>{name}</span>

                        <span>
                          {unit === 'bytes' && formatBytes(value)}
                          {unit === 'milliseconds' && formatMilliseconds(value)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

const StatisticsDescription = (statistics: {
  avg: number;
  count: number;
  max: number;
  min: number;
  stdDeviation: number;
  sum: number;
  unit: DailyMetricsUnits;
}) => {
  return (
    <Descriptions bordered size='small' column={{ sm: 1 }}>
      <Descriptions.Item label='Average (Avg)'>
        {statistics.unit === 'bytes' && formatBytes(statistics.avg)}
        {statistics.unit === 'milliseconds' &&
          formatMilliseconds(statistics.avg)}
      </Descriptions.Item>
      <Descriptions.Item label='Count'>
        {counterFormat(statistics.count)} Requests
      </Descriptions.Item>
      <Descriptions.Item label='Maximum (Max)'>
        {statistics.unit === 'bytes' && formatBytes(statistics.max)}
        {statistics.unit === 'milliseconds' &&
          formatMilliseconds(statistics.max)}
      </Descriptions.Item>
      <Descriptions.Item label='Minimum (Min)'>
        {statistics.unit === 'bytes' && formatBytes(statistics.min)}
        {statistics.unit === 'milliseconds' &&
          formatMilliseconds(statistics.min)}
      </Descriptions.Item>
      <Descriptions.Item label='Standard Deviation (Std Dev)'>
        {statistics.unit === 'bytes' && formatBytes(statistics.stdDeviation)}
        {statistics.unit === 'milliseconds' &&
          formatMilliseconds(statistics.stdDeviation)}
      </Descriptions.Item>
      <Descriptions.Item label='Sum'>
        {statistics.unit === 'bytes' && formatBytes(statistics.sum)}
        {statistics.unit === 'milliseconds' &&
          formatMilliseconds(statistics.sum)}
      </Descriptions.Item>
    </Descriptions>
  );
};

const PerformanceItem = ({
  title,
  unit,
  stats,
  percentile,
}: {
  title: string;
  unit: DailyMetricsUnits;
  stats: DailyMetricsStats;
  percentile: DailyMetricsPercentiles;
}) => {
  return (
    <div className='flex flex-col gap-2 bg-white p-4'>
      <h5 className='text-base font-medium'>{title}</h5>

      <div className='grid grid-cols-1 items-start gap-4'>
        <PercentileBoxChart
          unit={unit}
          data={[
            {
              x: title,
              '99%': percentile.values['99.0'],
              '75%': percentile.values['75.0'],
              '50%': percentile.values['50.0'],
              '25%': percentile.values['25.0'],
              '1%': percentile.values['1.0'],
            },
          ]}
        />

        <StatisticsDescription
          unit={unit}
          avg={stats.avg}
          count={stats.count}
          max={stats.max}
          min={stats.min}
          stdDeviation={stats.std_deviation}
          sum={stats.sum}
        />
      </div>
    </div>
  );
};

const DailyMetricsPerfomanceOverview = ({
  dailyMetrics,
}: {
  dailyMetrics: DailyMetricsContextClass;
}) => {
  return (
    <>
      {dailyMetrics.hasPerformanceOverview && (
        <div className='flex flex-col gap-6 bg-gray-100 -mx-6 -mb-8 p-6 '>
          <div className='flex flex-col gap-1'>
            <div className='flex gap-2 items-center'>
              <VscDashboard size={28} />

              <h4 className='text-xl font-semibold'>Performance Overview:</h4>
            </div>

            <p>
              The percentiles provide a detailed view of various performance
              metrics, showcasing key values that help in understanding the
              distribution of data.
            </p>
          </div>

          <div
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 
          2xl:grid-cols-4 gap-x-4 gap-y-5'
          >
            <PerformanceItem
              title='Execution Time'
              unit='milliseconds'
              stats={dailyMetrics.executionTimeStats}
              percentile={dailyMetrics.executionTimePercentiles}
            />

            <PerformanceItem
              title='Combined Header Size'
              unit='bytes'
              stats={dailyMetrics.combinedHeaderSizeStats}
              percentile={dailyMetrics.combinedHeaderSizePercentiles}
            />

            <PerformanceItem
              title='Combined Payload Size'
              unit='bytes'
              stats={dailyMetrics.combinedPayloadSizeStats}
              percentile={dailyMetrics.combinedPayloadSizePercentiles}
            />

            <PerformanceItem
              title='Request Header Size'
              unit='bytes'
              stats={dailyMetrics.requestHeaderSizeStats}
              percentile={dailyMetrics.requestHeaderSizePercentiles}
            />

            <PerformanceItem
              title='Request Payload Size'
              unit='bytes'
              stats={dailyMetrics.requestPayloadSizeStats}
              percentile={dailyMetrics.requestPayloadSizePercentiles}
            />

            <PerformanceItem
              title='Response Header Size'
              unit='bytes'
              stats={dailyMetrics.responseHeaderSizeStats}
              percentile={dailyMetrics.responseHeaderSizePercentiles}
            />

            <PerformanceItem
              title='Response Payload Size'
              unit='bytes'
              stats={dailyMetrics.responsePayloadSizeStats}
              percentile={dailyMetrics.responsePayloadSizePercentiles}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DailyMetricsPerfomanceOverview;
