import { AlertClass, AlertType } from '@/utils/models/Alert';
import { Drawer, Form, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import AlertHeader from './Sections/AlertHeader';
import AlertFooter from './Sections/AlertFooter';
import AlertFilters from './Sections/AlertFilters';
import AlertIntegration from './Sections/AlertIntegration';
import AlertPreview from './Sections/AlertPreview';
import useHubspot from '@/utils/hooks/useHubspot';
import AlertForms from './Sections/AlertForms';
import AlertIdentificationandActivation from './Sections/AlertIdentificationandActivation';
import { selectDashboardFiltersGroups } from '../Dashboard/DashboardFilters/dashboardFiltersGroupsSlice';
import { useAppSelector } from '@/app/hooks';
import { FormInstance } from 'antd/es/form';
import { useGetAnomalyAlertingQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import { useParams } from 'react-router-dom';
import Billing from '../Billing';
import useRole from '@/utils/hooks/useRole';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';

interface AlertsDrawerProps {
  isOpen: boolean;
  forceType?: AlertType;
  formInstance: FormInstance<any>;
  onClose: () => void;
  onSubmit: (data: any) => Promise<AlertClass | void>;
  alert?: AlertClass;
  isGettingAlert?: boolean;
  isSubmittingAlert?: boolean;
  useCurrentFilters?: boolean;
}

const AlertsDrawer = ({
  isOpen,
  forceType,
  formInstance,
  onClose,
  onSubmit,
  alert,
  isGettingAlert,
  isSubmittingAlert,
  useCurrentFilters,
}: AlertsDrawerProps) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToCreateAlerting, hasPermissionToUpdateAlerting } =
    useRole();
  const { addHubspotWidget, removeHubspotWidget } = useHubspot();
  const [managedFilter, setManagedFilter] = useState<string>();

  const { data: anomalyQuota, isLoading: isAnomalyQuotaLoading } =
    useGetAnomalyAlertingQuotasQuery({
      orgID,
    });

  const isEditingRefetching = useSelector(
    (state: RootState) => state.alert.loadingStates['getAlert']
  );

  const isUpgradeRequired =
    anomalyQuota?.max === 0 && alert?.type === 'anomaly';

  const filtersGroups = useAppSelector(selectDashboardFiltersGroups);

  const handleSubmitAlert = async (data: any) => {
    const alert = {
      ...data,
      filters: managedFilter ? [] : data.filters || filtersGroups || [],
    };

    await onSubmit(alert);
    onClose();
  };

  useEffect(() => {
    if (isOpen) removeHubspotWidget();
    else {
      addHubspotWidget();
      formInstance.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Drawer
      width={'100%'}
      placement='right'
      onClose={onClose}
      size='large'
      open={isOpen}
      closable={false}
      drawerStyle={{ backgroundColor: '#F2F3F5' }}
      forceRender
    >
      <Form
        layout='vertical'
        form={formInstance}
        onFinish={handleSubmitAlert}
        className='relative'
        disabled={
          !hasPermissionToCreateAlerting && !hasPermissionToUpdateAlerting
        }
      >
        {isOpen && (
          <>
            <AlertHeader
              forceType={forceType}
              alert={alert}
              handleClose={onClose}
            />

            {isUpgradeRequired && (
              <div className='my-16'>
                <Billing.Cards titleText='Your current plan does not support anomaly detection alert' />
              </div>
            )}

            {!isUpgradeRequired &&
              !isAnomalyQuotaLoading &&
              !isEditingRefetching &&
              ((!isGettingAlert && alert) || !!forceType) && (
                <div className='my-7 -mx-6 overflow-auto'>
                  <div className='grid grid-cols-2 2xl:grid-cols-8 gap-6 my-6 mx-16'>
                    <div className='2xl:col-span-3 grid grid-cols-1 gap-6'>
                      <AlertIdentificationandActivation
                        formInstance={formInstance}
                        alert={alert}
                        forceType={forceType}
                      />
                      <AlertFilters
                        useCurrentFilters={useCurrentFilters}
                        alert={alert}
                        forceType={forceType}
                        formInstance={formInstance}
                        onManagedFilterChange={(filterID?: string) => {
                          setManagedFilter(filterID);
                        }}
                      />
                      <AlertForms
                        forceType={forceType}
                        formInstance={formInstance}
                        alert={alert}
                      />
                      <AlertIntegration
                        formInstance={formInstance}
                        alert={alert}
                        forceType={forceType}
                      />
                    </div>

                    <div className='border-l pl-6 2xl:col-span-5'>
                      <AlertPreview
                        forceType={forceType}
                        formInstance={formInstance}
                        alert={alert}
                      />
                    </div>
                  </div>
                </div>
              )}

            {(isGettingAlert ||
              isAnomalyQuotaLoading ||
              isEditingRefetching) && (
              <div className='my-12'>
                <Skeleton active />
              </div>
            )}

            <AlertFooter
              formInstance={formInstance}
              alert={alert}
              handleClose={onClose}
              isLoading={isSubmittingAlert}
              showSubmit={!isUpgradeRequired}
              showUpdate={!isUpgradeRequired}
            />
          </>
        )}
      </Form>
    </Drawer>
  );
};

export default AlertsDrawer;
