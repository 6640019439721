import {
  ActionClass,
  ActionInterface,
  ActionType,
} from '@/utils/models/Action';
import baseApi from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import {
  IntegrationAvailableClass,
  IntegrationAvailableInterface,
} from '@/utils/models/IntegrationAvailableInterface';
import { listActionAvailableIntegrations } from './actions-export-data';
import { getEventsMappings } from '../local-api-export-data';
import {
  mockGetActionAfterEdition,
  mockListActionsAfterAddition,
  mockListActionsAfterDeletion,
  mockListActionsAfterEdition,
} from './actions-methods';
import { getAPI, listAPIsByUUID } from '../api/api-export-data';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';
import { actionsQuotaEndpoint } from '../quota/quota-endpoints';
import { listSpecifications } from '../specification/specification-export-data';
import { FieldsData } from '@/utils/models/FieldType';
import { ApiClass } from '@/utils/models/Api';

interface ListActionsParams extends OrgGenericParams {
  apiID?: string;
  query?: string;
  pageSize?: number;
  marker?: number;
  sort?: { order?: 'asc' | 'desc'; field?: string };
  actionType?: ActionType;
  fetchApis?: boolean;
}

interface GetActionParams extends OrgGenericParams {
  actionID: string;
}

interface UpdateActionParams extends GetActionParams {
  data: any;
}

interface AddActionsParams extends OrgGenericParams {
  data: any;
}

interface ListActionAvailableIntegrationsParams extends OrgGenericParams {
  appID?: string;
  apiID?: string;
}

export interface ActionPaginationResponse {
  action: ActionClass[];
  total: number;
}

export const actionsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listActions: build.query<ActionPaginationResponse, ListActionsParams>({
      async queryFn(actionsParams, _queryApi, _extraOptions, fetchWithBQ) {
        sessionStorage.setItem(
          'listActionsParams',
          JSON.stringify(actionsParams)
        );
        const {
          orgID,
          apiID,
          query,
          marker,
          pageSize,
          sort,
          actionType,
          fetchApis = false,
        } = actionsParams;

        const params: string[] = [];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);

        const filters: FieldsData[] = [];
        if (actionType) {
          filters.push({ field: 'actionType', values: [actionType] });
        }

        if (apiID) {
          filters.push({ field: 'apiUUID', values: [apiID] });
        }

        const url = `/organisations/${orgID}/search?resource=action${
          params.length > 0 ? '&' : ''
        }${params.join('&')}`;

        try {
          const postData = {
            url,
            method: 'POST',
            data: {
              resource: 'action',
              search_value: query,
              filters,
              sort: sort || { order: 'desc' },
            },
          };

          const searchResult = await fetchWithBQ(postData);
          const { action: actions, total } = searchResult.data as {
            action: ActionInterface[];
            total: number;
          };

          const listOfApis = actions.map((action) => action.action_apiUUID);

          let apis: ApiClass[] = [];
          if (fetchApis) {
            apis = await listAPIsByUUID({
              UUIDs: listOfApis,
              orgID,
            });
          }

          return {
            data: {
              action: actions.map((action) => {
                const api = apis.find((a) => a.UUID === action.action_apiUUID);
                return new ActionClass(action, { api });
              }),
              total,
            },
          };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.action.map(({ UUID }) => ({
                type: 'Actions' as const,
                id: UUID,
              })),
              { type: 'Actions', id: 'LIST' },
            ]
          : [{ type: 'Actions', id: 'LIST' }],
    }),
    addAction: build.mutation<ActionClass, AddActionsParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/actions`,
        method: 'POST',
        data,
      }),
      transformResponse: (rawResult: { action: ActionInterface }) =>
        new ActionClass(rawResult.action),
      onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(
          (response) => {
            const actionsParams = JSON.parse(
              sessionStorage.getItem('listActionsParams') || ''
            );
            dispatch(
              actionsEndpoints.util.updateQueryData(
                'listActions',
                actionsParams,
                (draft) => {
                  return mockListActionsAfterAddition({
                    draft,
                    action: response.data,
                  });
                }
              )
            );

            dispatch(
              actionsQuotaEndpoint.util.updateQueryData(
                'actions',
                { orgID },
                (draft) => mockIncrementQuota(draft)
              )
            );

            setTimeout(() => {
              dispatch(
                actionsEndpoints.util.invalidateTags([
                  { type: 'Actions', id: 'LIST' },
                  'Quotas',
                ])
              );
            }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
          },
          (error) => {
            console.error('Failed to add action:', error);
          }
        );
      },
    }),
    updateAction: build.mutation<ActionClass, UpdateActionParams>({
      query: ({ orgID, actionID, data }) => ({
        url: `/organisations/${orgID}/actions/${actionID}`,
        method: 'PUT',
        data,
      }),
      transformResponse: (rawResult: { action: ActionInterface }) =>
        new ActionClass(rawResult.action),
      onQueryStarted({ orgID, actionID, data }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(
          (response) => {
            const actionsParams = JSON.parse(
              sessionStorage.getItem('listActionsParams') || ''
            );
            dispatch(
              actionsEndpoints.util.updateQueryData(
                'listActions',
                actionsParams,
                (draft) => {
                  return mockListActionsAfterEdition({
                    draft,
                    action: response.data,
                  });
                }
              )
            );

            dispatch(
              actionsEndpoints.util.updateQueryData(
                'getAction',
                { orgID, actionID },
                (draft) =>
                  mockGetActionAfterEdition({
                    action: response.data,
                  })
              )
            );

            setTimeout(() => {
              dispatch(
                actionsEndpoints.util.invalidateTags([
                  { type: 'Actions', id: actionID },
                  'Quotas',
                ])
              );
            }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
          },
          (error) => {
            console.error('Failed to update action:', error);
          }
        );
      },
    }),
    deleteAction: build.mutation<void, GetActionParams>({
      query: ({ orgID, actionID }) => ({
        url: `/organisations/${orgID}/actions/${actionID}`,
        method: 'DELETE',
      }),
      onQueryStarted({ orgID, actionID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(
          () => {
            const actionsParams = JSON.parse(
              sessionStorage.getItem('listActionsParams') || ''
            );
            dispatch(
              actionsEndpoints.util.updateQueryData(
                'listActions',
                actionsParams,
                (draft) =>
                  mockListActionsAfterDeletion({
                    draft,
                    actionID,
                  })
              )
            );

            dispatch(
              actionsQuotaEndpoint.util.updateQueryData(
                'actions',
                { orgID },
                (draft) => mockDecrementQuota(draft)
              )
            );

            setTimeout(() => {
              dispatch(
                actionsEndpoints.util.invalidateTags([
                  { type: 'Actions', id: 'LIST' },
                  'Quotas',
                ])
              );
            }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
          },
          (error) => {
            console.error('Failed to delete action:', error);
          }
        );
      },
    }),
    getAction: build.query<ActionClass, GetActionParams>({
      async queryFn(
        { orgID, actionID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        try {
          const actionResult = await fetchWithBQ(
            `/organisations/${orgID}/actions/${actionID}`
          );
          const { action } = actionResult.data as { action: ActionInterface };

          let managedIntegration: IntegrationAvailableClass | undefined;
          if (action.integrationType === 'managed') {
            const { managed } = await listActionAvailableIntegrations(orgID);
            managedIntegration = managed.find(
              (i) => i.UUID === action.integrationUUID
            );
          }

          return {
            data: new ActionClass(action, { managedIntegration }),
          };
        } catch (e: any) {
          return { error: e.message };
        }
      },
      providesTags: (result, error, { actionID }) => [
        { type: 'Actions', id: actionID },
      ],
    }),
    listActionEventCodes: build.query<
      { title: string; code: string }[],
      OrgGenericParams
    >({
      async queryFn({ orgID }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const eventCodesResult = await fetchWithBQ(
            `/organisations/${orgID}/actions/event-codes`
          );
          const { event_codes: eventCodes } = eventCodesResult.data as {
            event_codes: string[];
          };

          const mappingsResult = await getEventsMappings();

          return {
            data: eventCodes.map((code) => ({
              title: mappingsResult[code]?.title || code,
              code,
            })),
          };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
    listActionAvailableIntegrations: build.query<
      {
        custom: IntegrationAvailableClass[];
        managed: IntegrationAvailableClass[];
      },
      ListActionAvailableIntegrationsParams
    >({
      async queryFn(
        { orgID, appID, apiID },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        try {
          const integrationsResult = await fetchWithBQ(
            `/organisations/${orgID}/actions/integrations?resource=action`
          );
          const { custom, managed } = integrationsResult.data as {
            custom: IntegrationAvailableInterface[];
            managed: IntegrationAvailableInterface[];
          };

          const managedWithExtraData: IntegrationAvailableClass[] = [];
          managed.forEach(async (integration) => {
            integration.fields?.forEach(async (field) => {
              // It'll populate the field value with the first endpoint discovered
              if (field.sourceFrom?.resource === 'api-endpoints' && apiID) {
                const api = await getAPI({ orgID, apiID });
                const endpoints =
                  api?.ft_integration?.discovered_endpoints || [];
                if (endpoints.length > 0) field.value = endpoints[0];
              }

              // It'll populate the field value with the first specification
              if (
                field.sourceFrom?.resource === 'specifications' &&
                appID &&
                apiID
              ) {
                const specifications = await listSpecifications({
                  orgID,
                  appID,
                  apiID,
                  fetchApis: false,
                });
                if (specifications.length > 0)
                  field.value = specifications[0].UUID;
              }
            });

            managedWithExtraData.push(
              new IntegrationAvailableClass(integration)
            );
          });

          return {
            data: {
              custom: custom.map((i) => new IntegrationAvailableClass(i)),
              managed: managedWithExtraData,
            },
          };
        } catch (e: any) {
          return { error: e.message };
        }
      },
    }),
  }),
});

export const {
  useListActionsQuery,
  useLazyListActionsQuery,
  useLazyGetActionQuery,
  useGetActionQuery,
  useAddActionMutation,
  useListActionEventCodesQuery,
  useDeleteActionMutation,
  useListActionAvailableIntegrationsQuery,
  useUpdateActionMutation,
} = actionsEndpoints;
