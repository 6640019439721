import { FieldsData } from '@/utils/models/FieldType';
import { baseApi } from '../base-api';
import {
  IncidentPolicyClass,
  IncidentPolicyInterface,
} from '@/utils/models/IncidentPolicy';
import { SeveritiesFindingType } from '@/utils/models/Finding';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { incidentsPolicyQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';
import {
  mockListIncidentPoliciesAfterAdition,
  mockListIncidentPoliciesAfterDeletion,
} from './incident-policy-methods';

interface GenericParams {
  orgID: string;
  throwError?: boolean;
}

interface IncidentPolicyDataBody {
  minimumSeverities: SeveritiesFindingType;
  notificationIntegrations: string[];
  name: string;
  frequency: number;
  period: number;
  filters?: FieldsData[];
}

interface IncidentPolicyParams extends GenericParams {
  incidentPolicyID: string;
}

interface AddIncidentPolicyParams extends GenericParams {
  data: IncidentPolicyDataBody;
}

interface EditIncidentPolicyParams extends IncidentPolicyParams {
  data: IncidentPolicyDataBody;
}

interface ListIncidentPoliciesParams extends GenericParams {
  pageSize?: number;
  marker?: number;
  query?: string;
}

export interface ListIncidentPoliciesResponse {
  incidents: IncidentPolicyClass[];
  marker?: number;
  total: number;
}

export const incidentPolicyEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listIncidentPolicies: build.query<
      ListIncidentPoliciesResponse,
      ListIncidentPoliciesParams
    >({
      query: (args) => {
        const { orgID, marker, pageSize, query } = args;
        sessionStorage.setItem(
          'listIncidentPoliciesArgs',
          JSON.stringify(args)
        );

        const resource = 'incident_policy';

        const params: string[] = [`resource=${resource}`];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);
        const url = `/organisations/${orgID}/search?${params.join('&')}`;

        return {
          url,
          method: 'POST',
          data: {
            search_value: query,
            resource,
            sort: { order: 'desc' },
          },
        };
      },
      transformResponse: (rawResult: {
        incident_policy: IncidentPolicyInterface[];
        marker?: number;
        total: number;
      }) => ({
        incidents: rawResult.incident_policy.map(
          (incidentPolicy) => new IncidentPolicyClass(incidentPolicy)
        ),
        marker: rawResult.marker,
        total: rawResult.total,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.incidents.map(({ UUID }) => ({
                type: 'IncidentPolicy' as const,
                id: UUID,
              })),
              { type: 'IncidentPolicy', id: 'LIST' },
            ]
          : [{ type: 'IncidentPolicy', id: 'LIST' }],
    }),
    getIncidentPolicy: build.query<IncidentPolicyClass, IncidentPolicyParams>({
      query: ({ orgID, incidentPolicyID, throwError = true }) =>
        `/organisations/${orgID}/incidents/policy/${incidentPolicyID}${
          throwError ? '' : '?ntae' // ntae = not throw an error
        }`,
      transformResponse: (rawResult: {
        incident_policy: IncidentPolicyInterface;
      }) => {
        return new IncidentPolicyClass(rawResult['incident_policy']);
      },
      providesTags: (result, error, { incidentPolicyID }) => [
        { type: 'IncidentPolicy', id: incidentPolicyID },
      ],
    }),
    editIncidentPolicy: build.mutation<
      IncidentPolicyClass,
      EditIncidentPolicyParams
    >({
      query: ({ orgID, incidentPolicyID, data }) => ({
        url: `/organisations/${orgID}/incidents/policy/${incidentPolicyID}`,
        method: 'PUT',
        data: {
          ...data,
        },
      }),
      // TODO: Uncomment this when the backend is ready
      // transformResponse: (rawResult: {
      //   incident_policy: IncidentPolicyInterface;
      // }) => new IncidentPolicyClass(rawResult['incident_policy']),
      async onQueryStarted({ incidentPolicyID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          // TODO: Uncomment this when the backend is ready

          // const listIncidentPoliciesArgs = JSON.parse(
          //   sessionStorage.getItem('listIncidentPoliciesArgs') || '{}'
          // );

          // dispatch(
          //   incidentPolicyEndpoints.util.updateQueryData(
          //     'listIncidentPolicies',
          //     listIncidentPoliciesArgs,
          //     (draft) =>
          //       mockListIncidentPoliciesAfterEdition({
          //         draft,
          //         incidentPolicy: response.data,
          //       })
          //   )
          // );

          setTimeout(() => {
            dispatch(
              incidentPolicyEndpoints.util.invalidateTags([
                { type: 'IncidentPolicy', id: incidentPolicyID },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteIncidentPolicy: build.mutation<any, IncidentPolicyParams>({
      query: ({ orgID, incidentPolicyID }) => ({
        url: `/organisations/${orgID}/incidents/policy/${incidentPolicyID}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { orgID, incidentPolicyID },
        { dispatch, queryFulfilled }
      ) {
        queryFulfilled.then(() => {
          const listIncidentPoliciesArgs = JSON.parse(
            sessionStorage.getItem('listIncidentPoliciesArgs') || '{}'
          );

          dispatch(
            incidentPolicyEndpoints.util.updateQueryData(
              'listIncidentPolicies',
              listIncidentPoliciesArgs,
              (draft) =>
                mockListIncidentPoliciesAfterDeletion({
                  draft,
                  incidentPolicyID,
                })
            )
          );

          dispatch(
            incidentsPolicyQuotaEndpoint.util.updateQueryData(
              'incident_policies',
              { orgID },
              (draft) => mockDecrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              incidentPolicyEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'IncidentPolicy', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    addIncidentPolicy: build.mutation<
      IncidentPolicyClass,
      AddIncidentPolicyParams
    >({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/incidents/policy`,
        method: 'POST',
        data,
      }),
      transformResponse: (rawResult: {
        incident_policy: IncidentPolicyInterface;
      }) => new IncidentPolicyClass(rawResult['incident_policy']),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const listIncidentPoliciesArgs = JSON.parse(
            sessionStorage.getItem('listIncidentPoliciesArgs') || '{}'
          );

          dispatch(
            incidentPolicyEndpoints.util.updateQueryData(
              'listIncidentPolicies',
              listIncidentPoliciesArgs,
              (draft) =>
                mockListIncidentPoliciesAfterAdition({
                  draft,
                  incidentPolicy: response.data,
                })
            )
          );

          dispatch(
            incidentsPolicyQuotaEndpoint.util.updateQueryData(
              'incident_policies',
              { orgID },
              (draft) => mockIncrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              incidentPolicyEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'IncidentPolicy', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
  }),
});

export const {
  useGetIncidentPolicyQuery,
  useLazyGetIncidentPolicyQuery,
  useEditIncidentPolicyMutation,
  useListIncidentPoliciesQuery,
  useLazyListIncidentPoliciesQuery,
  useAddIncidentPolicyMutation,
  useDeleteIncidentPolicyMutation,
} = incidentPolicyEndpoints;
