import moment from 'moment';
import { MdUpdate } from 'react-icons/md';

const ListUpdate = ({
  fulfilledTimeStamp,
  disabled,
  handleUpdate,
}: {
  fulfilledTimeStamp?: number;
  disabled?: boolean;
  handleUpdate: () => void;
}) => {
  return (
    <>
      <span>Updated {moment(fulfilledTimeStamp).fromNow()}</span>
      <button
        disabled={disabled}
        className={`flex items-center gap-x-1 ${disabled && 'opacity-50'}`}
        onClick={handleUpdate}
      >
        <MdUpdate />
        <span>Reload Data</span>
      </button>
    </>
  );
};

export default ListUpdate;
