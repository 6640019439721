import { counterFormat } from '@/utils/methods/displayFormat';
import { SeverityFindingType } from '@/utils/models/Finding';
import { useGetFindingsOverviewQuery } from '@/utils/services/finding/finding-endpoints';
import { Link, useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { MdOutlineExpandMore } from 'react-icons/md';
import { Spin } from 'antd';
import { FieldsData } from '@/utils/models/FieldType';

const DashboardFindingsOverview = ({
  light,
  hideViewFindings,
  manualFilters,
}: {
  light?: boolean;
  hideViewFindings?: boolean;
  manualFilters?: FieldsData[];
}) => {
  const { orgID, apiID, appID } = useParams<{
    orgID: string;
    apiID?: string;
    appID?: string;
  }>();
  const textColor = light ? '' : 'text-anti-flash';
  const labelTextColor = light ? '' : 'text-gray-100';
  const linkTextColor = light ? '' : 'text-gray-300';

  const {
    data: counts,
    isLoading,
    isFetching,
  } = useGetFindingsOverviewQuery({
    orgID,
    filters: manualFilters || [{ field: 'apis', values: apiID ? [apiID] : [] }],
  });
  const loading = isLoading || isFetching;
  const severitiesLevels: SeverityFindingType[] = [
    'critical',
    'high',
    'medium',
    'low',
    'information',
  ];

  return (
    <Spin spinning={loading}>
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-4'>
          <span className={`${textColor} text-xl`}>Findings</span>
          <Loader spinning={loading} />
        </div>

        <div className='grid grid-cols-2 gap-x-8 gap-y-5'>
          {severitiesLevels.map((level) => (
            <div
              key={level}
              className='flex-col justify-start items-end gap-2 inline-flex'
            >
              <div className='w-full justify-between items-center inline-flex'>
                <div
                  className={`capitalize ${labelTextColor} text-sm font-normal font-['Titillium Web'] leading-[16.80px]`}
                >
                  {level}
                </div>
                <div className='justify-end items-center gap-1 flex'>
                  <div
                    className={`${labelTextColor} text-base font-semibold font-['Titillium Web'] leading-none`}
                  >
                    {counts ? counterFormat(counts[level].value) : 0}
                  </div>
                </div>
              </div>
              <div className='w-full flex'>
                <div
                  className='h-[5px] bg-[#227AFF] rounded-l justify-start items-start gap-2.5 inline-flex'
                  style={{
                    width: counts ? `${counts[level].percentage}%` : '0%',
                  }}
                />
                <div
                  className='w-full h-[5px] bg-neutral-400 rounded-r bg-opacity-20  justify-start items-start gap-2.5 inline-flex'
                  style={{
                    width: counts
                      ? `${100 - counts[level].percentage}%`
                      : '100%',
                  }}
                />
              </div>
            </div>
          ))}
          <div
            className={`w-full h-4 justify-end items-start gap-1 inline-flex ${
              hideViewFindings ? 'hidden' : ''
            }`}
          >
            <Link
              to={
                apiID
                  ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/findings`
                  : `/organisations/${orgID}/posture-management/findings`
              }
              className='inline-flex hover:opacity-80 items-center'
            >
              <div
                className={`text-right ${linkTextColor} text-sm font-normal leading-none tracking-tight`}
              >
                View findings
              </div>
              <MdOutlineExpandMore
                className={`${linkTextColor} transition-all focus:outline-none -rotate-90`}
                size={16}
              />
            </Link>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DashboardFindingsOverview;
