import { store } from '@/app/store';
import quotaEndpoints, {
  QuotasForLoggingDurationResponse,
  integrationsQuotaEndpoint,
} from './quota-endpoints';
import { IntegrationsQuotaType } from '@/utils/models/Quota';

export const getIntegrationsQuotas = async (orgID: string) => {
  const response = await store.dispatch<any>(
    integrationsQuotaEndpoint.endpoints.integrations.initiate({
      orgID,
    })
  );
  const quotas = response.data as IntegrationsQuotaType;
  return quotas;
};

export const getQuotasForLoggingDuration = async (orgID: string) => {
  const response = await store.dispatch<any>(
    quotaEndpoints.endpoints.getQuotasForLoggingDuration.initiate({
      orgID,
    })
  );
  const quotasForLoggingDuration =
    response.data as QuotasForLoggingDurationResponse;
  return quotasForLoggingDuration;
};
