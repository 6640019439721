import { MdClose } from 'react-icons/md';

const DashboardRequestDetailsHeader = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <div className='flex items-center pb-4 justify-between'>
      <div className='flex items-center gap-x-2'>
        <button className='focus:outline-none' onClick={onClose}>
          <MdClose size={22} />
        </button>
        <span className='text-base'>Log Details</span>
      </div>
    </div>
  );
};

export default DashboardRequestDetailsHeader;
