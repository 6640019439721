import { store } from '@/app/store';
import { EventMappingInterface } from '@/utils/models/Event';
import { localApi } from './local-api';
import { FindingMappingInterface } from '../models/Finding';

export const getEventsMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listEventsMappings.initiate()
  );
  const mappings = mappingResult.data as {
    [key: string]: EventMappingInterface;
  };
  return mappings || {};
};

export const getFindingMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listFindingMappings.initiate()
  );
  const mappings = mappingResult.data as {
    [key: string]: FindingMappingInterface;
  };
  return mappings || {};
};

export const getOwaspMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listOwaspMappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};

export const getMitreTechniqueMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listMitreTechniqueMappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};

export const getMitreTacticsMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listMitreTacticsMappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};

export const getMitreCweTop25Mappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listMitreCweTop25Mappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};

export const getCisAsgMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listCisAsgMappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};

export const getFindingsOwaspMappings = async () => {
  const mappingResult = await store.dispatch<any>(
    localApi.endpoints.listFindingsOwaspMappings.initiate()
  );
  const mappings = mappingResult.data;
  return mappings || {};
};
