import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { getAPI } from '../api/api-export-data';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { TokenClass, TokenInterface } from '@/utils/models/Token';

interface TokenGenericParams extends OrgGenericParams {
  appID: string;
  apiID?: string;
}

interface ListTokensParams extends TokenGenericParams {
  query?: string;
}

interface AddTokenParams extends OrgGenericParams {
  appID?: string;
  apiID?: string;
  data: { name: string };
  forceSaveIn?: 'app' | 'api';
}

interface DeleteTokenParams extends TokenGenericParams {
  tokenID: string;
}

export const tokenEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listTokens: build.query<TokenClass[], ListTokensParams>({
      async queryFn(
        { orgID, appID, apiID, query },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let tokensResult;
        if (query) {
          const url = apiID
            ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/tokens/search`
            : `/organisations/${orgID}/applications/${appID}/tokens/search`;
          tokensResult = await fetchWithBQ({
            url,
            method: 'POST',
            data: { search_value: query },
          });
        } else {
          const url = apiID
            ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/tokens`
            : `/organisations/${orgID}/applications/${appID}/tokens`;
          tokensResult = await fetchWithBQ(url);
        }

        if (apiID) {
          const { api_tokens } = tokensResult.data as {
            api_tokens: TokenInterface[];
          };
          tokensResult = api_tokens;
        } else {
          const { app_tokens } = tokensResult.data as {
            app_tokens: TokenInterface[];
          };
          tokensResult = app_tokens;
        }

        return {
          data: tokensResult
            .sort(
              (tokenA, tokenB) =>
                Number(tokenB.dateAddedInMicroSeconds) -
                Number(tokenA.dateAddedInMicroSeconds)
            )
            .map((token) => new TokenClass(token)),
        };
      },
      providesTags: ['Tokens'],
    }),
    addToken: build.mutation<TokenClass, AddTokenParams>({
      async queryFn(
        { orgID, appID, apiID, data, forceSaveIn },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let appUUID = appID;
        if (apiID && !appID) {
          const api = await getAPI({ orgID, apiID });
          appUUID = api.api_appUUID;
        }

        const saveInApp = `/organisations/${orgID}/applications/${appUUID}/tokens`;
        const saveInApi = `/organisations/${orgID}/applications/${appUUID}/apis/${apiID}/tokens`;

        let url = apiID ? saveInApi : saveInApp;
        if (forceSaveIn) url = forceSaveIn === 'api' ? saveInApi : saveInApp;

        const result = await fetchWithBQ({
          url,
          method: 'POST',
          data,
        });
        const rawResult = result.data as {
          app_token: TokenInterface;
          api_token: TokenInterface;
        };
        return {
          data: new TokenClass(rawResult.app_token || rawResult.api_token),
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(tokenEndpoints.util.invalidateTags(['Tokens']));

          setTimeout(() => {
            dispatch(tokenEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteToken: build.mutation<void, DeleteTokenParams>({
      query: ({ orgID, appID, apiID, tokenID }) => ({
        url: apiID
          ? `/organisations/${orgID}/applications/${appID}/apis/${apiID}/tokens/${tokenID}`
          : `/organisations/${orgID}/applications/${appID}/tokens/${tokenID}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(tokenEndpoints.util.invalidateTags(['Tokens']));

          setTimeout(() => {
            dispatch(tokenEndpoints.util.invalidateTags(['Quotas']));
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
  }),
});

export const {
  useListTokensQuery,
  useLazyListTokensQuery,
  useAddTokenMutation,
  useDeleteTokenMutation,
} = tokenEndpoints;

export default tokenEndpoints;
