import { AlertClass, AlertType } from '@/utils/models/Alert';
import { FormInstance } from 'antd';
import { useEffect } from 'react';
import AlertConditions from './AlertConditions';
import AlertControlSettings from './AlertControlSettings';
import AlertAdditionalConfig from './AlertAdditionalConfig';
import AlertSensitivity from './AlertSensitivity';
import AlertMetricSelection from './AlertMetricSelection';
import { useParams } from 'react-router-dom';

const AlertForms = ({
  formInstance,
  alert,
  forceType,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  useEffect(() => {
    if (!alert) return;

    formInstance.setFieldsValue({
      config: {
        ...alert.config,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  if (!alert && isEdition) return <></>;

  return (
    <div className='grid grid-cols-1 gap-6'>
      <AlertConditions
        forceType={forceType}
        formInstance={formInstance}
        alert={alert}
      />

      <AlertMetricSelection
        forceType={forceType}
        formInstance={formInstance}
        alert={alert}
      />

      <AlertAdditionalConfig
        forceType={forceType}
        formInstance={formInstance}
        alert={alert}
      />

      <AlertControlSettings
        forceType={forceType}
        formInstance={formInstance}
        alert={alert}
      />

      <AlertSensitivity
        forceType={forceType}
        formInstance={formInstance}
        alert={alert}
      />
    </div>
  );
};

export default AlertForms;
