import { AlertClass, AlertType } from '@/utils/models/Alert';
import { FormInstance } from 'rc-field-form';
import AlertSectionWrapper from './AlertSectionWrapper';
import InputSecondsField from '@/components/Form/FieldComponents/InputSecondsField';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const AlertControlSettings = ({
  formInstance,
  alert,
  forceType,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  alert?: AlertClass;
  forceType?: AlertType;
  isReadOnly?: boolean;
}) => {
  const { alertID } = useParams<{ alertID?: string }>();
  const isEdition = !!alertID;

  useEffect(() => {
    if (isEdition) return;

    formInstance.setFieldsValue({
      config: {
        throttle_enabled: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!alert && isEdition) return <></>;

  const alertType = forceType || alert?.type;

  if (alertType !== 'static') return <></>;

  const throttlePeriodDefaultValue = isEdition
    ? alert?.config.throttle_period
    : 1800; // 30 minutes

  const gracePeriodDefaultValue = isEdition ? alert?.config.grace_period : 600; // 10 minutes

  return (
    <AlertSectionWrapper
      title='Control Settings'
      info={`Adjust parameters to manage alert frequency and timing, preventing over-notification and accounting for expected activity spikes that shouldn't trigger alerts.`}
      isDefaultCollapseOpen={false}
    >
      <div className='grid grid-cols-6 gap-6'>
        <InputSecondsField
          key={'throttle_period'}
          formInstance={formInstance}
          inputField={{
            attribute: ['config', 'throttle_period'],
            name: `After every trigger don't run this check for`,
            fieldSize: 'large',
            description: `After an alert is triggered, enter a 'cooldown' period during which subsequent alerts are suppressed.`,
            validation: {
              required: true,
              min: 60,
              max: 2629800,
            },
            value: throttlePeriodDefaultValue,
          }}
          switchField={{
            attribute: ['config', 'throttle_enabled'],
            value: alert?.config.throttle_enabled,
          }}
          isReadOnly={isReadOnly}
        />

        <InputSecondsField
          key={'grace_period'}
          formInstance={formInstance}
          inputField={{
            attribute: ['config', 'grace_period'],
            name: `Delay evaluating the first check by`,
            fieldSize: 'large',
            value: gracePeriodDefaultValue,
            description: `After an alert is created, this value is used to delay the first evaluation. This serves as a grace period.`,
            validation: {
              required: true,
              min: 0,
              max: 2629800,
            },
          }}
          isReadOnly={isReadOnly}
        />
      </div>
    </AlertSectionWrapper>
  );
};

export default AlertControlSettings;
