import { STORAGE_KEY_PREFIX } from '@/utils/constants';
import { useCallback, useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

type TLoginWithRedirect = {
  appState?: { returnTo: string };
  authorizationParams?: { screen_hint?: string };
};

type TLogoutWithRedirect = {
  appState?: { returnTo: string };
};

export const returnToKey = `${STORAGE_KEY_PREFIX}returnTo`;

function useAuth() {
  const {
    isAuthenticated,
    idTokenData: user,
    tokenData: decodedTokenData,
    isLoading,
    logOut,
    logIn,
    error: errorAuth,
    getTokenSilently,
  } = useContext<IAuthContext>(AuthContext);

  const handleGetAccessTokenSilently = async () => {
    try {
      const token = await getTokenSilently();
      return token;
    } catch (e: any) {
      console.error('Error: Getting token silently', e);
      console.error(e);
      logIn();
    }
  };

  let error;
  if (errorAuth !== null) {
    error =
      typeof errorAuth === 'string' ? errorAuth : JSON.stringify(errorAuth);
  }

  const logoutWithRedirect = useCallback(
    (props?: TLogoutWithRedirect) => {
      // In case of multiple tabs open, it will send an event to other tabs so
      // they don't redirect to the login page.
      // See /src/utils/lib/firetail-auth/wrappers/withAuthenticationRequired.tsx
      localStorage.setItem('logout', 'true');

      const returnTo = props?.appState?.returnTo
        ? `${window.location.origin}${props?.appState?.returnTo}`
        : window.location.origin;
      logOut(undefined, undefined, { returnTo });
    },
    [logOut]
  );

  const loginWithRedirect = useCallback(
    (props?: TLoginWithRedirect) => {
      const { appState, authorizationParams = {} } = props || {};

      const customParams: any = {};

      if (appState?.returnTo) {
        sessionStorage.setItem(returnToKey, encodeURI(appState.returnTo));
        customParams.returnTo = appState.returnTo;
      }

      logIn(undefined, {
        ...customParams,
        ...authorizationParams,
      });
    },
    [logIn]
  );

  return {
    isLoading,
    isAuthenticated,
    decodedTokenData,
    user,
    error,
    logoutWithRedirect,
    loginWithRedirect,
    getAccessTokenSilently: handleGetAccessTokenSilently,
  };
}

export default useAuth;
