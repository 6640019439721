import { Tooltip } from 'antd';

const ItemDate = ({
  created,
  createdFromNow,
}: {
  created?: string;
  createdFromNow: string;
}) => {
  const createdFromNowComponent = (
    <span
      className='text-gray-600 text-xs font-normal 
  leading-none tracking-wide shrink whitespace-nowrap'
    >
      {`Created ${createdFromNow}`}
    </span>
  );

  if (created) {
    return (
      <Tooltip title={created}>
        <div className='-my-1'>{createdFromNowComponent}</div>
      </Tooltip>
    );
  }

  return createdFromNowComponent;
};

export default ItemDate;
