import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import FindingsPage from './posture-management/findings';
import IncidentsPage from './posture-management/incidents';
import IncidentsPolicyPage from './posture-management/incidents-policy';
import EventsPage from './posture-management/events';
import Heading from '@/components/Section/Heading';
import List from '@/components/List';
import Container from '@/components/Section/Container';
import { ListTabType } from '@/components/List/ListTabs';
import useRole from '@/utils/hooks/useRole';
import ExistingAlerts from './monitor-alerting/existing';

const PostureManagement = () => {
  const {
    hasPermissionToListFinding,
    hasPermissionToGetFinding,
    hasPermissionToGetIncidentPolicy,
    hasPermissionToListIncidentPolicy,
    hasPermissionToListIncident,
    hasPermissionToGetIncident,
    hasPermissionToListEvent,
    hasPermissionToGetEvent,
    hasPermissionToListAlerting,
    hasPermissionToGetAlerting,
  } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const navigator = useHistory();

  const tabs: ListTabType[] = [];
  if (hasPermissionToListFinding) {
    tabs.push({
      key: 'findings',
      tooltip:
        'Findings refer to the individual security issues found in your cloud environment.',
    });
  }
  if (hasPermissionToListIncident) {
    tabs.push({
      key: 'incidents',
      tooltip:
        'Incidents refer to the group of security issues found in your cloud environment.',
    });
  }
  if (hasPermissionToListIncidentPolicy) {
    tabs.push({
      label: 'Incidents Policy',
      key: 'incidents-policy',
      tooltip:
        'Incident Policy refers to the group of security alerts that are triggered when defined conditions are met.',
    });
  }
  if (hasPermissionToListEvent) {
    tabs.push({
      key: 'events',
      tooltip: 'Events refers to all triggered events.',
    });
  }
  if (hasPermissionToListEvent) {
    tabs.push({
      label: 'Alerting',
      key: 'monitor-alerting',
      tooltip:
        'Create alerts to receive notifications when pre-defined limits are exceeded, or anomalous behaviour is discovered.',
    });
  }

  return (
    <>
      <Heading text='Posture Management' />
      <Container>
        <List.Tabs
          onChange={(key) => {
            if (key === 'monitor-alerting') {
              navigator.replace(`/organisations/${orgID}/monitor-alerting`);
              return;
            }

            navigator.replace(
              `/organisations/${orgID}/posture-management/${key}`
            );
          }}
          tabs={tabs}
        />
        <Switch>
          {hasPermissionToGetFinding && (
            <Route
              path='/organisations/:orgID/posture-management/findings/:findingID'
              component={FindingsPage}
            />
          )}
          {hasPermissionToListFinding && (
            <Route
              path='/organisations/:orgID/posture-management/findings'
              component={FindingsPage}
            />
          )}
          {hasPermissionToGetIncident && (
            <Route
              path='/organisations/:orgID/posture-management/incidents/:incidentID'
              component={IncidentsPage}
            />
          )}
          {hasPermissionToListIncident && (
            <Route
              path='/organisations/:orgID/posture-management/incidents'
              component={IncidentsPage}
            />
          )}
          {hasPermissionToGetIncidentPolicy && (
            <Route
              path='/organisations/:orgID/posture-management/incidents-policy/:incidentPolicyID'
              component={IncidentsPolicyPage}
            />
          )}
          {hasPermissionToListIncidentPolicy && (
            <Route
              path='/organisations/:orgID/posture-management/incidents-policy'
              component={IncidentsPolicyPage}
            />
          )}
          {hasPermissionToGetEvent && (
            <Route
              path='/organisations/:orgID/posture-management/events/:eventID'
              component={EventsPage}
            />
          )}
          {hasPermissionToListEvent && (
            <Route
              path='/organisations/:orgID/posture-management/events'
              component={EventsPage}
            />
          )}
          {hasPermissionToListAlerting && (
            <Redirect
              from='/organisations/:orgID/monitor-alerting/create'
              to='/organisations/:orgID/monitor-alerting'
            />
          )}
          {hasPermissionToListAlerting && (
            <Redirect
              from='/organisations/:orgID/monitor-alerting/existing'
              to='/organisations/:orgID/monitor-alerting'
            />
          )}
          {hasPermissionToGetAlerting && (
            <Route
              path={`/organisations/:orgID/monitor-alerting/:alertID`}
              component={ExistingAlerts}
            />
          )}
          {hasPermissionToListAlerting && (
            <Route
              path={`/organisations/:orgID/monitor-alerting`}
              component={ExistingAlerts}
            />
          )}
          {hasPermissionToListFinding && (
            <Redirect
              from='/organisations/:orgID/posture-management'
              to='/organisations/:orgID/posture-management/findings'
            />
          )}
        </Switch>
      </Container>
    </>
  );
};

export default PostureManagement;
