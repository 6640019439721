import React, { useEffect, useRef, useState } from 'react';

const Tabs = ({
  style,
  activeKey,
  onChange,
  items = [],
}: {
  style: React.CSSProperties;
  activeKey: string | undefined;
  onChange: (key: string) => void;
  items: { key: string; label: React.ReactNode }[] | undefined;
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    if (ref.current && activeKey && items.length > 0) {
      // @ts-ignore
      setWidth(ref.current.clientWidth);
      // @ts-ignore
      setLeft(ref.current.offsetLeft);
    } else {
      setWidth(0);
      setLeft(0);
    }
  }, [ref, activeKey, items]);

  if (items.length === 0) return <></>;

  return (
    <div
      className='flex gap-x-8 items-center relative overflow-x-auto xl:overflow-hidden'
      style={style}
    >
      {items?.map((i) => (
        <button
          className='text-base text-anti-flash focus:outline-none px-1 h-full 
          whitespace-nowrap'
          key={i.key}
          ref={activeKey === i.key ? ref : undefined}
          onClick={() => onChange(i.key)}
        >
          {i.label}
        </button>
      ))}
      <div
        className='w-full bg-second absolute bottom-0'
        style={{
          width: width,
          left: left,
          height: 2,
          transition: 'width .3s,left .3s,right .3s',
        }}
      />
    </div>
  );
};

export default Tabs;
