import { Form, Input, Select } from 'antd';
import { MdCheckBox, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Rule } from 'rc-field-form/lib/interface';
import { AutocompleteFilterOptionsType } from '@/utils/services/resource/resource-endpoints';
import { handleFilterOptions } from '@/utils/methods/searchFormatting';
import { FilterClass } from '@/utils/models/Filter';

const AddonAfterField = ({
  type,
  onSelectChange,
  onTextChange,
  options,
  isOptionsLoading = false,
  onSelectChangeCallback,
}: {
  type?: FilterClass;
  onSelectChange?: (value: string[]) => void;
  onTextChange?: (value: string) => void;
  options?: AutocompleteFilterOptionsType[];
  isOptionsLoading?: boolean;
  onSelectChangeCallback?: (value: string[]) => void;
}) => {
  const capitalizeString = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  if (type?.type === 'float') return <></>;

  if (type && type.dynamic_key) {
    const { dynamic_key } = type;
    if (options) {
      const formatSingleValue = (values: string[]) => {
        if (values.length > 1) values.shift();
      };

      const rules: Rule[] = [{ required: true, message: 'Required' }];

      return (
        <Form.Item
          className='col-span-3'
          key='addonAfter'
          name='addonAfter'
          label={capitalizeString(dynamic_key)}
          rules={rules}
        >
          <Select
            options={options}
            mode='tags'
            showSearch
            optionFilterProp='children'
            popupClassName='select-custom-theme'
            suffixIcon={
              <MdOutlineKeyboardArrowDown
                size={16}
                className='text-[#262d35]'
              />
            }
            menuItemSelectedIcon={<MdCheckBox size={24} />}
            placeholder={`Select ${capitalizeString(type.dynamic_key)}s`}
            data-testid={`select-headers`}
            onChange={(headers) => {
              if (onSelectChange) onSelectChange(headers);
              if (onSelectChangeCallback) onSelectChangeCallback(headers);
              return formatSingleValue(headers);
            }}
            filterOption={handleFilterOptions}
            disabled={isOptionsLoading}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        className='col-span-3'
        key='addonAfter'
        name='addonAfter'
        label={type?.dynamic_key}
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input
          data-testid='addon-after'
          placeholder={type?.dynamic_key}
          onChange={
            onTextChange
              ? (event) => onTextChange(event.target.value)
              : undefined
          }
        />
      </Form.Item>
    );
  }

  return <></>;
};

export default AddonAfterField;
