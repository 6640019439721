import { FilterGroupType } from '../models/Filter';
import { DataNode } from 'antd/lib/tree';
import { EndpointDetailInterface } from '../models/SpecificationVersionDetail';
import MethodTag from '@/components/Tag/MethodTag';

export const getActiveFilterGroupKey = (filters: FilterGroupType[]) => {
  const filterGroup = filters.find(
    (f) => f.id === 'include.request.method.request.resource'
  );

  if (filterGroup) {
    const method = filterGroup.groups.find((g) => g.id === 'request.method');
    const resource = filterGroup.groups.find(
      (g) => g.id === 'request.resource'
    );

    let methodValue: string | undefined;
    if (method?.values && method?.values?.length === 1) {
      methodValue = method.values[0];
    }

    let resourceValue: string | undefined;
    if (resource?.values && resource?.values?.length === 1) {
      resourceValue = resource.values[0];
    }

    if (methodValue && resourceValue) {
      return createKey(methodValue, resourceValue);
    }
  }

  return undefined;
};

export const getTreeData = (
  endpoints: EndpointDetailInterface[],
  groups: string[]
) => {
  const treeData: DataNode[] = groups
    .sort((a, b) => a.localeCompare(b))
    .map((group) => ({
      title: <DirectoryTitleComponent title={group} />,
      key: group,
      children: endpoints
        .filter((endpoint) => endpoint.path.split('/')[1] === group)
        .sort((a, b) => a.path.localeCompare(b.path))
        .map((item: any) => ({
          title: <EndpointComponent path={item.path} method={item.method} />,
          key: createKey(item.method, item.path),
        })),
    }));
  return treeData;
};

export const getSelectedEndpoint = (
  endpoints: EndpointDetailInterface[],
  activeEndpointKey?: string
) => endpoints.find((e) => activeEndpointKey === e.key.toLowerCase());

const createKey = (method: string, path: string) =>
  `${method.toLowerCase()}::${path.toLowerCase()}`;

export const DirectoryTitleComponent = ({ title }: { title: string }) => (
  <span className='flex items-center font-medium capitalize'>{title}</span>
);

export const EndpointComponent = ({
  path,
  method,
}: {
  path: string;
  method: string;
}) => (
  <span className='flex items-center p-1'>
    <MethodTag mode='span' name={method} />
    <span className='font-normal text-gray-700'>{path}</span>
  </span>
);
