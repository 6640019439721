import { OrganisationInterface } from '@/utils/models/Organisation';

export type SummaryOrg = { UUID: string; name: string };

const _key: string = '@@organisation@@';
const _selected_orgs_key: string = '@@selected_orgs@@';

class OrganisationStorage {
  static getKey(): string {
    return _key;
  }

  static clear() {
    localStorage.removeItem(_key);
  }

  static set updateSelectedOrgs(orgs: SummaryOrg[]) {
    localStorage.setItem(_selected_orgs_key, JSON.stringify(orgs));
  }

  static set update(organisation: OrganisationInterface) {
    localStorage.setItem(_key, JSON.stringify(organisation));
  }

  static get(): OrganisationInterface | undefined {
    const org = localStorage.getItem(_key);
    return org ? JSON.parse(org) : undefined;
  }

  static getSelectedOrgs(): SummaryOrg[] {
    const orgs = localStorage.getItem(_selected_orgs_key);
    return orgs ? JSON.parse(orgs) : [];
  }

  static get UUID() {
    const org = this.get();
    return org?.UUID;
  }

  static get fullname() {
    const org = this.get();
    return org?.name;
  }
}

export default OrganisationStorage;
