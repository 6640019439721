import usePaginationQueryParam, {
  PAGINATION,
  PaginationDataType,
} from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import { Pagination } from 'antd';
import React, { ReactElement, useState } from 'react';

const ListPagination = ({
  children,
  dataSource,
  staticPosition = false,
  useURI,
  total,
}: {
  children: ReactElement[] | ReactElement;
  dataSource?: any[];
  staticPosition?: boolean;
  useURI?: boolean;
  total?: number;
}) => {
  const { pagination: uriPagination, setPagination: setUriPagination } =
    usePaginationQueryParam();

  const [localPagination, setLocalPagination] =
    useState<PaginationDataType>(PAGINATION);

  const localPaginationTotal = dataSource?.length || 0;

  let items = dataSource;
  if (!useURI && dataSource) {
    const firstItemPage = localPagination.marker;
    const lastItemPage =
      (localPagination.page || 0) * (localPagination.pageSize || 0);
    items = dataSource.slice(firstItemPage, lastItemPage);
  }

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        dataSource: items,
      });
    });
  };

  return (
    <div>
      {renderChildren()}

      <div
        className={`mt-4 text-right ${
          staticPosition ? 'bg-white py-2 pr-4 w-full border' : ''
        }`}
        style={
          staticPosition
            ? { bottom: 0, right: 0, position: 'absolute' }
            : undefined
        }
      >
        <Pagination
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          size='small'
          total={total || localPaginationTotal}
          current={useURI ? uriPagination.page : localPagination.page}
          pageSize={useURI ? uriPagination.pageSize : localPagination.pageSize}
          onChange={(page, pageSize) => {
            const marker = pageSize * (page - 1);
            const pagination: PaginationDataType = { page, pageSize, marker };

            useURI
              ? setUriPagination(pagination)
              : setLocalPagination(pagination);
          }}
          showSizeChanger
        />
      </div>
    </div>
  );
};

export default ListPagination;
