import { useCallback } from 'react';
import {
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

const searchDefault = withDefault(StringParam, '');

interface UseSearchQueryParamProps {
  updateType?: UrlUpdateType;
}

function useSearchQueryParam(props?: UseSearchQueryParamProps) {
  const updateType: UrlUpdateType = props?.updateType || 'push';

  const [{ search }, setQuery] = useQueryParams({
    search: searchDefault,
  });

  const setSearch = useCallback(
    (query?: string) => {
      setQuery({ search: query }, updateType);
    },
    [setQuery, updateType]
  );

  return { query: search, setSearch };
}

export default useSearchQueryParam;
