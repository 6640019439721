import moment from 'moment';

export interface ApplicationInterface {
  UUID: string;
  app_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  g_appUUID: string;
  g_orgUUID: string;
  itemType: string;
  name: string;
  status: string;
}

export class ApplicationClass {
  UUID: string;
  app_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  g_appUUID: string;
  g_orgUUID: string;
  itemType: string;
  name: string;
  status: string;
  key?: string;

  constructor(data: ApplicationInterface) {
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.app_orgUUID = data.app_orgUUID;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.g_appUUID = data.g_appUUID;
    this.g_orgUUID = data.g_orgUUID;
    this.itemType = data.itemType;
    this.name = data.name;
    this.status = data.status;
  }

  get created(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).format('lll');
  }

  get createdFromNow(): string {
    return moment(this.dateAddedInMicroSeconds / 1000).fromNow();
  }
}
