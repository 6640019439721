import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Descriptions,
  Modal,
  PageHeader,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  useDeleteApplicationMutation,
  useGetApplicationQuery,
} from '@/utils/services/application/application-endpoints';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import useRole from '@/utils/hooks/useRole';

const Settings = () => {
  const { hasPermissionToDeleteApplication } = useRole();
  const { appID, orgID } = useParams<{ appID: string; orgID: string }>();
  const { data: application, isLoading: appicationIsLoading } =
    useGetApplicationQuery({
      orgID,
      appID,
    });
  const { data: organisation } = useGetOrganisationQuery({ orgID });
  const [deleteApplication] = useDeleteApplicationMutation();
  const history = useHistory();

  const handleDeleteApplication = async () => {
    await deleteApplication({ orgID, appID });
    history.push(`/organisations/${orgID}/applications`);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this application?`,
      icon: <ExclamationCircleOutlined />,
      content: `Name: ${application?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteApplication();
      },
    });
  };

  const extra = [];
  if (hasPermissionToDeleteApplication) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => showDeleteConfirm()}
        danger
      >
        Delete Application
      </Button>
    );
  }

  return (
    <Spin spinning={appicationIsLoading}>
      <div className='p-1 bg-white mb-4 border'>
        {organisation && application && (
          <div className='pt-3 pl-4 mb-0'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/organisations/${orgID}`}>{organisation?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/organisations/${orgID}/applications/${appID}`}>
                  {application?.name}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        )}
        <PageHeader
          ghost={false}
          title={application?.name}
          subTitle='Application Settings'
          extra={extra}
          onBack={() => window.history.back()}
          tags={
            <Tag
              color={application?.status === 'active' ? 'green' : 'yellow'}
              className='uppercase'
            >
              {application?.status}
            </Tag>
          }
        >
          <Descriptions size='small' column={{ xs: 1, sm: 2, md: 3 }}>
            <Descriptions.Item label='UUID'>
              {application?.UUID}
            </Descriptions.Item>
            <Descriptions.Item label='Created by'>
              {application?.createdBy}
            </Descriptions.Item>
            {application?.dateAddedInMicroSeconds && (
              <Descriptions.Item label='Creation Time'>
                {moment(application?.dateAddedInMicroSeconds / 1000).format(
                  'lll'
                )}
              </Descriptions.Item>
            )}
          </Descriptions>
        </PageHeader>
      </div>
    </Spin>
  );
};

export default Settings;
