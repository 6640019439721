import { Button, Descriptions, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import { SequenceClass } from '@/utils/models/Sequence';
import { useListHistorySequencesQuery } from '@/utils/services/request/request-endpoints';
import useRequestIDQueryParam from '@/utils/hooks/QueryParam/useRequestIDQueryParam';
import MethodTag from '@/components/Tag/MethodTag';
import StatusCodeTag from '@/components/Tag/StatusCodeTag';
import Item from '@/components/List/Item';
import { RequestClass } from '@/utils/models/Request';
import { formatMilliseconds } from '@/utils/methods/displayFormat';
import { useEffect } from 'react';

const SECTION_ID = 'api-call-sequence-section';

const DashboardRequestDetailsSequences = ({ log }: { log: RequestClass }) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { setRequestID } = useRequestIDQueryParam();

  const historySequencesIDs = log?.sequences?.ip?.history || [];
  const { currentData, isLoading, isFetching } = useListHistorySequencesQuery({
    orgID,
    historySequencesIDs,
    currentLogID: log.UUID,
  });

  const scrollToSection = () => {
    setTimeout(() => {
      const element = document.getElementById(SECTION_ID);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  useEffect(() => {
    const sectionId = sessionStorage.getItem('sectionId');
    if (sectionId === SECTION_ID) {
      scrollToSection();
      sessionStorage.removeItem('sectionId');
    }
  }, []);

  const columns: ColumnsType<SequenceClass> = [
    {
      title: 'Order',
      key: 'order',
      render: (_, sequence) => (
        <div className='text-sm flex gap-1 items-center'>
          <span>{sequence?.order}</span>
          {sequence.order === 1 && (
            <span className='text-xs'>(current log)</span>
          )}
        </div>
      ),
    },
    {
      title: 'Path',
      key: 'path',
      render: (_, sequence) => (
        <span className='text-sm break-words'>{sequence.path}</span>
      ),
    },
    {
      title: 'Method',
      key: 'method',
      render: (_, sequence) => <MethodTag name={sequence.method} />,
    },
    {
      title: 'Status Code',
      key: 'statusCode',
      render: (_, sequence) => (
        <StatusCodeTag code={sequence.statusCode?.toString()} />
      ),
    },
    {
      title: 'Interval',
      render: (_, sequence) => (
        <Tooltip title='The elapsed time between this and the previous request in the sequence'>
          <div className='text-sm'>
            {sequence.order === 1 ? (
              <span>-</span>
            ) : (
              <div>
                <span className='text-green-600'>+</span>
                <span>
                  {formatMilliseconds(sequence.diffFromPreviousRequest)}
                </span>
              </div>
            )}
          </div>
        </Tooltip>
      ),
    },
  ];

  const expandedRowRender = (sequence: SequenceClass) => {
    return (
      <div className=''>
        <Descriptions column={{ xs: 1, sm: 1 }} size='small'>
          <Descriptions.Item label='Date Created'>
            {sequence.created}
          </Descriptions.Item>

          <Descriptions.Item label='Source'>
            <Item.LogoAndName
              logo={sequence.avatar}
              name={sequence.typeLabel}
            />
          </Descriptions.Item>

          <Descriptions.Item label='IP Address'>
            {sequence.ip}{' '}
            {RequestClass.isPrivateIP(sequence.ip) ? '(Private)' : '(Public)'}
          </Descriptions.Item>

          {sequence.ipDetails?.city && (
            <Descriptions.Item label='IP location' className='capitalize'>
              {[sequence.ipDetails.city, sequence.ipDetails?.country].join(
                ', '
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item label='Host'>{sequence.domain}</Descriptions.Item>
        </Descriptions>

        <Button
          onClick={(evt) => {
            evt.preventDefault();
            setRequestID(sequence.UUID);
          }}
          className='mt-2'
          style={{ backgroundColor: 'white' }}
        >
          See log details
        </Button>
      </div>
    );
  };

  return (
    <div className='gap-3 flex flex-col pt-4' id={SECTION_ID}>
      <div className='flex flex-col'>
        <h1 className='font-semibold' style={{ fontSize: 20 }}>
          API Call Sequence
        </h1>
        <h4 className='text-gray-800'>By IP Address</h4>
      </div>

      <Table
        scroll={{ x: 500 }}
        columns={columns}
        pagination={false}
        dataSource={currentData}
        loading={isLoading || isFetching}
        expandable={{
          expandedRowRender,
          rowExpandable: (sequence) => sequence.order > 1,
        }}
      />
    </div>
  );
};

export default DashboardRequestDetailsSequences;
