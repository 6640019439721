import { Form, Radio } from 'antd';
import { Rule } from 'antd/lib/form';
import FieldType from '@/utils/models/FieldType';
import { getColSize, getFieldSize } from './const/functions';
import { ExperimentOutlined } from '@ant-design/icons';

/* eslint-disable no-template-curly-in-string */
const DetailedRadioGroupField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }

  const options = field.values || [];

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')} w-full`}>
      <Form.Item
        key={field.attribute}
        label={field.name}
        tooltip={field.description}
        name={field.attribute}
        rules={rules}
        className={getFieldSize(field.fieldSize || 'extralarge')}
      >
        <Radio.Group disabled={field.disabled} className='w-full'>
          <div
            className='grid gap-1'
            style={{
              gridTemplateColumns: `repeat(${
                field.columns || 1
              }, minmax(0, 1fr))`,
            }}
          >
            {options.map((opt, index) => (
              <Radio
                key={opt.value}
                className={`border ${
                  index === options.length - 1 ? 'mr-0' : ''
                } radio-hidden ${
                  opt.selected ? 'border-gray-300' : 'border-gray-800 border-2'
                } hover:border-gray-400 hover:shadow cursor-pointer rounded-lg shadow-sm transition duration-300 ease-in-out flex-grow relative`}
                style={{
                  minHeight: '80px',
                }}
                disabled={opt.disabled}
                value={opt.value}
                id={opt.value}
              >
                <div className='flex flex-col justify-between h-full p-4'>
                  <div className='flex items-center mb-2'>
                    {opt.icon && <div className='mr-2'>{opt.icon}</div>}
                    <span className='text-base font-medium'>{opt.label}</span>
                  </div>
                  {opt.description && (
                    <span className='text-xs text-gray-500'>
                      {opt.description}
                    </span>
                  )}
                  {opt.disabled && (
                    <ExperimentOutlined className='absolute top-2 right-2' />
                  )}
                </div>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default DetailedRadioGroupField;
